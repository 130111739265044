import { IconsTypesProps } from './types';
import { CURRENT_COLOR } from 'src/config';

export const SuccessIcon = ({ fill = '#36B37E', width = 20, height = 20 }: IconsTypesProps) => {
  const color = fill ?? CURRENT_COLOR;

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.0001 1.6665C8.35191 1.6665 6.74074 2.15525 5.37033 3.07092C3.99992 3.9866 2.93182 5.28809 2.30109 6.81081C1.67036 8.33353 1.50533 10.0091 1.82687 11.6256C2.14842 13.2421 2.94209 14.727 4.10753 15.8924C5.27297 17.0578 6.75782 17.8515 8.37433 18.173C9.99084 18.4946 11.6664 18.3296 13.1891 17.6988C14.7118 17.0681 16.0133 16 16.929 14.6296C17.8447 13.2592 18.3334 11.648 18.3334 9.99984C18.3334 8.90549 18.1179 7.82186 17.6991 6.81081C17.2803 5.79976 16.6665 4.8811 15.8926 4.10728C15.1188 3.33346 14.2002 2.71963 13.1891 2.30084C12.1781 1.88205 11.0944 1.6665 10.0001 1.6665ZM10.0001 16.6665C8.68154 16.6665 7.39261 16.2755 6.29628 15.543C5.19996 14.8104 4.34547 13.7692 3.84089 12.5511C3.3363 11.3329 3.20428 9.99244 3.46152 8.69924C3.71875 7.40603 4.35369 6.21814 5.28604 5.28579C6.21839 4.35344 7.40628 3.7185 8.69948 3.46127C9.99269 3.20403 11.3331 3.33606 12.5513 3.84064C13.7695 4.34522 14.8107 5.19971 15.5432 6.29604C16.2758 7.39236 16.6668 8.6813 16.6668 9.99984C16.6668 11.7679 15.9644 13.4636 14.7141 14.7139C13.4639 15.9641 11.7682 16.6665 10.0001 16.6665Z"
        fill={color}
      />
      <path
        d="M12.25 6.99165L9.09999 11.1583L7.74166 9.39998C7.60574 9.22538 7.40602 9.11193 7.18645 9.08458C6.96687 9.05723 6.74543 9.11823 6.57083 9.25415C6.39623 9.39007 6.28277 9.58979 6.25542 9.80937C6.22807 10.0289 6.28907 10.2504 6.42499 10.425L8.45 13.0167C8.52847 13.116 8.62854 13.1961 8.74261 13.251C8.85668 13.3058 8.98175 13.334 9.10833 13.3333C9.23559 13.333 9.3611 13.3036 9.47521 13.2472C9.58933 13.1909 9.68903 13.1092 9.76666 13.0083L13.575 8.00832C13.7098 7.83151 13.7689 7.60838 13.7392 7.38803C13.7095 7.16767 13.5935 6.96814 13.4167 6.83332C13.2399 6.6985 13.0167 6.63944 12.7964 6.66913C12.576 6.69883 12.3765 6.81484 12.2417 6.99165H12.25Z"
        fill={color}
      />
    </svg>
  );
};
