import { Container, Stack } from '@mui/material';
import { loginLayout, content } from './style';

type LoginLayoutProps = {
  children: React.ReactNode;
};
export const LoginLayout = ({ children }: LoginLayoutProps) => (
  <Container maxWidth={false} sx={loginLayout}>
    <Stack justifyContent={'center'} height="100%">
      <Container sx={content}>{children}</Container>
    </Stack>
  </Container>
);
