import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { NavSubItemProps } from '../../types';
import { navSubItemBox, StyledNavSubItemCircle, StyledNavSubItemTitle } from './style';
import { useLocation, useNavigate } from 'react-router-dom';

export const NavSubItem = ({
  path,
  title,
  testId,
  isActive,
  onClick,
  handleCloseSideBar,
}: NavSubItemProps) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();

  const redirect = () => {
    navigate(path + location.search);
  };

  const onClickHandler = () => {
    redirect();
    if (onClick) onClick(title);
    if (handleCloseSideBar) handleCloseSideBar();
  };
  return (
    <Box sx={navSubItemBox} onClick={onClickHandler}>
      <StyledNavSubItemCircle isActive={isActive} theme={theme} data-sm={testId} />
      <StyledNavSubItemTitle
        isActive={isActive}
        theme={theme}
        to={path + location.search}
        sx={{ paddingLeft: '23px', fontSize: '14px' }}
      >
        {title}
      </StyledNavSubItemTitle>
    </Box>
  );
};
