export default class WebWorker {
  private worker: Worker;

  onmessage: ((e: MessageEvent) => void) | undefined;

  constructor(worker: any) {
    const code = worker.toString();
    const blob = new Blob(['(' + code + ')()']);
    this.worker = new Worker(URL.createObjectURL(blob));
    this.worker.onmessage = this.onMessageHandler.bind(this);
  }

  private onMessageHandler(event: MessageEvent) {
    try {
      if (this.onmessage) {
        this.onmessage(event);
      }
    } catch (error) {
      console.error('Error handling message in WebWorker:', error);
    }
  }

  postMessage(message: any) {
    this.worker.postMessage(message);
  }

  terminate() {
    this.worker.terminate();
  }
}
