import { Stack, Typography, useTheme } from '@mui/material';
import React, { FunctionComponent } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { MenuPopoverItem } from 'src/components';
import { SettingsNavigationItemType } from './types';

interface ISettingsMenuPopoverItemProps {
  settingsItem: SettingsNavigationItemType;
}

const SettingsMenuPopoverItem: FunctionComponent<ISettingsMenuPopoverItemProps> = ({
  settingsItem,
}) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const location = useLocation();
  const isActiveItem = location.pathname.includes(settingsItem.path);
  return (
    <MenuPopoverItem
      key={settingsItem.path}
      sx={{ px: '20px!important', py: '8px!important' }}
      customContent={
        <Stack
          sx={{
            color: isActiveItem ? theme.palette.grey[800] : theme.palette.grey[500],
          }}
          alignItems="center"
          flexDirection="row"
        >
          <Typography
            sx={{
              fontSize: '24px',
              fontWeight: isActiveItem ? '700' : '400',
              marginRight: '20px',
            }}
          >
            •
          </Typography>
          <Typography
            sx={{ fontSize: '14px', fontWeight: isActiveItem ? '700' : '400' }}
            variant="subtitle1"
          >
            {settingsItem?.title}
          </Typography>
        </Stack>
      }
      handleClick={() => {
        navigate({
          pathname: settingsItem.path,
          search: location.search,
        });
      }}
      testId="profile-button"
    />
  );
};

export default SettingsMenuPopoverItem;
