import { IconsTypesProps } from 'src/assets/icons/types';

export const PumpFilterIcon = ({ width = 22, height = 22 }: IconsTypesProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.6955 16.2569C16.6955 19.0265 14.4503 21.2716 11.6807 21.2716C8.91119 21.2716 6.66602 19.0265 6.66602 16.2569C6.66602 13.4874 8.91119 11.2422 11.6807 11.2422C14.4503 11.2422 16.6955 13.4874 16.6955 16.2569Z"
      stroke="#0461C1"
    />
    <circle cx="11.6804" cy="16.2292" r="4.10424" fill="#049ACC" />
    <path
      d="M15.7949 7.74414H17.1949V16.2283L16.6699 14.6193L15.7949 13.7884V7.74414Z"
      fill="#0461C1"
    />
    <path
      d="M16.3548 5.33651C16.4327 5.25938 16.5582 5.25938 16.6362 5.33651L18.7214 7.40038C18.8484 7.52607 18.7594 7.74253 18.5807 7.74253H14.4102C14.2315 7.74253 14.1425 7.52607 14.2695 7.40038L16.3548 5.33651Z"
      fill="#049ACC"
    />
    <path
      d="M16.3542 2.36972C16.4321 2.29258 16.5576 2.29258 16.6356 2.36972L18.7208 4.43358C18.8478 4.55927 18.7588 4.77573 18.5801 4.77573H14.4096C14.2309 4.77573 14.1419 4.55927 14.2689 4.43358L16.3542 2.36972Z"
      fill="#049ACC"
    />
  </svg>
);
