import { CustomErrors } from 'src/types/errors';

export const clearCustomErrors = (
  name: string,
  specificErrorValue: string,
  setCustomErrors: (value: React.SetStateAction<CustomErrors>) => void,
  clearEntireKey: boolean = false
) => {
  setCustomErrors((prevValue) => {
    const newValue = { ...prevValue };

    if (clearEntireKey) {
      delete newValue[name];
      return newValue;
    }

    if (newValue[name]) {
      newValue[name].message = newValue[name].message.filter(
        (message) => message !== specificErrorValue
      );

      if (newValue[name].message.length === 0) {
        delete newValue[name];
      }
    }

    return newValue;
  });
};
