import { IconsTypesProps } from './types';

export const TrashBinIcon = ({ fill = '#919EAB' }: IconsTypesProps) => (
  <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.50781 14.8516C4.50781 15.6766 5.18281 16.3516 6.00781 16.3516H12.0078C12.8328 16.3516 13.5078 15.6766 13.5078 14.8516V7.35156C13.5078 6.52656 12.8328 5.85156 12.0078 5.85156H6.00781C5.18281 5.85156 4.50781 6.52656 4.50781 7.35156V14.8516ZM13.5078 3.60156H11.6328L11.1003 3.06906C10.9653 2.93406 10.7703 2.85156 10.5753 2.85156H7.44031C7.24531 2.85156 7.05031 2.93406 6.91531 3.06906L6.38281 3.60156H4.50781C4.09531 3.60156 3.75781 3.93906 3.75781 4.35156C3.75781 4.76406 4.09531 5.10156 4.50781 5.10156H13.5078C13.9203 5.10156 14.2578 4.76406 14.2578 4.35156C14.2578 3.93906 13.9203 3.60156 13.5078 3.60156Z"
      fill={fill}
    />
  </svg>
);
