import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

type StylesTypes = {
  isMobile: boolean;
};

const StyledTagList = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isMobile',
})(({ isMobile }: StylesTypes) => ({
  display: 'grid',
  gridTemplateColumns: isMobile ? 'repeat(2,1fr)' : 'repeat(4,1fr)',
  padding: isMobile ? '0 5px 0 5px' : '0',
  columnGap: isMobile ? '10px' : '20px',
  marginTop: isMobile ? '10px' : '0',
  justifyItems: 'start',
  width: '60%',
}));

export { StyledTagList };
