import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import {
  SITES_MANAGEMENT_PATH,
  SITE_INFO_PATH,
  GATEWAY_PATH_ROOT,
  ASSET_PATH_ROOT,
  ALARM_RULES_PATH_ROOT,
  INTEGRATIONS_PATH,
  CONTROLLERS_PATH_ROOT,
  ALARMS_PATH,
  ALARMS_NOTIFICATIONS_PATH,
  SITES_MAP_PATH,
} from 'src/routes/config';
import {
  SITE_INFO_SUBITEM,
  GATEWAYS_SUBITEM,
  SITE_LIST_SUBITEM,
  SITE_MAP_SUBITEM,
  ASSETS_SUBITEM,
  ALARM_RULES_SUBITEM,
  INTEGRATIONS_SUBITEM,
  CONTROLLERS_SUBITEM,
  ALARMS_LIST_SUBITEM,
  MANAGE_ALARMS_NOTIFICATIONS_SUBITEM,
} from '../config';
import { NavSubItemProps } from '../types';

export const useSubNavigation = (subItems: NavSubItemProps[]) => {
  const location = useLocation();

  const isSiteManagementPage = location.pathname.includes(SITES_MANAGEMENT_PATH);
  const isSiteMapPage = location.pathname.includes(SITES_MAP_PATH);
  const isSiteInfoPage = location.pathname.includes(SITE_INFO_PATH);
  const isGatewaysPage = location.pathname.includes(GATEWAY_PATH_ROOT);
  const isAssetsPage = location.pathname.includes(ASSET_PATH_ROOT);
  const isAlarmRulesManagementPage = location.pathname.includes(ALARM_RULES_PATH_ROOT);
  const isIntegrationsPage = location.pathname.includes(INTEGRATIONS_PATH);
  const isControllersPage = location.pathname.includes(CONTROLLERS_PATH_ROOT);
  const isAlarmsPage =
    location.pathname.includes(ALARMS_PATH) &&
    !location.pathname.includes(ALARMS_NOTIFICATIONS_PATH);
  const isAlarmsNotificationPage = location.pathname.includes(ALARMS_NOTIFICATIONS_PATH);

  const [subItemsActive, setSubItemsActive] = useState<any>(
    subItems?.map(({ title }: any) => ({
      title: title,
      isActive: false,
    }))
  );

  const onSubitemClickHandler = (title: string) => {
    if (subItems && subItemsActive)
      setSubItemsActive((prevState: { title: string }[]) =>
        prevState.map((item: { title: string }) => {
          if (item.title === title)
            return {
              ...item,
              isActive: true,
            };
          return { ...item, isActive: false };
        })
      );
  };

  useEffect(() => {
    setSubItemsActive(() =>
      subItems.map(({ title }) => {
        if (isSiteManagementPage && title === SITE_LIST_SUBITEM) {
          return {
            title: title,
            isActive: true,
          };
        }
        if (isSiteMapPage && title === SITE_MAP_SUBITEM) {
          return {
            title: title,
            isActive: true,
          };
        }
        if (isSiteInfoPage && title === SITE_INFO_SUBITEM) {
          return {
            title: title,
            isActive: true,
          };
        }
        if (isGatewaysPage && title === GATEWAYS_SUBITEM) {
          return {
            title: title,
            isActive: true,
          };
        }
        if (isAssetsPage && title === ASSETS_SUBITEM) {
          return {
            title: title,
            isActive: true,
          };
        }
        if (isAlarmRulesManagementPage && title === ALARM_RULES_SUBITEM) {
          return {
            title: title,
            isActive: true,
          };
        }
        if (isIntegrationsPage && title === INTEGRATIONS_SUBITEM) {
          return {
            title: title,
            isActive: true,
          };
        }
        if (isControllersPage && title === CONTROLLERS_SUBITEM) {
          return {
            title: title,
            isActive: true,
          };
        }
        if (isAlarmsPage && title === ALARMS_LIST_SUBITEM) {
          return {
            title: title,
            isActive: true,
          };
        }

        if (isAlarmsNotificationPage && title === MANAGE_ALARMS_NOTIFICATIONS_SUBITEM) {
          return {
            title: title,
            isActive: true,
          };
        }
        return {
          title: title,
          isActive: false,
        };
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return { subItemsActive, onSubitemClickHandler };
};
