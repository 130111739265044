import { useAuthentication } from 'src/features/authentication/context';
import { Protected } from 'src/features/protected-route/components';
import { ACCOUNT_ISSUE_PATH, ADD_ALARM_RULE_HEADER, LOGIN_PATH } from '../../config';
import { AppLayout } from 'src/layouts';
import { NAV_ITEMS_HEADER_SELECTED_SITE, getNavItemsHeaderSelectedSiteSAAndAA } from 'src/features';
import { ElementProps } from '../../types/element';
import { LoadingElement } from '../LoadingElement';
import { AddAlarmRulePage } from 'src/routes/pages';
import { AccessRoles } from 'src/features/user-account-details/types';

export const AlarmRulesAddElement = ({ isSuperAdmin }: ElementProps) => {
  const {
    isCustomerAccessAllowed,
    isSiteAccessAllowed,
    loggedIn,
    customerId,
    siteId,
    siteIdBelongsToCustomer,
    getCurrentRole,
    user,
  } = useAuthentication();

  const isSiteEmployee =
    getCurrentRole(customerId.value, siteId.value) === AccessRoles.SITE_EMPLOYEE;

  const isAccountAdmin =
    getCurrentRole(customerId.value, siteId.value) === AccessRoles.ACCOUNT_ADMIN;

  const getUniqueRedirectPath = () => {
    if (!loggedIn.value) return LOGIN_PATH;
    if (!isSuperAdmin && (!isCustomerAccessAllowed.value || !isSiteAccessAllowed.value))
      return ACCOUNT_ISSUE_PATH + '?reason=Access Denied';
    if (!siteIdBelongsToCustomer.value) return ACCOUNT_ISSUE_PATH + '?reason=Site does not exist';
    if (isSiteEmployee) return ACCOUNT_ISSUE_PATH + '?reason=Access Denied';
  };

  const AlarmRulesAddRouteElement = () => (
    <Protected
      isAuthenticated={loggedIn.value}
      isAuthorized={
        (isSuperAdmin || (isCustomerAccessAllowed.value && isSiteAccessAllowed.value)) &&
        siteIdBelongsToCustomer.value &&
        !isSiteEmployee
      }
      uniqueRedirectPath={getUniqueRedirectPath()}
    >
      <AppLayout
        isSiteHeader
        headerTitle={ADD_ALARM_RULE_HEADER}
        headerNavItems={
          isAccountAdmin || isSuperAdmin
            ? getNavItemsHeaderSelectedSiteSAAndAA(user, isSuperAdmin)
            : NAV_ITEMS_HEADER_SELECTED_SITE
        }
      >
        <AddAlarmRulePage />
      </AppLayout>
    </Protected>
  );

  const getAlarmRulesAddElement = () => {
    if (!loggedIn.loaded) return <LoadingElement />;

    if (loggedIn.loaded && !loggedIn.value) return <AlarmRulesAddRouteElement />;

    if (!customerId.value || !customerId.loaded || !siteId.value || !siteId.loaded)
      return <LoadingElement />;

    if (!siteIdBelongsToCustomer.loaded) return <LoadingElement />;

    if (isSuperAdmin && loggedIn.loaded) return <AlarmRulesAddRouteElement />;

    if (!isCustomerAccessAllowed.loaded || !isSiteAccessAllowed.loaded) return <LoadingElement />;

    return <AlarmRulesAddRouteElement />;
  };

  return getAlarmRulesAddElement();
};
