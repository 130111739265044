import { IconsTypesProps } from './types';
import { CURRENT_COLOR } from 'src/config';

export const BWTIcon = ({ fill, width, height, altColor }: IconsTypesProps) => {
  const color = fill ?? CURRENT_COLOR;
  const bLogoColor = altColor || CURRENT_COLOR;
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      height={height}
      width={width}
      viewBox="0 0 1218.9 651.97"
      enableBackground="new 0 0 1218.9 651.97"
      xmlSpace="preserve"
    >
      <style type="text/css">
        {`.st0{fill:${bLogoColor};}`}
        {`.st1{fillRule:evenodd;clipRule:evenodd;fill:${color};}`}
        {`.st2{fill:${bLogoColor};}`}
      </style>
      <g id="BWT">
        <g>
          <polygon
            className="st1"
            points="1089.87,217.24 952.59,217.24 927.69,288.63 967.1,288.63 967.1,438.76 1047.64,438.76 
					1047.64,288.63 1089.87,288.63 		"
          />
          <polygon
            className="st1"
            points="861.21,217.24 826.86,314.83 800.82,217.24 740.15,217.24 714.1,314.83 679.76,217.24 597.91,217.24 
					675.18,438.76 741.85,438.76 770.48,330.96 799.11,438.76 865.79,438.76 943.06,217.24 		"
          />
          <path
            className="st1"
            d="M575.38,325.05c16.48-10.31,27.44-28.61,27.44-49.47c0-32.21-26.12-58.33-58.33-58.33H423.84v221.39v0.12
					h132.5c32.28,0,58.45-26.17,58.45-58.45C614.78,354.69,598.31,332.95,575.38,325.05z M491.38,272.18h21.56
					c6.43,0,11.64,6.66,11.64,14.89c0,8.22-5.21,14.89-11.64,14.89h-21.56V272.18z M524.32,390.15h-32.73v-32.39h32.73
					c7.18,0,13,7.25,13,16.19S531.5,390.15,524.32,390.15z"
          />
        </g>
      </g>
      <g id="Drop">
        <g>
          <path
            className="st2"
            d="M322.98,328c0,40.85-33.12,73.96-73.96,73.96s-73.96-33.12-73.96-73.96V146.73l35.78,20.66V328
					c0,21.08,17.1,38.18,38.18,38.18c21.09,0,38.18-17.1,38.18-38.18c0-14.13-7.68-26.48-19.1-33.08l-0.07-0.04l-36.73-21.2v-41.33
					l19.87,11.47l34.34,19.83l0.49,0.28l0.36,0.2C308.26,276.97,322.98,300.77,322.98,328z"
          />
          <path
            className="st0"
            d="M369,328c0,66.27-53.72,119.99-119.99,119.99c-66.27,0-119.99-53.72-119.99-119.99V120.15l35.79,20.66V328
					c0,46.51,37.7,84.21,84.2,84.21c46.51,0,84.21-37.7,84.21-84.21c0-16.1-4.51-31.15-12.35-43.93c-7.2-11.74-17.18-21.58-29.04-28.6
					l-0.72-0.42h-0.01l-0.46-0.27l-59.35-34.27v-41.33L309,224.05v0.01C344.87,244.81,369,283.59,369,328z"
          />
        </g>
      </g>
    </svg>
  );
};
