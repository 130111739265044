import { IconsTypesProps } from 'src/assets/icons/types';

export const LiftFilterIcon = ({ width = 22, height = 22 }: IconsTypesProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="3.81641" y="2" width="18" height="20" rx="1" stroke="#0461C1" />
    <path
      d="M4.81641 10C4.81641 9.44772 5.26412 9 5.81641 9H19.8164C20.3687 9 20.8164 9.44772 20.8164 10V20.7C20.8164 20.8657 20.6821 21 20.5164 21H5.11641C4.95072 21 4.81641 20.8657 4.81641 20.7V10Z"
      fill="#049ACC"
    />
  </svg>
);
