import { Dialog, Theme, styled } from '@mui/material';

type styleType = {
  theme: Theme;
};

const modeStyle = {
  display: 'flex',
  marginTop: '0.5rem',
};

const iconBox = {
  marginRight: '1rem',
};

const controllBtn = {
  marginTop: '0.7rem',
  marginLeft: 'auto',
  padding: '6px 15px',
};

const modeText = {
  fontSize: '0.9rem',
  wordWrap: 'break-word',
  maxWidth: '180px',
};

const currentMode = {
  fontSize: '0.9rem',
  marginLeft: '0.3rem',
  fontWeight: 'bold',
};

const ControllerWidgetModal = styled(Dialog)(({ theme }: styleType) => ({
  '& .MuiDialog-paperWidthLg': {
    maxWidth: '1000px!important',
    width: '800px',
    backgroundColor: theme.palette.background.default,
  },
}));

export { modeStyle, iconBox, controllBtn, modeText, currentMode, ControllerWidgetModal };
