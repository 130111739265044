import { IconsTypesProps } from '../types';
import { CURRENT_COLOR } from 'src/config';

export const ArrowDownIconSmall = ({ fill }: IconsTypesProps) => {
  const color = fill ?? CURRENT_COLOR;

  return (
    <svg width="10" height="5" viewBox="0 0 10 5" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.00004 4.66666C4.84427 4.66697 4.69331 4.61272 4.57337 4.51333L0.57337 1.18C0.289863 0.944354 0.251061 0.523502 0.486703 0.239996C0.722344 -0.0435107 1.1432 -0.0823126 1.4267 0.153329L5.00004 3.14L8.57337 0.259995C8.7111 0.148149 8.88773 0.0958162 9.06416 0.114585C9.24059 0.133354 9.40225 0.221676 9.51337 0.359996C9.63679 0.498568 9.69687 0.682314 9.67916 0.867035C9.66145 1.05176 9.56754 1.22074 9.42004 1.33333L5.42004 4.55333C5.29665 4.63701 5.14877 4.67691 5.00004 4.66666Z"
        fill={color}
      />
    </svg>
  );
};
