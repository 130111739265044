import { IconButton, Stack, Typography, useTheme } from '@mui/material';
import { Link } from 'react-router-dom';
import { InfoIconV2 } from 'src/assets';
import { LightTooltip } from 'src/components';
import { useAuthentication } from 'src/features/authentication/context';
import { CONTROLLERS_PATH_ROOT } from 'src/routes/config';
import { ImportantHoverableText, ImportantText } from '../style';

export const AdvancedTagMappingTooltip = () => {
  const {
    customerId: { value: customerId },
    siteId: { value: siteId },
  } = useAuthentication();

  const theme = useTheme();

  return (
    <LightTooltip
      placement="bottom"
      arrow
      enterTouchDelay={0}
      title={
        <Typography
          variant="body1"
          sx={{
            fontWeight: 400,
            fontSize: '14px',
            whiteSpace: 'pre-wrap',
            wordWrap: 'break-word',
            color: theme.palette.text.secondary,
          }}
        >
          <ImportantText
            style={{ fontWeight: 'bold', color: theme.palette.text.primary }}
            theme={theme}
          >
            Advanced Tag Mapping
          </ImportantText>{' '}
          is not available. To enable it at least one{' '}
          <Link
            style={{ textDecoration: 'none' }}
            to={`${CONTROLLERS_PATH_ROOT}?customerId=${customerId}&siteId=${siteId}`}
          >
            <ImportantHoverableText theme={theme}>Controller</ImportantHoverableText>
          </Link>{' '}
          must be added to the Site, tag <ImportantText theme={theme}>display name</ImportantText>{' '}
          should be defined, and tag type must be either{' '}
          <ImportantText theme={theme}>Mode</ImportantText>,{' '}
          <ImportantText theme={theme}>State</ImportantText>,{' '}
          <ImportantText theme={theme}>Setpoint</ImportantText> or{' '}
          <ImportantText theme={theme}>Output</ImportantText>.
        </Typography>
      }
    >
      <Stack justifyContent="center">
        <IconButton style={{ pointerEvents: 'none' }}>
          <InfoIconV2 width={20} height={20} />
        </IconButton>
      </Stack>
    </LightTooltip>
  );
};
