import { Dialog, DialogContent } from '@mui/material';
import { Theme, styled } from '@mui/material/styles';
import { scrollbarOverride } from 'src/styles';

type AddDialogStyledType = {
  theme: Theme;
};

export const AddDialogStyled = styled(Dialog)(({ theme }: AddDialogStyledType) => ({
  '& .MuiDialog-paperWidthLg': {
    maxWidth: '1200px!important',
    width: '450px',
    backgroundColor: theme.palette.background.default,
  },
}));

export const AddDialogContentWithCustomScroll = styled(DialogContent)(scrollbarOverride);
