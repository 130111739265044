//ACCESS ROLES
const ACCOUNT_ADMIN = 'ACCOUNT_ADMIN';
const SITE_MANAGER = 'SITE_MANAGER';
const SITE_EMPLOYEE = 'SITE_EMPLOYEE';
const LIMITED_ACCESS = 'LIMITED_ACCESS';

//JOB TITLES
const PLANT_MANAGER = 'PLANT_MANAGER';
const SUPERINTENDENT = 'SUPERINTENDENT';
const MAINTENANCE = 'MAINTENANCE';
const ENGINEER = 'ENGINEER';

//STATUS
const ACTIVE = 'active';
const LOCKED = 'locked';

//USER
const USER = 'user';

//PASSWORD
const NEW_PASSWORD_REQUIRED = 'NEW_PASSWORD_REQUIRED';

// ID TOKEN
const ID_TOKEN = 'id_token';

// CUSTOMER ID
const CUSTOMER_ID = 'customer_id';

// REMEMBER ME
const REMEMBER_ME = 'remember_me';

// SELECTED SITE
const SELECTED_SITE = 'selectedSite';

export {
  ACCOUNT_ADMIN,
  SITE_MANAGER,
  SITE_EMPLOYEE,
  LIMITED_ACCESS,
  PLANT_MANAGER,
  SUPERINTENDENT,
  MAINTENANCE,
  ENGINEER,
  ACTIVE,
  LOCKED,
  USER,
  NEW_PASSWORD_REQUIRED,
  ID_TOKEN,
  CUSTOMER_ID,
  REMEMBER_ME,
  SELECTED_SITE,
};
