import calculateFieldSpaceInPercent from './calculateFieldSpaceInPercent';

const fieldClass = (fielsLength: number, isMobile: boolean, blockElement?: boolean) => ({
  paddingBottom: '20px',
  maxWidth: `calc(${calculateFieldSpaceInPercent(fielsLength, isMobile)}% - ${
    isMobile ? '6px' : '8px'
  })`,
  flexBasis:
    isMobile && blockElement
      ? '100%'
      : `calc(${calculateFieldSpaceInPercent(fielsLength, isMobile)}% - ${
          isMobile ? '6px' : '8px'
        })`,
});

export default fieldClass;
