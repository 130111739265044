export type FormValuesType = {
  firstName?: string;
  lastName?: string;
  email?: string;
  mobilePhone?: string;
  password?: string;
};

export enum FormValuesEnum {
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  EMAIL = 'email',
  MOBILE_PHONE = 'mobilePhone',
  PASSWORD = 'password',
}
