import { useEffect, useState, useCallback, useRef } from 'react';
import { DateRangePicker } from 'rsuite';
import { SelectedBoxType } from 'src/features/trends/components/timeframe-bar-options/types';
import { OneMonthRangeIcon } from 'src/assets';
import { useTheme } from '@mui/material';
import 'rsuite/dist/rsuite-no-reset.min.css';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

type Props = {
  onChangeTimestapHandler: (values: [Date, Date]) => void;
  onSelectBoxHandler: (value: SelectedBoxType) => void;
  timestamps: { fromDate: string; toDate: string };
  changeTimeFormatHandler: (fromDate: Date, toDate: Date) => void;
};

export const DateRangeComponent = ({
  onChangeTimestapHandler,
  onSelectBoxHandler,
  timestamps,
  changeTimeFormatHandler,
}: Props) => {
  const [values, setValues] = useState<[Date, Date] | null>(null);
  const valuesRef = useRef<[Date, Date] | null>(null);
  const theme = useTheme();

  useEffect(() => {
    const initialValues: [Date, Date] = [
      new Date(timestamps.fromDate),
      new Date(timestamps.toDate),
    ];
    setValues(initialValues);
  }, [timestamps]);

  useEffect(() => {
    const cssVariables = {
      '--rs-btn-primary-bg': theme.palette.primary.main,
      '--rs-btn-primary-hover-bg': theme.palette.primary.main,
      '--rs-btn-primary-active-bg': theme.palette.primary.main,
      '--rs-bg-active': theme.palette.primary.main,
      '--rs-calendar-range-bg': theme.palette.primary.lighter,
      '--rs-state-hover-bg': theme.palette.primary.lighter,
      '--rs-calendar-cell-selected-hover-bg': theme.palette.primary.main,
      '--rs-listbox-option-hover-text': theme.palette.primary.main,
      '--rs-listbox-option-hover-bg': theme.palette.primary.lighter,
      '--rs-input-focus-border': theme.palette.primary.main,
      '--rs-color-focus-ring': theme.palette.primary.lighter,
    };
    Object.entries(cssVariables).forEach(([variable, color]) => {
      document.documentElement.style.setProperty(variable, color);
    });
  }, [theme]);

  const onChangeHandler = (newValues: [Date, Date] | null) => {
    setValues(newValues);
    valuesRef.current = newValues;
  };

  const onApplyHandler = useCallback(() => {
    if (valuesRef.current) {
      const fromDate = new Date(valuesRef.current[0]);

      const toDate = new Date(valuesRef.current[1]);
      const localEndOfDay = dayjs.utc(valuesRef.current[1]).tz(dayjs.tz.guess()).endOf('day');
      const localEndOfDayDate = localEndOfDay.toDate();

      onChangeTimestapHandler([fromDate, toDate]);
      onSelectBoxHandler('custom');
      changeTimeFormatHandler(fromDate, localEndOfDayDate);
    }
  }, [onChangeTimestapHandler, onSelectBoxHandler, changeTimeFormatHandler]);

  return (
    <DateRangePicker
      className="custom-date-range-picker"
      value={values}
      onChange={onChangeHandler}
      onOk={onApplyHandler}
      ranges={[]}
      caretAs={OneMonthRangeIcon}
      size="lg"
      locale={{ ok: 'Apply' }}
    />
  );
};
