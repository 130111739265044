import { Button, Stack } from '@mui/material';
import { FunctionComponent } from 'react';

interface IFormActionsProps {
  onCancel: () => void;
  isEdit: boolean;
  isSaveLoading: boolean;
  isDeleteing: boolean;
  disable: boolean;
  isHistory: boolean;
  isSaveDisabled?: boolean;
  showDelete: boolean;
  onDelete: () => void;
}

const FormActions: FunctionComponent<IFormActionsProps> = ({
  onCancel,
  onDelete,
  disable,
  isSaveLoading,
  isHistory,
  isDeleteing,
  showDelete,
  isSaveDisabled = false,
  isEdit = false,
}) => (
  <Stack justifyContent="space-between" flexDirection="row">
    {!isHistory ? (
      <>
        <Stack>
          {isEdit && showDelete ? (
            <Button onClick={onDelete} disabled={disable} variant="contained" color="error">
              {isDeleteing ? 'Deleting...' : 'Delete'}
            </Button>
          ) : (
            <></>
          )}
        </Stack>
        <Stack flexDirection="row" gap="12px">
          <Button disabled={disable} onClick={onCancel} variant="outlined">
            Cancel
          </Button>
          <Button disabled={disable || isSaveDisabled} type="submit" variant="contained">
            {isSaveLoading ? 'Saving...' : 'Save'}
          </Button>
        </Stack>{' '}
      </>
    ) : (
      <>
        <Stack />
        <Button onClick={onCancel} disabled={disable} type="button" variant="contained">
          Close
        </Button>
      </>
    )}
  </Stack>
);

export default FormActions;
