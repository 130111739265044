import { Box, BoxProps } from '@mui/material';
import { FunctionComponent, PropsWithChildren } from 'react';
import { cardHeader } from './style';

const CardHeader: FunctionComponent<PropsWithChildren<BoxProps>> = ({ children, ...props }) => (
  <Box sx={cardHeader} {...props}>
    {children}
  </Box>
);

export default CardHeader;
