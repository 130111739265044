import {
  Box,
  Button,
  Card,
  CardHeader,
  CardActions,
  Collapse,
  Grid,
  useTheme,
} from '@mui/material';

import { MapOverviewIcon, MapDirectionIcon, DuplicateCopyIcon } from 'src/assets';
import { useAuthentication } from 'src/features/authentication/context';
import { SITES_DASHBOARD_PATH } from 'src/routes/config';

import {
  COPY_ADDRESS_DESCRIPTION,
  OPEN_IN_APPLE_MAPS_DESCRIPTION,
  OPEN_IN_GOOGLE_MAPS_DESCRIPTION,
} from 'src/features/sites/config';

import { formatSiteType } from 'src/features/sites/utils';
import { useNavigate } from 'react-router';
import { useState } from 'react';
import { getAddressString } from '../../utils/getAddressString';
import { NewSite } from '../../types/sites';
import { MapPinIcon } from 'src/assets/icons/MapPinIcon';
import { MapAppleIcon } from 'src/assets/icons/MapAppleIcon';
import { MapGoogleIcon } from 'src/assets/icons/MapGoogleIcon';
import { CheckIcon } from 'src/assets/icons/CheckIcon';
import { StyledPopup } from '../../pages/site-map/styles';
import MapPopupIndicator from './MapPopupIndicator';

export const MapMarkerPopup = ({ site }: { site: NewSite | undefined }) => {
  const navigate = useNavigate();
  const [expanded, setExpanded] = useState(false);
  const [isAddressCopied, setIsAddressCopied] = useState(false);
  const theme = useTheme();

  const {
    customerId: { value: customerId },
  } = useAuthentication();

  const handleRedirect = (siteId: string | undefined) => {
    if (!customerId || !siteId) return;
    navigate(SITES_DASHBOARD_PATH + `?customerId=${customerId}&siteId=${siteId}`);
  };

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleCopyAddressClick = (address: string) => {
    navigator.clipboard.writeText(address);
    setIsAddressCopied(true);
    setTimeout(() => {
      setIsAddressCopied(false);
    }, 2000);
  };

  const handleOpenInAppleMapsClick = (address: string) => {
    const url = `https://maps.apple.com/?q=${encodeURIComponent(address)}`;
    window.open(url, '_blank');
  };

  const handleOpenInGoogleMapsClick = (address: string) => {
    const url = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(address)}`;
    window.open(url, '_blank');
  };

  const prepareSiteName = (site: NewSite | undefined) => {
    if (site?.siteName) {
      if (site?.siteName.length > 18) {
        return `${site?.siteName.substring(0, 18)}...`;
      } else {
        return site?.siteName;
      }
    }
  };

  return (
    <StyledPopup>
      <Card sx={{ backgroundColor: 'transparent', boxShadow: 'none', margin: '16px auto' }}>
        <CardHeader
          sx={{ textTransform: 'capitalize', justifyContent: 'space-between', paddingTop: 0 }}
          title={
            <Grid container>
              <Grid
                item
                xs={11}
                sx={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}
              >
                {prepareSiteName(site)}
              </Grid>
              <Grid item xs={1}>
                <MapPopupIndicator alarmPriority={site?.highestActiveAlarm} />
              </Grid>
            </Grid>
          }
          subheader={site?.siteType ? formatSiteType(site.siteType) : ''}
        />

        <CardActions sx={{ ml: '10px', mr: '10px' }}>
          <Button
            startIcon={<MapOverviewIcon />}
            data-sm="site-overview"
            onClick={() => {
              handleRedirect(site?.id);
            }}
            data-testid="OverviewBtn"
          >
            Overview
          </Button>
          <Button
            sx={{ paddinfLeft: '26px' }}
            variant={expanded ? 'contained' : 'text'}
            startIcon={<MapDirectionIcon />}
            data-sm="site-direction"
            onClick={handleExpandClick}
            data-testid="DirectionsBtn"
          >
            Directions
          </Button>
        </CardActions>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <Card
            sx={{ backgroundColor: 'transparent', boxShadow: 'none', margin: '0', zIndex: 99999 }}
          >
            <CardHeader
              sx={{ mt: '5px', paddingRight: '5px', paddingLeft: '22px', maxWidth: '200px' }}
              avatar={<MapPinIcon fill={theme.palette.text.secondary} />}
              subheader={getAddressString(site)}
            />
            <Box sx={{ textAlign: 'right' }}>
              <Button
                sx={{ fontWeight: 400, marginBottom: '22px', paddingRight: '22px' }}
                size="small"
                startIcon={isAddressCopied ? <CheckIcon /> : <DuplicateCopyIcon />}
                data-sm="site-overview"
                onClick={() => {
                  handleCopyAddressClick(getAddressString(site));
                }}
                data-testid="CopyAddressBtn"
              >
                {COPY_ADDRESS_DESCRIPTION}
              </Button>
            </Box>
            <Box alignItems="center" sx={{ textAlign: 'center' }}>
              <Button
                sx={{ textTransform: 'none' }}
                startIcon={<MapAppleIcon />}
                data-sm="site-overview"
                onClick={() => {
                  handleOpenInAppleMapsClick(getAddressString(site));
                }}
                data-testid="OpenInAppleMaps"
              >
                {OPEN_IN_APPLE_MAPS_DESCRIPTION}
              </Button>
            </Box>
            <Box alignItems="center" sx={{ textAlign: 'center' }}>
              <Button
                sx={{ textTransform: 'none' }}
                startIcon={<MapGoogleIcon />}
                data-sm="site-overview"
                onClick={() => {
                  handleOpenInGoogleMapsClick(getAddressString(site));
                }}
                data-testid="OpenInGoogleMaps"
              >
                {OPEN_IN_GOOGLE_MAPS_DESCRIPTION}
              </Button>
            </Box>
          </Card>
        </Collapse>
      </Card>
    </StyledPopup>
  );
};
