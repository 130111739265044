export function filterFormData(data: any) {
  const filteredData: any = {};

  Object.keys(data).forEach((key) => {
    const value = data[key];
    filteredData[key] = value !== '' ? value : null;
  });

  return filteredData;
}
