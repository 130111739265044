import { useCallback, useMemo } from 'react';
import { useAuthentication } from 'src/features/authentication/context';
import { Protected } from 'src/features/protected-route/components';
import { ACCOUNT_ISSUE_PATH, LOGIN_PATH, ADD_ALARM_NOTIFICATIONS } from '../../config';
import { AppLayout } from 'src/layouts';
import {
  NAV_ITEMS_HEADER_SITES_MANAGEMENT,
  getNavItemsHeaderSiutesManagementSAAndAA,
} from 'src/features';
import { AddAlarmNotificationGroupPage } from 'src/routes/pages';
import { ElementProps } from '../../types/element';
import { LoadingElement } from '../LoadingElement';
import { AccessRoles } from 'src/features/user-account-details/types';

export const AlarmsNotificationAddElement = ({ isSuperAdmin }: ElementProps) => {
  const { isCustomerAccessAllowed, loggedIn, customerId, getCurrentRole, siteId, user } =
    useAuthentication();
  const isAccountAdmin =
    getCurrentRole(customerId.value, siteId.value) === AccessRoles.ACCOUNT_ADMIN;

  const getUniqueRedirectPath = useCallback(() => {
    if (!loggedIn.value) return LOGIN_PATH;
    if (!isSuperAdmin && isCustomerAccessAllowed.value && !isAccountAdmin)
      return ACCOUNT_ISSUE_PATH + '?reason=Access Denied';
  }, [loggedIn.value, isSuperAdmin, isCustomerAccessAllowed.value, isAccountAdmin]);

  const alarmsNotificationAddRouteElement = useMemo(
    () => (
      <Protected
        isAuthenticated={loggedIn.value}
        isAuthorized={isSuperAdmin || (isCustomerAccessAllowed.value && isAccountAdmin)}
        uniqueRedirectPath={getUniqueRedirectPath()}
      >
        <AppLayout
          headerTitle={ADD_ALARM_NOTIFICATIONS}
          headerNavItems={
            isSuperAdmin || isAccountAdmin
              ? getNavItemsHeaderSiutesManagementSAAndAA(user, isSuperAdmin)
              : NAV_ITEMS_HEADER_SITES_MANAGEMENT
          }
        >
          <AddAlarmNotificationGroupPage />
        </AppLayout>
      </Protected>
    ),
    [
      user,
      loggedIn.value,
      isSuperAdmin,
      isCustomerAccessAllowed.value,
      isAccountAdmin,
      getUniqueRedirectPath,
    ]
  );

  const getAlarmsElement = () => {
    if (!loggedIn.loaded) return <LoadingElement />;

    if (loggedIn.loaded && !loggedIn.value) return alarmsNotificationAddRouteElement;

    if (!customerId.value || !customerId.loaded) return <LoadingElement />;

    if (isSuperAdmin && loggedIn.loaded) return alarmsNotificationAddRouteElement;

    if (!isCustomerAccessAllowed.loaded) return <LoadingElement />;

    return alarmsNotificationAddRouteElement;
  };

  return getAlarmsElement();
};
