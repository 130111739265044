import { timestampConverter } from 'src/utilities';
import { GraphSeriesType } from '../types/readings';

export const formatTagDataForExport = (tagData: GraphSeriesType[]) => {
  const data = tagData?.flatMap((item) => {
    const name = item?.name;
    const newData = item?.data?.map((item) => ({
      name: name,
      date: timestampConverter(item[0]),
      value: item[1],
    }));

    return newData;
  });
  return data;
};
