import { Box } from '@mui/material';
import { FunctionComponent, PropsWithChildren } from 'react';

const HeaderMobileWrapper: FunctionComponent<PropsWithChildren> = ({ children }) => (
  <Box sx={{ background: 'transparent', marginBottom: '150px' }}>
    <Box sx={{ width: ' 100%', position: 'fixed', top: '0', left: ' 0' }}>{children}</Box>
  </Box>
);

export default HeaderMobileWrapper;
