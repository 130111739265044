import { api, API_V2, THEMES } from '../api';
import { AddThemeTypes } from './types';

const add = async (data: AddThemeTypes) => {
  const response = await api({
    method: 'post',
    url: `${API_V2}/${THEMES}`,
    data,
  });
  return response.data;
};
const getById = async (customerId: string, themeId: string, reset: boolean) => {
  const response = await api({
    method: 'get',
    url: `${API_V2}/${THEMES}/${themeId}?customerId=${customerId}&reset=${reset}`,
  });
  return response.data;
};

const update = async (customerId: string, themeId: string) => {
  const response = await api({
    method: 'patch',
    url: `${API_V2}/${THEMES}/${themeId}?customerId=${customerId}`,
  });
  return response.data;
};
const getLogo = async (customerId: string, filename: string, themeId: string) => {
  const response = await api({
    method: 'get',
    url: `${API_V2}/${THEMES}/logos/${themeId}?customerId=${customerId}&filename=${filename}`,
  });
  return response.data;
};
export const ThemeService = { add, getById, update, getLogo };
