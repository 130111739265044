import { add, parseISO, sub } from 'date-fns';
export const calculateOffsetTimestamp = (timestamp: string, offset: string) => {
  const offsetStartDate = parseISO(timestamp);
  const regex = /\((UTC([-+]\d{2}:\d{2}))\)/;
  const match = offset?.match(regex);
  if (match) {
    const offsetParts = match[2]; // e.g., "-05:00"
    const sign = offsetParts?.charAt(0); // Extracts '-' or '+'
    const offset = offsetParts?.slice(1); // Extracts '05:00'
    const [hours, minutes] = offset?.split(':').map((num) => parseInt(num, 10));
    if (sign === '-') {
      return sub(offsetStartDate, {
        hours,
        minutes,
      }).toISOString();
    }
    if (sign === '+') {
      return add(offsetStartDate, {
        hours,
        minutes,
      }).toISOString();
    }
  }
};
