import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  TextField,
} from '@mui/material';
import { FunctionComponent, useEffect, useState } from 'react';

const EditTitleDialog: FunctionComponent<
  Omit<DialogProps, 'onSubmit' | 'onClose'> & {
    value: string;
    onClose: () => void;
    onSubmit: (value: string) => void;
  }
> = ({ onSubmit, value, onClose, ...props }) => {
  const [title, setTitle] = useState(value);

  useEffect(() => {
    setTitle(value);
  }, [value]);

  return (
    <Dialog {...props}>
      <DialogTitle id="alert-dialog-title">Edit Title</DialogTitle>
      <DialogContent>
        <TextField
          onChange={(event) => {
            setTitle(event.target.value);
          }}
          defaultValue={title}
        />
      </DialogContent>
      <DialogActions sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Button
          variant="text"
          onClick={() => {
            setTitle(value);
            onClose();
          }}
        >
          Cancel
        </Button>
        <Button variant="contained" onClick={() => onSubmit(title)} autoFocus>
          Agree
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditTitleDialog;
