const trendWidgetBox = {
  boxShadow: '0px 0px 2px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12)',
};

const trendWidgetWrapper = {
  borderRadius: '8px',
  padding: '10px 20px 20px 20px',
  margin: '1.5rem 0',
};

const timelineBoxDetails = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: '1rem',
};

const hamburgerBox = {
  cursor: 'pointer',
  paddingTop: '1rem',
  marginLeft: '10px',
};

const menuPopover = {
  marginTop: '45px',
  padding: '1rem',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
};

const tooltipItemBox = { display: 'flex', alignItems: 'center', cursor: 'pointer' };
const tooltipItemText = { marginLeft: '10px' };
const removeBox = {
  paddingLeft: '4px',
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
};

const rightSideContentBox = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginLeft: 'auto',
};

export {
  trendWidgetWrapper,
  timelineBoxDetails,
  hamburgerBox,
  menuPopover,
  tooltipItemBox,
  tooltipItemText,
  removeBox,
  rightSideContentBox,
  trendWidgetBox,
};
