import { IconsTypesProps } from './types';
import { CURRENT_COLOR } from 'src/config';

export const MapAppleIcon = ({ fill }: IconsTypesProps) => {
  const color = fill ?? CURRENT_COLOR;

  return (
    <svg width="14" height="17" viewBox="0 0 14 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.6169 8.89092C11.6394 11.3122 13.741 12.118 13.7643 12.1283C13.7465 12.1851 13.4285 13.2765 12.6571 14.4039C11.9902 15.3786 11.2981 16.3497 10.2078 16.3698C9.13643 16.3896 8.79193 15.7345 7.56709 15.7345C6.34263 15.7345 5.95988 16.3497 4.94574 16.3896C3.89332 16.4294 3.09192 15.3356 2.4195 14.3645C1.0455 12.378 -0.0045169 8.75125 1.40539 6.30311C2.10581 5.08736 3.3575 4.31749 4.7161 4.29775C5.74955 4.27804 6.725 4.99302 7.35677 4.99302C7.98813 4.99302 9.17351 4.13319 10.4197 4.25947C10.9413 4.28118 12.4057 4.4702 13.346 5.84657C13.2703 5.89354 11.5987 6.86662 11.6169 8.89092ZM9.60345 2.94526C10.1622 2.26894 10.5383 1.32742 10.4357 0.390625C9.63028 0.422994 8.6564 0.927306 8.07872 1.60326C7.56101 2.20185 7.10761 3.15994 7.22994 4.07819C8.12763 4.14765 9.04469 3.62202 9.60345 2.94526Z"
        fill={color}
      />
    </svg>
  );
};
