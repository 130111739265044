const LIFT_STATION = 'LIFT_STATION';
const WATER_TREATMENT_PLANT = 'WATER_TREATMENT_PLANT';
const WASTEWATER_TREATMENT_PLANT = 'WASTEWATER_TREATMENT_PLANT';
const BOOSTER_PUMP_STATION = 'BOOSTER_PUMP_STATION';
const WATER_TOWER = 'WATER_TOWER';
const PROCESS_PLANT = 'PROCESS_PLANT';
const COMBINED_SEWAGE_OVERFLOW = 'COMBINED_SEWAGE_OVERFLOW';
const WATER_WELL = 'WATER_WELL';

export {
  LIFT_STATION,
  WASTEWATER_TREATMENT_PLANT,
  WATER_TREATMENT_PLANT,
  BOOSTER_PUMP_STATION,
  WATER_TOWER,
  PROCESS_PLANT,
  COMBINED_SEWAGE_OVERFLOW,
  WATER_WELL,
};
