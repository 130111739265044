import { ReactNode } from 'react';
import { Button, Dialog, DialogActions, DialogTitle } from '@mui/material';

type Props = {
  title: ReactNode;
  state: boolean;
  onToggle: () => void;
  onDelete: () => void;
};

export const DeleteConfirmationPopup = ({ title, state, onToggle, onDelete }: Props) => (
  <Dialog open={state} onClose={onToggle}>
    <DialogTitle>{title}</DialogTitle>
    <DialogActions>
      <Button color="inherit" variant="contained" onClick={onToggle}>
        Cancel
      </Button>
      <Button color="error" variant="contained" onClick={onDelete}>
        Delete
      </Button>
    </DialogActions>
  </Dialog>
);
