import { useAuthentication } from 'src/features/authentication/context';
import { Protected } from 'src/features/protected-route/components';
import { AppLayout } from 'src/layouts';
import { UserAccountManagementPage } from '../../pages';
import { ACCOUNT_ISSUE_PATH, LOGIN_PATH, USER_ACCOUNTS_HEADER } from '../../config';
import {
  NAV_ITEMS_HEADER_SITES_MANAGEMENT,
  getNavItemsHeaderSiutesManagementSAAndAA,
} from 'src/features';
import { ElementProps } from '../../types/element';
import { LoadingElement } from '../LoadingElement';
import { AccessRoles } from 'src/features/user-account-details/types';
import { useCallback, useMemo } from 'react';

export const UserAccountManagementElement = ({ isSuperAdmin }: ElementProps) => {
  const { loggedIn, getCurrentRole, customerId, user } = useAuthentication();

  const isAccountAdmin = getCurrentRole(customerId.value, null) === AccessRoles.ACCOUNT_ADMIN;

  const getUniqueRedirectPath = useCallback(() => {
    if (!loggedIn.value) return LOGIN_PATH;

    if (!isSuperAdmin && getCurrentRole(customerId.value, null) !== AccessRoles.ACCOUNT_ADMIN) {
      return `${ACCOUNT_ISSUE_PATH}?reason=Access Denied`;
    }

    return null;
  }, [loggedIn.value, isSuperAdmin, customerId.value, getCurrentRole]);

  const userAccountManagementRouteElement = useMemo(
    () => (
      <Protected
        isAuthenticated={loggedIn.value}
        isAuthorized={isSuperAdmin || isAccountAdmin}
        uniqueRedirectPath={getUniqueRedirectPath()}
      >
        <AppLayout
          headerTitle={USER_ACCOUNTS_HEADER}
          headerNavItems={
            isSuperAdmin || isAccountAdmin
              ? getNavItemsHeaderSiutesManagementSAAndAA(user, isSuperAdmin)
              : NAV_ITEMS_HEADER_SITES_MANAGEMENT
          }
        >
          <UserAccountManagementPage />
        </AppLayout>
      </Protected>
    ),
    [loggedIn.value, isSuperAdmin, isAccountAdmin, getUniqueRedirectPath, user]
  );

  const getUserAccountManagementElement = () => {
    if (!loggedIn.loaded || !customerId.loaded) {
      return <LoadingElement />;
    }

    return userAccountManagementRouteElement;
  };

  return getUserAccountManagementElement();
};
