import { useRef, useState } from 'react';
import { useHtmlElement } from 'src/hooks';
import { GatewayLabel, MenuPopoverTooltip } from './style';

type TooltipRichLabelProps = {
  children: React.ReactNode;
};

export const MobileTooltipRichLabel = ({ children }: TooltipRichLabelProps) => {
  const { htmlElement, addHtmlElement } = useHtmlElement();
  const [menuOpen, setMenuOpen] = useState(false);
  const anchorRef = useRef(null);

  return (
    <>
      <GatewayLabel
        data-testid="styled-label"
        variant="filled"
        color="default"
        ref={anchorRef}
        onClick={(event) => {
          setMenuOpen((prev) => !prev);
          if (!menuOpen) {
            addHtmlElement(event.currentTarget);
          }
        }}
      >
        {children}
      </GatewayLabel>
      <MenuPopoverTooltip
        data-testid="popover-tooltip"
        anchorEl={anchorRef.current}
        onClose={() => setMenuOpen(false)}
        open={menuOpen ? htmlElement : null}
        arrow="top-center"
      >
        {children}
      </MenuPopoverTooltip>
    </>
  );
};
