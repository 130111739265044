import dayjs from 'dayjs';

function formatDateRange(startDate: string, endDate: string | null = null) {
  const start = dayjs(startDate);
  const end = endDate ? dayjs(endDate) : null;

  const dateFormat = 'M/D/YYYY';
  const timeFormat = 'h:mm A';

  let result = `${start.format(dateFormat)} , ${start.format(timeFormat)}`;

  if (end) {
    if (start.isSame(end, 'day')) {
      result += ` - ${end.format(timeFormat)}`;
    } else {
      result += ` - ${end.format(dateFormat)}, ${end.format(timeFormat)}`;
    }
  }

  return result;
}

export default formatDateRange;
