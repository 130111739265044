import dayjs from 'dayjs';
import { AssetReportTemplate, DataReportTemplate, ReportTemplateType } from '../types';
import { timeFrameOptions } from './timeFrameToDateUtils';
import { getFilterDto } from '../helper';

type ParseDTOFunction = (
  reportType: ReportTemplateType,
  data: any
) => DataReportTemplate | AssetReportTemplate | null;

export const parseReportTemplatePayload: ParseDTOFunction = (reportType, data) => {
  let timeFrame = ['', ''];

  if (Array.isArray(data.data.timeFrame)) {
    const { timeFrame: tF } = data.data;
    timeFrame = tF;
  } else {
    const timeFrameOption = timeFrameOptions.find((el) => el.value === data.data.timeFrame);
    if (!timeFrameOption) {
      throw Error('Could not parse date for a selected time frame!');
    }

    timeFrame = timeFrameOption.parseToDate();
  }

  if (reportType === 'DATA') {
    return {
      name: data.title,
      reportType: data.header.reportType.id,
      timezone:
        typeof data.header.timeZone === 'string'
          ? data.header.timeZone
          : data.header.timeZone.value,
      timeframe: {
        start: dayjs(timeFrame[0]).format('YYYY-MM-DD'),
        end: dayjs(timeFrame[1]).add(1, 'day').format('YYYY-MM-DD'),
      },
      dataAggregation: data.data.aggregationInterval,
      columns: {
        date: !!data?.data?.columns?.default?.date,
        time: !!data?.data.columns?.default?.time,
        custom:
          Array.isArray(data.data.columns?.custom) && data.data.columns.custom?.length
            ? data.data.columns.custom.map((column: any) => ({
                siteId: column.tag.siteId,
                siteName: column.tag.siteName,
                unit: column.tag.rawUnit,
                gatewayId: column.tag.gatewayId,
                tagId: column.tag.tagId,
                aggregation: column.aggregation.value.toUpperCase(),
                tagName: column.tag.tagName,
                statistics: Object.entries(column.columnStatistics)
                  .filter(([key, value]) => !!value)
                  .map(([key]) => key.toUpperCase()),
              }))
            : [],
      },
    } as DataReportTemplate;
  }

  if (reportType === 'ASSET') {
    return {
      name: data.title,
      reportType: data.header.reportType.id,
      timezone:
        typeof data.header.timeZone === 'string'
          ? data.header.timeZone
          : data.header.timeZone.value,
      timeframe: {
        start: dayjs(timeFrame[0]).format('YYYY-MM-DD'),
        end: dayjs(timeFrame[1]).add(1, 'day').format('YYYY-MM-DD'),
      },
      dataAggregation: data.data.aggregationInterval,
      filters: getFilterDto(data.filters),
      columns: {
        default: {
          date: !!data?.data?.columns?.default?.date,
          site_name: !!data?.data.columns?.default?.site_name,
          site_type: !!data?.data.columns?.default?.site_type,
          asset_name: !!data?.data.columns?.default?.asset_name,
          asset_type: !!data?.data.columns?.default?.asset_type,
        },
        custom:
          Array.isArray(data.data.columns?.custom) && data.data.columns.custom?.length
            ? data.data.columns.custom.map((column: any) => ({
                name: column.asset.property.value,
                assetType: column.asset.type,
                aggregation: column.aggregation.value.toUpperCase(),
                statistics: Object.entries(column.columnStatistics)
                  .filter(([key, value]) => !!value)
                  .map(([key]) => key.toUpperCase()),
              }))
            : undefined,
      },
    } as AssetReportTemplate;
  }
  return null;
};
