import { Theme, styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

type StylesTypes = {
  theme: Theme;
  isActive: boolean;
  isSuperAdmin: boolean;
  isCustomerAccountPage: boolean;
};

type StylesNavItemTypes = {
  theme: Theme;
  isActive: boolean;
  isSuperAdmin: boolean;
  footerNavItem: boolean | undefined;
  isCustomerAccountPage: boolean;
};

const StyledNavItem = styled('li', {
  shouldForwardProp: (prop) =>
    prop !== 'isActive' &&
    prop !== 'isSuperAdmin' &&
    prop !== 'footerNavItem' &&
    prop !== 'isCustomerAccountPage',
})(
  ({
    theme,
    isActive,
    isSuperAdmin,
    footerNavItem,
    isCustomerAccountPage,
  }: StylesNavItemTypes) => ({
    display: 'flex',
    width: '100%',
    maxWidth: '248px',
    height: '48px',
    alignItems: 'center',
    paddingLeft: '16px',
    borderRadius: '8px',
    backgroundColor: footerNavItem
      ? 'none'
      : isSuperAdmin && isCustomerAccountPage
      ? theme.palette.grey[500]
      : isActive
      ? theme.sidebar.selectedNavItemBackground
      : 'none',
    cursor: 'pointer',
  })
);

const link = { display: 'flex', alignItems: 'center', cursor: 'pointer', width: '100%' };

const boxIcon = { display: 'flex', alignItems: 'center' };

const baseNavItemStyles = {
  display: 'inline-block',
  width: '100%',
  marginLeft: '18px',
  lineHeight: '16px',
  textDecoration: 'none',
};

const StyledNavItemText = styled('span', {
  shouldForwardProp: (prop) =>
    prop !== 'isActive' && prop !== 'isSuperAdmin' && prop !== 'isCustomerAccountPage',
})(({ theme, isActive, isSuperAdmin, isCustomerAccountPage }: StylesTypes) => ({
  ...baseNavItemStyles,
  fontSize: theme.typography.body2.fontSize,
  fontFamily: theme.typography.fontFamily,
  fontWeight: isActive ? theme.typography.fontWeightMedium : theme.typography.fontWeightRegular,
  color:
    isSuperAdmin && isCustomerAccountPage
      ? theme.palette.common.white
      : isActive
      ? theme.palette.common.white
      : theme.sidebar.unselectedText,
  '&:hover': {
    color: theme.palette.common.white,
  },
}));

const StyledNavItemTextLink = styled(Link, {
  shouldForwardProp: (prop) =>
    prop !== 'isActive' && prop !== 'isSuperAdmin' && prop !== 'isCustomerAccountPage',
})(({ theme, isActive, isSuperAdmin, isCustomerAccountPage }: StylesTypes) => ({
  ...baseNavItemStyles,
  fontSize: theme.typography.body2.fontSize,
  fontFamily: theme.typography.fontFamily,
  fontWeight: isActive ? theme.typography.fontWeightMedium : theme.typography.fontWeightRegular,
  color:
    isSuperAdmin && isCustomerAccountPage
      ? theme.palette.common.white
      : isActive
      ? theme.palette.common.white
      : theme.sidebar.unselectedText,
  '&:hover': {
    color: theme.palette.common.white,
  },
}));

const boxArrowIcon = {
  cursor: 'pointer',
  transition: 'all ease-out 0.2s',
  padding: '0 15px',
  marginLeft: 'auto',
};

const StyledAdditionalLabelBox = styled(Box)(
  ({ theme, loaded }: { theme: Theme; loaded: string }) => ({
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '39px',
    marginLeft: 'auto',
    marginRight: '10px',
    padding: '1px 8px',
    borderRadius: '6px',
    backgroundColor: loaded === 'true' ? theme.sidebar.alarmNotificationColor : 'none',
    border: loaded === 'true' ? `1px solid ${theme.sidebar.alarmNotificationColor}` : 'none',
  })
);

const StyledAdditionalLabel = styled(Typography)(({ theme }: { theme: Theme }) => ({
  fontWeight: theme.typography.fontWeightBold,
  color: theme.palette.common.white,
}));

export {
  StyledNavItem,
  link,
  boxIcon,
  StyledNavItemText,
  boxArrowIcon,
  StyledAdditionalLabelBox,
  StyledAdditionalLabel,
  StyledNavItemTextLink,
};
