import { Typography, useTheme } from '@mui/material';

type Props = {
  text: string;
};

export const StateLabelBox = ({ text }: Props) => {
  const theme = useTheme();

  const isOpened = text.toLowerCase() === 'opened';
  const isError = text.toLowerCase() === 'error';

  return (
    <Typography
      sx={{
        color: isOpened
          ? theme.palette.primary.main
          : isError
          ? theme.palette.error.main
          : theme.palette.grey[800],
        background: isOpened
          ? theme.palette.primary.lighter
          : isError
          ? theme.palette.error.lighter
          : theme.palette.grey[300],
        borderRadius: '6px',
        fontWeight: 'bold',
        padding: '6px 8px',
      }}
    >
      {text}
    </Typography>
  );
};
