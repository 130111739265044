import { IconsTypesProps } from './types';
import { CURRENT_COLOR } from 'src/config';

export const LogoIcon = ({ fill, height = 30, width = 30 }: IconsTypesProps) => {
  const color = fill ?? CURRENT_COLOR;

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.7861 0.157379H15.0197C15.9048 0.189535 16.3736 0.817411 16.8289 1.44867C17.0372 1.74047 17.2201 2.04962 17.3755 2.37272C18.462 4.57283 19.6839 6.69678 20.8347 8.85965C21.6809 10.4488 22.5441 12.0295 23.4072 13.6102C24.3871 15.4041 25.3433 17.2082 26.3502 18.9886C26.3851 19.047 26.414 19.1088 26.4366 19.1731C26.511 19.3948 26.4501 19.4929 26.2182 19.5437C25.7972 19.6131 25.3696 19.6335 24.9439 19.6046C24.6502 19.5984 24.3596 19.6646 24.0977 19.7976C22.236 20.7182 20.3406 21.5915 18.462 22.4935C17.0032 23.1964 15.5415 23.8898 14.077 24.5735C11.9023 25.5889 9.73267 26.6247 7.55117 27.6198C6.61867 28.048 5.68955 28.5066 4.73335 28.862C3.91399 29.2008 3.014 29.2949 2.1423 29.1328C1.81521 29.0656 1.50893 28.9211 1.24898 28.7115C0.989037 28.5019 0.782956 28.2332 0.647916 27.9278C0.551631 27.6061 0.492019 27.2745 0.470215 26.9395V25.9985C0.510877 25.8906 0.522547 25.7739 0.504063 25.66C0.545977 25.1376 0.689103 24.6283 0.925468 24.1605C1.6752 22.546 2.44862 20.9365 3.28297 19.366C3.6756 18.6315 4.04116 17.8818 4.42364 17.1422C5.61508 14.8552 6.81668 12.5767 8.02843 10.3066C8.72231 8.99673 9.42296 7.69134 10.1304 6.39045C10.9241 4.93669 11.6823 3.466 12.4862 2.01901C12.6909 1.66511 12.9271 1.33036 13.1919 1.01881C13.3728 0.761598 13.611 0.549912 13.8876 0.400427C14.1643 0.250941 14.4719 0.16774 14.7861 0.157379V0.157379Z"
        fill={color}
      />
      <path
        d="M29.5673 26.2574C29.5532 26.8219 29.4181 27.3767 29.1711 27.8845C28.9241 28.3922 28.5709 28.841 28.1355 29.2005C27.595 29.6392 26.9137 29.8671 26.218 29.8419C26.0924 29.8347 25.9691 29.8049 25.8541 29.7539L16.9589 26.3268C16.8828 26.2963 16.7559 26.298 16.7559 26.1948C16.7559 26.0915 16.8879 26.0695 16.9742 26.0357C18.9492 25.286 20.9259 24.543 22.8975 23.7865C23.9637 23.3786 25.0418 22.9928 26.0725 22.4952C26.2283 22.4381 26.3979 22.4304 26.5582 22.4732C28.4689 22.7592 29.5757 24.0657 29.5673 26.2574V26.2574Z"
        fill={color}
      />
    </svg>
  );
};
