import { useState, useCallback } from 'react';
import { TimelineChartValue } from '../types';

export const useTrendLocalStorage = (key: string, customerId: string | null) => {
  const localStorage = window?.localStorage;

  const initialState = JSON.parse(localStorage.getItem(key)!) || [];

  const [values, setValues] = useState<TimelineChartValue[]>(initialState || []);
  const updatedSetValues = useCallback(
    (newValue: TimelineChartValue, isTimelineChart: boolean) => {
      if (!isTimelineChart && newValue.siteIds.length === 0) return;
      const updatedPrevState = values.filter((item) => item.id !== newValue.id);
      setValues([...updatedPrevState, newValue]);

      const newData = [...updatedPrevState, newValue];

      localStorage.setItem(key, JSON.stringify(newData));
    },
    [key, localStorage, values]
  );

  const removeValue = useCallback(
    (chartId: string) => {
      const newData = values.filter((elements) => elements.id !== chartId);
      setValues(newData);
      localStorage.setItem(key, JSON.stringify(newData));
    },
    [key, localStorage, values]
  );

  return {
    values: values.filter((item) => item.customerId === customerId),
    updatedSetValues,
    removeValue,
  };
};
