import { IconsTypesProps } from './types';
import { CURRENT_COLOR } from 'src/config';

export const LeftArrowIcon = ({ fill }: IconsTypesProps) => {
  const color = fill ?? CURRENT_COLOR;
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.7898 18.8444C13.4939 18.8454 13.2135 18.7124 13.0271 18.4827L8.30446 12.616C8.00789 12.2552 8.00789 11.735 8.30446 11.3742L13.1934 5.50754C13.539 5.09173 14.1562 5.03482 14.572 5.38043C14.9878 5.72604 15.0447 6.34329 14.6991 6.7591L10.3285 12L14.5525 17.2409C14.7966 17.5339 14.848 17.9422 14.6842 18.2867C14.5204 18.6311 14.1712 18.8489 13.7898 18.8444Z"
        fill={color}
      />
    </svg>
  );
};
