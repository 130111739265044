import {
  api,
  ACCOUNTS_USERS_URL_START,
  ACCOUNTS_USERS_URL_END,
  COUNT_URL,
  ACCOUNTS_JOB_TITLES,
  ACCOUNTS_CUSTOMERS_URL,
} from '../api';
import {
  GetAllParamsType,
  AddParamsTypes,
  GetCountParamsType,
  GetByIdParamsType,
  EditParamsTypes,
} from './types';

// SUMMARY:
// Endpoint for creating user account.
const add = async (data: AddParamsTypes, customerId: string) => {
  const response = await api({
    method: 'post',
    url: `${ACCOUNTS_USERS_URL_START}/${customerId}${ACCOUNTS_USERS_URL_END}`,
    data,
  });

  return response.data;
};

//SUMMARY:
//Endpoint fot getting all users.
const getAll = async (customerId: string, params?: GetAllParamsType) => {
  const response = await api({
    method: 'get',
    url: `${ACCOUNTS_USERS_URL_START}/${customerId}${ACCOUNTS_USERS_URL_END}/all`,
    params,
  });

  return response.data;
};

// SUMMARY:
// Endpoint for getting user by ID.
const getById = async ({ customerId, userId }: GetByIdParamsType) => {
  try {
    const response = await api({
      method: 'get',
      url: `${ACCOUNTS_USERS_URL_START}/${customerId}${ACCOUNTS_USERS_URL_END}/${userId}`,
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return {};
  }
};

// SUMMARY:
// Endpoint for getting the total number of users accounts for a customer.
const getCount = async (customerId: GetCountParamsType) => {
  try {
    const response = await api({
      method: 'get',
      url: `${ACCOUNTS_USERS_URL_START}/${customerId}${ACCOUNTS_USERS_URL_END}${COUNT_URL}`,
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return {};
  }
};

// SUMMARY:
// Endpoint for editing user.
const edit = async (data: EditParamsTypes, customerId: string) => {
  const response = await api({
    method: 'patch',
    url: `${ACCOUNTS_USERS_URL_START}/${customerId}${ACCOUNTS_USERS_URL_END}/${data.id}`,
    data,
  });
  return response.data;
};

// SUMMARY:
// Endpoint for getting all job titles for a customer.
const getAllJobTitles = async (customerId: string) => {
  try {
    const response = await api({
      method: 'get',
      url: `${ACCOUNTS_CUSTOMERS_URL}/${customerId}${ACCOUNTS_JOB_TITLES}`,
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return {};
  }
};

// SUMMARY:
// Endpoint for withdrawing an invitation.
const withdraw = async (email: string, customerId: string) => {
  const response = await api({
    method: 'post',
    url: `${ACCOUNTS_USERS_URL_START}/${customerId}${ACCOUNTS_USERS_URL_END}/invitations/withdraw`,
    data: { email: email },
  });

  return response.data;
};

const editUserStatusInMultipleCustomers = async (data: EditParamsTypes, customerId: string) => {
  const response = await api({
    method: 'patch',
    url: `${ACCOUNTS_USERS_URL_START}/${customerId}${ACCOUNTS_USERS_URL_END}/${data.id}/users-status`,
    data,
  });
  return response.data;
};

const editUserProfile = async (data: any, customerId: string, userId: string) => {
  const response = await api({
    method: 'patch',
    url: `${ACCOUNTS_USERS_URL_START}/${customerId}${ACCOUNTS_USERS_URL_END}/${userId}/profile`,
    data,
  });
  return response.data;
};

export const UserService = {
  add,
  getAll,
  getCount,
  getById,
  edit,
  getAllJobTitles,
  withdraw,
  editUserStatusInMultipleCustomers,
  editUserProfile,
};
