import { useState, useCallback, useEffect } from 'react';
import { WidgetService } from 'src/services';
import { WidgetType, WidgetTypeEnum } from '../types/types';

type Props = {
  customerId?: string | null;
  siteId?: string | null;
};

export const useGetAllWidgets = ({ customerId, siteId }: Props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [widgets, setWidgets] = useState<WidgetType[] | null>(null);
  const [layout, setLayout] = useState<any>([]);
  const [isAddedOrRemoved, setIsAddedOrRemoved] = useState<boolean>(false);

  const getAll = useCallback(() => {
    if (!customerId || !siteId) return;
    WidgetService.getAll({ customerId: customerId, siteId: siteId })
      .then((data) => {
        const editedData = data.map((widget: WidgetType) => {
          const { widgetType } = widget;
          const isPumpOrControllerWidget =
            widgetType === WidgetTypeEnum.PUMP ||
            widgetType === WidgetTypeEnum.CONTROLLER_WIDGET ||
            widgetType === WidgetTypeEnum.VALVE;
          if (isPumpOrControllerWidget) {
            return {
              ...widget,
              positioning: {
                height: 1.1,
                width: 2,
                x: widget.positioning.x,
                y: widget.positioning.y,
              },
            };
          }
          return widget;
        });

        setIsLoading(false);
        setWidgets(editedData);
        setLayout(() =>
          editedData.map((widget: WidgetType) => ({
            w: widget.positioning.width,
            h: widget.positioning.height,
            y: widget.positioning.y,
            x: widget.positioning.x,
            i: widget.id,
          }))
        );
      })
      .catch((error) => console.error(error));
  }, [customerId, siteId]);

  useEffect(() => {
    setIsLoading(true);
    getAll();
  }, [isAddedOrRemoved, getAll]);

  const toggleAddOrRemoveHandler = () => {
    setIsAddedOrRemoved((prevState) => !prevState);
  };

  const setLayoutHandler = (layout: any) => {
    setLayout(layout);
  };

  return {
    isLoading,
    widgets,
    layout,
    setLayout,
    toggleAddOrRemoveHandler,
    setLayoutHandler,
    getAll,
  };
};
