import { CURRENT_COLOR } from 'src/config';
import { IconsTypesProps } from './types';

export const XIcon = ({ fill }: IconsTypesProps) => {
  const color = fill ?? CURRENT_COLOR;

  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.333313 7.00004C0.333313 3.31814 3.31808 0.333374 6.99998 0.333374C8.76809 0.333374 10.4638 1.03575 11.714 2.286C12.9643 3.53624 13.6666 5.23193 13.6666 7.00004C13.6666 10.6819 10.6819 13.6667 6.99998 13.6667C3.31808 13.6667 0.333313 10.6819 0.333313 7.00004ZM9.00384 8.33337C9.00384 8.15562 8.93285 7.98522 8.80665 7.86004L7.93998 7.00004L8.80665 6.14004C9.06806 5.87863 9.06806 5.45479 8.80665 5.19337C8.54523 4.93196 8.12139 4.93196 7.85998 5.19337L6.99998 6.06004L6.13998 5.19337C5.87856 4.93196 5.45473 4.93196 5.19331 5.19337C4.9319 5.45479 4.9319 5.87863 5.19331 6.14004L6.05998 7.00004L5.19331 7.86004C5.0671 7.98522 4.99611 8.15562 4.99611 8.33337C4.99611 8.51113 5.0671 8.68153 5.19331 8.80671C5.31849 8.93292 5.48889 9.00391 5.66665 9.00391C5.8444 9.00391 6.0148 8.93292 6.13998 8.80671L6.99998 7.94004L7.85998 8.80671C7.98516 8.93292 8.15555 9.00391 8.33331 9.00391C8.51107 9.00391 8.68147 8.93292 8.80665 8.80671C8.93285 8.68153 9.00384 8.51113 9.00384 8.33337Z"
        fill={color}
      />
    </svg>
  );
};
