import { useAuthentication } from 'src/features/authentication/context';
import { Protected } from 'src/features/protected-route/components';
import { LoginLayout } from 'src/layouts';
import { LoginPage } from '../../pages';
import { ElementProps } from '../../types/element';
import { LoadingElement } from '../LoadingElement';
import { TENANT_SWITCH_PATH } from '../../config';
import { useLocation } from 'react-router-dom';

export const LoginElement = ({ isSuperAdmin }: ElementProps) => {
  const { loggedIn, getUser } = useAuthentication();

  const LoginRouteElement = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const reason = queryParams.get('reason');
    return (
      <Protected
        isAuthenticated={!getUser() || reason === 'logout'}
        isAuthorized={true}
        uniqueRedirectPath={TENANT_SWITCH_PATH}
      >
        <LoginLayout>
          <LoginPage />
        </LoginLayout>
      </Protected>
    );
  };

  const getLoginElement = () => {
    if (!loggedIn.loaded) {
      return <LoadingElement />;
    }

    return <LoginRouteElement />;
  };

  return getLoginElement();
};
