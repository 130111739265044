import React, { useContext } from 'react';
import { AlertBox } from 'src/components';

const TagUpdateAlertsContext = React.createContext<any>({} as any);

export const useTagUpdateAlertsContext = () => useContext(TagUpdateAlertsContext);

export const TagUpdateAlertsProvider = ({ children }: any) => {
  const deviceRejectedUpdateMessage =
    'Failed applying controller changes. Device rejected the update. Error Code: 500';

  return (
    <TagUpdateAlertsContext.Provider value={{}}>
      <AlertBox alertMessage={deviceRejectedUpdateMessage} />
      {children}
    </TagUpdateAlertsContext.Provider>
  );
};
