import { Box, Typography, Tooltip, useTheme } from '@mui/material';
import { controllerWidgetStatusBox, lastUpdatedText, tooltip, statusTitleStyle } from './style';
import { CancelIcon } from 'src/assets';
import { SuccessIcon } from 'src/assets/icons/SuccessIcon';
import { CustomWaterLoading } from 'src/components';

type Props = {
  isDataLoading: boolean;
  isSuccessfulUpdate: boolean;
  isErrorLastControls: boolean;
  isErrorOnUpdateControls: boolean;
  lastUpdateReadingTimestamp: string;
};

export const ControllerWidgetStatusBox = ({
  isDataLoading,
  isSuccessfulUpdate,
  isErrorLastControls,
  isErrorOnUpdateControls,
  lastUpdateReadingTimestamp,
}: Props) => {
  const theme = useTheme();

  const tooltipErrorMessage = 'Failed to apply changes';
  const tooltipSuccessfullMessage = 'Changes successfully applied';

  const statusTitle = lastUpdateReadingTimestamp ? `${lastUpdateReadingTimestamp} ago` : '-';

  const tooltipTitle = isErrorLastControls
    ? tooltipErrorMessage
    : !isDataLoading
    ? tooltipSuccessfullMessage
    : '';

  return (
    <Box sx={controllerWidgetStatusBox}>
      <Box>
        <Typography sx={lastUpdatedText}>
          {isDataLoading ? 'Applying Changes' : 'Last updated:'}
        </Typography>
        {!isDataLoading ? <Typography sx={statusTitleStyle}>{statusTitle}</Typography> : null}
      </Box>

      <Tooltip title={tooltipTitle} sx={tooltip}>
        <Box>
          {isDataLoading ? (
            <CustomWaterLoading customColor={theme.palette.warning.main} width={12} height={12} />
          ) : (
            <>
              {isErrorLastControls || isErrorOnUpdateControls ? (
                <CancelIcon width={20} height={20} fill={theme.palette.error.main} />
              ) : null}
              {isSuccessfulUpdate && lastUpdateReadingTimestamp ? (
                <SuccessIcon width={22} height={22} fill={theme.palette.success.main} />
              ) : null}
            </>
          )}
        </Box>
      </Tooltip>
    </Box>
  );
};
