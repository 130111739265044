import { useEffect, useMemo, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { WidgetWrapper } from '../WidgetWrapper';
import { StateLabelBox } from '../StateLabelBox';
import { ValveWidget } from 'src/assets';
import { valveWrapper, svgBox, modeBox, modeTitle, modeValue } from './style';
import { AssetType } from 'src/features/sites/types/types';
import { STATE, MODE } from 'src/features/sites';
import { getStateOrModeData } from '../../utils';
import { ReadingsService } from 'src/services';
import { DataStructure } from '../../types';

type Props = {
  widgetId: string;
  title: string;
  asset: AssetType | undefined;
  toggleAddOrRemoveHandler?: () => void;
  isEditMode?: boolean;
  testId?: string;
};

export const Valve = ({
  title,
  asset,
  toggleAddOrRemoveHandler,
  widgetId,
  testId,
  isEditMode,
}: Props) => {
  const [readings, setReadings] = useState<DataStructure>([]);
  const [isDataReady, setIsDataReady] = useState<boolean>(false);

  useEffect(() => {
    const uniqueGatewayIds = Array.from(
      new Set(asset?.assignedTags?.map((item) => item.assignedGatewayId).filter(Boolean))
    );
    const requests = uniqueGatewayIds.map(async (gatewayId) => {
      if (!gatewayId) return Promise.resolve();

      const uniqueTagIds: string[] = Array.from(
        new Set(
          asset?.assignedTags
            ?.filter(
              (item) => item.assignedGatewayId === gatewayId && item.assignedTagId !== undefined
            )
            ?.map((item) => item.assignedTagId as string)
        )
      );

      try {
        const res = await ReadingsService.getLatestReadingsNewDataStorage({
          gatewayId: gatewayId,
          measurements: uniqueTagIds,
        });
        setReadings((prevState) => [...prevState, { gatewayId: gatewayId, values: res.data }]);
      } catch (error) {
        console.error(error);
      }
    });

    Promise.all(requests).then(() => {
      setIsDataReady(true);
    });
  }, [asset]);

  const filteredAllAssetStates = useMemo(
    () =>
      asset?.assignedTags
        ?.filter((tag) => tag.tagName.toLowerCase() === STATE)
        .sort((a, b) => a.tagIndicatorPosition - b.tagIndicatorPosition),
    [asset]
  );

  const filteredAllAssetModes = useMemo(
    () =>
      asset?.assignedTags
        ?.filter((tag) => tag.tagName.toLowerCase() === MODE)
        .sort((a, b) => a.tagIndicatorPosition - b.tagIndicatorPosition),
    [asset]
  );

  const displayState = getStateOrModeData({
    data: filteredAllAssetStates!!,
    latestReadings: readings,
  });
  const displayMode = getStateOrModeData({
    data: filteredAllAssetModes!!,
    latestReadings: readings,
  });

  return (
    <WidgetWrapper
      title={title}
      isEditMode={isEditMode}
      widgetId={widgetId!}
      toggleAddOrRemoveHandler={toggleAddOrRemoveHandler}
      testId={testId}
      isDataReady={isDataReady}
      isValveWidget={true}
      assetPriority={asset?.priority}
    >
      <Box sx={valveWrapper}>
        <Box sx={svgBox}>
          <ValveWidget />
        </Box>
        {displayState ? <StateLabelBox text={displayState} /> : <Typography>-</Typography>}
        <Box sx={modeBox}>
          <Typography sx={modeTitle}>Mode:</Typography>
          <Typography sx={modeValue}>{displayMode ?? '-'}</Typography>
        </Box>
      </Box>
    </WidgetWrapper>
  );
};
