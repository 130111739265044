import { CURRENT_COLOR } from 'src/config';
import { IconsTypesProps } from './types';

export const ExpandMoreIcon = ({ fill }: IconsTypesProps) => {
  const color = fill ?? CURRENT_COLOR;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height="23" viewBox="0 -960 960 960" width="23">
      <path d="M480-345 240-585l56-56 184 184 184-184 56 56-240 240Z" fill={color} />
    </svg>
  );
};
