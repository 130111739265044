import { Line } from 'react-chartjs-2';
import 'chartjs-adapter-date-fns';
import zoomPlugin from 'chartjs-plugin-zoom';
import 'chartjs-adapter-luxon';
import { getFormatedLabels } from './helper/getFormatedLabels';
import { useEffect } from 'react';
import {
  Chart as ChartJS,
  LineElement,
  CategoryScale,
  LinearScale,
  registerables,
  TimeScale,
} from 'chart.js';
import { ChartData } from './types';

const customCrosshair = {
  id: 'customCrosshair',
  afterDatasetsDraw(chart: any, args: any, pluginOptions: any) {
    const {
      ctx,
      chartArea: { top, bottom },
      scales: { x, y },
      tooltip,
    } = chart;

    if (tooltip._active && tooltip._active.length) {
      const { x: crosshairX } = tooltip._active[0].element;

      ctx.save();
      ctx.beginPath();
      ctx.moveTo(crosshairX, top);
      ctx.lineTo(crosshairX, bottom);
      ctx.strokeStyle = pluginOptions.color || '#919EAB';
      ctx.lineWidth = pluginOptions.width || 1;
      ctx.stroke();
      ctx.restore();

      // eslint-disable-next-line prefer-destructuring
      const datasets = chart.data.datasets;

      const activeElements: any = [];
      const tooltipPositions: any = [];

      datasets.forEach((dataset: any, datasetIndex: any) => {
        dataset.data.forEach((point: any, index: any) => {
          const xValue = x.getValueForPixel(crosshairX);

          if (Math.abs(point.x - xValue) < 0.5) {
            const yPixel = y.getPixelForValue(point.y);

            activeElements.push({
              datasetIndex,
              index,
            });

            tooltipPositions.push({
              x: crosshairX + 10,
              y: yPixel,
            });
          }
        });
      });

      tooltip.setActiveElements(activeElements, tooltipPositions);

      chart.update('none');
    }
  },
};

ChartJS.register(
  ...registerables,
  LineElement,
  CategoryScale,
  TimeScale,
  LinearScale,
  customCrosshair,
  zoomPlugin
);

type LineChartProps = {
  chartRef: any;
  options: any;
  allSeries: any;
  extendedColors: string[];
};

const LineChart = ({ chartRef, options, allSeries, extendedColors }: LineChartProps) => {
  const formattedLabels = getFormatedLabels({ data: allSeries });
  const userData = {
    labels: formattedLabels,
    datasets: allSeries.map((item: ChartData, index: number) => ({
      label: item.name,
      data: item.data.map(([timestamp, value]) => ({
        x: new Date(timestamp),
        y: value,
      })),
      yAxisID: index === 0 ? 'y' : `y${index - 1}`,
      backgroundColor: extendedColors[index % allSeries.length],
      borderColor: extendedColors[index % allSeries.length],
      borderWidth: 2,
      radius: 0,
      pointHoverRadius: 0,
    })),
  };

  useEffect(() => {
    if (chartRef && chartRef.current) {
      chartRef.current.canvas.style.touchAction = 'unset';
    }
  }, [chartRef]);

  return (
    <Line
      ref={chartRef as React.MutableRefObject<any>}
      data={userData}
      options={options}
      plugins={[zoomPlugin]}
      style={{ maxHeight: '400px', height: '400px' }}
    />
  );
};

export default LineChart;
