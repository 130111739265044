const CONFIRMATION_MODAL_HEADING_ADD = 'Customer Account Added';
const CONFIRMATION_MODAL_DESCRIPTION_ADD = 'You have successfully created a new Customer Account.';

const CONFIRMATION_MODAL_HEADING_EDIT = 'Customer Account Edited';
const CONFIRMATION_MODAL_DESCRIPTION_EDIT = 'You have successfully edited a Customer Account.';

const BREADCRUMBS_ROOT = 'Customer Account Management';
const BREADCRUMBS_ADD_CUSTOMER = 'Add Customer Account';
const BREADCRUMBS_EDIT_CUSTOMER = 'Edit Customer Account';

export {
  CONFIRMATION_MODAL_HEADING_ADD,
  CONFIRMATION_MODAL_DESCRIPTION_ADD,
  CONFIRMATION_MODAL_HEADING_EDIT,
  CONFIRMATION_MODAL_DESCRIPTION_EDIT,
  BREADCRUMBS_ROOT,
  BREADCRUMBS_ADD_CUSTOMER,
  BREADCRUMBS_EDIT_CUSTOMER,
};
