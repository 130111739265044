import { Box, styled } from '@mui/material';

type OverlayProps = {
  active: string;
};

const IconWrapper = styled(Box)`
  height: inherit;
  position: relative;
  transition: transform 0.3s;
  &:hover {
    transform: scale(1.15);
  }
`;

const Overlay = styled(Box)(({ active }: OverlayProps) => ({
  position: 'absolute',
  top: '0',
  left: '0',
  width: '100%',
  height: '100%',
  borderRadius: '50%',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  opacity: `${active === 'true' ? '1' : '0'}`,
  transition: 'opacity 0.3s',
}));

const menuPopoverItemStyle = { px: '20px!important', py: '8px!important' };

const iconBox = {
  cursor: 'pointer',
  height: '40px',
};

export { iconBox, Overlay, IconWrapper, menuPopoverItemStyle };
