import {
  Button,
  CardContent,
  DialogActions,
  DialogTitle,
  Divider,
  FormControlLabel,
  Stack,
  Switch,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { ArrowTopRightIcon } from 'src/assets';
import { Label } from 'src/components';
import {
  AlarmPopupCard,
  AlarmPopupDialog,
  DialogContentWithCustomScroll,
  TableContainerWithCustomScroll,
} from '../style';
import { InputHTMLAttributes, useState } from 'react';
import {
  formatSecondsToDhms,
  formatUTCDateToLocal,
  unixTimestampToDateTime,
  upperSnakeCaseToTitleCase,
} from 'src/utilities';
import { Notification } from '../../types/notification';
import { SitesService } from 'src/services/sites';
import { SiteInterface } from 'src/features/sites/types/site';
import { useSite } from 'src/contexts/siteContext/sites.context';
import { useNavigate } from 'react-router';
import { SITES_DASHBOARD_PATH } from 'src/routes/config';
import { getPriorityColor, renderTitleIcon } from '../../utils';
import { CustomPaperProps, PopupProps } from '../../types';
import {
  AlarmConditionsCellGateway,
  AlarmConditionsCellLabel,
  AlarmConditionsCellValue,
  DetailsCellLabel,
  DetailsCellValue,
  FooterStack,
  GatewayLabel,
  NotificationsCellLabel,
  NotificationsCellValue,
  NotificationsHeaderCellLabel,
  NotificationsHeaderCellValue,
} from './style';
import { AlarmsService } from 'src/services';
import { useAuthentication } from 'src/features/authentication/context';

interface CustomInputAttributes extends InputHTMLAttributes<HTMLInputElement> {
  'data-sm'?: string;
}

export const DesktopAlarmPopUp = ({
  open,
  onClose,
  customerId,
  siteId,
  alarmData,
  alarmRule,
  notifications = [],
  onAcknowledged,
  setRefreshTrigger,
}: PopupProps) => {
  const theme = useTheme();
  const { saveSite } = useSite();
  const navigate = useNavigate();
  const [acknowledged, setAcknowledged] = useState<boolean>(false);
  const { idToken } = useAuthentication();

  const onAcknowledgeAndClose = () => {
    AlarmsService.acknowledgeAlarm(customerId, siteId, alarmData.id, idToken)
      .then((response) => {
        onAcknowledged && onAcknowledged(response);
        setRefreshTrigger(true);
      })
      .catch((error) => {
        console.error(error);
      });
    setAcknowledged(false);
    onClose();
  };

  const handleGoToSiteClick = () => {
    SitesService.getById({ customerId: customerId, siteId: siteId })
      .then((data: SiteInterface) => {
        saveSite(data);
      })
      .catch((error) => {
        console.error(error);
      });
    navigate(SITES_DASHBOARD_PATH + `?customerId=${customerId}&siteId=${siteId}`);
  };

  const customSwitchProps: CustomInputAttributes = {
    'data-sm': 'acknowledge-button',
  };

  const customPaperProps: CustomPaperProps = {
    'data-sm': 'alarm-popup',
  };

  return (
    <AlarmPopupDialog
      open={open}
      onClose={onClose}
      maxWidth="lg"
      sx={{ width: '100%' }}
      PaperProps={{
        ...customPaperProps,
      }}
    >
      <DialogTitle sx={{ backgroundColor: theme.palette.background.default }}>
        <Stack flexDirection={'row'} justifyContent={'space-between'}>
          <Stack flexDirection={'row'} alignItems={'center'}>
            {renderTitleIcon(alarmData, theme)}
            <Typography variant="h6" sx={{ ml: '16px', mr: '8px' }}>
              Alarm - {upperSnakeCaseToTitleCase(alarmData?.priority || '')}
            </Typography>
            <Label
              variant="filled"
              color={getPriorityColor(alarmData?.priority, alarmData?.eventStatus)}
            >
              {alarmData?.eventStatus}
            </Label>
          </Stack>
          <Button
            variant="outlined"
            endIcon={<ArrowTopRightIcon />}
            onClick={handleGoToSiteClick}
            data-sm={'go-to-site-button'}
          >
            Go to Site
          </Button>
        </Stack>
      </DialogTitle>
      <Divider />
      <DialogContentWithCustomScroll sx={{ backgroundColor: theme.palette.background.default }}>
        <AlarmPopupCard>
          <CardContent sx={{ p: '16px!important' }}>
            <Typography variant="h6">Site Details:</Typography>
            <TableContainer sx={{ mt: '12px' }}>
              <Table>
                <TableBody>
                  <TableRow>
                    <DetailsCellLabel align="left">Site Name:</DetailsCellLabel>
                    <DetailsCellValue align="left">
                      <strong>{alarmData?.siteName}</strong>
                    </DetailsCellValue>
                  </TableRow>
                  <TableRow>
                    <DetailsCellLabel align="left">Asset:</DetailsCellLabel>
                    <DetailsCellValue align="left">
                      <strong>{alarmData?.asset?.name ?? '-'}</strong>
                    </DetailsCellValue>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </CardContent>
        </AlarmPopupCard>

        <AlarmPopupCard>
          <CardContent sx={{ p: '16px!important' }}>
            <Typography variant="h6">Event Details:</Typography>
            <TableContainer sx={{ mt: '12px' }}>
              <Table>
                <TableBody>
                  <TableRow>
                    <DetailsCellLabel align="left">Alarm Title:</DetailsCellLabel>
                    <DetailsCellValue align="left">
                      <strong>{alarmData?.title}</strong>
                    </DetailsCellValue>
                  </TableRow>
                  <TableRow>
                    <DetailsCellLabel align="left">Start (date / time):</DetailsCellLabel>
                    <DetailsCellValue align="left">
                      <strong>
                        {alarmData.startDate !== null
                          ? formatUTCDateToLocal(false, alarmData?.startDate)
                          : '-'}
                      </strong>
                    </DetailsCellValue>
                  </TableRow>
                  <TableRow>
                    <DetailsCellLabel align="left">End (date / time):</DetailsCellLabel>
                    <DetailsCellValue align="left">
                      <strong>
                        {alarmData.endDate !== null
                          ? formatUTCDateToLocal(false, alarmData?.endDate)
                          : '-'}
                      </strong>
                    </DetailsCellValue>
                  </TableRow>
                  <TableRow>
                    <DetailsCellLabel align="left">Duration:</DetailsCellLabel>
                    <DetailsCellValue align="left">
                      <strong>
                        {+alarmData.durationInSeconds > 0
                          ? formatSecondsToDhms(+alarmData.durationInSeconds)
                          : '-'}
                      </strong>
                    </DetailsCellValue>
                  </TableRow>
                  <TableRow>
                    <DetailsCellLabel align="left">Description:</DetailsCellLabel>
                    <DetailsCellValue align="left">
                      <strong>{alarmData?.description ?? '-'}</strong>
                    </DetailsCellValue>
                  </TableRow>
                  <TableRow>
                    <DetailsCellLabel align="left">
                      <FormControlLabel
                        value="end"
                        control={
                          <Switch
                            checked={alarmData?.acknowledgment ? true : acknowledged}
                            disabled={!!alarmData?.acknowledgment}
                            color="primary"
                            onChange={(event, checked) => {
                              setAcknowledged(checked);
                            }}
                            inputProps={{ ...customSwitchProps }}
                          />
                        }
                        label={
                          !!alarmData?.acknowledgment || acknowledged
                            ? 'Acknowledged'
                            : 'Acknowledge'
                        }
                        labelPlacement="end"
                      />
                    </DetailsCellLabel>
                    {alarmData?.acknowledgment ? (
                      <DetailsCellValue align="left" sx={{ verticalAlign: 'inherit' }}>
                        Acknowledged by:{' '}
                        <strong>
                          {alarmData?.acknowledgment?.firstName}{' '}
                          {alarmData?.acknowledgment?.lastName},{' '}
                          {unixTimestampToDateTime(+alarmData?.acknowledgment?.timestamp)}
                        </strong>
                      </DetailsCellValue>
                    ) : null}
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </CardContent>
        </AlarmPopupCard>

        <AlarmPopupCard>
          <CardContent sx={{ p: '16px!important' }}>
            <Typography variant="h6">Notifications:</Typography>
            <TableContainerWithCustomScroll sx={{ mt: '12px', maxHeight: '125px' }}>
              <Table>
                <TableHead sx={{ position: 'sticky', top: '0', left: '0', zIndex: '10' }}>
                  <TableRow>
                    <NotificationsHeaderCellLabel theme={theme} align="left">
                      Time
                    </NotificationsHeaderCellLabel>
                    <NotificationsHeaderCellValue theme={theme} align="left">
                      Receivers
                    </NotificationsHeaderCellValue>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {notifications?.map((notification: Notification) => (
                    <TableRow key={notification?.id ?? notification?.createdAt}>
                      <NotificationsCellLabel align="left">
                        <strong>{formatUTCDateToLocal(false, notification?.createdAt)}</strong>
                      </NotificationsCellLabel>
                      <NotificationsCellValue align="left">
                        <strong>{notification?.firstName + ' ' + notification?.lastName}</strong>
                      </NotificationsCellValue>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainerWithCustomScroll>
          </CardContent>
        </AlarmPopupCard>

        <AlarmPopupCard>
          <CardContent sx={{ p: '16px!important' }}>
            <Typography variant="h6">Alarm Conditions</Typography>
            <TableContainer sx={{ mt: '12px', overflow: 'hidden' }}>
              <Table>
                <TableBody>
                  <TableRow>
                    <AlarmConditionsCellLabel align="left">Low Limit</AlarmConditionsCellLabel>
                    <AlarmConditionsCellGateway align="left" />
                    <AlarmConditionsCellValue align="left">
                      {alarmRule?.lowLimit
                        ? `${alarmRule?.lowLimit} ${alarmRule?.tag.tagRawUnit}`
                        : '-'}
                    </AlarmConditionsCellValue>
                  </TableRow>
                  <TableRow>
                    <AlarmConditionsCellLabel align="left">High Limit</AlarmConditionsCellLabel>
                    <AlarmConditionsCellGateway align="left" />
                    <AlarmConditionsCellValue align="left">
                      {alarmRule?.highLimit
                        ? `${alarmRule?.highLimit} ${alarmRule?.tag?.tagRawUnit}`
                        : '-'}
                    </AlarmConditionsCellValue>
                  </TableRow>
                  <TableRow>
                    <AlarmConditionsCellLabel align="left">
                      {alarmRule?.tag?.tagName}
                    </AlarmConditionsCellLabel>
                    <AlarmConditionsCellGateway align="left">
                      <Tooltip
                        title={alarmData?.gateway?.name}
                        arrow
                        sx={{ backgroundColor: 'white!important' }}
                      >
                        <GatewayLabel variant="filled" color="default">
                          {alarmData?.gateway?.name}
                        </GatewayLabel>
                      </Tooltip>
                    </AlarmConditionsCellGateway>
                    <AlarmConditionsCellValue align="left">
                      {alarmData?.mainTag?.value} {alarmRule?.tag?.tagRawUnit}
                    </AlarmConditionsCellValue>
                  </TableRow>
                  {alarmRule?.additionalTag ? (
                    <TableRow>
                      <AlarmConditionsCellLabel align="left">
                        {alarmRule?.additionalTag?.additionalTagName ?? ''}
                      </AlarmConditionsCellLabel>
                      <AlarmConditionsCellGateway align="left">
                        <Tooltip
                          title={alarmData?.gateway?.name}
                          arrow
                          sx={{ backgroundColor: 'white!important' }}
                        >
                          <GatewayLabel variant="filled" color="default">
                            {alarmData?.gateway?.name}
                          </GatewayLabel>
                        </Tooltip>
                      </AlarmConditionsCellGateway>
                      <AlarmConditionsCellValue align="left">
                        {alarmRule?.additionalTag?.value ?? '-'}{' '}
                        {alarmRule?.additionalTag?.additionalTagRawUnit}
                      </AlarmConditionsCellValue>
                    </TableRow>
                  ) : (
                    ''
                  )}

                  <TableRow>
                    <AlarmConditionsCellLabel align="left">
                      Notification Delay
                    </AlarmConditionsCellLabel>
                    <AlarmConditionsCellGateway align="left" />
                    <AlarmConditionsCellValue align="left">
                      {`${alarmRule?.notificationDelay}s` || '-'}
                    </AlarmConditionsCellValue>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </CardContent>
        </AlarmPopupCard>
      </DialogContentWithCustomScroll>
      <Divider />
      <DialogActions sx={{ backgroundColor: theme.palette.background.default }}>
        <FooterStack>
          <Typography>Alarm ID: {alarmData.id}</Typography>
          {acknowledged ? (
            <Button
              onClick={onAcknowledgeAndClose}
              variant="outlined"
              data-sm="acknowledge-and-close-button"
            >
              Acknowledge & Close
            </Button>
          ) : (
            <Button onClick={onClose} variant="outlined" data-sm="close-button">
              Close
            </Button>
          )}
        </FooterStack>
      </DialogActions>
    </AlarmPopupDialog>
  );
};
