import { Box, Typography, Divider, useTheme } from '@mui/material';
import Chart from 'react-apexcharts';
import { CloseIcon } from 'src/assets';
import { ApexOptions } from 'apexcharts';
import { cardTitle } from '../style';
import {
  StyledTrendModalContent,
  trendModalContentHeader,
  chartWrapper,
  closeIconBox,
  StyledLabel,
} from './style';
import { CHART_LABEL } from '../../config';
import { NoDataAvailableBox } from '../NoDataAvailableBox';

type TrendModalContentProps = {
  title: string;
  onClose: () => void;
  options?: ApexOptions;
  series: any;
  typeChart?: 'area' | 'bar';
  unit?: string;
  noDataAvailable?: boolean;
};

export const TrendModalContent = ({
  title,
  options,
  series,
  onClose,
  typeChart,
  unit,
  noDataAvailable,
}: TrendModalContentProps) => {
  const theme = useTheme();
  const onCloseHandler = () => {
    onClose();
  };
  return (
    <StyledTrendModalContent>
      <Box sx={trendModalContentHeader}>
        <Typography variant="h6" sx={cardTitle}>
          {title}
        </Typography>
        <Box onClick={onCloseHandler} sx={closeIconBox}>
          <CloseIcon />
        </Box>
      </Box>
      <Divider />
      <Box sx={chartWrapper}>
        {noDataAvailable ? (
          <NoDataAvailableBox />
        ) : (
          <Chart options={options} series={series} type={typeChart} width="100%" height="100%" />
        )}

        <StyledLabel theme={theme}>
          {unit ? unit : typeChart === 'bar' ? CHART_LABEL : ''}
        </StyledLabel>
      </Box>
    </StyledTrendModalContent>
  );
};
