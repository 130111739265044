import { useEffect, useState } from 'react';
import { Checkbox } from '@mui/material';
import { CheckmarkCircleEmptyIcon, CheckmarkCircleFilledIcon } from 'src/assets';

interface AlarmCheckboxProps {
  acknowledgedBy: string | null;
}

const label = { inputProps: { 'aria-label': 'Checkbox' } };

export const AlarmCheckbox: React.FC<AlarmCheckboxProps> = ({ acknowledgedBy }) => {
  const isAcknowledged = acknowledgedBy !== null && acknowledgedBy !== 'Unacknowledged';
  const [checked, setChecked] = useState<boolean>(isAcknowledged);

  useEffect(() => {
    setChecked(isAcknowledged);
  }, [isAcknowledged, setChecked]);
  return (
    <Checkbox
      size="small"
      icon={<CheckmarkCircleEmptyIcon />}
      checkedIcon={<CheckmarkCircleFilledIcon fill="#36B37E" />}
      disableRipple
      checked={checked}
      readOnly
      {...label}
      color={isAcknowledged ? 'success' : 'default'}
      sx={{
        marginRight: '5px',
      }}
    />
  );
};
