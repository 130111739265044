import { Box, Button, IconButton, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { ListIcon } from 'src/assets';
import { StyledHeader } from './style';
import { useResponsive } from 'src/hooks';
import { Link } from 'react-router-dom';
import { TENANT_SWITCH_PATH } from 'src/routes/config';
import { useMapFilters } from 'src/contexts/siteMapFilterContext';
import { useSite } from 'src/contexts/siteContext';
import { UserPopoverBtn } from 'src/components';
import { ExtendedHeader } from '../extendedHeader';
import { ExtendedNavItemTypes } from '../extendedHeader/types';

import SiteHeaderWrapper from 'src/features/sites/components/site-header/SiteHeaderWrapper';
import { useCallback } from 'react';
type HeaderProps = {
  title?: string;
  showButton?: boolean;
  isTransparent?: boolean;
  onTitleChange?: (title: string) => void;
  isTitleEditable?: boolean;
  extendedHeaderTitle?: string;
  isSiteHeader?: boolean;
  activeExtendedNavItem?: string;
  isExtendedHeader?: boolean;
  extendedNavItems?: ExtendedNavItemTypes[];
  defaultExtendedHeaderTitle?: string;
  onNavigateBackClick?: () => void;
};

export const Header = ({
  title,
  showButton = false,
  onTitleChange,
  isSiteHeader = false,
  activeExtendedNavItem,
  onNavigateBackClick,
  isTransparent = false,
  extendedHeaderTitle,
  isTitleEditable = true,
  isExtendedHeader = false,
  extendedNavItems,
  defaultExtendedHeaderTitle,
}: HeaderProps) => {
  const theme = useTheme();

  const { getSite } = useSite();
  const site = getSite();
  const siteExists = site && Object.values(site).length;
  const isMobile = useResponsive('down', 'sm');
  const onTitleChangeCallback = useCallback(
    (value: any) => {
      onTitleChange && onTitleChange(value);
    },
    [onTitleChange]
  );

  const { updateMapFilters } = useMapFilters();

  const handleAccountSwitch = () => {
    updateMapFilters('');
  };

  if (siteExists && isSiteHeader) {
    return <SiteHeaderWrapper site={site} />;
  }

  if (isExtendedHeader) {
    return (
      <ExtendedHeader
        onNavigateBackClick={onNavigateBackClick}
        extendedHeaderTitle={extendedHeaderTitle}
        defaultExtendedHeaderTitle={defaultExtendedHeaderTitle}
        activeExtendedNavItem={activeExtendedNavItem}
        key="header"
        isTitleEditable={isTitleEditable}
        onTitleChange={onTitleChangeCallback}
        isMobile={isMobile}
        isTransparent={isTransparent}
        extendedNavItems={extendedNavItems}
      />
    );
  }

  return (
    <StyledHeader
      isTransparent={isTransparent}
      component="header"
      data-sm="layout:header"
      theme={theme}
      isMobile={isMobile}
    >
      <Stack
        flexDirection={'row'}
        justifyContent={title ? 'space-between' : 'flex-end'}
        alignItems={'center'}
        sx={{ width: '100%' }}
      >
        {title ? (
          <Box component="div">
            <Typography variant="h4" component="h1" data-sm="title">
              {title}
            </Typography>
          </Box>
        ) : null}
        <Stack flexDirection={'row'}>
          {showButton &&
            (isMobile ? (
              <IconButton to={TENANT_SWITCH_PATH} component={Link} onClick={handleAccountSwitch}>
                <ListIcon width={24} height={24} />
              </IconButton>
            ) : (
              <Button
                variant="outlined"
                startIcon={<ListIcon width={18} height={18} />}
                sx={{ mr: '40px' }}
                to={TENANT_SWITCH_PATH}
                component={Link}
                onClick={handleAccountSwitch}
              >
                Switch Account
              </Button>
            ))}
          <UserPopoverBtn isTransparent={isTransparent} />
        </Stack>
      </Stack>
    </StyledHeader>
  );
};
