import React, { useState, useContext, useEffect, useCallback } from 'react';
import { ThemeOptions } from '@mui/material';
import { useLocation } from 'react-router';
import { Palette } from 'src/features/theme/palette/types';
import { Theme } from 'src/features/theme/type';
import { ThemeTypeContextTypes, ThemeTypeProviderPropsTypes } from './type';
import { ThemeService } from 'src/services/themes';
import { useCustomer } from '../customerContext/customer.context';

const ThemeTypeContext = React.createContext<ThemeTypeContextTypes>({} as ThemeTypeContextTypes);

export const useThemeType = () => useContext(ThemeTypeContext);

const defaultAppTheme: Palette = {
  lighter: '#CDF0F0',
  light: '#69D3D1',
  main: '#05B6B2',
  dark: '#398599',
  darker: '#005249',
  contrastText: '#fff',
};
const defaultSidebarTheme: ThemeOptions['sidebar'] = {
  background: '#21374E',
  unselectedText: '#BCC7D1',
  selectedSiteBackground: '#2B4764',
  selectedNavItemBackground: '#3A5776',
  alarmNotificationColor: '#FF4842',
};

export const ThemeTypeProvider = ({ children }: ThemeTypeProviderPropsTypes) => {
  const [themeType, setThemeType] = useState<'light' | 'dark'>('light');

  const toggleThemeType = () => {
    setThemeType((prevValue) => (prevValue === 'light' ? 'dark' : 'light'));
  };

  const [customerTheme, setCustomerTheme] = useState<Theme | null | {}>({
    primary: defaultAppTheme,
    sidebar: defaultSidebarTheme,
  });
  const [customerThemeLoaded, setCustomerThemeLoaded] = useState<boolean>(false);

  const location = useLocation();
  const customerId = location.search.slice(12, 38);
  const { themeIds } = useCustomer(customerId);

  const fetchTheme = useCallback(async () => {
    if (!customerId || themeIds?.value.activeThemeId === '' || themeIds?.loading) {
      const themeData = await ThemeService.getById('1', '1', false);
      setCustomerTheme({
        id: themeData.id,
        customerId: themeData.customerId,
        themeName: themeData.themeName,
        primary: themeData.primary,
        sidebar: themeData.sidebar,
      });
      setCustomerThemeLoaded(true);
      return;
    }

    try {
      if (
        customerId &&
        themeIds?.value &&
        !themeIds.loading &&
        themeIds.value.activeThemeId !== undefined
      ) {
        const themeData = await ThemeService.getById(
          customerId,
          themeIds.value.activeThemeId,
          false
        );
        setCustomerTheme({
          id: themeData.id,
          customerId: themeData.customerId,
          themeName: themeData.themeName,
          primary: themeData.primary,
          sidebar: themeData.sidebar,
        });
        setCustomerThemeLoaded(true);
      }
    } catch (error) {
      console.error(error);
    }
  }, [customerId, themeIds]);

  useEffect(() => {
    fetchTheme();
  }, [fetchTheme]);

  return (
    <ThemeTypeContext.Provider
      value={{
        themeType,
        toggleThemeType,
        customerTheme,
        customerThemeLoaded,
      }}
    >
      {children}
    </ThemeTypeContext.Provider>
  );
};
