import { createPortal } from 'react-dom';
import { FormWrapperBackdrop } from '../styles';
import { Box, Button, Stack, SvgIcon, Typography, useTheme } from '@mui/material';
import useQueryParamsActions from 'src/hooks/useQueryParamsActions';
import DownloadIcon from '@mui/icons-material/Download';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import { FunctionComponent, SyntheticEvent, useEffect, useState } from 'react';
import { useAuthentication } from 'src/features/authentication/context';
import { LogsService } from 'src/services/logs';
import defaultContentRenderer from 'src/components/streametric/image-preview-box/utils/defaultContentRenderer';
import { CustomWaterLoading } from 'src/components';

type OldAttachment = {
  id: string;
  name: string;
  order: number;
  mime_type: string;
};
type NewAttachment = {
  id: string;
  order: number;
  name: string;
  file: File;
};

interface IAttachmentsPreviewBoxProps {
  content: Array<OldAttachment | NewAttachment>;
  noDownload?: boolean;
  attachment: string;
  log: string;
  onClose: (event: SyntheticEvent) => void;
}

const AttachmentsPreviewBox: FunctionComponent<IAttachmentsPreviewBoxProps> = ({
  content,
  attachment,
  noDownload = false,
  log,
  onClose,
}) => {
  const { append } = useQueryParamsActions();
  const theme = useTheme();
  const [attachmentMetadata, setAttachmentMetadata] = useState<
    NewAttachment | OldAttachment | null
  >(null);

  const [file, setFile] = useState<{
    loaded: boolean;
    data: File | null;
  }>({
    loaded: false,
    data: null,
  });
  const {
    siteId: { value: siteId },
    customerId: { value: customerId },
  } = useAuthentication();

  const currentOrder = attachmentMetadata?.order || 1;

  useEffect(() => {
    const attachmentMeta = content.find((el) => el.id === attachment) || null;
    setAttachmentMetadata(attachmentMeta);

    setFile({ loaded: true, data: (attachmentMeta as any)?.file });
  }, [setAttachmentMetadata, setFile, content, attachment]);

  useEffect(() => {
    function isUUIDv4(id: string) {
      const uuidv4Regex = /^[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
      return uuidv4Regex.test(id);
    }
    if (attachment && log && siteId && customerId && !isUUIDv4(attachment)) {
      (async () => {
        setFile({ loaded: false, data: null });
        const file = await LogsService.getAttachment(log, attachment, siteId, customerId);
        setFile({ loaded: true, data: file });
      })();
    }
  }, [attachment, log, siteId, customerId, setFile]);

  const downloadFile = (event: SyntheticEvent) => {
    event.stopPropagation();
    if (!file.data) return;
    const link = document.createElement('a');
    link.href = URL.createObjectURL(file.data);
    link.download = attachmentMetadata?.name || '';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const portal = createPortal(
    <>
      <FormWrapperBackdrop sx={{ zIndex: 1300 }} onClick={onClose} />

      <Box
        onClick={(event) => {
          event.stopPropagation();
        }}
        sx={{
          backgroundColor: theme.palette.background.default,
          maxWidth: '342px',
          maxHeight: '400px',
          width: '100%',
          height: '100%',
          borderRadius: '16px',
          position: 'fixed',
          zIndex: 1301,
          boxShadow: ' -40px 40px 80px -8px rgba(145, 158, 171, 0.24)',
          left: '50%',
          top: '50%',
          transform: 'translate(-50%,-50%)',
          [theme.breakpoints.down('md')]: {
            width: 'calc(100% - 32px)',
          },
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: '12px',
            left: '50%',
            transform: 'translateX(-50%)',
          }}
        >
          {currentOrder}&nbsp;/&nbsp;{content.length}
        </Box>
        {file.loaded && file.data ? (
          defaultContentRenderer(file.data, theme, attachmentMetadata?.name)
        ) : (
          <Stack sx={{ width: '100%', height: '100%' }} justifyContent="center" alignItems="center">
            <CustomWaterLoading />
          </Stack>
        )}

        {!noDownload && (
          <Button
            variant="contained"
            onClick={downloadFile}
            sx={{
              bottom: '12px',
              left: '50%',
              transform: 'translateX(-50%)',
              position: 'absolute',
            }}
          >
            <Stack alignItems="center" flexDirection="row">
              <SvgIcon sx={{ marginRight: '8px' }} fontSize="small" component={DownloadIcon} />
              <Typography variant="body1">Download</Typography>
            </Stack>
          </Button>
        )}

        <Stack
          alignItems="center"
          onClick={(event) => {
            event.stopPropagation();
            if (!attachmentMetadata) return;
            const prevAttachment = content.find((el) => el.order === attachmentMetadata.order + 1);
            if (!prevAttachment) return;
            append('attachment', prevAttachment.id);
          }}
          justifyContent="center"
          sx={{
            position: 'absolute',
            top: '0',
            cursor: 'pointer',
            right: '0px',
            width: '40px',
            height: '100%',
            pointerEvents:
              !attachmentMetadata || !content[attachmentMetadata.order] || !file.loaded
                ? 'none'
                : 'initial',
            opacity:
              !attachmentMetadata || !content[attachmentMetadata.order] || !file.loaded
                ? '0.5'
                : 'initial',
          }}
        >
          <SvgIcon component={KeyboardArrowRight} />
        </Stack>
        <Stack
          onClick={(event) => {
            event.stopPropagation();
            if (!attachmentMetadata) return;
            const prevAttachment = content.find((el) => el.order === attachmentMetadata.order - 1);

            if (!prevAttachment) return;
            append('attachment', prevAttachment.id);
          }}
          alignItems="center"
          justifyContent="center"
          sx={{
            position: 'absolute',
            top: '0',
            left: '0px',
            cursor: 'pointer',

            width: '40px',
            height: '100%',
            pointerEvents: attachmentMetadata?.order === 1 || !file.loaded ? 'none' : 'initial',
            opacity: attachmentMetadata?.order === 1 || !file.loaded ? '0.5' : 'initial',
          }}
        >
          <SvgIcon component={KeyboardArrowLeft} />
        </Stack>
      </Box>
    </>,
    document.body
  );
  return <>{portal}</>;
};

export default AttachmentsPreviewBox;
