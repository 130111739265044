import { Box, Theme, styled } from '@mui/material';

type styleType = {
  theme: Theme;
};
const StyledBox = styled(Box)(({ theme }: styleType) => ({
  maxWidth: '100%',
  width: '450px',
  padding: ' 2rem 1rem',
  margin: '0 auto',
  backgroundColor: theme.palette.background.default,
}));
const textBox = {
  display: 'flex',
  justifyContent: 'center',
  marginTop: '2rem',
};

const btnContainer = {
  display: 'flex',
  justifyContent: 'flex-end',
  marginTop: '2rem',
};

const noBtn = {
  marginRight: '0.8rem',
  maxHeight: '36px',
};

const yesBtn = {
  maxHeight: '36px',
  '@media (max-width:600px)': {
    minWidth: '135px',
  },
};

export { StyledBox, textBox, noBtn, yesBtn, btnContainer };
