import React, { useEffect, useState } from 'react';
import { Box, IconButton, Stack, useTheme } from '@mui/material';
import { Link } from 'react-router-dom';
import { ArrowBackBox, SwitchAccountBtn } from 'src/components';
import { HamburgerIcon, ListIcon } from 'src/assets';
import { TENANT_SWITCH_PATH } from 'src/routes/config';
import { StyledExtendedHeader, headerTop, rightSide, extendedNavList } from './style';
import { ExtendedNavItemTypes } from './types';
import { useMapFilters } from 'src/contexts/siteMapFilterContext';
import { DisplayTitleBox, EditTitleBox, ExtendedNavItem } from './components';
import UserAction from 'src/components/streametric/user-action/UserAction';
import { useSidebarToggleContext } from '../appLayout/sidebarToggle.context';
import { useResponsive } from 'src/hooks';
import EditTitleDialog from './components/editTitleBox/EditTitleDialog';
import { useBlockNavigation } from 'src/routes/elements/ReportFlow/BuildReportsElement/blockNavigationContext';
import { confirmNavigationProceed } from 'src/routes/elements/ReportFlow/BuildReportsElement/confirmNavigationProceed';

type Props = {
  extendedNavItems?: ExtendedNavItemTypes[];
  isMobile: boolean;
  showButton?: boolean;
  activeExtendedNavItem?: string;
  isTitleEditable?: boolean;
  isTransparent: boolean;
  extendedHeaderTitle?: string;
  onNavigateBackClick?: () => void;
  onTitleChange?: (title: string) => void;
  defaultExtendedHeaderTitle?: string;
};

export const ExtendedHeader = ({
  extendedNavItems,
  isMobile,
  onTitleChange,
  isTitleEditable = true,
  extendedHeaderTitle,
  activeExtendedNavItem,
  onNavigateBackClick,
  defaultExtendedHeaderTitle = '',
}: Props) => {
  const { onToggle } = useSidebarToggleContext();
  const theme = useTheme();
  const iconSize = 22;
  const isDesktop = useResponsive('up', 'md');
  const greyColor = theme.palette.grey[500];
  const greenColor = theme.palette.success.main;

  const defaultActiveItem = extendedNavItems
    ? activeExtendedNavItem
      ? activeExtendedNavItem
      : extendedNavItems[0].title
    : '';

  const [activeItem, setActiveItem] = useState<string>(defaultActiveItem);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [title, setTitle] = useState<string>(extendedHeaderTitle || defaultExtendedHeaderTitle);

  const onSetActiveItemHandler = (newActiveItem: string) => {
    setActiveItem(newActiveItem);
  };

  useEffect(() => {
    if (extendedHeaderTitle) {
      setTitle(extendedHeaderTitle);
    }
  }, [extendedHeaderTitle]);

  const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(event.target.value);
  };

  const toggleEditHandler = (title: string | null) => {
    if (isEditing && onTitleChange && title) onTitleChange(title);
    setIsEditing((prevState) => !prevState);
  };

  const { updateMapFilters } = useMapFilters();

  const blockNavigation = useBlockNavigation();

  const handleAccountSwitch = (event: any) => {
    let shouldBlockNavigation = false;
    if (blockNavigation && blockNavigation[0]) shouldBlockNavigation = true;
    if (shouldBlockNavigation) {
      const confirmed = confirmNavigationProceed();
      if (!confirmed) {
        event.preventDefault();
      } else {
        updateMapFilters('');
      }
    } else {
      updateMapFilters('');
    }
  };
  const onCloseEditMode = () => {
    setIsEditing(false);
    //Set title to the default value
    setTitle(extendedHeaderTitle || defaultExtendedHeaderTitle);
  };

  return (
    <StyledExtendedHeader component="header">
      <Box sx={{ flex: '1' }}>
        <Box sx={headerTop}>
          <Stack flex="1" flexDirection="row" gap="10px">
            {isMobile ? (
              <Box
                sx={{
                  display: 'flex',

                  alignItems: 'center',
                }}
                alignItems="center"
                onClick={onToggle}
                data-sm="side-bar-open-new"
              >
                <HamburgerIcon fill={theme.palette.text.secondary} />
              </Box>
            ) : null}
            <ArrowBackBox onClick={() => (onNavigateBackClick ? onNavigateBackClick() : {})} />
            <EditTitleDialog
              value={title}
              onClose={() => {
                setIsEditing(false);
              }}
              onSubmit={(val) => {
                setIsEditing(false);
                onTitleChange && onTitleChange(val);
              }}
              open={isEditing && !isDesktop}
            />
            {isEditing && isTitleEditable && isDesktop ? (
              <EditTitleBox
                value={title}
                onChangeHandler={onChangeHandler}
                iconSize={iconSize}
                greenColor={greenColor}
                onCloseEditMode={onCloseEditMode}
                toggleEditHandler={toggleEditHandler}
              />
            ) : (
              <Box sx={{ position: 'relative', flex: '1', overflow: 'hidden' }}>
                <DisplayTitleBox
                  isMobile={isMobile}
                  isTitleEditable={isTitleEditable}
                  iconSize={iconSize}
                  isDesktop={isDesktop}
                  greyColor={greyColor}
                  title={title}
                  toggleEditHandler={toggleEditHandler}
                />
              </Box>
            )}
          </Stack>
          <Box sx={rightSide}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
              {isMobile ? (
                <IconButton to={TENANT_SWITCH_PATH} component={Link} onClick={handleAccountSwitch}>
                  <ListIcon width={24} height={24} />
                </IconButton>
              ) : (
                <SwitchAccountBtn handleAccountSwitch={handleAccountSwitch} />
              )}
              <UserAction />
            </Box>
          </Box>
        </Box>
        <Box sx={extendedNavList}>
          {extendedNavItems?.map(({ title, url, icon }) => (
            <ExtendedNavItem
              key={title}
              title={title}
              url={url}
              icon={icon}
              greyColor={greyColor}
              activeItem={activeItem}
              onSetActiveItemHandler={onSetActiveItemHandler}
            />
          ))}
        </Box>
      </Box>
    </StyledExtendedHeader>
  );
};
