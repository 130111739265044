import { useEffect, useState } from 'react';
import { worker } from '../../../../app.worker';
import WebWorker from 'src/WebWorker';
import { ChartItemType, MainObjectType, SelectedTagTypes, TimestampsTypes } from './types';

type Props = {
  timestamps: TimestampsTypes;
  selectedTags: SelectedTagTypes[];
  startDate: string;
  endDate: string;
  aggregationTypePayload: 'two_hour' | 'five_min' | 'thirty_min' | null;
};

export const useChartData = ({
  timestamps,
  selectedTags,
  startDate,
  endDate,
  aggregationTypePayload,
}: Props) => {
  const [data, setData] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (!selectedTags || selectedTags.length === 0) return;

    console.log('useEffect start');

    setData([]);
    setIsLoading(true);
    let completedRequests = 0;

    const webWorker = new WebWorker(worker);
    console.log(webWorker, 'Provera new WebWorkera');

    webWorker.onmessage = (e: MessageEvent) => {
      const { status, error } = e.data;
      console.log('on message web worker');

      if (status === 'success') {
        setData((prevState: any) => [...prevState, e.data.response]);
        console.log('set data web worker');
      } else {
        console.error(error);
      }
      completedRequests++;
      if (completedRequests === selectedTags.length) {
        setIsLoading(false);
      }
    };

    selectedTags.forEach((tag) => {
      if (tag?.gatewayId) {
        webWorker.postMessage({
          gatewayId: tag?.gatewayId,
          startDate: timestamps.fromDate ? startDate : null,
          endDate: timestamps.toDate ? endDate : null,
          measurements: tag?.inputId,
          aggregationType: aggregationTypePayload,
        });
      }
    });

    return () => {
      webWorker.terminate();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timestamps, selectedTags, aggregationTypePayload]);

  const formatedNewData = data.map((item: ChartItemType) => {
    const dataObj = Object.entries(item?.data);
    const data = dataObj[0];
    const tagId = data[0];
    const valuesObj: MainObjectType = data[1];
    // eslint-disable-next-line prefer-destructuring
    const values = valuesObj.values;

    return {
      tagId: tagId,
      values: values,
    };
  });

  return { data: formatedNewData, isLoading };
};
