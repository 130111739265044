import { useAuthentication } from 'src/features/authentication/context';
import { Protected } from 'src/features/protected-route/components';
import { AppLayout } from 'src/layouts';
import { EditCustomerAccountPage } from '../../pages';
import { ACCOUNT_ISSUE_PATH, EDIT_CUSTOMER_ACCOUNT_HEADER, LOGIN_PATH } from '../../config';
import { NAV_ITEMS_HEADER_HOME_PAGE } from 'src/features';
import { ElementProps } from '../../types/element';
import { LoadingElement } from '../LoadingElement';

export const CustomerEditAccountElement = ({ isSuperAdmin }: ElementProps) => {
  const { loggedIn } = useAuthentication();

  const getUniqueRedirectPath = () => {
    if (!loggedIn.value) return LOGIN_PATH;
    if (!isSuperAdmin) return ACCOUNT_ISSUE_PATH + '?reason=Access Denied';
  };

  const CustomerEditAccountRouteElement = () => (
    <Protected
      isAuthenticated={loggedIn.value}
      isAuthorized={isSuperAdmin}
      uniqueRedirectPath={getUniqueRedirectPath()}
    >
      <AppLayout
        headerTitle={EDIT_CUSTOMER_ACCOUNT_HEADER}
        headerNavItems={NAV_ITEMS_HEADER_HOME_PAGE}
        isCustomerAccountPage={true}
        isSuperAdmin={isSuperAdmin}
      >
        <EditCustomerAccountPage />
      </AppLayout>
    </Protected>
  );

  const getCustomerEditAccountElement = () => {
    if (!loggedIn.loaded) {
      return <LoadingElement />;
    }

    return <CustomerEditAccountRouteElement />;
  };

  return getCustomerEditAccountElement();
};
