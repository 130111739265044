import { IconsTypesProps } from './types';

export const MapPopupCriticalIcon = ({ width = 24, height = 24 }: IconsTypesProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-testid="MapPopupCriticalIcon"
  >
    <path
      d="M14.2479 0.134766H6.86418C6.57403 0.134766 6.28389 0.254766 6.08379 0.454766L0.861176 5.66477C0.661076 5.86477 0.541016 6.15477 0.541016 6.44477V13.8148C0.541016 14.1148 0.661076 14.3948 0.861176 14.6048L6.06378 19.8048C6.27388 20.0148 6.56403 20.1348 6.85417 20.1348H14.2279C14.528 20.1348 14.8081 20.0148 15.0183 19.8148L20.2209 14.6148C20.3209 14.5148 20.4109 14.3848 20.461 14.2548C20.521 14.1148 20.541 13.9748 20.541 13.8248V6.44477C20.541 6.14477 20.421 5.86477 20.2209 5.65477L15.0183 0.454766C14.8182 0.254766 14.528 0.134766 14.2379 0.134766H14.2479ZM10.556 16.0248C9.75562 16.0248 9.1053 15.3848 9.1053 14.5848C9.1053 13.7848 9.74562 13.1448 10.556 13.1448C11.3664 13.1448 12.0067 13.7848 12.0067 14.5848C12.0067 15.3848 11.3664 16.0248 10.556 16.0248ZM10.556 11.2448C9.94572 11.2448 9.44547 10.7448 9.44547 10.1348V5.69477C9.44547 5.08477 9.94572 4.58477 10.556 4.58477C11.1663 4.58477 11.6666 5.08477 11.6666 5.69477V10.1348C11.6666 10.7448 11.1663 11.2448 10.556 11.2448Z"
      fill="#FF4842"
    />
  </svg>
);
