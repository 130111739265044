import { MapPopupCriticalIcon } from 'src/assets/icons/MapPopupCriticalIcon';
import { MapPopupInfoIcon } from 'src/assets/icons/MapPopupInfoIcon';
import { MapPopupWarningIcon } from 'src/assets/icons/MapPopupWarningIcon';
import { AlarmPriorityType } from 'src/types/alarm-rules';

const getIcon = (alarmPriority: AlarmPriorityType | undefined) => {
  switch (alarmPriority) {
    case 'Info':
      return <MapPopupInfoIcon width={15} height={15} />;
    case 'Warning':
      return <MapPopupWarningIcon width={15} height={15} />;
    case 'Critical':
      return <MapPopupCriticalIcon width={15} height={15} />;
    default:
      return '';
  }
};

export const MapPopupIndicator = ({
  alarmPriority,
}: {
  alarmPriority: AlarmPriorityType | undefined;
}) => <>{getIcon(alarmPriority)}</>;

export default MapPopupIndicator;
