export const formatNumberFromTheDevice = (value: string) => {
  let number = parseFloat(value);

  if (number % 1 === 0) {
    return number;
  } else {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    let [integerPart, decimalPart] = value?.split('.');
    if (decimalPart === '0') {
      return Math.round(number);
    } else {
      return number;
    }
  }
};
