import { useFormContext, Controller, ValidationRule } from 'react-hook-form';
import { TextField, TextFieldProps } from '@mui/material';
import { forwardRef, useState } from 'react';

type Props = TextFieldProps & {
  name: string;
  testId: string;
  rules?: { [key: string]: ValidationRule | ValidationRule[] };
  customHelperText?: string;
};

export const RHFTextField = forwardRef(
  (
    {
      name,
      testId,
      rules,
      customHelperText = '',
      onFocus,
      InputProps,
      inputProps: additionalInputProps = {},
      ...other
    }: Props,
    ref
  ) => {
    const { control } = useFormContext();
    const [isAutoFill, setIsAutoFill] = useState(false);

    const mergedInputProps = {
      ...additionalInputProps,
      'data-sm': testId,
      'data-testid': testId,
    };

    return (
      <Controller
        name={name}
        control={control}
        rules={rules}
        defaultValue=""
        render={({ field, fieldState: { error } }) => (
          <TextField
            {...field}
            inputRef={ref}
            inputProps={mergedInputProps}
            InputLabelProps={{ shrink: isAutoFill || undefined }}
            InputProps={{
              ...InputProps,
              onAnimationStart: (e: React.AnimationEvent<HTMLDivElement>) => {
                e.animationName === 'mui-auto-fill' && setIsAutoFill(true);
              },
              onAnimationEnd: (e: React.AnimationEvent<HTMLDivElement>) =>
                e.animationName === 'mui-auto-fill-cancel' && setIsAutoFill(false),
              onFocus: (e) => {
                setIsAutoFill(false);
                if (onFocus) {
                  onFocus(e);
                }
              },
            }}
            fullWidth
            value={typeof field.value === 'number' && field.value === 0 ? '' : field.value}
            error={!!error}
            helperText={
              <span style={{ position: 'absolute', left: '4px', bottom: '-20px' }}>
                {error?.message || customHelperText}
              </span>
            }
            {...other}
          />
        )}
      />
    );
  }
);
