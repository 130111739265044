import { LogItem } from 'src/features/logs/types';

const formatLogToFormData = (log: LogItem, noComments = false) => {
  const formFields: any = {};
  formFields.date_from = log.date;
  formFields.date_to = log.endDate;
  if (!noComments) {
    formFields.comments = log.comments;
  }
  if (log.assetIds) {
    formFields.assets = log.assetIds;
  }
  log.elements.forEach((element: any) => {
    if (element.type === 'selection' && element.isMulti) {
      formFields[element.id] = element.value ?? [];
    } else if (element.id === 'assets') {
      formFields[element.id] = log.assetIds;
    } else if (element.type === 'attachment') {
      formFields[element.id + '_attachment'] = element.value;
    } else {
      if (element.type === 'section') {
        formFields[element.id] = {};
        element.fields.forEach((field: any) => {
          if (field.type === 'selection' && field.isMulti) {
            formFields[element.id][field.id] = field.value ?? [];
          } else if (field.id === 'assets') {
            formFields[element.id][field.id] = log.assetIds;
          } else if (field.type === 'attachment') {
            formFields[element.id][field.id + '_attachment'] = field.value;
          } else {
            formFields[element.id][field.id] = field.value;
          }
        });
      } else {
        formFields[element.id] = element.value ? element.value + '' : '';
      }
    }
  });

  return formFields;
};

export default formatLogToFormData;
