import { IconsTypesProps } from './types';
import { CURRENT_COLOR } from 'src/config';

export const LockIcon = ({ fill, width = 12, height = 15 }: IconsTypesProps) => {
  const color = fill ?? CURRENT_COLOR;

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 12 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.00008 14.6667C1.63342 14.6667 1.31942 14.536 1.05808 14.2747C0.796749 14.0134 0.666305 13.6996 0.666749 13.3334V6.66669C0.666749 6.30002 0.797416 5.98602 1.05875 5.72469C1.32008 5.46335 1.63386 5.33291 2.00008 5.33335H2.66675V4.00002C2.66675 3.0778 2.99186 2.29158 3.64208 1.64135C4.29231 0.991132 5.0783 0.666243 6.00008 0.666687C6.92231 0.666687 7.70853 0.991799 8.35875 1.64202C9.00897 2.29224 9.33386 3.07824 9.33342 4.00002V5.33335H10.0001C10.3667 5.33335 10.6807 5.46402 10.9421 5.72535C11.2034 5.98669 11.3339 6.30047 11.3334 6.66669V13.3334C11.3334 13.7 11.2028 14.014 10.9414 14.2754C10.6801 14.5367 10.3663 14.6671 10.0001 14.6667H2.00008ZM6.00008 11.3334C6.36675 11.3334 6.68075 11.2027 6.94208 10.9414C7.20342 10.68 7.33386 10.3662 7.33342 10C7.33342 9.63335 7.20275 9.31935 6.94142 9.05802C6.68008 8.79669 6.3663 8.66624 6.00008 8.66669C5.63342 8.66669 5.31942 8.79735 5.05808 9.05869C4.79675 9.32002 4.66631 9.6338 4.66675 10C4.66675 10.3667 4.79742 10.6807 5.05875 10.942C5.32008 11.2034 5.63386 11.3338 6.00008 11.3334ZM4.00008 5.33335H8.00008V4.00002C8.00008 3.44447 7.80564 2.97224 7.41675 2.58335C7.02786 2.19447 6.55564 2.00002 6.00008 2.00002C5.44453 2.00002 4.9723 2.19447 4.58342 2.58335C4.19453 2.97224 4.00008 3.44447 4.00008 4.00002V5.33335Z"
        fill={color}
      />
    </svg>
  );
};
