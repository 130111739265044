import { Theme, styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';

type StylesTypes = {
  theme?: Theme;
  isMobile: boolean;
  isLiveData?: boolean;
};

const StyledTagItem = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isMobile',
})(({ theme, isMobile, isLiveData }: StylesTypes) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  minWidth: isMobile ? '142px' : '220px',
  borderRight: isMobile || isLiveData ? '0' : `1px solid ${theme?.palette.grey[300]}`,
  padding: isMobile ? '5px 6px 0 0' : '5px 12px 0 0',
  gap: isMobile ? '5px' : '20px',
  whiteSpace: isLiveData ? 'nowrap' : '',
  marginTop: isLiveData ? '12px' : '',
}));

const StyledItemText = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'isMobile',
})(({ isMobile, isLiveData }: StylesTypes) => ({
  fontSize: '14px',
  maxWidth: isMobile ? '80px' : '140px',
  wordWrap: 'break-word',
  fontWeight: isLiveData ? 'bold' : '',
}));

export { StyledTagItem, StyledItemText };
