import { IconButton, Stack, SvgIcon, useTheme } from '@mui/material';
import { FunctionComponent, PropsWithChildren } from 'react';
import DragItem from 'src/components/streametric/drag/DragItem';
import { columnStyles } from '../../style';
import { useHtmlElement } from 'src/hooks';
import { MoreVert } from '@mui/icons-material';
import { MenuPopover } from 'src/components';

const DraggableColumn: FunctionComponent<
  PropsWithChildren<{ index: number; id: string | number; renderContextItems: () => JSX.Element }>
> = ({ index, id, children, renderContextItems }) => {
  const theme = useTheme();
  const { htmlElement, addHtmlElement, removeHtmlElement } = useHtmlElement();
  return (
    <DragItem
      dragHandleProps={{
        sx: {
          color: theme.palette.grey[500],
        },
      }}
      index={index}
      id={`${id}`}
      sx={columnStyles(theme)}
    >
      <Stack alignItems="center" flexDirection="row" justifyContent="space-between" flex="1">
        {children}
        <IconButton
          onClick={(event) => {
            addHtmlElement(event.currentTarget);
          }}
        >
          <SvgIcon sx={{ color: theme.palette.grey[500] }} component={MoreVert} />
        </IconButton>
      </Stack>
      <MenuPopover
        onClose={() => {
          removeHtmlElement();
        }}
        arrow="top-left"
        open={htmlElement}
      >
        {renderContextItems()}
      </MenuPopover>
    </DragItem>
  );
};

export default DraggableColumn;
