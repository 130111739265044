import { styled } from '@mui/material/styles';
import { Button } from '@mui/material';

type StylesTypes = {
  isRunMode: boolean;
};

const StyledCardPumpStatus = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'isRunMode',
})(({ isRunMode }: StylesTypes) => ({
  isplay: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  height: '40px',
  background: isRunMode ? 'rgba(5, 182, 178, 0.08)' : 'rgba(145, 158, 171, 0.08)',
  borderRadius: '8px',
  fontFamily: 'Public Sans',
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '14px',
  lineHeight: '24px',
  width: '40px',
  minWidth: '40px',
  color: isRunMode ? '#05B6B2' : '#212B36',
  margin: '0 1rem',
  '&:hover': {
    cursor: 'pointer',
    background: isRunMode ? 'rgba(5, 182, 178, 0.08)' : 'rgba(145, 158, 171, 0.08)',
    boxShadow: isRunMode
      ? '0 8px 16px 0 rgba(5, 182, 178, 0.24)'
      : '0 8px 16px 0 rgba(145, 158, 171, 0.08)',
  },
}));

const pump = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
};

const modeValue = {
  fontWeight: 'bold',
};

export { pump, StyledCardPumpStatus, modeValue };
