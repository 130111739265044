import { Navigate } from 'react-router-dom';
import { ACCOUNT_ISSUE_PATH, LOGIN_PATH } from 'src/routes/config';

type ProtectedProps = {
  isAuthenticated: boolean;
  isAuthorized: boolean;
  uniqueRedirectPath?: string | null;
  children: any;
};

export const Protected = ({
  isAuthenticated,
  isAuthorized,
  uniqueRedirectPath,
  children,
}: ProtectedProps) => {
  let redirectPath = null;

  if (!isAuthenticated) {
    redirectPath = uniqueRedirectPath ? uniqueRedirectPath : LOGIN_PATH;
  } else if (!isAuthorized) {
    redirectPath = uniqueRedirectPath ? uniqueRedirectPath : ACCOUNT_ISSUE_PATH;
  }

  if ((!isAuthenticated || !isAuthorized) && redirectPath) {
    return <Navigate to={redirectPath} replace />;
  }

  return children;
};
