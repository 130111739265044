import { Theme, styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { Link } from 'react-router-dom';

type StylesTypes = {
  theme: Theme;
  isActive: any;
};

const navSubItemBox = {
  display: 'flex',
  alignItems: 'center',
  height: '40px',
  paddingLeft: '16px',
  cursor: 'pointer',
};

const StyledNavSubItemCircle = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isActive',
})(({ theme, isActive }: StylesTypes) => ({
  width: isActive ? '8px' : '4px',
  height: isActive ? '8px' : '4px',
  borderRadius: '50%',
  backgroundColor: isActive ? theme.palette.common.white : theme.palette.grey[400],
}));

const StyledNavSubItemTitle = styled(Link, {
  shouldForwardProp: (prop) => prop !== 'isActive',
})(({ theme, isActive }: StylesTypes) => ({
  color: isActive ? theme.palette.common.white : theme.sidebar.unselectedText,
  fontWeight: theme.typography.fontWeightMedium,
  textDecoration: 'none',
  '&:hover': {
    color: theme.palette.common.white,
  },
}));

export { navSubItemBox, StyledNavSubItemCircle, StyledNavSubItemTitle };
