import React from 'react';
import { useTheme } from '@mui/material';
import { SubTypeWidget } from '../../types';
import { StyledWidgetAdditionalOptionItem } from './style';

type Props = {
  subType: SubTypeWidget;
  activeOption: string;
  setActiveOption: (activeOption: string) => void;
  handleClose: () => void;
};

export const WidgetAdditonalOptionItem = ({
  subType,
  activeOption,
  setActiveOption,
  handleClose,
}: Props) => {
  const theme = useTheme();
  const { name } = subType;
  const isActive = activeOption === name;

  const icon = React.createElement(subType?.icon, { width: 50, height: 50 });

  const onClickHandler = () => {
    setActiveOption(name);
    handleClose();
  };

  return (
    <StyledWidgetAdditionalOptionItem theme={theme} isActive={isActive} onClick={onClickHandler}>
      {icon}
    </StyledWidgetAdditionalOptionItem>
  );
};
