import { styled, Theme } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';

type StylesTypes = {
  theme: Theme;
};

const StyledLabelOffBox = styled(Box)(({ theme }: StylesTypes) => ({
  display: 'inline-block',
  background: theme.palette.grey[300],
  padding: '2px 8px',
  borderRadius: '6px',
}));

const StyledLabelText = styled(Typography)(({ theme }: StylesTypes) => ({
  color: theme.palette.grey[600],
  fontSize: '12px',
  fontWeight: '700',
}));

export { StyledLabelOffBox, StyledLabelText };
