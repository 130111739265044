const TABLE_HEAD = [
  { id: 'siteName', label: 'Site Name', align: 'left', testId: 'site-name-header' },
  {
    id: 'accessRole',
    label: 'Access Role',
    align: 'left',
    testId: 'access-role-header',
  },
];

const CONFIRMATION_MODAL_HEADING = 'User Invited';
const CONFIRMATION_MODAL_DESCRIPTION_START = 'User ';
const CONFIRMATION_MODAL_DESCRIPTION_END = ' has been invited successfully.';

const BREADCRUMBS_ROOT = 'Administration';
const BREADCRUMBS_USER_MANAGEMENT = 'User Management';
const BREADCRUMBS_INVITE_USER = 'Invite User';
const BREADCRUMBS_EDIT_USER = 'Edit User';

const SUBMIT_BUTTON_LABEL = 'Invite';
const EDIT_SUBMIT_BUTTON_LABEL = 'Save';
const CANCEL_BUTTON_LABEL = 'Cancel';

const COPY_BUTTON_LABEL = 'COPY';
const PRINT_BUTTON_LABEL = 'Print';

const EDIT_SNACKBAR_SUCCESS_LABEL = 'User edited successfully!';

const COPY_NEW_TEMP_PASSWORD_TO_CLIPBOARD_SNACKBAR_INFO_LABEL =
  'Copied new temporary password to clipboard!';
const COPY_TEMP_PASSWORD_TO_CLIPBOARD_SNACKBAR_INFO_LABEL =
  'Copied temporary password to clipboard!';
const COPY_EMAIL_TO_CLIPBOARD_SNACKBAR_INFO_LABEL = 'Copied email to clipboard!';
const RESET_PASSWORD_CONFIRMATION_ALERT_CONTENT =
  'Confirm password resetting for the selected user:';
const RESET_PASSWORD_CONFIRMATION_ALERT_TITLE = 'Reset Password';
const RESET_PASSWORD_CONFIRMATION_ALERT_CANCEL_BUTTON_LABEL = 'Cancel';
const RESET_PASSWORD_CONFIRMATION_ALERT_CONFIRM_BUTTON_LABEL = 'Confirm';
const RESET_PASSWORD_SUCCESS_ALERT_CONTENT_1_1 = 'Password for user ';
const RESET_PASSWORD_SUCCESS_ALERT_CONTENT_1_2 = 'has been reset.';
const RESET_PASSWORD_SUCCESS_ALERT_CONTENT_2_1 = 'Email:';
const RESET_PASSWORD_SUCCESS_ALERT_CONTENT_2_2 = 'New temporary password:';
const RESET_PASSWORD_SUCCESS_ALERT_CONTENT_3 =
  'Please note the credentials or send them to the user via email.';
const RESET_PASSWORD_SUCCESS_ALERT_CANCEL_BUTTON_LABEL =
  'Close without sending, I noted the credentials';
const RESET_PASSWORD_SUCCESS_ALERT_SUBMIT_BUTTON_LABEL = 'Send email';

const STATUS_ALERT_DEACTIVATE_TITLE = 'Deactivate User';
const STATUS_ALERT_REACTIVATE_TITLE = 'Reactivate User';
const STATUS_ALERT_DEACTIVATE_CONTENT_1 = 'Are you sure you want to deactivate user ';
const STATUS_ALERT_REACTIVATE_CONTENT_1 = 'Are you sure you want to reactivate user ';
const STATUS_ALERT_DEACTIVATE_CONTENT_2_1 = 'The user will no longer be able to log into ';
const STATUS_ALERT_DEACTIVATE_CONTENT_2_2 =
  'account (tenant), and will no longer receive alarms for ';
const STATUS_ALERT_DEACTIVATE_CONTENT_2_3 =
  ' account. No user data will be deleted. You will be able to reactivate the user later.';
const STATUS_ALERT_CANCEL_BUTTON_LABEL = 'No, cancel';
const STATUS_ALERT_DEACTIVATE_CONFIRM_BUTTON_LABEL = 'Yes, deactivate';
const STATUS_ALERT_REACTIVATE_CONFIRM_BUTTON_LABEL = 'Yes, reactivate';
const EDIT_SITE_SNACKBAR_SUCCESS_LABEL = 'Site edited successfully!';

const INVITE_ALERT_RESEND_TITLE = 'Re-send Invite';
const INVITE_ALERT_RESEND_CONTENT = 'Are you sure you want to re-invite ';
const INVITE_ALERT_RESEND_CONFIRM_BUTTON_LABEL = 'Yes, re-invite';

const INVITE_ALERT_WITHDRAW_TITLE = 'Withdraw Invite';
const INVITE_ALERT_WITHDRAW_CONTENT = 'Are you sure you want to withdraw the invitation for ';
const INVITE_ALERT_WITHDRAW_CONFIRM_BUTTON_LABEL = 'Yes, withdraw';

export {
  INVITE_ALERT_WITHDRAW_TITLE,
  INVITE_ALERT_WITHDRAW_CONTENT,
  INVITE_ALERT_WITHDRAW_CONFIRM_BUTTON_LABEL,
  INVITE_ALERT_RESEND_CONFIRM_BUTTON_LABEL,
  INVITE_ALERT_RESEND_TITLE,
  INVITE_ALERT_RESEND_CONTENT,
  CONFIRMATION_MODAL_HEADING,
  CONFIRMATION_MODAL_DESCRIPTION_START,
  CONFIRMATION_MODAL_DESCRIPTION_END,
  BREADCRUMBS_ROOT,
  BREADCRUMBS_USER_MANAGEMENT,
  BREADCRUMBS_INVITE_USER,
  BREADCRUMBS_EDIT_USER,
  TABLE_HEAD,
  COPY_BUTTON_LABEL,
  PRINT_BUTTON_LABEL,
  EDIT_SNACKBAR_SUCCESS_LABEL,
  COPY_NEW_TEMP_PASSWORD_TO_CLIPBOARD_SNACKBAR_INFO_LABEL,
  COPY_TEMP_PASSWORD_TO_CLIPBOARD_SNACKBAR_INFO_LABEL,
  COPY_EMAIL_TO_CLIPBOARD_SNACKBAR_INFO_LABEL,
  RESET_PASSWORD_CONFIRMATION_ALERT_CONTENT,
  RESET_PASSWORD_CONFIRMATION_ALERT_TITLE,
  RESET_PASSWORD_CONFIRMATION_ALERT_CANCEL_BUTTON_LABEL,
  RESET_PASSWORD_CONFIRMATION_ALERT_CONFIRM_BUTTON_LABEL,
  RESET_PASSWORD_SUCCESS_ALERT_CONTENT_1_1,
  RESET_PASSWORD_SUCCESS_ALERT_CONTENT_1_2,
  RESET_PASSWORD_SUCCESS_ALERT_CONTENT_2_1,
  RESET_PASSWORD_SUCCESS_ALERT_CONTENT_2_2,
  RESET_PASSWORD_SUCCESS_ALERT_CONTENT_3,
  RESET_PASSWORD_SUCCESS_ALERT_CANCEL_BUTTON_LABEL,
  RESET_PASSWORD_SUCCESS_ALERT_SUBMIT_BUTTON_LABEL,
  STATUS_ALERT_DEACTIVATE_TITLE,
  STATUS_ALERT_REACTIVATE_TITLE,
  STATUS_ALERT_DEACTIVATE_CONTENT_1,
  STATUS_ALERT_REACTIVATE_CONTENT_1,
  STATUS_ALERT_DEACTIVATE_CONTENT_2_1,
  STATUS_ALERT_DEACTIVATE_CONTENT_2_2,
  STATUS_ALERT_DEACTIVATE_CONTENT_2_3,
  STATUS_ALERT_CANCEL_BUTTON_LABEL,
  STATUS_ALERT_DEACTIVATE_CONFIRM_BUTTON_LABEL,
  STATUS_ALERT_REACTIVATE_CONFIRM_BUTTON_LABEL,
  EDIT_SITE_SNACKBAR_SUCCESS_LABEL,
  SUBMIT_BUTTON_LABEL,
  EDIT_SUBMIT_BUTTON_LABEL,
  CANCEL_BUTTON_LABEL,
};
