import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

const StyledReportPreviewHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  background: theme.palette.common.white,
  padding: '24px 16px 24px 20px',
}));

const StyledTitle = styled(Box)(({ theme }) => ({
  color: theme.palette.grey[800],
  fontSize: '14px',
  fontWeight: '700',
}));

const btnsBox = {
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
};

export { StyledReportPreviewHeader, StyledTitle, btnsBox };
