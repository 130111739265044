import { Box, Button, Stack, SvgIcon, Typography } from '@mui/material';
import { GetAllResponse } from 'src/services/sites/types';
import { SelectedItemLabel } from '../selected-item-label';
import { selectedItemsList } from './style';
import { Delete } from '@mui/icons-material';
import { useResponsive } from 'src/hooks';

type SelectedItemsListTypes = {
  values: any;
  sites?: GetAllResponse[];
  single?: boolean;
  setValue: (name: string, value: string) => void;
};

export const SelectedItemsList = ({
  single = false,
  values,
  sites,
  setValue,
}: SelectedItemsListTypes) => {
  const valuesArr = Object.entries(values);
  const isTablet = useResponsive('down', 'md');

  const defaultSelectedElements = valuesArr.filter((item) => item[1] === 'true');

  const content = (
    <Box component="ul" sx={selectedItemsList}>
      {defaultSelectedElements.map((selectedValue) => (
        <SelectedItemLabel
          single={single}
          key={selectedValue[0]}
          selectedValue={selectedValue}
          sites={sites}
          setValue={setValue}
        />
      ))}
    </Box>
  );

  return single ? (
    <Stack
      sx={{ paddingTop: '4px' }}
      flexDirection={isTablet ? 'column' : 'row'}
      justifyContent="space-between"
    >
      {content}
      {defaultSelectedElements.length ? (
        <Button
          onClick={() => {
            setValue(defaultSelectedElements[0][0], 'false');
          }}
          variant="text"
          color="error"
        >
          <Stack gap="4px" flexDirection="row" alignItems="center">
            <SvgIcon component={Delete} />
            <Typography variant="body2">Clear</Typography>
          </Stack>
        </Button>
      ) : (
        <></>
      )}
    </Stack>
  ) : (
    content
  );
};
