import React, { useState } from 'react';
import { Box, Checkbox, TextField } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import {
  StyledWidgetModalItem,
  widgetBox,
  StyledWidgetName,
  textFiled,
  MultiSelectDropdownStyle,
} from './style';
import { RHFAutocomplete } from 'src/components/minimals/form';
import { useResponsive } from 'src/hooks';
import { WidgetTypeEnum, AssetType } from '../../types/types';
import { TagResponse } from 'src/features/alarm-rules/types';
import { GetDataSourceInputsResponse } from 'src/services/sites';
import { getWidgetOptions } from '../../utils';
import { Controller } from 'src/types';
import { addIndexesToDuplicates, filterOptions } from 'src/utilities';
import { WidgetsAdditionalOptions } from 'src/features/widgets/components/WidgetsAdditionalOptions';
import { SubTypeWidget } from 'src/features/widgets/types';
import { IconsTypesProps } from 'src/assets/icons/types';
import { PUMP_2 } from '../../config';

type WidgetModalItemProps = {
  setValue: (name: string, newValue: string) => void;
  reset: () => void;
  name: string;
  id?: string;
  widgetIcon: React.FunctionComponent<IconsTypesProps>;
  widgetName?: string;
  widgetType?: WidgetTypeEnum;
  selectWidgetHandler?: (widgetType: WidgetTypeEnum) => void;
  assets: AssetType[];
  gatewayInputs: TagResponse[];
  dataSourceInputs?: GetDataSourceInputsResponse[];
  controllers: Controller[];
  subTypes?: SubTypeWidget[];
  selectedWidgetSubtype: string;
  setSelectedWidgetSubType: (subType: string) => void;
  label: string;
};

export const WidgetModalItem = ({
  setValue,
  label,
  reset,
  widgetIcon,
  widgetName,
  name,
  widgetType,
  selectWidgetHandler,
  assets,
  gatewayInputs,
  dataSourceInputs = [],
  controllers,
  subTypes,
  selectedWidgetSubtype,
  setSelectedWidgetSubType,
}: WidgetModalItemProps) => {
  const theme = useTheme();
  const [isHover, setIsHover] = useState<boolean>(false);
  const [liveDataLength, setLiveDataLength] = useState<number>(0);
  const isMobile = useResponsive('down', 'md');
  const filteredDataSource = dataSourceInputs.filter((input) => input.displayName);
  const isLiveDataWidget = widgetType === WidgetTypeEnum.LIVE_DATA;
  const MAX_LIVE_DATA_LEN = 6;

  const widgetOptions = getWidgetOptions(
    widgetType,
    gatewayInputs,
    assets,
    filteredDataSource,
    controllers
  );

  function isAssetTypeArray(value: any): value is AssetType[] {
    return (
      Array.isArray(value) && value.every((item) => typeof item === 'object' && 'assetType' in item)
    );
  }

  function isControllerTypeArray(value: any): value is Controller[] {
    return (
      Array.isArray(value) &&
      value.every((item) => typeof item === 'object' && 'controllerType' in item)
    );
  }

  const onMouseEnterHandler = () => {
    setIsHover(true);
  };

  const onMouseLeaveHandler = () => {
    setIsHover(false);
  };

  const onChangeHandler = (newValue: string) => {
    reset();
    setValue(name, newValue);
    if (selectWidgetHandler) selectWidgetHandler(widgetType!);
  };

  const getOptionLabel = (input: any) => {
    if ('assetName' in input) return input.assetName;
    if ('displayName' in input) return input.displayName;
    if ('controllerName' in input) return input.controllerName;
  };

  const getAutocompleteLabel = (value: string) => {
    if (value === '') return '';

    const labels: string[] = [];
    const values: string[] = [];

    widgetOptions.forEach((option) => {
      if (option.id) {
        labels.push(getOptionLabel(option));
        values.push(option.id);
      }
    });

    const formattedLabels = addIndexesToDuplicates(labels);
    const index = values.findIndex((option) => option === value);
    return formattedLabels[index];
  };

  const getAutocompleteLabels = () => {
    const labels: string[] = [''];
    widgetOptions.forEach((option) => {
      labels.push(getOptionLabel(option));
    });

    return labels;
  };
  const selectedTypeObject = subTypes?.find((item) => item.name === selectedWidgetSubtype);
  const isPump2 = selectedTypeObject?.name === PUMP_2;
  const renderedIcon = React.createElement(selectedTypeObject?.icon || widgetIcon, {
    width: isPump2 ? 70 : 90,
    height: isPump2 ? 70 : 90,
  });

  const renderOptions = (props: any, option: any) => (
    <Box display="li" {...props} key={option} sx={{ lineBreak: 'anywhere' }}>
      {getAutocompleteLabel(option)}
    </Box>
  );

  const renderMultiSelectOptions = (props: any, option: any, selected: boolean) => (
    <Box component="li" {...props} key={option} sx={{ lineBreak: 'anywhere' }}>
      <Checkbox
        checked={selected}
        disabled={!selected && liveDataLength + 1 > MAX_LIVE_DATA_LEN}
        sx={{ marginRight: 2 }}
      />
      {getAutocompleteLabel(option)}
    </Box>
  );
  return (
    <StyledWidgetModalItem
      isMobile={isMobile}
      theme={theme}
      isHover={isHover}
      onMouseEnter={onMouseEnterHandler}
      onMouseLeave={onMouseLeaveHandler}
      isMultiSelect={isLiveDataWidget}
    >
      {subTypes ? (
        <WidgetsAdditionalOptions
          subTypes={subTypes}
          setSelectedWidgetSubType={setSelectedWidgetSubType}
          selectedWidgetSubtype={selectedWidgetSubtype}
        />
      ) : null}
      <Box sx={widgetBox}>{renderedIcon}</Box>
      <StyledWidgetName isHover={isHover} theme={theme}>
        {widgetName}
      </StyledWidgetName>
      <RHFAutocomplete
        disablePortal
        disableClearable={isLiveDataWidget}
        multiple={isLiveDataWidget}
        disableCloseOnSelect={isLiveDataWidget}
        onChange={(event, newValue) => {
          if (Array.isArray(newValue)) {
            setLiveDataLength(newValue.length);
            if (newValue.length > MAX_LIVE_DATA_LEN) return;
          }
          onChangeHandler(newValue as string);
        }}
        name={name!}
        options={['', ...widgetOptions.map((option) => option.id)]}
        getOptionLabel={(value: any) => getAutocompleteLabel(value)}
        filterOptions={(options, state) =>
          filterOptions(options as string[], state, getAutocompleteLabels())
        }
        renderOption={(props, option, { selected }) =>
          isLiveDataWidget
            ? renderMultiSelectOptions(props, option, selected)
            : renderOptions(props, option)
        }
        componentsProps={isLiveDataWidget ? MultiSelectDropdownStyle : undefined}
        customListboxProps={{ style: { maxHeight: 244 } }}
        renderInput={(params) => (
          <TextField
            sx={textFiled}
            {...params}
            label={label}
            inputProps={{
              ...params.inputProps,
              'data-sm': `asset-input-${widgetName?.toLocaleLowerCase().replace(' ', '-')}`,
              readOnly: !(
                (!isAssetTypeArray(widgetOptions) && !isControllerTypeArray(widgetOptions)) ||
                !isMobile
              ),
            }}
          />
        )}
      />
    </StyledWidgetModalItem>
  );
};
