import { createContext, FunctionComponent, PropsWithChildren, useContext } from 'react';
import { ASSET_REPORT_INITIAL_STATE, DATA_REPORT_INITIAL_STATE } from '../config/config';
import _ from 'lodash';
import { FormDataType } from '../types';
import useTimezone from '../hooks/useTimezone';
import { useFilterSites } from './sitesContext';
import { useBlockNavigation } from 'src/routes/elements/ReportFlow/BuildReportsElement/blockNavigationContext';

const templateContext = createContext<{
  undoChanges: () => void;
  assetProperties: any[];
  checkTemplateInitialState: (state: any) => boolean;
  checkTemplateForChanges: (state: any) => boolean | 'INVALID';
}>({
  assetProperties: [],
  undoChanges: () => {},
  checkTemplateInitialState: () => false,
  checkTemplateForChanges: () => false,
});

const TemplateContextProvider: FunctionComponent<
  PropsWithChildren<{
    reportType: FormDataType | string;
    title: string;
    template: any;
    loaded: boolean;
    undoChanges: (sites?: any) => void;
    assetProperties: any[];
  }>
> = ({ reportType, loaded, template, undoChanges, assetProperties, children, title }) => {
  const { parseTimezone } = useTimezone();
  const { initialFilters } = useFilterSites();
  const blockNavigation = useBlockNavigation();
  const checkTemplateInitialState = (state: any) => {
    const stateToCompare = _.cloneDeep(state);

    const defaultState =
      reportType === 'ASSET' ? ASSET_REPORT_INITIAL_STATE.data : DATA_REPORT_INITIAL_STATE.data;

    if (typeof stateToCompare.data.filters === 'undefined') delete stateToCompare.data.filters;

    if (_.isEqual(stateToCompare.data, defaultState)) return true;

    return false;
  };
  const checkTemplateForChanges = (state: any) => {
    if (!loaded) return true;
    const stateToCompare = _.cloneDeep(state);

    const defaultState =
      reportType === 'ASSET' ? ASSET_REPORT_INITIAL_STATE.data : DATA_REPORT_INITIAL_STATE.data;

    if (typeof stateToCompare.filters === 'undefined') delete stateToCompare.filters;

    if (checkTemplateInitialState(state)) {
      if (blockNavigation) {
        blockNavigation[1](false);
      }
      return 'INVALID';
    }

    const templateToCompare = _.cloneDeep(template || defaultState);
    const templateStateToCompare = templateToCompare.state;
    if (typeof templateToCompare.state.filters === 'undefined')
      delete templateToCompare.state.filters;
    if (typeof stateToCompare.data.filters === 'undefined') delete stateToCompare.data.filters;
    if (templateStateToCompare.columns.custom) {
      templateStateToCompare.columns.custom.forEach((column: any) => {
        delete column.id;
        return column;
      });
    }
    if (stateToCompare.data.columns.custom) {
      stateToCompare.data.columns.custom.forEach((column: any) => {
        delete column.id;
        return column;
      });
    }

    let templateTimezone = template.timezone;
    if (typeof templateTimezone === 'string') {
      templateTimezone = parseTimezone(templateTimezone);
    }
    let sameFilters = true;
    if (reportType === 'ASSET') {
      sameFilters = _.isEqual(stateToCompare.filters, initialFilters);
      delete stateToCompare.data.filters;
      delete templateStateToCompare.filters;
      if (
        templateToCompare.state.filters?.assetFilters &&
        !templateToCompare.state.filters.assetFilters.assetTypes
      )
        templateToCompare.state.filters.assetFilters.assetTypes = undefined;
      if (
        templateToCompare.state.filters?.siteFilters &&
        !templateToCompare.state.filters.siteFilters.siteTypes
      )
        templateToCompare.state.filters.siteFilters.siteTypes = undefined;
      if (
        stateToCompare.data.filters?.assetFilters &&
        !stateToCompare.data.filters.assetFilters.assetTypes
      )
        stateToCompare.data.filters.assetFilters.assetTypes = undefined;
      if (
        stateToCompare.data.filters?.siteFilters &&
        !stateToCompare.data.filters.siteFilters.siteTypes
      )
        stateToCompare.data.filters.siteFilters.siteTypes = undefined;
    }

    const changed =
      _.isEqual(stateToCompare.data, templateStateToCompare) &&
      sameFilters &&
      stateToCompare.header.timeZone?.value === templateTimezone?.value &&
      title === template.title;

    if (blockNavigation) {
      blockNavigation[1](!changed);
    }

    return changed;
  };

  return (
    <templateContext.Provider
      value={{
        assetProperties,
        undoChanges,
        checkTemplateForChanges,
        checkTemplateInitialState,
      }}
    >
      {children}
    </templateContext.Provider>
  );
};

export const useTemplateState = () => useContext(templateContext);

export default TemplateContextProvider;
