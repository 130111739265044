import { styled } from '@mui/material/styles';
import { Popup } from 'react-leaflet';

export const StyledPopup = styled(Popup)(() => ({
  '& .leaflet-popup-content': {
    margin: 0,
  },
}));

export const siteContainer = {
  height: '100%',
  width: '100%',
  minHeight: '400px',
  position: 'relative',
};

export const siteSearchBox = {
  position: 'absolute',
  width: '100%',
};

export const siteHeaderBox = {
  position: 'relative',
  zIndex: 2,
  margin: '16px 16px 0 16px',
};
