import { SelectedInputType } from '../types/selected-input';
import { SEPARATOR } from '../config';

export const getSelectedInputs = (values: Record<string, string>): SelectedInputType[] => {
  const inputs = Object.entries(values);

  const filteredInputs = inputs.filter(
    ([key, value]) => value === 'true' && !key.includes('events')
  );

  const data = filteredInputs.map(([key]) => {
    const [, siteId, siteName, rawInputName, unit, gatewayId, inputId, inputName] =
      key.split(SEPARATOR);

    return {
      siteId: siteId ?? '',
      siteName: siteName ?? '',
      rawInputName: rawInputName ?? '',
      unit: unit ?? '',
      gatewayId: gatewayId ?? '',
      inputId: inputId ?? '',
      inputName: inputName ? inputName.replace(/\(dot\)/g, '.') : '',
    };
  });
  return data;
};
