import { AlarmPriorityType } from 'src/features/alarm-rules/types';
import { SiteType } from './site-type';

export class NewSite {
  siteName: string;

  siteType?: string;

  location?: Location;

  status: string;

  constructor(siteName: string, status: string, siteType?: string, location?: Location) {
    this.siteName = siteName;
    this.status = status;
    this.siteType = siteType;
    this.location = location;
  }
}

export class Site extends NewSite {
  id: string;

  createdAt: string;

  updatedAt: string;

  constructor(
    id: string,
    siteName: string,
    status: string,
    createdAt: string,
    updatedAt: string,
    siteType?: string,
    location?: Location
  ) {
    super(siteName, status, siteType, location);
    this.id = id;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
  }
}

export class Location {
  street?: string;

  city?: string;

  country?: string;

  state?: string;

  zip?: string;

  altitude?: string;

  longitude?: string;

  latitude?: string;

  timeZone?: string;

  constructor(location?: Location) {
    this.street = location?.street;
    this.city = location?.city;
    this.country = location?.country;
    this.state = location?.state;
    this.zip = location?.zip;
    this.altitude = location?.altitude;
    this.longitude = location?.longitude;
    this.latitude = location?.latitude;
    this.timeZone = location?.timeZone;
  }

  public toString = (): string => {
    const propArray = [this.street, this.city, this.country, this.state, this.zip];
    return propArray.filter((entry) => !!entry).join(', ');
  };
}

export class NewSiteWithActualSiteType extends NewSite {
  actualSiteType: SiteType;

  constructor(
    siteName: string,
    status: string,
    actualSiteType: SiteType,
    siteType?: string,
    location?: Location
  ) {
    super(siteName, status, siteType, location);
    this.actualSiteType = actualSiteType;
  }
}

export class UpdateSite extends NewSiteWithActualSiteType {}

export type LocationType = {
  street?: string;
  city?: string;
  country?: string;
  state?: string;
  zip?: string;
  altitude?: string;
  longitude?: string;
  latitude?: string;
  timeZone?: string;
};

export interface SiteInterface {
  id: string;
  createdAt: string;
  updatedAt: string;
  siteName: string;
  siteType?: string;
  location: LocationType;
  highestActiveAlarm: AlarmPriorityType;
}
