import { Stack, Typography, useTheme } from '@mui/material';
import { SnackErrorIcon } from 'src/assets';

type ContentLoadingErrorType = {
  isMobile?: boolean;
  contentFailedToLoad?: string;
};

export const ContentLoadingError = ({
  isMobile = false,
  contentFailedToLoad,
}: ContentLoadingErrorType) => {
  const theme = useTheme();

  return (
    <Stack
      flexDirection={isMobile ? 'column' : 'row'}
      justifyContent={'center'}
      alignItems={'center'}
      sx={{ width: '80%', padding: '16px' }}
    >
      <SnackErrorIcon
        width={isMobile ? 40 : 24}
        height={isMobile ? 40 : 24}
        fill={theme.palette.error.main}
      />
      <Typography variant="body2" sx={{ ml: '8px', textAlign: isMobile ? 'center' : 'initial' }}>
        {`${contentFailedToLoad ? contentFailedToLoad : 'Content'}`} failed to load! Please try
        again in a bit or contact your administrator.
      </Typography>
    </Stack>
  );
};
