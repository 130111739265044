import { Tooltip, useTheme } from '@mui/material';
import { AlarmPriority } from 'src/types/alarms';
import { StyledLabelTagBox, StyledLabelTagValue } from './style';

type Props = {
  alarmPriority: AlarmPriority;
  value: number;
};

export const LabelTagBox = ({ alarmPriority, value }: Props) => {
  const theme = useTheme();

  const backgroundColors = {
    Critical: theme.palette.error.lighter,
    Warning: theme.palette.warning.lighter,
    Info: theme.palette.info.lighter,
    Cleared: theme.palette.success.lighter,
    null: theme.palette.grey[300],
    Offline: theme.palette.error.lighter,
  };

  const colors = {
    Critical: theme.palette.error.main,
    Warning: theme.palette.warning.main,
    Info: theme.palette.info.main,
    Cleared: theme.palette.success.main,
    null: theme.palette.grey[500],
    Offline: theme.palette.error.main,
  };
  const roundDigitsCount = 6;

  const getNthChars = (value: number, digits: number) => value.toString().substring(0, digits);

  const borderColor = alarmPriority === 'null' ? 'transparent' : colors[alarmPriority];

  return (
    <Tooltip
      title={`${value}`}
      componentsProps={{
        tooltip: {
          sx: {
            bgcolor: '#fff',
          },
        },
      }}
    >
      <StyledLabelTagBox background={backgroundColors[alarmPriority]} color={borderColor}>
        <StyledLabelTagValue color={colors[alarmPriority]}>
          {getNthChars(value, roundDigitsCount)}
        </StyledLabelTagValue>
      </StyledLabelTagBox>
    </Tooltip>
  );
};
