const loaderBox = {
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  paddingTop: '10px',

  position: 'absolute',
  top: '0',
  left: '0',
};

export { loaderBox };
