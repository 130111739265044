import { FunctionComponent } from 'react';
import { FormHeaderStack } from '../styles';
import SectionTitle from 'src/features/formRenderer/components/SectionTitle';

interface IFormHeaderProps {
  title: string;
  action?: JSX.Element | boolean;
}

const FormHeader: FunctionComponent<IFormHeaderProps> = ({ title, action }) => (
  <FormHeaderStack alignItems="center" justifyContent="space-between" flexDirection="row">
    <SectionTitle>{title}</SectionTitle>
    {action}
  </FormHeaderStack>
);

export default FormHeader;
