import React, { useContext, useLayoutEffect, useMemo, useState } from 'react';
import { AssetContextTypes, CustomerAssetProviderProps, CustomerAssets } from './types';
import { ThemeService } from 'src/services/themes';

const AssetContext = React.createContext<AssetContextTypes>({} as AssetContextTypes);

export const useCustomerAssets = (themeId?: string, customerId?: string) => {
  const context = useContext(AssetContext);

  useLayoutEffect(() => {
    if (themeId && customerId) {
      context.setThemeId(themeId);
      context.setCustomerId(customerId);
    }
  }, [themeId, context, customerId]);

  return context;
};

export const CustomerAssetProvider = ({ children }: CustomerAssetProviderProps) => {
  const [loadingAssets, setLoadingAssets] = useState(true);
  const [themeId, setThemeId] = useState<string | null>(null);
  const [customerId, setCustomerId] = useState<string | null>(null);
  const [customerAssets, setCustomerAssets] = useState<CustomerAssets>({
    logo: '',
  });
  const filename = 'logo';
  const env_url = window.location.href;

  useLayoutEffect(() => {
    ThemeService.getLogo(customerId || '1', filename, themeId || '1').then((data) => {
      setCustomerAssets({ logo: data });
      setLoadingAssets(false);
    });
  }, [themeId, customerId, env_url]);

  const contextValue = useMemo(
    () => ({
      setCustomerId,
      setThemeId,
      customerAssets,
      loadingAssets,
    }),
    [setCustomerId, setThemeId, customerAssets, loadingAssets]
  );

  return <AssetContext.Provider value={contextValue}>{children}</AssetContext.Provider>;
};
