import {
  WaterTowerWidget,
  WetWellWidget,
  PumpWidget,
  BarsChartWidget,
  LinearChartWidget,
  SpiralDotsChartWidget,
  ControllerWidget,
  ValveWidgetModal,
} from 'src/assets/widgets';

import { WidgetTypeEnum } from '../types/types';
import featureFlags from '../../../feature-flags.json';
import { PumpIcon, DosingPumpIcon, SubmersiblePumpWidget, LiveDataWidget } from 'src/assets';

const ADD_SITE_BTN = 'Add Site';
const FILTER_BTN = 'Filters';
const ADD_WIDGET_BTN = 'Add widget';

const DEFAULT_SORT_BY_VALUE = 'siteName';

const DASHBOARD_BREADCRUMB_TITLE = 'Sites';
const DASHBOARD_MODAL_TITLE = 'Add New Widget';

const COPY_ADDRESS_DESCRIPTION = 'Copy address';
const OPEN_IN_APPLE_MAPS_DESCRIPTION = 'Open in Apple Maps';
const OPEN_IN_GOOGLE_MAPS_DESCRIPTION = 'Open in Google Maps';
const EDIT_SITE_PREVIEW = 'Edit Site Preview';

const RAW_INPUT_GATEWAY_TABLE_HEAD = [
  { id: 'rawInput', label: 'Raw Input Names', align: 'left' },
  {
    id: 'tagType',
    label: 'Tag Type',
    align: 'left',
  },
  { id: 'rawUnit', label: 'Raw Unit', align: 'left' },
  { id: 'displayNames', label: 'Tag Display Name', align: 'left' },
  ...(featureFlags.advancedTagMapping === 1
    ? [
        { id: 'controller', label: 'Controller', align: 'left' },
        { id: 'rangeValue', label: 'Acceptable Range/Value', align: 'left' },
        { id: 'dataType', label: 'Data Type', align: 'left' },
        { id: 'controllable', label: 'Controllable', align: 'left' },
      ]
    : []),
  { id: 'hide', label: 'Hide', align: 'center' },
];

//PUMPS
export const PUMP_1 = 'PUMP_1';
export const PUMP_2 = 'PUMP_2';
export const PUMP_3 = 'PUMP_3';

const MODAL_WIDGETS = [
  {
    widgetType: WidgetTypeEnum.TOWER,
    name: 'tower',
    id: 'Tower',
    title: 'Water Tower',
    label: 'Select Asset',
    icon: WaterTowerWidget,
  },
  {
    widgetType: WidgetTypeEnum.LIFT_STATION_WET_WELL,
    name: 'liftStationWetWell',
    id: 'Lift Station Wet Well',
    title: 'Lift Station Wet Well',
    label: 'Select Asset',
    icon: WetWellWidget,
  },
  {
    widgetType: WidgetTypeEnum.PUMP,
    name: 'pumpWidget',
    id: 'Pump Widget',
    title: 'Pump',
    label: 'Select Asset',
    icon: PumpWidget,
    subTypes: [
      { id: '1', icon: PumpIcon, name: PUMP_1 },
      { id: '2', icon: DosingPumpIcon, name: PUMP_2 },
      { id: '3', icon: SubmersiblePumpWidget, name: PUMP_3 },
    ],
  },
  {
    widgetType: WidgetTypeEnum.BARS_GRAPH,
    name: 'barsGraph',
    id: 'Bars Graph',
    title: 'Aggregations',
    label: 'Select Asset',
    icon: BarsChartWidget,
  },
  {
    widgetType: WidgetTypeEnum.LINEAR_GRAPH,
    name: 'linearGraph',
    id: 'Linear Graph',
    title: 'Mode/State/Alarms',
    label: 'Select Tag',
    icon: LinearChartWidget,
  },
  {
    widgetType: WidgetTypeEnum.SPIRAL_DOTS_GRAPH,
    name: 'spiralDotsGraph',
    id: 'Spiral Dots Graph',
    title: 'Trend',
    label: 'Select Tag',
    icon: SpiralDotsChartWidget,
  },
  {
    widgetType: WidgetTypeEnum.VALVE,
    name: 'valve',
    id: 'Valve',
    title: 'Valve',
    label: 'Select Asset',
    icon: ValveWidgetModal,
  },

  ...(featureFlags.widgetController === 1
    ? [
        {
          widgetType: WidgetTypeEnum.CONTROLLER_WIDGET,
          name: 'controllerWidget',
          id: 'Controller Widget',
          title: 'Controller Widget',
          label: 'Select Controller',
          icon: ControllerWidget,
        },
      ]
    : []),
  {
    widgetType: WidgetTypeEnum.LIVE_DATA,
    name: 'liveData',
    id: 'Live Data',
    title: 'Live Data',
    label: 'Select up to 6 tags',
    icon: LiveDataWidget,
  },
];
const CONFIRMATION_MODAL_HEADING_ADD_SITE = 'Site Added';
const CONFIRMATION_MODAL_DESCRIPTION_ADD_SITE = 'You have successfully created a new site.';

const BREADCRUMBS_ROOT_SITE = 'Sites';
const BREADCRUMBS_ADD_SITE = 'Add Site';
const BREADCRUMBS_EDIT_SITE = 'Site Info';

//TAG NAMES
const STATE = 'state';
const MODE = 'mode';
const WET_WELL_TAG_NAME = 'level';
const PUMP_RUNTIME_TAG_NAME = 'daily runtime';
//ASSET PROPERTIES
const HEIGHT = 'height';
const DAILY_CYCLE_COUNT = 'daily cycles';

const WIDGET_TYPES_CONNECTED_TO_ASSET = [
  WidgetTypeEnum.PUMP,
  WidgetTypeEnum.TOWER,
  WidgetTypeEnum.LIFT_STATION_WET_WELL,
  WidgetTypeEnum.BARS_GRAPH,
  WidgetTypeEnum.VALVE,
];

//MAP CONFIG
const MAX_ZOOM = 18;
const MAP_FILTER_SESSION_STORAGE_KEY = 'map-filters';
const MAP_SEARCH_SESSION_STORAGE_KEY = 'map-search';

export {
  ADD_SITE_BTN,
  FILTER_BTN,
  ADD_WIDGET_BTN,
  DASHBOARD_BREADCRUMB_TITLE,
  DASHBOARD_MODAL_TITLE,
  MODAL_WIDGETS,
  CONFIRMATION_MODAL_HEADING_ADD_SITE,
  CONFIRMATION_MODAL_DESCRIPTION_ADD_SITE,
  BREADCRUMBS_ROOT_SITE,
  BREADCRUMBS_ADD_SITE,
  BREADCRUMBS_EDIT_SITE,
  RAW_INPUT_GATEWAY_TABLE_HEAD,
  STATE,
  MODE,
  PUMP_RUNTIME_TAG_NAME,
  DAILY_CYCLE_COUNT,
  WET_WELL_TAG_NAME,
  HEIGHT,
  WIDGET_TYPES_CONNECTED_TO_ASSET,
  COPY_ADDRESS_DESCRIPTION,
  OPEN_IN_APPLE_MAPS_DESCRIPTION,
  OPEN_IN_GOOGLE_MAPS_DESCRIPTION,
  MAX_ZOOM,
  MAP_FILTER_SESSION_STORAGE_KEY,
  MAP_SEARCH_SESSION_STORAGE_KEY,
  DEFAULT_SORT_BY_VALUE,
  EDIT_SITE_PREVIEW,
};

export const MISSING_SITE_COORDINATES = 'Cannot be displayed due to missing coordinates';
