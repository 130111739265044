import { PREDEFINED, RANGE } from '../config';
import { ControllerAdditionalInfo } from 'src/types';

type UseModePossibleValuesTypes = {
  data: ControllerAdditionalInfo[];
};

type PossibleValues = {
  id?: string;
  displayName: string;
};

export const modePossibleValues = ({ data }: UseModePossibleValuesTypes) => {
  const possibleValues: PossibleValues[] = [];
  const isMultipleModeTags = data?.length > 1;
  const controllableData = data?.filter((item) => item.advancedTagMapping?.controllable);

  controllableData?.forEach((tag) => {
    const allowedInputs = tag?.advancedTagMapping?.allowedInputs;

    if (!isMultipleModeTags && allowedInputs === PREDEFINED) {
      tag.advancedTagMapping?.userDefinedValues?.forEach(({ tagId, value, name }: any) => {
        const tagForDisplay = {
          id: tagId,
          displayName: `${value}-${name}`,
          value: value.toString(),
        };
        possibleValues.push(tagForDisplay);
      });
    }

    if (isMultipleModeTags && allowedInputs === RANGE) {
      const { displayName } = tag;
      const maximumValue = tag.advancedTagMapping?.maximumValue;
      const minimumValue = tag.advancedTagMapping?.minimumValue;
      if (!maximumValue || (!minimumValue && minimumValue !== 0)) return;
      for (let i = minimumValue; i < maximumValue + 1; i++) {
        const tagForDisplay = {
          id: tag.id,
          displayName: `${displayName}-${i.toString()}`,
          value: i.toString(),
        };
        possibleValues.push(tagForDisplay);
      }
    }

    if (isMultipleModeTags && allowedInputs === PREDEFINED) {
      const { displayName } = tag;
      tag.advancedTagMapping?.userDefinedValues?.forEach(({ tagId, value, name }: any) => {
        const tagForDisplay = {
          id: tagId,
          displayName: `${displayName}-${value}-${name}`,
          value: value.toString(),
        };
        possibleValues.push(tagForDisplay);
      });
    }

    if (!isMultipleModeTags && allowedInputs === RANGE) {
      const maximumValue = tag.advancedTagMapping?.maximumValue;
      const minimumValue = tag.advancedTagMapping?.minimumValue;
      if (!maximumValue || (!minimumValue && minimumValue !== 0)) return;
      for (let i = minimumValue; i < maximumValue + 1; i++) {
        const tagForDisplay = {
          id: tag.id,
          displayName: i.toString(),
          value: i.toString(),
        };
        possibleValues.push(tagForDisplay);
      }
    }
  });

  return { possibleValues };
};
