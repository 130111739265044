import { Theme } from '@mui/material/styles';
import { SxProps, TableRow, TableCell, TableHead, Stack } from '@mui/material';
import { LightTooltip } from '../tooltip/LightTooltip';
import { InfoIconV2 } from 'src/assets';

type Props = {
  tableCellStyle?: {};
  headLabel: any[];
  sx?: SxProps<Theme>;
  testId?: string;
  tooltipInfo?: { id: string; message: string };
};

export const TableHeadBasic = ({ tableCellStyle, headLabel, sx, testId, tooltipInfo }: Props) => (
  <TableHead sx={sx} data-sm={testId}>
    <TableRow>
      {headLabel?.map((headCell) =>
        headCell.id === 'value' ? (
          <TableCell
            key={headCell.id}
            align={headCell.align || 'left'}
            sx={{
              width: headCell.width,
              minWidth: headCell.minWidth,
              ...tableCellStyle,
            }}
            data-sm={headCell.testId}
          >
            <Stack flexDirection="row">
              {headCell.label}

              <LightTooltip
                key={headCell.id}
                placement="left-start"
                arrow
                enterTouchDelay={0}
                title={
                  <span style={{ whiteSpace: 'pre-line', fontSize: '14px' }}>
                    {'Entered values dictate input options in the Controller widget'}
                  </span>
                }
              >
                <Stack style={{ marginLeft: '4px' }} justifyContent="center">
                  <InfoIconV2 width={20} height={20} />
                </Stack>
              </LightTooltip>
            </Stack>
          </TableCell>
        ) : (
          <TableCell
            key={headCell.id}
            align={headCell.align || 'left'}
            sx={{
              width: headCell.width,
              minWidth: headCell.minWidth,
              ...tableCellStyle,
            }}
            data-sm={headCell.testId}
          >
            {headCell.label}
          </TableCell>
        )
      )}
    </TableRow>
  </TableHead>
);
