import { SelectedInputType } from 'src/features/trends/types/selected-input';

type ValueType = {
  [timestamp: string]: number;
};
type ReadingType = {
  displayName: string;
  rawInputName: string;
  rawUnit?: string;
  tagId: string;
  values: ValueType;
};

export const getSeries = (readings: ReadingType[], selectedInputs: SelectedInputType[]) => {
  if (readings.length === 0) return [];
  const allSeries: {
    inputId: string;
    inputName: string;
    siteName: string;
    unit: string;
    data: any;
  }[] = selectedInputs.map((x) => {
    const filteredTags = readings.filter((item) => item.tagId === x.inputId);
    const values = filteredTags.map((item: any) => item.values);

    const removedEmptyOBjects = values.filter((obj: {}) => Object.keys(obj).length !== 0);

    const dataArray = [];

    for (const key in removedEmptyOBjects) {
      if (Object.hasOwnProperty.call(removedEmptyOBjects, key)) {
        const nestedData = removedEmptyOBjects[key];
        for (const nestedKey in nestedData) {
          if (Object.hasOwnProperty.call(nestedData, nestedKey)) {
            dataArray.push({ datetime: nestedKey, value: nestedData[nestedKey] });
          }
        }
      }
    }

    const transformedData = dataArray.map((entry) => {
      const utcDate = new Date(entry.datetime);
      const localDate = new Date(utcDate.getTime() - utcDate.getTimezoneOffset() * 60 * 1000);

      return [localDate.getTime(), entry.value];
    });

    return {
      inputId: x.inputId,
      inputName: x.inputName,
      siteName: x.siteName,
      unit: x.unit,
      data: transformedData,
    };
  });

  const data = allSeries.map((x) => {
    const { siteName, unit, inputName } = x;
    const displayUnit = unit && unit !== 'null' ? `(${unit})` : '';
    if (siteName == null) {
      return {
        name: `${inputName}${displayUnit}`,
        data: x.data,
      };
    } else {
      return {
        name: `${siteName} - ${inputName} ${displayUnit}`,
        data: x.data,
      };
    }
  });
  return data;
};
