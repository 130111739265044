import { Box, Button, Stack, Typography, useTheme } from '@mui/material';
import { FunctionComponent, useEffect, useState } from 'react';
import { CustomTextArea } from '../styles';
import dayjs from 'dayjs';
import { LogComment } from 'src/features/logs/types';
import { useResponsive } from 'src/hooks';
import { useAuthentication } from 'src/features/authentication/context';
import { ACCESS_ROLES } from 'src/config';
import { formatUTCDateToLocalDate } from 'src/utilities';

interface ICommentActions {
  onDelete: (commentId: string) => void;
  onEdit: (commentId: string, comment: string) => void;
  showDateAndStatus: boolean;
}

const CommentBox: FunctionComponent<LogComment & ICommentActions> = ({
  id,
  onDelete,
  onEdit,
  authorName,
  content,
  authorId,
  showDateAndStatus,
  createdAt,
  updatedAt,
}) => {
  const [temporaryComment, setTemporaryComment] = useState(content);
  const [isEditing, setIsEditing] = useState(false);
  const [isEditLoading, setIsEditLoading] = useState(false);
  const isMobile = useResponsive('down', 'lg');
  const {
    user,
    getCurrentRole,
    customerId: { value: customerId },
    siteId: { value: siteId },
  } = useAuthentication();
  const theme = useTheme();

  const canModifyComment = () => {
    const currentRole = getCurrentRole(customerId, siteId);
    const isSuperAdmin = currentRole === ACCESS_ROLES.SUPER_ADMIN;
    const isAccountAdmin = currentRole === ACCESS_ROLES.ACCOUNT_ADMIN;

    return isSuperAdmin || isAccountAdmin;
  };

  useEffect(() => {
    if (isEditing) setTemporaryComment(content);
  }, [isEditing, setTemporaryComment, content]);
  return (
    <Box sx={{ borderRadius: '12px', backgroundColor: theme.palette.grey[100], padding: 1 }}>
      <Stack>
        {isEditing ? (
          <></>
        ) : (
          <Box
            sx={{
              display: 'flex',
              flexDirection: isMobile ? 'column' : 'row',
              gap: isMobile ? '2px' : '8px',
            }}
          >
            <Typography fontSize="14px" fontWeight="600">
              {authorName}
            </Typography>
            {showDateAndStatus && (
              <Typography fontSize="12px" sx={{ marginTop: '-1px' }}>
                {dayjs(formatUTCDateToLocalDate(updatedAt || createdAt)).format(
                  'MMMM D, YYYY [at] h:mm A'
                )}{' '}
                {updatedAt ? 'Edited' : ''}
              </Typography>
            )}
          </Box>
        )}
        {isEditing ? (
          <CustomTextArea
            disabled={isEditLoading}
            onChange={(event) => setTemporaryComment(event.target.value.slice(0, 2000))}
            maxRows={3}
            minRows={3}
            value={temporaryComment}
          />
        ) : (
          <Typography sx={{ marginTop: '18px' }} fontSize="14px">
            {content}
          </Typography>
        )}
        <Stack marginTop="8px" justifyContent="flex-end" flexDirection="row" gap="10px">
          {isEditing ? (
            <>
              <Button
                disabled={isEditLoading}
                onClick={() => {
                  setIsEditing(false);
                }}
                variant="text"
              >
                Cancel
              </Button>
              <Button
                disabled={isEditLoading}
                onClick={async () => {
                  setIsEditLoading(true);
                  await onEdit(id, temporaryComment);
                  setIsEditLoading(false);
                  setIsEditing(false);
                }}
                variant="contained"
              >
                {isEditLoading ? 'Editing...' : 'Confirm'}
              </Button>
            </>
          ) : user?.userId === authorId || canModifyComment() ? (
            <>
              <Button
                sx={{ paddingInline: '4px', minWidth: 'initial' }}
                onClick={() => setIsEditing(true)}
                color="primary"
                variant="text"
              >
                Edit
              </Button>
              <Button
                sx={{ paddingInline: '4px', minWidth: 'initial' }}
                onClick={() => onDelete(id)}
                color="error"
                variant="text"
              >
                Delete
              </Button>
            </>
          ) : (
            <></>
          )}
        </Stack>
      </Stack>
    </Box>
  );
};

export default CommentBox;
