export function formatAcknowledgedBy(
  acknowledgment:
    | {
        firstName: string;
        timestamp: string;
        lastName: string;
      }
    | undefined
) {
  if (acknowledgment && acknowledgment.firstName) {
    const acknowledgedDate = new Date(Number(acknowledgment.timestamp) * 1000).toLocaleString(
      'en-US',
      {
        month: 'long',
        day: 'numeric',
        year: '2-digit',
        hour: 'numeric',
        minute: 'numeric',
      }
    );
    return `${acknowledgment.firstName} ${acknowledgment.lastName}, ${acknowledgedDate}`;
  } else {
    return 'Unacknowledged';
  }
}
