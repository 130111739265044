import { SITES_ROOT, WIDGETS, api } from '../api';
import { GetAllTypes, AddTypes, EditTypes, RemoveTypes } from './types';

const getAll = async ({ customerId, siteId }: GetAllTypes) => {
  try {
    const response = await api({
      method: 'get',
      url: `v2/${WIDGETS}?customerId=${customerId}&siteId=${siteId}`,
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

const add = async ({ customerId, siteId, data }: AddTypes) => {
  const response = await api({
    method: 'post',
    url: `v2/${WIDGETS}?customerId=${customerId}&siteId=${siteId}`,
    data,
  });

  return response.data;
};

const edit = async ({ customerId, siteId, widgetId, data }: EditTypes) => {
  const response = await api({
    method: 'patch',
    url: `${SITES_ROOT}/${customerId}/${siteId}/${WIDGETS}/${widgetId}`,
    data,
  });

  return response.data;
};

const remove = async ({ customerId, siteId, widgetId }: RemoveTypes) => {
  const response = await api({
    method: 'delete',
    url: `${SITES_ROOT}/${customerId}/${siteId}/${WIDGETS}/${widgetId}`,
  });

  return response.data;
};

export const WidgetService = {
  getAll,
  add,
  edit,
  remove,
};
