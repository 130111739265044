export const WaterTowerWidget = () => (
  <svg width="64" height="141" viewBox="0 0 64 141" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.190186 16.4375C0.190186 7.83824 7.16125 0.867188 15.7605 0.867188H47.9391C56.5384 0.867188 63.5095 7.83825 63.5095 16.4375V42.388C63.5095 50.9873 56.5384 57.9583 47.9391 57.9583H36.6939C35.7384 57.9583 34.9639 58.7329 34.9639 59.6884V140.654C34.9639 140.845 34.809 141 34.6179 141H28.0437C27.8527 141 27.6977 140.845 27.6977 140.654V59.6884C27.6977 58.7329 26.9232 57.9583 25.9677 57.9583H15.7605C7.16125 57.9583 0.190186 50.9873 0.190186 42.388V16.4375Z"
      fill="white"
    />
    <path
      d="M0.363189 16.4375C0.363189 7.93379 7.2568 1.04019 15.7605 1.04019H47.9391C56.4428 1.04019 63.3365 7.9338 63.3365 16.4375V42.388C63.3365 50.8917 56.4428 57.7853 47.9391 57.7853H36.6939C35.6429 57.7853 34.7909 58.6373 34.7909 59.6884V140.654C34.7909 140.75 34.7134 140.827 34.6179 140.827H28.0437C27.9482 140.827 27.8707 140.75 27.8707 140.654V59.6884C27.8707 58.6373 27.0187 57.7853 25.9677 57.7853H15.7605C7.25679 57.7853 0.363189 50.8917 0.363189 42.388V16.4375Z"
      stroke="#566369"
      strokeOpacity="0.32"
      strokeWidth="0.346007"
    />
    <path
      d="M3.3042 18.5132C3.3042 9.91395 10.2753 2.94287 18.8745 2.94287H44.825C53.4243 2.94287 60.3953 9.91394 60.3953 18.5132V40.3301C60.3953 48.9293 53.4243 55.9004 44.825 55.9004H33.0954V138.578C33.0954 138.769 32.9405 138.924 32.7494 138.924H30.0056C29.8145 138.924 29.6595 138.769 29.6595 138.578V55.9004H18.8745C10.2753 55.9004 3.3042 48.9294 3.3042 40.3301V18.5132Z"
      fill="#F4F4F7"
    />
    <path
      d="M18.8745 55.9006H27.9295C28.885 55.9006 29.6595 56.6752 29.6595 57.6306V138.578C29.6595 138.769 29.8145 138.924 30.0056 138.924H32.7494C32.9405 138.924 33.0954 138.769 33.0954 138.578V57.6306C33.0954 56.6752 33.87 55.9006 34.8254 55.9006H44.825C53.4243 55.9006 60.3953 48.9295 60.3953 40.3303V31.1519H3.3042V40.3303C3.3042 48.9295 10.2753 55.9006 18.8745 55.9006Z"
      fill="#2486FD"
      fillOpacity="0.2"
    />
    <line
      y1="-0.173003"
      x2="85.565"
      y2="-0.173003"
      transform="matrix(-0.0849196 0.996388 -0.997701 -0.0677706 7.45654 55.5366)"
      stroke="#566369"
      strokeOpacity="0.32"
      strokeWidth="0.346007"
    />
    <line
      y1="-0.173003"
      x2="85.565"
      y2="-0.173003"
      transform="matrix(0.0849196 0.996388 0.997701 -0.0677706 56.2434 55.5366)"
      stroke="#566369"
      strokeOpacity="0.32"
      strokeWidth="0.346007"
    />
    <path
      d="M7.50757 55.6123L57.6272 74.2208"
      stroke="#566369"
      strokeOpacity="0.32"
      strokeWidth="0.242205"
    />
    <path
      d="M57.6272 74.2203L4.39297 93.8259"
      stroke="#566369"
      strokeOpacity="0.32"
      strokeWidth="0.242205"
    />
    <path
      d="M4.51523 93.7698L60.9141 114.876"
      stroke="#566369"
      strokeOpacity="0.32"
      strokeWidth="0.242205"
    />
    <path
      d="M0.536133 139.443L61.0874 114.877"
      stroke="#566369"
      strokeOpacity="0.32"
      strokeWidth="0.242205"
    />
    <path
      d="M24.4378 27.6308V23.4136H23.4331V22.771C23.755 22.771 24.0146 22.7412 24.2119 22.6815C24.4118 22.6192 24.5611 22.5309 24.6597 22.4167C24.7584 22.2998 24.8168 22.161 24.835 22H25.5904V27.6308H24.4378Z"
      fill="#3B3B3B"
    />
    <path
      d="M27.769 27.6308V23.4136H26.7644V22.771C27.0863 22.771 27.3459 22.7412 27.5432 22.6815C27.7431 22.6192 27.8924 22.5309 27.991 22.4167C28.0897 22.2998 28.1481 22.161 28.1662 22H28.9217V27.6308H27.769Z"
      fill="#3B3B3B"
    />
    <path
      d="M34.3551 23.6044V24.3949H33.5062V27.6308H32.3691V24.3949H31.6253V23.6044H32.3691V23.0241C32.3691 22.6996 32.4639 22.4478 32.6534 22.2687C32.8455 22.0896 33.1168 22 33.4672 22H34.3901L34.394 22.7555H33.8644C33.719 22.7555 33.6191 22.7918 33.5646 22.8645C33.5127 22.9346 33.4867 23.0514 33.4867 23.215V23.6044H34.3551Z"
      fill="#3B3B3B"
    />
    <path
      d="M37.5227 24.3949H36.6271L36.6349 26.5405C36.6349 26.6391 36.6466 26.7131 36.6699 26.7625C36.6959 26.8092 36.7348 26.8403 36.7867 26.8559C36.8413 26.8689 36.9101 26.8754 36.9931 26.8754H37.5344V27.5763C37.4825 27.5997 37.4007 27.6217 37.2891 27.6425C37.1801 27.6633 37.0269 27.6737 36.8296 27.6737C36.4765 27.6737 36.2026 27.6282 36.0079 27.5374C35.8158 27.4465 35.6821 27.3193 35.6068 27.1558C35.5316 26.9922 35.4939 26.8027 35.4939 26.5872V24.3949H34.8397V23.6044H35.5329L35.8171 22.3894H36.6271V23.6005H37.5227V24.3949Z"
      fill="#3B3B3B"
    />
    <path d="M3 31L60 31" stroke="#2486FD" strokeWidth="0.778816" />
  </svg>
);
