import { Box, Typography } from '@mui/material';

export const NoDataAvailableBox = () => (
  <Box
    sx={{
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '2rem',
    }}
  >
    <Typography sx={{ fontWeight: '600' }}>No data available</Typography>
  </Box>
);
