import { AlarmRule } from 'src/features/alarm-rules/types';
import { AlarmData } from '../types';

export function initializeAlarmData() {
  const initialAlarmData: AlarmData = {
    id: '',
    eventStatus: '',
    title: '',
    priority: '',
    siteId: '',
    description: '',
    ruleId: '',
    siteName: '',
    asset: { id: '', name: '' },
    gateway: { id: '', name: '' },
    mainTag: { name: '', limit: '', value: 0 },
    additionalTag: { name: '', limit: '', value: 0 },
    startDate: '',
    endDate: '',
    durationInSeconds: '',
    acknowledgment: { firstName: '', lastName: '', userId: '', timestamp: '' },
  };
  return initialAlarmData;
}

export function initializeAlarmRule() {
  const initialAlarmRule: AlarmRule = new AlarmRule(
    '',
    '',
    '',
    '',
    '',
    0,
    {
      tagId: '',
      tagName: '',
      tagConnectedAsset: '',
    },
    'Critical'
  );

  return initialAlarmRule;
}
