import { ReactNode } from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import { StyledBoxIcon, timeBoxOption, timeBoxTitle } from './style';
import { SelectedBoxType } from '../../types';
import { TimeOptionType } from 'src/features/trends/types';

type Props = {
  title: SelectedBoxType;
  icon: ReactNode;
  selectedBox?: SelectedBoxType;
  onSelectBoxHandler?: (value: SelectedBoxType) => void;
  onSelectTimestampHandler?: (days: number) => void;
  onTemporarySelectTabletTimeOption?: (timeOption: TimeOptionType) => void;
  isTablet?: boolean;
  temporarySelectedTabletTimeOption?: TimeOptionType;
  onResetZoomHandler?: () => void;
  onSelectTemporaryTimestampHandler?: (days: number) => void;
};

export const TimeBoxOption = ({
  title,
  icon,
  selectedBox,
  onSelectBoxHandler,
  onSelectTimestampHandler,
  onTemporarySelectTabletTimeOption,
  isTablet,
  temporarySelectedTabletTimeOption,
  onResetZoomHandler,
  onSelectTemporaryTimestampHandler,
}: Props) => {
  const theme = useTheme();
  const getDays = () => {
    if (title === '1d') return 1;
    if (title === '7d') return 7;
    if (title === '30d') return 30;
    return 0;
  };

  const onClickHandler = () => {
    if (onSelectBoxHandler) onSelectBoxHandler(title);
    if (onTemporarySelectTabletTimeOption) onTemporarySelectTabletTimeOption(title);
    if (!isTablet && onSelectTimestampHandler) onSelectTimestampHandler(getDays());
    if (onResetZoomHandler) onResetZoomHandler();
    if (onSelectTemporaryTimestampHandler) onSelectTemporaryTimestampHandler(getDays());
  };

  const isActive =
    title === selectedBox || (isTablet && title === temporarySelectedTabletTimeOption);

  return (
    <Box sx={timeBoxOption} onClick={onClickHandler}>
      <StyledBoxIcon theme={theme} isActive={isActive!!}>
        {icon}
      </StyledBoxIcon>
      <Typography sx={timeBoxTitle}>{title}</Typography>
    </Box>
  );
};
