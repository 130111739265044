const formFooter = {
  display: 'flex',
  marginTop: '3rem',
  '@media (max-width:600px)': {
    flexDirection: 'column',
  },
};

const lastEditedBox = {
  display: 'flex',
  alignItems: 'center',
  marginRight: '48px',
  '@media (max-width:600px)': {
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginBottom: '1rem',
  },
};

const cancelBtn = {
  marginRight: '1rem',
  '@media (max-width:600px)': {
    margin: '0 0 0.5rem 0',
  },
};

export { formFooter, lastEditedBox, cancelBtn };
