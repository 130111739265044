import { IconsTypesProps } from 'src/assets/icons/types';

export const WellFilterIcon = ({ width = 22, height = 22 }: IconsTypesProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="6.81641" y="2" width="12" height="20" rx="1" stroke="#0461C1" />
    <path
      d="M7.81641 10C7.81641 9.44772 8.26412 9 8.81641 9H16.8164C17.3687 9 17.8164 9.44772 17.8164 10V20.7C17.8164 20.8657 17.6821 21 17.5164 21H8.11641C7.95072 21 7.81641 20.8657 7.81641 20.7V10Z"
      fill="#049ACC"
    />
    <path
      d="M12.6757 4.55526C12.7537 4.47813 12.8792 4.47813 12.9571 4.55526L15.0688 6.6453C15.1958 6.77099 15.1068 6.98744 14.9281 6.98744H10.7047C10.526 6.98744 10.437 6.77099 10.564 6.6453L12.6757 4.55526Z"
      fill="#049ACC"
    />
  </svg>
);
