import React, { useState, useEffect } from 'react';
import { Box, Popover } from '@mui/material';
import { WidgetAdditonalOptionItem } from '../WidgetAdditonalOptionItem';
import { SubTypeWidget } from '../../types';
import { widgetsAddtionalOptions, widgetsAddtionalOptionsWrapper } from './style';
import { ArrowDownIcon } from 'src/assets';

type Props = {
  subTypes: SubTypeWidget[];
  setSelectedWidgetSubType: (subType: string) => void;
  selectedWidgetSubtype: string;
};

export const WidgetsAdditionalOptions = ({
  subTypes,
  setSelectedWidgetSubType,
  selectedWidgetSubtype,
}: Props) => {
  useEffect(() => {
    setSelectedWidgetSubType(subTypes[0].name ?? '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onOpenPopover = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };

  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

  const open = Boolean(anchorEl);
  const popoverId = open ? 'simple-popover' : undefined;

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <Box sx={widgetsAddtionalOptionsWrapper}>
      <Box onClick={onOpenPopover} aria-describedby={popoverId}>
        <ArrowDownIcon />
      </Box>

      <Popover
        id={popoverId}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{ vertical: 20, horizontal: -40 }}
      >
        <Box sx={widgetsAddtionalOptions}>
          {subTypes.map((subType: any) => (
            <WidgetAdditonalOptionItem
              key={subType.id}
              subType={subType}
              activeOption={selectedWidgetSubtype}
              setActiveOption={setSelectedWidgetSubType}
              handleClose={handleClose}
            />
          ))}
        </Box>
      </Popover>
    </Box>
  );
};
