import { Box } from '@mui/material';
import { timeframeSection, calendarsBox } from './style';
import { Calendar } from 'src/features/reports';

type TimeframeProps = {
  handleSelectDate: (date: any) => void;
  disabled?: boolean;
};

export const Timeframe = ({ handleSelectDate, disabled }: TimeframeProps) => (
  <Box sx={timeframeSection}>
    <Box sx={calendarsBox}>
      <Calendar onChange={handleSelectDate} disabled={disabled} />
    </Box>
  </Box>
);
