export function filterOptions(options: string[], state: any, labels: string[]): string[] {
  if (state.inputValue === '') {
    // Show all options when no text is entered (except for the empty value option)
    return options.filter((option) => option !== '');
  }

  return options.filter((option, index) =>
    labels[index]?.toLowerCase()?.includes(state.inputValue?.toLowerCase())
  );
}

export function uniqueFilterOptions(
  options: string[],
  state: any,
  labels: string[],
  selectedOptions: string[]
): string[] {
  if (state.inputValue === '') {
    return options.filter((option) => option !== '' && !selectedOptions.includes(option));
  }
  return options.filter(
    (option, index) =>
      labels[index]?.toLowerCase()?.includes(state.inputValue?.toLowerCase()) &&
      !selectedOptions.includes(option)
  );
}
