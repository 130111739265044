import { Box, Typography, useTheme } from '@mui/material';
import { CloseIcon, ExportIcon, RefreshIcon } from 'src/assets';
// import { LightTooltip } from 'src/components';
import { CSVLink } from 'react-csv';
import { AlarmData } from 'src/features/alarms';
import { GraphSeriesType } from '../../types/readings';
import { formatAlarmsDataForExport } from '../../utils/formatAlarmsDataForExport';
import { formatTagDataForExport } from '../../utils/formatTagDataForExport';
import {
  tooltipItemBox,
  tooltipItemText,
  removeBox,
  // tooltipSyncItemBox,
  // syncBox,
  // tooltipText,
  StyledMenuPopover,
} from './style';

type Props = {
  htmlElement: any;
  onClosePopover: () => void;
  onRemoveHandler: () => void;
  isTimelineChart: boolean;
  pointChartData?: GraphSeriesType[];
  alarms?: AlarmData[];
  handleDownloadPNGClick: () => void;
  onSelectDataHandler: () => void;
  isSyncTimeframe: boolean;
  toggleSyncTimeframe: () => void;
};

export const MenuPopoverOptions = ({
  htmlElement,
  onClosePopover,
  pointChartData,
  alarms,
  isTimelineChart,
  handleDownloadPNGClick,
  onRemoveHandler,
  onSelectDataHandler,
}: // isSyncTimeframe,
// toggleSyncTimeframe,
Props) => {
  const tagForExport = pointChartData ? formatTagDataForExport(pointChartData) : [];
  const alarmsForExport = alarms ? formatAlarmsDataForExport(alarms) : [];

  const theme = useTheme();

  // const onToggleSyncHandler = () => {
  //   toggleSyncTimeframe();
  // };

  return (
    <StyledMenuPopover open={htmlElement} onClose={onClosePopover} arrow="right-top" theme={theme}>
      <Box sx={{ padding: '0.3rem 1rem' }}>
        {/* <Box sx={tooltipSyncItemBox}>
          <Box sx={syncBox}>
            <Switch size="small" onChange={onToggleSyncHandler} checked={isSyncTimeframe} />
            <Typography>Sync</Typography>
          </Box>
          <LightTooltip
            arrow
            enterTouchDelay={0}
            title={
              <Typography variant="body1" sx={tooltipText}>
                Sync on - this chart shows the same timeframe as others. Sync off - this chart can
                show a unique timeframe.
              </Typography>
            }
          >
            <Stack justifyContent="center" alignItems="center">
              <InfoIconV2 fill={theme.palette.grey[600]} width={16} height={16} />
            </Stack>
          </LightTooltip>
        </Box> */}

        <Box sx={tooltipItemBox} data-sm="select-data" onClick={onSelectDataHandler}>
          <RefreshIcon />
          <Typography sx={tooltipItemText}>Select data</Typography>
        </Box>

        <CSVLink
          data-sm="download-csv"
          onClick={onClosePopover}
          style={{ textDecoration: 'none', color: 'inherit' }}
          data={isTimelineChart ? alarmsForExport : tagForExport || []}
        >
          <Box sx={tooltipItemBox}>
            <ExportIcon width={10} height={10} />
            <Typography sx={tooltipItemText}>Download CSV</Typography>
          </Box>
        </CSVLink>

        <Box sx={tooltipItemBox} onClick={handleDownloadPNGClick} data-sm="download-png">
          <ExportIcon width={10} height={10} />
          <Typography sx={tooltipItemText}>Download PNG</Typography>
        </Box>
        <Box sx={removeBox} onClick={onRemoveHandler} data-sm="remove-button">
          <CloseIcon width={10} />
          <Typography sx={tooltipItemText}>Remove</Typography>
        </Box>
      </Box>
    </StyledMenuPopover>
  );
};
