import { UserTypes } from '../types';

export const createUser = (idToken: any): UserTypes | null =>
  idToken !== ''
    ? {
        userId: idToken['custom:user_id'],
        customerId: idToken['custom:customer_id'],
        firstName: idToken.first_name,
        lastName: idToken.last_name,
        mobilePhone: idToken.mobile_phone,
        email: idToken.email,
        jobTitle: idToken.job_title,
        accessRoles: idToken.access_roles,
        accessRole: idToken.access_role,
        status: idToken.status,
      }
    : null;
