import { useState, useCallback, useEffect } from 'react';
import { useAuthentication } from 'src/features/authentication/context';
import { getLatestControlsForInhand } from 'src/features/widgets/utils/getLatestControlsForInhand';
import { LatestControlsForInhandTypes } from './types';
import { ReadingsService } from 'src/services';

type UseControllerTypes = {
  gatewayId?: string;
  controllerModalContentState: boolean;
};

export const useController = ({ gatewayId, controllerModalContentState }: UseControllerTypes) => {
  const [isDataLoading, setIsDataLoading] = useState<boolean>(false);
  const [isBtnApplyDisabled, setIsBtnApplyDisabled] = useState<boolean>(true);
  const [isCloseBtn, setIsCloseBtn] = useState<boolean>(false);
  const [isAppliedChanges, setIsAppliedChanges] = useState<boolean>(false);
  const [isErrorOnUpdateControls, setIsErrorOnUpdateControls] = useState<boolean>(false);
  const [latestControlsForInhand, setLatestControlsForInhand] = useState<
    LatestControlsForInhandTypes[] | []
  >([]);
  const enableApplyButtonHandler = () => {
    setIsBtnApplyDisabled(false);
  };

  const disableApplyButtonHandler = () => {
    setIsBtnApplyDisabled(true);
  };

  const isErrorLastControls =
    !isDataLoading && latestControlsForInhand.some((item) => !item.success);
  const isSuccessfulUpdate = !isErrorLastControls && !isErrorOnUpdateControls;
  const [lastUpdateReadingTimestamp, setLastUpdateReadingTimestamp] = useState<string>('');

  const {
    customerId: { value: customerId },
    siteId: { value: siteId },
  } = useAuthentication();

  const setIsDataLoadingHandler = useCallback((value: boolean) => {
    setIsDataLoading(value);
  }, []);

  const setErrorOnUpdateControls = useCallback((value: boolean) => {
    setIsErrorOnUpdateControls(value);
  }, []);

  const setIsAppliedChangedHandler = (value: boolean) => {
    setIsAppliedChanges(value);
  };

  const latestControl = useCallback(
    async (customerId: string, gatewayId: string, formTagIds: string[]) => {
      if (!customerId || !gatewayId) return;

      try {
        const reponseData: any = await getLatestControlsForInhand(
          formTagIds,
          customerId,
          gatewayId
        );

        const isEveryItemCompleted = reponseData?.every((item: any) => item?.complete !== 0);
        setIsDataLoading(!isEveryItemCompleted);
        setLatestControlsForInhand(reponseData);
        if (isEveryItemCompleted && controllerModalContentState) setIsCloseBtn(true);
      } catch (err) {
        console.error(err);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isCloseBtn, controllerModalContentState]
  );

  useEffect(() => {
    if (!customerId || !siteId || !gatewayId) return;

    try {
      ReadingsService.getLatestReadings(customerId, gatewayId, siteId).then((response) => {
        const currentTime = Date.now();
        const timestampInMillis = response.timestamp * 1000;
        const difference = currentTime - timestampInMillis;

        const hours = Math.floor(difference / (1000 * 60 * 60));

        const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));

        setLastUpdateReadingTimestamp(
          `${isNaN(hours) ? '-' : hours}h ${isNaN(minutes) ? '-' : minutes}min`
        );
      });
    } catch (err) {
      console.error(err);
    }
  }, [customerId, gatewayId, siteId]);

  return {
    isDataLoading,
    isErrorLastControls,
    isErrorOnUpdateControls,
    isSuccessfulUpdate,
    setIsDataLoadingHandler,
    setErrorOnUpdateControls,
    latestControl,
    latestControlsForInhand,
    lastUpdateReadingTimestamp,
    isAppliedChanges,
    setIsAppliedChangedHandler,
    enableApplyButtonHandler,
    disableApplyButtonHandler,
    isBtnApplyDisabled,
    isCloseBtn,
    setIsCloseBtn,
  };
};
