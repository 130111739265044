import { IconButton, InputAdornment, TextFieldProps } from '@mui/material';
import { useRef, useState } from 'react';
import { UseFormSetValue } from 'react-hook-form';
import { VisibilityOffIcon, VisibilityOnIcon } from 'src/assets';
import { RHFTextField } from 'src/components/minimals/form';
import { useToggle } from 'src/hooks';

type PasswordTextFieldProps = TextFieldProps & {
  name: string;
  currentValue: string;
  setValue: UseFormSetValue<any>;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  label?: string;
  testId?: string;
};

export const PasswordTextField = ({
  name = 'password',
  currentValue,
  setValue,
  label = 'Password',
  onChange,
  testId = 'password-input',
  onFocus,
  ...other
}: PasswordTextFieldProps) => {
  const isEdge = /Edg/.test(navigator.userAgent) || /Edge/.test(navigator.userAgent);
  const { state: showPassword, onToggle: setShowPassword } = useToggle();
  const [isAutoFill, setIsAutoFill] = useState(false);
  const componentRef = useRef<HTMLInputElement>(null);
  return (
    <RHFTextField
      name={name}
      ref={componentRef}
      label={label}
      type={showPassword ? 'text' : 'password'}
      testId={testId}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
        onChange ? onChange(e) : setValue(name, e.target.value)
      }
      onFocus={onFocus}
      InputLabelProps={{ shrink: isAutoFill || undefined }}
      InputProps={{
        onAnimationStart: (e: React.AnimationEvent<HTMLDivElement>) => {
          e.animationName === 'mui-auto-fill' && setIsAutoFill(true);
        },
        onAnimationEnd: (e: React.AnimationEvent<HTMLDivElement>) =>
          e.animationName === 'mui-auto-fill-cancel' && setIsAutoFill(false),
        endAdornment: (
          <InputAdornment
            position="end"
            style={{ display: !isEdge && (currentValue !== '' || isAutoFill) ? 'flex' : 'none' }}
          >
            <IconButton onClick={setShowPassword} edge="end" sx={{ marginRight: '0px' }}>
              {showPassword ? <VisibilityOffIcon /> : <VisibilityOnIcon />}
            </IconButton>
          </InputAdornment>
        ),
      }}
      {...other}
    />
  );
};
