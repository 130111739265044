import {
  getNavItemsHeaderSiutesManagementSAAndAA,
  NAV_ITEMS_HEADER_SITES_MANAGEMENT,
} from 'src/features';
import { useAuthentication } from 'src/features/authentication/context';
import { Protected } from 'src/features/protected-route/components';
import { AccessRoles } from 'src/features/user-account-details/types';
import { AppLayout } from 'src/layouts';
import { FormsAddFormPage, FormsEditFormPage, FormsManagementPage } from 'src/routes/pages';

type FormsRouteElementType = {
  isSuperAdmin: boolean;
  getUniqueRedirectPath: () => string | undefined;
  route: 'management' | 'add' | 'edit' | '';
};

export const FormsRouteElement = ({
  isSuperAdmin,
  getUniqueRedirectPath,
  route,
}: FormsRouteElementType) => {
  const { loggedIn, getCurrentRole, customerId, user } = useAuthentication();
  const isAccountAdmin = getCurrentRole(customerId.value, null) === AccessRoles.ACCOUNT_ADMIN;

  const getPageInfo = () => {
    if (route === 'management') {
      return { title: 'Forms', page: <FormsManagementPage /> };
    } else if (route === 'add') {
      return { title: 'Add Form', page: <FormsAddFormPage /> };
    } else if (route === 'edit') {
      return { title: 'Edit Form', page: <FormsEditFormPage /> };
    } else {
      return { title: '', page: <></> };
    }
  };

  return (
    <Protected
      isAuthenticated={loggedIn.value}
      isAuthorized={
        isSuperAdmin || getCurrentRole(customerId.value, null) === AccessRoles.ACCOUNT_ADMIN
      }
      uniqueRedirectPath={getUniqueRedirectPath()}
    >
      <AppLayout
        headerTitle={getPageInfo().title}
        headerNavItems={
          isSuperAdmin || isAccountAdmin
            ? getNavItemsHeaderSiutesManagementSAAndAA(user, isSuperAdmin)
            : NAV_ITEMS_HEADER_SITES_MANAGEMENT
        }
      >
        {getPageInfo().page}
      </AppLayout>
    </Protected>
  );
};
