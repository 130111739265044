import { alpha } from '@mui/material/styles';
import { Palette } from './types';
import { ERROR, GREY, INFO, SECONDARY, SUCCESS, WARNING } from './streammetric-palette';

export type ColorSchema = 'primary' | 'secondary' | 'info' | 'success' | 'warning' | 'error';

declare module '@mui/material/styles/createPalette' {
  interface TypeBackground {
    neutral: string;
  }
  interface SimplePaletteColorOptions {
    lighter: string;
    darker: string;
  }
  interface PaletteColor {
    lighter: string;
    darker: string;
  }
}

export const createCommon = (primary: Palette) => ({
  common: { black: '#000', white: '#fff' },
  primary: primary,
  secondary: SECONDARY,
  info: INFO,
  success: SUCCESS,
  warning: WARNING,
  error: ERROR,
  grey: GREY,
  divider: alpha(GREY[500], 0.24),
  action: {
    hover: alpha(GREY[500], 0.08),
    selected: alpha(GREY[500], 0.16),
    disabled: alpha(GREY[500], 0.8),
    disabledBackground: alpha(GREY[500], 0.24),
    focus: alpha(GREY[500], 0.24),
    hoverOpacity: 0.08,
    disabledOpacity: 0.48,
  },
});

export const palette = (themeMode: 'light' | 'dark', COMMON: any) => {
  const light = {
    ...COMMON,
    scrollbar: {
      thumb: GREY[400],
      thumbHover: GREY[500],
      background: GREY[100],
    },
    mode: 'light',
    text: {
      primary: GREY[800],
      secondary: GREY[600],
      disabled: GREY[500],
    },
    background: { paper: '#F4F6F8', default: '#fff', neutral: GREY[200] },
    action: {
      ...COMMON.action,
      active: GREY[600],
    },
  } as const;

  const dark = {
    ...COMMON,
    scrollbar: {
      thumb: GREY[400],
      thumbHover: GREY[500],
      background: GREY[100],
    },
    mode: 'dark',
    text: {
      primary: '#fff',
      secondary: GREY[500],
      disabled: GREY[600],
    },
    background: {
      default: GREY[900],
      paper: GREY[800],
      neutral: alpha(GREY[500], 0.16),
    },
    action: {
      ...COMMON.action,
      active: GREY[500],
    },
  } as const;

  return themeMode === 'light' ? light : dark;
};

export const scrollbar = (themeMode: 'light' | 'dark') => {
  const light = {
    thumb: GREY[400],
    thumbHover: GREY[500],
    background: GREY[100],
  } as const;

  const dark = {
    thumb: GREY[900],
    thumbHover: alpha(GREY[900], 0.8),
    background: GREY[700],
  } as const;

  return themeMode === 'light' ? light : dark;
};

export const sidebar = (themeMode: 'light' | 'dark') => {
  const light = {
    background: '#21374E',
    unselectedText: '#BCC7D1',
    selectedSiteBackground: '#2B4764',
    selectedNavItemBackground: '#3A5776',
    alarmNotificationColor: '#FF4842',
  } as const;

  const dark = {
    background: '#21374E',
    unselectedText: '#BCC7D1',
    selectedSiteBackground: '#2B4764',
    selectedNavItemBackground: '#3A5776',
    alarmNotificationColor: '#FF4842',
  } as const;

  return themeMode === 'light' ? light : dark;
};
