import { AlarmData } from 'src/features/alarms';
import { timestampConverter } from 'src/utilities';

export const formatAlarmsDataForExport = (alarms: AlarmData[]) =>
  alarms.map((alarm) => {
    const {
      id,
      acknowledgment,
      asset,
      description,
      startDate,
      endDate,
      eventStatus,
      gateway,
      mainTag,
      priority,
      ruleId,
      siteId,
      siteName,
      title,
    } = alarm;

    const timestampStartDate = new Date(startDate).getTime();
    const timestampEndDate = new Date(endDate).getTime();
    const formatedStartDate = startDate ? timestampConverter(timestampStartDate) : '';
    const formatedEndDate = endDate ? timestampConverter(timestampEndDate) : '';
    return {
      id,
      acknowledgment,
      assetId: asset?.id,
      assetName: asset?.name,
      description,
      startDate: formatedStartDate,
      endDate: formatedEndDate,
      eventStatus,
      gatewayId: gateway?.id,
      gatewayName: gateway?.name,
      mainTagName: mainTag?.name,
      mainTagLimit: mainTag?.limit,
      mainTagValue: mainTag?.value,
      priority,
      ruleId,
      siteId,
      siteName,
      title,
    };
  });
