import { styled, Theme } from '@mui/material/styles';
import { Box } from '@mui/material';

type StylesTypes = {
  theme: Theme;
  isActive: boolean;
};

const StyledWidgetAdditionalOptionItem = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isActive',
})(({ isActive, theme }: StylesTypes) => ({
  cursor: 'pointer',
  border: `1px solid ${isActive ? theme.palette.primary.main : 'none'}`,
  borderRadius: '8px',
  marginRight: '0.5rem',
}));

export { StyledWidgetAdditionalOptionItem };
