import { SxProps, Theme, Typography } from '@mui/material';
import { FunctionComponent, PropsWithChildren } from 'react';

const SectionTitle: FunctionComponent<PropsWithChildren<{ sx?: SxProps<Theme> }>> = ({
  children,
  sx,
}) => (
  <Typography sx={{ fontWeight: 'bold', ...sx }} variant="subtitle1">
    {children}
  </Typography>
);

export default SectionTitle;
