import { mimeTypeToUserFriendlyType } from './mimeTypesToUserFriendly';

export const required = () => 'This field is required';
export const maxLength = (number: number) =>
  'The field must contain less than ' + number + ' characters';
export const minLength = (number: number) =>
  'The field must contain more than ' + number + ' characters';
export const max = (number: number) => 'The field must be less than ' + number;
export const min = (number: number) => 'The field must be more than ' + number;
export const mimeTypes = (mimeTypes: string[]) =>
  `Allowed file types are: ${mimeTypes
    .map((mimeType: string) => mimeTypeToUserFriendlyType[mimeType])
    .filter(Boolean)
    .join(', ')}`;
