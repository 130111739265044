export type AlarmPriorityType = 'Critical' | 'Warning' | 'Info' | 'Offline';

export type Tag = {
  tagId: string;
  tagName: string;
  tagDisplayName?: string;
  tagConnectedAsset?: string;
  tagConnectedAssetId?: string;
  tagRawUnit?: string;
};

export type AdditionalTag = {
  additionalTagId?: string;
  additionalTagName?: string;
  additionalTagDisplayName?: string;
  operator?: string;
  value?: number;
  additionalTagConnectedAsset?: string;
  additionalTagRawUnit?: string;
};

export type TagResponse = {
  connectedAsset: string;
  dataType: string;
  displayName: string;
  gatewayId: string;
  gatewayName?: string;
  hidden: boolean;
  siteName?: string;
  id: string;
  rawInputName: string;
  rawUnit: string;
  connectedAssetId: string;
  forwarded?: boolean;
  siteForwardedFrom?: string;
  gatewayForwardedFrom?: string;
  parentType?: string;
  tagId?: string;
};

export class AlarmRule {
  id: string;

  gatewayId: string;

  title: string;

  lowLimit: string;

  highLimit: string;

  acknowledgementRequired: boolean = true;

  notificationDelay: number;

  createdAt: string = '';

  updatedAt: string = '';

  description: string = '';

  alarmPriority: AlarmPriorityType;

  tag: Tag;

  additionalTag?: AdditionalTag;

  subscriptionType: string = '';

  constructor(
    id: string,
    gatewayId: string,
    title: string,
    lowLimit: string,
    highLimit: string,
    notificationDelay: number,
    tag: Tag,
    alarmPriority: AlarmPriorityType
  ) {
    this.id = id;
    this.gatewayId = gatewayId;
    this.title = title;
    this.lowLimit = lowLimit;
    this.highLimit = highLimit;
    this.notificationDelay = notificationDelay;
    this.tag = tag;
    this.alarmPriority = alarmPriority;
  }
}

export class NewAlarmRule {
  gatewayId: string;

  title: string;

  lowLimit?: string | null;

  highLimit?: string | null;

  acknowledgementRequired: boolean = true;

  notificationDelay: number;

  description: string = '';

  alarmPriority?: AlarmPriorityType;

  tag: Tag;

  additionalTag?: AdditionalTag;

  constructor(gatewayId: string, title: string, notificationDelay: number, tag: Tag) {
    this.gatewayId = gatewayId;
    this.title = title;
    this.notificationDelay = notificationDelay;
    this.tag = tag;
  }
}
