import { api, SITES_ROOT, GATEWAYS_URL_END, COUNT_URL, DISCONNECT } from '../api';
import { GATEWAY_PATH_ROOT } from 'src/routes/config';
import {
  EditGatewayTypes,
  NewGatewayTypes,
  DisconnectGatewayParamsTypes,
  GetAllParamsTypes,
  GetAllV2ParamsTypes,
} from './types';

// SUMMARY:
// Endpoint for getting all gateways for specific site.
const getAll = async (customerId: string, siteId: string, params?: GetAllParamsTypes) => {
  const response = await api({
    method: 'get',
    url: `${SITES_ROOT}/${customerId}/${siteId}${GATEWAY_PATH_ROOT}`,
    params,
  });

  return response.data;
};
// Endpoint for getting all gateways for specific site - V2.
const getAllV2 = async ({ siteId }: GetAllV2ParamsTypes) => {
  const response = await api({
    method: 'get',
    url: `/v2/integrations?siteId=${siteId}`,
  });
  return response.data;
};

// SUMMARY:
// Endpoint for getting gateway by id for specific site.
const getById = async (customerId: string, siteId: string, gatewayId: string) => {
  const response = await api({
    method: 'get',
    url: `${SITES_ROOT}/${customerId}/${siteId}${GATEWAYS_URL_END}/${gatewayId}`,
  });

  return response.data;
};

// SUMMARY:
// Endpoint for creating gateway for specific site.
const add = async (
  data: NewGatewayTypes,
  customerId: string,
  siteId: string
): Promise<ArrayBuffer> => {
  const response = await api({
    method: 'post',
    url: `${SITES_ROOT}/${customerId}/${siteId}${GATEWAYS_URL_END}`,
    data,
    responseType: 'arraybuffer',
    transformResponse: [(data: ArrayBuffer) => data],
  });

  return response.data;
};

const edit = async (
  data: EditGatewayTypes,
  customerId: string,
  siteId: string,
  gatewayId: string
) => {
  const response = await api({
    method: 'patch',
    url: `${SITES_ROOT}/${customerId}/${siteId}${GATEWAYS_URL_END}/${gatewayId}`,
    data,
  });

  return response.data;
};

// SUMMARY:
// Endpoint for getting gateways count for specific site.
const getCount = async (customerId: string, siteId: string) => {
  const response = await api({
    method: 'get',
    url: `${SITES_ROOT}/${customerId}/${siteId}${GATEWAY_PATH_ROOT}${COUNT_URL}`,
  });

  return response.data;
};

const disconnectGateway = async ({
  customerId,
  siteId,
  gatewayId,
}: DisconnectGatewayParamsTypes) => {
  const response = await api({
    method: 'post',
    url: `${SITES_ROOT}/${customerId}/${siteId}${GATEWAYS_URL_END}/${gatewayId}/${DISCONNECT}`,
  });
  return response;
};

export const GatewayService = {
  add,
  getAll,
  getAllV2,
  getCount,
  getById,
  edit,
  disconnectGateway,
};
