import { Stack, Typography } from '@mui/material';
import { formatUTCDateToLocal } from 'src/utilities';

type LastEditedOnProps = {
  isEdit: boolean;
  isMobile: boolean;
  currentEntity: any;
};

export const LastEditedOn = ({ isEdit, isMobile, currentEntity }: LastEditedOnProps) =>
  isEdit && currentEntity ? (
    <Stack
      flexDirection="row"
      alignItems="center"
      sx={!isMobile ? { mr: '48px', mb: 0 } : { mr: 0, mb: '8px' }}
    >
      <Typography variant="body2" color="text.secondary">
        Last edited on: &nbsp;
      </Typography>
      <Typography variant="subtitle2" color="text.secondary">
        {formatUTCDateToLocal(false, currentEntity.updatedAt)}
      </Typography>
    </Stack>
  ) : null;
