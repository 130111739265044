import { Box, Theme, keyframes, styled } from '@mui/material';

type CustomThemeProps = {
  theme?: Theme;
  customColor?: string;
};

export const Circle = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'customColor',
})<CustomThemeProps>(({ theme, customColor }) => ({
  position: 'relative',
  width: '100%',
  height: '100%',
  backgroundColor: 'transparent',
  border: `2px solid ${customColor ? customColor : theme.palette.primary.main}`,
  borderRadius: '50%',
  overflow: 'hidden',
  WebkitBackfaceVisibility: 'hidden',
  MozBackfaceVisibility: 'hidden',
  WebkitTransform: 'translate3d(0, 0, 0)',
  MozTransform: 'translate3d(0, 0, 0)',
}));

const waveAnimation = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(3600deg); }
`;

const topTransition = keyframes`
  0%, 100% { top: 110%; }
  50% { top: 0%; }
`;

export const Wave = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'customColor',
})<CustomThemeProps>(({ theme, customColor }) => ({
  backgroundColor: customColor ? customColor : theme.palette.primary.main,
  position: 'absolute',
  top: '110%',
  height: '200%',
  width: '200%',
  borderRadius: '38%',
  left: '-50%',
  animation: `${waveAnimation} 30s linear infinite, ${topTransition} 5s ease-in-out infinite`,
}));
