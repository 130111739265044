export const UnfavoriteIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.1008 21.3902C17.2431 21.4631 17.4008 21.5008 17.5608 21.5002C17.7816 21.5104 17.9997 21.4471 18.1808 21.3202C18.4975 21.0933 18.6537 20.703 18.5808 20.3202L17.5808 14.6902L21.7008 10.6902C21.9627 10.4288 22.0589 10.0441 21.9508 9.6902C21.8325 9.32763 21.5183 9.06387 21.1408 9.0102L15.4408 8.1802L12.9008 3.0602C12.7337 2.71521 12.3841 2.49609 12.0008 2.49609C11.6174 2.49609 11.2679 2.71521 11.1008 3.0602L8.59076 8.1902L2.89076 9.0202C2.51319 9.07387 2.19901 9.33763 2.08076 9.7002C1.97265 10.0541 2.06882 10.4388 2.33076 10.7002L6.45076 14.7002L5.45076 20.3302C5.384 20.706 5.53703 21.0871 5.84519 21.3124C6.15336 21.5377 6.5629 21.5679 6.90076 21.3902L12.0008 18.7202L17.1008 21.3902ZM12.4608 16.7102C12.3196 16.6341 12.1611 16.5962 12.0008 16.6002C11.8336 16.567 11.6605 16.5808 11.5008 16.6402L7.73076 18.6402L8.45076 14.4802C8.50749 14.1538 8.39888 13.8205 8.16076 13.5902L5.16076 10.6602L9.36076 10.0402C9.68394 9.99345 9.96392 9.79186 10.1108 9.5002L12.0008 5.7502L13.8908 9.5002C14.0247 9.78933 14.2884 9.99731 14.6008 10.0602L18.8008 10.6802L15.8008 13.6102C15.5626 13.8405 15.454 14.1738 15.5108 14.5002L16.2308 18.7102L12.4608 16.7102Z"
      fill="#637381"
    />
  </svg>
);
