import { Checkbox, Stack, Typography } from '@mui/material';
import { FunctionComponent } from 'react';
import { FilterComponentProps } from '../types';

const FilterItem: FunctionComponent<FilterComponentProps> = ({ label, name, onChange, value }) => (
  <Stack sx={{ cursor: 'pointer' }} alignItems="center" flexDirection="row">
    <Checkbox id={name} onChange={(_, checked) => onChange(checked, name)} checked={!!value} />
    <Typography component="label" htmlFor={name} variant="body1">
      {label}
    </Typography>
  </Stack>
);

export default FilterItem;
