import { styled, Theme } from '@mui/material/styles';
import { Typography } from '@mui/material';

type StylesTypes = {
  theme: Theme;
};

const modalContent = {
  maxWidth: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
  padding: '30px 0 32px 0',
};

const iconBox = {
  margin: '0 30px 12px auto',
  cursor: 'pointer',
};

const headingText = {
  margin: '30px 0 24px 0',
  textTransform: 'capitalize',
};

const StyledDescription = styled(Typography)(({ theme }: StylesTypes) => ({
  color: theme.palette.grey[600],
  padding: '0 10px',
}));

export { modalContent, iconBox, headingText, StyledDescription };
