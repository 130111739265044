import { useRef } from 'react';
import { SnackbarProvider as NotistackProvider, SnackbarKey } from 'notistack';
import { Box, IconButton } from '@mui/material';

import StyledNotistack from './styles';
import {
  CloseIcon,
  SnackCheckmarkIcon,
  SnackErrorIcon,
  SnackInfoIcon,
  SnackWarningIcon,
} from 'src/assets';

type Props = {
  children: React.ReactNode;
};

export const SnackbarProvider = ({ children }: Props) => {
  const notistackRef = useRef<any>(null);
  const onClose = (key: SnackbarKey) => () => {
    notistackRef.current.closeSnackbar(key);
  };

  return (
    <>
      <StyledNotistack />

      <NotistackProvider
        ref={notistackRef}
        dense
        maxSnack={5}
        preventDuplicate
        autoHideDuration={3000}
        TransitionComponent={undefined}
        variant="success" // Set default variant
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        iconVariant={{
          info: <SnackbarIcon icon={<SnackInfoIcon />} color="info" />,
          success: <SnackbarIcon icon={<SnackCheckmarkIcon />} color="success" />,
          warning: <SnackbarIcon icon={<SnackWarningIcon />} color="warning" />,
          error: <SnackbarIcon icon={<SnackErrorIcon />} color="error" />,
        }}
        // With close as default
        action={(key) => (
          <IconButton
            size="small"
            onClick={onClose(key)}
            sx={{ p: 0.5, width: '18px', height: '18px' }}
          >
            <CloseIcon />
          </IconButton>
        )}
      >
        {children}
      </NotistackProvider>
    </>
  );
};

type SnackbarIconProps = {
  icon: React.ReactElement | null;
  color: 'info' | 'success' | 'warning' | 'error';
};

function SnackbarIcon({ icon, color }: SnackbarIconProps) {
  return (
    <Box
      component="span"
      sx={{
        mr: 1.5,
        width: 40,
        height: 40,
        display: 'flex',
        borderRadius: 1.5,
        alignItems: 'center',
        justifyContent: 'center',
        color: `${color}.darker`,
      }}
    >
      {icon}
    </Box>
  );
}
