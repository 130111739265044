import { Key } from 'react';
import { Collapse } from '@mui/material';
import { NavItem } from '../navItem';
import { NavSubItem } from '../navSubItem';
import { NavItemListProps, NavSubItemProps } from '../../types';
import { useSubNavigation } from '../../hooks';

export const NavList = ({
  isToggle,
  isActive,
  onClickHandler,
  title,
  path,
  icon,
  testId,
  subItems,
  additionalLabel,
  handleCloseSideBar,
  isSuperAdmin,
  additionalLabelState,
}: NavItemListProps) => {
  const { subItemsActive, onSubitemClickHandler } = useSubNavigation(subItems!);

  const findIsSubNavItemActive = (title: string) => {
    if (subItems && subItemsActive)
      return subItemsActive.find((item: { title: string }) => item.title === title)?.isActive;
  };

  return (
    <>
      <NavItem
        isActive={isActive}
        onClickHandler={onClickHandler}
        path={path}
        title={title}
        icon={icon}
        testId={testId}
        subItems={subItems}
        toggleState={isToggle}
        additionalLabel={additionalLabel}
        handleCloseSideBar={handleCloseSideBar}
        isSuperAdmin={isSuperAdmin}
        additionalLabelState={additionalLabelState}
      />
      {subItems && subItems.length > 0 ? (
        <Collapse in={isToggle && isActive} unmountOnExit>
          {subItems.map(({ path, title, testId }: NavSubItemProps, i: Key) => (
            <NavSubItem
              key={i}
              path={path}
              title={title}
              testId={testId}
              isActive={findIsSubNavItemActive(title)}
              onClick={onSubitemClickHandler}
              handleCloseSideBar={handleCloseSideBar}
            />
          ))}
        </Collapse>
      ) : null}
    </>
  );
};
