import { createContext, FunctionComponent, PropsWithChildren, useContext } from 'react';

type ToggleState = {
  toggle: boolean;
  onToggle: () => void;
};

const SidebarToggleContext = createContext({ toggle: false, onToggle: () => {} });

const SidebarToggleContextProvider: FunctionComponent<PropsWithChildren<ToggleState>> = ({
  children,
  ...toggleState
}) => <SidebarToggleContext.Provider value={toggleState}>{children}</SidebarToggleContext.Provider>;

export const useSidebarToggleContext = () => useContext(SidebarToggleContext);

export default SidebarToggleContextProvider;
