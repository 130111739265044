import { Container, Stack } from '@mui/material';
import { ReportsHeader } from '../components';
import { useResponsive } from 'src/hooks';
import { Outlet } from 'react-router';
import { useTemplateState } from '../context/templateContext';
import { useState } from 'react';

const BuildReport = ({
  title,
  disableHeaderFormBuilder = false,
}: {
  title: string;
  disableHeaderFormBuilder?: boolean;
}) => {
  const isTablet = useResponsive('down', 'lg');
  const [disableActions, setDisableActions] = useState(false);

  const { undoChanges } = useTemplateState();

  return (
    <Container maxWidth={false} disableGutters>
      {!disableHeaderFormBuilder && (
        <ReportsHeader disableActions={disableActions} onUndo={undoChanges} title={title} />
      )}
      <Stack
        flexDirection={isTablet ? 'column' : 'row'}
        gap="24px"
        sx={{
          marginTop: '24px',
        }}
      >
        <Outlet context={{ title, disableActions, setDisableActions }} />
      </Stack>
    </Container>
  );
};

export default BuildReport;
