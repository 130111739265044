const valveWrapper = { display: 'flex', alignItems: 'center' };

const svgBox = {
  marginRight: '20px',
};

const modeBox = {
  marginLeft: '18px',
};

const modeTitle = {
  fontSize: '14px',
  fontWeight: '700',
};

const modeValue = {
  fontSize: '14px',
};

export { valveWrapper, svgBox, modeBox, modeTitle, modeValue };
