export function processRolesArray(
  roles: string,
  currentCustomerId: string | null,
  currentSiteId: string | null
) {
  const data = roles.slice(1, -1).split(', ');

  const matchingRole = data.find((item) => {
    // eslint-disable-next-line
    const [customerId, siteId, role] = item.slice(1, -1).split(':');

    if (siteId === '*' && customerId === currentCustomerId) {
      return true;
    }

    if (currentSiteId && currentCustomerId) {
      if (customerId === currentCustomerId && siteId === currentSiteId) {
        return true;
      }
    }

    return false;
  });

  return matchingRole
    ? matchingRole.slice(1, -1).split(':').slice(-2, -1)[0] || 'NO_ACCESS'
    : 'NO_ACCESS';
}
