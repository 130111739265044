import { useState, useEffect, useCallback } from 'react';
import { ControllersService } from 'src/services';
import { Controller } from 'src/types';

type UseControllerProps = {
  customerId?: string;
  siteId?: string;
};
export const useControllers = ({ customerId, siteId }: UseControllerProps) => {
  const [controllers, setControllers] = useState<Controller[]>([]);

  const getControllers = useCallback(() => {
    if (!customerId || !siteId) return;
    ControllersService.getAll(customerId, siteId).then((response) => {
      setControllers(response);
    });
  }, [customerId, siteId]);

  useEffect(() => {
    getControllers();
  }, [getControllers]);

  return { controllers, getControllers };
};
