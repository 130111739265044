import { AlarmType } from '../types';

export const transferValueToNewRange = (
  oldMin: number,
  oldMax: number,
  newMin: number,
  newMax: number,
  oldValue: number
) => {
  var oldRange = oldMax - oldMin;
  var newRange = newMax - newMin;
  if (oldRange <= 0 || oldValue < oldMin) {
    return newMin;
  }

  if (oldValue > oldMax) {
    return newMax;
  }

  return ((oldValue - oldMin) * newRange) / oldRange + newMin;
};

export const roundNumber = (num: number) => {
  if (num <= 0.001) return '0';
  const newNum = num % 1;
  return newNum === 0 ? num.toFixed(0) : num.toFixed(1);
};

export const isAlarmLevelValid = (alarm: AlarmType) =>
  alarm.level &&
  alarm.assignedAlarmGatewayId &&
  alarm.assignedAlarmGatewayId !== '' &&
  alarm.assignedAlarmTagId;
