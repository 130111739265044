type TowerIconProps = {
  waterLevel: number | null;
};

export const TowerIcon = ({ waterLevel }: TowerIconProps) => (
  <svg
    style={{ display: 'block', width: '100%', height: 'auto', margin: '0 auto' }}
    version="1.1"
    id="Layer_2"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 155 325"
    xmlSpace="preserve"
  >
    <style type="text/css">
      .st0{'{'}fill:#CADEF8;{'}'}
      .st1{'{'}
      fill:none;stroke:#CCCCCC;stroke-width:0.75;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;
      {'}'}
      .st2{'{'}fill:none;stroke:#CCCCCC;stroke-width:0.7475;stroke-miterlimit:10;{'}'}
    </style>
    {waterLevel !== null && (
      <path
        id="water"
        className="st0"
        d="M80.2,131.7c0,0.2,0,0.3,0,0.3c0,1.6,0,63.2,0,184.8h-8.4c0-121.6,0-183.2,0-184.8c0,0,0-0.2,0-0.3
                    c-0.1-1.8-1.6-3.1-3-4.1h14.4C81.8,128.6,80.3,130,80.2,131.7z"
      />
    )}
    <g id="frame">
      <line className="st1" x1="132.5" y1="127.6" x2="148.8" y2="320.7" />
      <polyline
        className="st1"
        points="5.5,320.7 14.5,214 21.8,127.6 136.1,169.9 14.5,214 143.9,262.4 5.7,318 	"
      />
      <path
        className="st2"
        d="M148.8,37.8v59.8c0,19.2-15.5,34.7-34.7,34.7H90.8c-0.3,0-0.6,0-0.9,0c-5.9,0.2-6.2,4.5-6,9
                        c0,60,0,120.1,0,180.1H68.2V141.4c0.2-4.6-0.1-8.8-6-9c-0.3,0-0.6,0-0.9,0H40.2c-19.1,0-34.7-15.5-34.7-34.7V37.8
                        C5.5,18.7,21,3.2,40.2,3.2h74C133.3,3.2,148.8,18.7,148.8,37.8z"
      />
    </g>
  </svg>
);

export default TowerIcon;
