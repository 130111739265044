import { styled, Theme, Typography } from '@mui/material';

type StylesTypes = {
  theme: Theme;
};

const applyingChangesBox = {
  display: 'flex',
  alignItems: 'center',
  '@media (max-width:600px)': {
    minWidth: '140px',
  },
};
const StyledApplyingChangesTitle = styled(Typography)(({ theme }: StylesTypes) => ({
  fontSize: '0.8rem',
  marginRight: '0.5rem',
  fontWeight: theme.typography.fontWeightLight,
  color: theme.palette.warning.main,
}));

const lastUpdatedTitle = {
  fontSize: '0.8rem',
};

export { applyingChangesBox, StyledApplyingChangesTitle, lastUpdatedTitle };
