const pageWrapper = {
  width: '100%',
  '@media (max-width:600px)': {
    margin: '0 auto',
  },
};

const btnsBox = {
  display: 'flex',
  flexDirection: 'row',
  marginTop: '3rem',
  justifyContent: 'flex-end',
  '@media (max-width:600px)': {
    flexDirection: 'column',
  },
};

const cancelBtn = {
  marginRight: '1rem',
  '@media (max-width:600px)': {
    marginRight: '0',
    marginBottom: '0.5rem',
  },
};

const lastEditedBox = {
  display: 'flex',
  alignItems: 'center',
  marginRight: '48px',
  '@media (max-width:600px)': {
    marginBottom: '1rem',
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
  },
};

export { pageWrapper, btnsBox, cancelBtn, lastEditedBox };
