import { useFormContext, Controller } from 'react-hook-form';
import { Switch, FormControlLabel, FormControlLabelProps } from '@mui/material';
import { InputHTMLAttributes } from 'react';

interface Props extends Omit<FormControlLabelProps, 'control'> {
  name: string;
  testId: string;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
}

interface CustomInputAttributes extends InputHTMLAttributes<HTMLInputElement> {
  'data-sm'?: string;
}

export const RHFSwitch = ({ name, testId, handleChange, ...other }: Props) => {
  const { control } = useFormContext();

  const customSwitchProps: CustomInputAttributes = {
    'data-sm': testId,
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <FormControlLabel
          sx={{ '&.MuiFormControlLabel-labelPlacementEnd': { mr: 0 } }}
          control={
            <Switch
              inputProps={{ ...customSwitchProps }}
              {...field}
              onChange={handleChange}
              checked={field.value}
            />
          }
          {...other}
        />
      )}
    />
  );
};
