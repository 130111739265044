import { Clear } from '@mui/icons-material';
import { IconButton, ListItemButton, ListItemText, Stack, Theme, Typography } from '@mui/material';
import { useEffect, useRef } from 'react';
import { AnimatedList } from './style';

interface SearchSuggestionProps {
  suggestions: string[];
  highlightedIndex: number | null;
  onSelect: (suggestion: string) => void;
  onClear: (suggestion: string) => void;
  isFocused: boolean;
  theme?: Theme;
}

export const SearchSuggestion = ({
  suggestions,
  highlightedIndex,
  onSelect,
  onClear,
  isFocused,
  theme,
}: SearchSuggestionProps) => {
  const listRef = useRef<HTMLUListElement>(null);

  useEffect(() => {
    if (highlightedIndex !== null && listRef.current) {
      const highlightedItem = listRef.current.children[highlightedIndex] as HTMLElement;
      if (highlightedItem) {
        highlightedItem.scrollIntoView({ block: 'nearest' });
      }
    }
  }, [highlightedIndex]);

  return (
    <AnimatedList
      className={`custom-autocomplete-scrollbar ${isFocused ? 'expanded' : ''}`}
      ref={listRef}
      areSuggestionsEmpty={suggestions.length === 0}
    >
      {suggestions.map((suggestion, index) => (
        <ListItemButton
          dense
          key={index}
          onClick={() => onSelect(suggestion)}
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            backgroundColor:
              highlightedIndex === index
                ? theme?.palette.grey[200]
                : theme?.palette.background.default,
            '&:hover': {
              backgroundColor: theme?.palette.grey[200],
            },
          }}
        >
          <ListItemText>
            <Stack sx={{ flexDirection: 'row', alignItems: 'center' }}>
              <Typography variant="body2">{suggestion}</Typography>
              {highlightedIndex === index ? (
                <Typography variant="body2" fontSize={'12px'} color="GrayText">
                  <i> &nbsp;&nbsp;&nbsp;press ENTER to select</i>
                </Typography>
              ) : null}
            </Stack>
          </ListItemText>
          <IconButton
            sx={{ p: '4px' }}
            edge="end"
            onClick={(event) => {
              event.stopPropagation();
              onClear(suggestion);
            }}
          >
            <Clear fontSize="small" />
          </IconButton>
        </ListItemButton>
      ))}
    </AnimatedList>
  );
};
