import { FunctionComponent, useEffect, useState } from 'react';
import { useAuthentication } from 'src/features/authentication/context';
import { FilterComponentProps } from '../types';
import { Box, Checkbox, Stack, TextField, Typography } from '@mui/material';
import { searchFiled, threeBox } from 'src/features/reports/components/side-bar-filter/style';
import { AssetService } from 'src/services';
import { CustomWaterLoading } from 'src/components';

const AssetsFilter: FunctionComponent<FilterComponentProps> = ({ value, name, onChange }) => {
  const {
    customerId: { value: customerId },
    siteId: { value: siteId },
  } = useAuthentication();

  const [searchTerm, setSearchTerm] = useState('');
  const [assets, setAssets] = useState<{ loaded: boolean; data: any }>({
    loaded: false,
    data: [],
  });

  const toggleAsset = (asset: any) => {
    if (!Array.isArray(value) || !value.length) return onChange([asset], name);

    onChange(
      value.findIndex((el: any) => el.id === asset.id) !== -1
        ? value.filter((el: any) => el.id !== asset.id)
        : [...value, asset],
      name
    );
  };

  useEffect(() => {
    (async () => {
      if (customerId && siteId) {
        setAssets({ loaded: false, data: [] });
        const data = await AssetService.getAll(customerId, siteId);
        setAssets({ loaded: true, data });
      }
    })();
  }, [customerId, siteId]);

  return (
    <Box sx={threeBox}>
      <TextField
        sx={searchFiled}
        value={searchTerm}
        size="small"
        placeholder="Search Assets"
        onChange={(event) => {
          const search = event.target.value;
          setSearchTerm(search);
        }}
      />
      {assets.loaded ? (
        assets.data
          .filter(
            (el: any) =>
              typeof el?.assetName === 'string' &&
              el.assetName.toLowerCase().includes(searchTerm.toLowerCase())
          )
          .map((asset: any) => (
            <Stack
              sx={{ cursor: 'pointer' }}
              alignItems="center"
              gap="4px"
              onClick={() => {
                toggleAsset(asset);
              }}
              flexDirection="row"
              key={asset.id}
            >
              <Checkbox
                onClick={(event: any) => {
                  event.stopPropagation();
                  toggleAsset(asset);
                }}
                checked={
                  Array.isArray(value) && value.findIndex((el: any) => el.id === asset.id) !== -1
                }
              />
              <Typography variant="body1">{asset.assetName}</Typography>
            </Stack>
          ))
      ) : (
        <CustomWaterLoading />
      )}
    </Box>
  );
};

export default AssetsFilter;
