import { Box, Typography } from '@mui/material';
import { notificationMessage } from './style';

type Props = {
  message: string;
};

export const NotificationMessage = ({ message }: Props) => (
  <Box sx={notificationMessage}>
    <Typography>{message}</Typography>
  </Box>
);
