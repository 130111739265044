export const WetWellWidget = () => (
  <svg width="58" height="137" viewBox="0 0 58 137" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.959961" y="0.139648" width="56.8857" height="136.86" fill="white" />
    <rect
      x="1.12727"
      y="0.306959"
      width="56.5511"
      height="136.526"
      stroke="#566369"
      strokeOpacity="0.4"
      strokeWidth="0.334622"
    />
    <rect x="3.48828" y="7.83594" width="51.5764" height="125.149" fill="#F4F4F7" />
    <path
      d="M3.48828 75.0943C20.5203 75.0941 40.9115 75.0945 55.0647 75.0943C55.0647 77.5065 55.0647 132.984 55.0647 132.984H3.48828C3.48828 132.984 3.48828 77.5063 3.48828 75.0943Z"
      fill="#2486FD"
      fillOpacity="0.2"
    />
    <path
      d="M24.2687 73V69.429H23.418V68.8849C23.6906 68.8849 23.9104 68.8597 24.0775 68.8091C24.2467 68.7564 24.3731 68.6816 24.4566 68.5849C24.5402 68.486 24.5896 68.3684 24.605 68.2321H25.2447V73H24.2687ZM27.0894 73V69.429H26.2387V68.8849C26.5113 68.8849 26.7311 68.8597 26.8982 68.8091C27.0675 68.7564 27.1939 68.6816 27.2774 68.5849C27.3609 68.486 27.4104 68.3684 27.4258 68.2321H28.0655V73H27.0894ZM32.6662 69.5906V70.2599H31.9474V73H30.9846V70.2599H30.3548V69.5906H30.9846V69.0993C30.9846 68.8245 31.0648 68.6113 31.2253 68.4596C31.3879 68.3079 31.6176 68.2321 31.9144 68.2321H32.6959L32.6992 68.8718H32.2507C32.1276 68.8718 32.043 68.9025 31.9968 68.9641C31.9529 69.0234 31.9309 69.1224 31.9309 69.2608V69.5906H32.6662ZM35.3484 70.2599H34.59L34.5966 72.0768C34.5966 72.1603 34.6065 72.2229 34.6263 72.2647C34.6483 72.3043 34.6813 72.3306 34.7252 72.3438C34.7714 72.3548 34.8296 72.3603 34.9 72.3603H35.3583V72.9538C35.3143 72.9736 35.2451 72.9923 35.1506 73.0099C35.0582 73.0275 34.9285 73.0363 34.7615 73.0363C34.4625 73.0363 34.2306 72.9978 34.0658 72.9209C33.9031 72.8439 33.7899 72.7362 33.7261 72.5977C33.6624 72.4592 33.6305 72.2988 33.6305 72.1163V70.2599H33.0766V69.5906H33.6635L33.9042 68.5618H34.59V69.5873H35.3484V70.2599Z"
      fill="#3B3B3B"
    />
    <line
      x1="4.01343"
      y1="74.9027"
      x2="54.7921"
      y2="74.9027"
      stroke="#2486FD"
      strokeWidth="0.659464"
    />
  </svg>
);
