import { styled, TextareaAutosize } from '@mui/material';

export const StyledTextarea = styled(TextareaAutosize)(({ theme }) => ({
  width: '100%',
  padding: theme.spacing(1),
  borderRadius: theme.shape.borderRadius,
  borderColor: theme.palette.divider,
  background: 'transparent',
  fontSize: theme.typography.body1.fontSize,
  fontFamily: theme.typography.fontFamily,
  '&:focus': {
    borderColor: theme.palette.primary.main,
    outline: 'none',
  },
  '&:hover': {
    borderColor: theme.palette.text.primary,
  },
  // Add more styles as needed
}));
