import {
  Alert,
  Button,
  Card,
  Chip,
  FormControlLabel,
  Grid,
  IconButton,
  Skeleton,
  Stack,
  Switch,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { LocalizationProvider, TimePicker, renderTimeViewClock } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import { Suspense, useCallback, useEffect, useState } from 'react';
import { UseFormReturn, useForm } from 'react-hook-form';
import { allTimezones, useTimezoneSelect } from 'react-timezone-select';
import { FormProvider, RHFAutocomplete } from 'src/components/minimals/form';
import { useResponsive } from 'src/hooks';
import { filterOptions, formatUTCDateToLocal } from 'src/utilities';
import { StyledStack, StyledUserContainerStack } from './style';
import { PlusFillIcon } from 'src/assets';
import { ChipsSkeleton, CustomWaterLoading } from 'src/components';
import { useAuthentication } from 'src/features/authentication/context';
import { ReportsService, UserService } from 'src/services';
import { ChipData } from 'src/components/minimals/form/types';
import { AddDialog } from 'src/features/alarms-notification-group-details/components/add-dialog';
import { useTimezone } from '../../hooks';
import useQueryParamsActions from 'src/hooks/useQueryParamsActions';
import { useSnackbar } from 'notistack';
import { REPORTS_NEW_PATH } from 'src/routes/config';
import useTemplateQuery from '../../hooks/useTemplateQuery';
import { daysOfAMonth, daysOfTheWeek, scheduleIntervals, timeFrames } from './predefinedData';
import {
  CustomInputAttributes,
  MonthlyInterval,
  ReportSchedulePayload,
  ReportTimeFrame,
  ScheduleInterval,
  ScheduleReportFormData,
  WeeklyInterval,
} from './types';
import { ReportType } from '../../types';
import _ from 'lodash';

export const ScheduleReportForm = () => {
  const isMobile = useResponsive('down', 'sm');
  const theme = useTheme();

  const labelStyle = 'original';
  const { options } = useTimezoneSelect({ labelStyle, timezones: allTimezones });
  const {
    customerId: { value: customerId },
  } = useAuthentication();

  const { parseTimezone } = useTimezone();
  const { navigateWithParams, get } = useQueryParamsActions();
  const { enqueueSnackbar } = useSnackbar();
  const templateId = get('templateId');
  const onTemplateError = useCallback(() => {
    navigateWithParams(REPORTS_NEW_PATH);
    enqueueSnackbar('Template with id ' + templateId + ' failed to load.', {
      variant: 'error',
    });
  }, [enqueueSnackbar, navigateWithParams, templateId]);

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [dataHasFullyLoaded, setDataHasFullyLoaded] = useState<boolean>(false);
  const [tempUsers, setTempUsers] = useState<string[]>([]);

  const [userChipData, setUserChipData] = useState<{ value: ChipData[]; loaded: boolean }>({
    value: [],
    loaded: false,
  });

  const [errorMessage, setErrorMessage] = useState<string>('');
  const [schedulingReport, setSchedulingReport] = useState(false);
  const [scheduleId, setScheduleId] = useState<string | null>(null);

  const defaultFormValues: ScheduleReportFormData = {
    isScheduleOn: false,
    reportTimeFrame: '',
    scheduleInterval: 'WEEKLY',
    scheduleIntervalValues: { days: [] },
    time: dayjs(),
    timeZone: { value: '' },
    userEmails: [],
  };

  const [initialState, setInitialState] = useState<ScheduleReportFormData>(defaultFormValues);

  const methods = useForm<ScheduleReportFormData>({
    defaultValues: defaultFormValues,
  });

  const { watch, handleSubmit, setValue } = methods;

  const convertedMethods = methods as unknown as UseFormReturn<any>;

  const { loaded, data: templateData } = useTemplateQuery({
    onTemplateError,
    methods: convertedMethods,
  });

  const validateData = (formData: ScheduleReportFormData) => {
    let isValid = true;
    console.log(formData);
    if (!formData.reportTimeFrame) {
      isValid = false;
      setErrorMessage('Report Time Frame field is mandatory!');
    }
    if (
      formData.scheduleInterval === 'WEEKLY' &&
      (formData.scheduleIntervalValues as WeeklyInterval).days.length === 0
    ) {
      isValid = false;
      setErrorMessage('Weekly schedule interval requires at least 1 day being selected!');
    }

    console.log(formData);
    if (
      formData.scheduleInterval === 'MONTHLY' &&
      (!(formData.scheduleIntervalValues as any).everyXMonths ||
        !(formData.scheduleIntervalValues as any).dayOfTheMonth)
    ) {
      isValid = false;
      setErrorMessage('Days and months fields is mandatory!');
    }

    return isValid;
  };

  const onSubmit = (formData: ScheduleReportFormData) => {
    const isValid = validateData(formData);

    if (!isValid) {
      console.log('Form data is invalid!');
      return;
    }

    const daysMap: Record<string, number> = {
      MONDAY: 1,
      TUESDAY: 2,
      WEDNESDAY: 3,
      THURSDAY: 4,
      FRIDAY: 5,
      SATURDAY: 6,
      SUNDAY: 7,
    };

    function convertTo24HourFormat(timeStr: string): string {
      console.log(timeStr);
      // Validate input using a regular expression
      const match = timeStr.match(/^(\d{1,2}):(\d{2})(AM|PM)$/i);
      if (!match) {
        throw new Error("Invalid time format. Expected format: 'hh:mmAM' or 'hh:mmPM'");
      }

      // eslint-disable-next-line
      let [_, hours, minutes, period] = match;
      let hour = parseInt(hours, 10);

      // Convert to 24-hour format
      if (period.toUpperCase() === 'PM' && hour !== 12) {
        hour += 12;
      } else if (period.toUpperCase() === 'AM' && hour === 12) {
        hour = 0;
      }

      // Ensure the format is always 'HH:mm'
      const hourFormatted = hour.toString().padStart(2, '0');
      return `${hourFormatted}:${minutes}`;
    }

    const payload: ReportSchedulePayload = {
      active: formData.isScheduleOn,
      scheduleTimeframe: formData.reportTimeFrame.toLocaleLowerCase() as ReportTimeFrame,
      interval: formData.scheduleInterval.toLocaleLowerCase() as ScheduleInterval,
      time: convertTo24HourFormat(formatUTCDateToLocal(true, formData.time.toISOString())),
      report_type: sessionStorage.getItem('report_type') as ReportType,
      usersToNotify: formData.userEmails,
      timezone: formData.timeZone.value,
      periodicity: (formData.scheduleIntervalValues as any)?.everyXMonths
        ? parseInt((formData.scheduleIntervalValues as any)?.everyXMonths)
        : undefined,
      onDay: (formData.scheduleIntervalValues as any)?.dayOfTheMonth
        ? parseInt((formData.scheduleIntervalValues as any)?.dayOfTheMonth)
        : undefined,
      days: (formData.scheduleIntervalValues as any)?.days
        ? (formData.scheduleIntervalValues as any)?.days
            .map((day: string) => daysMap[day])
            .filter(Boolean)
        : undefined,
    };

    setSchedulingReport(true);
    ReportsService.scheduleReport(payload, templateId!, customerId!, scheduleId)
      .then(() => {
        enqueueSnackbar('Your report is scheduled!', { variant: 'success' });
        setInitialState(formData);
        setSchedulingReport(false);
      })
      .catch((err) => {
        enqueueSnackbar('An error occured while scheduling a report!', { variant: 'error' });
        setSchedulingReport(false);
      });
  };

  const customSwitchProps: CustomInputAttributes = {
    'data-sm': 'is-schedule-on-button',
  };

  const onDialogSubmit = () => {
    setValue('userEmails', tempUsers, { shouldValidate: true });
    onDialogClose();
  };

  const onDialogCancel = () => {
    const currentUsers = watch('userEmails');
    setTempUsers(currentUsers);
    onDialogClose();
  };

  const onDialogClose = () => {
    setIsDialogOpen(false);
  };

  const handleScheduleIntervalChange = (newValue: string) => {
    setValue('scheduleIntervalValues', {} as WeeklyInterval | MonthlyInterval);
    setValue('scheduleInterval', newValue);
  };

  const handleDaysOfTheWeekValueChange = (day: string) => {
    const currentDays = watch('scheduleIntervalValues.days');
    if (!currentDays?.includes(day)) {
      setValue('scheduleIntervalValues.days', [...currentDays, day]);
      setErrorMessage('');
    } else {
      setValue(
        'scheduleIntervalValues.days',
        currentDays.filter((currentDay: any) => currentDay !== day)
      );
    }
  };

  const isDaySelected = (day: string) => {
    const currentDays = watch('scheduleIntervalValues.days');

    if (currentDays?.includes(day)) {
      return true;
    }
    return false;
  };

  const [isScheduledReportLoading, setIsScheduledReportLoading] = useState(true);

  useEffect(() => {
    (async () => {
      try {
        setIsScheduledReportLoading(true);
        const response = await ReportsService.getScheduledReport(templateId!, customerId!);
        const [hours, minutes] = response.time.split(':');

        const daysMap: Record<number, string> = {
          1: 'MONDAY',
          2: 'TUESDAY',
          3: 'WEDNESDAY',
          4: 'THURSDAY',
          5: 'FRIDAY',
          6: 'SATURDAY',
          7: 'SUNDAY',
        };

        const scheduleReportFormData: ScheduleReportFormData = {
          isScheduleOn: response.active,
          scheduleInterval: response.interval.toLocaleUpperCase(),
          timeZone: options.find((timeZone) => timeZone.value === response.timezone) ?? {
            value: '',
          },
          time: dayjs().hour(parseInt(hours)).minute(parseInt(minutes)).second(0).millisecond(0),
          reportTimeFrame: response.scheduleTimeframe.toLocaleUpperCase(),
          scheduleIntervalValues: {
            dayOfTheMonth: response.onDay ? response.onDay + '' : '',
            everyXMonths: response.periodicity ? response.periodicity + '' : '',
            days: response.days ? response.days.map((day) => daysMap[day]) : [],
          },
          userEmails: response.usersToNotify,
        };

        setInitialState(scheduleReportFormData);
        setTempUsers(response.usersToNotify);
        setIsScheduledReportLoading(false);
        setScheduleId(response.id);
        methods.reset(scheduleReportFormData);
      } catch (err) {
        setIsScheduledReportLoading(false);
        setScheduleId(null);
      }
    })();
    //eslint-disable-next-line
  }, [templateId, customerId, methods, setIsScheduledReportLoading, setInitialState, setTempUsers]);

  const reportTimeFrame = watch('reportTimeFrame');
  const scheduleInterval = watch('scheduleInterval');
  const everyXMonths = watch('scheduleIntervalValues.everyXMonths');
  const dayOfTheMonth = watch('scheduleIntervalValues.dayOfTheMonth');
  const formData = watch();

  const [hasChanged, setHasChanged] = useState(false);
  const discardChanges = useCallback(() => {
    methods.reset(initialState);
  }, [initialState, methods]);

  useEffect(() => {
    setHasChanged(!_.isEqual(initialState, formData));
  }, [initialState, formData, setHasChanged]);
  useEffect(() => {
    if (reportTimeFrame) {
      if (scheduleInterval === 'MONTHLY') {
        if (everyXMonths && dayOfTheMonth) {
          setErrorMessage('');
        }
      } else {
        setErrorMessage('');
      }
    }
  }, [reportTimeFrame, setErrorMessage, everyXMonths, dayOfTheMonth, scheduleInterval]);
  useEffect(() => {
    if (!customerId) return;

    UserService.getAll(customerId)
      .then((data) => {
        if (data.users.length > 0) {
          const formattedData: ChipData[] = [];
          data.users.forEach((user: any) => {
            formattedData.push({
              key: user.id,
              label:
                user.firstName && user.lastName ? user.firstName + ' ' + user.lastName : user.email,
            });
          });
          setUserChipData({ value: formattedData, loaded: true });
          setDataHasFullyLoaded(true);
        }
      })
      .catch((error) => {
        setUserChipData({ value: [], loaded: true });
        console.error(error);
        setDataHasFullyLoaded(true);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerId]);

  useEffect(() => {
    if (loaded && templateId) {
      const { timezone } = templateData;
      setValue('timeZone', parseTimezone(timezone) as any);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templateId, loaded]);

  const handleUserDelete = (user: ChipData) => {
    const currentUsers = watch('userEmails');
    setValue(
      `userEmails`,
      currentUsers.filter((userId: any) => userId !== user.key.toString())
    );
    setTempUsers((prevValue) => prevValue.filter((val) => val !== user.key.toString()));
  };

  const renderUserChips = () => {
    const currentUserEmails = watch('userEmails');
    if (dataHasFullyLoaded) {
      return currentUserEmails.length > 0 ? (
        userChipData.value.map((data) =>
          currentUserEmails.includes(data.key.toString()) ? (
            <Chip
              key={data.key}
              label={data.label}
              onDelete={() => handleUserDelete(data)}
              sx={{ width: 'fit-content', m: '4px' }}
            />
          ) : null
        )
      ) : (
        <Typography sx={{ ml: '8px' }} color={'GrayText'}>
          Select users
        </Typography>
      );
    } else {
      return <ChipsSkeleton />;
    }
  };

  const handleDialogOpen = () => {
    setIsDialogOpen(true);
  };

  const handleChange = (user: string, checked: boolean) => {
    let newUsers: string[];

    if (checked) {
      newUsers = [...tempUsers, user];
    } else {
      newUsers = tempUsers.filter((u: string) => u !== user);
    }

    setTempUsers(newUsers);
  };

  const isUserChecked = (user: string) => tempUsers?.includes(user) ?? false;

  return (
    <Card sx={{ background: theme.palette.background.default, padding: 2 }}>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <FormControlLabel
              sx={{ ml: 0 }}
              control={
                <Switch
                  checked={watch('isScheduleOn')}
                  color="primary"
                  onChange={(_, checked) => {
                    setValue('isScheduleOn', checked);
                  }}
                  inputProps={{ ...customSwitchProps }}
                />
              }
              label={'Schedule Report'}
              labelPlacement="start"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <RHFAutocomplete
              name="reportTimeFrame"
              size="small"
              fullWidth
              options={['', ...timeFrames.map((timeFrame) => timeFrame.id)]}
              filterOptions={(options, state) =>
                filterOptions(options, state, [
                  '',
                  ...timeFrames.map((timeFrame) => timeFrame.label),
                ])
              }
              getOptionLabel={(option) => {
                const labels = ['', ...timeFrames.map((timeFrame) => timeFrame.label)];
                const values = ['', ...timeFrames.map((timeFrame) => timeFrame.id)];

                const index = values.indexOf(option);
                return labels[index];
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Report Time Frame"
                  data-sm="report-time-frame-input"
                  inputProps={{
                    ...params.inputProps,
                    readOnly: isMobile,
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <RHFAutocomplete
              name="scheduleInterval"
              size="small"
              fullWidth
              disableClearable
              options={['', ...scheduleIntervals.map((interval) => interval.id)]}
              onChange={(_, newValue) => handleScheduleIntervalChange(newValue as string)}
              filterOptions={(options, state) =>
                filterOptions(options, state, [
                  '',
                  ...scheduleIntervals.map((interval) => interval.label),
                ])
              }
              getOptionLabel={(option) => {
                const labels = ['', ...scheduleIntervals.map((interval) => interval.label)];
                const values = ['', ...scheduleIntervals.map((interval) => interval.id)];

                const index = values.indexOf(option);
                return labels[index];
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Schedule Interval"
                  data-sm="schedule-interval-input"
                  inputProps={{
                    ...params.inputProps,
                    readOnly: isMobile,
                  }}
                />
              )}
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
          >
            {watch('scheduleInterval') === 'WEEKLY' ? (
              daysOfTheWeek.map((day) => (
                <IconButton
                  disableRipple
                  key={day}
                  sx={{
                    width: '32px',
                    height: '32px',
                    border: '1px solid',
                    borderColor: theme.palette.primary.main,
                    backgroundColor: isDaySelected(day)
                      ? theme.palette.primary.main
                      : 'transparent',
                    mr: '4px',
                  }}
                  onClick={() => handleDaysOfTheWeekValueChange(day)}
                >
                  <Typography
                    variant="body2"
                    color={isDaySelected(day) ? 'background.default' : 'text.primary'}
                  >
                    {day.charAt(0)}
                  </Typography>
                </IconButton>
              ))
            ) : (
              <Stack sx={{ flexDirection: 'row', alignItems: 'center' }}>
                <Typography variant="body2">Every</Typography>
                <RHFAutocomplete
                  sx={{ marginX: '8px' }}
                  name="scheduleIntervalValues.everyXMonths"
                  size="small"
                  disableClearable
                  filterOptions={(options, state) => filterOptions(options, state, options)}
                  fullWidth
                  options={['', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12']}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      data-sm="schedule-interval-every-x-months-input"
                      inputProps={{
                        ...params.inputProps,
                        readOnly: isMobile,
                        style: {
                          width: '64px',
                          textAlign: 'center',
                        },
                      }}
                      variant="standard"
                    />
                  )}
                />
                <Typography variant="body2" sx={{ mr: isMobile ? '0px' : '16px' }}>
                  month(s)
                </Typography>
                {watch('scheduleInterval') === 'MONTHLY' && !isMobile ? (
                  <>
                    <Typography variant="body2">On</Typography>
                    <RHFAutocomplete
                      sx={{ marginX: '8px' }}
                      name="scheduleIntervalValues.dayOfTheMonth"
                      size="small"
                      disableClearable
                      options={['', ...daysOfAMonth.map((day) => day.id)]}
                      filterOptions={(options, state) =>
                        filterOptions(options, state, ['', ...daysOfAMonth.map((day) => day.label)])
                      }
                      getOptionLabel={(option) => {
                        const labels = ['', ...daysOfAMonth.map((day) => day.label)];
                        const values = ['', ...daysOfAMonth.map((day) => day.id)];

                        const index = values.indexOf(option);
                        return labels[index];
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          data-sm="schedule-interval-day-of-the-month-input"
                          inputProps={{
                            ...params.inputProps,
                            readOnly: isMobile,
                            style: {
                              width: '64px',
                              textAlign: 'center',
                            },
                          }}
                          variant="standard"
                        />
                      )}
                    />
                    <Typography variant="body2">day</Typography>
                  </>
                ) : null}
              </Stack>
            )}
          </Grid>
          {isMobile && watch('scheduleInterval') === 'MONTHLY' ? (
            <Grid item xs={12} md={6}>
              <Stack sx={{ flexDirection: 'row', alignItems: 'center' }}>
                <Typography variant="body2">On</Typography>
                <RHFAutocomplete
                  sx={{ marginX: '8px' }}
                  name="scheduleIntervalValues.dayOfTheMonth"
                  size="small"
                  disableClearable
                  options={['', ...daysOfAMonth.map((day) => day.id)]}
                  filterOptions={(options, state) =>
                    filterOptions(options, state, ['', ...daysOfAMonth.map((day) => day.label)])
                  }
                  getOptionLabel={(option) => {
                    const labels = ['', ...daysOfAMonth.map((day) => day.label)];
                    const values = ['', ...daysOfAMonth.map((day) => day.id)];

                    const index = values.indexOf(option);
                    return labels[index];
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      sx={{ input: { textAlign: 'center' } }}
                      data-sm="schedule-interval-day-of-the-month-input"
                      inputProps={{
                        ...params.inputProps,
                        readOnly: isMobile,
                        style: {
                          width: '64px',
                          textAlign: 'center',
                        },
                      }}
                      variant="standard"
                    />
                  )}
                />
                <Typography variant="body2">day</Typography>
              </Stack>
            </Grid>
          ) : null}
          <Grid item xs={12} md={6}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <TimePicker
                label="Time"
                viewRenderers={{
                  hours: renderTimeViewClock,
                  minutes: renderTimeViewClock,
                  seconds: renderTimeViewClock,
                }}
                slotProps={{
                  textField: { size: 'small', fullWidth: true },
                }}
                value={dayjs(watch('time'))}
                // Saving the UTC value in state
                onChange={(value: Dayjs | null) => setValue('time', value ? value : dayjs())}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} md={6}>
            <RHFAutocomplete
              name="timeZone"
              rules={{ required: 'The field is required' }}
              options={options}
              disableClearable
              size="small"
              renderInput={(params) => <TextField {...params} fullWidth label={'Time Zone *'} />}
            />
          </Grid>
          <Grid item xs={12}>
            <StyledUserContainerStack isMobile={isMobile} theme={theme} onClick={handleDialogOpen}>
              <Typography
                variant="body2"
                color={theme.palette.text.primary}
                sx={{
                  position: 'absolute',
                  top: '-12px',
                  left: '12px',
                  backgroundColor: theme.palette.background.default,
                  pl: '4px',
                  pr: '4px',
                }}
              >
                Email Users
              </Typography>
              <StyledStack
                sx={{
                  width: isMobile ? '100%' : '85%',
                  maxHeight: '164px',
                  overflowY: 'auto',
                  padding: '8px',
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                  alignItems: 'center',
                }}
              >
                {renderUserChips()}
              </StyledStack>
              <Stack
                sx={{
                  width: isMobile ? '100%' : '15%',
                  alignItems: 'center',
                  justifyContent: 'center',
                  pt: isMobile ? '16px' : 0,
                }}
              >
                {dataHasFullyLoaded ? (
                  <Button
                    fullWidth={isMobile}
                    variant="text"
                    startIcon={<PlusFillIcon />}
                    onClick={handleDialogOpen}
                    data-sm={`add-user`}
                  >
                    Add
                  </Button>
                ) : (
                  <Skeleton animation="wave" variant="rounded" height={'32px'} width={'88px'} />
                )}
              </Stack>
            </StyledUserContainerStack>
          </Grid>
          <Grid item xs={12}>
            {errorMessage !== '' ? (
              <Alert severity="error" style={{ margin: '8px 0' }}>
                <Typography>{errorMessage}</Typography>
              </Alert>
            ) : null}
          </Grid>
          <Grid item xs={12}>
            <Stack sx={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end' }}>
              <Button
                disabled={schedulingReport || isScheduledReportLoading || !hasChanged}
                onClick={discardChanges}
                variant="outlined"
              >
                Discard
              </Button>

              <Button
                disabled={
                  errorMessage !== '' || schedulingReport || isScheduledReportLoading || !hasChanged
                }
                onClick={() => onSubmit(watch())}
                variant="contained"
                sx={{ ml: '8px' }}
              >
                {schedulingReport ? 'Saving...' : 'Save'}
              </Button>
            </Stack>
          </Grid>
        </Grid>
        {isDialogOpen && (
          <Suspense fallback={<CustomWaterLoading />}>
            <AddDialog
              isOpen={isDialogOpen}
              dialogTitle={'Add Users'}
              selectTitle={'Select user(s)'}
              onClose={onDialogCancel}
              chipData={userChipData}
              isChecked={isUserChecked}
              handleChange={handleChange}
              onSubmit={onDialogSubmit}
              setTempData={setTempUsers}
              entity="Users"
            />
          </Suspense>
        )}
      </FormProvider>
    </Card>
  );
};
