import React, { useRef } from 'react';
import { CSVLink } from 'react-csv';
import { Box, Dialog, Typography } from '@mui/material';
import {
  trendWidgetBox,
  trendWidgetWrapper,
  timelineBoxDetails,
  hamburgerBox,
  menuPopover,
  tooltipItemBox,
  tooltipItemText,
  removeBox,
  rightSideContentBox,
} from './style';
import { Timeframe } from '../timeframe';
import { MenuPopover } from 'src/components';
import { useHtmlElement, useToggle } from 'src/hooks';
import { HamburgerIcon, CloseIcon, ExportIcon, RefreshIcon } from 'src/assets';
import { AlarmData } from 'src/features/alarms';
import { exportAsPNG } from 'src/utilities';
import { GraphSeriesType } from '../../types/readings';
import { AddChartModalContent } from '../add-chart-modal-content';
import { TimelineChartValue } from '../../types';
import { SelectedInputType } from '../../types/selected-input';
import { formatAlarmsDataForExport } from '../../utils';
import { formatTagDataForExport } from '../../utils/formatTagDataForExport';

type TrendWidgetWrapperProps = {
  children: React.ReactNode;
  handleSelectDate: (date: any) => void;
  isTimelineChart?: boolean;
  disabled?: boolean;
  id?: string;
  removeChart?: (chartId: string) => void;
  alarms?: AlarmData[];
  pointChartData?: GraphSeriesType[];
  updatedSetValues?: (values: TimelineChartValue, isTimelineChart: boolean) => void;
  onSelectEventHandler?: (selectedInputs: SelectedInputType[], id: string) => void;
  sites?: any;
  tags?: { value: any; loaded: boolean };
};

export const TrendWidgetWrapper = ({
  children,
  isTimelineChart,
  handleSelectDate,
  disabled,
  id,
  removeChart,
  alarms,
  pointChartData,
  updatedSetValues,
  onSelectEventHandler,
  sites,
  tags,
}: TrendWidgetWrapperProps) => {
  const { htmlElement, addHtmlElement, removeHtmlElement } = useHtmlElement();
  const { state, onToggle } = useToggle();
  const chartRef = useRef(null);

  const onRemoveHandler = () => {
    if (removeChart && id) removeChart(id);
    onClosePopover();
  };

  const handleDownloadPNGClick = () => {
    onClosePopover();
    exportAsPNG(chartRef);
  };

  const tagForExport = pointChartData ? formatTagDataForExport(pointChartData) : [];

  const alarmsForExport = alarms ? formatAlarmsDataForExport(alarms) : [];

  const onOpenPopover = (event: any) => {
    addHtmlElement(event.currentTarget);
  };

  const onClosePopover = () => {
    removeHtmlElement();
  };

  const onSelectDataHandler = () => {
    onToggle();
    onClosePopover();
  };
  return (
    <>
      <Box sx={trendWidgetBox}>
        <Box sx={trendWidgetWrapper} ref={chartRef}>
          <Box sx={timelineBoxDetails}>
            {isTimelineChart ? <Typography>Alarms and Events</Typography> : null}
            <Box sx={rightSideContentBox}>
              <Timeframe handleSelectDate={handleSelectDate} disabled={disabled} />
              <Box sx={hamburgerBox} onClick={onOpenPopover} data-sm="chart-burger-menu">
                <HamburgerIcon />
              </Box>
            </Box>

            <MenuPopover
              open={htmlElement}
              onClose={onClosePopover}
              arrow="right-top"
              sx={menuPopover}
            >
              <Box sx={tooltipItemBox} data-sm="select-data" onClick={onSelectDataHandler}>
                <RefreshIcon />
                <Typography sx={tooltipItemText}>Select data</Typography>
              </Box>

              <CSVLink
                data-sm="download-csv"
                onClick={onClosePopover}
                style={{ textDecoration: 'none', color: 'inherit' }}
                data={isTimelineChart ? alarmsForExport : tagForExport || []}
              >
                <Box sx={tooltipItemBox}>
                  <ExportIcon width={10} height={10} />
                  <Typography sx={tooltipItemText}>Download CSV</Typography>
                </Box>
              </CSVLink>

              <Box sx={tooltipItemBox} onClick={handleDownloadPNGClick} data-sm="download-png">
                <ExportIcon width={10} height={10} />
                <Typography sx={tooltipItemText}>Download PNG</Typography>
              </Box>
              <Box sx={removeBox} onClick={onRemoveHandler} data-sm="remove-button">
                <CloseIcon width={10} />
                <Typography sx={tooltipItemText}>Remove</Typography>
              </Box>
            </MenuPopover>
          </Box>
          {children}
        </Box>
      </Box>
      <Dialog open={state} onClose={onToggle} maxWidth="lg">
        <AddChartModalContent
          updatedSetValues={updatedSetValues}
          onSelectEventHandler={onSelectEventHandler}
          onToggleHandler={onToggle}
          isParticularChart
          chartId={id}
          isTimelineChart={isTimelineChart}
          sites={sites}
        />
      </Dialog>
    </>
  );
};
