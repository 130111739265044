import { IconsTypesProps } from './types';
import { CURRENT_COLOR } from 'src/config';

export const CookieIcon = ({ fill }: IconsTypesProps) => {
  const color = fill ?? CURRENT_COLOR;

  return (
    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.27 9.8999C18.06 9.5699 16.96 8.4399 16.98 7.0099C16.99 6.4499 16.58 5.9899 16.02 5.9999C13.83 6.0299 12 4.2199 12 2.0199C12 1.5299 11.65 1.1199 11.16 1.0599C4.53 0.219898 0 5.8099 0 10.9999C0 16.5199 4.48 20.9999 10 20.9999C15.61 20.9999 20.11 16.3799 20 10.8199C19.99 10.3799 19.69 10.0099 19.27 9.8999ZM6.5 13.9999C5.67 13.9999 5 13.3299 5 12.4999C5 11.6699 5.67 10.9999 6.5 10.9999C7.33 10.9999 8 11.6699 8 12.4999C8 13.3299 7.33 13.9999 6.5 13.9999ZM8.5 8.9999C7.67 8.9999 7 8.3299 7 7.4999C7 6.6699 7.67 5.9999 8.5 5.9999C9.33 5.9999 10 6.6699 10 7.4999C10 8.3299 9.33 8.9999 8.5 8.9999ZM13 14.9999C12.45 14.9999 12 14.5499 12 13.9999C12 13.4499 12.45 12.9999 13 12.9999C13.55 12.9999 14 13.4499 14 13.9999C14 14.5499 13.55 14.9999 13 14.9999Z"
        fill={color}
      />
    </svg>
  );
};
