import { Fragment } from 'react';
import { Box, Fade, Skeleton, Stack, Typography } from '@mui/material';
import { NAV_ITEMS_FOOTER, NAV_FOOTER_TEXT } from '../../config';
import { NavItem } from '../navItem';
import { NavList } from '../navList';
import {
  navHeader,
  headerList,
  StyledHeaderTitle,
  StyledFooterText,
  footerStack,
  StyledNav,
} from './style';
import { useTheme } from '@mui/material/styles';
import { useNavigation } from '../../hooks';
import { HeaderNavItemType } from '../../types';
import { SelectedSiteNavigationBox } from '../selectedSiteNavigationBox';
import { useSite } from 'src/contexts/siteContext';
import { useAuthentication } from 'src/features/authentication/context';
import { USER_ACCOUNTS_PATH_ROOT } from 'src/routes/config';
import { AccessRoles } from 'src/features/user-account-details/types';
import { useAlarms } from 'src/contexts/alarmsContext';

type NavSectionProps = {
  navHeaderItems: HeaderNavItemType[];
  handleCloseSideBar: () => void;
  isSuperAdmin?: boolean;
  isCustomerAccountPage?: boolean;
  customerLoading: boolean;
  customerName: string;
};

export const NavSection = ({
  navHeaderItems,
  handleCloseSideBar,
  isSuperAdmin,
  isCustomerAccountPage,
  customerLoading,
  customerName,
}: NavSectionProps) => {
  const theme = useTheme();

  const navItems = navHeaderItems.flatMap((item) => item.navItem);

  const { customerId, getCurrentRole } = useAuthentication();

  const { alarmsCountValue } = useAlarms(customerId.value!!);

  const { activeItems, onClickHandler } = useNavigation([...navItems]);

  const findIsNavItemActive = (title: string) =>
    activeItems.find((item) => item.title === title)?.isActive;

  const findIsNavItemToggle = (title: string) =>
    activeItems.find((item) => item.title === title)?.isToggle;

  const { getSiteToString, getSite } = useSite();
  const site = getSite();

  const getCustomerName = (title: string) => {
    if (title !== 'Selected Site' && title !== 'Global Administration') {
      return (
        <Fade in={!customerLoading} timeout={500}>
          <Typography variant="body2" sx={{ m: 0, p: 0, fontWeight: 600 }}>
            {customerName}
          </Typography>
        </Fade>
      );
    }
    return '';
  };

  return (
    <>
      <StyledNav component="nav" data-sm="navigation">
        {navHeaderItems.map(({ navItem, title }) => (
          <Box key={title} component="header" sx={navHeader}>
            {!customerLoading ? (
              <StyledHeaderTitle
                theme={theme}
                isSuperAdmin={isSuperAdmin ?? false}
                isCustomerAccountPage={isCustomerAccountPage || false}
                variant="body2"
                sx={{ fontWeight: 600 }}
              >
                {title !== '' ? (
                  <Typography variant="body2" sx={{ fontWeight: 600 }}>
                    {title}
                  </Typography>
                ) : null}
                {getCustomerName(title)}
              </StyledHeaderTitle>
            ) : (
              <Skeleton
                variant="text"
                sx={{ ml: '16px', backgroundColor: theme.sidebar.background }}
              />
            )}
            {title === 'Selected Site' ? (
              <SelectedSiteNavigationBox
                title={site?.siteName ?? ''}
                description={getSiteToString()}
              />
            ) : null}
            <Box component="ul" sx={headerList}>
              {navItem.map(({ path, title, icon, subItems, testId, additionalLabel }, i) => {
                if (
                  path.includes(USER_ACCOUNTS_PATH_ROOT) &&
                  getCurrentRole(customerId.value, null) !== AccessRoles.ACCOUNT_ADMIN &&
                  getCurrentRole(customerId.value, null) !== AccessRoles.SUPER_ADMIN
                )
                  return <Fragment key={i} />;

                return (
                  <NavList
                    key={i}
                    path={path}
                    title={title}
                    icon={icon}
                    testId={testId}
                    subItems={subItems}
                    additionalLabel={additionalLabel}
                    onClickHandler={onClickHandler}
                    isActive={findIsNavItemActive(title)}
                    isToggle={findIsNavItemToggle(title)}
                    handleCloseSideBar={handleCloseSideBar}
                    isSuperAdmin={isSuperAdmin}
                    additionalLabelState={alarmsCountValue}
                  />
                );
              })}
            </Box>
          </Box>
        ))}
        <Stack sx={footerStack}>
          <Box component="footer" sx={{ marginTop: '16px' }}>
            <Box component="ul">
              {NAV_ITEMS_FOOTER.map(({ path, title, icon, testId }, i) => (
                <NavItem
                  key={i}
                  path={path}
                  title={title}
                  icon={icon}
                  testId={testId}
                  isSuperAdmin={isSuperAdmin}
                  isCustomerAccountPage={isCustomerAccountPage}
                  additionalLabelState={alarmsCountValue}
                  footerNavItem
                />
              ))}
            </Box>
            <StyledFooterText
              isSuperAdmin={isSuperAdmin ?? false}
              isCustomerAccountPage={isCustomerAccountPage || false}
              theme={theme}
            >
              {NAV_FOOTER_TEXT}
            </StyledFooterText>
          </Box>
        </Stack>
      </StyledNav>
    </>
  );
};
