import { Theme, styled } from '@mui/material/styles';
import { Card } from '@mui/material';

type StylesTypes = {
  theme: Theme;
};

const StyledCard = styled(Card)(({ theme }: StylesTypes) => ({
  margin: '16px auto',
  boxShadow: '0px 0px 2px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12)',
  borderRadius: '16px',
  backgroundColor: theme.palette.background.default,
}));

const cardContent = {
  padding: '16px 18px 16px 16px  !important ',
};

const cardHeader = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
};

const cardHeaderLeft = {
  display: 'flex',
};

const cardHeaderRight = {
  display: 'flex',
  alignItems: 'center',
  paddingLeft: '3px',
};

const iconBox = {
  paddingLeft: '5px',
  paddingRight: '17px',
};

export { StyledCard, cardContent, cardHeader, iconBox, cardHeaderLeft, cardHeaderRight };
