import { Box, Typography, Button, useTheme } from '@mui/material';
import { CSVLink } from 'react-csv';
import { ModalCalendars } from '../modal-calendars';
import { AssetType, PumpModalData, SiteType } from '../../types';
import { ReportsService } from 'src/services';
import { useEffect, useState } from 'react';
import { formatDate } from '../../helper';
import { LoadingButton } from '@mui/lab';
import { useAuthentication } from 'src/features/authentication/context';
import { convertSecondsToHours } from 'src/utilities';

type ExportModalContentProps = {
  headerTitle: string;
  width?: number;
  onClose: () => void;
};

type ResponseType = Record<string, Record<string, SiteType>>;

export const ExportModalContent = ({ headerTitle, width, onClose }: ExportModalContentProps) => {
  const [value, setValue] = useState<any>({ startDate: null, endDate: null });
  const [pumpReportData, setPumpReportData] = useState<PumpModalData[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const theme = useTheme();
  const {
    customerId: { value: customerId },
  } = useAuthentication();

  const processAssetData = (date: string, siteName: string, asset: AssetType) => ({
    date: date,
    site: siteName,
    assetName: asset.assetName,
    runtime: convertSecondsToHours(Number(asset.data.dailyRuntime), 2),
    cycles: asset.data.dailyCycles,
    flow: asset.data.dailyVolumetricFlow,
    efficiency: asset.data.averageDailyEfficiency,
    energyConsumption: asset.data.dailyEnergyConsumption,
  });

  const processSiteData = (date: string, site: SiteType) => {
    const siteName = site?.siteName;
    return Object.values(site.assets).map((asset) => processAssetData(date, siteName, asset));
  };

  const processData = (response: ResponseType) => {
    const data = response && Object.entries(response);
    let newData: any = [];
    data.forEach((item: any) => {
      const date = item[0];
      Object.values(item[1]).forEach((site: any) => {
        newData = newData.concat(processSiteData(date, site));
      });
    });

    return newData;
  };

  const fetchData = async (customerId: string, fromDate: string, toDate: string) => {
    try {
      setLoading(true);
      const response = await ReportsService.getAll(customerId, fromDate, toDate);
      const newData = processData(response);
      setPumpReportData(newData);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (customerId && value?.startDate && value?.endDate) {
      const fromDate = formatDate(value?.startDate);
      const toDate = formatDate(value?.endDate);
      if (fromDate && toDate) {
        fetchData(customerId, fromDate, toDate);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, customerId]);

  const onCloseHandler = () => {
    onClose();
  };

  return (
    <Box
      sx={{
        width: `${width}px`,
        maxWidth: '100%',
        p: '24px',
        backgroundColor: theme.palette.background.default,
      }}
    >
      <Box component="header">
        <Typography variant="h6">{headerTitle}</Typography>
      </Box>
      <Box sx={{ marginTop: '48px' }}>
        <Typography sx={{ mb: '20px' }} variant="body1">
          Select date range
        </Typography>
        <ModalCalendars setValue={setValue} />
      </Box>

      <Box sx={{ display: 'flex', justifyContent: 'flex-end', pt: '30px' }} component="footer">
        <Button
          data-sm="modal-cancel-button"
          sx={{ mr: '12px' }}
          variant="outlined"
          onClick={onCloseHandler}
        >
          Cancel
        </Button>

        <LoadingButton
          loading={loading}
          data-sm="modal-export-button"
          variant="contained"
          disabled={pumpReportData.length <= 0}
        >
          <CSVLink
            style={{ textDecoration: 'none', color: 'inherit' }}
            data={pumpReportData}
            onClick={onCloseHandler}
          >
            Export.csv
          </CSVLink>
        </LoadingButton>
      </Box>
    </Box>
  );
};
