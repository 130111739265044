import { IconsTypesProps } from '../types';
import { CURRENT_COLOR } from 'src/config';

export const DoubleLeftArrowIcon = ({ fill, width = 14, height = 12 }: IconsTypesProps) => {
  const color = fill ?? CURRENT_COLOR;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 14 12"
      fill="none"
    >
      <path
        d="M13.2898 11.29C13.6798 10.9 13.6798 10.27 13.2898 9.87998L9.41982 5.99998L13.2998 2.11998C13.6898 1.72998 13.6898 1.09998 13.2998 0.70998C12.9098 0.31998 12.2798 0.31998 11.8898 0.70998L7.29982 5.29998C6.90982 5.68998 6.90982 6.31998 7.29982 6.70998L11.8898 11.3C12.2698 11.68 12.8998 11.68 13.2898 11.29Z"
        fill={color}
      />
      <path
        d="M6.69998 11.29C7.08998 10.9 7.08998 10.27 6.69998 9.87998L2.82998 5.99998L6.70998 2.11998C7.09998 1.72998 7.09998 1.09998 6.70998 0.70998C6.31998 0.31998 5.68998 0.31998 5.29998 0.70998L0.70998 5.29998C0.31998 5.68998 0.31998 6.31998 0.70998 6.70998L5.29998 11.3C5.67998 11.68 6.30998 11.68 6.69998 11.29Z"
        fill={color}
      />
    </svg>
  );
};
