import { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useTheme } from '@mui/material';

type CalendarPropsTypes = {
  onChange: (data: any) => void;
  disabled?: boolean;
};

export const Calendar = ({ onChange, disabled }: CalendarPropsTypes) => {
  const [value, setValue] = useState<any>(dayjs(new Date()));
  const theme = useTheme();

  useEffect(() => {
    onChange(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChangeHandler = (date: any) => {
    setValue(date);
    onChange(date);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        onChange={(date) => onChangeHandler(date)}
        value={value}
        slotProps={{
          textField: { inputProps: { 'data-sm': 'datepicker-input' } },
          mobilePaper: { sx: { backgroundColor: theme.palette.background.default } },
          desktopPaper: {
            sx: {
              backgroundColor: theme.palette.background.default,
            },
          },
        }}
        disableFuture={true}
        disabled={disabled === true}
      />
    </LocalizationProvider>
  );
};
