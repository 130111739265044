import { ReactElement } from 'react';
import { MenuItem, Box } from '@mui/material';

type MenuPopoverItemProps = {
  icon: ReactElement;
  description: string;
  color?: string;
  testId?: string;
  handleClick: (e: any) => void;
};

export const MenuPopoverItem = ({
  icon,
  description,
  color,
  testId,
  handleClick,
}: MenuPopoverItemProps) => {
  const colorStyle = color || 'inherit';

  return (
    <MenuItem
      onClick={(e) => handleClick(e)}
      sx={{
        display: 'flex',
        px: '4px',
        py: '3px',
        alignItems: 'center',
        color: colorStyle,
        maxWidth: '100%',
      }}
      data-testid={testId}
    >
      {icon}
      <Box
        sx={{
          wordWrap: 'break-word',
          whiteSpace: 'normal',
          maxWidth: '100%',
        }}
        data-sm={testId}
      >
        {description}
      </Box>
    </MenuItem>
  );
};
