import { Box, Button, IconButton, Stack, useTheme } from '@mui/material';
import { FunctionComponent } from 'react';
import SiteTitle from './SiteTitle';
import UserAction from 'src/components/streametric/user-action/UserAction';
import SiteBox from './SiteBox';
import SiteTab from './SiteTab';
import { navigationItems } from './siteItems';
import NavigationItem from './NavigationItem';
import SiteAction from './SiteAction';
import { useResponsive } from 'src/hooks';
import { HamburgerIcon, ListIcon } from 'src/assets';
import { useSidebarToggleContext } from 'src/layouts/appLayout/sidebarToggle.context';
import { TENANT_SWITCH_PATH } from 'src/routes/config';
import { Link } from 'react-router-dom';
import { useMapFilters } from 'src/contexts/siteMapFilterContext';
import { Header } from './style';
import { ISiteHeaderProps } from './types';

const SiteHeader: FunctionComponent<ISiteHeaderProps> = ({ site }) => {
  const { onToggle } = useSidebarToggleContext();
  const theme = useTheme();
  const { updateMapFilters } = useMapFilters();
  const isMobile = useResponsive('down', 'md');
  return (
    <Header>
      <Stack
        flexDirection="row"
        justifyContent={'space-between'}
        alignItems={'center'}
        sx={{ width: '100%' }}
      >
        <Stack flex="1" flexDirection="row" alignItems={'center'}>
          {isMobile ? (
            <Box
              sx={{
                display: 'flex',
                marginRight: '6px',
                alignItems: 'center',
              }}
              alignItems="center"
              onClick={onToggle}
              data-sm="side-bar-open-new"
            >
              <HamburgerIcon fill={theme.palette.text.secondary} />
            </Box>
          ) : null}
          <SiteBox siteType={site.siteType} highestActiveAlarm={site.highestActiveAlarm} />
          <Stack flex="1" alignItems="center" flexDirection="row">
            <Box sx={{ flex: 1, height: '27px', position: 'relative', overflow: 'hidden' }}>
              <SiteTitle>{site.siteName}</SiteTitle>
            </Box>
            <SiteAction site={site} />
          </Stack>
        </Stack>
        <Stack flexShrink="0" alignItems="center" flexDirection="row">
          {isMobile ? (
            <IconButton
              to={TENANT_SWITCH_PATH}
              component={Link}
              onClick={() => {
                updateMapFilters('');
              }}
            >
              <ListIcon width={24} height={24} />
            </IconButton>
          ) : (
            <Button
              variant="outlined"
              startIcon={<ListIcon width={18} height={18} />}
              sx={{ mr: '40px' }}
              component={Link}
              to={TENANT_SWITCH_PATH}
              onClick={() => {
                updateMapFilters('');
              }}
            >
              Switch Account
            </Button>
          )}
          <UserAction />
        </Stack>
      </Stack>
      <SiteTab>
        {navigationItems.map((navigationItem) => {
          if (typeof navigationItem.label === 'string') {
            return (
              <NavigationItem
                key={navigationItem.path}
                {...navigationItem}
                label={navigationItem.label as string}
                isActive={window.location.pathname.includes(navigationItem.path)}
              />
            );
          }
          const NavigationLabelComponent = navigationItem.label;

          return <NavigationLabelComponent key={navigationItem.path} {...navigationItem} />;
        })}
      </SiteTab>
    </Header>
  );
};

export default SiteHeader;
