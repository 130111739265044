import { TableRow, TableCell, Typography } from '@mui/material';
// import { AlarmIcon } from '../alarm-icon';
import { PumpData } from '../../types';
import { convertSecondsToHours } from 'src/utilities';

type TableRowItemProps = {
  row: PumpData;
  selected: boolean;
};

export const TableRowItem = ({ row, selected }: TableRowItemProps) => {
  const { siteName } = row;
  const { assetName } = row;
  const {
    averageDailyEfficiency,
    dailyCycles,
    dailyEnergyConsumption,
    dailyRuntime,
    dailyVolumetricFlow,
  } = row.data;

  const tableCellStyle = { maxWidth: 'sm', whiteSpace: 'normal', wordWrap: 'break-word' };

  return (
    <TableRow sx={{ cursor: 'pointer' }} hover selected={selected} data-sm="table-row">
      <TableCell data-sm="site-name-row" sx={tableCellStyle}>
        <Typography variant="subtitle2">{siteName ?? '-'}</Typography>
      </TableCell>
      <TableCell data-sm="asset-row" sx={tableCellStyle}>
        <Typography variant="subtitle2">{assetName ?? '-'}</Typography>
      </TableCell>
      <TableCell data-sm="daily-runtime-row" sx={tableCellStyle}>
        <Typography variant="subtitle2">
          {dailyRuntime ? convertSecondsToHours(dailyRuntime, 2) : '-'}
        </Typography>
      </TableCell>
      <TableCell data-sm="daily-cycles-row" sx={tableCellStyle}>
        <Typography variant="subtitle2">{dailyCycles ?? '-'}</Typography>
      </TableCell>
      <TableCell data-sm="daily-volumetric-flow-row" sx={tableCellStyle}>
        <Typography variant="subtitle2">{dailyVolumetricFlow ?? '-'}</Typography>
      </TableCell>
      <TableCell data-sm="average-daily-efficiency-row" sx={tableCellStyle}>
        <Typography variant="subtitle2">{averageDailyEfficiency ?? '-'}</Typography>
      </TableCell>
      <TableCell data-sm="total-daily-energy-consumption-row" sx={tableCellStyle}>
        <Typography variant="subtitle2">{dailyEnergyConsumption ?? '-'}</Typography>
      </TableCell>
      {/* <TableCell data-sm="alarms-active-row" sx={tableCellStyle}>
        <Typography variant="subtitle2">
          {alarmsActive ? <AlarmIcon alarmType={alarmsActive} /> : '-'}
        </Typography>
      </TableCell> */}
    </TableRow>
  );
};
