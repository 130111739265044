import { ControllerAdditionalInfo } from 'src/types';
import { PREDEFINED, RANGE } from '../components/ControllerModalContent/config';
import { ActiveModeType } from '../types';
import { filterData } from '../utils';

type UseActiveModeTypes = {
  activeMode?: ActiveModeType;
  controllerAdditionalInfo?: ControllerAdditionalInfo[];
};

export const useActiveMode = ({ activeMode, controllerAdditionalInfo }: UseActiveModeTypes) => {
  const activeModeTagId = activeMode?.tagId;
  const activeModeValue = activeMode?.value;
  const activeModeName = activeMode?.name;

  let displayMode = '';

  const filteredModeTags = filterData(controllerAdditionalInfo, 'mode');

  const isMultipleModeTags = filteredModeTags?.length > 1;
  const findControlleAdditionalInfo = controllerAdditionalInfo?.find(
    (item) => item.id === activeModeTagId
  );

  const allowedInputs = findControlleAdditionalInfo?.advancedTagMapping?.allowedInputs;

  if (!isMultipleModeTags && allowedInputs === PREDEFINED) {
    displayMode = `${activeModeValue}-${activeModeName}`;
  }
  if (!isMultipleModeTags && allowedInputs === RANGE) {
    displayMode = `${activeModeValue}`;
  }
  if (isMultipleModeTags && allowedInputs === PREDEFINED) {
    displayMode = `${findControlleAdditionalInfo?.displayName}-${activeModeValue}-${activeModeName}`;
  }
  if (isMultipleModeTags && allowedInputs === RANGE) {
    displayMode = `${findControlleAdditionalInfo?.displayName}-${activeModeValue}`;
  }

  return {
    displayMode: displayMode,
  };
};
