import { List, Paper, Stack, styled } from '@mui/material';

const AnimatedList = styled(List, {
  shouldForwardProp: (prop) => prop !== 'areSuggestionsEmpty',
})<{ areSuggestionsEmpty?: boolean }>(({ theme, areSuggestionsEmpty }) => ({
  maxHeight: 0,
  padding: '0px !important',
  border: `0px solid ${theme?.palette.grey[400]}`,
  borderRadius: `${theme?.shape.borderRadius}px`,
  borderTop: 0,
  borderTopLeftRadius: 0,
  borderTopRightRadius: 0,
  overflow: 'hidden',
  transition: 'max-height 0.2s ease-in-out, opacity 0.2s ease-in-out',
  '&.expanded': {
    maxHeight: 194,
    border: `${areSuggestionsEmpty ? 0 : 1}px solid ${theme?.palette.grey[400]}`,
    borderTop: 0,
    overflow: 'auto',
  },
}));

const Container = styled(Stack)({
  position: 'relative',
  width: '100%',
  padding: '8px',
});

const SuggestionsContainer = styled(Paper)(({ theme }) => ({
  position: 'absolute',
  top: '100%',
  left: 0,
  right: 0,
  zIndex: 1,
  padding: '8px',
  paddingTop: 0,
  marginTop: '-8px',
  background: theme.palette.background.default,
}));

export { AnimatedList, Container, SuggestionsContainer };
