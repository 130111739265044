import { Divider, IconButton, styled, SvgIcon, useTheme } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { SiteInterface } from '../../types';
import PlaceIcon from '@mui/icons-material/Place';
import { FunctionComponent } from 'react';
import { MenuPopover } from 'src/components';
import { useHtmlElement, useResponsive } from 'src/hooks';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import NearMeOutlinedIcon from '@mui/icons-material/NearMeOutlined';
import { MenuPopoverItem } from 'src/features/customer-accounts/components';
import { getAddressStringFromLocation } from '../../utils/getAddressString';
import {
  handleCopyAddressClick,
  handleOpenInAppleMapsClick,
  handleOpenInGoogleMapsClick,
} from '../../utils/siteAddressUtils';

interface ISiteActionProps {
  site: SiteInterface;
}

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.grey[700],
  flexShrink: 0,
}));

const SiteAction: FunctionComponent<ISiteActionProps> = ({ site }) => {
  const theme = useTheme();
  const isMobile = useResponsive('down', 'md');
  const address = getAddressStringFromLocation(site.location);
  const { addHtmlElement, removeHtmlElement, htmlElement } = useHtmlElement();
  return (
    <>
      <StyledIconButton
        sx={{
          color: theme.palette.grey[500],
        }}
        onClick={(event) => {
          addHtmlElement(event.currentTarget);
        }}
      >
        <SvgIcon fontSize={isMobile ? 'small' : 'medium'} component={MoreVertIcon} />
      </StyledIconButton>
      <MenuPopover
        sx={{ padding: '14px', width: '228px' }}
        onClose={removeHtmlElement}
        arrow="top-left"
        open={htmlElement}
      >
        <MenuPopoverItem
          color={theme.palette.grey[500]}
          description={address}
          icon={<SvgIcon fontSize="small" component={PlaceIcon} />}
          handleClick={(event: any) => {
            event.stopPropagation();
          }}
        />

        <Divider sx={{ marginBlock: 1 }} />
        <MenuPopoverItem
          handleClick={() => {
            handleCopyAddressClick(address);
          }}
          icon={<SvgIcon component={ContentCopyIcon} />}
          description={'Copy address'}
        />
        <MenuPopoverItem
          handleClick={() => {
            handleOpenInAppleMapsClick(address);
          }}
          description="Open in Apple Maps"
          icon={<SvgIcon component={NearMeOutlinedIcon} />}
        />
        <MenuPopoverItem
          handleClick={() => {
            handleOpenInGoogleMapsClick(address);
          }}
          description="Open in Google Maps"
          icon={<SvgIcon component={NearMeOutlinedIcon} />}
        />
      </MenuPopover>
    </>
  );
};

export default SiteAction;
