import { Accordion, Theme, styled } from '@mui/material';

type styleType = {
  theme: Theme;
};

const StyledAccoridon = styled(Accordion)(({ theme }: styleType) => ({
  '&:before': {
    display: 'none',
  },
  backgroundColor: theme.palette.background.default,
}));

const accoridonSummary = {
  width: '140px',
};

const accoridonSummaryTitle = {
  fontWeight: 'bold',
};

const accordionDetails = {
  padding: '0',
};

const inputsList = {
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
};

const inputWrapper = {
  width: '48%',
  margin: '0.5rem 0.3rem',
};

export {
  StyledAccoridon,
  accoridonSummary,
  accoridonSummaryTitle,
  accordionDetails,
  inputsList,
  inputWrapper,
};
