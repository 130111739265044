import { useAuthentication } from 'src/features/authentication/context';
import { Protected } from 'src/features/protected-route/components';
import { LoginLayout } from 'src/layouts';
import { SetProfilePage } from '../../pages';
import { ElementProps } from '../../types/element';
import { SITES_MANAGEMENT_PATH, TENANT_SWITCH_PATH } from '../../config';

export const SetProfileElement = ({ isSuperAdmin }: ElementProps) => {
  const { session, getUser, customerId } = useAuthentication();

  const getUniqueRedirectPath = () => {
    if (customerId.value) return SITES_MANAGEMENT_PATH + `?customerId=${customerId.value}`;
    return TENANT_SWITCH_PATH;
  };

  const SetProfileRouteElement = () => (
    <Protected
      isAuthenticated={session !== '' && !getUser()}
      isAuthorized={true}
      uniqueRedirectPath={getUniqueRedirectPath()}
    >
      <LoginLayout>
        <SetProfilePage />
      </LoginLayout>
    </Protected>
  );

  const getSetProfileElement = () => <SetProfileRouteElement />;

  return getSetProfileElement();
};
