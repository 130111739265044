import { Typography, Box, styled, Theme } from '@mui/material';

type StylesTypes = {
  theme: Theme;
};
type StylesDevicesTypes = {
  isMobile: boolean;
};

const StyledModalContentRow = styled(Box)(({ theme }: StylesTypes) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginTop: '10px',
  paddingRight: '8px',
  '&:hover': {
    backgroundColor: theme.palette.background.neutral,
    cursor: 'pointer',
  },
}));

const modalContentSide = {
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'row',
};

const StyledModalContentSide = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isMobile',
})(({ isMobile }: StylesDevicesTypes) => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: isMobile ? 'column' : 'row',
}));

const StyledGatewayNameLabel = styled(Typography)(({ theme }: StylesTypes) => ({
  textTransform: 'capitalize',
  background: theme.palette.info.lighter,
  color: theme.palette.info.dark,
  padding: '2px 8px',
  borderRadius: '6px',
}));

const StyledAssetNameLabel = styled(Typography)(({ theme }: StylesTypes) => ({
  textTransform: 'capitalize',
  background: theme.palette.grey[300],
  color: theme.palette.grey[700],
  padding: '2px 8px',
  borderRadius: '6px',
}));

const gatewayNameBox = {
  marginRight: '2px',
};

const assetNameLabelBox = {
  marginLeft: 'auto',
};

const StyledDisplayName = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'isMobile',
})(({ isMobile }: StylesDevicesTypes) => ({
  fontWeight: 'bold',
  wordBreak: 'break-word',
  marginRight: isMobile ? '5px' : '30px',
  maxWidth: isMobile ? '150px' : '100%',
}));

export {
  StyledModalContentRow,
  modalContentSide,
  StyledModalContentSide,
  StyledGatewayNameLabel,
  StyledAssetNameLabel,
  gatewayNameBox,
  assetNameLabelBox,
  StyledDisplayName,
};
