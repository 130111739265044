import { Stack } from '@mui/material';
import { FunctionComponent } from 'react';
import LogCard from './LogCard';
import { LogItem } from '../types';
import { random } from 'lodash';
import { MobileAlarmCard } from 'src/features/alarms/components/mobile/mobile-alarm-card';

const LogsList: FunctionComponent<{
  logs: LogItem[];
  onAlarmSelected: (alarmId: string) => void;
  onLogSelected: (log: LogItem) => void;
  onLogRevert: (log: LogItem) => void;
}> = ({ logs, onLogSelected, onLogRevert, onAlarmSelected }) => (
  <Stack flex="1" overflow="auto" paddingBottom="60px">
    {logs.map((logItem, index) =>
      logItem.type === 'log' ? (
        <LogCard
          onLogSelected={onLogSelected}
          onLogRevert={onLogRevert}
          key={logItem.id ?? random().toString()}
          {...logItem}
        />
      ) : (
        <MobileAlarmCard
          logs
          acknowledgedId={logItem.acknowledgment}
          index={index}
          itemRefs={{ current: [] }}
          onClick={() => {
            onAlarmSelected(logItem.id);
          }}
          id={logItem.id}
          alarmData={logItem as any}
          key={logItem.id ?? random().toString()}
        />
      )
    )}
  </Stack>
);

export default LogsList;
