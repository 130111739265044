import { Typography, TypographyProps } from '@mui/material';
import { FunctionComponent, PropsWithChildren } from 'react';

const CardTitle: FunctionComponent<PropsWithChildren<Omit<TypographyProps, 'variant'>>> = ({
  children,
  ...props
}) => (
  <Typography variant="subtitle1" {...props}>
    {children}
  </Typography>
);

export default CardTitle;
