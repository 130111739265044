import { IconsTypesProps } from 'src/assets/icons/types';

export const WaterTowerFilterIcon = ({ width = 22, height = 22 }: IconsTypesProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.81641 6.13867C5.81641 5.58639 6.26412 5.13867 6.81641 5.13867H18.8164C19.3687 5.13867 19.8164 5.58639 19.8164 6.13867V9.71387C19.8164 9.82432 19.7269 9.91387 19.6164 9.91387H16.1193C15.1252 9.91387 14.3193 10.7198 14.3193 11.7139V20.8294C14.3193 20.9398 14.2297 21.0294 14.1193 21.0294H13.2831H11.9893C11.8788 21.0294 11.7893 20.9398 11.7893 20.8294V11.7139C11.7893 10.7198 10.9834 9.91387 9.98928 9.91387H6.01641C5.90595 9.91387 5.81641 9.82432 5.81641 9.71387V6.13867Z"
      fill="#049ACC"
    />
    <path
      d="M4.81641 3C4.81641 2.44771 5.26412 2 5.81641 2H19.8164C20.3687 2 20.8164 2.44772 20.8164 3V9.83721C20.8164 10.3895 20.3687 10.8372 19.8164 10.8372H16.3164C15.7641 10.8372 15.3164 11.2849 15.3164 11.8372V21C15.3164 21.5523 14.8687 22 14.3164 22H11.8164C11.2641 22 10.8164 21.5523 10.8164 21V11.8372C10.8164 11.2849 10.3687 10.8372 9.81641 10.8372H5.81641C5.26412 10.8372 4.81641 10.3895 4.81641 9.83721V3Z"
      stroke="#0461C1"
    />
  </svg>
);
