import { ReactElement, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { TypographyTypeMap } from '@mui/material/Typography';

import { modalContent, iconBox, headingText, StyledDescription } from './style';

type TypographyVariantsTypes = TypographyTypeMap['props']['variant'];

type ConfirmationModalProps = {
  onClose: () => void;
  state: boolean;
  closeIcon?: ReactElement;
  mainIcon?: ReactElement;
  contentWidth?: number;
  heading: string;
  headingVariant?: TypographyVariantsTypes;
  description: string;
  descriptionVariant?: TypographyVariantsTypes;
};

export const ConfirmationModal = ({
  onClose,
  state,
  closeIcon,
  mainIcon,
  contentWidth = 400,
  heading,
  headingVariant,
  description,
  descriptionVariant,
}: ConfirmationModalProps) => {
  const styledModalContent = { ...modalContent, width: `${contentWidth}px` };

  useEffect(() => {
    if (state) {
      setTimeout(() => {
        onClose();
      }, 3000);
    }
  }, [state, onClose]);

  return (
    <Box sx={styledModalContent} data-sm="confirmation-modal">
      <Box sx={iconBox} onClick={onClose}>
        {closeIcon}
      </Box>
      <Box>{mainIcon}</Box>
      <Typography sx={headingText} variant={headingVariant} data-sm="confirmation-modal-text">
        {heading}
      </Typography>
      <StyledDescription variant={descriptionVariant}>{description}</StyledDescription>
    </Box>
  );
};
