import waterTreatmentPlant from './water_treatment_plant.svg';
import waterTreatmentPlantWarning from './water_treatment_plant_warning.svg';
import waterTreatmentPlantCritical from './water_treatment_plant_critical.svg';
import waterTreatmentPlantOffline from './water_treatment_plant_offline.svg';

import liftStation from './lift_station.svg';
import liftStationWarning from './lift_station_warning.svg';
import liftStationCritical from './lift_station_critical.svg';
import liftStationOffline from './liftStationOffline.svg';

import waterTower from './water_tower.svg';
import waterTowerWarning from './water_tower_warning.svg';
import waterTowerCritical from './water_tower_critical.svg';
import waterTowerOffline from './waterTowerOffline.svg';

import waterWell from './water_well.svg';
import waterWellWarning from './water_well_warning.svg';
import waterWellCritical from './water_well_critical.svg';
import waterWellOffline from './waterWellOffline.svg';

import wastewaterTreatmentPlant from './wastewater_treatment.svg';
import wastewaterTreatmentPlantWarning from './wastewater_treatment_warning.svg';
import wastewaterTreatmentPlantCritical from './wastewater_treatment_critical.svg';
import wastewaterTreatmentPlantOffline from './wasteWaterOffline.svg';

import boosterPumpStation from './pump_booster_station.svg';
import boosterPumpStationCritical from './pump_booster_station_critical.svg';
import boosterPumpStationWarning from './pump_booster_station_warning.svg';
import boosterPumpStationOffline from './boosterPumpStationOffline.svg';

export const siteIcons: Record<string, any> = {
  lift_station_: liftStation,
  lift_station_warning: liftStationWarning,
  lift_station_critical: liftStationCritical,
  lift_station_offline: liftStationOffline,
  water_treatment_plant_: waterTreatmentPlant,
  water_treatment_plant_warning: waterTreatmentPlantWarning,
  water_treatment_plant_critical: waterTreatmentPlantCritical,
  water_treatment_plant_offline: waterTreatmentPlantOffline,
  wastewater_treatment_plant_: wastewaterTreatmentPlant,
  wastewater_treatment_plant_warning: wastewaterTreatmentPlantWarning,
  wastewater_treatment_plant_critical: wastewaterTreatmentPlantCritical,
  wastewater_treatment_plant_offline: wastewaterTreatmentPlantOffline,
  water_tower_: waterTower,
  water_tower_warning: waterTowerWarning,
  water_tower_critical: waterTowerCritical,
  water_tower_offline: waterTowerOffline,
  water_well_: waterWell,
  water_well_warning: waterWellWarning,
  water_well_critical: waterWellCritical,
  water_well_offline: waterWellOffline,
  booster_pump_station_: boosterPumpStation,
  booster_pump_station_critical: boosterPumpStationCritical,
  booster_pump_station_warning: boosterPumpStationWarning,
  booster_pump_station_offline: boosterPumpStationOffline,
};
