import { IconsTypesProps } from './types';
import { CURRENT_COLOR } from 'src/config';

export const ReportIcon = ({ fill }: IconsTypesProps) => {
  const color = fill ?? CURRENT_COLOR;

  return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.3852 2.37482C13.0369 2.02648 12.5694 1.83398 12.0836 1.83398H5.51107C4.50273 1.83398 3.67773 2.65898 3.67773 3.66732V18.334C3.67773 19.3423 4.49357 20.1673 5.5019 20.1673H16.5111C17.5194 20.1673 18.3444 19.3423 18.3444 18.334V8.09482C18.3444 7.60898 18.1519 7.14148 17.8036 6.80232L13.3852 2.37482ZM13.7611 16.5007H8.26107C7.7569 16.5007 7.3444 16.0882 7.3444 15.584C7.3444 15.0798 7.7569 14.6673 8.26107 14.6673H13.7611C14.2652 14.6673 14.6777 15.0798 14.6777 15.584C14.6777 16.0882 14.2652 16.5007 13.7611 16.5007ZM13.7611 12.834H8.26107C7.7569 12.834 7.3444 12.4215 7.3444 11.9173C7.3444 11.4132 7.7569 11.0007 8.26107 11.0007H13.7611C14.2652 11.0007 14.6777 11.4132 14.6777 11.9173C14.6777 12.4215 14.2652 12.834 13.7611 12.834ZM11.9277 7.33398V3.20898L16.9694 8.25065H12.8444C12.3402 8.25065 11.9277 7.83815 11.9277 7.33398Z"
        fill={color}
      />
    </svg>
  );
};
