import { useAuthentication } from 'src/features/authentication/context';
import { Protected } from 'src/features/protected-route/components';
import { HeaderOnlyLayout } from 'src/layouts';
import { ElementProps } from '../types/element';
import { AccountIssuePage } from '../pages';
import { LoadingElement } from './LoadingElement';

export const AccountIssueElement = ({ isSuperAdmin }: ElementProps) => {
  const { loggedIn } = useAuthentication();

  const AccountIssueRouteElement = () => (
    <Protected isAuthenticated={loggedIn.value} isAuthorized={true}>
      <HeaderOnlyLayout>
        <AccountIssuePage />
      </HeaderOnlyLayout>
    </Protected>
  );

  const getAccountIssueElement = () => {
    if (!loggedIn.loaded) return <LoadingElement />;

    if (loggedIn.loaded && !loggedIn.value) return <AccountIssueRouteElement />;

    return <AccountIssueRouteElement />;
  };

  return getAccountIssueElement();
};
