import { IconsTypesProps } from './types';
import { CURRENT_COLOR } from 'src/config';

export const MapPinIcon = ({ fill, width = 12, height = 17 }: IconsTypesProps) => {
  const color = fill ?? CURRENT_COLOR;

  return (
    <svg
      width={`${width}px`}
      height={`${height}px`}
      viewBox="0 0 12 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="MapPinIcon"
    >
      <path
        d="M6.03841 0.267578C2.81341 0.267578 0.205078 2.87591 0.205078 6.10091C0.205078 10.4759 6.03841 16.9342 6.03841 16.9342C6.03841 16.9342 11.8717 10.4759 11.8717 6.10091C11.8717 2.87591 9.26341 0.267578 6.03841 0.267578ZM6.03841 8.18425C4.88841 8.18425 3.95508 7.25091 3.95508 6.10091C3.95508 4.95091 4.88841 4.01758 6.03841 4.01758C7.18841 4.01758 8.12174 4.95091 8.12174 6.10091C8.12174 7.25091 7.18841 8.18425 6.03841 8.18425Z"
        fill={color}
      />
    </svg>
  );
};
