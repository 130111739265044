import React, { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { Box, Fade } from '@mui/material';
import {
  StyledNavItem,
  boxIcon,
  boxArrowIcon,
  StyledAdditionalLabelBox,
  StyledAdditionalLabel,
  StyledNavItemText,
  StyledNavItemTextLink,
} from './style';
import { NavItemProps } from '../../types';
import { useLocation, useNavigate } from 'react-router-dom';
import { CookiesPopup } from 'src/features/cookies/CookiesPopup';
import { CustomWaterLoading } from 'src/components';
import { ArrowDownIconSmall } from 'src/assets';
import { useBlockNavigation } from 'src/routes/elements/ReportFlow/BuildReportsElement/blockNavigationContext';
import { confirmNavigationProceed } from 'src/routes/elements/ReportFlow/BuildReportsElement/confirmNavigationProceed';
import { useWebSockets } from 'src/contexts/webSocketsContext/websockets.context';

export const NavItem = ({
  isActive,
  onClickHandler,
  handleCloseSideBar,
  title,
  path,
  icon,
  testId,
  subItems,
  toggleState,
  isSuperAdmin,
  footerNavItem,
  isCustomerAccountPage,
  additionalLabel,
  additionalLabelState,
}: NavItemProps) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const { alarmMessage } = useWebSockets();

  const liveAlarmValue = alarmMessage && alarmMessage?.data?.active_alarms;

  const [showCookieBanner, setShowCookieBanner] = useState(false);

  const greyColor = theme.palette.grey[400];
  const whiteColor = theme.palette.common.white;
  const color =
    isSuperAdmin && isCustomerAccountPage ? whiteColor : isActive ? whiteColor : greyColor;
  const isSubItems = subItems && subItems.length > 0;

  const iconComponent = React.createElement(icon, { fill: color });

  const blockNavigation = useBlockNavigation();

  const onHandleClick = () => {
    if (footerNavItem && title.toLowerCase() === 'support') {
      window.open(path, '_blank');
      return;
    }
    if (onClickHandler) onClickHandler(title);
    if (handleCloseSideBar && !isSubItems) handleCloseSideBar();
    if (title === 'Cookies') {
      setShowCookieBanner(true);
    } else {
      redirect();
    }
  };

  const redirect = () => {
    if (isSubItems) return;
    navigate(path + location.search);
  };

  const renderAdditionalLabel = () => {
    if (additionalLabelState && !additionalLabelState.loaded && title === 'Alarms') {
      return (
        <StyledAdditionalLabelBox loaded={'false'} theme={theme}>
          <CustomWaterLoading width={22} height={22} />
        </StyledAdditionalLabelBox>
      );
    }

    if (additionalLabelState && additionalLabel && additionalLabelState.value > 0) {
      return (
        <Fade in={additionalLabelState.loaded} timeout={500}>
          <StyledAdditionalLabelBox loaded={'true'} theme={theme}>
            <StyledAdditionalLabel variant="caption">
              <Box data-sm="active-alarms-indicator">
                {liveAlarmValue || additionalLabelState.value}
              </Box>
            </StyledAdditionalLabel>
          </StyledAdditionalLabelBox>
        </Fade>
      );
    }

    return null;
  };

  return (
    <>
      <StyledNavItem
        theme={theme}
        isActive={isActive!}
        onClick={() => {
          let shouldBlockNavigation = false;
          if (blockNavigation && blockNavigation[0]) shouldBlockNavigation = true;

          if (shouldBlockNavigation) {
            const confirmProceeding = confirmNavigationProceed();
            if (confirmProceeding) return onHandleClick();
          } else {
            onHandleClick();
          }
        }}
        data-sm={testId}
        isSuperAdmin={isSuperAdmin ?? false}
        isCustomerAccountPage={isCustomerAccountPage || false}
        footerNavItem={footerNavItem}
      >
        <Box sx={boxIcon}>{iconComponent}</Box>

        {isSubItems || path.includes('cookies') || path.includes('support') ? (
          <StyledNavItemText
            isSuperAdmin={isSuperAdmin ?? false}
            isCustomerAccountPage={isCustomerAccountPage || false}
            theme={theme}
            isActive={isActive!}
          >
            {title}
          </StyledNavItemText>
        ) : (
          <StyledNavItemTextLink
            isSuperAdmin={isSuperAdmin ?? false}
            isCustomerAccountPage={isCustomerAccountPage || false}
            theme={theme}
            isActive={isActive!}
            onClick={(event) => {
              let shouldBlockNavigation = false;
              if (blockNavigation && blockNavigation[0]) shouldBlockNavigation = true;

              if (shouldBlockNavigation) {
                const confirmProceeding = confirmNavigationProceed();
                if (!confirmProceeding) return event.preventDefault();
              }
            }}
            to={path + location.search}
          >
            {title}
          </StyledNavItemTextLink>
        )}
        {renderAdditionalLabel()}
        {isSubItems ? (
          <Box
            sx={{
              ...boxArrowIcon,
              transform: toggleState && isActive ? 'rotate(0deg)' : 'rotate(-90deg)',
            }}
          >
            <ArrowDownIconSmall fill={color} />
          </Box>
        ) : null}
      </StyledNavItem>
      {showCookieBanner && <CookiesPopup />}
    </>
  );
};
