import { Container, Stack, useTheme } from '@mui/material';
import { StyledMainTop, headerOnlyLayout } from './style';
import { Header } from '../header/Header';
import { HEADER } from 'src/config';
import { useResponsive } from 'src/hooks';

type HeaderOnlyLayoutProps = {
  children: React.ReactNode;
};

export const HeaderOnlyLayout = ({ children }: HeaderOnlyLayoutProps) => {
  const theme = useTheme();

  const isMobile = useResponsive('down', 'sm');

  return (
    <Container maxWidth={false} sx={{ height: '100%', p: 0 }}>
      <StyledMainTop theme={theme} isMobile={isMobile}>
        <Header />
      </StyledMainTop>
      <Stack justifyContent={'center'} height={`calc(100vh - ${HEADER.H_MAIN_DESKTOP * 2}px)`}>
        <Container sx={headerOnlyLayout}>{children}</Container>
      </Stack>
    </Container>
  );
};
