export const handleCopyAddressClick = (address: string, onFinished?: () => void) => {
  navigator.clipboard.writeText(address);
};

export const handleOpenInAppleMapsClick = (address: string, onFinished?: () => void) => {
  const url = `https://maps.apple.com/?q=${encodeURIComponent(address)}`;
  window.open(url, '_blank');
};

export const handleOpenInGoogleMapsClick = (address: string, onFinished?: () => void) => {
  const url = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(address)}`;
  window.open(url, '_blank');
};
