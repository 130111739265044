import { ReactElement } from 'react';
import { MenuItem, Box } from '@mui/material';

type MenuPopoverItemProps = {
  icon: ReactElement;
  description: string;
  color?: string;
  testId?: string;
  handleClick: VoidFunction;
};

export const MenuPopoverItem = ({
  icon,
  description,
  color,
  testId,
  handleClick,
}: MenuPopoverItemProps) => {
  const colorStyle = color || 'inherit';

  return (
    <MenuItem
      onClick={() => handleClick()}
      sx={{
        display: 'flex',
        px: '4px',
        py: '3px',
        alignItems: 'center',
        color: colorStyle,
      }}
    >
      {icon}
      <Box data-sm={testId}>{description}</Box>
    </MenuItem>
  );
};
