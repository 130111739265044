import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  Typography,
} from '@mui/material';
import { FunctionComponent } from 'react';

interface IDeleteLogConfirmDialogProps extends DialogProps {
  logTitle: string;
  onConfirm: () => void;
  onClose: () => void;
}

const DeleteLogConfirmDialog: FunctionComponent<IDeleteLogConfirmDialogProps> = ({
  logTitle,
  onConfirm,
  onClose,
  ...props
}) => (
  <Dialog {...props}>
    <Box borderBottom="1px solid #919EAB3D" padding="24px">
      <Typography fontSize="18px" fontWeight="bold">
        Delete Log
      </Typography>
    </Box>
    <DialogContent sx={{ padding: '24px' }}>
      <Typography fontSize="16px" fontWeight="400">
        Are you sure you want to delete <b>{logTitle}</b>?
      </Typography>
    </DialogContent>
    <DialogActions sx={{ borderTop: '1px solid #919EAB3D' }}>
      <Button onClick={onClose} variant="outlined">
        Cancel
      </Button>
      <Button onClick={onConfirm} variant="contained" color="error">
        Delete Log
      </Button>
    </DialogActions>
  </Dialog>
);

export default DeleteLogConfirmDialog;
