import React, { FC } from 'react';
import { DragDropContext, Droppable, OnDragEndResponder } from 'react-beautiful-dnd';
import { List } from '@mui/material';
import { DraggableListItem } from './DraggableListItem';
import { TagType } from '../../types/types';

export type Props = {
  items: any[];
  selectedTags: string[];
  dropdownData: { value: any[]; loaded: boolean };
  onDragEnd: OnDragEndResponder;
  onRemoveRow: (id: string) => void;
  handleSetRow: (
    rowId: string,
    tagId: string,
    gatewayId: string,
    parentType: string,
    tag: TagType | null
  ) => void;
  handleMobileListWidth: (value: string) => void;
};

export const DraggableList: FC<Props> = React.memo(
  ({
    items,
    onDragEnd,
    onRemoveRow,
    dropdownData,
    handleSetRow,
    handleMobileListWidth,
    selectedTags,
  }) => (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable-list">
        {(provided) => (
          <List
            sx={{ overflow: 'hidden', pt: '8px!important', pb: '8px' }}
            ref={provided.innerRef}
            data-testid="droppable-container"
            {...provided.droppableProps}
          >
            {items.map((item: any, index: number) => (
              <DraggableListItem
                item={item}
                rows={items}
                selectedTags={selectedTags}
                index={index}
                key={item.id}
                onRemoveRow={onRemoveRow}
                dropdownData={dropdownData}
                handleSetRow={handleSetRow}
                handleMobileListWidth={handleMobileListWidth}
                data-testid={`draggable-item-${item.id}`}
              />
            ))}
            {provided.placeholder}
          </List>
        )}
      </Droppable>
    </DragDropContext>
  )
);
