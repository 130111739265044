import { useMemo, useEffect } from 'react';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Divider,
  Drawer,
  Stack,
  styled,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { FormProvider, RHFAutocomplete, RHFCheckbox } from 'src/components/minimals/form';
import { filterOptions } from 'src/utilities';
import { FilterData } from './types';
import { FiltersReportPageTypes } from '../../types';
import { initialStateReportFilter } from '../../config/filterConfig';

type SetAppliedFiltersType = React.Dispatch<React.SetStateAction<FiltersReportPageTypes>>;

type ReportsNewFilterType = {
  open: boolean;
  handleFilterClose: VoidFunction;
  userOptions: { userId: string; label: string }[];
  appliedFilters: FiltersReportPageTypes;
  setAppliedFilters: SetAppliedFiltersType;
};

export const ReportsNewFilter = ({
  open,
  handleFilterClose,
  userOptions,
  appliedFilters,
  setAppliedFilters,
}: ReportsNewFilterType) => {
  const theme = useTheme();

  const labelOptions = userOptions?.map((item) => item.label);

  const methods = useForm<FilterData>({
    defaultValues: appliedFilters,
  });

  const {
    setValue,
    reset,
    handleSubmit,

    formState: { isSubmitting },
  } = methods;

  useEffect(() => {
    reset(appliedFilters);
  }, [appliedFilters, open, reset]);

  const DrawerRow = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: '8px 16px',
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start',
  }));

  const handleFilterSubmit = (data: FilterData) => {
    setAppliedFilters(() => ({ userId: data.userId, favorites: data.favorites }));
    handleFilterClose();
  };

  const handleFilterClear = () => {
    reset(initialStateReportFilter);
  };

  const handleUserChange = (newUserId: string | null) => {
    setValue('userId', newUserId);
  };
  const displayUserOptions = useMemo(() => {
    if (!labelOptions || !Array.isArray(labelOptions)) {
      return [];
    }

    const uniqueArray = labelOptions.filter((value, index, self) => self.indexOf(value) === index);
    return uniqueArray;
  }, [labelOptions]);

  return (
    <Drawer
      sx={{
        width: 320,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: 320,
          backgroundColor: theme.palette.background.default,
        },
      }}
      onClose={(event, reason) => {
        handleFilterClear();
        handleFilterClose();
      }}
      anchor="right"
      open={open}
    >
      <FormProvider methods={methods} onSubmit={handleSubmit((data) => handleFilterSubmit(data))}>
        <Stack sx={{ flexDirection: 'column', height: '100vh' }}>
          <Stack sx={{ height: '100%' }}>
            <DrawerRow>
              <Typography variant="h5">Filters</Typography>
            </DrawerRow>
            <Divider />
            <DrawerRow sx={{ paddingBottom: '0px' }}>
              <Typography variant="h6">Created By</Typography>
            </DrawerRow>
            <DrawerRow sx={{ paddingTop: '0px' }}>
              <RHFAutocomplete
                name="userId"
                size="small"
                fullWidth
                options={displayUserOptions}
                onChange={(_, newValue) => {
                  handleUserChange(newValue as string | null);
                }}
                filterOptions={(options: string[], state: any) =>
                  filterOptions(options, state, options)
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select a user"
                    inputProps={{
                      ...params.inputProps,
                      'data-sm': `created-by-user-input`,
                    }}
                  />
                )}
              />
            </DrawerRow>
            <Divider />
            <DrawerRow>
              <RHFCheckbox name="favorites" label="Favorites" />
            </DrawerRow>
          </Stack>
          <Stack sx={{ height: '100%', justifyContent: 'flex-end', padding: '22px 8px' }}>
            <DrawerRow>
              <Stack
                sx={{
                  width: '100%',
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                  justifyContent: 'space-around !important',
                }}
              >
                <Button
                  sx={{ flex: 1 }}
                  variant="outlined"
                  color="error"
                  onClick={handleFilterClear}
                >
                  Clear All
                </Button>
                <LoadingButton
                  sx={{ flex: 1, ml: '8px' }}
                  type="submit"
                  loading={isSubmitting}
                  variant="contained"
                  color="primary"
                >
                  Apply
                </LoadingButton>
              </Stack>
            </DrawerRow>
          </Stack>
        </Stack>
      </FormProvider>
    </Drawer>
  );
};
