import { useCallback } from 'react';
import { Box, AccordionSummary, AccordionDetails, Typography, useTheme, Grid } from '@mui/material';
import {
  StyledAccoridon,
  accoridonSummary,
  accoridonSummaryTitle,
  accordionDetails,
} from './style';
import { ControllerAdditionalInfo } from 'src/types';
import { DropdownModalItem, InputFieldModalItem } from './components';
import { LatestControlsForInhandTypes } from '../../hooks/types';
import { useResponsive } from 'src/hooks';
import { ArrowDownIcon } from 'src/assets';
import { formatNumberFromTheDevice } from '../../utils';

type ControllerModalContentItemProps = {
  title: string;
  data: ControllerAdditionalInfo[];
  setValue: (key: string, value: string) => void;
  selectedMode?: { id?: string; displayName?: string; value?: string };
  latestControlsForInhand: LatestControlsForInhandTypes[];
  isErrorOnUpdateControls: boolean;
  enableApplyButtonHandler: () => void;
  isDataLoading: boolean;
  isAppliedChanges: boolean;
  setIsAppliedChangedHandler: (value: boolean) => void;
  disableApplyButtonHandler: () => void;
  isCloseBtn: boolean;
};

export const ControllerModalContentItem = ({
  title,
  data,
  setValue,
  selectedMode,
  latestControlsForInhand,
  isErrorOnUpdateControls,
  enableApplyButtonHandler,
  isDataLoading,
  isAppliedChanges,
  setIsAppliedChangedHandler,
  disableApplyButtonHandler,
  isCloseBtn,
}: ControllerModalContentItemProps) => {
  const theme = useTheme();
  const greyColor = theme.palette.grey[800];
  const isMobile = useResponsive('down', 'md');

  const titleTooltip = useCallback((inputItem: ControllerAdditionalInfo) => {
    const { advancedTagMapping } = inputItem;
    const maxValue = advancedTagMapping?.maximumValue;
    const minValue = advancedTagMapping?.minimumValue;

    const range =
      typeof minValue === 'number' && typeof maxValue === 'number'
        ? ` Range: ${minValue} - ${maxValue}`
        : '';
    const maxNumberOfDecimals =
      advancedTagMapping?.dataTypeTagMapping === 'FLOAT'
        ? `Max number of decimals: ${advancedTagMapping?.maximumDecimal}`
        : '';
    const assetName = inputItem.connectedAsset ? `Asset: ${inputItem.connectedAsset} ` : '';

    const isRelevantMode =
      advancedTagMapping?.relevantMode.length > 0 &&
      advancedTagMapping?.relevantMode !== 'DO_NOT_RESTRICT';

    if (!range && !maxNumberOfDecimals && !assetName && !isRelevantMode) return;

    const relevantMode = advancedTagMapping?.relevantMode;

    return (
      <Box>
        <Typography>{range}</Typography>
        <Typography>{maxNumberOfDecimals}</Typography>
        <Typography>{assetName}</Typography>
        {isRelevantMode ? (
          <Box sx={{ display: 'flex' }}>
            <Typography>Editable in:</Typography>
            {relevantMode.map((relevantMode: any) => (
              <Typography key={relevantMode.index} sx={{ marginLeft: '0.2rem' }}>
                {relevantMode.value}-{relevantMode.name}
              </Typography>
            ))}
          </Box>
        ) : null}
      </Box>
    );
  }, []);

  const getInputOptions = (inputItem: ControllerAdditionalInfo) => {
    const { advancedTagMapping } = inputItem;
    if (advancedTagMapping?.allowedInputs === 'PREDEFINED') {
      const predefinedValues =
        advancedTagMapping?.userDefinedValues?.map((item) =>
          item.name !== '' ? `${item.value} - ${item.name}` : `${item.value}`
        ) ?? [];

      const findValueIncludedInsideOfUserDefinedValues =
        advancedTagMapping?.userDefinedValues?.find(
          (item) => item.value === formatNumberFromTheDevice(inputItem.value)
        );

      if (!findValueIncludedInsideOfUserDefinedValues)
        predefinedValues.unshift(inputItem.value.toString());

      return predefinedValues;
    }
    return [];
  };

  if (!data || data?.length === 0) return null;

  return (
    <StyledAccoridon defaultExpanded={true}>
      <AccordionSummary expandIcon={<ArrowDownIcon stroke={greyColor} />} sx={accoridonSummary}>
        <Typography sx={accoridonSummaryTitle} variant="body1">
          {title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails sx={accordionDetails}>
        <Grid container spacing={2}>
          {data?.map((item: ControllerAdditionalInfo) => (
            <Grid item xs={data.length > 1 && !isMobile ? 6 : 12} key={item.id}>
              {item?.advancedTagMapping?.allowedInputs === 'PREDEFINED' ? (
                <DropdownModalItem
                  setValue={setValue}
                  getInputOptions={getInputOptions}
                  titleTooltip={titleTooltip}
                  item={item}
                  title={title}
                  selectedMode={selectedMode}
                  latestControlsForInhand={latestControlsForInhand}
                  isErrorOnUpdateControls={isErrorOnUpdateControls}
                  enableApplyButtonHandler={enableApplyButtonHandler}
                  isDataLoading={isDataLoading}
                  isAppliedChanges={isAppliedChanges}
                  setIsAppliedChangedHandler={setIsAppliedChangedHandler}
                  isCloseBtn={isCloseBtn}
                />
              ) : (
                <InputFieldModalItem
                  setValue={setValue}
                  titleTooltip={titleTooltip}
                  item={item}
                  title={title}
                  selectedMode={selectedMode}
                  latestControlsForInhand={latestControlsForInhand}
                  isErrorOnUpdateControls={isErrorOnUpdateControls}
                  enableApplyButtonHandler={enableApplyButtonHandler}
                  isDataLoading={isDataLoading}
                  isAppliedChanges={isAppliedChanges}
                  setIsAppliedChangedHandler={setIsAppliedChangedHandler}
                  disableApplyButtonHandler={disableApplyButtonHandler}
                  isCloseBtn={isCloseBtn}
                />
              )}
            </Grid>
          ))}
        </Grid>
      </AccordionDetails>
    </StyledAccoridon>
  );
};
