import { IconsTypesProps } from './types';
import { CURRENT_COLOR } from 'src/config';

export const CriticalIcon = ({ fill }: IconsTypesProps) => {
  const color = fill ?? CURRENT_COLOR;

  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-label="alarm-critical"
    >
      <path
        d="M13.7082 0H6.32632C6.03727 0 5.74822 0.122222 5.54811 0.322222L0.334119 5.53333C0.134009 5.73333 0.0117188 6.02222 0.0117188 6.31111V13.6778C0.0117188 13.9778 0.134009 14.2556 0.334119 14.4667L5.537 19.6667C5.74822 19.8778 6.03727 20 6.32632 20H13.6971C13.9972 20 14.2752 19.8778 14.4864 19.6778L19.6893 14.4778C19.7928 14.3743 19.8746 14.2513 19.93 14.1158C19.9854 13.9803 20.0131 13.8352 20.0117 13.6889V6.31111C20.0117 6.01111 19.8894 5.73333 19.6893 5.52222L14.4864 0.322222C14.2863 0.122222 13.9972 0 13.7082 0ZM10.0173 15.8889C9.21681 15.8889 8.57201 15.2444 8.57201 14.4444C8.57201 13.6444 9.21681 13 10.0173 13C10.8177 13 11.4625 13.6444 11.4625 14.4444C11.4625 15.2444 10.8177 15.8889 10.0173 15.8889ZM10.0173 11.1111C9.4058 11.1111 8.90552 10.6111 8.90552 10V5.55556C8.90552 4.94444 9.4058 4.44444 10.0173 4.44444C10.6287 4.44444 11.129 4.94444 11.129 5.55556V10C11.129 10.6111 10.6287 11.1111 10.0173 11.1111Z"
        fill={color}
      />
    </svg>
  );
};
