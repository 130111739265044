import { InputLabel, InputLabelProps, useTheme } from '@mui/material';
import React, { FunctionComponent, PropsWithChildren } from 'react';

const Label: FunctionComponent<PropsWithChildren<InputLabelProps>> = ({
  children,
  sx,
  ...props
}) => {
  const theme = useTheme();
  return (
    <InputLabel
      sx={{ backgroundColor: theme.palette.background.default, paddingInline: 1, ...sx }}
      {...props}
    >
      {children}
    </InputLabel>
  );
};

export default Label;
