import { IconsTypesProps } from './types';
import { CURRENT_COLOR } from 'src/config';

export const ExportIcon = ({ fill }: IconsTypesProps) => {
  const color = fill ?? CURRENT_COLOR;

  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="3.33325" y="15" width="13.3333" height="1.66667" rx="0.833333" fill={color} />
      <rect
        x="3.33325"
        y="16.6667"
        width="3.33333"
        height="1.66667"
        rx="0.833333"
        transform="rotate(-90 3.33325 16.6667)"
        fill={color}
      />
      <rect
        x="15"
        y="16.6667"
        width="3.33333"
        height="1.66667"
        rx="0.833333"
        transform="rotate(-90 15 16.6667)"
        fill={color}
      />
      <path
        d="M9.99994 12.5C9.82718 12.5013 9.65828 12.4489 9.51661 12.35L6.18328 10C5.80926 9.73471 5.71989 9.21707 5.98328 8.8417C6.11084 8.65966 6.30584 8.53612 6.52493 8.49857C6.74402 8.46101 6.96903 8.51254 7.14994 8.6417L9.99994 10.6334L12.8333 8.50003C13.2015 8.22389 13.7238 8.29851 13.9999 8.6667C14.2761 9.03489 14.2015 9.55722 13.8333 9.83336L10.4999 12.3334C10.3557 12.4415 10.1803 12.5 9.99994 12.5Z"
        fill={color}
      />
      <path
        d="M10.0001 10.8333C9.53984 10.8333 9.16675 10.4602 9.16675 10V3.33333C9.16675 2.8731 9.53984 2.5 10.0001 2.5C10.4603 2.5 10.8334 2.8731 10.8334 3.33333V10C10.8334 10.4602 10.4603 10.8333 10.0001 10.8333Z"
        fill={color}
      />
    </svg>
  );
};
