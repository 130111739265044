const tableContainer = {
  position: 'relative',
  overflow: 'unset',
};

const table = {
  minWidth: 800,
  borderCollapse: 'separate',
  borderSpacing: '0 24px',
};

const tableHeadCellStyle = {
  padding: '23px 24px',
  '&:first-of-type': {
    borderRadius: '16px 0 0 16px',
  },
  '&:last-of-type': {
    borderRadius: '0 16px 16px 0',
  },
};

export { tableContainer, table, tableHeadCellStyle };
