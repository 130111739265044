import { useTheme } from '@mui/material/styles';
import { Box, CardContent, Stack, Typography } from '@mui/material';
import { StyledCard, cardContent, cardFooter, cardHeader } from './style';
import { AlarmCheckbox } from 'src/components/minimals/checkbox/AlarmCheckbox';
import { formatUTCDateToLocal } from 'src/utilities';
import { EventStatus } from 'src/features/alarm-pop-up/types';
import { renderTitleIcon } from 'src/features/alarm-pop-up/utils';
import { formatAcknowledgedBy } from 'src/layouts/alarmMobileLayout/formatAcknowledgedBy';
import { AlarmData } from 'src/features/alarms/types';
type MobileAlarmCardProps = {
  id: string;
  itemRefs: React.MutableRefObject<(HTMLDivElement | null)[]>;
  index: number;
  logs?: boolean;
  alarmData: AlarmData;
  onClick: React.MouseEventHandler<HTMLDivElement> | undefined;
  acknowledgedId: string;
};

export const MobileAlarmCard = ({
  id,
  alarmData,
  onClick,
  itemRefs,
  logs,
  index,
  acknowledgedId,
}: MobileAlarmCardProps) => {
  const theme = useTheme();

  const getLabelStatusIcon = () => {
    if (alarmData.eventStatus === EventStatus.CLEARED) {
      return renderTitleIcon(alarmData, theme, theme.palette.grey[300]);
    } else {
      return renderTitleIcon(alarmData, theme);
    }
  };
  return (
    <StyledCard
      logs={logs}
      ref={(el) => (itemRefs.current[index] = el)}
      theme={theme}
      onClick={onClick}
    >
      <CardContent sx={cardContent}>
        <Box sx={cardHeader}>
          <Box>
            <Typography variant="subtitle1">{alarmData?.title}</Typography>
            <Typography variant="body2">{alarmData?.mainTag?.name}</Typography>
          </Box>
          <Box display="flex" justifyContent="flex-end">
            {getLabelStatusIcon()}
          </Box>
        </Box>
        <Box sx={cardFooter}>
          <Stack flexDirection={'row'}>
            <Stack>
              <span style={{ display: 'inline' }}>Site: </span>
              <span style={{ display: 'inline' }}>Start: </span>
              <span style={{ display: 'inline' }}>End: </span>
              {/* <span style={{ display: 'inline' }}>Duration: </span> */}
            </Stack>
            <Stack sx={{ ml: '10px' }}>
              <Typography variant="subtitle1" component="span">
                {alarmData?.siteName}
              </Typography>
              <Typography variant="subtitle1" component="span">
                {alarmData?.startDate ? formatUTCDateToLocal(false, alarmData?.startDate) : '-'}
              </Typography>
              <Typography variant="subtitle1" component="span">
                {alarmData?.endDate ? formatUTCDateToLocal(false, alarmData?.endDate) : '-'}
              </Typography>
              {/* <Typography variant="subtitle1" component="span">
                {+alarmData?.durationInSeconds > 0
                  ? formatSecondsToDhms(+alarmData?.durationInSeconds)
                  : '-'}
              </Typography> */}
            </Stack>
          </Stack>
        </Box>
        <Box>
          <AlarmCheckbox acknowledgedBy={formatAcknowledgedBy(alarmData?.acknowledgment)} />
          <Typography variant="body2" component="span" sx={{ fontWeight: 'bold' }}>
            {formatAcknowledgedBy(alarmData?.acknowledgment) || 'Unacknowledged'}
          </Typography>
        </Box>
      </CardContent>
    </StyledCard>
  );
};
