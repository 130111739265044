import { FormControl, MenuItem, Select, Stack, useTheme } from '@mui/material';
import { FunctionComponent, useEffect } from 'react';
import { DateRangePicker } from 'rsuite';
import { TimeFrameSelectValue, TimeFrameValue } from 'src/features/reports/types';
import Label from 'src/components/minimals/form/Label';
import { timeFrameOptions } from 'src/features/reports/utils/timeFrameToDateUtils';
import { useSnackbar } from 'notistack';

type TimeFrameOptionValue = typeof timeFrameOptions[number]['value'];
interface ITimeFrameSelectProps {
  timeFrames?: TimeFrameValue[];
  onChange: (value: TimeFrameOptionValue | [string, string]) => void;
  value: TimeFrameSelectValue | 'custom';
  testId?: string;
}

const TimeFrameSelect: FunctionComponent<ITimeFrameSelectProps> = ({
  timeFrames = timeFrameOptions,
  value,
  onChange,
  testId,
}) => {
  const theme = useTheme();
  useEffect(() => {
    const cssVariables = {
      '--rs-btn-primary-bg': theme.palette.primary.main,
      '--rs-btn-primary-hover-bg': theme.palette.primary.main,
      '--rs-btn-primary-active-bg': theme.palette.primary.main,
      '--rs-bg-active': theme.palette.primary.main,
      '--rs-calendar-range-bg': theme.palette.primary.lighter,
      '--rs-state-hover-bg': theme.palette.primary.lighter,
      '--rs-calendar-cell-selected-hover-bg': theme.palette.primary.main,
      '--rs-listbox-option-hover-text': theme.palette.primary.main,
      '--rs-listbox-option-hover-bg': theme.palette.primary.lighter,
      '--rs-input-focus-border': theme.palette.primary.main,
      '--rs-color-focus-ring': theme.palette.primary.lighter,
    };
    Object.entries(cssVariables).forEach(([variable, color]) => {
      document.documentElement.style.setProperty(variable, color);
    });
  }, [theme]);

  const { enqueueSnackbar } = useSnackbar();
  return (
    <Stack gap="8px">
      <FormControl size="small" fullWidth>
        <Label>Time Frame</Label>
        <Select
          inputProps={{
            'data-sm': testId,
          }}
          size="small"
          onChange={(event) => {
            if (event.target.value === 'custom') {
              onChange([new Date().toISOString(), new Date().toISOString()]);
              return;
            }

            onChange(event.target!.value as TimeFrameOptionValue);
          }}
          value={Array.isArray(value) ? 'custom' : value}
        >
          {timeFrames.map((timeFrame) => (
            <MenuItem key={timeFrame.value} value={timeFrame.value}>
              {timeFrame.label}
            </MenuItem>
          ))}
          <MenuItem value="custom">Custom</MenuItem>
        </Select>
      </FormControl>
      {Array.isArray(value) ? (
        <DateRangePicker
          className="custom-date-range-picker"
          cleanable={false}
          placement="bottomStart"
          ranges={[]}
          value={
            Array.isArray(value) && value.length === 2
              ? [new Date(value[0] as string), new Date(value[1] as string)]
              : [new Date(), new Date()]
          }
          onChange={(dates) => {
            if (dates && dates.length === 2) {
              const [startDate, endDate] = dates;
              const diffInDays = (endDate.getTime() - startDate.getTime()) / (1000 * 3600 * 24);

              if (diffInDays > 31) {
                enqueueSnackbar('Please select a range within 31 days.', { variant: 'error' });
                return;
              }

              onChange(dates.map((date) => date.toISOString()) as [string, string]);
            } else {
              onChange(['', '']);
            }
          }}
        />
      ) : (
        <></>
      )}
    </Stack>
  );
};
export default TimeFrameSelect;
