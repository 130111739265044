import { useAuthentication } from 'src/features/authentication/context';
import { Protected } from 'src/features/protected-route/components';
import { ACCOUNT_ISSUE_PATH, GATEWAYS_MANAGEMENT_HEADER, LOGIN_PATH } from '../../config';
import { AppLayout } from 'src/layouts';
import { NAV_ITEMS_HEADER_SELECTED_SITE, getNavItemsHeaderSelectedSiteSAAndAA } from 'src/features';
import { ElementProps } from '../../types/element';
import { LoadingElement } from '../LoadingElement';
import { GatewayManagementPage } from 'src/routes/pages';
import { AccessRoles } from 'src/features/user-account-details/types';

export const GatewayManagementElement = ({ isSuperAdmin }: ElementProps) => {
  const {
    isCustomerAccessAllowed,
    isSiteAccessAllowed,
    loggedIn,
    customerId,
    siteId,
    siteIdBelongsToCustomer,
    getCurrentRole,
    user,
  } = useAuthentication();

  const isAccountAdmin =
    getCurrentRole(customerId.value, siteId.value) === AccessRoles.ACCOUNT_ADMIN;

  const getUniqueRedirectPath = () => {
    if (!loggedIn.value) return LOGIN_PATH;
    if (!isSuperAdmin && (!isCustomerAccessAllowed.value || !isSiteAccessAllowed.value))
      return ACCOUNT_ISSUE_PATH + '?reason=Access Denied';
    if (!siteIdBelongsToCustomer.value) return ACCOUNT_ISSUE_PATH + '?reason=Site does not exist';
  };

  const GatewayManagementRouteElement = () => (
    <Protected
      isAuthenticated={loggedIn.value}
      isAuthorized={
        (isSuperAdmin || (isCustomerAccessAllowed.value && isSiteAccessAllowed.value)) &&
        siteIdBelongsToCustomer.value
      }
      uniqueRedirectPath={getUniqueRedirectPath()}
    >
      <AppLayout
        isSiteHeader
        headerTitle={GATEWAYS_MANAGEMENT_HEADER}
        headerNavItems={
          isSuperAdmin || isAccountAdmin
            ? getNavItemsHeaderSelectedSiteSAAndAA(user, isSuperAdmin)
            : NAV_ITEMS_HEADER_SELECTED_SITE
        }
      >
        <GatewayManagementPage />
      </AppLayout>
    </Protected>
  );

  const getGatewayManagementElement = () => {
    if (!loggedIn.loaded) return <LoadingElement />;

    if (loggedIn.loaded && !loggedIn.value) return <GatewayManagementRouteElement />;

    if (!customerId.value || !customerId.loaded || !siteId.value || !siteId.loaded)
      return <LoadingElement />;

    if (!siteIdBelongsToCustomer.loaded) return <LoadingElement />;

    if (isSuperAdmin && loggedIn.loaded) return <GatewayManagementRouteElement />;

    if (!isCustomerAccessAllowed.loaded || !isSiteAccessAllowed.loaded) return <LoadingElement />;

    return <GatewayManagementRouteElement />;
  };

  return getGatewayManagementElement();
};
