import { useAuthentication } from 'src/features/authentication/context';
import { ACCOUNT_ISSUE_PATH, LOGIN_PATH } from '../../config';
import { ElementProps } from '../../types/element';
import { LoadingElement } from '../LoadingElement';
import { AccessRoles } from 'src/features/user-account-details/types';
import { FormsRouteElement } from './FormsRouteElement';

export const FormsElement = ({ isSuperAdmin, route }: ElementProps) => {
  const { loggedIn, getCurrentRole, customerId } = useAuthentication();

  const getUniqueRedirectPath = () => {
    if (!loggedIn.value) return LOGIN_PATH;
    if (!isSuperAdmin && getCurrentRole(customerId.value, null) !== AccessRoles.ACCOUNT_ADMIN)
      return ACCOUNT_ISSUE_PATH + '?reason=Access Denied';
  };

  const getFormsElement = () => {
    if (!loggedIn.loaded || !customerId.loaded) {
      return <LoadingElement />;
    }

    return (
      <FormsRouteElement
        isSuperAdmin={isSuperAdmin}
        getUniqueRedirectPath={getUniqueRedirectPath}
        route={route ?? ''}
      />
    );
  };

  return getFormsElement();
};
