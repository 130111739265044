import { Theme, styled } from '@mui/material/styles';
import { Box } from '@mui/material';

type StylesTypes = {
  theme: Theme;
};

const StyledSelectedItemLabel = styled(Box)(({ theme }: StylesTypes) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  margin: '0.5rem 0.2rem',
  padding: '0.2rem 0.5rem',
  borderRadius: '6px',
  color: theme.palette.common.black,
  background: theme.palette.grey[300],
  textTransform: 'capitalize',
}));

const labelTitle = {
  fontWeight: 'bold',
};

const closeIconBox = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginLeft: '0.5rem',
  cursor: 'pointer',
};

export { StyledSelectedItemLabel, labelTitle, closeIconBox };
