import { IconsTypesProps } from './types';
import { CURRENT_COLOR } from 'src/config';

export const HamburgerIcon = ({ fill }: IconsTypesProps) => {
  const color = fill ?? CURRENT_COLOR;

  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.92828 6C4.40913 6 3.98828 6.42085 3.98828 6.94V7.06C3.98828 7.57915 4.40913 8 4.92828 8H21.0483C21.5674 8 21.9883 7.57915 21.9883 7.06V6.94C21.9883 6.42085 21.5674 6 21.0483 6H4.92828ZM4.98828 13C5.54057 13 5.98828 12.5523 5.98828 12C5.98828 11.4477 5.54057 11 4.98828 11C4.436 11 3.98828 11.4477 3.98828 12C3.98828 12.5523 4.436 13 4.98828 13ZM8.92828 11C8.40913 11 7.98828 11.4209 7.98828 11.94V12.06C7.98828 12.5791 8.40913 13 8.92828 13H21.0483C21.5674 13 21.9883 12.5791 21.9883 12.06V11.94C21.9883 11.4209 21.5674 11 21.0483 11H8.92828ZM3.98828 16.94C3.98828 16.4209 4.40913 16 4.92828 16H21.0483C21.5674 16 21.9883 16.4209 21.9883 16.94V17.06C21.9883 17.5791 21.5674 18 21.0483 18H4.92828C4.40913 18 3.98828 17.5791 3.98828 17.06V16.94Z"
        fill={color}
      />
    </svg>
  );
};
