import jwt_decode from 'jwt-decode';

export const decodeToken = (token: string) => {
  if (token) {
    try {
      const decoded = jwt_decode(token);
      return decoded;
    } catch (error) {
      console.error('Error decoding JWT payload:', error);
      return null;
    }
  }

  return null;
};
