import { Add } from '@mui/icons-material';
import { Stack, SvgIcon, Typography } from '@mui/material';
import { FunctionComponent } from 'react';
import { MenuPopover } from 'src/components';
import { useHtmlElement } from 'src/hooks';
import { StyledFloatingActionButton } from '../styles';

interface IFloatingAddLogProps {
  renderActions: (onClose: () => void) => JSX.Element;
  disabled?: boolean;
}

const FloatingAddLog: FunctionComponent<IFloatingAddLogProps> = ({
  renderActions,
  disabled = false,
}) => {
  const { addHtmlElement, removeHtmlElement, htmlElement } = useHtmlElement();
  return (
    <>
      <MenuPopover
        arrow="bottom-right"
        onClose={() => {
          removeHtmlElement();
        }}
        open={htmlElement}
      >
        {renderActions(() => {
          removeHtmlElement();
        })}
      </MenuPopover>
      <StyledFloatingActionButton
        onClick={(event) => {
          addHtmlElement(event.currentTarget);
        }}
        disabled={disabled}
        sx={{}}
        color="primary"
      >
        <Stack alignItems="center" flexDirection="row">
          <Typography variant="caption">Enter log</Typography>
          <SvgIcon component={Add} />
        </Stack>
      </StyledFloatingActionButton>
    </>
  );
};

export default FloatingAddLog;
