import { createContext, FunctionComponent, PropsWithChildren, useContext } from 'react';
import { AssetStateType } from '../types';

const assetPropertyContext = createContext<AssetStateType | null>(null);

const AssetPropertyDataProvider: FunctionComponent<
  PropsWithChildren<{
    value: AssetStateType;
  }>
> = ({ value, children }) => (
  <assetPropertyContext.Provider value={value}>{children}</assetPropertyContext.Provider>
);

export const useAssetPropertyData = () => useContext(assetPropertyContext);

export default AssetPropertyDataProvider;
