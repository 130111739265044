import { Theme } from '@mui/material/styles';

export default function TextField(theme: Theme) {
  return {
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiInputBase-input': {
            '&:-webkit-autofill': {
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: theme.palette.text.primary,
              transition: 'background-color 5000s ease-in-out 0s',
            },
          },
          '& input:-webkit-autofill, & .MuiInputBase-root:has(> input:-webkit-autofill)': {
            backgroundColor: theme.palette.primary.lighter,
          },
        },
      },
    },
  };
}
