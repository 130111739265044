import { CalendarScheduleIcon, EditIcon, ListIcon } from 'src/assets';
import {
  REPORTS_ASSET_PATH,
  REPORTS_BUILD_PREFIX,
  REPORTS_DATA_PATH,
  REPORTS_HISTORY,
} from 'src/routes/config';

export const ASSET_REPORT_NAV_ITEMS = [
  {
    title: 'Editor',
    icon: EditIcon,
    url: REPORTS_BUILD_PREFIX + '/' + REPORTS_ASSET_PATH,
  },
  {
    title: 'History',
    icon: ListIcon,
    url: `${REPORTS_BUILD_PREFIX}/${REPORTS_HISTORY}`,
  },
  {
    title: 'Schedule',
    icon: CalendarScheduleIcon,
    url: REPORTS_BUILD_PREFIX + '/schedule',
  },
];
export const DATA_REPORT_NAV_ITEMS = [
  {
    title: 'Editor',
    icon: EditIcon,
    url: REPORTS_BUILD_PREFIX + '/' + REPORTS_DATA_PATH,
  },
  {
    title: 'History',
    icon: ListIcon,
    url: `${REPORTS_BUILD_PREFIX}/${REPORTS_HISTORY}`,
  },
  {
    title: 'Schedule',
    icon: CalendarScheduleIcon,
    url: REPORTS_BUILD_PREFIX + '/schedule',
  },
];
