import { FunctionComponent } from 'react';
import { IFilterProps } from '../types';
import { IconButton, Stack, Typography } from '@mui/material';
import { FilterIcon } from 'src/assets/icons/FilterIcon';

const MobileFilters: FunctionComponent<IFilterProps> = ({ openFilters }) => (
  <Stack sx={{ minHeight: '50px', position: 'relative' }}>
    <Typography variant="h6" sx={{ flex: '1', color: 'black' }} />
    <IconButton
      sx={{
        position: 'absolute',
        right: ' 12px',
        top: '50%',

        transform: 'translateY(-50%)',
      }}
      onClick={() => {
        openFilters();
      }}
    >
      <FilterIcon />
    </IconButton>
  </Stack>
);

export default MobileFilters;
