import { useState } from 'react';
import { Container, useTheme, Divider, Button, Grid, Stack, InputAdornment } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useAuthentication } from 'src/features/authentication/context';
import { FormProvider, RHFTextField } from 'src/components/minimals/form';
import { UserIcon } from 'src/assets';
import { FormValuesType, FormValuesEnum } from '../types';
import { UserService } from 'src/services';
import {
  StyledContentWrapper,
  StyledUserProfileBox,
  StyledAdditionalInfoBox,
  emailInput,
  cancelBtn,
} from './style';
import { useDocumentTitle, useResponsive } from 'src/hooks';
import { decodeToken } from 'src/utilities';
import { REMEMBER_ME } from 'src/features/authentication/config';
import {
  createUser,
  LocalStorageService,
  SessionStorageService,
} from 'src/features/authentication/utils';
import { LoadingButton } from '@mui/lab';
import { PhoneInputCustom } from 'src/components/streametric/phone-input';

export const UserProfile = () => {
  const {
    user,
    customerId: { value: customerId },
    setUser,
  } = useAuthentication();
  const isMobile = useResponsive('down', 'md');
  const [isEdit, setIsEdit] = useState(false);
  const [formValues] = useState<FormValuesType>({
    firstName: user.firstName,
    lastName: user.lastName,
    email: user.email,
    mobilePhone: user.mobilePhone,
    password: 'oldpassword',
  });

  useDocumentTitle('Streametric - Profile');

  const PROFILE_PICTURE_SIZE = 128;
  const [isApplyButtonDisabled, setIsApplyButtonDisabled] = useState<boolean>(true);

  const theme = useTheme();
  const greyColor = theme.palette.grey[700];
  const greyLightColor = theme.palette.grey[300];

  const methods = useForm<FormValuesType>({
    defaultValues: formValues,
  });

  const {
    setValue,
    trigger,
    reset,
    watch,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = methods;

  const onChangeHandler = (e: any, key: keyof FormValuesType) => {
    const { value } = e.target;
    setIsApplyButtonDisabled(!(value !== user[key]));
    setValue(key, value);
    trigger(key);
  };

  const onCancelHandler = () => {
    setIsEdit(false);
    setIsApplyButtonDisabled(true);
    reset();
  };

  const onApplyHandler = async (data: FormValuesType) => {
    const { userId } = user;
    if (!customerId || !userId) return;
    const payload = {
      firstName: data.firstName,
      lastName: data.lastName,
      mobilePhone: data.mobilePhone,
    };
    try {
      const response = await UserService.editUserProfile(payload, customerId, userId);
      const token = decodeToken(response);

      LocalStorageService.getRaw(REMEMBER_ME) === 'true'
        ? LocalStorageService.set('user', JSON.stringify(token))
        : SessionStorageService.set('user', JSON.stringify(token));

      const user = createUser(token);
      setUser(user);

      setIsApplyButtonDisabled(true);
    } catch (err) {
      console.error(err);
    }
  };

  const handleEditClick = () => {
    setIsEdit(true);
  };

  return (
    <Container
      maxWidth={false}
      disableGutters
      data-sm="user-profile-content"
      sx={{ backgroundColor: theme.palette.background.paper }}
    >
      <FormProvider methods={methods} onSubmit={handleSubmit((data) => onApplyHandler(data))}>
        <StyledContentWrapper isMobile={isMobile}>
          <StyledUserProfileBox isMobile={isMobile} theme={theme}>
            <Stack
              sx={{
                alignItems: 'center',
                justifyContent: 'center',
                mb: 3,
                height: `${PROFILE_PICTURE_SIZE + 16}px`,
              }}
            >
              <Stack
                sx={{
                  width: `${PROFILE_PICTURE_SIZE + 16}px`,
                  height: `${PROFILE_PICTURE_SIZE + 16}px`,
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderRadius: '50%',
                  border: `1px dashed ${theme.palette.grey[500]}`,
                }}
              >
                <UserIcon
                  width={PROFILE_PICTURE_SIZE}
                  height={PROFILE_PICTURE_SIZE}
                  fill={greyColor}
                  outline={greyLightColor}
                />
              </Stack>
            </Stack>
            <Grid container spacing={1.5}>
              <Grid item xs={12}>
                <RHFTextField
                  name={FormValuesEnum.FIRST_NAME}
                  rules={{ required: 'First Name is required!' }}
                  label="First Name*"
                  testId="first-name-input"
                  disabled={!isEdit}
                  onChange={(e) => onChangeHandler(e, FormValuesEnum.FIRST_NAME)}
                />
              </Grid>
              <Grid item xs={12}>
                <RHFTextField
                  name={FormValuesEnum.LAST_NAME}
                  rules={{ required: 'Last Name is required!' }}
                  label="Last Name*"
                  testId="last-name-input"
                  disabled={!isEdit}
                  onChange={(e) => onChangeHandler(e, FormValuesEnum.LAST_NAME)}
                  sx={{ mt: errors.firstName ? 3 : 0 }}
                />
              </Grid>
            </Grid>
          </StyledUserProfileBox>

          <StyledAdditionalInfoBox isMobile={isMobile}>
            <Stack sx={{ height: '100%' }}>
              <Grid container spacing={1.5} sx={{ padding: '2rem' }}>
                <Grid item xs={isMobile ? 12 : 6} sx={{ mt: isMobile ? 1 : 0 }}>
                  <RHFTextField
                    disabled
                    sx={emailInput}
                    type="email"
                    name={FormValuesEnum.EMAIL}
                    rules={{ required: 'Email is required!' }}
                    label="Email*"
                    testId="email-input"
                    onChange={(e) => onChangeHandler(e, FormValuesEnum.EMAIL)}
                  />
                </Grid>
                <Grid
                  item
                  xs={isMobile ? 12 : 6}
                  sx={{ mt: isMobile ? 1 : 0, pointerEvents: !isEdit ? 'none' : 'auto' }}
                >
                  <PhoneInputCustom
                    sx={{ width: '100%' }}
                    name="mobilePhone"
                    label="Mobile Phone"
                    value={watch('mobilePhone') || ''}
                    inputProps={{
                      'data-sm': 'phone-input',
                    }}
                    disabled={!isEdit}
                    onChange={(newValue) => {
                      setIsApplyButtonDisabled(false);
                      setValue('mobilePhone', newValue as string);
                    }}
                  />
                </Grid>
                <Grid item xs={12} sx={{ mt: 1 }}>
                  <RHFTextField
                    disabled
                    type="password"
                    name={FormValuesEnum.PASSWORD}
                    label="Password"
                    testId="password-input"
                    onChange={(e) => onChangeHandler(e, FormValuesEnum.PASSWORD)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end" style={{ display: 'flex' }}>
                          <Button disabled variant="text">
                            Change Password
                          </Button>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
              <Divider sx={{ mb: 2 }} />
              <Stack
                sx={{ height: '100%', pb: 2 }}
                justifyContent={'flex-end'}
                alignItems={'flex-end'}
              >
                {isEdit ? (
                  <Stack flexDirection="row">
                    <Button sx={cancelBtn} variant="outlined" onClick={onCancelHandler}>
                      Cancel
                    </Button>
                    <LoadingButton
                      loading={isSubmitting}
                      variant="contained"
                      disabled={isApplyButtonDisabled || Object.keys(errors).length > 0}
                      type="submit"
                      sx={{ mr: 4 }}
                    >
                      Save Changes
                    </LoadingButton>
                  </Stack>
                ) : (
                  <Button
                    sx={{ width: '40px', mr: 4 }}
                    variant="contained"
                    onClick={handleEditClick}
                  >
                    Edit
                  </Button>
                )}
              </Stack>
            </Stack>
          </StyledAdditionalInfoBox>
        </StyledContentWrapper>
      </FormProvider>
    </Container>
  );
};

export default UserProfile;
