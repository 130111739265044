export const getSiteIdsOfSelectedEvents = (values: {}) => {
  const selectedEvents = Object.entries(values);

  const filteredItems = selectedEvents.filter((itemArray) => {
    if (itemArray[0].includes('events')) {
      const filteredItem: any = itemArray[1] === 'true';
      return filteredItem;
    }
    return null;
  });

  const siteIds = filteredItems.map((itemArray) => itemArray[0].split('-')[1]);
  return siteIds;
};
