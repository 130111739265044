import { Link as RouterLink } from 'react-router-dom';
// @mui
import { Box, Link } from '@mui/material';
//
import { BreadcrumbsLinkProps } from './types';

// ----------------------------------------------------------------------

type Props = {
  link: BreadcrumbsLinkProps;
  activeLast?: boolean;
  disabled: boolean;
  testId?: string;
};

export default function BreadcrumbsLink({ link, activeLast, disabled, testId }: Props) {
  const { name, href, icon } = link;

  const styles = {
    display: 'inline-flex',
    alignItems: 'center',
    color: 'text.primary',
    ...(disabled &&
      !activeLast && {
        cursor: 'default',
        pointerEvents: 'none',
        color: 'text.disabled',
      }),
  };

  const renderContent = (
    <>
      {icon && (
        <Box
          component="span"
          sx={{
            mr: 1,
            display: 'inherit',
            '& svg': { width: 20, height: 20 },
          }}
          data-sm={link.testId}
        >
          {icon}
        </Box>
      )}
      <Box component="div" data-sm={link.testId}>
        {name}
      </Box>
    </>
  );

  if (href) {
    return (
      <Link to={href} component={RouterLink} sx={styles} data-sm={testId}>
        {renderContent}
      </Link>
    );
  }

  return (
    <Box sx={styles} data-sm={link.testId}>
      {renderContent}
    </Box>
  );
}
