import { FunctionComponent, useEffect, useMemo, useRef, useState } from 'react';
import { AssetField as AssetFieldType, ICustomFieldBaseProps } from '../types';
import { Box, Checkbox, TextField } from '@mui/material';
import { RHFAutocomplete } from 'src/components/minimals/form';
import useFieldSpacing from '../hooks/useFieldSpacing';
import { api } from 'src/api.client';
import { useAuthentication } from 'src/features/authentication/context';
import { useFormContext } from 'react-hook-form';
import { useFormRendererData } from '../formRenderer.context';

type Asset = {
  assetName: string;
  id: string;
  assetType: string;
};

const AssetField: FunctionComponent<ICustomFieldBaseProps<AssetFieldType>> = ({
  field,
  fieldsLength,
  disabled,
}) => {
  const fieldClass = useFieldSpacing(fieldsLength);
  const { watch, setValue } = useFormContext();
  const { customerId, siteId } = useAuthentication();
  const [error, setError] = useState<boolean>(false);
  const [assets, setAssets] = useState<Asset[]>([]);
  const assetsValue = watch('assets');
  const assetsRef = useRef<any>([]);
  const schema = useFormRendererData();

  useEffect(() => {
    assetsRef.current = assets;
    if (
      Array.isArray(assetsValue) &&
      assetsValue.some((element) => typeof element === 'string') &&
      assets.length
    ) {
      setValue(
        'assets',
        assets
          .filter((asset) => assetsValue.findIndex((assetValue) => asset.id === assetValue) !== -1)
          .map((asset) => ({ label: asset.assetName, value: asset.id }))
      );
    }
  }, [assetsValue, setValue, assets]);

  useEffect(() => {
    if (customerId.loaded && siteId.loaded) {
      (async () => {
        try {
          const response = await api.get<Asset[]>(
            '/sites/' + customerId.value + '/sites/' + siteId.value + '/assets'
          );
          const assetTypes =
            schema?.assetTypes && Array.isArray(schema?.assetTypes) ? schema.assetTypes : [];

          setAssets(
            response.data.filter(
              (asset) =>
                assetTypes.findIndex((assetType: any) => asset.assetType === assetType) !== -1
            )
          );
        } catch (err) {
          setError(true);
        }
      })();
    }
  }, [customerId, siteId, schema?.assetTypes]);

  const assetOptions = useMemo(
    () => assets.map((asset) => ({ label: asset.assetName, value: asset.id })),
    [assets]
  );

  return (
    <Box sx={fieldClass}>
      <RHFAutocomplete
        disabled={disabled}
        name="assets"
        multiple
        disableCloseOnSelect
        renderOption={(props, option, { selected }) => {
          const { key, ...optionProps } = props;
          return (
            <li key={key} {...optionProps}>
              <Checkbox style={{ marginRight: 8 }} checked={selected} />
              {option.label}
            </li>
          );
        }}
        getOptionKey={(option) => (typeof option === 'string' ? option : option.value)}
        options={assetOptions}
        isOptionEqualToValue={(option, value) => option.value === value.value}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Assets"
            error={error}
            helperText={error ? 'Failed to fetch assets' : undefined}
            inputProps={{
              ...params.inputProps,
              'data-sm': 'asset-type-input',
              //readOnly: isMobile,
            }}
          />
        )}
      />
    </Box>
  );
};

export default AssetField;
