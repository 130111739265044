import { Stack } from '@mui/material';
import { Circle, Wave } from './style';

type Props = {
  width?: number;
  height?: number;
  customColor?: string;
};

export const CustomWaterLoading = ({ width = 40, height = 40, customColor }: Props) => (
  <Stack sx={{ width: `${width}px`, height: `${height}px` }}>
    <Circle customColor={customColor}>
      <Wave customColor={customColor} />
    </Circle>
  </Stack>
);
