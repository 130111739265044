import React, { useState, useContext, useEffect } from 'react';
import { AlarmsService } from 'src/services';
import { AlarmsContextTypes, AlarmsProviderPropsTypes } from './types';

const AlarmsContext = React.createContext<AlarmsContextTypes>({} as AlarmsContextTypes);

export const useAlarms = (customerId?: string) => {
  const context = useContext(AlarmsContext);

  useEffect(() => {
    if (customerId) {
      context.setCustomerId(customerId);
    }
  }, [customerId, context]);

  return context;
};

export const AlarmsProvider = ({ children }: AlarmsProviderPropsTypes) => {
  const [alarmsCountValue, setAlarmsCountValue] = useState<{
    value: number;
    loaded: boolean;
  }>({ value: 0, loaded: false });

  const [customerId, setCustomerId] = useState<string | null>(null);

  useEffect(() => {
    if (customerId) {
      AlarmsService.getActiveAlarmCount(customerId)
        .then((response) => {
          setAlarmsCountValue({ value: response, loaded: true });
        })
        .catch((error) => {
          setAlarmsCountValue({ value: 0, loaded: true });
          console.error(error);
        });
    } else {
      setAlarmsCountValue({ value: 0, loaded: true });
    }
  }, [customerId]);

  return (
    <AlarmsContext.Provider value={{ alarmsCountValue, setCustomerId }}>
      {children}
    </AlarmsContext.Provider>
  );
};
