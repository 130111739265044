import { IconsTypesProps } from './types';
import { CURRENT_COLOR } from 'src/config';

export const UserIcon = ({ fill, outline, width = 40, height = 40 }: IconsTypesProps) => {
  const fillColor = fill ?? CURRENT_COLOR;
  const outlineColor = outline || 'none';

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="40" height="40" rx="20" fill={outlineColor} />
      <path
        d="M20 19C22.2091 19 24 17.2091 24 15C24 12.7909 22.2091 11 20 11C17.7909 11 16 12.7909 16 15C16 17.2091 17.7909 19 20 19Z"
        fill={fillColor}
      />
      <path
        d="M26 29C26.5523 29 27 28.5523 27 28C27 24.134 23.866 21 20 21C16.134 21 13 24.134 13 28C13 28.5523 13.4477 29 14 29H26Z"
        fill={fillColor}
      />
    </svg>
  );
};
