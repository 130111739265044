import { useState } from 'react';
import { Box, useTheme, Button, DialogTitle, DialogActions, Divider } from '@mui/material';
import { FormProvider } from 'src/components/minimals/form';
import { WidgetModalItem } from '../widget-modal-item';
import {
  StyledModalContent,
  StyledWidgetsList,
  buttonsBox,
  discardBtn,
  DialogContentWithCustomScroll,
} from './style';
import { useForm } from 'react-hook-form';
import { MODAL_WIDGETS } from '../../config';
import { useResponsive } from 'src/hooks';
import { WidgetService } from 'src/services';
import { WidgetTypeEnum, AssetType } from '../../types/types';
import { TagResponse } from 'src/features/alarm-rules/types';
import { useAuthentication } from 'src/features/authentication/context';
import { GetDataSourceInputsResponse } from 'src/services/sites';
import { Controller } from 'src/types';

type WidgetModalContentProps = {
  title: string;
  onClose: () => void;
  selectedWidget: WidgetTypeEnum;
  selectWidgetHandler: (widget: WidgetTypeEnum) => void;
  position: {
    x: number;
    y: number;
  };
  toggleAddOrRemoveHandler: () => void;
  assets: AssetType[];
  gatewayInputs: TagResponse[];
  dataSourceInputs?: GetDataSourceInputsResponse[];
  controllers: Controller[];
};

type WidgetModalFormValues = {
  tower: string;
  liftStationWetWell: string;
  pumpWidget: string;
  barsGraph: string;
  spiralGraph: string;
  linearGraph: string;
  spiralDotsGraph: string;
  linearBarsGraph: string;
  liveData: string[];
};

export const WidgetModalContent = ({
  title,
  onClose,
  selectedWidget,
  selectWidgetHandler,
  position,
  toggleAddOrRemoveHandler,
  assets,
  gatewayInputs,
  dataSourceInputs,
  controllers,
}: WidgetModalContentProps) => {
  const theme = useTheme();
  const isMobile = useResponsive('down', 'sm');
  const [selectedWidgetSubtype, setSelectedWidgetSubType] = useState<string>('');
  const {
    customerId: { value: customerId },
    siteId: { value: siteId },
  } = useAuthentication();
  const onDiscardHandler = () => {
    onClose();
  };

  const getWidth = (widgetType: WidgetTypeEnum) =>
    widgetType === WidgetTypeEnum.CONTROLLER_WIDGET ? 1 : 2;

  const getHeight = (widgetType: WidgetTypeEnum) => {
    if (widgetType === WidgetTypeEnum.TOWER || widgetType === WidgetTypeEnum.LIFT_STATION_WET_WELL)
      return 4.4;
    if (widgetType === WidgetTypeEnum.PUMP) return 1.1;
    return 2.2;
  };
  const isLiveData = selectedWidget === WidgetTypeEnum.LIVE_DATA;

  const defaultValues: WidgetModalFormValues = {
    tower: '',
    liftStationWetWell: '',
    pumpWidget: '',
    barsGraph: '',
    spiralGraph: '',
    linearGraph: '',
    spiralDotsGraph: '',
    linearBarsGraph: '',
    liveData: [],
  };

  const methods = useForm<any>({
    defaultValues,
  });

  const { reset, setValue, watch } = methods;
  const values = watch();
  const valuesArray = Object.values(values);
  const isNotSelectedWidget = valuesArray.every(
    (value) => !value || (Array.isArray(value) && value.length === 0)
  );

  const selectedValues = Object.values(values);
  const connect: string = selectedValues.filter((el) => el)[0] as string;

  const splitString = (selectedValue: string) => {
    if (!selectedValue || Array.isArray(selectedValue)) return ['', ''];
    return selectedValue.split('-');
  };
  const idWithDataType = splitString(connect);
  const assetId = idWithDataType[0];
  const dataType = idWithDataType[1];

  const onApplyHandler = async (widgetType: WidgetTypeEnum) => {
    if (!customerId || !siteId) return;
    try {
      await WidgetService.add({
        title: isLiveData ? 'Live Data' : '',
        customerId: customerId,
        siteId: siteId,
        data: {
          widgetType: widgetType,
          connectedTo: assetId!,
          widgetSubType: selectedWidgetSubtype,
          ...(dataType && { widgetDataType: dataType }),
          positioning: {
            x: position.x,
            y: position.y,
            width: getWidth(widgetType),
            height: getHeight(widgetType),
          },
          ...(isLiveData && {
            tags: Array.isArray(values.liveData)
              ? values.liveData
                  .map((id: string) => {
                    const tag = gatewayInputs.find((input) => input.id === id);
                    return tag;
                  })
                  .filter(Boolean)
              : [],
          }),
        },
      });
      toggleAddOrRemoveHandler();
      onClose();
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <>
      <DialogTitle sx={{ backgroundColor: theme.palette.background.default }}>{title}</DialogTitle>
      <Divider />
      <DialogContentWithCustomScroll sx={{ backgroundColor: theme.palette.background.default }}>
        <FormProvider methods={methods}>
          <StyledModalContent theme={theme} isMobile={isMobile} data-sm="add-new-widget-modal">
            <StyledWidgetsList theme={theme} isMobile={isMobile}>
              {MODAL_WIDGETS.map(({ id, title, icon, name, widgetType, subTypes, label }) => (
                <WidgetModalItem
                  label={label}
                  setValue={setValue}
                  reset={reset}
                  key={id}
                  id={id}
                  widgetIcon={icon}
                  widgetName={title}
                  name={name}
                  widgetType={widgetType}
                  selectWidgetHandler={selectWidgetHandler}
                  assets={assets}
                  gatewayInputs={gatewayInputs?.filter((x) => !x.hidden)}
                  dataSourceInputs={dataSourceInputs}
                  controllers={controllers}
                  subTypes={subTypes}
                  selectedWidgetSubtype={selectedWidgetSubtype}
                  setSelectedWidgetSubType={setSelectedWidgetSubType}
                />
              ))}
            </StyledWidgetsList>
          </StyledModalContent>
        </FormProvider>
      </DialogContentWithCustomScroll>
      <Divider />
      <DialogActions sx={{ backgroundColor: theme.palette.background.default }}>
        <Box sx={buttonsBox}>
          <Button
            onClick={onDiscardHandler}
            sx={discardBtn}
            variant="outlined"
            data-sm="discard-modal-button"
          >
            Discard
          </Button>
          <Button
            disabled={isNotSelectedWidget}
            onClick={() => onApplyHandler(selectedWidget)}
            variant="contained"
            data-sm="apply-changes-modal-button"
          >
            Apply Changes
          </Button>
        </Box>
      </DialogActions>
    </>
  );
};
