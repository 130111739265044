import { Theme, css } from '@mui/material';
import styled from '@emotion/styled';

const tableCellStyle = {
  maxWidth: 'sm',
  whiteSpace: 'normal',
  wordWrap: 'break-word',
  flexGrow: 1,
};

const pageWrapper = {
  width: '100%',
  '@media (max-width:600px)': {
    margin: '0 auto',
  },
};

const gridContainer = {
  '@media (max-width:600px)': {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0 auto',
  },
};

const btnsContainer = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  marginTop: '3rem',
  '@media (max-width:600px)': {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  },
};

const lastEditedViewBox = {
  display: 'flex',
  alignItems: 'center',
  marginRight: '48px',
  '@media (max-width:600px)': {
    display: 'flex',
    marginBottom: '1rem',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
};

const cancelBtn = {
  marginRight: '1rem',
  '@media (max-width:600px)': {
    marginBottom: '0.5rem',
    marginRight: '0',
  },
};

const sharedPrimaryColorStyle = (theme: Theme) => css`
  color: ${theme.palette.primary.main};
  font-weight: 500;
`;

const ImportantText = styled.span((props: { theme: Theme }) =>
  sharedPrimaryColorStyle(props.theme)
);

const ImportantHoverableText = styled.span(
  (props: { theme: Theme }) => css`
    ${sharedPrimaryColorStyle(props.theme)}
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  `
);

export {
  tableCellStyle,
  pageWrapper,
  gridContainer,
  btnsContainer,
  lastEditedViewBox,
  cancelBtn,
  ImportantText,
  ImportantHoverableText,
};
