import {
  createContext,
  Dispatch,
  FunctionComponent,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useState,
} from 'react';

type BlockNavigationStateType = [boolean, Dispatch<SetStateAction<boolean>>];

const blockNavigationContext = createContext<BlockNavigationStateType>([false, () => {}]);

const BlockNavigationContextProvider: FunctionComponent<PropsWithChildren> = ({ children }) => {
  const blockNavigationState = useState(true);
  return (
    <blockNavigationContext.Provider value={blockNavigationState}>
      {' '}
      {children}
    </blockNavigationContext.Provider>
  );
};

export const useBlockNavigation = () => {
  const contextValue = useContext(blockNavigationContext);
  if (contextValue === undefined) {
    return null;
  }
  return contextValue;
};

export default BlockNavigationContextProvider;
