import { Box, Typography, useTheme } from '@mui/material';
import { CloseIcon } from 'src/assets';
import {
  closeIconBox,
  labelTitle,
  StyledSelectedItemLabel,
} from 'src/features/trends/components/selected-item-label/style';
import { SEPARATOR } from 'src/features/trends/config';

type Props = {
  selectedValue: string;
  setValue: (name: string, value: string) => void;
};

export const SelectedItemLabel = ({ selectedValue, setValue }: Props) => {
  const value = selectedValue.split(SEPARATOR);
  const theme = useTheme();
  const greyColor = theme.palette.grey[600];
  // const isTagElement = value.includes('row');
  // const isEventElement = value.includes('events');

  const rawInputname = value[1];

  const onDeleteHandler = () => {
    setValue(selectedValue, 'false');
  };

  return (
    <StyledSelectedItemLabel>
      <Typography sx={labelTitle}>{rawInputname}</Typography>
      <Box sx={closeIconBox} onClick={onDeleteHandler}>
        <CloseIcon width={10} fill={greyColor} />
      </Box>
    </StyledSelectedItemLabel>
  );
};
