import { Box } from '@mui/material';
import { SelectedItemLabel } from '../selected-item-label';
import { selectedItemsList } from './style';
import { StringKeyObjectType } from 'src/features/sites/types';

type Props = {
  values: StringKeyObjectType;
  setValue: (name: string, value: string) => void;
};

export const SelectedItemsListSiteTrends = ({ values, setValue }: Props) => {
  const valuesArr = Object.entries(values);
  const defaultSelectedElements = valuesArr.filter((item) => item[1] === 'true');

  return (
    <Box sx={selectedItemsList}>
      {defaultSelectedElements.map((selectedValue) => (
        <SelectedItemLabel
          key={selectedValue[0]}
          setValue={setValue}
          selectedValue={selectedValue[0]}
        />
      ))}
    </Box>
  );
};
