import { ChartData } from '../types';

type GetFormatedLabelsTypes = {
  data: ChartData[];
};

export const getFormatedLabels = ({ data }: GetFormatedLabelsTypes) => {
  /// Extract unique timestamps from the data
  const allTimestamps: number[] = data.reduce((timestamps: number[], item: ChartData) => {
    const timestampsForItem: number[] = item.data.map(([timestamp]) => timestamp);
    return [...timestamps, ...timestampsForItem];
  }, []);

  // Get unique timestamps as an array without using Set
  const uniqueTimestampsArray: number[] = allTimestamps.filter(
    (timestamp, index, self) => self.indexOf(timestamp) === index
  );

  // Sort timestamps
  uniqueTimestampsArray.sort((a, b) => a - b);

  // Format timestamps for labels
  const labels: string[] = uniqueTimestampsArray.map((timestamp) => timestamp.toString());
  const formattedLabels = labels.map((timestamp) => new Date(parseInt(timestamp, 10)));

  return formattedLabels;
};
