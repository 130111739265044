import { api, ACCOUNTS_CUSTOMERS_URL, COUNT_URL } from '../api';
import {
  GetAllParamsTypes,
  NewCustomerAccountTypes,
  CustomerAccountTypes,
  GetByIdParamsType,
} from './types';

// SUMMARY:
// Endpoint for getting all customer accounts.
const getAll = async (params?: GetAllParamsTypes) => {
  try {
    const response = await api({
      method: 'get',
      url: ACCOUNTS_CUSTOMERS_URL,
      params,
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

// SUMMARY:
// Endpoint for getting customer account by ID.
const getById = async (itemId: GetByIdParamsType) => {
  try {
    const response = await api({
      method: 'get',
      url: `${ACCOUNTS_CUSTOMERS_URL}/${itemId}`,
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return {};
  }
};

// SUMMARY:
// Endpoint for creating customer account.
const add = async (data: NewCustomerAccountTypes) => {
  const response = await api({
    method: 'post',
    url: ACCOUNTS_CUSTOMERS_URL,
    data,
  });

  return response.data;
};

// SUMMARY:
// Endpoint for editing customer accounts.
const edit = async (data: CustomerAccountTypes) => {
  const response = await api({
    method: 'patch',
    url: `${ACCOUNTS_CUSTOMERS_URL}/${data.id}`,
    data,
  });
  return response.data;
};

// SUMMARY:
// Endpoint for getting the total number of customer accounts.
const getCount = async () => {
  try {
    const response = await api({
      method: 'get',
      url: `${ACCOUNTS_CUSTOMERS_URL}${COUNT_URL}`,
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return {};
  }
};

// SUMMARY:
// Endpoint for getting all customer accounts with specific status.
const getAllByIdsAndStatus = async (ids?: string[], status?: string) => {
  try {
    const staticURLPart = `${ACCOUNTS_CUSTOMERS_URL}/status/filter`;

    let dynamicURLPart = '';

    if (ids) {
      dynamicURLPart += ids.map((id, index) => `${index === 0 ? '?' : ''}id=${id}`).join('&');
    }

    if (status) {
      dynamicURLPart += `&status=${status}`;
    }

    const response = await api({
      method: 'get',
      url: staticURLPart + dynamicURLPart,
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const CustomerService = {
  getAll,
  getById,
  getCount,
  add,
  edit,
  getAllByIdsAndStatus,
};
