import { parseISO } from 'date-fns';
import { utcToZonedTime, format } from 'date-fns-tz';

function formatDate(timestamp: string = '') {
  if (!timestamp || timestamp.length <= 0) return '';

  const date = parseISO(timestamp);
  const formattedDate = format(date, 'MMMM do yyyy. h:mma');
  return formattedDate;
}

function unixTimestampToDateTime(timestamp: number): string {
  const date = new Date(timestamp * 1000);
  const formattedDateTime = date.toLocaleString('en-US', {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  });
  return formattedDateTime;
}

function unixTimestampToISO8601(unixTimestamp: number) {
  if (!unixTimestamp) return '1970-01-01T00:00:00';
  const date = new Date(unixTimestamp * 1000);
  const year = date.getUTCFullYear();
  const month = String(date.getUTCMonth() + 1).padStart(2, '0');
  const day = String(date.getUTCDate()).padStart(2, '0');
  const hours = String(date.getUTCHours()).padStart(2, '0');
  const minutes = String(date.getUTCMinutes()).padStart(2, '0');
  const seconds = String(date.getUTCSeconds()).padStart(2, '0');
  return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
}

function formatUTCDateToLocal(showTimeOnly: boolean, utcTimestampStr: string = '') {
  if (!utcTimestampStr || utcTimestampStr.length <= 0) return '';
  const isoString =
    utcTimestampStr.length === 19
      ? utcTimestampStr.concat('.000000Z')
      : utcTimestampStr.concat('Z');
  const utcDate = parseISO(isoString);
  let patternOptions = 'MMMM do yyyy. h:mma';
  if (showTimeOnly) {
    patternOptions = 'h:mma';
  }
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const zonedDate = utcToZonedTime(utcDate, userTimeZone);

  const formattedDate = format(zonedDate, patternOptions, { timeZone: userTimeZone });
  return formattedDate;
}
function formatUTCDateToLocalDate(utcTimestampStr: string = '') {
  if (!utcTimestampStr || utcTimestampStr.length <= 0) return '';
  const isoString =
    utcTimestampStr.length === 19
      ? utcTimestampStr.concat('.000000Z')
      : utcTimestampStr.concat('Z');
  const utcDate = parseISO(isoString);
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const zonedDate = utcToZonedTime(utcDate, userTimeZone);

  return zonedDate;
}

function convertSecondsToHours(seconds: number, decimalPlace: number) {
  return `${(seconds / 3600).toFixed(decimalPlace)}h`;
}

function timestampConverter(timestamp: number) {
  const date = new Date(timestamp);
  const dateFormatOptions: Intl.DateTimeFormatOptions = {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  };

  const timeFormatOptions: Intl.DateTimeFormatOptions = {
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    timeZoneName: 'short',
  };

  const formattedDate = date.toLocaleDateString('en-US', dateFormatOptions);
  const formattedTime = date.toLocaleTimeString('en-US', timeFormatOptions);
  return `${formattedDate} ${formattedTime}`;
}

function formatSecondsToHoursAndMinutes(seconds: number): string {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);

  if (hours > 0 && minutes > 0) {
    return `${hours}h ${minutes}min`;
  } else if (hours > 0) {
    return `${hours}h`;
  } else if (minutes > 0) {
    return `${minutes}min`;
  } else {
    return '0min';
  }
}

export {
  formatDate,
  unixTimestampToDateTime,
  formatUTCDateToLocal,
  unixTimestampToISO8601,
  convertSecondsToHours,
  timestampConverter,
  formatUTCDateToLocalDate,
  formatSecondsToHoursAndMinutes,
};
