import { Theme, styled } from '@mui/material/styles';
import { Card, Stack } from '@mui/material';

type StylesTypes = {
  isMobile: boolean;
  theme?: Theme;
};

const StyledContentWrapper = styled(Stack, {
  shouldForwardProp: (prop) => prop !== 'isMobile',
})(({ isMobile }: StylesTypes) => ({
  justifyContent: 'flex-start',
  marginTop: '2rem',
  flexDirection: isMobile ? 'column' : 'row',
  marginBottom: isMobile ? '1rem' : '0',
}));

const StyledUserProfileBox = styled(Card, {
  shouldForwardProp: (prop) => prop !== 'isMobile',
})(({ isMobile, theme }: StylesTypes) => ({
  backgroundColor: theme ? theme.palette.background.default : 'fff',
  width: isMobile ? '100%' : '40%',
  padding: '2rem',
  marginRight: '1.5rem',
}));

const userIconBox = {
  display: 'flex',
  justifyContent: 'center',
  marginBottom: '2rem',
};

const lastNameInput = {
  marginTop: '1.5rem',
};

const StyledAdditionalInfoBox = styled(Card, {
  shouldForwardProp: (prop) => prop !== 'isMobile',
})(({ isMobile, theme }: StylesTypes) => ({
  backgroundColor: theme ? theme.palette.background.default : 'fff',
  width: isMobile ? '100%' : '60%',
  marginTop: isMobile ? '16px' : 0,
  height: 'fit-content',
}));

const additionalInfoInputsBox = {
  padding: '1.5rem 1.5rem 1rem 1.5rem',
};

const emailMobileBox = {
  display: 'flex',
};

const emailInput = {
  marginRight: '1rem',
};

const passwordInput = {
  margin: '1.5rem 0',
};

const btnContainer = {
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'flex-end',
  marginTop: '1.5rem',
  marginRight: '1.5rem',
  marginBottom: '1.5rem',
};

const cancelBtn = {
  marginRight: '0.8rem',
};

export {
  StyledContentWrapper,
  StyledUserProfileBox,
  StyledAdditionalInfoBox,
  userIconBox,
  lastNameInput,
  emailMobileBox,
  additionalInfoInputsBox,
  emailInput,
  passwordInput,
  btnContainer,
  cancelBtn,
};
