import {
  Box,
  Button,
  Divider,
  Drawer,
  DrawerProps,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { FilterGroup, FilterItem as FilterItemType } from '../types';
import { CustomWaterLoading } from 'src/components';

interface IFiltersDrawerProps extends DrawerProps {
  updateFilters: (filters: FilterItemType[]) => void;
  filters: FilterItemType[];
  isFormsLoading: boolean;
}

const FiltersDrawer: FunctionComponent<IFiltersDrawerProps> = ({
  updateFilters,
  filters,
  isFormsLoading,
  ...props
}) => {
  const [localFilterState, setLocalFilterState] = useState<FilterItemType[]>(filters);
  const theme = useTheme();

  useEffect(() => {
    setLocalFilterState(filters);
  }, [filters]);

  useEffect(() => {
    const cssVariables = {
      '--rs-btn-primary-bg': theme.palette.primary.main,
      '--rs-btn-primary-hover-bg': theme.palette.primary.main,
      '--rs-btn-primary-active-bg': theme.palette.primary.main,
      '--rs-bg-active': theme.palette.primary.main,
      '--rs-calendar-range-bg': theme.palette.primary.lighter,
      '--rs-state-hover-bg': theme.palette.primary.lighter,
      '--rs-calendar-cell-selected-hover-bg': theme.palette.primary.main,
      '--rs-listbox-option-hover-text': theme.palette.primary.main,
      '--rs-listbox-option-hover-bg': theme.palette.primary.lighter,
      '--rs-input-focus-border': theme.palette.primary.main,
      '--rs-color-focus-ring': theme.palette.primary.lighter,
    };
    Object.entries(cssVariables).forEach(([variable, color]) => {
      document.documentElement.style.setProperty(variable, color);
    });
  }, [theme]);

  const groupFilters = useMemo(() => {
    const groups: Record<
      string,
      {
        group: FilterGroup;
        filters: FilterItemType[];
      }
    > = {};

    localFilterState.forEach((filter) => {
      if (!groups[filter.group.name]) {
        groups[filter.group.name] = {
          group: filter.group,
          filters: [filter],
        };
      } else {
        groups[filter.group.name].filters.push(filter);
      }
    });

    return groups;
  }, [localFilterState]);

  return (
    <Drawer
      {...props}
      anchor="right"
      PaperProps={{
        sx: {
          width: '80%',
          backgroundColor: theme.palette.background.default,
          [theme.breakpoints.up('md')]: {
            width: '300px',
          },
        },
      }}
    >
      <Stack sx={{ height: '100%', paddingBottom: theme.spacing(3) }}>
        <Box sx={{ paddingInline: theme.spacing(3), flexShrink: 0, paddingTop: theme.spacing(3) }}>
          <Typography variant="h5">Filters</Typography>
        </Box>
        <Divider sx={{ marginTop: theme.spacing(3) }} />

        <Stack sx={{ paddingBottom: theme.spacing(3) }} overflow="auto" flexGrow="1">
          {Object.values(groupFilters).map((group) => (
            <Stack key={group.group.name} sx={{ padding: theme.spacing(2) }}>
              <Box>
                <Stack>
                  <Typography sx={{ paddingLeft: theme.spacing(1) }} variant="h6">
                    {group.group.label}
                  </Typography>
                </Stack>
              </Box>
              {group.filters.map((filter) => {
                const FilterComponent = filter.component;

                return (
                  <FilterComponent
                    key={filter.name}
                    onChange={(checked, name) => {
                      setLocalFilterState((prevState) =>
                        prevState.map((filter) =>
                          filter.name === name ? { ...filter, value: checked } : filter
                        )
                      );
                    }}
                    {...filter}
                  />
                );
              })}
              {group.group.name === 'event_type' && isFormsLoading ? <CustomWaterLoading /> : <></>}
            </Stack>
          ))}
        </Stack>
        <Stack
          sx={{ paddingInline: theme.spacing(3) }}
          flexDirection="row"
          justifyContent="space-between"
        >
          <Button
            onClick={() => {
              setLocalFilterState(localFilterState.map((filter) => ({ ...filter, value: null })));
              updateFilters(localFilterState.map((filter) => ({ ...filter, value: null })));
            }}
            variant="outlined"
            color="error"
          >
            Clear all
          </Button>
          <Button
            onClick={() => {
              updateFilters(localFilterState);
            }}
            variant="contained"
          >
            Apply
          </Button>
        </Stack>
      </Stack>
    </Drawer>
  );
};

export default FiltersDrawer;
