const searchList = {
  background: 'white',
  borderRadius: '16px',
  maxHeight: '186px',
  marginTop: '4px',
  height: 'auto',
  overflowY: 'auto',
  minHeight: 0,
  boxShadow: '-40px 40px 80px -8px #919EAB3D',
};

const searchListItemIcon = {
  minWidth: '40px',
};

const mapSearchBox = {
  position: 'relative',
  zIndex: 2,
  margin: '16px 16px 0 16px',
  '@media (min-width:600px)': {
    margin: '28px 40px 0 40px',
  },
};

const mapSearchWrapper = {
  position: 'absolute',
  width: '100%',
};

const mapSearchBarListBox = {
  position: 'relative',
  zIndex: 2,
};

const emptyListText = {
  padding: '0 16px',
  opacity: 0.5,
};

const searchListItemIconDisabled = {
  minWidth: '26px',
  marginLeft: '12px',
  marginRight: '4px',
};

const listItem = {
  flex: 'initial',
  '& span': {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
};

const filterNotificationBox = {
  position: 'absolute',
  top: '60px',
  width: '100%',
  '@media (min-width:600px)': {
    top: '76px',
  },
};

const mapSearchTextfield = {
  '@media (max-width:600px)': {
    '& fieldset': {
      border: 0,
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        border: 'none',
      },
      '&:hover fieldset': {
        border: 'none',
      },
      '&.Mui-focused': {
        boxShadow: 'none',
      },
    },
  },
};

const mapSearchTextfieldBox = {
  display: 'flex',
  alignItems: 'center',
  padding: '0 8px',
  borderRadius: '16px',
  backgroundColor: 'white',
  boxShadow: '0px 3px 8px 3px #00000021',
  '@media (min-width:600px)': {
    padding: '8px',
  },
};

export {
  searchList,
  searchListItemIcon,
  mapSearchBox,
  mapSearchWrapper,
  mapSearchBarListBox,
  emptyListText,
  searchListItemIconDisabled,
  listItem,
  filterNotificationBox,
  mapSearchTextfield,
  mapSearchTextfieldBox,
};
