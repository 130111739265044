const timeframeSection = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  paddingTop: '1rem',
};

const calendarsBox = {
  display: 'flex',
  marginLeft: '20px',
  justifyContent: 'space-between',
  alignItems: 'center',
};

export { timeframeSection, calendarsBox };
