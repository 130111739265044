import { useState, useCallback } from 'react';
import { FiltersReportPageTypes } from '../types';
import { initialStateReportFilter } from '../config/filterConfig';

export const useFiltersReportPage = () => {
  const [appliedFilters, setAppliedFilters] =
    useState<FiltersReportPageTypes>(initialStateReportFilter);

  const clearAllFilters = useCallback(() => {
    setAppliedFilters(initialStateReportFilter);
  }, []);

  const isAppliedFilters = Object.values(appliedFilters).some(
    (value) => value !== null && value !== false
  );

  return { appliedFilters, setAppliedFilters, clearAllFilters, isAppliedFilters };
};
