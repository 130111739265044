import { Box, styled } from '@mui/material';
import { FunctionComponent } from 'react';
import { AlarmPriorityType } from 'src/types/alarm-rules';
import { siteIcons } from '../../site-icons/siteIcons';
import { useResponsive } from 'src/hooks';

interface ISiteBoxProps {
  siteType?: string;
  highestActiveAlarm?: AlarmPriorityType | null;
}

const StyledBox = styled(Box)(({ theme }) => ({
  marginRight: theme.spacing(1),

  marginLeft: '6px',
  flexShrink: 0,
}));

const SiteBox: FunctionComponent<ISiteBoxProps> = ({ siteType, highestActiveAlarm }) => {
  const siteIcon =
    siteType &&
    siteIcons[(siteType + (highestActiveAlarm ? '_' + highestActiveAlarm : '_')).toLowerCase()];
  const isMobile = useResponsive('down', 'md');
  return (
    <StyledBox data-testid="styled-site-box">
      {siteIcon && (
        <img
          alt="Site display"
          style={isMobile ? { width: '28px', height: '28px' } : {}}
          src={siteIcon}
        />
      )}
    </StyledBox>
  );
};

export default SiteBox;
