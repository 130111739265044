import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  Typography,
} from '@mui/material';
import { FunctionComponent } from 'react';

interface IRestoreLogConfirmDialogProps extends DialogProps {
  logTitle: string;
  onConfirm: () => void;
  onClose: () => void;
  loading: boolean;
}

const RestoreLogConfirmDialog: FunctionComponent<IRestoreLogConfirmDialogProps> = ({
  logTitle,
  onConfirm,
  onClose,
  loading,
  ...props
}) => (
  <Dialog {...props}>
    <Box borderBottom="1px solid #919EAB3D" padding="24px">
      <Typography fontSize="18px" fontWeight="bold">
        Restore Log
      </Typography>
    </Box>
    <DialogContent sx={{ padding: '24px' }}>
      <Typography fontSize="16px" fontWeight="400">
        Are you sure you want to restore <b>{logTitle}</b>?
      </Typography>
    </DialogContent>
    <DialogActions sx={{ borderTop: '1px solid #919EAB3D' }}>
      <Button disabled={loading} onClick={onClose} variant="outlined">
        Cancel
      </Button>
      <Button disabled={loading} onClick={onConfirm} variant="contained">
        {loading ? 'Reverting log...' : 'Revert Log'}
      </Button>
    </DialogActions>
  </Dialog>
);

export default RestoreLogConfirmDialog;
