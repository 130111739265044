// LAYOUT
// ----------------------------------------------------------------------

const HEADER = {
  H_MAIN_DESKTOP: 88,
};

const NAV = {
  W_SIDE_BAR: 280,
};

//COLORS
const CURRENT_COLOR = 'currentColor';

//VARIABLES
const ACCESS_TOKEN = 'access_token';
const REFRESH_TOKEN = 'refresh_token';

//ROLES
enum ACCESS_ROLES {
  SUPER_ADMIN = 'SUPER_ADMIN',
  ACCOUNT_ADMIN = 'ACCOUNT_ADMIN',
  SITE_MANAGER = 'SITE_MANAGER',
  SITE_EMPLOYEE = 'SITE_EMPLOYEE',
  LIMITED_ACCESS = 'LIMITED_ACCESS',
}

export { HEADER, NAV, CURRENT_COLOR, ACCESS_TOKEN, REFRESH_TOKEN, ACCESS_ROLES };
