import { IconsTypesProps } from './types';
import { CURRENT_COLOR } from 'src/config';

export const MapGoogleIcon = ({ fill }: IconsTypesProps) => {
  const color = fill ?? CURRENT_COLOR;

  return (
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_14833_225278)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.79492 5.32286C2.12006 4.60522 2.5797 3.97868 3.11902 3.41187C4.33903 2.12957 5.81563 1.30836 7.56382 1.02818C10.0107 0.636266 12.2005 1.21468 14.0931 2.84936C14.2126 2.95249 14.2422 3.01308 14.1124 3.14071C13.4445 3.7969 12.787 4.46384 12.1256 5.12691C12.0579 5.1948 12.012 5.27774 11.8917 5.1673C10.2257 3.63576 7.50599 3.65337 5.73553 5.30524C5.12766 5.87248 4.69544 6.55188 4.41271 7.33097C4.37159 7.3039 4.32789 7.27983 4.28891 7.24975C3.45744 6.60817 2.62639 5.96573 1.79535 5.32329L1.79492 5.32286Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.39357 10.459C4.6326 11.0675 4.92904 11.6429 5.37026 12.1315C6.49174 13.3742 7.88224 13.9101 9.54776 13.7365C10.3218 13.6557 11.0269 13.3927 11.6858 12.9888C11.7492 13.0455 11.8091 13.1061 11.876 13.1585C12.647 13.7623 13.4194 14.3652 14.1909 14.9681C13.3397 15.779 12.3348 16.3187 11.2043 16.6054C8.53894 17.2813 6.08222 16.8542 3.90051 15.1271C2.99879 14.4129 2.28897 13.5311 1.78906 12.4873C2.65695 11.8113 3.52526 11.1349 4.39314 10.459H4.39357Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.1905 14.9676C13.4186 14.3647 12.6467 13.7618 11.8756 13.1581C11.8088 13.1056 11.7488 13.0451 11.6854 12.9883C12.208 12.5883 12.6441 12.1151 12.9303 11.5139C13.0442 11.2741 13.1247 11.0232 13.2006 10.7696C13.2528 10.5948 13.2365 10.5269 13.0211 10.5286C11.7364 10.5393 10.4517 10.5342 9.16699 10.5337C8.98565 10.5337 8.89483 10.4396 8.89455 10.2514C8.89455 9.37777 8.89883 8.50456 8.89069 7.63093C8.88898 7.46247 8.91854 7.39759 9.10788 7.39845C11.4772 7.40532 13.847 7.40403 16.2163 7.40059C16.3444 7.40059 16.4245 7.41005 16.4468 7.56604C16.7415 9.64505 16.5055 11.6312 15.4204 13.4662C15.0884 14.0278 14.6892 14.5409 14.1897 14.9676H14.1905Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.39258 10.4575C3.5247 11.1335 2.65639 11.8094 1.7885 12.4858C1.36441 11.6951 1.12024 10.846 1.00586 9.96116C0.808384 8.42833 1.02557 6.95351 1.6737 5.54744C1.70925 5.47009 1.75423 5.39747 1.79493 5.32227C2.62597 5.96428 3.45702 6.60672 4.28849 7.2483C4.32747 7.27838 4.37074 7.30244 4.41229 7.32952C4.06402 8.36988 4.08158 9.41283 4.39258 10.4571V10.4575Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_14833_225278">
          <rect width="15.6279" height="16" fill="white" transform="translate(0.935547 0.910156)" />
        </clipPath>
      </defs>
    </svg>
  );
};
