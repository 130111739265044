export const formatDate = (date: any) => {
  const month = Number(date?.$M) + 1;
  const day = date?.$D;
  const year = date?.$y;

  const formattedMonth = month?.toString().length > 1 ? `${month}` : `${0}${month}`;
  const formattedDay = day?.toString().length > 1 ? `${day}` : `${0}${day}`;

  return year && formattedMonth && formattedDay
    ? `${year}-${formattedMonth}-${formattedDay}`
    : null;
};
