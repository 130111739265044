// import { SelectedInputType } from '../types/selected-input';
import { SEPARATOR } from 'src/features/trends/config';
//Define returned value like SelectedInputType

export const getSelectedInputsLocalTrends = (values: Record<string, string>) => {
  const inputs = Object.entries(values);

  const filteredInputs = inputs.filter(
    ([key, value]) => value === 'true' && !key.includes('events')
  );

  const data = filteredInputs.map(([key]) => {
    const [, rawInputName, unit, gatewayId, inputId, inputName] = key.split(SEPARATOR);

    return {
      rawInputName: rawInputName ?? '',
      unit: unit ?? '',
      gatewayId: gatewayId ?? '',
      inputId: inputId ?? '',
      inputName: inputName ? inputName.replace(/\(dot\)/g, '.') : '',
    };
  });
  return data;
};
