import { IconsTypesProps } from './types';
import { CURRENT_COLOR } from 'src/config';

export const ListIcon = ({ fill, width = 22, height = 22 }: IconsTypesProps) => {
  const color = fill ?? CURRENT_COLOR;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="none"
    >
      <g>
        <path
          d="M6 12.75C6.2125 12.75 6.39062 12.6781 6.53438 12.5344C6.67812 12.3906 6.75 12.2125 6.75 12C6.75 11.7875 6.67812 11.6094 6.53438 11.4656C6.39062 11.3219 6.2125 11.25 6 11.25C5.7875 11.25 5.60938 11.3219 5.46562 11.4656C5.32187 11.6094 5.25 11.7875 5.25 12C5.25 12.2125 5.32187 12.3906 5.46562 12.5344C5.60938 12.6781 5.7875 12.75 6 12.75ZM6 9.75C6.2125 9.75 6.39062 9.67813 6.53438 9.53438C6.67812 9.39063 6.75 9.2125 6.75 9C6.75 8.7875 6.67812 8.60938 6.53438 8.46563C6.39062 8.32188 6.2125 8.25 6 8.25C5.7875 8.25 5.60938 8.32188 5.46562 8.46563C5.32187 8.60938 5.25 8.7875 5.25 9C5.25 9.2125 5.32187 9.39063 5.46562 9.53438C5.60938 9.67813 5.7875 9.75 6 9.75ZM6 6.75C6.2125 6.75 6.39062 6.67812 6.53438 6.53438C6.67812 6.39062 6.75 6.2125 6.75 6C6.75 5.7875 6.67812 5.60938 6.53438 5.46562C6.39062 5.32187 6.2125 5.25 6 5.25C5.7875 5.25 5.60938 5.32187 5.46562 5.46562C5.32187 5.60938 5.25 5.7875 5.25 6C5.25 6.2125 5.32187 6.39062 5.46562 6.53438C5.60938 6.67812 5.7875 6.75 6 6.75ZM8.25 12.75H12.75V11.25H8.25V12.75ZM8.25 9.75H12.75V8.25H8.25V9.75ZM8.25 6.75H12.75V5.25H8.25V6.75ZM3.75 15.75C3.3375 15.75 2.98438 15.6031 2.69063 15.3094C2.39688 15.0156 2.25 14.6625 2.25 14.25V3.75C2.25 3.3375 2.39688 2.98438 2.69063 2.69063C2.98438 2.39688 3.3375 2.25 3.75 2.25H14.25C14.6625 2.25 15.0156 2.39688 15.3094 2.69063C15.6031 2.98438 15.75 3.3375 15.75 3.75V14.25C15.75 14.6625 15.6031 15.0156 15.3094 15.3094C15.0156 15.6031 14.6625 15.75 14.25 15.75H3.75ZM3.75 14.25H14.25V3.75H3.75V14.25Z"
          fill={color}
        />
      </g>
    </svg>
  );
};
