import { LiftFilterIcon } from '../map-filter/LiftFilterIcon';
import { WaterTreatmentPlantFilterIcon } from '../map-filter/WaterTreatmentPlantFilterIcon';
import { WastewaterFilterIcon } from '../map-filter/WastewaterFilterIcon';
import { PumpFilterIcon } from '../map-filter/PumpFilterIcon';
import { WaterTowerFilterIcon } from '../map-filter/WaterTowerFilterIcon';
import { MapPinIcon } from 'src/assets';
import { WellFilterIcon } from '../map-filter/WellFilterIcon';

type Props = {
  siteType: string;
};

export const SiteTypeIcon = ({ siteType }: Props) => {
  const mapPinIconColor = '#049ACC';
  switch (siteType) {
    case 'LIFT_STATION':
      return <LiftFilterIcon />;
    case 'WATER_TREATMENT_PLANT':
      return <WaterTreatmentPlantFilterIcon />;
    case 'WASTEWATER_TREATMENT_PLANT':
      return <WastewaterFilterIcon />;
    case 'BOOSTER_PUMP_STATION':
      return <PumpFilterIcon />;
    case 'WATER_TOWER':
      return <WaterTowerFilterIcon />;
    case 'PROCESS_PLANT':
      return <MapPinIcon width={22} height={22} fill={mapPinIconColor} />;
    case 'COMBINED_SEWAGE_OVERFLOW':
      return <MapPinIcon width={22} height={22} fill={mapPinIconColor} />;
    case 'WATER_WELL':
      return <WellFilterIcon />;
    default:
      return <MapPinIcon width={22} height={22} fill={mapPinIconColor} />;
  }
};
