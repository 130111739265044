import { IconsTypesProps } from './types';
import { CURRENT_COLOR } from 'src/config';

export const InvitationWithdrawIcon = ({ fill }: IconsTypesProps) => {
  const color = fill ?? CURRENT_COLOR;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48">
      <path
        d="M480-463 140-680v460h386q0 14 2.5 30.5T535-160H140q-24 0-42-18.5T80-220v-520q0-23 18-41.5t42-18.5h680q23 0 41.5 18.5T880-740v291q-11-6-28.5-11.5T820-469v-211L480-463Zm0-64 340-213H140l340 213ZM773-40q-77.605 0-132.302-54Q586-148 586-226.014q0-78.014 54.698-133Q695.395-414 773-414q77.605 0 132.302 54.986Q960-304.028 960-226.014 960-148 905.302-94 850.605-40 773-40ZM660-210h226v-40H660v40Zm-520-10v-520 520Z"
        fill={color}
      />
    </svg>
  );
};
