import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

const StyledEditTitleBox = styled(Box)(({ theme }) => ({
  background: theme.palette.success.lighter,
  display: 'flex',
  alignItems: 'center',
  borderRadius: '8px',
  padding: '7px 8px',
  flexWrap: 'nowrap',
}));

const inputField = {
  '& .MuiInput-underline:before': {
    borderBottom: 'none',
  },
  '& .MuiInput-underline:after': {
    borderBottom: 'none',
  },
  '&:hover .MuiInput-underline:before': {
    borderBottom: 'none',
  },
};

const iconBox = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
  marginLeft: '8px',
};

const spanFiledStyle = {
  visibility: 'hidden',
  position: 'absolute',
  whiteSpace: 'nowrap',
  pointerEvents: 'none',
  font: 'inherit',
};

export { StyledEditTitleBox, inputField, iconBox, spanFiledStyle };
