import { Stack, Typography } from '@mui/material';
import { InfoIconV2 } from 'src/assets';
import { LightTooltip } from 'src/components';

type SimpleTooltipProps = {
  content: string;
  placement?:
    | 'bottom'
    | 'left'
    | 'right'
    | 'top'
    | 'bottom-end'
    | 'bottom-start'
    | 'left-end'
    | 'left-start'
    | 'right-end'
    | 'right-start'
    | 'top-end'
    | 'top-start'
    | undefined;
};

export const SimpleTooltip = ({ content, placement = 'left-start' }: SimpleTooltipProps) => (
  <LightTooltip
    placement={placement}
    arrow
    enterTouchDelay={0}
    title={
      <Typography
        variant="body1"
        sx={{
          fontWeight: 400,
          fontSize: '14px',
          whiteSpace: 'pre-wrap',
          wordWrap: 'break-word',
        }}
      >
        {content}
      </Typography>
    }
  >
    <Stack justifyContent="center" alignItems="center">
      <InfoIconV2 />
    </Stack>
  </LightTooltip>
);
