import { Theme, styled } from '@mui/material/styles';
import { TableRow, TableCell, Box } from '@mui/material';

type StylesTypes = {
  theme: Theme;
  lengtOfConnectedTags: boolean;
};

type StylesSiteTypes = {
  isTopPosition: boolean;
};

type StylesSiteNameBoxTypes = {
  isTags: boolean;
};

const StyledTableRow = styled(TableRow, {
  shouldForwardProp: (prop) => prop !== 'lengtOfConnectedTags',
})(({ theme, lengtOfConnectedTags }: StylesTypes) => ({
  height: '72px',
  boxShadow: '0px 0px 2px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12)',
  transform: 'scale(1)',
  backgroundColor: `${theme.palette.background.default} !important`,
  cursor: lengtOfConnectedTags ? 'auto' : 'pointer',
  borderRadius: '16px',
  '& td:first-of-type': {
    borderTopLeftRadius: theme.shape.borderRadius,
    borderBottomLeftRadius: theme.shape.borderRadius,
  },
  '& td:last-of-type': {
    borderTopRightRadius: theme.shape.borderRadius,
    borderBottomRightRadius: theme.shape.borderRadius,
  },
  '&:hover': {
    backgroundColor: 'transparent !important',
    '.css-civl3-MuiTableCell-root': {
      '&:not(:last-of-type)': {
        color: theme.palette.primary.main,
      },
    },
  },
}));
const tableCell = {
  maxWidth: 'sm',
  whiteSpace: 'normal',
  wordWrap: 'break-word',
  textAlign: 'right',
};

const StyledTableDirectionsCell = styled(TableCell, {
  shouldForwardProp: (prop) => prop !== 'isTopPosition',
})(({ isTopPosition }: StylesSiteTypes) => ({
  whiteSpace: 'normal',
  wordWrap: 'break-word',
  textAlign: 'right',
  maxWidth: '30px',
  paddingLeft: '0',
  verticalAlign: isTopPosition ? 'top' : 'center',
}));

const StyledTableAlarmCell = styled(TableCell, {
  shouldForwardProp: (prop) => prop !== 'isTopPosition',
})(({ isTopPosition }: StylesSiteTypes) => ({
  whiteSpace: 'normal',
  wordWrap: 'break-word',
  textAlign: 'right',
  maxWidth: '20px',
  padding: '16px 0',
  verticalAlign: isTopPosition ? 'top' : 'middle',
}));

const StyledTableSiteCell = styled(TableCell, {
  shouldForwardProp: (prop) => prop !== 'isTopPosition',
})(({ isTopPosition }: StylesSiteTypes) => ({
  whiteSpace: 'normal',
  wordWrap: 'break-word',
  textAlign: 'right',
  cursor: 'pointer',
  verticalAlign: isTopPosition ? 'top' : 'middle',
}));

const tableCellBox = {
  display: 'flex',
  alignItems: 'start',
};

const StyledTableSiteCellBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isTags',
})(({ isTags }: StylesSiteNameBoxTypes) => ({
  ...tableCellBox,
  width: '250px',
  maxWidth: isTags ? '250px' : 'none',
}));

const iconSiteBox = {
  display: 'flex',
  alignItems: 'center',
  paddingLeft: '14px',
  paddingRight: '10px',
};

const iconAlarmBox = {
  display: 'flex',
  justifyContent: 'flex-end',
};

const siteType = {
  textTransform: 'capitalize',
};

const noLiveDataTableCell = {
  display: 'flex',
  justifyContent: 'center',
};

export {
  StyledTableRow,
  tableCell,
  tableCellBox,
  iconSiteBox,
  iconAlarmBox,
  StyledTableSiteCellBox,
  siteType,
  noLiveDataTableCell,
  StyledTableSiteCell,
  StyledTableAlarmCell,
  StyledTableDirectionsCell,
};
