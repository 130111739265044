import { Stack, SxProps, Theme, Typography, useTheme } from '@mui/material';
import { FunctionComponent } from 'react';

const unitsNotToDisplay = ['hh:mm:ss'];

interface ILogItemProps {
  label: string;
  value: string;
  emphasizedValue?: boolean;
  sx: SxProps<Theme>;
  options?: any;
  defaultUnit?: string | null;
}

const LogItem: FunctionComponent<ILogItemProps> = ({
  label,
  value,
  defaultUnit,
  options,
  sx,
  emphasizedValue = true,
}) => {
  const theme = useTheme();

  const getValue = () => {
    if (Array.isArray(value) && value.length) return value.join(', ');
    if (!options) return value;

    const option = options.find((opt: any) => opt.value === value);

    if (!option) return value;

    return option.label;
  };

  const getUnit = () =>
    defaultUnit
      ? unitsNotToDisplay.findIndex((unit) => unit === defaultUnit) === -1
        ? defaultUnit
        : ''
      : '';

  return (
    <Stack flexDirection="row" sx={sx}>
      <Typography
        sx={{ color: theme.palette.grey[600], whiteSpace: 'nowrap', fontSize: '14px' }}
        variant="body1"
      >
        {label}:&nbsp;
      </Typography>
      <Typography
        sx={{
          whiteSpace: 'nowrap',
          fontSize: '14px',
          fontWeight: '600',
          color: !emphasizedValue ? theme.palette.grey[600] : undefined,
        }}
        variant="body1"
      >
        {getValue()} {getUnit()}
      </Typography>
    </Stack>
  );
};

export default LogItem;
