import { useTheme } from '@mui/material/styles';
import { CustomScroll, StyledSideBar } from './style';

type SideBarProps = {
  children: React.ReactNode;
  isOpen: boolean;
  isMobile: boolean;
  isSuperAdmin?: boolean;
  isCustomerAccountPage?: boolean;
};

export const SideBar = ({
  children,
  isOpen,
  isMobile,
  isSuperAdmin,
  isCustomerAccountPage,
}: SideBarProps) => {
  const theme = useTheme();
  return (
    <StyledSideBar
      theme={theme}
      isMobile={isMobile}
      isSuperAdmin={isSuperAdmin ?? false}
      isCustomerAccountPage={isCustomerAccountPage ?? false}
      isOpen={isOpen}
      data-sm="layout:side-bar"
    >
      <CustomScroll sx={{ overflowY: 'auto' }} theme={theme}>
        {children}
      </CustomScroll>
    </StyledSideBar>
  );
};
