import { Dialog, DialogTitle, DialogActions, DialogContent, Divider } from '@mui/material';
import { AlertDialogProps, CustomPaperProps } from './types';

export const AlertDialog = ({
  title,
  customContent,
  action,
  open,
  onClose,
  testId,
  maxWidth = 'sm',
  ...other
}: AlertDialogProps) => {
  const customPaperProps: CustomPaperProps = {
    'data-sm': testId,
  };

  return (
    <Dialog
      fullWidth
      maxWidth={maxWidth}
      open={open}
      sx={{ '& .css-eemfwe-MuiPaper-root-MuiDialog-paper': { height: 'fit-content' } }}
      onClose={onClose}
      PaperProps={{
        ...customPaperProps,
      }}
      {...other}
    >
      <DialogTitle sx={{ pb: 2 }}>{title}</DialogTitle>

      <Divider />

      {customContent && (
        <DialogContent
          sx={{
            typography: 'subtitle1',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            my: '24px',
          }}
        >
          {customContent}
        </DialogContent>
      )}

      <Divider />
      <DialogActions>{action}</DialogActions>
    </Dialog>
  );
};
