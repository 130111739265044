import dayjs from 'dayjs';
import { subDays } from 'date-fns';
import { TimeOptionType } from 'src/features/trends/types';

export const getTimestampsBasedOnTimeBox = (selectedBox: TimeOptionType) => {
  const days = Number(selectedBox.split('d')[0]);
  const now = new Date();

  return {
    fromDate: dayjs(subDays(now, days)),
    toDate: dayjs(now),
  };
};
