import { Container } from '@mui/material';
import { BREADCRUMBS_ROOT_SITE, BREADCRUMBS_EDIT_SITE } from '../config';
import { SiteDetailsForm } from '../components/SiteDetailsForm';
import { CustomBreadcrumbs } from 'src/components';
import { useDocumentTitle, useToggle } from 'src/hooks';
import { SITES_MANAGEMENT_PATH } from 'src/routes/config';
import { GetByIdParamsType } from 'src/services/sites/types';
import { SitesService } from 'src/services/sites';
import { useEffect, useState } from 'react';
import { Site, Location } from '../types/site';
import { useSite } from 'src/contexts/siteContext/sites.context';
import { useAuthentication } from 'src/features/authentication/context';

export const EditSite = () => {
  const {
    customerId: { value: customerId },
    siteId: { value: siteId },
  } = useAuthentication();
  const { onToggle } = useToggle();
  const [currentSite, setCurrentSite] = useState<Site>(
    new Site('', '', '', '', '', '', new Location())
  );

  useDocumentTitle('Streametric - Site Info');

  useEffect(() => {
    if (!customerId || !siteId) return;
    const params: GetByIdParamsType = {
      customerId: customerId,
      siteId: siteId,
    };

    SitesService.getById(params)
      .then((data: Site) => {
        setCurrentSite(data);
      })
      .catch((error) => console.error(error));
  }, [customerId, siteId]);

  const { getSite } = useSite();

  return (
    <Container maxWidth={false} disableGutters>
      <CustomBreadcrumbs
        links={[
          {
            name: BREADCRUMBS_ROOT_SITE,
            href: SITES_MANAGEMENT_PATH + '?customerId=' + customerId,
            testId: 'site-breadcrumb-root',
          },
          {
            name: getSite()?.siteName,
            href: `${SITES_MANAGEMENT_PATH}/dashboard?customerId=${customerId}&siteId=${siteId}`,
          },
          {
            name: BREADCRUMBS_EDIT_SITE,
          },
        ]}
        sx={{ my: '35px' }}
        testId="customer-account-breadcrumb"
      />
      <SiteDetailsForm
        isEdit={true}
        currentSite={currentSite}
        handleConfirmationWindow={onToggle}
      />
    </Container>
  );
};

export default EditSite;
