import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { datePicker } from './style';

type ModalCalendarsProps = {
  setValue: React.Dispatch<any>;
};

export const ModalCalendars = ({ setValue }: ModalCalendarsProps) => (
  <LocalizationProvider dateAdapter={AdapterDayjs}>
    <DatePicker
      sx={datePicker}
      label="Start Date"
      onChange={(date) => setValue((prevValue: any) => ({ ...prevValue, startDate: date }))}
      disableFuture
    />
    <DatePicker
      sx={datePicker}
      label="End Date"
      onChange={(date) => setValue((prevValue: any) => ({ ...prevValue, endDate: date }))}
      disableFuture
    />
  </LocalizationProvider>
);
