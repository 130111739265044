import { useCallback, useMemo } from 'react';
import { useAuthentication } from 'src/features/authentication/context';
import { Protected } from 'src/features/protected-route/components';
import { ACCOUNT_ISSUE_PATH, MANAGE_NOTIFICATIONS, LOGIN_PATH } from '../../config';
import { AppLayout } from 'src/layouts';
import {
  NAV_ITEMS_HEADER_SITES_MANAGEMENT,
  getNavItemsHeaderSiutesManagementSAAndAA,
} from 'src/features';
import { AlarmsNotificationPage } from 'src/routes/pages';
import { ElementProps } from '../../types/element';
import { LoadingElement } from '../LoadingElement';
import { AccessRoles } from 'src/features/user-account-details/types';

export const AlarmsNotificationElement = ({ isSuperAdmin }: ElementProps) => {
  const { isCustomerAccessAllowed, loggedIn, customerId, getCurrentRole, siteId, user } =
    useAuthentication();
  const isAccountAdmin =
    getCurrentRole(customerId.value, siteId.value) === AccessRoles.ACCOUNT_ADMIN;

  const getUniqueRedirectPath = useCallback(() => {
    if (!loggedIn.value) return LOGIN_PATH;
    if ((!isSuperAdmin && !isCustomerAccessAllowed.value) || (!isSuperAdmin && !isAccountAdmin))
      return ACCOUNT_ISSUE_PATH + '?reason=You do not have access to this page.';
  }, [loggedIn.value, isSuperAdmin, isCustomerAccessAllowed.value, isAccountAdmin]);

  const alarmsNotificationRouteElement = useMemo(
    () => (
      <Protected
        isAuthenticated={loggedIn.value}
        isAuthorized={isSuperAdmin || (isCustomerAccessAllowed.value && isAccountAdmin)}
        uniqueRedirectPath={getUniqueRedirectPath()}
      >
        <AppLayout
          headerTitle={MANAGE_NOTIFICATIONS}
          headerNavItems={
            isSuperAdmin || isAccountAdmin
              ? getNavItemsHeaderSiutesManagementSAAndAA(user, isSuperAdmin)
              : NAV_ITEMS_HEADER_SITES_MANAGEMENT
          }
        >
          <AlarmsNotificationPage />
        </AppLayout>
      </Protected>
    ),
    [
      user,
      loggedIn.value,
      isSuperAdmin,
      isCustomerAccessAllowed.value,
      isAccountAdmin,
      getUniqueRedirectPath,
    ]
  );

  const getAlarmsElement = () => {
    if (!loggedIn.loaded) return <LoadingElement />;

    if (loggedIn.loaded && !loggedIn.value) return alarmsNotificationRouteElement;

    if (!customerId.value || !customerId.loaded) return <LoadingElement />;

    if (isSuperAdmin && loggedIn.loaded) return alarmsNotificationRouteElement;

    if (!isCustomerAccessAllowed.loaded) return <LoadingElement />;

    return alarmsNotificationRouteElement;
  };

  return getAlarmsElement();
};
