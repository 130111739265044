import { useEffect, useState, useMemo } from 'react';
import {
  Box,
  Button,
  useTheme,
  IconButton,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  DialogTitle,
} from '@mui/material';
import { CloseIcon } from 'src/assets';
import { ControllerModalContentItem } from '../ControllerModalContentItem';
import { FormProvider, RHFAutocomplete } from 'src/components/minimals/form';
import { useForm } from 'react-hook-form';
import { ControllersService } from 'src/services';
import { filterData, formatNumberFromTheDevice } from '../../utils';
import { getDataForUpdateInhand, modePossibleValues } from './utils';
import { ConfirmationModalContent } from './components/confirmationModalContent';
import { FormDataType, PossibleValuesType } from './types';
import { ControllerAdditionalInfo } from 'src/types';
import { modalHeaderRight, iconBox, modalHeading, modalHeader, textFieldMode } from './style';
import { useAuthentication } from 'src/features/authentication/context';
import { UpdatedValueState } from './components';
import { LatestControlsForInhandTypes } from '../../hooks/types';
import { useResponsive } from 'src/hooks';
import { filterOptions } from 'src/utilities';
import { NotificationMessage } from './components/notificationMessage';
import { NOTIFICATION_MESSAGE } from './config';
import { LocalStorageService } from 'src/features/authentication/utils';

type ControllerModalContentProps = {
  onClose: () => void;
  title: string;
  controllerId: string;
  gatewayId?: string;
  activeMode?: any;
  latestControl: (customerId: string, gatewayId: string, data: any) => void;
  latestControlsForInhand: LatestControlsForInhandTypes[];
  setIsDataLoadingHandler: (value: boolean) => void;
  setErrorOnUpdateControls: (value: boolean) => void;
  controllerAdditionalInfo?: ControllerAdditionalInfo[];
  isErrorLastControls: boolean;
  isErrorOnUpdateControls: boolean;
  isDataLoading: boolean;
  lastUpdateReadingTimestamp: string;
  isAppliedChanges: boolean;
  setIsAppliedChangedHandler: (value: boolean) => void;
  confirmDialogState: boolean;
  onConfirmDialogStateHandler: (value: boolean) => void;
  enableApplyButtonHandler: () => void;
  disableApplyButtonHandler: () => void;
  isBtnApplyDisabled: boolean;
  onControllerModalContentStateHandler: (value: boolean) => void;
  displayMode?: string;
  onSetTagIdsForUpdate: (tagIds: string[]) => void;
  isCloseBtn: boolean;
};

export const ControllerModalContent = ({
  onClose,
  title,
  gatewayId,
  activeMode,
  setIsDataLoadingHandler,
  setErrorOnUpdateControls,
  controllerAdditionalInfo,
  latestControlsForInhand,
  isDataLoading,
  isErrorOnUpdateControls,
  lastUpdateReadingTimestamp,
  isAppliedChanges,
  setIsAppliedChangedHandler,
  confirmDialogState,
  onConfirmDialogStateHandler,
  enableApplyButtonHandler,
  disableApplyButtonHandler,
  isBtnApplyDisabled,
  onControllerModalContentStateHandler,
  displayMode,
  onSetTagIdsForUpdate,
  isCloseBtn,
}: ControllerModalContentProps) => {
  const theme = useTheme();
  const greyColor = theme.palette.grey[700];

  const { possibleValues } = useMemo(
    () =>
      modePossibleValues({
        data: filterData(controllerAdditionalInfo, 'mode'),
      }),
    [controllerAdditionalInfo]
  );

  const {
    customerId: { value: customerId },
    siteId: { value: siteId },
  } = useAuthentication();

  const isControllableTag = controllerAdditionalInfo ? controllerAdditionalInfo.length > 0 : false;

  const [formValues, setFormValues] = useState<FormDataType>({
    mode: displayMode ?? '',
  });

  useEffect(() => {
    const newFormValues: FormDataType = {};

    newFormValues.mode = activeMode ?? '';
    setValue('mode', displayMode ?? '');
    setFormValues(newFormValues);

    controllerAdditionalInfo?.forEach((item: ControllerAdditionalInfo) => {
      const key = `${item.dataType}-${item.id}`;
      if (item.dataType !== 'mode') {
        if (item.value !== null && item.value !== undefined) {
          let newValue;
          const findValueIncludedInsideOfUserDefinedValues =
            item?.advancedTagMapping?.userDefinedValues?.find(
              (userItem) => userItem.value === formatNumberFromTheDevice(item?.value)
            );
          if (findValueIncludedInsideOfUserDefinedValues) {
            const { value, name } = findValueIncludedInsideOfUserDefinedValues;
            const sufixName = name && `-${name}`;
            const newValueWithName = `${value}${sufixName}`;
            newValue = newValueWithName;
          } else {
            newValue = item.value.toString();
          }

          newFormValues[key] = newValue;
          setValue(key, newValue);
        }
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [controllerAdditionalInfo, displayMode, activeMode]);

  const onCloseHandler = () => {
    onClose();
  };

  const methods = useForm<FormDataType>({
    defaultValues: formValues,
  });
  const { setValue, watch, handleSubmit } = methods;

  const values = watch();

  const activeModeValue = activeMode?.value.toString() ?? '';

  const data = getDataForUpdateInhand(
    values,
    possibleValues,
    controllerAdditionalInfo,
    gatewayId,
    activeModeValue,
    displayMode
  );
  const tagIds = data?.controls?.map((item) => item?.tagId);

  const isMobile = useResponsive('down', 'md');

  const onChangeModeHandler = (value: string) => {
    setValue('mode', value);
    enableApplyButtonHandler();
  };

  const onSubmitHandler = async () => {
    if (!siteId || !customerId) return;
    disableApplyButtonHandler();
    onSetTagIdsForUpdate(tagIds);

    try {
      setIsDataLoadingHandler(true);
      await ControllersService.updateInhand(data, siteId, customerId);
      setIsAppliedChangedHandler(true);
      setErrorOnUpdateControls(false);
    } catch (err) {
      console.error(err);
      setErrorOnUpdateControls(true);
      setIsDataLoadingHandler(false);
    }

    controllerAdditionalInfo?.forEach((tag) => {
      LocalStorageService.remove(tag.id);
    });
  };

  const selectedMode = possibleValues.find((item) => item.displayName === values.mode);

  //Mode
  const activeModeTagId = activeMode?.tagId;
  const latestControlModeItem = latestControlsForInhand?.find(
    (item) => item?.tagId === activeModeTagId
  );

  const isSuccessFalse = !latestControlModeItem?.success && !isDataLoading;
  const isControlError = isSuccessFalse || isErrorOnUpdateControls;

  const isErrorShown = window.localStorage.getItem(activeModeTagId) === 'error-seen';

  if (latestControlModeItem && latestControlModeItem?.success === false && !isErrorShown) {
    LocalStorageService.set(activeModeTagId, 'error');
  }

  const modeErrorMessage =
    (latestControlModeItem && latestControlModeItem?.success === false && isAppliedChanges) ||
    !isErrorShown
      ? `Failed to change to ${latestControlModeItem?.newValue}`
      : '';
  const modeSuccessMessage =
    isAppliedChanges && !isDataLoading && latestControlModeItem && latestControlModeItem?.success
      ? 'Successfully updated'
      : '';

  return (
    <>
      <Dialog open={confirmDialogState} onClose={() => onConfirmDialogStateHandler(false)}>
        <ConfirmationModalContent
          onConfirmDialogStateHandler={onConfirmDialogStateHandler}
          setIsAppliedChangedHandler={setIsAppliedChangedHandler}
          onControllerModalContentStateHandler={onControllerModalContentStateHandler}
          disableApplyButtonHandler={disableApplyButtonHandler}
        />
      </Dialog>
      <Box sx={modalHeader}>
        <Box sx={modalHeaderRight}>
          <DialogTitle sx={modalHeading}>{title}</DialogTitle>
          <UpdatedValueState
            isDataLoading={isDataLoading}
            lastUpdate={lastUpdateReadingTimestamp}
          />
        </Box>
        <IconButton sx={iconBox} onClick={onCloseHandler}>
          <CloseIcon fill={greyColor} />
        </IconButton>
      </Box>
      <Divider />

      <DialogContent sx={{ padding: '24px' }}>
        {isControllableTag ? (
          <FormProvider methods={methods} onSubmit={handleSubmit(() => onSubmitHandler())}>
            <Box>
              {possibleValues.length > 0 ? (
                <RHFAutocomplete
                  disabled={isDataLoading || isCloseBtn}
                  sx={textFieldMode(isControlError, theme, !!modeSuccessMessage)}
                  onChange={(_, newValue) => onChangeModeHandler(newValue as string)}
                  name="mode"
                  options={[
                    '',
                    ...possibleValues.map((item: PossibleValuesType) => item.displayName),
                  ]}
                  filterOptions={(options: string[], state: any) =>
                    filterOptions(options, state, options)
                  }
                  renderInput={(params) => (
                    <TextField
                      helperText={
                        isErrorOnUpdateControls || (isSuccessFalse && latestControlModeItem)
                          ? modeErrorMessage
                          : modeSuccessMessage
                      }
                      {...params}
                      fullWidth
                      inputProps={{
                        ...params.inputProps,
                        'data-sm': 'mode-input',
                        readOnly: isMobile,
                      }}
                      label="Mode"
                    />
                  )}
                />
              ) : null}

              <Box>
                <ControllerModalContentItem
                  title="States"
                  data={filterData(controllerAdditionalInfo, 'state')}
                  setValue={setValue}
                  selectedMode={selectedMode}
                  latestControlsForInhand={latestControlsForInhand}
                  isErrorOnUpdateControls={isErrorOnUpdateControls}
                  enableApplyButtonHandler={enableApplyButtonHandler}
                  isDataLoading={isDataLoading}
                  isAppliedChanges={isAppliedChanges}
                  setIsAppliedChangedHandler={setIsAppliedChangedHandler}
                  disableApplyButtonHandler={disableApplyButtonHandler}
                  isCloseBtn={isCloseBtn}
                />
                <ControllerModalContentItem
                  title="Setpoints"
                  data={filterData(controllerAdditionalInfo, 'setpoint')}
                  setValue={setValue}
                  selectedMode={selectedMode}
                  latestControlsForInhand={latestControlsForInhand}
                  isErrorOnUpdateControls={isErrorOnUpdateControls}
                  enableApplyButtonHandler={enableApplyButtonHandler}
                  isDataLoading={isDataLoading}
                  isAppliedChanges={isAppliedChanges}
                  setIsAppliedChangedHandler={setIsAppliedChangedHandler}
                  disableApplyButtonHandler={disableApplyButtonHandler}
                  isCloseBtn={isCloseBtn}
                />
                <ControllerModalContentItem
                  title="Outputs"
                  data={filterData(controllerAdditionalInfo, 'output')}
                  setValue={setValue}
                  selectedMode={selectedMode}
                  latestControlsForInhand={latestControlsForInhand}
                  isErrorOnUpdateControls={isErrorOnUpdateControls}
                  enableApplyButtonHandler={enableApplyButtonHandler}
                  isDataLoading={isDataLoading}
                  isAppliedChanges={isAppliedChanges}
                  setIsAppliedChangedHandler={setIsAppliedChangedHandler}
                  disableApplyButtonHandler={disableApplyButtonHandler}
                  isCloseBtn={isCloseBtn}
                />
              </Box>
            </Box>
          </FormProvider>
        ) : (
          <NotificationMessage message={NOTIFICATION_MESSAGE} />
        )}
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button variant="outlined" onClick={onCloseHandler}>
          {!isControllableTag || isCloseBtn ? 'Close' : 'Cancel'}
        </Button>
        {isControllableTag && !isCloseBtn ? (
          <Button
            disabled={isBtnApplyDisabled || tagIds?.length === 0}
            type="submit"
            variant="contained"
            onClick={onSubmitHandler}
          >
            Apply Changes
          </Button>
        ) : null}
      </DialogActions>
    </>
  );
};
