import { useAuthentication } from 'src/features/authentication/context';
import { Protected } from 'src/features/protected-route/components';
import { HeaderOnlyLayout } from 'src/layouts';
import { LOGIN_PATH } from 'src/routes/config';
import { PageNotFoundPage } from 'src/routes/pages';
import { ElementProps } from 'src/routes/types/element';
import { LoadingElement } from '../LoadingElement';

export const PageNotFoundElement = ({ isSuperAdmin }: ElementProps) => {
  const { loggedIn } = useAuthentication();

  const getUniqueRedirectPath = () => {
    if (!loggedIn.value) return LOGIN_PATH;
  };

  const PageNotFoundAddRoute = () => (
    <Protected
      isAuthenticated={loggedIn.value}
      isAuthorized={loggedIn.value}
      uniqueRedirectPath={getUniqueRedirectPath()}
    >
      <HeaderOnlyLayout>
        <PageNotFoundPage />
      </HeaderOnlyLayout>
    </Protected>
  );

  const getPageNotFoundElement = () => {
    if (loggedIn.loaded && !loggedIn.value) return <PageNotFoundAddRoute />;

    if (!loggedIn.loaded) return <LoadingElement />;

    if (isSuperAdmin && loggedIn.loaded) return <PageNotFoundAddRoute />;
    return <PageNotFoundAddRoute />;
  };

  return getPageNotFoundElement();
};
