import {
  createContext,
  Dispatch,
  FunctionComponent,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';

type TemplateCache = Record<string, any>;

const templateCacheContext = createContext<
  [TemplateCache, Dispatch<SetStateAction<TemplateCache>>]
>([{}, () => {}]);

const TemplateCacheContextProvider: FunctionComponent<PropsWithChildren> = ({ children }) => {
  const [cache, setCache] = useState<TemplateCache>({});

  useEffect(
    () => () => {
      setCache({});
    },
    [setCache]
  );
  return (
    <templateCacheContext.Provider value={[cache, setCache]}>
      {children}
    </templateCacheContext.Provider>
  );
};

export const useTemplateCache = () => useContext(templateCacheContext);

export default TemplateCacheContextProvider;
