import { Stack, TableCell, Theme, styled } from '@mui/material';
import { Label, MenuPopover } from 'src/components';

const HeaderStack = styled(Stack)(() => ({
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: '16px',
}));

const DetailsCell = styled(TableCell)(() => ({
  padding: '4px 0',
  verticalAlign: 'top',
  wordWrap: 'break-word',
  wordBreak: 'break-all',
}));

const NotificationsCellLabel = styled(TableCell)(() => ({
  padding: '4px 4px 4px 0px',
}));

const NotificationsCellValue = styled(TableCell)(() => ({
  padding: '4px 0',
  wordWrap: 'break-word',
  wordBreak: 'break-all',
}));

type AlarmConditionsStackType = {
  theme: Theme;
};

const AlarmConditionsStack = styled(Stack)(({ theme }: AlarmConditionsStackType) => ({
  backgroundColor: theme.palette.grey[200],
  margin: '4px 0',
  padding: '10px',
  borderRadius: '10px',
}));

const GatewayLabel = styled(Label)(() => ({
  maxWidth: '100%',
  width: 'fit-content',
  display: 'inline-block!important',
  justifyContent: 'unset!important',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  lineHeight: '24px!important',
}));

const MenuPopoverTooltip = styled(MenuPopover)(() => ({
  marginTop: '10px',
  maxWidth: '256px',
  padding: '0 10px',
  wordWrap: 'break-word',
  wordBreak: 'break-all',
}));

export {
  HeaderStack,
  DetailsCell,
  NotificationsCellLabel,
  NotificationsCellValue,
  AlarmConditionsStack,
  GatewayLabel,
  MenuPopoverTooltip,
};
