import { Box, Button, Tooltip } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { useState } from 'react';
import { ExportIcon } from 'src/assets';
import { useAuthentication } from 'src/features/authentication/context';
import useQueryParamsActions from 'src/hooks/useQueryParamsActions';
import { ReportsService } from 'src/services';
import { ReportType } from '../../types';
import { MenuPopover, MenuPopoverItem } from 'src/components';
import { StyledReportPreviewHeader, StyledTitle, btnsBox } from './style';
import { parseReportTemplatePayload } from '../../utils/parseReportTemplatePayload';
import { useSnackbar } from 'notistack';
import { useHtmlElement } from 'src/hooks';

type Props = {
  title: string;
  instanceId?: string;
  onlyExport?: boolean;
  isHistoryPage?: boolean;
  reportId?: string;
};

export const ReportPreviewHeader = ({
  title,
  onlyExport = false,
  instanceId,
  isHistoryPage,
  reportId,
}: Props) => {
  const [exporting, setExporting] = useState(false);
  const [savedReport, setSavedReport] = useState(false);
  const { get } = useQueryParamsActions();
  const { enqueueSnackbar } = useSnackbar();
  const {
    customerId: { value: customerId },
  } = useAuthentication();
  const { getValues } = useFormContext();
  const methods = useFormContext();
  const reportType = methods.watch('header.reportType');

  const templateId = get('templateId');

  const { removeHtmlElement, addHtmlElement, htmlElement } = useHtmlElement();

  const onSaveHandler = async () => {
    if (!templateId || !customerId) return;
    try {
      if (reportType.id === ReportType.DATA)
        await ReportsService.saveDataReport(customerId, { dataReportTemplateId: templateId });
      if (reportType.id === ReportType.ASSET && instanceId)
        await ReportsService.saveAssetReport(customerId, {
          templateId: templateId,
          reportId: instanceId,
        });
      setSavedReport(true);
      enqueueSnackbar('Report successfully saved in History!', {
        variant: 'success',
      });
    } catch (error) {
      enqueueSnackbar('Error occured when trying to save report!', {
        variant: 'error',
      });
      console.error(error);
    }
  };

  const onExportHandler = async (type: 'csv' | 'excel') => {
    if (!customerId) return;
    setExporting(true);
    const data = parseReportTemplatePayload(getValues().header.reportType.id, {
      ...getValues(),
      title,
    });

    try {
      if (reportType.id === ReportType.DATA)
        await ReportsService.exportDataReport({
          customerId: customerId,
          format: type,
          ...(isHistoryPage ? { reportId: reportId } : { data: data }),
        });

      if (reportType.id === ReportType.ASSET)
        await ReportsService.exportAssetReport({
          customerId: customerId,
          format: type,
          ...(isHistoryPage ? { templateId: templateId } : { data: data }),
        });
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Could not export document', { variant: 'error' });
    } finally {
      setExporting(false);
    }
  };

  return (
    <StyledReportPreviewHeader>
      <StyledTitle>{title}</StyledTitle>
      <Box sx={btnsBox}>
        <Button
          size="small"
          variant="outlined"
          disabled={exporting}
          onClick={(e) => addHtmlElement(e.currentTarget)}
          startIcon={<ExportIcon width={18} height={18} />}
        >
          {exporting ? 'Exporting...' : 'Export'}
        </Button>
        <MenuPopover
          open={htmlElement}
          onClose={removeHtmlElement}
          arrow="top-left"
          disabledArrow
          width="230px"
        >
          <MenuPopoverItem
            customContent={'Microsoft Excel (.xlsx)'}
            handleClick={() => onExportHandler('excel')}
            testId="export-report-excel"
          />
          <MenuPopoverItem
            customContent={'Comma Separated Values (.csv)'}
            handleClick={() => onExportHandler('csv')}
            testId="export-report-csv"
          />
        </MenuPopover>
        {!onlyExport && (
          <>
            {!templateId ? (
              <Tooltip
                placement="top-start"
                title="Template must be saved to be able to save the report."
              >
                <Box>
                  <Button
                    disabled
                    size="small"
                    variant="contained"
                    data-sm="save-report-btn"
                    onClick={onSaveHandler}
                  >
                    Save Report
                  </Button>
                </Box>
              </Tooltip>
            ) : (
              <Button
                size="small"
                disabled={savedReport}
                variant="contained"
                onClick={onSaveHandler}
              >
                {'Save Report'}
              </Button>
            )}
          </>
        )}
      </Box>
    </StyledReportPreviewHeader>
  );
};
