import { Box } from '@mui/material';
import { FunctionComponent } from 'react';
import { DataEntryField as DataEntryFieldType, ICustomFieldBaseProps } from '../types';
import { RHFTextField } from 'src/components/minimals/form';
import useFieldSpacing from '../hooks/useFieldSpacing';
import { useFormContext } from 'react-hook-form';
import { getErrorMessage } from '../utils/getErrorMessage';
import { capitalize } from 'lodash';
import RHFTimePicker from 'src/components/minimals/form/RHFTimePicker';

const DataEntryField: FunctionComponent<ICustomFieldBaseProps<DataEntryFieldType>> = ({
  field,
  fieldsLength,
  blockElement,
  disabled,
}) => {
  const fieldClass = useFieldSpacing(fieldsLength, blockElement);
  const isTimePicker = field.isTime || field.defaultUnit === 'h:min:sec';
  const {
    formState: { errors },
  } = useFormContext();

  return (
    <Box sx={fieldClass}>
      {isTimePicker ? (
        <RHFTimePicker
          disabled={disabled}
          label={`${capitalize(field.measurement)} ${capitalize(field.location)} ${
            field.defaultUnit ? '(' + field.defaultUnit + ')' : ''
          }`}
          rules={field.validations}
          name={field.id}
        />
      ) : (
        <RHFTextField
          disabled={disabled}
          customHelperText={getErrorMessage(errors, field.id, field.validations)}
          rules={field.validations}
          label={`${field.measurement} ${field.location} ${
            field.defaultUnit ? '(' + field.defaultUnit + ')' : ''
          }`}
          type="number"
          testId={field.id.toLocaleLowerCase()}
          name={field.id}
        />
      )}
    </Box>
  );
};

export default DataEntryField;
