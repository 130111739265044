import { IconsTypesProps } from './types';
import { CURRENT_COLOR } from 'src/config';

export const SnackWarningIcon = ({ fill }: IconsTypesProps) => {
  const color = fill ?? CURRENT_COLOR;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.5601 16.3002L14.8901 3.58018C14.2598 2.59418 13.1703 1.99756 12.0001 1.99756C10.8299 1.99756 9.74038 2.59418 9.1101 3.58018L1.4401 16.3002C0.888647 17.2194 0.869586 18.3631 1.3901 19.3002C1.99207 20.3553 3.11533 21.0048 4.3301 21.0002H19.6701C20.8766 21.0131 21.9979 20.3799 22.6101 19.3402C23.1462 18.3928 23.1271 17.2294 22.5601 16.3002ZM12.0001 17.0002C11.4478 17.0002 11.0001 16.5525 11.0001 16.0002C11.0001 15.4479 11.4478 15.0002 12.0001 15.0002C12.5524 15.0002 13.0001 15.4479 13.0001 16.0002C13.0001 16.5525 12.5524 17.0002 12.0001 17.0002ZM12.0001 14.0002C12.5524 14.0002 13.0001 13.5525 13.0001 13.0002V9.00018C13.0001 8.4479 12.5524 8.00018 12.0001 8.00018C11.4478 8.00018 11.0001 8.4479 11.0001 9.00018V13.0002C11.0001 13.5525 11.4478 14.0002 12.0001 14.0002Z"
        fill={color}
      />
    </svg>
  );
};
