import { ControllerAdditionalInfo } from 'src/types';

type IsTagEditableTypes = {
  item: ControllerAdditionalInfo;
  selectedMode?: { id?: string; displayName?: string; value?: string };
};

export const isTagEditable = ({ item, selectedMode }: IsTagEditableTypes) => {
  const relevantMode = item?.advancedTagMapping?.relevantMode;
  const relevantModeList = Array.isArray(relevantMode)
    ? relevantMode.map((item: any) => ({
        id: item?.tagId,
        value: item?.value?.toString(),
      }))
    : [];

  const isDoNotRestrictMode = relevantMode === 'DO_NOT_RESTRICT';

  if (isDoNotRestrictMode) return true;

  const isSelectedModeInRelevantMode = relevantModeList?.some(
    (relevantMode) =>
      relevantMode.id === selectedMode?.id && relevantMode.value === selectedMode?.value?.toString()
  );
  if (isSelectedModeInRelevantMode) return true;

  return false;
};
