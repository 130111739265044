import { Theme } from '@mui/system';

const textField = (isErrorMessage: boolean, theme: Theme, isSuccessfull: boolean) => ({
  '&  .MuiFormHelperText-root': {
    color: isErrorMessage
      ? `${theme.palette.error.main} !important`
      : isSuccessfull
      ? `${theme.palette.success.main} !important`
      : `${theme.palette.warning.main} !important`,
  },
});
export { textField };
