import { IconsTypesProps } from './types';
import { CURRENT_COLOR } from 'src/config';

export const InvitationResendIcon = ({ fill }: IconsTypesProps) => {
  const color = fill ?? CURRENT_COLOR;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48">
      <path
        d="M480-462 140-685v465h390v60H140q-24 0-42-18t-18-42v-520q0-24 18-42t42-18h680q24 0 42 18t18 42v310h-60v-255L480-462Zm0-60 340-218H140l340 218ZM774-44l-42-42 73-74H590v-60h215l-74-74 43-42 146 146L774-44ZM140-685v499-244 4-314 55Z"
        fill={color}
      />
    </svg>
  );
};
