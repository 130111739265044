import { Box, Checkbox, TextField } from '@mui/material';
import { FunctionComponent, useEffect } from 'react';
import { ICustomFieldBaseProps, SelectField as SelectFieldType } from '../types';
import { RHFAutocomplete } from 'src/components/minimals/form';
import useFieldSpacing from '../hooks/useFieldSpacing';
import { useFormContext } from 'react-hook-form';

const SelectField: FunctionComponent<ICustomFieldBaseProps<SelectFieldType>> = ({
  field,
  fieldsLength,
  disabled,
}) => {
  const fieldClass = useFieldSpacing(fieldsLength);
  const { watch, setValue } = useFormContext();
  const values = watch(field.id);
  useEffect(() => {
    if ((typeof values === 'string' || typeof values === 'number') && !field.isMulti) {
      setValue(
        field.id,
        field.options.find((el: any) => el.value + '' === values + '')
      );
    }
    if (
      Array.isArray(values) &&
      values.some((el) => typeof el === 'string' || typeof el === 'number')
    ) {
      setValue(
        field.id,
        values.map((fieldEl: any) =>
          typeof fieldEl === 'string' || typeof fieldEl === 'number'
            ? field.options.find((el: any) => el.value + '' === fieldEl + '')
            : fieldEl
        )
      );
    }
  }, [values, field, setValue]);

  return (
    <Box sx={fieldClass}>
      <RHFAutocomplete
        disabled={disabled}
        disableCloseOnSelect={field.isMulti}
        renderOption={(props, option, data) => {
          const { key, ...optionProps } = props;

          return (
            <li key={key} {...optionProps}>
              <Checkbox style={{ marginRight: 8 }} checked={data.selected} />
              {option.label}
            </li>
          );
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label={field.name}
            inputProps={{
              ...params.inputProps,
              'data-sm': 'asset-type-input',
              //readOnly: isMobile,
            }}
          />
        )}
        getOptionKey={(option) => (typeof option === 'string' ? option : option.value)}
        isOptionEqualToValue={(option, value) => option.value === value.value}
        rules={field.validations}
        multiple={field.isMulti}
        options={field.options.sort((a, b) => a.order - b.order) || []}
        name={field.id}
      />
    </Box>
  );
};

export default SelectField;
