import { TableCell, TableRow } from '@mui/material';
import { tableCellStyle } from './style';

type Props = {
  title: string;
  cols: any;
};

export const TableFooterRow = ({ title, cols }: Props) => (
  <TableRow data-sm="table-footer-row">
    <TableCell sx={tableCellStyle}>
      <TableRow sx={{ textTransform: 'capitalize' }}>{title || ''}</TableRow>
    </TableCell>
    {cols.map((col: any) => (
      <TableCell key={col} sx={tableCellStyle}>
        <TableRow>{col}</TableRow>
      </TableCell>
    ))}
  </TableRow>
);
