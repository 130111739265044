import {
  Box,
  FormControl,
  InputLabel,
  ListItemIcon,
  MenuItem,
  Select,
  SelectChangeEvent,
  useTheme,
} from '@mui/material';
import { ExpandMoreIcon } from 'src/assets/icons/ExpandMoreIcon';
import { CheckIcon } from 'src/assets/icons/CheckIcon';
import { formStyle } from './style';

type Option = {
  value: string;
  label: string;
};

type Props = {
  sortListDropdownValue?: string;
  onSortListDropdownChange?: (newType: string) => void;
};

export const SortListDropdown = ({ sortListDropdownValue, onSortListDropdownChange }: Props) => {
  const theme = useTheme();
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    sx: { width: '100%' },
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        backgroundColor: theme.palette.background.default,
      },
    },
  };
  const sortOptions: Option[] = [
    { value: 'A-Z', label: 'A-Z' },
    { value: 'Z-A', label: 'Z-A' },
  ];

  const handleOnChange = (e: SelectChangeEvent<string>) => {
    onSortListDropdownChange?.(e.target.value);
  };

  return (
    <Box sx={{ paddingLeft: '50px' }}>
      <FormControl sx={formStyle} size="small">
        <InputLabel id="sort-site-list-dropdown">Sort Sites</InputLabel>
        <Select
          sx={{ '& .remove-icon': { display: 'none ' } }}
          SelectDisplayProps={{
            style: { display: 'flex', alignItems: 'center' },
          }}
          IconComponent={(props) => (
            <i {...props} className={`material-icons ${props.className}`}>
              <ExpandMoreIcon />
            </i>
          )}
          value={sortListDropdownValue}
          label="sort-sites"
          onChange={(e) => handleOnChange(e)}
          MenuProps={MenuProps}
        >
          {sortOptions.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
              {sortListDropdownValue === option.value && (
                <ListItemIcon className="remove-icon">
                  <CheckIcon fill={theme.palette.success.main} />
                </ListItemIcon>
              )}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};
