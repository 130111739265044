import { DialogContent, styled, Theme } from '@mui/material';
import { scrollbarOverride } from 'src/styles';

const ModalContent = styled(DialogContent)(scrollbarOverride, {
  padding: '2rem ',
  maxWidth: '100%',
  minWidth: '520px',
  margin: '0 auto',
  '@media (max-width:600px)': {
    minWidth: '90%',
    maxWidth: '100%',
  },
});

const textFieldMode = (isErrorMessage: boolean, theme: Theme, isSuccessfull: boolean) => ({
  '&  .MuiFormHelperText-root': {
    color: isErrorMessage
      ? `${theme.palette.error.main} !important`
      : isSuccessfull
      ? `${theme.palette.success.main} !important`
      : `${theme.palette.warning.main} !important`,
  },
});

const modalHeader = {
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'row',
  alignItems: 'flex-start',
  padding: '24px',
};

const modalHeaderRight = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  '@media (max-width:600px)': {
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
};

const modalHeading = {
  fontSize: '1.2rem',
  fontWeight: 'bold',
  padding: '0',
};
const iconBox = {
  marginLeft: '2rem',
  cursor: 'pointer',
  '@media (max-width:600px)': {
    marginLeft: '0.5rem',
  },
};

const accordionsList = {
  marginTop: '2rem',
};

const btnsBox = {
  display: 'flex',
  justifyContent: 'flex-end',
  marginTop: '5rem',
};

const cancelBtn = {
  marginRight: '0.8rem',
  maxHeight: '36px',
};

const applyBtn = {
  maxHeight: '36px',
  '@media (max-width:600px)': {
    minWidth: '135px',
  },
};

const textFiled = {
  '.MuiAutocomplete-input': {
    padding: '4px !important',
  },
};

const formProviderContainer = {
  margin: '2rem auto 0  auto',
};

export {
  ModalContent,
  modalHeader,
  modalHeaderRight,
  modalHeading,
  iconBox,
  accordionsList,
  btnsBox,
  cancelBtn,
  applyBtn,
  textFiled,
  textFieldMode,
  formProviderContainer,
};
