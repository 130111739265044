import DashboardIcon from '@mui/icons-material/Dashboard';
import SettingsIcon from '@mui/icons-material/Settings';
import featureFlags from '../../../../feature-flags.json';
import SettingsNavigationItem from './SettingsNavigationItem';
import { NavigationItemType, SettingsNavigationItemType } from './types';
import { InsertDriveFileOutlined } from '@mui/icons-material';
import { AnalyticsIcon } from 'src/assets/icons/AnalyticsIcon';
import { SITE_TREND_PATH } from 'src/routes/config';
import { ACCESS_ROLES } from 'src/config';

const trendsIcon = <AnalyticsIcon />;

export const navigationItems: NavigationItemType[] = [
  {
    path: '/sites-management/dashboard',
    label: 'Dashboard',
    icon: DashboardIcon,
  },
  ...(featureFlags.siteTrends
    ? [{ path: SITE_TREND_PATH, label: 'Trends', icon: trendsIcon }]
    : []),
  {
    path: '/logs',
    label: 'Logs',
    icon: InsertDriveFileOutlined,
  },
  {
    path: '/settings',
    label: SettingsNavigationItem,
    icon: SettingsIcon,
  },
];

export const getSettingsNavigationItems = (role: ACCESS_ROLES) => {
  const initialSettingsNavigationItems: SettingsNavigationItemType[] = [
    {
      path: '/site-info',
      title: 'Site Info',
    },
    {
      path: '/gateways',
      title: 'Gateways',
    },
    {
      path: '/integrations',
      title: 'Integrations',
    },
    ...(featureFlags.controllers === 1
      ? [
          {
            path: '/controllers',
            title: 'Controllers',
          },
        ]
      : []),
    {
      path: '/assets',
      title: 'Assets',
    },
    {
      path: '/alarm-rules',
      title: 'Alarm Rules',
    },
  ];

  if (
    role === ACCESS_ROLES.SUPER_ADMIN ||
    role === ACCESS_ROLES.ACCOUNT_ADMIN ||
    role === ACCESS_ROLES.SITE_MANAGER
  ) {
    initialSettingsNavigationItems.push({
      path: '/assign-form',
      title: 'Assign form',
    });
  }

  return initialSettingsNavigationItems;
};
