import { Box } from '@mui/material';
import { DateTimePickerProps } from '@mui/x-date-pickers';
import { Dayjs } from 'dayjs';
import { FunctionComponent } from 'react';
import RHFDatePicker from 'src/components/minimals/form/RHFDatePicker';

const DateField: FunctionComponent<DateTimePickerProps<Dayjs>> = (props) => (
  <Box sx={{ flex: 1 }}>
    <RHFDatePicker
      {...props}
      rules={{ required: true }}
      // label="Start date / Time"
      name="date_from"
    />
  </Box>
);

export default DateField;
