import { Skeleton, TableCell, TableRow } from '@mui/material';

type TableRowsSkeletonProps = {
  rowsNum: number;
  cellsNum: number;
};

export const TableRowsSkeleton = ({ rowsNum, cellsNum }: TableRowsSkeletonProps) => {
  const renderTableRows = () => {
    const rows: { id: string }[] = [];
    const cells: { id: string }[] = [];
    for (let rowIndex = 0; rowIndex < rowsNum; rowIndex++) {
      rows.push({ id: `row-${rowIndex}` });
    }
    for (let cellIndex = 0; cellIndex < cellsNum; cellIndex++) {
      cells.push({ id: `cell-${cellIndex}` });
    }

    return (
      <>
        {rows.map((row) => (
          <TableRow key={row.id}>
            {cells.map((cell) => (
              <TableCell key={cell.id}>
                <Skeleton animation="wave" variant="text" height={'40px'} />
              </TableCell>
            ))}
          </TableRow>
        ))}
      </>
    );
  };

  return renderTableRows();
};
