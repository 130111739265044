import { Box, DialogContent, Theme, styled } from '@mui/material';
import { scrollbarOverride } from 'src/styles';

type ModalWrapperType = {
  theme: Theme;
};

const formProviderWrapper = {
  minWidth: '550px',
  display: 'flex',
  flexDirection: 'column',
  '@media (max-width:600px)': {
    minWidth: '300px',
  },
};

const formProvider = {
  display: 'flex',
  flexDirection: 'column',
};

const StyledModalWrapper = styled(Box)(({ theme }: ModalWrapperType) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  flex: ' 1 1 auto',
  backgroundColor: theme.palette.background.default,
}));

const modalHeader = {
  flex: 'none',
  padding: '24px',
};

const selectDataBox = {
  display: 'flex',
  flexDirection: 'column',
  padding: '8px 22px 16px 24px',
  marginBottom: '10px',
};

const selectDataTitle = {
  marginBottom: '10px',
};

const modalContentItemsList = {
  padding: '0 26px',
};

const buttonsBox = { display: 'flex', justifyContent: 'flex-end' };

const cancelBtn = { marginRight: '10px' };

const DialogContentWithCustomScroll = styled(DialogContent)(scrollbarOverride, { padding: 0 });

export {
  StyledModalWrapper,
  formProvider,
  modalHeader,
  selectDataBox,
  selectDataTitle,
  modalContentItemsList,
  formProviderWrapper,
  buttonsBox,
  cancelBtn,
  DialogContentWithCustomScroll,
};
