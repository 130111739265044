import {
  Box,
  IconButton,
  Popover,
  PopoverProps,
  Stack,
  SvgIcon,
  Typography,
  useTheme,
} from '@mui/material';
import { FunctionComponent } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { VersionHistoryItem } from '../types';
import HistoryPopoverItem from './HistoryPopoverItem';

interface IHistoryPopoverProps {
  element?: HTMLElement | null;
  previousVersions: VersionHistoryItem[];
  onClose: () => void;
  onExpand: (element: VersionHistoryItem) => void;
}

const HistoryPopover: FunctionComponent<IHistoryPopoverProps & PopoverProps> = ({
  element,
  previousVersions,
  onClose,
  onExpand,
  ...props
}) => {
  const theme = useTheme();
  return (
    <Popover
      {...props}
      onClose={onClose}
      id="history-popover"
      open={!!element}
      anchorEl={element}
      PaperProps={{
        sx: { backgroundColor: theme.palette.background.default },
      }}
      anchorPosition={{
        top: 200,
        left: 0,
      }}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      <Stack
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ padding: '12px 16px' }}
      >
        <Typography fontSize="16px" fontWeight="600">
          Version History
        </Typography>
        <IconButton onClick={onClose}>
          <SvgIcon component={CloseIcon} />
        </IconButton>
      </Stack>
      <Box sx={{ padding: '8px 16px 16px 16px' }}>
        {previousVersions.map((prevVersion, index) => (
          <HistoryPopoverItem onExpand={onExpand} key={index} version={prevVersion} />
        ))}
      </Box>
    </Popover>
  );
};

export default HistoryPopover;
