import { useState, useCallback } from 'react';

export const useToggle = () => {
  const [state, setState] = useState(false);

  const onToggle = useCallback(() => {
    setState((prevState) => !prevState);
  }, []);

  return { state, onToggle };
};
