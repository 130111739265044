import { Box, Typography, Button } from '@mui/material';
import { StyledBox, textBox, btnContainer, noBtn, yesBtn } from './style';

type Props = {
  onConfirmDialogStateHandler: (value: boolean) => void;
  setIsAppliedChangedHandler: (value: boolean) => void;
  onControllerModalContentStateHandler: (value: boolean) => void;
  disableApplyButtonHandler: () => void;
};

export const ConfirmationModalContent = ({
  onConfirmDialogStateHandler,
  setIsAppliedChangedHandler,
  onControllerModalContentStateHandler,
  disableApplyButtonHandler,
}: Props) => {
  const onCancelHandler = () => {
    onConfirmDialogStateHandler(false);
  };

  const onConfirmHandler = () => {
    disableApplyButtonHandler();
    setIsAppliedChangedHandler(false);
    onConfirmDialogStateHandler(false);
    onControllerModalContentStateHandler(false);
  };

  return (
    <StyledBox>
      <Box sx={textBox}>
        <Typography>
          Are you sure you want to exit the widget without applying the changes? Your changes will
          not be applied.
        </Typography>
      </Box>
      <Box sx={btnContainer}>
        <Button sx={noBtn} variant="outlined" onClick={onCancelHandler}>
          No
        </Button>
        <Button sx={yesBtn} variant="contained" onClick={onConfirmHandler}>
          Yes
        </Button>
      </Box>
    </StyledBox>
  );
};
