import { Checkbox, Stack, Typography, useTheme } from '@mui/material';
import React, { FunctionComponent } from 'react';
import { COLUMN_STATISTICS } from 'src/features/reports/config/config';

type ColumnAggregationProps = Record<string, boolean>;

const ColumnAggregations: FunctionComponent<{
  value: ColumnAggregationProps;
  onChange: (colAgr: ColumnAggregationProps) => void;
}> = ({ value, onChange }) => {
  const theme = useTheme();
  return (
    <Stack>
      <Typography
        variant="body1"
        sx={{
          paddingTop: '24px',
          paddingBottom: '12px',
          fontWeight: '700',
          color: theme.palette.grey[500],
        }}
      >
        Column Statistics
      </Typography>
      <Stack flexWrap="wrap" flexDirection="row">
        {COLUMN_STATISTICS.map((columnStatistic) => (
          <Stack
            alignItems="center"
            flexBasis="50%"
            key={columnStatistic.value}
            flexDirection="row"
          >
            <Checkbox
              onChange={(_, checked) => onChange({ ...value, [columnStatistic.value]: checked })}
              checked={!!value[columnStatistic.value]}
            />
            <Typography variant="body1">{columnStatistic.label}</Typography>
          </Stack>
        ))}
      </Stack>
    </Stack>
  );
};

export default ColumnAggregations;
