import { TagResponse } from 'src/features/alarm-rules/types';
import { GetDataSourceInputsResponse } from 'src/services/sites';

export const getTitleForTrendWidgets = (
  gatewayInput?: TagResponse,
  dataSourceInput?: GetDataSourceInputsResponse
) => {
  const gatewayName = gatewayInput?.displayName;
  const dataSourceName = dataSourceInput?.displayName;

  if (gatewayName) return gatewayName;
  if (dataSourceName) return dataSourceName;

  return '';
};
