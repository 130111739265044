import { useAuthentication } from 'src/features/authentication/context';
import { Protected } from 'src/features/protected-route/components';
import { LoginLayout } from 'src/layouts';
import { ForgotPasswordPage } from '../../pages';
import { TENANT_SWITCH_PATH } from '../../config';
import { ElementProps } from '../../types/element';
import { LoadingElement } from '../LoadingElement';

export const ForgotPasswordElement = ({ isSuperAdmin }: ElementProps) => {
  const { getUser, loggedIn } = useAuthentication();

  const getUniqueRedirectPath = () => {
    if (getUser()) return TENANT_SWITCH_PATH;
  };

  const ForgotPasswordRouteElement = () => (
    <Protected
      isAuthenticated={!getUser()}
      isAuthorized={true}
      uniqueRedirectPath={getUniqueRedirectPath()}
    >
      <LoginLayout>
        <ForgotPasswordPage />
      </LoginLayout>
    </Protected>
  );

  const getForgotPasswordElement = () => {
    if (!loggedIn.loaded) {
      return <LoadingElement />;
    }

    return <ForgotPasswordRouteElement />;
  };

  return getForgotPasswordElement();
};
