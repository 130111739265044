import { IconsTypesProps } from './types';
import { CURRENT_COLOR } from 'src/config';

export const GearIcon = ({ fill, width = 24, height = 24 }: IconsTypesProps) => {
  const color = fill ?? CURRENT_COLOR;

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.5124 12C19.5124 11.77 19.5024 11.55 19.4824 11.32L21.3424 9.91C21.7424 9.61 21.8524 9.05 21.6024 8.61L19.7324 5.38C19.4824 4.94 18.9424 4.76 18.4824 4.96L16.3324 5.87C15.9624 5.61 15.5724 5.38 15.1624 5.19L14.8724 2.88C14.8124 2.38 14.3824 2 13.8824 2H10.1524C9.64236 2 9.21236 2.38 9.15236 2.88L8.86236 5.19C8.45236 5.38 8.06236 5.61 7.69236 5.87L5.54236 4.96C5.08236 4.76 4.54236 4.94 4.29236 5.38L2.42236 8.62C2.17236 9.06 2.28236 9.61 2.68236 9.92L4.54236 11.33C4.52236 11.55 4.51236 11.77 4.51236 12C4.51236 12.23 4.52236 12.45 4.54236 12.68L2.68236 14.09C2.28236 14.39 2.17236 14.95 2.42236 15.39L4.29236 18.62C4.54236 19.06 5.08236 19.24 5.54236 19.04L7.69236 18.13C8.06236 18.39 8.45236 18.62 8.86236 18.81L9.15236 21.12C9.21236 21.62 9.64236 22 10.1424 22H13.8724C14.3724 22 14.8024 21.62 14.8624 21.12L15.1524 18.81C15.5624 18.62 15.9524 18.39 16.3224 18.13L18.4724 19.04C18.9324 19.24 19.4724 19.06 19.7224 18.62L21.5924 15.39C21.8424 14.95 21.7324 14.4 21.3324 14.09L19.4724 12.68C19.5024 12.45 19.5124 12.23 19.5124 12ZM12.0524 15.5C10.1224 15.5 8.55236 13.93 8.55236 12C8.55236 10.07 10.1224 8.5 12.0524 8.5C13.9824 8.5 15.5524 10.07 15.5524 12C15.5524 13.93 13.9824 15.5 12.0524 15.5Z"
        fill={color}
      />
    </svg>
  );
};
