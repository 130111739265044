import { FunctionComponent, useCallback, useEffect, useRef, useState } from 'react';
import { FormFooter, FormWrapperBackdrop } from '../styles';
import FormWrapper from './FormWrapper';
import FormRenderer from 'src/features/formRenderer/components/FormRenderer';
import FormActions from './FormActions';
import { Stack } from '@mui/material';
import FormHeader from './FormHeader';
import FormHistory from './FormHistory';
import { FormSchema } from 'src/features/formRenderer/types';
import { ApiState, VersionHistoryItem } from '../types';
import { api } from 'src/api.client';
import { useAuthentication } from 'src/features/authentication/context';
import { CustomWaterLoading } from 'src/components';
import { useSnackbar } from 'notistack';
import formatLogToFormData from 'src/features/formRenderer/utils/formatLogToFormData';
import DeleteLogConfirmDialog from './DeleteLogConfirmDialog';
import { LogsService } from 'src/services/logs';
import dayjs from 'dayjs';
import _ from 'lodash';
import { ACCESS_ROLES } from 'src/config';

interface ILogFormProps {
  id?: string;
  logId?: string | null;
  onDelete: (logId: string) => void;
  isCreatingLog: boolean;
  isDeletingLog: boolean;
  onClose: () => void;
  isDefault?: boolean;
  version?: string;
  onChangeVersionHistory: (
    version: VersionHistoryItem,
    lastItem?: VersionHistoryItem | null
  ) => void;
  onSubmit: (data: any, logId: string, allowComments: boolean) => void;
}

const LogForm: FunctionComponent<ILogFormProps> = ({
  onClose,
  id,
  onChangeVersionHistory,
  onDelete,
  isDefault,
  version,
  isDeletingLog,
  logId,
  onSubmit,
  isCreatingLog,
}) => {
  const currentFormId = useRef<string | null>(null);

  const [log, setLog] = useState<ApiState<any>>({
    data: null,
    loading: !!logId,
  });

  const [historyLoading, setHistoryLoading] = useState(false);
  const [historyList, setHistoryList] = useState<VersionHistoryItem[]>([]);
  const [isChanged, setIsChanged] = useState(!logId);
  const [lastHistoryItem, setLastHistoryItem] = useState<VersionHistoryItem | null>();
  const [openDeleteConfirmationModal, setOpenDeleteConfirmationModal] = useState(false);
  const {
    getCurrentRole,
    customerId: { value: customerId },
    siteId: { value: siteId },
  } = useAuthentication();

  const [formState, setFormState] = useState<ApiState<FormSchema | null>>({
    data: null,
    loading: true,
  });

  const { enqueueSnackbar } = useSnackbar();

  const formatTitle = () => {
    if (!selectedForm) {
      return 'Loading...';
    }
    if (!log.data) {
      return `Enter ${selectedForm.title || selectedForm.name}`;
    }
    if (log.data.status !== 'inactive') {
      if (!version) {
        return `Edit: ${selectedForm.title || selectedForm.name}`;
      }
      if (log.data.changedBy && log.data.changedAt) {
        return `History: ${selectedForm.title || selectedForm.name}| ${dayjs(
          log.data.updatedAt
        ).format('M/D/YYYY, h:mm A')}| ${log.data.changedBy}`;
      }
    }
    return selectedForm.title || selectedForm.name;
  };

  const onFormChange = useCallback(
    (data: any) => {
      const formData = _.cloneDeep(data);
      formData.assets = formData.assets.map((asset: any) => asset?.value).filter(Boolean);

      delete formData.comments;
      delete formData.id;
      setIsChanged(!_.isEqual(formData, formatLogToFormData(log.data, true)));
    },
    [setIsChanged, log.data]
  );

  useEffect(() => {
    (async () => {
      if (id && !logId && currentFormId.current !== id) {
        currentFormId.current = id;
        try {
          const response = await api.get('/sites/forms/' + id + '?customerId=' + customerId);

          setFormState({ loading: false, data: response.data });
        } catch (err) {
          onClose();
          enqueueSnackbar('Could not load form', { variant: 'error' });
          setFormState({ loading: false, data: null });
        }
      }
    })();
  }, [customerId, id, logId, isDefault, enqueueSnackbar, onClose]);

  useEffect(() => {
    (async () => {
      try {
        if (logId) {
          setLog((prevState) => ({ ...prevState, loading: true }));
          const log = await LogsService.getLog(customerId!, siteId!, logId, version);
          setFormState({ data: log, loading: false });
          setLog({ data: log, loading: false });
          setHistoryLoading(true);
          const history = await LogsService.getHistory(customerId!, siteId!, logId);
          if (Array.isArray(history)) {
            if (version) {
              setHistoryList(
                history.filter((historyItem: any) => historyItem.version + '' !== version)
              );
            } else {
              history.sort((a, b) => a.version - b.version);
              setLastHistoryItem(history[history.length - 1]);
              setHistoryList(history.slice(0, -1));
            }
          }

          setHistoryLoading(false);
        }
      } catch (err) {
        enqueueSnackbar('Could not load log', { variant: 'error' });
        setFormState({ loading: false, data: null });
        setLog({ data: {}, loading: false });
      }
    })();
  }, [
    logId,
    customerId,
    setLastHistoryItem,
    enqueueSnackbar,
    siteId,
    setFormState,
    setHistoryLoading,
    version,
  ]);

  const selectedForm = formState.data || log.data;

  const canDeleteLog = () => {
    const currentRole = getCurrentRole(customerId, siteId);
    const isSuperAdmin = currentRole === ACCESS_ROLES.SUPER_ADMIN;
    const isAccountAdmin = currentRole === ACCESS_ROLES.ACCOUNT_ADMIN;
    const isSiteManager = currentRole === ACCESS_ROLES.SITE_MANAGER;

    return isSuperAdmin || isAccountAdmin || isSiteManager;
  };

  return (
    <>
      <FormWrapperBackdrop disabledStyle={isCreatingLog} onClick={onClose} />
      {log.data && (
        <DeleteLogConfirmDialog
          logTitle={log.data.title}
          onClose={() => {
            setOpenDeleteConfirmationModal(false);
          }}
          onConfirm={() => {
            setOpenDeleteConfirmationModal(false);

            onDelete(log.data.id);
          }}
          open={openDeleteConfirmationModal}
        />
      )}
      <FormWrapper>
        {formState.loading || !selectedForm || log.loading ? (
          <Stack
            sx={{ width: '100%', height: ' 100%' }}
            alignItems="center"
            justifyContent="center"
          >
            <CustomWaterLoading />
          </Stack>
        ) : (
          <>
            <FormHeader title={formatTitle()} />
            <FormRenderer
              onChange={log.data ? onFormChange : undefined}
              preview={!!version || log?.data?.status === 'inactive'}
              formData={log.data ? { ...formatLogToFormData(log.data), id: logId } : null}
              formActionsComponent={
                <FormFooter>
                  <FormActions
                    isHistory={!!version || log?.data?.status === 'inactive'}
                    isSaveLoading={isCreatingLog}
                    isDeleteing={isDeletingLog}
                    isSaveDisabled={!isChanged}
                    showDelete={!!canDeleteLog()}
                    disable={isCreatingLog || isDeletingLog}
                    isEdit={!!logId}
                    onCancel={onClose}
                    onDelete={() => {
                      setOpenDeleteConfirmationModal(true);
                    }}
                  />
                </FormFooter>
              }
              onSubmit={(data: any) =>
                onSubmit(
                  data,
                  log?.data?.formId || id,
                  formState.data?.allowComments || log.data?.allowComments
                )
              }
              schema={selectedForm as FormSchema}
            >
              {!historyLoading ? (
                historyList.length && lastHistoryItem ? (
                  <FormHistory
                    onChangeVersionHistory={(version) =>
                      onChangeVersionHistory(version, lastHistoryItem)
                    }
                    versions={historyList}
                    lastVersion={lastHistoryItem}
                  />
                ) : (
                  <></>
                )
              ) : (
                <></>
              )}
            </FormRenderer>
          </>
        )}
      </FormWrapper>
    </>
  );
};

export default LogForm;
