import { styled } from '@mui/material/styles';
import { TextField } from '@mui/material';

type StylesTypes = {
  isActive: boolean;
};

const StyledHeaderSiteContent = styled(TextField, {
  shouldForwardProp: (prop) => prop !== 'isActive',
})(({ isActive }: StylesTypes) => ({
  flex: isActive ? 1 : 0,
  position: isActive ? 'absolute' : 'relative',
  top: '0',
  left: isActive ? 0 : 'auto',
  width: '100%',
  background: 'rgba(255,255,255,0.9)',
  display: 'inline-flex',
  zIndex: isActive ? 1 : 'inherit',
  '& > div': {
    height: '30px',
    marginTop: isActive ? '5px' : 0,
  },
}));

export { StyledHeaderSiteContent };
