import React, { useState, useContext, useCallback } from 'react';
import { SEPARATOR } from 'src/features/trends/config';
import { LineChartLocalTrend } from 'src/types/site-local-trends';
import {
  SetNewChartsTypes,
  SiteLocalTrendsContextTypes,
  SiteLocalTrendsProviderPropsTypes,
} from './types';

const SiteLocalTrendsContext = React.createContext<SiteLocalTrendsContextTypes>(
  {} as SiteLocalTrendsContextTypes
);

export const useSiteLocalTrendsContext = () => useContext(SiteLocalTrendsContext);

const locaLineTrendChartsKey = 'localLineChart';
const initialState = JSON.parse(localStorage.getItem(locaLineTrendChartsKey)!) || [];

export const SiteLocalTrendsProvider = ({ children }: SiteLocalTrendsProviderPropsTypes) => {
  const [lineChartsLocalTrends, setLineChartsLocalTrends] =
    useState<LineChartLocalTrend[]>(initialState);

  const addNewChart = useCallback((chart: LineChartLocalTrend) => {
    setLineChartsLocalTrends((prevState) => {
      const updatedState = [...prevState, chart];
      localStorage.setItem(locaLineTrendChartsKey, JSON.stringify(updatedState));
      return updatedState;
    });
  }, []);

  const updateExitingChart = useCallback((updatedChart: LineChartLocalTrend) => {
    setLineChartsLocalTrends((prevState) => {
      const updatedState = prevState.map((item) =>
        item.id === updatedChart.id ? updatedChart : item
      );
      localStorage.setItem(locaLineTrendChartsKey, JSON.stringify(updatedState));
      return updatedState;
    });
  }, []);

  const getFormValuesForSpecificChart = useCallback(
    (chartId: string) => {
      if (!chartId) return;

      const chart = lineChartsLocalTrends.find((item) => item.id === chartId);
      const selectedInputs = chart?.selectedInputs;

      const formValues = selectedInputs?.reduce((acc, item) => {
        const { rawInputName, unit, gatewayId, inputId, inputName } = item;
        const key = `row${SEPARATOR}${rawInputName}${SEPARATOR}${unit}${SEPARATOR}${gatewayId}${SEPARATOR}${inputId}${SEPARATOR}${inputName}`;

        acc[key] = 'true';
        return acc;
      }, {} as any);

      return formValues;
    },
    [lineChartsLocalTrends]
  );

  const removeChart = useCallback((chartId: string) => {
    setLineChartsLocalTrends((prevState) => {
      const updatedState = prevState.filter((chart) => chart.id !== chartId);
      localStorage.setItem(locaLineTrendChartsKey, JSON.stringify(updatedState));
      return updatedState;
    });
  }, []);

  const setNewCharts = useCallback(
    ({ charts, customerId, siteId }: SetNewChartsTypes) => {
      const filteredCharts = lineChartsLocalTrends.filter(
        (item) => item.customerId !== customerId || item.siteId !== siteId
      );
      const newChartState = [...filteredCharts, ...charts];
      setLineChartsLocalTrends(newChartState);
      localStorage.setItem(locaLineTrendChartsKey, JSON.stringify(newChartState));
    },
    [lineChartsLocalTrends]
  );

  return (
    <SiteLocalTrendsContext.Provider
      value={{
        addNewChart,
        removeChart,
        updateExitingChart,
        lineChartsLocalTrends,
        getFormValuesForSpecificChart,
        setNewCharts,
      }}
    >
      {children}
    </SiteLocalTrendsContext.Provider>
  );
};
