import { ControllersService } from 'src/services';

export const getLatestControlsForInhand = async (
  data: any,
  customerId: string,
  gatewayId: string
) => {
  let responseData;
  try {
    await ControllersService.getLatestControls(data, customerId, gatewayId).then((response) => {
      responseData = response;
    });
  } catch (err) {
    console.error(err);
  }

  return responseData;
};
