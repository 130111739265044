import { CURRENT_COLOR } from 'src/config';
import { IconsTypesProps } from './types';

export const FilterIcon = ({ fill, width = 22, height = 22 }: IconsTypesProps) => {
  const color = fill ?? CURRENT_COLOR;

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.5 5.83333C2.5 6.29167 2.875 6.66667 3.33333 6.66667H16.6667C17.125 6.66667 17.5 6.29167 17.5 5.83333C17.5 5.375 17.125 5 16.6667 5H3.33333C2.875 5 2.5 5.375 2.5 5.83333ZM9.16667 15H10.8333C11.2917 15 11.6667 14.625 11.6667 14.1667C11.6667 13.7083 11.2917 13.3333 10.8333 13.3333H9.16667C8.70833 13.3333 8.33333 13.7083 8.33333 14.1667C8.33333 14.625 8.70833 15 9.16667 15ZM14.1667 10.8333H5.83333C5.375 10.8333 5 10.4583 5 10C5 9.54167 5.375 9.16667 5.83333 9.16667H14.1667C14.625 9.16667 15 9.54167 15 10C15 10.4583 14.625 10.8333 14.1667 10.8333Z"
        fill={color}
      />
    </svg>
  );
};
