import { useCallback } from 'react';

export const usePagination = () => {
  const setPagesUsingEntityState = useCallback(
    (
      setPages: (
        value: React.SetStateAction<
          {
            page: number;
            skipTo: string;
            siteId?: string | undefined;
          }[]
        >
      ) => void,
      entityState: { value: any[] | null; loaded: boolean },
      page: number
    ) => {
      setPages((prevValue) => {
        if (entityState.value?.length && entityState.value.length > 0) {
          const newValue: { page: number; skipTo: string }[] = [];

          prevValue.forEach((value) => {
            if (!newValue.find((newV) => newV.page === value.page)) {
              newValue.push(value);
            }
          });

          if (
            !newValue.find(
              (value: { page: number; skipTo: string; siteId?: string }) =>
                entityState.value &&
                value.skipTo === entityState.value[entityState.value.length - 1].id
            )
          ) {
            newValue.push({
              page: page + 1,
              skipTo: entityState.value[entityState.value.length - 1].id,
            });
          }

          return newValue;
        }
        return prevValue;
      });
    },
    []
  );

  return { setPagesUsingEntityState };
};
