import { IconsTypesProps } from './types';
import { CURRENT_COLOR } from 'src/config';

export const SettingsIconV2 = ({ fill, width = 18, height = 18 }: IconsTypesProps) => {
  const color = fill ?? CURRENT_COLOR;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="none"
    >
      <path
        d="M15.26 8.99984C15.26 8.80817 15.2516 8.62484 15.235 8.43317L16.785 7.25817C17.1183 7.00817 17.21 6.5415 17.0016 6.17484L15.4433 3.48317C15.235 3.1165 14.785 2.9665 14.4016 3.13317L12.61 3.8915C12.3016 3.67484 11.9766 3.48317 11.635 3.32484L11.3933 1.39984C11.3433 0.983171 10.985 0.666504 10.5683 0.666504H7.45998C7.03498 0.666504 6.67664 0.983171 6.62664 1.39984L6.38498 3.32484C6.04331 3.48317 5.71831 3.67484 5.40998 3.8915L3.61831 3.13317C3.23498 2.9665 2.78498 3.1165 2.57664 3.48317L1.01831 6.18317C0.809975 6.54984 0.901642 7.00817 1.23497 7.2665L2.78498 8.4415C2.76831 8.62484 2.75997 8.80817 2.75997 8.99984C2.75997 9.1915 2.76831 9.37484 2.78498 9.5665L1.23497 10.7415C0.901642 10.9915 0.809975 11.4582 1.01831 11.8248L2.57664 14.5165C2.78498 14.8832 3.23498 15.0332 3.61831 14.8665L5.40998 14.1082C5.71831 14.3248 6.04331 14.5165 6.38498 14.6748L6.62664 16.5998C6.67664 17.0165 7.03498 17.3332 7.45164 17.3332H10.56C10.9766 17.3332 11.335 17.0165 11.385 16.5998L11.6266 14.6748C11.9683 14.5165 12.2933 14.3248 12.6016 14.1082L14.3933 14.8665C14.7766 15.0332 15.2266 14.8832 15.435 14.5165L16.9933 11.8248C17.2016 11.4582 17.11 10.9998 16.7766 10.7415L15.2266 9.5665C15.2516 9.37484 15.26 9.1915 15.26 8.99984ZM9.04331 11.9165C7.43498 11.9165 6.12664 10.6082 6.12664 8.99984C6.12664 7.3915 7.43498 6.08317 9.04331 6.08317C10.6516 6.08317 11.96 7.3915 11.96 8.99984C11.96 10.6082 10.6516 11.9165 9.04331 11.9165Z"
        fill={color}
      />
    </svg>
  );
};
