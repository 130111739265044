import { Box, Typography, useTheme } from '@mui/material';
import { applyingChangesBox, StyledApplyingChangesTitle, lastUpdatedTitle } from './style';
import { CustomWaterLoading } from 'src/components';

type UpdateValueStateProps = {
  isDataLoading: boolean;
  lastUpdate: string;
};

export const UpdatedValueState = ({ isDataLoading, lastUpdate }: UpdateValueStateProps) => {
  const theme = useTheme();
  return (
    <Box>
      {isDataLoading ? (
        <Box sx={applyingChangesBox}>
          <StyledApplyingChangesTitle theme={theme}>Applying Changes</StyledApplyingChangesTitle>
          <CustomWaterLoading customColor={theme.palette.warning.main} width={15} height={15} />
        </Box>
      ) : null}
      {lastUpdate ? (
        <Typography sx={lastUpdatedTitle}>Last Updated: {lastUpdate} ago</Typography>
      ) : null}
    </Box>
  );
};
