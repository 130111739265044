import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { CalendarIcon } from 'src/assets';
import { TimeOptionType } from '../../types';
import { SelectedBoxType } from '../timeframe-bar-options/types';
import { useTheme } from '@mui/material';
import { useEffect, useState } from 'react';

type Props = {
  label: string;
  timestamp: any;
  type: 'fromDate' | 'toDate';
  isTablet?: boolean;
  onTemporarySelectTabletTimeOption?: (timeOption: TimeOptionType) => void;
  onChangeTemporaryTimestapHandler?: (date: 'fromDate' | 'toDate', value: any) => void;
  onSelectBoxHandler?: (value: SelectedBoxType) => void;
  onResetZoomHandler?: () => void;
  timestamps: any;
  changeTimeFormatHandler?: (fromDate: Date, toDate: Date) => void;
};

export const DatePickerTimeframe = ({
  label,
  timestamp,
  type,
  isTablet,
  onTemporarySelectTabletTimeOption,
  onChangeTemporaryTimestapHandler,
  onSelectBoxHandler,
  onResetZoomHandler,
  timestamps,
  changeTimeFormatHandler,
}: Props) => {
  const [localTimestamp, setLocalTimestamp] = useState(timestamp);

  useEffect(() => {
    setLocalTimestamp(timestamp);
  }, [timestamps, timestamp]);
  const onChangeHandler = (value: any) => {
    const fromDateFormat = new Date(timestamps.fromDate);
    const toDateFormat = new Date(timestamps.toDate);
    const comparisonDate = new Date(value);

    const fromYearMonthDay = fromDateFormat.toISOString().slice(0, 10);
    const toYearMonthDay = toDateFormat.toISOString().slice(0, 10);
    const comparisonYearMonthDay = comparisonDate.toISOString().slice(0, 10);

    if (
      type === 'toDate' &&
      (fromYearMonthDay >= comparisonYearMonthDay || fromYearMonthDay === comparisonYearMonthDay)
    ) {
      return;
    }
    if (
      type === 'fromDate' &&
      (comparisonYearMonthDay >= toYearMonthDay || comparisonYearMonthDay === toYearMonthDay)
    ) {
      return;
    }

    const fromDate = type === 'fromDate' ? value : timestamps.fromDate;
    const toDate = type === 'toDate' ? value : timestamps.toDate;

    if (changeTimeFormatHandler) changeTimeFormatHandler(fromDate, toDate);

    try {
      const date = new Date(value);
      if (!isNaN(date.getTime())) {
        if (onChangeTemporaryTimestapHandler) onChangeTemporaryTimestapHandler(type, value);
        if (onTemporarySelectTabletTimeOption) onTemporarySelectTabletTimeOption('custom');
        if (onSelectBoxHandler) onSelectBoxHandler('custom');
        if (onResetZoomHandler) onResetZoomHandler();
      } else {
        console.error('Invalid time value:', value);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };
  const theme = useTheme();

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        onChange={(value) => onChangeHandler(value)}
        value={localTimestamp}
        label={label}
        slotProps={{
          textField: { inputProps: { 'data-sm': 'datepicker-input' }, size: 'small' },
          mobilePaper: { sx: { backgroundColor: theme.palette.background.default } },
          desktopPaper: {
            sx: {
              backgroundColor: theme.palette.background.default,
            },
          },
        }}
        disableFuture={true}
        slots={{
          openPickerIcon: CalendarIcon,
        }}
        sx={{
          maxWidth: isTablet ? '100%' : '200px',
          height: '36px',
          marginLeft: '10px',
        }}
      />
    </LocalizationProvider>
  );
};
