import { useState, useCallback, SetStateAction } from 'react';

type UseHtmlElementState = HTMLElement | null;

export const useHtmlElement = () => {
  const [htmlElement, setHtmlElement] = useState<UseHtmlElementState>(null);

  const addHtmlElement = useCallback((htmlElement: SetStateAction<UseHtmlElementState>) => {
    setHtmlElement(htmlElement);
  }, []);

  const removeHtmlElement = useCallback(() => {
    setHtmlElement(null);
  }, []);

  return { htmlElement, addHtmlElement, removeHtmlElement };
};
