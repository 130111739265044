import { ReactElement, createContext, useContext, useState } from 'react';
import { MAP_FILTER_SESSION_STORAGE_KEY, MAP_SEARCH_SESSION_STORAGE_KEY } from 'src/features';
import { SessionStorageService } from 'src/features/authentication/utils';

type SiteMapFilterProviderProps = {
  children: ReactElement;
};
type SiteMapFilterContextType = {
  mapFilters: string;
  updateMapFilters: (newFilters: string) => void;
  mapSearch: string;
  updateMapSearch: (newSearch: string) => void;
};

const SiteMapFilterContext = createContext<SiteMapFilterContextType>(
  {} as SiteMapFilterContextType
);

export const SiteMapFilterProvider: React.FunctionComponent<SiteMapFilterProviderProps> = ({
  children,
}: SiteMapFilterProviderProps) => {
  const initalValue = SessionStorageService.getRaw(MAP_FILTER_SESSION_STORAGE_KEY) ?? '';
  const [mapFilters, setMapFilters] = useState<string>(initalValue);
  const [mapSearch, setMapSearch] = useState<string>('');

  const updateMapFilters = (newFilters: string) => {
    SessionStorageService.set(MAP_FILTER_SESSION_STORAGE_KEY, newFilters);
    setMapFilters(newFilters);
  };

  const updateMapSearch = (newSearch: string) => {
    SessionStorageService.set(MAP_SEARCH_SESSION_STORAGE_KEY, newSearch);
    setMapSearch(newSearch);
  };

  const contextValue: SiteMapFilterContextType = {
    mapFilters,
    updateMapFilters,
    mapSearch,
    updateMapSearch,
  };

  return (
    <SiteMapFilterContext.Provider value={contextValue}>{children}</SiteMapFilterContext.Provider>
  );
};

export const useMapFilters = () => useContext(SiteMapFilterContext);
