import { Box, Checkbox } from '@mui/material';
import { StringKeyObjectType } from 'src/features/sites/types';
import {
  assetNameLabelBox,
  gatewayNameBox,
  modalContentSide,
  StyledAssetNameLabel,
  StyledDisplayName,
  StyledGatewayNameLabel,
  StyledModalContentRow,
  StyledModalContentSide,
} from 'src/features/trends/components/modal-content-row/style';
import { SEPARATOR } from 'src/features/trends/config';
import { useResponsive } from 'src/hooks';
import { TagResponse } from 'src/types';

type Props = {
  tag: TagResponse;
  setValue: (name: string, value: string) => void;
  values: StringKeyObjectType;
};

export const ModalContentItem = ({ tag, setValue, values }: Props) => {
  const isMobile = useResponsive('down', 'sm');

  const key = `row${SEPARATOR}${tag.rawInputName}${SEPARATOR}${tag.rawUnit}${SEPARATOR}${
    tag.gatewayId
  }${SEPARATOR}${tag.id}${SEPARATOR}${tag.displayName?.replace(/\./g, '(dot)')}`;

  return (
    <StyledModalContentRow onClick={() => setValue(key, (!(values[key] === 'true')).toString())}>
      <Box sx={modalContentSide}>
        <Checkbox name={key} checked={values[key] === 'true'} />
        <StyledDisplayName isMobile={isMobile}>{tag.displayName}</StyledDisplayName>
      </Box>
      <StyledModalContentSide isMobile={isMobile}>
        {tag?.gatewayName ? (
          <Box sx={gatewayNameBox}>
            <StyledGatewayNameLabel variant="caption">{tag.gatewayName}</StyledGatewayNameLabel>
          </Box>
        ) : null}
        {tag?.connectedAsset ? (
          <Box sx={assetNameLabelBox}>
            <StyledAssetNameLabel variant="caption">{tag.connectedAsset}</StyledAssetNameLabel>
          </Box>
        ) : null}
      </StyledModalContentSide>
    </StyledModalContentRow>
  );
};
