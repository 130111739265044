export const SpiralDotsChartWidget = () => (
  <svg width="161" height="93" viewBox="0 0 161 93" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2 5.68587H159"
      stroke="#919EAB"
      strokeOpacity="0.24"
      strokeWidth="0.628572"
      strokeLinecap="square"
      strokeDasharray="1.26 1.89"
    />
    <path
      d="M2 46.6058H159"
      stroke="#919EAB"
      strokeOpacity="0.24"
      strokeWidth="0.628572"
      strokeLinecap="square"
      strokeDasharray="1.26 1.89"
    />
    <path
      d="M2 87.5257H159"
      stroke="#919EAB"
      strokeOpacity="0.24"
      strokeWidth="0.628572"
      strokeLinecap="square"
      strokeDasharray="1.26 1.89"
    />
    <path
      d="M2 60.266H7.23333C12.4667 60.266 22.9333 60.266 33.4 54.4341C43.8667 48.6896 54.3333 36.8948 64.8 33.4656C75.2667 29.9053 85.7333 34.7106 96.2 32.3079C106.667 29.9053 117.133 20.7315 127.6 20.666C138.067 20.7315 148.533 29.9053 153.767 34.6451L159 39.2975"
      stroke="#05B6B2"
      strokeWidth="2.51429"
      strokeLinecap="round"
      strokeDasharray="0.63 6.91"
    />
  </svg>
);
