import { Stack, SxProps, Theme } from '@mui/material';
import React, { FunctionComponent, PropsWithChildren } from 'react';
import { DragDropContext, DragDropContextProps, Droppable } from 'react-beautiful-dnd';

interface IDragListProps extends DragDropContextProps {
  id: string;
  isChildDragging?: boolean;
}

const DragList: FunctionComponent<PropsWithChildren<IDragListProps & { sx?: SxProps<Theme> }>> = ({
  id,
  isChildDragging = false,
  children,
  sx,
  ...dragDropContextProps
}) => (
  <DragDropContext {...dragDropContextProps}>
    <Droppable droppableId={id} type="PARENT" isDropDisabled={isChildDragging}>
      {(provided) => (
        <Stack sx={sx} ref={provided.innerRef} {...provided.droppableProps}>
          {children}
          {provided.placeholder}
        </Stack>
      )}
    </Droppable>
  </DragDropContext>
);
export default DragList;
