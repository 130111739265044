import { Box, Stack, styled } from '@mui/material';
import Textarea from 'src/components/minimals/textarea/Textarea';

export const CustomTextArea = styled(Textarea)(() => ({
  resize: 'vertical',
}));

export const StyledSection = styled(Box)(({ theme }) => ({
  borderRadius: '16px',
  border: `1px solid ${theme.palette.grey[300]}`,
  padding: theme.spacing(1),
  marginBlock: theme.spacing(2),
  flexBasis: '100%',
}));

export const BlockStack = styled(Stack)(() => ({
  flexBasis: '100%',
  width: '100%',
}));
