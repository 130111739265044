import { useAuthentication } from 'src/features/authentication/context';
import { Protected } from 'src/features/protected-route/components';
import { AppLayout } from 'src/layouts';
import { CustomerAccountManagementPage } from '../../pages';
import { LOGIN_PATH, ROOT_HEADER, TENANT_SWITCH_PATH } from '../../config';
import { NAV_ITEMS_HEADER_HOME_PAGE } from 'src/features';
import { ElementProps } from '../../types/element';
import { LoadingElement } from '../LoadingElement';
import { useMemo } from 'react';

export const CustomerAccountManagementElement = ({ isSuperAdmin }: ElementProps) => {
  const { loggedIn } = useAuthentication();

  const uniqueRedirectPath = useMemo(() => {
    if (!loggedIn.value) return LOGIN_PATH;
    if (!isSuperAdmin) return TENANT_SWITCH_PATH;
    return undefined;
  }, [loggedIn.value, isSuperAdmin]);

  const CustomerAccountManagementRouteElement = useMemo(
    () => (
      <Protected
        isAuthenticated={loggedIn.value}
        isAuthorized={isSuperAdmin}
        uniqueRedirectPath={uniqueRedirectPath}
      >
        <AppLayout
          headerTitle={ROOT_HEADER}
          headerNavItems={NAV_ITEMS_HEADER_HOME_PAGE}
          isCustomerAccountPage={true}
          isSuperAdmin={isSuperAdmin}
        >
          <CustomerAccountManagementPage />
        </AppLayout>
      </Protected>
    ),
    [loggedIn.value, isSuperAdmin, uniqueRedirectPath]
  );

  const getCustomerAccountManagementElement = () => {
    if (!loggedIn.loaded) {
      return <LoadingElement />;
    }

    return CustomerAccountManagementRouteElement;
  };

  return getCustomerAccountManagementElement();
};
