import { Theme } from '@mui/system';

const textField = (isErrorMessage: boolean, theme: Theme, isSuccessfull: boolean) => ({
  '&  .MuiFormHelperText-root': {
    color: isErrorMessage
      ? theme.palette.error.main
      : isSuccessfull
      ? theme.palette.success.main
      : theme.palette.grey[600],
  },
});
export { textField };
