import { AssignedTag } from 'src/features/sites/types/types';
import { DataStructure } from '../types';

type Props = {
  data: AssignedTag[];
  latestReadings: DataStructure;
};
export const getStateOrModeData = ({ data, latestReadings }: Props) => {
  let displayedValue = '';

  const isItemFound = data?.some((item) => {
    const assignedTagId = item?.assignedTagId;
    const tagIndicatorValue: string = item?.tagIndicatorValue;
    const tagIndicator: string = item?.tagIndicator;
    const tagGatewayId = item?.assignedGatewayId;
    const findGatewayWithTags = latestReadings?.find(
      (gateway) => gateway.gatewayId === tagGatewayId
    );

    const readingsArray =
      findGatewayWithTags?.values && Object.entries(findGatewayWithTags?.values);
    const tagReadingArray = readingsArray?.find((item) => item[0] === assignedTagId);

    const readingValue = tagReadingArray && Object.values(tagReadingArray[1].values)[0];
    const formattedReadingValue: string = String(readingValue);

    if (tagIndicatorValue === formattedReadingValue) {
      displayedValue = tagIndicator;
      return true;
    }
    return false;
  });
  if (!isItemFound) {
    displayedValue = '';
  }

  return displayedValue;
};
