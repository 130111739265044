import { Theme } from '@mui/material/styles';

export default function Tooltip(theme: Theme) {
  return {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          borderRadius: '5px',
          backgroundColor: theme.palette.background.paper,
          //boxShadow: theme.shadows[2],
          color: theme.palette.text.primary,
          maxWidth: 'none',
          padding: theme.spacing(1),
          fontSize: '14px',
        },
        arrow: {
          color: theme.palette.background.paper,
        },
      },
    },
  };
}
