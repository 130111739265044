// Usage: "TWO_WORDS" => "Two Words"
export function upperSnakeCaseToTitleCase(
  upper_snake_case: string,
  keepXWordUppercase?: { x: number }
): string {
  const normalCase = upper_snake_case
    ?.split('_')
    .map((word, index) =>
      keepXWordUppercase && keepXWordUppercase.x === index + 1
        ? word
        : word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
    )
    .join(' ');

  return normalCase;
}
