export const LinearChartWidget = () => (
  <svg width="162" height="80" viewBox="0 0 162 80" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1 74.1186H161"
      stroke="#919EAB"
      strokeOpacity="0.24"
      strokeWidth="0.633664"
      strokeLinecap="square"
      strokeDasharray="1.27 1.9"
    />
    <path
      d="M1 5.68304H161"
      stroke="#919EAB"
      strokeOpacity="0.24"
      strokeWidth="0.633664"
      strokeLinecap="square"
      strokeDasharray="1.27 1.9"
    />
    <path d="M29.198 9.52686H14.7294V26.6141H1" stroke="#05B6B2" strokeWidth="1.90099" />
    <path
      d="M28.2476 9.19884V41.1876H40.9208V8.23712H53.5941V25.0295H67.8515V49.4256H79.5743V9.19884H91.6139V31.683H102.703V49.4256H120.762V70.6533H135.337V31.683H147.376V9.19884H161"
      stroke="#05B6B2"
      strokeWidth="1.90099"
    />
  </svg>
);
