import { Theme, styled } from '@mui/material/styles';
import { Card, Dialog, DialogContent, TableContainer } from '@mui/material';
import { scrollbarOverride } from 'src/styles';

type StylesTypes = {
  theme: Theme;
};

const TableContainerWithCustomScroll = styled(TableContainer)(scrollbarOverride);

const DialogContentWithCustomScroll = styled(DialogContent)(scrollbarOverride);

const AlarmPopupCard = styled(Card)(({ theme }: StylesTypes) => ({
  border: `1px solid ${theme.palette.grey[300]}`,
  margin: '32px 0 32px 0',
  boxShadow: 'none',
  backgroundColor: theme.palette.background.default,
}));

const AlarmPopupDialog = styled(Dialog)(() => ({
  '& .MuiDialog-paperWidthLg': {
    maxWidth: 720,
    width: '720px',
  },
}));

export {
  TableContainerWithCustomScroll,
  DialogContentWithCustomScroll,
  AlarmPopupCard,
  AlarmPopupDialog,
};
