import { Box, Theme, styled } from '@mui/material';

type StyledMainTopTypes = {
  theme: Theme;
  isMobile: boolean;
};

const headerOnlyLayout = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

const StyledMainTop = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isMobile',
})(({ isMobile, theme }: StyledMainTopTypes) => ({
  position: isMobile ? 'sticky' : 'static',
  top: 0,
  zIndex: 9,
  display: 'flex',
  alignItems: 'center',
  background: theme.palette.common.white,
}));

export { headerOnlyLayout, StyledMainTop };
