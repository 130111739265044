import { Box, Stack, SvgIcon, SvgIconProps, SxProps, Theme, useTheme } from '@mui/material';
import { FunctionComponent, PropsWithChildren } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { IDragItemProps } from './types';

const DragItem: FunctionComponent<
  PropsWithChildren<
    IDragItemProps & { dragHandleProps?: Omit<SvgIconProps, 'component'>; sx?: SxProps<Theme> }
  >
> = ({
  id,
  index,
  isDragIndicatorManual = false,
  isDragDisabled = false,
  children,
  dragHandleProps = {},
  sx,
}) => {
  const theme = useTheme();
  return (
    <Draggable draggableId={id} index={index} isDragDisabled={isDragDisabled}>
      {(provided, snapshot) => (
        <Stack
          ref={provided.innerRef}
          sx={{
            backgroundColor: snapshot.isDragging
              ? theme.palette.grey[200]
              : theme.palette.background.default,
            ...sx,
          }}
          {...provided.dragHandleProps}
          {...provided.draggableProps}
          flexDirection="row"
          alignItems="center"
        >
          {isDragIndicatorManual ? null : (
            <SvgIcon component={DragIndicatorIcon} {...dragHandleProps} />
          )}
          <Box sx={{ flex: 1, marginLeft: '12px' }}>{children}</Box>
        </Stack>
      )}
    </Draggable>
  );
};

export default DragItem;
