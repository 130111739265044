import { useEffect, useState } from 'react';
import { TableContainer, Table, TableBody, Container, Stack, TablePagination } from '@mui/material';
import { tableContainer, table } from './style';
import { TableRowItem } from '../table-row-item';
import { NewSite, SiteManagementProps } from 'src/features/sites/types/sites';
import {
  MemoizedScrollbar,
  useTable,
  ContentLoadingError,
  PaginationSkeleton,
} from 'src/components';
import { SiteManagementRowsSkeleton } from '../../custom-skeletons';
import { TagsTypes } from 'src/features/sites/types/types';

export const DesktopSiteTable = ({
  data,
  dataCount,
  pageNum,
  rowsPerPage,
  onChangePage,
  onChangeRowsPerPage,
  onSortByChange,
  onSortByOrderChange,
  search,
  handleDialogOpen,
  tags,
  liveData,
  roleAccessInfoBox,
}: SiteManagementProps) => {
  const { order, orderBy, selected, onSelectRow } = useTable();
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const [filteredDataLength, setFilteredDataLength] = useState<number>(0);

  useEffect(() => {
    onSortByChange?.(orderBy);
    onSortByOrderChange?.(order);
  }, [onSortByChange, orderBy, order, onSortByOrderChange]);

  useEffect(() => {
    if (search !== '') {
      setFilteredDataLength(data.value?.length ?? 0);
    } else {
      setFilteredDataLength(dataCount);
    }
  }, [search, dataCount, data.value]);

  useEffect(() => {
    setIsDisabled(filteredDataLength < rowsPerPage);
  }, [filteredDataLength, rowsPerPage]);

  return (
    <Container maxWidth={false} disableGutters data-sm="site-management-content">
      {data.loaded ? (
        <TablePagination
          nextIconButtonProps={
            isDisabled
              ? {
                  disabled: isDisabled,
                }
              : undefined
          }
          component={'div'}
          sx={{
            borderTop: 0,
            height: '42px',
            overflow: 'hidden',
            '.MuiTablePagination-selectLabel': { mt: 0, mb: 0 },
            '.MuiTablePagination-displayedRows': { mt: 0, mb: 0 },
          }}
          count={filteredDataLength}
          page={pageNum}
          rowsPerPage={rowsPerPage}
          onPageChange={onChangePage}
          onRowsPerPageChange={onChangeRowsPerPage}
        />
      ) : (
        <PaginationSkeleton />
      )}
      <TableContainer sx={tableContainer}>
        <MemoizedScrollbar>
          <Table size="medium" sx={table} data-sm="site-management-table">
            <TableBody data-sm="table-site-management-content">
              {data.loaded ? (
                data.value?.map((row: NewSite) => {
                  const findTagsBySiteId = tags.find((item: TagsTypes) => item.siteId === row.id);
                  return (
                    <TableRowItem
                      key={row.id}
                      row={row}
                      tags={findTagsBySiteId}
                      selected={selected.includes(row.id)}
                      onSelectRow={() => onSelectRow(row.id)}
                      liveData={liveData}
                      handleEditSitePreview={handleDialogOpen}
                      roleAccessInfoBox={roleAccessInfoBox}
                    />
                  );
                })
              ) : (
                <SiteManagementRowsSkeleton rowsNum={8} isMobile={false} />
              )}
            </TableBody>
          </Table>
        </MemoizedScrollbar>
      </TableContainer>
      {!data.value ? (
        <Stack sx={{ width: '100%', alignItems: 'center' }}>
          <ContentLoadingError />
        </Stack>
      ) : null}
      {data.loaded ? (
        <TablePagination
          nextIconButtonProps={
            isDisabled
              ? {
                  disabled: isDisabled,
                }
              : undefined
          }
          component={'div'}
          sx={{
            borderTop: 0,
            height: '42px',
            overflow: 'hidden',
            '.MuiTablePagination-selectLabel': { mt: 0, mb: 0 },
            '.MuiTablePagination-displayedRows': { mt: 0, mb: 0 },
            mb: '32px',
          }}
          count={filteredDataLength}
          page={pageNum}
          rowsPerPage={rowsPerPage}
          onPageChange={onChangePage}
          onRowsPerPageChange={onChangeRowsPerPage}
        />
      ) : null}
    </Container>
  );
};
