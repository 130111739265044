import { Typography } from '@mui/material';
import { FunctionComponent, PropsWithChildren } from 'react';

const SiteTitle: FunctionComponent<PropsWithChildren> = ({ children }) => (
  <Typography
    sx={{
      position: 'absolute',
      left: '0px',
      width: '100%',
      top: '50%',
      transform: 'translateY(-50%)',
      fontSize: '18px',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    }}
    variant="h4"
  >
    {children}
  </Typography>
);

export default SiteTitle;
