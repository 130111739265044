import { IconsTypesProps } from './types';
import { CURRENT_COLOR } from 'src/config';

export const UserGroupIcon = ({ fill }: IconsTypesProps) => {
  const color = fill ?? CURRENT_COLOR;

  return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.24998 10.0833C10.275 10.0833 11.9166 8.44171 11.9166 6.41667C11.9166 4.39162 10.275 2.75 8.24998 2.75C6.22494 2.75 4.58331 4.39162 4.58331 6.41667C4.58331 8.44171 6.22494 10.0833 8.24998 10.0833Z"
        fill={color}
      />
      <path
        d="M15.5833 11.9167C17.1021 11.9167 18.3333 10.6855 18.3333 9.16669C18.3333 7.6479 17.1021 6.41669 15.5833 6.41669C14.0645 6.41669 12.8333 7.6479 12.8333 9.16669C12.8333 10.6855 14.0645 11.9167 15.5833 11.9167Z"
        fill={color}
      />
      <path
        d="M19.25 18.3333C19.7562 18.3333 20.1666 17.9229 20.1666 17.4167C20.1651 15.6689 19.1698 14.0741 17.6003 13.3049C16.0308 12.5358 14.1606 12.7262 12.7783 13.7958C10.9423 11.967 8.18646 11.4221 5.7926 12.4145C3.39874 13.407 1.83682 15.7419 1.83331 18.3333C1.83331 18.8396 2.24372 19.25 2.74998 19.25H13.75C14.2562 19.25 14.6666 18.8396 14.6666 18.3333"
        fill={color}
      />
    </svg>
  );
};
