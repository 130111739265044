import { Drawer, Box } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledDrawer = styled(Drawer)(({ theme }) => ({
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  position: 'absolute',
  width: 320,
  '& .MuiDrawer-paper': {
    width: 320,
    backgroundColor: theme.palette.background.default,
  },
}));

const titleHeaderBox = {
  padding: '24px',
};

const titleBox = {
  padding: '24px 24px 16px 24px',
};

const StledBtnsBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  padding: '31px 24px',
  gap: '20px',
  position: 'sticky',
  bottom: 0,
  left: 0,
  right: 0,
  backgroundColor: theme.palette.background.default,
}));

const threeBox = {
  padding: '0px 15px 8px 15px',
};

const titleStyle = {
  fontWeight: '700',
  fontSize: '16px',
};

const searchFiled = {
  width: '100%',
};

export { titleHeaderBox, titleBox, StledBtnsBox, StyledDrawer, threeBox, titleStyle, searchFiled };
