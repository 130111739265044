import { useAuthentication } from 'src/features/authentication/context';
import { Protected } from 'src/features/protected-route/components';
import { ACCOUNT_ISSUE_PATH, LOGIN_PATH, SITES_MANAGEMENT_HEADER } from '../../config';
import { AppLayout } from 'src/layouts';
import {
  NAV_ITEMS_HEADER_SITES_MANAGEMENT,
  getNavItemsHeaderSiutesManagementSAAndAA,
} from 'src/features';
import { SiteManagementPage } from '../../pages';
import { ElementProps } from '../../types/element';
import { LoadingElement } from '../LoadingElement';
import { AccessRoles } from 'src/features/user-account-details/types';
import { useCustomer } from 'src/contexts/customerContext/customer.context';
import { useCustomerAssets } from 'src/contexts/assetContext/asset.context';
import { useCallback, useMemo } from 'react';

export const SiteManagementElement = ({ isSuperAdmin }: ElementProps) => {
  const { isCustomerAccessAllowed, loggedIn, customerId, getCurrentRole, user } =
    useAuthentication();
  const { loading } = useCustomer(customerId.value!!);
  const { loadingAssets } = useCustomerAssets(customerId.value!!);

  const isAccountAdmin = useMemo(
    () => getCurrentRole(customerId.value, null) === AccessRoles.ACCOUNT_ADMIN,
    [customerId, getCurrentRole]
  );

  const getUniqueRedirectPath = useCallback(() => {
    if (!loggedIn.value) return LOGIN_PATH;
    if (!isSuperAdmin && !isCustomerAccessAllowed.value)
      return isCustomerAccessAllowed.isCustomerActive
        ? ACCOUNT_ISSUE_PATH + '?reason=Access Denied'
        : ACCOUNT_ISSUE_PATH;
  }, [loggedIn, isSuperAdmin, isCustomerAccessAllowed]);

  const SiteManagementRouteElement = useMemo(
    () => (
      <Protected
        isAuthenticated={loggedIn.value}
        isAuthorized={isSuperAdmin || isCustomerAccessAllowed.value}
        uniqueRedirectPath={getUniqueRedirectPath()}
      >
        <AppLayout
          headerTitle={SITES_MANAGEMENT_HEADER}
          headerNavItems={
            isSuperAdmin || isAccountAdmin
              ? getNavItemsHeaderSiutesManagementSAAndAA(user, isSuperAdmin)
              : NAV_ITEMS_HEADER_SITES_MANAGEMENT
          }
        >
          <SiteManagementPage />
        </AppLayout>
      </Protected>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [loggedIn, isSuperAdmin, isCustomerAccessAllowed, user, isAccountAdmin]
  );

  if (isSuperAdmin && loggedIn.loaded) return SiteManagementRouteElement;

  if (!loggedIn.loaded) return <LoadingElement />;

  if (loggedIn.loaded && !loggedIn.value) return SiteManagementRouteElement;

  if (!customerId.value || !customerId.loaded) return <LoadingElement />;

  if (!isCustomerAccessAllowed.loaded) return <LoadingElement />;

  if (loading) return <LoadingElement />;

  if (loadingAssets) return <LoadingElement />;

  return SiteManagementRouteElement;
};
