import { FunctionComponent } from 'react';
import { useResponsive } from 'src/hooks';
import HeaderMobileWrapper from './HeaderMobileWrapper';
import SiteHeader from './SiteHeader';
import { ISiteHeaderProps } from './types';

const SiteHeaderWrapper: FunctionComponent<ISiteHeaderProps> = (props) => {
  const isMobile = useResponsive('down', 'md');
  return isMobile ? (
    <HeaderMobileWrapper>
      <SiteHeader {...props} />
    </HeaderMobileWrapper>
  ) : (
    <SiteHeader {...props} />
  );
};

export default SiteHeaderWrapper;
