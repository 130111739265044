import { Box, Typography, useTheme } from '@mui/material';

type SelectedSiteProps = {
  title: string;
  description: string;
};

export const SelectedSiteNavigationBox = ({ title, description }: SelectedSiteProps) => {
  const theme = useTheme();
  const { fontWeightMedium } = theme.typography;
  return (
    <Box
      sx={{
        padding: '12px 10px 12px 14px',
        backgroundColor: theme.sidebar.selectedSiteBackground,
        mt: '14px',
        borderRadius: '8px',
      }}
    >
      <Typography
        sx={{
          fontWeight: fontWeightMedium,
          color: theme.palette.primary.main,
          lineHeight: '22px',
          fontSize: '14px',
        }}
      >
        {title}
      </Typography>
      <Typography
        sx={{
          color: theme.palette.grey[400],
          lineHeight: '22px',
          fontSize: '14px',
        }}
      >
        {description}
      </Typography>
    </Box>
  );
};
