import { Box, Stack, SvgIcon, Typography, useTheme } from '@mui/material';
import { FunctionComponent } from 'react';
import { imageMimeTypes } from 'src/constants/imageMimeTypes';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import ImageIcon from '@mui/icons-material/Image';
import useQueryParamsActions from 'src/hooks/useQueryParamsActions';
import AttachmentsPreviewBox from './AttachmentsPreviewBox';

interface ILogAtttachmentProps {
  attachments: {
    id: string;
    name: string;
    mime_type: string;
    order: number;
  }[];
  log: string;
  singleView?: boolean;
}

const LogAttachments: FunctionComponent<ILogAtttachmentProps> = ({
  attachments,
  log,
  singleView = false,
}) => {
  const theme = useTheme();
  const { append, get, remove } = useQueryParamsActions();
  const attachment = get('attachment');
  return (
    <Box
      sx={{
        cursor: 'pointer',
        position: 'relative',
        width: singleView ? '60px' : '100px',
        height: singleView ? '60px' : '100px',
      }}
    >
      {attachment && log && get('log') === log ? (
        <AttachmentsPreviewBox
          onClose={(event) => {
            event.stopPropagation();
            remove('attachment');
          }}
          attachment={attachment}
          log={log}
          content={attachments}
        />
      ) : (
        <></>
      )}
      {attachments.slice(0, singleView ? 1 : 3).map((attachment, index) => (
        <Box
          onClick={(event) => {
            event.stopPropagation();
            append('log', log);
            append('attachment', attachment.id);
          }}
          sx={{
            ...(index !== 0
              ? {
                  '&:hover': {
                    transform: 'translateY(-10px)',
                  },
                }
              : {
                  '&:hover': {
                    transform: 'translateY(-2px)',
                  },
                }),
            transition: 'transform 0.3s',
            background: 'white',
            filter:
              'drop-shadow(0.4px 0px 1.2px rgba(53, 55, 57, 0.63)) drop-shadow(0px 1.6px 1.6px rgba(0, 0, 0, 0.25))',
            width: '60px',
            borderRadius: '8px',
            height: '60px',
            position: singleView ? 'static' : 'absolute',
            overflow: 'hidden',
            left: index * 7 + 'px',
            bottom: index * 7 + 'px',
            zIndex: 3 - index,
          }}
          key={attachment.name}
        >
          <Stack
            alignItems="center"
            justifyContent="center"
            sx={{
              width: '100%',
              height: '100%',
            }}
          >
            <SvgIcon
              fontSize="small"
              component={
                imageMimeTypes.includes(attachment.mime_type) ? ImageIcon : InsertDriveFileIcon
              }
            />
            <Typography
              sx={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                marginTop: theme.spacing(1),
                width: '80%',
                fontSize: '8px',
              }}
              variant="caption"
            >
              {attachment.name}
            </Typography>
          </Stack>
        </Box>
      ))}
    </Box>
  );
};

export default LogAttachments;
