import { AssetStateType } from 'src/features/reports/types';

export const getAvailableAggregationOptions = (
  aggregations: { value: string; label: string }[],
  assetPropertyData: AssetStateType | null,
  type: string
) =>
  aggregations.map((aggregation) => {
    if (
      !assetPropertyData?.assetProperties ||
      !assetPropertyData.assetProperties.length ||
      !assetPropertyData.currentState ||
      !assetPropertyData.currentState.asset
    )
      return { ...aggregation, available: true };

    const assetPropertiesWithTag = assetPropertyData.assetProperties.filter(
      (assetProperty) => assetProperty.aggregation.value === aggregation.value
    );
    if (!assetPropertiesWithTag.length) return { ...aggregation, available: true };

    const currentStateAssetPropertyValue =
      assetPropertyData.currentState.asset.property.split('_')[0];

    return {
      ...aggregation,
      available:
        assetPropertiesWithTag.findIndex(
          (element) =>
            element.asset.property.value === currentStateAssetPropertyValue &&
            element.id !== assetPropertyData.currentAssetProperty?.id &&
            element.asset.type === type
        ) === -1,
    };
  });
