import { Stack, TablePagination } from '@mui/material';
import { ContentLoadingError } from '../content-loading-error';

type PaginationProps = {
  value: any[] | null;
  loaded: boolean;
  entityCount: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (event: unknown, newPage: number) => void;
  onRowsPerPageChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  tablePaginationStyle?: { position: string; left: string };
};

export const Pagination = ({
  value,
  loaded,
  entityCount,
  page,
  rowsPerPage,
  onPageChange,
  onRowsPerPageChange,
  tablePaginationStyle,
}: PaginationProps) => (
  <>
    {!value ? (
      <Stack sx={{ width: '100%', alignItems: 'center' }}>
        <ContentLoadingError />
      </Stack>
    ) : null}
    {loaded ? (
      <TablePagination
        component="div"
        count={entityCount}
        page={page}
        rowsPerPage={rowsPerPage}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
        sx={tablePaginationStyle}
      />
    ) : null}
  </>
);
