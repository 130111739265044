import {
  BOOSTER_PUMP_STATION,
  COMBINED_SEWAGE_OVERFLOW,
  LIFT_STATION,
  MBR_TRAIN_ASSET,
  PROCESS_PLANT,
  PUMP_ASSET,
  VALVE_ASSET,
  WASTEWATER_TREATMENT_PLANT,
  WATER_TOWER,
  WATER_TOWER_ASSET,
  WATER_TREATMENT_PLANT,
  WATER_WELL,
  WET_WELL_ASSET,
} from 'src/variables';

export const sitesTypes = {
  [LIFT_STATION]: 'Lift Station',
  [WATER_TREATMENT_PLANT]: 'Water Treatment Plant',
  [WASTEWATER_TREATMENT_PLANT]: 'Wastewater Treatment Plant',
  [BOOSTER_PUMP_STATION]: 'Booster Pump Station',
  [WATER_TOWER]: 'Water Tower',
  [PROCESS_PLANT]: 'Process Plant',
  [COMBINED_SEWAGE_OVERFLOW]: 'Combined Sewage Overflow',
  [WATER_WELL]: 'Water Well',
} as const;

export const assetTypes = {
  [PUMP_ASSET]: 'Pump',
  [WATER_TOWER_ASSET]: 'Water Tower',
  [WET_WELL_ASSET]: 'Wet Well',
  [MBR_TRAIN_ASSET]: 'MBR Train',
  [VALVE_ASSET]: 'Valve',
} as const;
