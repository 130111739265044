import { Theme, styled } from '@mui/material';
import { Link } from 'react-router-dom';

type StylesTypes = {
  theme: Theme;
};

const StyledTableLink = styled(Link, {
  shouldForwardProp: (prop) => prop !== 'isActive',
})(({ theme }: StylesTypes) => ({
  color: theme.palette.text.primary,
  fontWeight: theme.typography.fontWeightMedium,
  textDecoration: 'none',
  '&:hover': {
    color: theme.palette.primary.main,
  },
}));

export { StyledTableLink };
