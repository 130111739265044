import { styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';

const StyledLabelOnBox = styled(Box)(() => ({
  display: 'inline-block',
  background: 'rgba(5, 182, 178, 0.08)',
  padding: '2px 8px',
  borderRadius: '6px',
}));

const StyledLabelText = styled(Typography)(() => ({
  color: '#05b6b2',
  fontSize: '12px',
  fontWeight: '700',
}));

export { StyledLabelOnBox, StyledLabelText };
