import { Box, useTheme } from '@mui/material';
import { OneDayRangeIcon, SenvenDaysRangeIcon, OneMonthRangeIcon } from 'src/assets';
import { timeFrameBarOptions } from './style';
import { TimeBoxOption } from './components/time-box-option';
import { SelectedBoxType } from './types';
import { TimeOptionType } from '../../types';

type Props = {
  onSelectTimestampHandler?: (days: number) => void;
  onTemporarySelectTabletTimeOption?: (timeOption: TimeOptionType) => void;
  isTablet?: boolean;
  selectedBox?: SelectedBoxType;
  onSelectBoxHandler?: (value: SelectedBoxType) => void;
  temporarySelectedTabletTimeOption?: TimeOptionType;
  onResetZoomHandler?: () => void;
  onSelectTemporaryTimestampHandler?: (days: number) => void;
};

export const TimeFrameBarOptions = ({
  onSelectTimestampHandler,
  onTemporarySelectTabletTimeOption,
  isTablet,
  selectedBox,
  onSelectBoxHandler,
  temporarySelectedTabletTimeOption,
  onResetZoomHandler,
  onSelectTemporaryTimestampHandler,
}: Props) => {
  const theme = useTheme();
  const greyColor = theme.palette.grey[600];
  const mainColor = theme.palette.primary.main;

  return (
    <Box sx={timeFrameBarOptions}>
      <TimeBoxOption
        selectedBox={selectedBox}
        title="1d"
        icon={
          <OneDayRangeIcon
            fill={
              selectedBox === '1d' || temporarySelectedTabletTimeOption === '1d'
                ? mainColor
                : greyColor
            }
          />
        }
        onSelectBoxHandler={onSelectBoxHandler}
        onSelectTimestampHandler={onSelectTimestampHandler}
        onTemporarySelectTabletTimeOption={onTemporarySelectTabletTimeOption}
        temporarySelectedTabletTimeOption={temporarySelectedTabletTimeOption}
        isTablet={isTablet}
        onResetZoomHandler={onResetZoomHandler}
        onSelectTemporaryTimestampHandler={onSelectTemporaryTimestampHandler}
      />
      <TimeBoxOption
        selectedBox={selectedBox}
        title="7d"
        icon={
          <SenvenDaysRangeIcon
            fill={
              selectedBox === '7d' || temporarySelectedTabletTimeOption === '7d'
                ? mainColor
                : greyColor
            }
          />
        }
        onSelectBoxHandler={onSelectBoxHandler}
        onSelectTimestampHandler={onSelectTimestampHandler}
        onTemporarySelectTabletTimeOption={onTemporarySelectTabletTimeOption}
        temporarySelectedTabletTimeOption={temporarySelectedTabletTimeOption}
        isTablet={isTablet}
        onResetZoomHandler={onResetZoomHandler}
        onSelectTemporaryTimestampHandler={onSelectTemporaryTimestampHandler}
      />
      <TimeBoxOption
        selectedBox={selectedBox}
        title="30d"
        icon={
          <OneMonthRangeIcon
            fill={
              selectedBox === '30d' || temporarySelectedTabletTimeOption === '30d'
                ? mainColor
                : greyColor
            }
          />
        }
        onSelectBoxHandler={onSelectBoxHandler}
        onSelectTimestampHandler={onSelectTimestampHandler}
        onTemporarySelectTabletTimeOption={onTemporarySelectTabletTimeOption}
        temporarySelectedTabletTimeOption={temporarySelectedTabletTimeOption}
        isTablet={isTablet}
        onResetZoomHandler={onResetZoomHandler}
        onSelectTemporaryTimestampHandler={onSelectTemporaryTimestampHandler}
      />
    </Box>
  );
};
