import { Theme, styled } from '@mui/material/styles';
import { Button } from '@mui/material';

const alertBox = {
  width: '100%',
  margin: '0 auto',
  position: 'absolute',
  top: '75px',
  left: '0',
  zIndex: '101',
  display: 'flex',
  justifyContent: 'center',
};

const collapseBox = {
  width: '760px',
  maxWidth: '90%',
  margin: '0 auto',
};

const alert = {
  display: 'flex',
  alignItems: 'center',
};

const StyledButton = styled(Button)(({ theme }: { theme: Theme }) => ({
  color: theme.palette.error.main,
  borderColor: theme.palette.error.main,
  marginLeft: '2.5rem',
  padding: '0.2rem 0.8rem',
}));

export { alertBox, collapseBox, alert, StyledButton };
