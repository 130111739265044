import { styled, Theme } from '@mui/material/styles';
import { Box } from '@mui/material';

type StylesTypes = {
  theme: Theme;
  isActive: boolean;
};

const StyledBoxIcon = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isActive',
})(({ isActive, theme }: StylesTypes) => ({
  background: isActive ? theme.palette.primary.lighter : 'none',
  width: '30px',
  height: '30px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '8px',
}));

const timeBoxOption = {
  display: 'flex',
  alignItems: 'center',
  marginRight: '24px',
  cursor: 'pointer',
  '@media (max-width:600px)': {
    marginRight: '12px',
  },
};
const timeBoxTitle = {
  marginLeft: '6px',
};

export { timeBoxOption, timeBoxTitle, StyledBoxIcon };
