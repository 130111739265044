import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  Alert,
  AlertColor,
  Button,
  Card,
  Container,
  Menu,
  MenuItem,
  Stack,
  Table,
  TableBody,
  TableContainer,
  useTheme,
} from '@mui/material';

import { INTEGRATIONS_PATH, SITES_MANAGEMENT_PATH } from 'src/routes/config';
import { PlusFillIcon } from 'src/assets';
import {
  CONNECT_DATA_SOURCE,
  BREADCRUMBS_ROOT,
  INTEGRATIONS_LABEL,
  TABLE_HEAD,
  WEATHER_DATA_SOURCE_TYPE,
  STREAMETRIC_AI_DATA_SOURCE_TYPE,
} from '../config';
import {
  CustomBreadcrumbs,
  MemoizedScrollbar,
  Pagination,
  TableHeadCustom,
  TableRowsSkeleton,
  useTable,
} from 'src/components';
import { useSite } from 'src/contexts/siteContext/sites.context';
import { useAuthentication } from 'src/features/authentication/context';
import { DataSourcesTableRow } from '../components/index';
import { DataSource } from '../types';
import { useSnackbar } from 'notistack';
import { dataSourceParamsType, DataSourceService } from 'src/services/dataSources';
import { AccessRoles } from 'src/features/user-account-details/types';
import { useDocumentTitle, usePagination } from 'src/hooks';
import { tablePaginationStyle } from 'src/styles/tablePaginationStyle';
import { menuWidth } from './style';

export const DataSourceManagement = () => {
  const {
    page,
    pages,
    setPages,
    order,
    orderBy,
    rowsPerPage,
    selected,
    onSelectRow,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable();
  const { getSite } = useSite();
  const navigate = useNavigate();
  const {
    customerId: { value: customerId },
    siteId: { value: siteId },
    getCurrentRole,
  } = useAuthentication();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const [errorMessage, setErrorMessage] = useState<{ color: AlertColor; message: string } | null>(
    null
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const validDataSourceTypes: dataSourceParamsType['dataSourceType'][] = [
    STREAMETRIC_AI_DATA_SOURCE_TYPE,
    WEATHER_DATA_SOURCE_TYPE,
  ];

  const handleMenuItemClick = (item: string) => {
    if (validDataSourceTypes.includes(item as dataSourceParamsType['dataSourceType'])) {
      connectDataSource({ dataSourceType: item as dataSourceParamsType['dataSourceType'] });
    }
    handleClose();
  };

  const [dataSourcesData, setDataSourcesData] = useState<{
    value: DataSource[] | null;
    loaded: boolean;
  }>({ value: [], loaded: false });
  const [dataSourceCount, setDataSourceCount] = useState(0);
  const { setPagesUsingEntityState } = usePagination();

  useDocumentTitle('Streametric - Integrations');

  const handleEditRow = (id: string) => {
    if (!customerId || !siteId) return;
    navigate(`${INTEGRATIONS_PATH}/${id}?customerId=${customerId}&siteId=${siteId}`);
  };

  const disableItem = (type: string) => {
    if (dataSourcesData.loaded && type && dataSourcesData.value) {
      if (dataSourcesData.value.some((item) => item.dataSourceType === type)) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  };

  useEffect(() => {
    if (!customerId || !siteId) return;

    const chosenSkipToId = pages.find((obj) => obj.page === page)?.skipTo ?? '';

    setDataSourcesData((prevData) => ({ ...prevData, loaded: false }));
    DataSourceService.getAll(customerId, siteId, { skipTo: chosenSkipToId, limit: rowsPerPage })
      .then((data) => {
        if (data?.items?.length > 0) {
          setDataSourcesData({ value: data.items, loaded: true });
        } else {
          setDataSourcesData((prevValue) => ({ ...prevValue, loaded: true }));
        }
      })
      .catch((error) => {
        setDataSourcesData({ value: null, loaded: true });
        console.error(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowsPerPage, page]);

  useEffect(() => {
    setPagesUsingEntityState(setPages, dataSourcesData, page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setPagesUsingEntityState, dataSourcesData]);

  useEffect(() => {
    setPages([{ page: 0, skipTo: '', siteId: '' }]);
    setDataSourcesData({ value: [], loaded: false });
  }, [rowsPerPage, setPages]);

  useEffect(() => {
    if (!customerId || !siteId) return;

    DataSourceService.getCount(customerId, siteId)
      .then((data) => {
        setDataSourceCount(data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [customerId, siteId, dataSourcesData]);

  const connectDataSource = async (dataSourceType: dataSourceParamsType) => {
    if (!customerId || !siteId) return;
    try {
      await DataSourceService.add(customerId, siteId, dataSourceType);
      enqueueSnackbar('Successfully connected to data source', {
        variant: 'success',
      });
      DataSourceService.getAll(customerId, siteId)
        .then((data) => {
          setDataSourcesData({ value: data.items, loaded: true });
        })
        .catch((error) => console.error(error));
    } catch (err) {
      if (dataSourceType.dataSourceType === WEATHER_DATA_SOURCE_TYPE) {
        setErrorMessage({
          color: 'warning',
          message: 'Site coordinates need to be defined to use the Weather API',
        });
      } else {
        setErrorMessage({ color: 'error', message: err.message });
      }
    }
  };

  const showErrorMessage = (variant: AlertColor, message: string) => (
    <Alert
      severity={variant}
      onClose={() => setErrorMessage(null)}
      sx={{ mb: '8px', alignItems: 'center', justifyContent: 'center' }}
    >
      {message || 'An error occurred'}
      <Button sx={{ ml: 2 }} href={`site-info?customerId=${customerId}&siteId=${siteId}`}>
        Add Coordinates
      </Button>
    </Alert>
  );

  return (
    <Container maxWidth={false} disableGutters data-sm="data-sources-content">
      <Stack
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ my: '25px' }}
      >
        <CustomBreadcrumbs
          links={[
            {
              name: BREADCRUMBS_ROOT,
              href: SITES_MANAGEMENT_PATH + `?customerId=${customerId}`,
              testId: 'breadcrumb-root',
            },
            {
              name: getSite()?.siteName,
              href: `${SITES_MANAGEMENT_PATH}/dashboard?customerId=${customerId}&siteId=${siteId}`,
              testId: 'site-breadcrumb',
            },
            {
              name: INTEGRATIONS_LABEL,
            },
          ]}
          sx={{ mb: '0px' }}
        />

        {getCurrentRole(customerId, null) === AccessRoles.SUPER_ADMIN ? (
          <>
            <Button
              sx={{ minWidth: '200px' }}
              variant="contained"
              startIcon={<PlusFillIcon />}
              onClick={handleClick}
              disabled={
                disableItem(STREAMETRIC_AI_DATA_SOURCE_TYPE) &&
                disableItem(WEATHER_DATA_SOURCE_TYPE)
              }
              data-sm="connect-data-source-button"
            >
              {CONNECT_DATA_SOURCE}
            </Button>
            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
              <MenuItem
                disabled={disableItem(WEATHER_DATA_SOURCE_TYPE)}
                sx={menuWidth}
                onClick={() => handleMenuItemClick(WEATHER_DATA_SOURCE_TYPE)}
              >
                Weather API
              </MenuItem>
              <MenuItem
                disabled={disableItem(STREAMETRIC_AI_DATA_SOURCE_TYPE)}
                sx={menuWidth}
                onClick={() => handleMenuItemClick(STREAMETRIC_AI_DATA_SOURCE_TYPE)}
              >
                AI Flow
              </MenuItem>
            </Menu>
          </>
        ) : null}
      </Stack>
      {errorMessage && showErrorMessage(errorMessage.color, errorMessage.message)}

      <Card sx={{ md: '32px' }}>
        <TableContainer
          sx={{
            position: 'relative',
            overflow: 'unset',
            backgroundColor: theme.palette.background.default,
          }}
        >
          <MemoizedScrollbar>
            <Table size={'medium'} sx={{ minWidth: 800 }} data-sm="data-sources-table">
              <TableHeadCustom order={order} orderBy={orderBy} headLabel={TABLE_HEAD} />

              <TableBody data-sm="table-content">
                {dataSourcesData.loaded ? (
                  dataSourcesData.value?.map((row: any) => (
                    <DataSourcesTableRow
                      key={row.id}
                      row={row}
                      selected={selected.includes(row.id)}
                      onSelectRow={() => onSelectRow(row.id)}
                      handleEdit={() => handleEditRow(row.id)}
                    />
                  ))
                ) : (
                  <TableRowsSkeleton rowsNum={8} cellsNum={5} />
                )}
              </TableBody>
            </Table>
            <Pagination
              value={dataSourcesData.value}
              loaded={dataSourcesData.loaded}
              entityCount={dataSourceCount}
              page={page}
              rowsPerPage={rowsPerPage}
              onPageChange={onChangePage}
              onRowsPerPageChange={onChangeRowsPerPage}
              tablePaginationStyle={tablePaginationStyle}
            />
          </MemoizedScrollbar>
        </TableContainer>
      </Card>
    </Container>
  );
};

export default DataSourceManagement;
