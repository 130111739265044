import { Theme } from '@mui/material/styles';
import { Box, SxProps, TablePagination, TablePaginationProps } from '@mui/material';

type Props = {
  sx?: SxProps<Theme>;
  testId: string;
};

type CustomLabelProps = {
  from: number;
  to: number;
  count: number;
};

export const TablePaginationCustom = ({
  rowsPerPageOptions = [10, 25, 50, 100],
  sx,
  testId,
  ...other
}: Props & TablePaginationProps) => {
  const customLabelDisplayedRows = ({ from, to, count }: CustomLabelProps) => (
    <span data-sm={testId}>{`${from} - ${to} of ${count}`}</span>
  );

  return (
    <Box sx={{ position: 'relative', ...sx }} data-sm="pagination">
      <TablePagination
        rowsPerPageOptions={rowsPerPageOptions}
        component="div"
        {...other}
        labelDisplayedRows={customLabelDisplayedRows}
      />
    </Box>
  );
};
