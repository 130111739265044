import axios from 'axios';

const ACCOUNTS_CUSTOMERS_URL = '/accounts/customers';
const COUNT_URL = '/count';
const LOGIN_URL = '/accounts/login';
const RESET_PASSWORD = '/reset-password';
const ACCOUNTS_USERS_URL_START = '/accounts';
const ACCOUNTS_USERS_URL_END = '/users';
const SITES_ROOT = '/sites';
const GATEWAYS_URL_END = '/gateways';
const ACCOUNTS_USERS_FORGOT_PASSWORD = '/forgot-password';
const ACCOUNTS_USERS_REPLACE_PASSWORD = '/replace-password';
const ACCOUNTS_USERS_SET_PASSWORD = '/set-password';
const ACCOUNTS_JOB_TITLES = '/job-titles';
const WIDGETS = 'widgets';
const ASSETS_MANAGEMENT_URL = '/assets';
const ASSETS_PROPERTIES = '/properties';
const ASSETS_PROPERTY = '/property';
const ASSETS_TAGS = '/tags';
const ASSETS_ALARM_TAGS = '/alarm-tags';
const VALUES = '/values';
const ALARMS_URL = '/alarms';
const EVENTS = '/events';
const CUSTOMER_EVENTS = '/customer-events';
const ACTIVE_EVENTS = '/active-events';
const READINGS = '/readings';
const PUMP_REPORT = '/pump-report';
const REFRESH = '/refresh';
const NOTIFICATIONS_URL = '/notifications';
const UNSUBSCRIBE = '/unsubscribe';
const DISCONNECT = 'disconnect';
const SET_PROFILE_URL = 'accounts/set-profile';
const CONTROLLERS_URL = '/controllers';
const CONTROLLER_URL = '/controller';
const NOTIFICATION_GROUPS_URL = '/notification-groups';
const CHECK_USERS_SITES_ACCESS = 'check-users-sites-access';
const SITE_PREVIEWS = 'site/previews';
const THEMES = 'themes';
const API_V2 = '/v2';
const REPORTS = 'reports';
const CUSTOMERS = 'customers';
const TEMPLATES = 'templates';

const api = axios.create({
  baseURL: process.env.REACT_APP_STREAMETRIC_API,
  withCredentials: true,
});

export {
  ACCOUNTS_CUSTOMERS_URL,
  COUNT_URL,
  LOGIN_URL,
  RESET_PASSWORD,
  api,
  ACCOUNTS_USERS_URL_START,
  ACCOUNTS_USERS_URL_END,
  GATEWAYS_URL_END,
  ACCOUNTS_JOB_TITLES,
  ACCOUNTS_USERS_FORGOT_PASSWORD,
  ACCOUNTS_USERS_SET_PASSWORD,
  ACCOUNTS_USERS_REPLACE_PASSWORD,
  WIDGETS,
  ASSETS_MANAGEMENT_URL,
  ASSETS_PROPERTIES,
  ASSETS_PROPERTY,
  ASSETS_TAGS,
  ASSETS_ALARM_TAGS,
  VALUES,
  ALARMS_URL,
  EVENTS,
  READINGS,
  PUMP_REPORT,
  REFRESH,
  NOTIFICATIONS_URL,
  UNSUBSCRIBE,
  DISCONNECT,
  SET_PROFILE_URL,
  SITES_ROOT,
  CONTROLLERS_URL,
  CONTROLLER_URL,
  CUSTOMER_EVENTS,
  ACTIVE_EVENTS,
  NOTIFICATION_GROUPS_URL,
  CHECK_USERS_SITES_ACCESS,
  SITE_PREVIEWS,
  THEMES,
  API_V2,
  REPORTS,
  CUSTOMERS,
  TEMPLATES,
};
