export class NewUserAccount {
  email: string;

  jobTitle?: string;

  status: string;

  accessRoles: string[];

  constructor(email: string, accessRoles: string[], status: string, jobTitle?: string) {
    this.email = email;
    this.jobTitle = jobTitle;
    this.accessRoles = accessRoles;
    this.status = status;
  }
}

export class NewUserAccountWithActiveProp extends NewUserAccount {
  isActive: boolean;

  mainAccessRole: string;

  constructor(
    email: string,
    mainAccessRole: string,
    accessRoles: string[],
    status: string,
    isActive: boolean,
    jobTitle?: string
  ) {
    super(email, accessRoles, status, jobTitle);
    this.isActive = isActive;
    this.mainAccessRole = mainAccessRole;
  }
}

export class UpdateUserAccount extends NewUserAccountWithActiveProp {}

export class UserAccount extends NewUserAccount {
  id: string;

  createdAt: string;

  updatedAt: string;

  constructor(
    id: string,
    email: string,
    accessRoles: string[],
    status: string,
    createdAt: string,
    updatedAt: string,
    jobTitle?: string
  ) {
    super(email, accessRoles, status, jobTitle);
    this.id = id;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
  }
}

export enum AccessRoles {
  SUPER_ADMIN = 'SUPER_ADMIN',
  ACCOUNT_ADMIN = 'ACCOUNT_ADMIN',
  SITE_MANAGER = 'SITE_MANAGER',
  SITE_EMPLOYEE = 'SITE_EMPLOYEE',
  LIMITED_ACCESS = 'LIMITED_ACCESS',
}

export enum SiteRoles {
  SITE_MANAGER = 'SITE_MANAGER',
  SITE_EMPLOYEE = 'SITE_EMPLOYEE',
  NO_ACCESS = 'NO_ACCESS',
}
