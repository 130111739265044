import WidgetWrapper from '../WidgetWrapper';
import { TagItem } from 'src/features/sites/components/tag-item';
import { useCallback, useEffect, useState } from 'react';
import { PositioningTypes, WidgetService } from 'src/services';
import { TagType } from 'src/features/sites/types/types';
import { Dialog, useTheme } from '@mui/material';
import { ModalEditLiveDataWidget } from '../ModalEditLiveDataWidget';
import { RowType } from '../ModalEditLiveDataWidget/types';
import { TagResponse } from 'src/types';
import { useAuthentication } from 'src/features/authentication/context';

type Props = {
  title: string;
  isDataReady: boolean;
  tags?: TagType[];
  widgetId?: string;
  toggleAddOrRemoveHandler?: () => void;
  testId?: string;
  isEditMode: boolean;
  messages?: any;
  isMobile: boolean;
  pos: PositioningTypes;
};

export const LiveDataWidget = ({
  title,
  isDataReady,
  tags,
  widgetId,
  toggleAddOrRemoveHandler,
  testId,
  isEditMode,
  messages,
  isMobile,
  pos,
}: Props) => {
  const {
    customerId: { value: customerId },
    siteId: { value: siteId },
  } = useAuthentication();
  const [liveData, setLiveData] = useState<any>([]);
  const [isLiveDataModalOpen, setIsLiveDataModalOpen] = useState<boolean>(false);
  const [rows, setRows] = useState<{ value: RowType[]; loaded: boolean }>({
    value: Array.from({ length: 1 }, (v, k) => k).map((k) => ({
      id: `${k + 1}`,
      position: k + 1,
      tagId: '',
      gatewayId: '',
      parentType: 'gateway',
    })),
    loaded: false,
  });
  const theme = useTheme();

  const onCloseEditDialog = () => {
    if (setIsLiveDataModalOpen) setIsLiveDataModalOpen(false);
  };

  const updateOutOfSyncWidget = useCallback(
    async (updatedTags: TagType[]) => {
      if (!customerId || !siteId || !widgetId) return;

      try {
        await WidgetService.edit({
          customerId: customerId,
          siteId: siteId,
          widgetId: widgetId,
          data: {
            tags: updatedTags,
            positioning: pos,
          },
        });
      } catch (error) {
        console.error('Error syncing widget:', error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [customerId, siteId, widgetId, pos]
  );

  const isTagNameOutOfSync = useCallback(() => {
    const outOfSyncTagIds: { tag: TagType; index: number }[] = [];

    if (tags && messages?.data?.gateway_inputs) {
      tags.forEach((tag, index) => {
        const match = messages.data.gateway_inputs.find(
          (input: TagResponse) => input.id === tag.tagId
        );

        if (match && match.displayName !== tag.displayName) {
          const updatedTag = {
            ...tag,
            displayName: match.displayName,
          };

          outOfSyncTagIds.push({
            tag: updatedTag,
            index: index,
          });
        }
      });

      if (outOfSyncTagIds.length > 0) {
        const updatedTags = [...tags];
        outOfSyncTagIds.forEach(({ tag, index }) => {
          updatedTags[index] = tag;
        });

        updateOutOfSyncWidget(updatedTags);
      }
    }
  }, [tags, messages?.data?.gateway_inputs, updateOutOfSyncWidget]);

  useEffect(() => {
    if (tags && messages?.data?.gateway_inputs) {
      isTagNameOutOfSync();
    }
  }, [tags, messages?.data?.gateway_inputs, isTagNameOutOfSync]);

  useEffect(() => {
    if (tags) {
      setRows({
        value: tags.map((tag, index) => ({
          id: `${index + 1}`,
          position: index + 1,
          tagId: tag.tagId,
          gatewayId: tag.gatewayId || '',
          tag: tag,
        })),
        loaded: true,
      });
    }
  }, [tags]);

  useEffect(() => {
    if (messages?.data) {
      try {
        setLiveData((prevState: any) => {
          const existingGateway = prevState.find(
            (item: any) => item.data.gateway_id === messages.data.gateway_id
          );
          if (existingGateway && messages?.data.gateway_inputs?.length === 1) {
            const inputToUpdate = messages.data.gateway_inputs[0];

            const inputIndex = existingGateway.data.gateway_inputs.findIndex(
              (input: any) => input.id === inputToUpdate.id
            );
            if (inputIndex !== -1) {
              const updatedGateway = {
                ...existingGateway,
                gateway_inputs: [
                  ...existingGateway.data.gateway_inputs.slice(0, inputIndex),
                  inputToUpdate,
                  ...existingGateway.data.gateway_inputs.slice(inputIndex + 1),
                ],
              };
              return [
                ...prevState.filter(
                  (item: any) => item.data.gateway_id !== messages.data.gateway_id
                ),
                updatedGateway,
              ];
            }
          }

          return [
            ...prevState.filter((item: any) => item.data.gateway_id !== messages.data.gateway_id),
            messages,
          ];
        });
      } catch (error) {
        console.error('Error parsing gateway_inputs:', error);
      }
    }
  }, [messages]);

  const combinedInputs = liveData.reduce(
    (acc: any, item: any) => acc.concat(item.data.gateway_inputs),
    []
  );

  const liveDataComplete = {
    siteId: undefined,
    tags: combinedInputs,
  };

  return (
    <>
      <Dialog
        onClose={onCloseEditDialog}
        open={isLiveDataModalOpen}
        PaperProps={{
          sx: { backgroundColor: theme.palette.background.default },
        }}
      >
        <ModalEditLiveDataWidget
          title={title}
          initialRows={rows}
          widgetId={widgetId}
          pos={pos}
          toggleAddOrRemoveHandler={toggleAddOrRemoveHandler}
          onClose={onCloseEditDialog}
        />
      </Dialog>
      <WidgetWrapper
        title={title}
        isDataReady={isDataReady}
        isLiveDataWidget={true}
        widgetId={widgetId}
        toggleAddOrRemoveHandler={toggleAddOrRemoveHandler}
        isEditMode={isEditMode}
        testId={testId}
        setIsLiveDataModalOpen={setIsLiveDataModalOpen}
      >
        {tags?.map((tag) => (
          <TagItem
            testId={`tag-item-${tag.tagId}`}
            key={tag.tagId}
            tag={tag}
            isMobile={isMobile}
            liveDataComplete={liveDataComplete}
            isLiveDataWidget={true}
          />
        ))}
      </WidgetWrapper>
    </>
  );
};
