import { useState, useId, useEffect } from 'react';
import {
  Box,
  Button,
  DialogActions,
  DialogTitle,
  Divider,
  InputAdornment,
  Stack,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { FormProvider } from 'src/components/minimals/form';
import {
  StyledModalWrapper,
  selectDataBox,
  selectDataTitle,
  modalContentItemsList,
  formProviderWrapper,
  buttonsBox,
  cancelBtn,
  DialogContentWithCustomScroll,
} from './style';
import { ModalContentItem } from '../modal-content-item/ModalContentItem';
import { GetAllResponse } from 'src/services/sites/types';
import { SearchFillIcon } from 'src/assets';
import { getSelectedInputs, getSiteIdsOfSelectedEvents } from '../../utils';
import { SelectedInputType } from '../../types/selected-input';
import { TimelineChartValue } from '../../types';
import { useFormValues } from '../../hooks';
import { useAuthentication } from 'src/features/authentication/context';
import { SelectedItemsList } from '../selected-items-list';
import { CustomWaterLoading } from 'src/components';
import { useDebounce } from 'src/hooks/useDebounce';

type AddChartModalContentProps = {
  onToggleHandler: () => void;
  updatedSetValues?: (values: TimelineChartValue, isTimelineChart: boolean) => void;
  addNewItemToExistingChart?: (values: TimelineChartValue) => void;
  onSelectEventHandler?: (selectedInputs: SelectedInputType[], id: string) => void;
  isParticularChart?: boolean;
  chartId?: string;
  isTimelineChart?: boolean;
  sites?: { value: GetAllResponse[]; loaded: boolean };
};

export const AddChartModalContent = ({
  onToggleHandler,
  updatedSetValues,
  onSelectEventHandler,
  isParticularChart,
  chartId,
  isTimelineChart,
  sites,
}: AddChartModalContentProps) => {
  const {
    customerId: { value: customerId },
  } = useAuthentication();
  const id = useId();

  const [filteredItems, setFilteredItems] = useState<GetAllResponse[] | []>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const theme = useTheme();
  const disabledTextColor = theme.palette.text.disabled;

  const {
    setOrUpdateNewFormValuesForSpecificChart,
    getNewFormValuesForSpecificChart,
    updateLocalStorageForAllPointCharts,
  } = useFormValues();

  const [stateForSpecificChart] = useState<any>(() => {
    const newState = getNewFormValuesForSpecificChart({
      chartType: isTimelineChart ? 'events' : 'pointChart',
      chartId: chartId!,
      customerId: customerId!,
    });
    Object.keys(newState).forEach(function (itm) {
      if (itm.includes(isTimelineChart ? 'row' : 'event')) delete newState[itm];
    });
    newState.search = '';

    return newState;
  });
  console.log(stateForSpecificChart, 'state for specific chart');

  const defaultValues: any = {
    search: '',
  };

  const methods = useForm({
    defaultValues: isParticularChart ? stateForSpecificChart : defaultValues,
  });

  const { setValue, watch } = methods;
  const values = watch();

  const onAddChartHandler = () => {
    if (!customerId) {
      onToggleHandler();
      return;
    }
    if (isParticularChart && !isTimelineChart) {
      updateLocalStorageForAllPointCharts({ chartId: chartId!, values: values });
    }
    const siteIds = getSiteIdsOfSelectedEvents(values);
    if (updatedSetValues)
      updatedSetValues(
        {
          id: isTimelineChart && chartId ? chartId : id,
          customerId: customerId,
          siteIds: siteIds,
        },
        isTimelineChart || false
      );

    const selectedInputsPoint = getSelectedInputs(values);

    if (selectedInputsPoint.length > 0 && onSelectEventHandler) {
      onSelectEventHandler(selectedInputsPoint, isParticularChart ? chartId! : id);
    }

    onToggleHandler();

    if (siteIds.length > 0 && !isParticularChart)
      setOrUpdateNewFormValuesForSpecificChart({
        chartType: 'events',
        chartId: isParticularChart && chartId ? chartId : id,
        customerId: customerId!,
        formValues: values,
      });
    if (selectedInputsPoint.length > 0 && !isParticularChart)
      setOrUpdateNewFormValuesForSpecificChart({
        chartType: 'pointChart',
        chartId: isParticularChart && chartId ? chartId : id,
        customerId: customerId!,
        formValues: values,
      });

    if (isParticularChart) {
      setOrUpdateNewFormValuesForSpecificChart({
        chartType: isTimelineChart ? 'events' : 'pointChart',
        chartId: isParticularChart && chartId ? chartId : id,
        customerId: customerId!,
        formValues: values,
      });
    }
  };

  const debouncedSearchTerm = useDebounce(values?.search, 300);
  const sitesForDisplay = debouncedSearchTerm ? filteredItems : sites?.value;

  useEffect(() => {
    const filteredItems = sites?.value.filter((tag) =>
      tag.siteName?.toLowerCase().includes(debouncedSearchTerm)
    );
    if (filteredItems) setFilteredItems(filteredItems);
    setIsLoading(false);
  }, [debouncedSearchTerm, sites]);

  return (
    <>
      <DialogTitle sx={{ backgroundColor: theme.palette.background.default }}>
        Add Chart
      </DialogTitle>
      <Divider />
      <DialogContentWithCustomScroll sx={formProviderWrapper} data-sm="add-chart-modal">
        <FormProvider methods={methods}>
          <StyledModalWrapper theme={theme}>
            <Box sx={selectDataBox}>
              <Typography variant="body1" sx={selectDataTitle}>
                Select Data
              </Typography>
              <TextField
                onChange={(event) => {
                  setValue('search', event.target.value);
                  setIsLoading(true);
                }}
                name="search"
                fullWidth
                placeholder="Search sites..."
                inputProps={{ style: { padding: '10px' }, 'data-sm': 'search-input' }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchFillIcon fill={disabledTextColor} />
                    </InputAdornment>
                  ),
                }}
              />
              <SelectedItemsList values={values} setValue={setValue} sites={sites?.value} />
            </Box>

            {!sites?.loaded || isLoading ? (
              <Stack
                flexDirection={'row'}
                justifyContent={'center'}
                alignItems={'center'}
                sx={{ mb: '16px' }}
              >
                <CustomWaterLoading />
              </Stack>
            ) : (
              <Box sx={modalContentItemsList}>
                {sitesForDisplay?.map((site) => (
                  <ModalContentItem
                    key={site.id}
                    site={site}
                    setValue={setValue}
                    values={values}
                    isParticularChart={isParticularChart}
                    isTimelineChart={isTimelineChart}
                  />
                ))}
              </Box>
            )}
          </StyledModalWrapper>
        </FormProvider>
      </DialogContentWithCustomScroll>
      <Divider />
      <DialogActions sx={{ backgroundColor: theme.palette.background.default }}>
        <Box sx={buttonsBox}>
          <Button
            sx={cancelBtn}
            onClick={onToggleHandler}
            variant="outlined"
            data-sm="cancel-modal-button"
          >
            Cancel
          </Button>
          <Button onClick={onAddChartHandler} variant="contained" data-sm="add-modal-button">
            Add
          </Button>
        </Box>
      </DialogActions>
    </>
  );
};
