import { Tooltip, TooltipProps, styled, tooltipClasses } from '@mui/material';

export const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    boxShadow: theme.customShadows?.primary,
    fontSize: 12,
    padding: 10,
    maxWidth: '400px',
    background: theme.palette.background.default,
  },
}));
