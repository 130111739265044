import { Theme, styled } from '@mui/material/styles';
import { Card, Typography } from '@mui/material';

type StylesTypes = {
  theme: Theme;
};

const StyledCard = styled(Card)<{ logs?: boolean }>(({ theme, logs }) => ({
  maxWidth: logs ? '100%' : '343px',
  margin: '16px auto',
  width: logs ? '100%' : 'initial',
  boxShadow: '0px 0px 2px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12)',
  borderRadius: '16px',
  backgroundColor: theme.palette.background.default,
}));

const cardContent = {
  padding: '16px 18px 16px 16px  !important ',
};

const cardHeader = {
  display: 'flex',
  justifyContent: 'space-between',
};

const StyledAlarmTypeText = styled(Typography)(({ theme }: StylesTypes) => ({
  color: theme.palette.grey[600],
  textTransform: 'capitalize',
}));

const cardFooter = {
  display: 'flex',
  paddingTop: '16px',
  flexDirection: 'column',
  justifyContent: 'space-between',
};

const footerText = {
  color: '#212B36',
};
export { StyledCard, cardContent, cardHeader, StyledAlarmTypeText, cardFooter, footerText };
