import { ApexOptions } from 'apexcharts';
import { roundNumber } from '../utils';

export const PUMP_MODE = 'Mode:';
export const CHART_LABEL = 'Hours';

export const CYCLE_COUNT_CHART_LABEL = 'Count';

export const chartSeries = (data: any[]) => [
  {
    name: 'Value',
    data: data?.map((inputValue) => ({
      x: inputValue.timestamp,
      y: inputValue.input,
    })),
  },
];

export const getRuntimeChartOptions = (labels: string[], values?: number[]): ApexOptions => ({
  chart: {
    type: 'bar',
    height: 350,
  },
  plotOptions: {
    bar: {
      borderRadius: 5,
      borderRadiusApplication: 'end',
      horizontal: false,
      columnWidth: '55%',
      dataLabels: {
        position: 'top',
      },
    },
  },
  dataLabels: {
    enabled: true,
    offsetY: -15,
    style: {
      fontSize: '11px',
      colors: ['#304758'],
    },
  },
  stroke: {
    show: true,
    width: 2,
    colors: ['transparent'],
  },
  xaxis: {
    categories: labels,
  },
  fill: {
    opacity: 1,
  },
  yaxis: {
    tickAmount: 4,
    max: getMaxAxisValue(values),
    labels: {
      formatter: function (val) {
        return val.toFixed(2);
      },
    },
  },
  tooltip: {
    y: {
      title: {
        formatter(seriesName) {
          return `${seriesName}: `;
        },
      },
    },
  },
  colors: ['#05B6B2'],
});

export const getCycleCountChartOptions = (labels: string[], values?: number[]): ApexOptions => ({
  chart: {
    type: 'bar',
    height: 350,
  },
  plotOptions: {
    bar: {
      borderRadius: 5,
      borderRadiusApplication: 'end',
      horizontal: false,
      columnWidth: '55%',
      dataLabels: {
        position: 'top',
      },
    },
  },
  dataLabels: {
    enabled: true,
    offsetY: -15,
    style: {
      fontSize: '11px',
      colors: ['#304758'],
    },
  },
  stroke: {
    show: true,
    width: 2,
    colors: ['transparent'],
  },
  xaxis: {
    categories: labels,
  },
  fill: {
    opacity: 1,
  },
  yaxis: {
    tickAmount: 4,
    max: getMaxAxisValue(values),
    labels: {
      formatter: function (val) {
        return val.toFixed(0);
      },
    },
  },
  tooltip: {
    y: {
      title: {
        formatter(seriesName) {
          return `${seriesName}: `;
        },
      },
    },
  },
  colors: ['#05B6B2'],
});
export const getChartOptions = (chartType: string): ApexOptions => ({
  chart: {
    stacked: false,
    id: 'basic-bar-2',
    zoom: {
      type: 'x',
      enabled: true,
      autoScaleYaxis: false,
    },
    toolbar: {
      autoSelected: 'zoom',
    },
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    width: 2,
    curve: chartType === 'continuous' ? 'straight' : 'stepline',
  },
  markers: {
    size: 0,
  },
  fill: {
    type: 'gradient',
    gradient: {
      shadeIntensity: 1,
      inverseColors: false,
      opacityFrom: 0,
      opacityTo: 0,
      stops: [0, 90, 100],
    },
  },
  yaxis: {
    labels: {
      formatter: function (val) {
        return roundNumber(val);
      },
    },
  },
  xaxis: {
    type: 'datetime',
    labels: {
      datetimeUTC: false,
      showDuplicates: false,
      format: 'hh:mmtt',
    },
  },
  tooltip: {
    shared: false,
    x: {
      show: true,
      format: 'dd MMM yyyy hh:mmtt',
    },
    y: {
      formatter: function (val) {
        return val?.toString();
      },
    },
  },
  colors: ['#05B6B2'],
});

export const formatRuntimeChartTooltip = (val: number) => {
  const [fullHour, decimals] = val.toFixed(2).split('.');
  let percentage = 0;

  for (let i = 0; i < decimals.length; i++) {
    if (i === 0) {
      percentage += Number(decimals[i]) * 10;
      continue;
    }
    percentage += Number(decimals[i]);
  }

  const [fullMinutes, firstDecimal] = (60 * (percentage / 100)).toFixed(2).split('.').map(Number);
  const minutes = firstDecimal < 5 ? fullMinutes : fullMinutes + 1;

  return `${fullHour}h ${minutes}min`;
};

const getMaxAxisValue = (values?: number[]): number | undefined => {
  if (!values) return;

  const maxValue = Math.max(...values);

  return maxValue >= 24 ? Math.floor(maxValue) : Math.ceil(maxValue) + 1;
};
