import { AxiosResponse } from 'axios';

export const getToken = (response: AxiosResponse) => {
  if (!response.data.idToken) {
    return '';
  }

  let base64Url = response.data.idToken.split('.')[1];
  let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');

  while (base64.length % 4) {
    base64 += '=';
  }

  return window.atob(base64);
};

export const getTokenAfterSetProfile = (response: any) =>
  response.idToken ? window.atob(response.idToken.split('.')[1]) : '';

export const decodeToken = (codedToken: string) =>
  codedToken ? window.atob(codedToken.split('.')[1]) : '';
