import { Theme } from '@mui/material/styles';
import Alert from './Alert';
import Autocomplete from './Autocomplete';
import Card from './Card';
import Checkbox from './Checkbox';
import Tabs from './Tabs';
import Link from './Link';
import Table from './Table';
import Paper from './Paper';
import Button from './Button';
import Switch from './Switch';
import Typography from './Typography';
import Pagination from './Pagination';
import Dialog from './Dialog';
import Popover from './Popover';
import Tooltip from './Tooltip';
import Skeleton from './Skeleton';
import TextField from './TextField';

export default function ComponentsOverrides(theme: Theme) {
  return Object.assign(
    Alert(theme),
    Autocomplete(theme),
    Card(theme),
    Checkbox(theme),
    Link(theme),
    Table(theme),
    Paper(theme),
    Button(theme),
    Switch(theme),
    Typography(theme),
    Pagination(theme),
    Dialog(theme),
    Popover(theme),
    Tabs(theme),
    Tooltip(theme),
    Skeleton(theme),
    TextField(theme)
  );
}
