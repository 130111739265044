import { FormControl } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker, DateTimePickerProps } from '@mui/x-date-pickers/DateTimePicker';
import dayjs, { Dayjs } from 'dayjs';
import { FunctionComponent } from 'react';
import { Controller, useFormContext, ValidationRule } from 'react-hook-form';

interface IRHFDatePickerProps extends DateTimePickerProps<Dayjs> {
  name: string;
  rules?: { [key: string]: ValidationRule | ValidationRule[] };
}

const RHFDatePicker: FunctionComponent<IRHFDatePickerProps> = ({
  name,
  rules,
  minDate,
  maxDate,
  ...props
}) => {
  const { control } = useFormContext();

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <FormControl sx={{ width: '100%' }} component="fieldset">
        <Controller
          render={({ field }) => (
            <DateTimePicker
              sx={{ width: '100%' }}
              {...props}
              className="date-picker"
              minDate={minDate ? dayjs(minDate) : undefined}
              maxDate={maxDate ? dayjs(maxDate) : undefined}
              value={field.value ? dayjs(field.value) : null}
              onChange={(date) => field.onChange(date?.toISOString())}
            />
          )}
          rules={rules}
          name={name}
          control={control}
        />
      </FormControl>
    </LocalizationProvider>
  );
};

export default RHFDatePicker;
