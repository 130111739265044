import * as Yup from 'yup';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Card, Grid, Stack, TextField, Typography, useTheme } from '@mui/material';
import {
  CustomerAccount,
  NewCustomerAccount,
  UpdateCustomerAccount,
} from 'src/features/customer-accounts/types/customer-account';
import { FormProvider, RHFAutocomplete, RHFTextField } from 'src/components/minimals/form';
import { ROOT_PATH } from 'src/routes/config';
import { CountryType } from '../types/country-type';
import { CustomerService } from 'src/services';
import { formatUTCDateToLocal } from 'src/utilities';
import { useAuthentication } from 'src/features/authentication/context';
import { formFooter, lastEditedBox, cancelBtn } from './style';

type AddCustomerAccountFormProps = {
  handleConfirmationWindow: () => void;
  isEdit?: boolean;
  currentCustomer?: CustomerAccount;
};

export const CustomerAccountDetailsForm = ({
  handleConfirmationWindow,
  isEdit = false,
  currentCustomer,
}: AddCustomerAccountFormProps) => {
  const [lastEdited, setLastEdited] = useState('');
  const countries: CountryType[] = require('../../shared-data/countries.json');

  const { customerId } = useAuthentication();
  const allowedToEdit = customerId?.value && isEdit;
  const theme = useTheme();

  const CustomerAccountSchema = Yup.object().shape({
    customerName: Yup.string().required('Customer Name is required'),
    businessAddress: Yup.object().shape({
      companyName: Yup.string().notRequired(),
      street: Yup.string().notRequired(),
      city: Yup.string().notRequired(),
      country: Yup.string().notRequired(),
      state: Yup.string().notRequired(),
      zip: Yup.string().notRequired(),
    }),
  });

  const defaultValues = useMemo(
    () => ({
      customerName: currentCustomer?.customerName || '',
      companyName: currentCustomer?.companyName || '',
      businessAddress: {
        street: currentCustomer?.businessAddress?.street || '',
        city: currentCustomer?.businessAddress?.city || '',
        country: currentCustomer?.businessAddress?.country || countries[0].label,
        state: currentCustomer?.businessAddress?.state || '',
        zip: currentCustomer?.businessAddress?.zip || '',
      },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentCustomer]
  );

  const methods = useForm<CustomerAccount>({
    resolver: yupResolver(CustomerAccountSchema),
    defaultValues,
  });

  const {
    reset,
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  useEffect(() => {
    reset(defaultValues);
    setLastEdited(formatUTCDateToLocal(false, currentCustomer?.updatedAt)); // already in EST?
  }, [reset, defaultValues, currentCustomer, setLastEdited]);

  const createNew = async (data: NewCustomerAccount) => {
    try {
      await CustomerService.add(data);
      handleConfirmationWindow();
    } catch (error) {
      console.error(error);
    }
  };

  const updateExisting = async (data: UpdateCustomerAccount, id: string | null) => {
    if (!customerId.value) return;
    try {
      await CustomerService.edit({ ...data, id: customerId.value });
      handleConfirmationWindow();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <FormProvider
      methods={methods}
      onSubmit={handleSubmit((data) =>
        allowedToEdit ? updateExisting(data, customerId.value) : createNew(data)
      )}
    >
      <Grid container spacing={3} sx={{ my: '0px', mx: '0px', width: '100%', mb: '1rem' }}>
        <Card sx={{ p: 3, width: '100%', backgroundColor: theme.palette.background.default }}>
          <Box rowGap={3} columnGap={2} display="grid">
            <Grid item xs={12} md={12}>
              <RHFTextField
                name="customerName"
                label="Customer Name*"
                testId="customer-name-input"
                onBlur={(event) => setValue('customerName', event.target.value.trim())}
              />
              <Box component="div" sx={{ my: '24px' }}>
                <Typography variant="h5" component="h2">
                  Business Address
                </Typography>
              </Box>
            </Grid>
          </Box>
          <Grid item xs={12} md={12}>
            <Box
              rowGap={3}
              columnGap={2}
              display="grid"
              gridTemplateColumns={{
                xs: 'repeat(1, 1fr)',
                sm: 'repeat(2, 1fr)',
              }}
            >
              <RHFTextField name="companyName" label="Company Name" testId="company-name-input" />
              <RHFTextField name="businessAddress.street" label="Street" testId="street-input" />
              <RHFTextField name="businessAddress.city" label="City" testId="city-input" />
              <RHFAutocomplete
                name="businessAddress.country"
                options={countries.map((country) => country.label)}
                onChange={(event, newValue) =>
                  newValue === null
                    ? setValue('businessAddress.country', '')
                    : setValue('businessAddress.country', newValue as string)
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Countries"
                    inputProps={{
                      ...params.inputProps,
                      'data-sm': 'country-input',
                    }}
                  />
                )}
              />

              <RHFTextField name="businessAddress.state" label="State" testId="state-input" />
              <RHFTextField name="businessAddress.zip" label="Zip" testId="zip-code-input" />
            </Box>

            <Stack justifyContent="flex-end" flexDirection={'row'} sx={formFooter}>
              {isEdit && currentCustomer ? (
                <Box component="div" sx={lastEditedBox}>
                  <Typography variant="body2" color="text.secondary">
                    Last edited on: &nbsp;
                  </Typography>
                  <Typography variant="subtitle2" color="text.secondary">
                    {lastEdited}
                  </Typography>
                </Box>
              ) : null}
              <Button
                to={ROOT_PATH}
                component={Link}
                variant="outlined"
                sx={cancelBtn}
                data-sm="cancel-button"
              >
                Cancel
              </Button>
              <LoadingButton
                type="submit"
                variant="contained"
                loading={isSubmitting}
                data-sm={isEdit ? 'save-changes-button' : 'add-account-button'}
              >
                {!isEdit ? 'Add Account' : 'Save Changes'}
              </LoadingButton>
            </Stack>
          </Grid>
        </Card>
      </Grid>
    </FormProvider>
  );
};
