import {
  Button,
  CardContent,
  DialogActions,
  DialogTitle,
  Divider,
  FormControlLabel,
  Stack,
  Switch,
  Table,
  TableBody,
  TableContainer,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material';
import { ArrowTopRightIcon } from 'src/assets';
import { Label } from 'src/components';
import {
  AlarmPopupCard,
  AlarmPopupDialog,
  DialogContentWithCustomScroll,
  TableContainerWithCustomScroll,
} from '../style';
import { useState } from 'react';
import {
  formatSecondsToDhms,
  formatUTCDateToLocal,
  unixTimestampToDateTime,
  upperSnakeCaseToTitleCase,
} from 'src/utilities';
import { MobileTooltipRichLabel } from './MobileTooltipRichLabel';
import { Notification, PopupProps } from '../../types';
import { SITES_DASHBOARD_PATH } from 'src/routes/config';
import { SitesService } from 'src/services/sites';
import { useSite } from 'src/contexts/siteContext/sites.context';
import { useNavigate } from 'react-router-dom';
import { SiteInterface } from 'src/features/sites/types/site';
import { getPriorityColor, renderTitleIcon } from '../../utils';
import {
  AlarmConditionsStack,
  DetailsCell,
  HeaderStack,
  NotificationsCellLabel,
  NotificationsCellValue,
} from './style';
import { useAuthentication } from 'src/features/authentication/context';
import { AlarmsService } from 'src/services';

export const MobileAlarmPopUp = ({
  open,
  onClose,
  customerId,
  siteId,
  alarmData,
  setAlarmData,
  alarmRule,
  notifications = [],
  setRefreshTrigger,
  onAcknowledged,
  onAcknowledgedChange,
}: PopupProps) => {
  const theme = useTheme();
  const { saveSite } = useSite();
  const navigate = useNavigate();
  const [acknowledged, setAcknowledged] = useState<boolean>(false);
  const { idToken } = useAuthentication();

  const onAcknowledgeAndClose = () => {
    AlarmsService.acknowledgeAlarm(customerId, siteId, alarmData.id, idToken)
      .then((response) => {
        setRefreshTrigger(true);
        onAcknowledgedChange(alarmData.id);

        onAcknowledged && onAcknowledged(response);
        setAlarmData((prevData) => {
          if (prevData.value === null) {
            return prevData;
          }
          return {
            ...prevData,
            value: prevData.value.map((item) => {
              if (item.id === alarmData.id) {
                return {
                  ...item,
                  acknowledgment: response.acknowledgment,
                };
              }
              return item;
            }),
          };
        });
      })
      .catch((error) => {
        console.error(error);
      });
    setAcknowledged(false);
    onClose();
  };

  const handleGoToSiteClick = () => {
    SitesService.getById({ customerId: customerId, siteId: siteId })
      .then((data: SiteInterface) => {
        saveSite(data);
      })
      .catch((error) => {
        console.error(error);
      });
    navigate(SITES_DASHBOARD_PATH + `?customerId=${customerId}&siteId=${siteId}`);
  };

  return (
    <AlarmPopupDialog open={open} onClose={onClose} maxWidth="lg">
      <DialogTitle sx={{ backgroundColor: theme.palette.background.default }}>
        <Stack flexDirection={'column'}>
          <HeaderStack>
            <Stack flexDirection={'row'} alignItems={'center'}>
              {renderTitleIcon(alarmData, theme)}
              <Typography variant="h6" sx={{ ml: '16px', mr: '8px' }}>
                Alarm - {upperSnakeCaseToTitleCase(alarmData?.priority || '')}
              </Typography>
            </Stack>
            <Label
              variant="filled"
              color={getPriorityColor(alarmData?.priority, alarmData?.eventStatus)}
            >
              {alarmData?.eventStatus}
            </Label>
          </HeaderStack>
          <Button variant="outlined" endIcon={<ArrowTopRightIcon />} onClick={handleGoToSiteClick}>
            Go to Site
          </Button>
        </Stack>
      </DialogTitle>
      <Divider />
      <DialogContentWithCustomScroll sx={{ backgroundColor: theme.palette.background.default }}>
        <AlarmPopupCard>
          <CardContent sx={{ p: '16px!important' }}>
            <Typography variant="h6">Site Details:</Typography>
            <TableContainer sx={{ mt: '12px', overflow: 'hidden' }}>
              <Table>
                <TableBody>
                  <TableRow>
                    <DetailsCell align="left">Site Name:</DetailsCell>
                  </TableRow>
                  <TableRow>
                    <DetailsCell align="left">
                      <strong>{alarmData?.siteName}</strong>
                    </DetailsCell>
                  </TableRow>

                  <TableRow>
                    <DetailsCell align="left">Asset:</DetailsCell>
                  </TableRow>
                  <TableRow>
                    <DetailsCell align="left">
                      <strong>{alarmData?.asset?.name ?? '-'}</strong>
                    </DetailsCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </CardContent>
        </AlarmPopupCard>

        <AlarmPopupCard>
          <CardContent sx={{ p: '16px!important' }}>
            <Typography variant="h6">Event Details:</Typography>
            <TableContainer sx={{ mt: '12px' }}>
              <Table>
                <TableBody>
                  <TableRow>
                    <DetailsCell align="left">Alarm Title:</DetailsCell>
                  </TableRow>
                  <TableRow>
                    <DetailsCell align="left">
                      <strong>{alarmData?.title}</strong>
                    </DetailsCell>
                  </TableRow>

                  <TableRow>
                    <DetailsCell align="left">Start (date / time):</DetailsCell>
                  </TableRow>
                  <TableRow>
                    <DetailsCell align="left">
                      <strong>
                        {alarmData?.startDate !== null
                          ? formatUTCDateToLocal(false, alarmData?.startDate)
                          : '-'}
                      </strong>
                    </DetailsCell>
                  </TableRow>

                  <TableRow>
                    <DetailsCell align="left">End (date / time):</DetailsCell>
                  </TableRow>
                  <TableRow>
                    <DetailsCell align="left">
                      <strong>
                        {alarmData?.endDate !== null
                          ? formatUTCDateToLocal(false, alarmData?.endDate)
                          : '-'}
                      </strong>
                    </DetailsCell>
                  </TableRow>

                  <TableRow>
                    <DetailsCell align="left">Duration:</DetailsCell>
                  </TableRow>
                  <TableRow>
                    <DetailsCell align="left">
                      <strong>
                        {+alarmData.durationInSeconds > 0
                          ? formatSecondsToDhms(+alarmData.durationInSeconds)
                          : '-'}
                      </strong>
                    </DetailsCell>
                  </TableRow>

                  <TableRow>
                    <DetailsCell align="left">Description:</DetailsCell>
                  </TableRow>
                  <TableRow>
                    <DetailsCell align="left">
                      <strong>{alarmData?.description ?? '-'}</strong>
                    </DetailsCell>
                  </TableRow>

                  <TableRow>
                    <DetailsCell align="left">
                      <FormControlLabel
                        value="end"
                        control={
                          <Switch
                            checked={alarmData?.acknowledgment ? true : acknowledged}
                            disabled={!!alarmData?.acknowledgment}
                            color="primary"
                            onChange={(event, checked) => {
                              setAcknowledged(checked);
                            }}
                          />
                        }
                        label={
                          !!alarmData?.acknowledgment || acknowledged
                            ? 'Acknowledged'
                            : 'Acknowledge'
                        }
                        labelPlacement="end"
                      />
                    </DetailsCell>
                  </TableRow>
                  {alarmData?.acknowledgment ? (
                    <TableRow>
                      <DetailsCell align="left" sx={{ verticalAlign: 'inherit' }}>
                        Acknowledged by:{' '}
                        <strong>
                          {alarmData?.acknowledgment?.firstName}{' '}
                          {alarmData?.acknowledgment?.lastName},{' '}
                          {unixTimestampToDateTime(+alarmData?.acknowledgment?.timestamp)}
                        </strong>
                      </DetailsCell>
                    </TableRow>
                  ) : null}
                </TableBody>
              </Table>
            </TableContainer>
          </CardContent>
        </AlarmPopupCard>

        <AlarmPopupCard>
          <CardContent sx={{ p: '16px!important' }}>
            <Typography variant="h6">Notifications:</Typography>
            <TableContainerWithCustomScroll sx={{ mt: '12px', maxHeight: '125px' }}>
              <Table>
                <TableBody>
                  {notifications.map((notification: Notification) => (
                    <TableRow key={notification?.id ?? notification?.createdAt}>
                      <NotificationsCellLabel align="left">
                        <strong>{formatUTCDateToLocal(false, notification?.createdAt)}</strong>
                      </NotificationsCellLabel>
                      <NotificationsCellValue align="left">
                        <strong>{notification?.firstName + ' ' + notification?.lastName}</strong>
                      </NotificationsCellValue>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainerWithCustomScroll>
          </CardContent>
        </AlarmPopupCard>

        <AlarmPopupCard>
          <CardContent sx={{ p: '16px!important' }}>
            <Typography variant="h6">Alarm Conditions</Typography>

            <AlarmConditionsStack>
              <Typography sx={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
                Low Limit:
              </Typography>
              <Typography>
                <strong>
                  {alarmRule?.lowLimit
                    ? `${alarmRule?.lowLimit} ${alarmRule?.tag?.tagRawUnit}`
                    : '-'}
                </strong>
              </Typography>
            </AlarmConditionsStack>

            <AlarmConditionsStack>
              <Typography sx={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
                High Limit:
              </Typography>
              <Typography>
                <strong>
                  {alarmRule?.highLimit
                    ? `${alarmRule?.highLimit} ${alarmRule?.tag?.tagRawUnit}`
                    : '-'}
                </strong>
              </Typography>
            </AlarmConditionsStack>

            <AlarmConditionsStack>
              <Typography sx={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
                {alarmData?.mainTag?.name}
              </Typography>

              <MobileTooltipRichLabel>{alarmData?.gateway?.name}</MobileTooltipRichLabel>

              <Typography>
                <strong>
                  {alarmData?.mainTag?.value} {alarmRule?.tag?.tagRawUnit}
                </strong>
              </Typography>
            </AlarmConditionsStack>

            {alarmRule?.additionalTag ? (
              <AlarmConditionsStack>
                <Typography sx={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
                  {alarmRule?.additionalTag?.additionalTagName}
                </Typography>

                <MobileTooltipRichLabel>{alarmData?.gateway?.name}</MobileTooltipRichLabel>

                <Typography>
                  <strong>
                    {alarmRule?.additionalTag?.value}{' '}
                    {alarmRule?.additionalTag?.additionalTagRawUnit}
                  </strong>
                </Typography>
              </AlarmConditionsStack>
            ) : null}

            <AlarmConditionsStack>
              <Typography sx={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
                Notification Delay
              </Typography>

              <Typography>
                <strong>{alarmRule?.notificationDelay}s</strong>
              </Typography>
            </AlarmConditionsStack>
          </CardContent>
        </AlarmPopupCard>
      </DialogContentWithCustomScroll>
      <Divider />
      <DialogActions sx={{ backgroundColor: theme.palette.background.default }}>
        <Stack flexDirection={'column'} sx={{ width: '100%' }}>
          <Typography variant="body2">Alarm ID: {alarmData.id}</Typography>
          {acknowledged ? (
            <Button onClick={onAcknowledgeAndClose} variant="outlined" fullWidth sx={{ mt: '8px' }}>
              Acknowledge & Close
            </Button>
          ) : (
            <Button onClick={onClose} variant="outlined" fullWidth sx={{ mt: '8px' }}>
              Close
            </Button>
          )}
        </Stack>
      </DialogActions>
    </AlarmPopupDialog>
  );
};
