import { IconsTypesProps } from './types';
import { CURRENT_COLOR } from 'src/config';

export const InfoIcon = ({ fill }: IconsTypesProps) => {
  const color = fill ?? CURRENT_COLOR;

  return (
    <svg width="21" height="20" fill={color} viewBox="0 0 21 20" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.0117 0C4.49172 0 0.0117188 4.48 0.0117188 10C0.0117188 15.52 4.49172 20 10.0117 20C15.5317 20 20.0117 15.52 20.0117 10C20.0117 4.48 15.5317 0 10.0117 0ZM10.0117 11C9.46172 11 9.01172 10.55 9.01172 10V6C9.01172 5.45 9.46172 5 10.0117 5C10.5617 5 11.0117 5.45 11.0117 6V10C11.0117 10.55 10.5617 11 10.0117 11ZM11.0117 15H9.01172V13H11.0117V15Z" />
    </svg>
  );
};
