import { Container, Dialog } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import {
  CONFIRMATION_MODAL_HEADING_EDIT,
  CONFIRMATION_MODAL_DESCRIPTION_EDIT,
  BREADCRUMBS_ROOT,
  BREADCRUMBS_EDIT_CUSTOMER,
} from '../config';
import { CustomerAccountDetailsForm } from '../components/CustomerAccountDetailsForm';
import { CheckmarkCircleFilledLargeIcon, CloseIcon } from 'src/assets';
import { ConfirmationModal, CustomBreadcrumbs } from 'src/components';
import { useDocumentTitle, useToggle } from 'src/hooks';
import { ROOT_PATH } from 'src/routes/config';
import {
  BusinessAddress,
  CustomerAccount,
} from 'src/features/customer-accounts/types/customer-account';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { CustomerService } from 'src/services';
import { useAuthentication } from 'src/features/authentication/context';

export const EditCustomerAccount = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { state, onToggle } = useToggle();
  const { customerId } = useAuthentication();
  const [currentCustomer, setCurrentCustomer] = useState<CustomerAccount>(
    new CustomerAccount('', '', '', '', '', '', new BusinessAddress())
  );

  const location = useLocation();

  useDocumentTitle('Streametric - Edit Customer Account');

  useEffect(() => {
    if (!customerId.value) return;
    CustomerService.getById(customerId.value)
      .then((data: CustomerAccount) => {
        setCurrentCustomer(data);
      })
      .catch((error) => console.error(error));
  }, [location.search, customerId.value]);

  const primaryMainColor = theme.palette.primary.main;
  const greyBoldColor = theme.palette.grey[700];

  const handleRedirect = () => {
    navigate(ROOT_PATH);
  };

  const onCloseDialog = () => {
    onToggle();
    handleRedirect();
  };

  return (
    <Container maxWidth={false} disableGutters>
      <Dialog onClose={onCloseDialog} open={state}>
        <ConfirmationModal
          onClose={onCloseDialog}
          state={state}
          mainIcon={<CheckmarkCircleFilledLargeIcon fill={primaryMainColor} />}
          closeIcon={<CloseIcon fill={greyBoldColor} />}
          heading={CONFIRMATION_MODAL_HEADING_EDIT}
          headingVariant="h6"
          description={CONFIRMATION_MODAL_DESCRIPTION_EDIT}
          descriptionVariant="body1"
        />
      </Dialog>
      <CustomBreadcrumbs
        links={[
          {
            name: BREADCRUMBS_ROOT,
            href: ROOT_PATH,
          },
          {
            name: BREADCRUMBS_EDIT_CUSTOMER,
          },
        ]}
        sx={{ my: '35px' }}
        testId="customer-account-breadcrumb"
      />
      <CustomerAccountDetailsForm
        handleConfirmationWindow={onToggle}
        isEdit={true}
        currentCustomer={currentCustomer}
      />
    </Container>
  );
};

export default EditCustomerAccount;
