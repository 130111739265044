import { CURRENT_COLOR } from 'src/config';
import { IconsTypesProps } from './types';

export const SimpleCheckmarkIcon = ({ fill, width = 22, height = 22 }: IconsTypesProps) => {
  const color = fill ?? CURRENT_COLOR;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.03671 16.5002C8.78264 16.4994 8.5403 16.3931 8.36754 16.2069L3.91254 11.4677C3.56575 11.0981 3.58422 10.5174 3.95379 10.1706C4.32336 9.82382 4.90408 9.84228 5.25087 10.2119L9.02754 14.236L16.7367 5.80269C16.9499 5.53724 17.2926 5.41179 17.6268 5.47691C17.9609 5.54203 18.2315 5.78699 18.3294 6.11306C18.4273 6.43914 18.3364 6.79261 18.0934 7.03102L9.71504 16.1977C9.54388 16.3874 9.30134 16.497 9.04587 16.5002H9.03671Z"
        fill={color}
      />
    </svg>
  );
};
