import { useEffect, useState } from 'react';
import { Box, Button, Popover, Typography, useTheme } from '@mui/material';
import { SelectedTimeframeOption } from '../selected-timeframe-option';
import { TimeFrameBarOptions } from '../timeframe-bar-options';
import { DatePickerTimeframe } from '../date-picker-timeframe';
import { StyledArrow } from './style';
import getPosition from 'src/components/minimals/menu-popover/getPosition';
import { TimeOptionType } from '../../types';
import dayjs from 'dayjs';
import { subDays } from 'date-fns';
import { SessionStorageService } from 'src/features/authentication/utils';
import { useResponsive } from 'src/hooks';

type Props = {
  timestamps: any;
  onSelectTimestampHandler?: (days: number) => void;
  selectTabletTimeOptionHandler: (timeOption: TimeOptionType) => void;
  setTimestamps: (timestamps: any) => void;
  sessionStorageKey: string;
  onResetZoomHandler?: () => void;
  selectedBox: TimeOptionType;
  changeTimeFormatHandler: (fromDate: Date, toDate: Date) => void;
};

export const TimeframePopover = ({
  timestamps,
  onSelectTimestampHandler,
  selectedBox,
  selectTabletTimeOptionHandler,
  setTimestamps,
  sessionStorageKey,
  onResetZoomHandler,
  changeTimeFormatHandler,
}: Props) => {
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const theme = useTheme();

  const [temporarySelectedTabletTimeOption, setTemporaySelectedTabletTimeOption] =
    useState<TimeOptionType>(selectedBox);
  const isMobile = useResponsive('down', 'md');

  const [temporaryTimestamps, setTemporaryTimestamps] = useState<any>({
    fromDate: timestamps.fromDate,
    toDate: timestamps.toDate,
  });

  useEffect(() => {
    setTemporaryTimestamps({
      fromDate: timestamps.fromDate,
      toDate: timestamps.toDate,
    });
  }, [timestamps]);

  const onChangeTemporaryTimestapHandler = (date: 'fromDate' | 'toDate', value: any) => {
    setTemporaryTimestamps((prevState: any) => ({ ...prevState, [date]: value }));
  };

  const onTemporarySelectTabletTimeOption = (timeOption: TimeOptionType) => {
    setTemporaySelectedTabletTimeOption(timeOption);
  };

  const open = Boolean(anchorEl);
  const popoverId = open ? 'simple-popover' : undefined;

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setTemporaySelectedTabletTimeOption(selectedBox);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onCancelHandler = () => {
    handleClose();

    setTemporaySelectedTabletTimeOption(selectedBox);
    setTemporaryTimestamps(timestamps);
  };

  const { style } = getPosition('top-right');

  const getDays = () => {
    if (temporarySelectedTabletTimeOption === '1d') return 1;
    if (temporarySelectedTabletTimeOption === '7d') return 7;
    if (temporarySelectedTabletTimeOption === '30d') return 30;
    return 0;
  };

  const setCustomTimestamps = () => {
    setTimestamps(temporaryTimestamps);
    SessionStorageService.set(sessionStorageKey, JSON.stringify(temporaryTimestamps));
  };

  const onApplyHandler = () => {
    selectTabletTimeOptionHandler(temporarySelectedTabletTimeOption);
    temporarySelectedTabletTimeOption === 'custom'
      ? setCustomTimestamps()
      : onSelectTimestampHandler && onSelectTimestampHandler(getDays());
    handleClose();
    if (onResetZoomHandler) onResetZoomHandler();
    changeTimeFormatHandler(temporaryTimestamps.fromDate, temporaryTimestamps.toDate);
    if (onResetZoomHandler) onResetZoomHandler();
  };

  const onSelectTemporaryTimestampHandler = (days: number) => {
    const now = new Date();
    setTemporaryTimestamps(() => {
      const newState = {
        fromDate: dayjs(subDays(now, days)),
        toDate: dayjs(now),
      };

      return newState;
    });
  };

  return (
    <Box>
      <SelectedTimeframeOption
        aria-describedby={popoverId}
        selectedTimeOption={selectedBox}
        onClick={handleClick}
      />
      <Popover
        id={popoverId}
        open={open}
        anchorEl={anchorEl}
        onClose={onCancelHandler}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        sx={{
          '.MuiPopover-paper': {
            left: isMobile ? '27px !important' : '303px !important ',
            backgroundColor: theme.palette.background.default,
          },
        }}
        PaperProps={{
          sx: {
            p: 1,
            width: 'auto',
            overflow: 'inherit',
            ...style,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
              '& svg': { mr: 2, width: 20, height: 20, flexShrink: 0 },
            },
          },
        }}
      >
        <Box sx={{ padding: '24px', maxWidth: '100%' }}>
          <StyledArrow arrow={'top-left'} />
          <Typography variant="h6" sx={{ mb: '24px' }}>
            Select Date Range
          </Typography>
          <TimeFrameBarOptions
            onSelectTimestampHandler={onSelectTimestampHandler}
            onTemporarySelectTabletTimeOption={onTemporarySelectTabletTimeOption}
            temporarySelectedTabletTimeOption={temporarySelectedTabletTimeOption}
            isTablet={true}
            onSelectTemporaryTimestampHandler={onSelectTemporaryTimestampHandler}
          />
          <Typography variant="body1" sx={{ margin: '16px 0' }}>
            Use custom range
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
            <DatePickerTimeframe
              label="From"
              timestamp={temporaryTimestamps.fromDate}
              type="fromDate"
              isTablet={true}
              onTemporarySelectTabletTimeOption={onTemporarySelectTabletTimeOption}
              onChangeTemporaryTimestapHandler={onChangeTemporaryTimestapHandler}
              timestamps={temporaryTimestamps}
            />
            <DatePickerTimeframe
              label="To"
              timestamp={temporaryTimestamps.toDate}
              type="toDate"
              isTablet={true}
              onTemporarySelectTabletTimeOption={onTemporarySelectTabletTimeOption}
              onChangeTemporaryTimestapHandler={onChangeTemporaryTimestapHandler}
              timestamps={temporaryTimestamps}
            />
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                gap: '12px',
                marginTop: '12px',
              }}
            >
              <Button variant="outlined" data-sm="cancel-button" onClick={onCancelHandler}>
                Cancel
              </Button>
              <Button variant="contained" data-sm="cancel-button" onClick={onApplyHandler}>
                Done
              </Button>
            </Box>
          </Box>
        </Box>
      </Popover>
    </Box>
  );
};
