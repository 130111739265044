import { Stack, Typography, useTheme } from '@mui/material';
import { InfoIconV2 } from 'src/assets';
import { LightTooltip } from 'src/components';

type SimpleTooltipProps = {
  content: string;
};
export const SimpleTooltip = ({ content }: SimpleTooltipProps) => {
  const theme = useTheme();

  return (
    <LightTooltip
      placement="left-start"
      arrow
      enterTouchDelay={0}
      title={
        <Typography
          variant="body1"
          sx={{
            fontWeight: 400,
            fontSize: '14px',
            whiteSpace: 'pre-wrap',
            wordWrap: 'break-word',
            backgroundColor: theme.palette.background.default,
          }}
        >
          {content}
        </Typography>
      }
    >
      <Stack justifyContent="center" alignItems="center">
        <InfoIconV2 />
      </Stack>
    </LightTooltip>
  );
};
