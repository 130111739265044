import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import LineChart from 'src/components/streametric/lineChart/LineChart';
import { PointChartType } from '../../types/point-chart';
import { SelectedInputType } from '../../types/selected-input';
import { useChartData, useChartOptions } from '../../hooks';
import { ChartWrapper, CustomWaterLoading } from 'src/components';
import { differenceInDays, parseISO, subDays } from 'date-fns';
import dayjs from 'dayjs';
import { TimeOptionType, TimestampsTypes } from '../../types';
import { ChartData } from 'src/components/streametric/lineChart/types';
import { Box } from '@mui/material';
import { SessionStorageService } from 'src/features/authentication/utils';
import { loaderBox } from './style';
import { GetAllResponse } from 'src/services/sites';
import { getSeries, getTimestampsBasedOnTimeBox } from './utils';
import { debounce } from 'lodash';
import { generateSimilarColorShades, getRandomColor } from 'src/utilities';
import { useResponsive } from 'src/hooks';

type Props = {
  pointChart: PointChartType;
  removeChart: (chartId: string) => void;
  onSelectEventHandler?: (selectedInputs: SelectedInputType[], id: string) => void;
  sites?: { value: GetAllResponse[]; loaded: boolean };
  syncTimestamps?: TimestampsTypes;
  syncSelectedBox?: TimeOptionType;
  isLocalTrends?: boolean;
};

export const LineChartTrend = ({
  pointChart,
  removeChart,
  onSelectEventHandler,
  sites,
  isLocalTrends,
}: Props) => {
  const [isSyncTimeframe, setIsSyncTimeframe] = useState<boolean>(false);
  const isMobile = useResponsive('down', 'md');

  const toggleSyncTimeframe = () => {
    setIsSyncTimeframe((prevState) => !prevState);
  };

  const sessionStorageKey = `${pointChart.id}-timeframe`;
  const selectedBoxSessionStorageKey = `${pointChart.id}-box`;

  const [selectedBox, setSelectedBox] = useState<TimeOptionType>(() => {
    const sessionStorageSelectedBox = SessionStorageService.getRaw(selectedBoxSessionStorageKey);

    return sessionStorageSelectedBox ? (sessionStorageSelectedBox as TimeOptionType) : '1d';
  });

  const [timestamps, setTimestamps] = useState<any>(() => {
    const timestamps = {
      fromDate: dayjs(subDays(new Date(), 1)),
      toDate: dayjs(new Date()),
    };
    const sessionStorageTimestamps = SessionStorageService.getParsed(sessionStorageKey);
    const sessionStorage = {
      fromDate: dayjs(new Date(sessionStorageTimestamps.fromDate)),
      toDate: dayjs(new Date(sessionStorageTimestamps.toDate)),
    };

    if (selectedBox === 'custom' && sessionStorageTimestamps) {
      return sessionStorage;
    }
    if (selectedBox !== 'custom' && sessionStorageTimestamps) {
      const newDate = getTimestampsBasedOnTimeBox(selectedBox);
      return newDate;
    }
    return timestamps;
  });

  const selectedTags = pointChart?.selectedInputs;

  const selectTabletTimeOptionHandler = (timeOption: TimeOptionType) => {
    setSelectedBox(timeOption);
    SessionStorageService.set(selectedBoxSessionStorageKey, timeOption);
  };

  const startDate =
    selectedBox === 'custom'
      ? new Date(timestamps.fromDate.toDate().setHours(0, 0, 0, 0)).toISOString()
      : timestamps.fromDate.toISOString();
  const endDate =
    selectedBox === 'custom'
      ? new Date(timestamps.toDate.toDate().setHours(23, 59, 59, 999)).toISOString()
      : timestamps.toDate.toISOString();

  const start = parseISO(startDate);
  const end = parseISO(endDate);

  const difference = differenceInDays(end, start);
  const isSevenDaysOrLess = difference < 7;

  let aggregationTypePayload: 'two_hour' | 'five_min' | 'thirty_min' | null = null;
  if (selectedBox === '1d' || difference < 3) {
    aggregationTypePayload = null;
  } else if (isSevenDaysOrLess || selectedBox === '7d') {
    aggregationTypePayload = 'five_min';
  } else {
    aggregationTypePayload = 'two_hour';
  }

  const onChangeTimestapHandler = (values: [Date, Date]) => {
    setTimestamps(() => {
      const newState = { fromDate: dayjs(values[0]), toDate: dayjs(values[1]) };

      SessionStorageService.set(sessionStorageKey, JSON.stringify(newState));
      return newState;
    });
  };

  const onSelectTimestampHandler = (days: number) => {
    const now = new Date();
    setTimestamps(() => {
      const newState = {
        fromDate: dayjs(subDays(now, days)),
        toDate: dayjs(now),
      };
      SessionStorageService.set(sessionStorageKey, JSON.stringify(newState));
      return newState;
    });
  };

  const { data, isLoading } = useChartData({
    timestamps: timestamps,
    selectedTags: selectedTags,
    startDate: startDate,
    endDate: endDate,
    aggregationTypePayload: aggregationTypePayload,
  });

  const timestampsRef = useRef(timestamps);
  useEffect(() => {
    timestampsRef.current = timestamps;
  }, [timestamps]);

  const extendTimeframeOnZoomOutOrPan = useCallback((min: string, max: string) => {
    const formatedMin = dayjs(new Date(min));
    const formatedMax = dayjs(new Date(max));
    const currentTimestamps = timestampsRef.current;
    if (
      formatedMin.isBefore(currentTimestamps.fromDate) ||
      formatedMax.isAfter(currentTimestamps.toDate)
    ) {
      setTimestamps({ fromDate: formatedMin, toDate: formatedMax });
      setSelectedBox('custom');
    }
  }, []);

  const debouncedExtendTimeframeOnZoomOutOrPan = useRef(
    debounce(extendTimeframeOnZoomOutOrPan, 800)
  ).current;

  const allSeries: ChartData[] = useMemo(
    () => getSeries(data, pointChart.selectedInputs), //Add checking for the local site trends
    [data, pointChart]
  );

  const colors = ['#32DFB6', '#7E5CC6', '#FC8B8B', '#F5CB35'];
  const randomColor = getRandomColor(colors);
  const similarShades = generateSimilarColorShades(randomColor, allSeries.length - colors.length);
  const extendedColors = [...colors, ...similarShades];

  const toDateCustom = useMemo(
    () =>
      new Date(
        dayjs(timestamps.toDate)
          .set('hour', 23)
          .set('minute', 59)
          .set('second', 59)
          .set('millisecond', 0)
          .toISOString()
      ),
    [timestamps.toDate]
  );

  const {
    chartRef,
    onResetZoomHandler,
    zoomInHandler,
    zoomOutHandler,
    isPanAndZoomActive,
    togglePanAndZoom,
    options,
    changeTimeFormatHandler,
    toggleModeZoomOption,
    activeZoomMode,
  } = useChartOptions({
    fromDate: timestamps.fromDate,
    toDate: selectedBox === 'custom' ? toDateCustom : timestamps.toDate,
    debouncedExtendTimeframeOnZoomOutOrPan: debouncedExtendTimeframeOnZoomOutOrPan,
    tags: selectedTags,
    colors: extendedColors,
    isMobile: isMobile,
  });

  const onResetTimestamp = () => {
    setTimestamps(() => {
      const timestamps = {
        fromDate: dayjs(subDays(new Date(), 1)),
        toDate: dayjs(new Date()),
      };
      const sessionStorageTimestamps = SessionStorageService.getParsed(sessionStorageKey);
      const sessionStorage = {
        fromDate: dayjs(new Date(sessionStorageTimestamps.fromDate)),
        toDate: dayjs(new Date(sessionStorageTimestamps.toDate)),
      };

      if (selectedBox === 'custom' && sessionStorageTimestamps) {
        return sessionStorage;
      }
      if (selectedBox !== 'custom' && sessionStorageTimestamps) {
        const newDate = getTimestampsBasedOnTimeBox(selectedBox);
        return newDate;
      }
      return timestamps;
    });
    setSelectedBox(() => {
      const sessionStorageSelectedBox = SessionStorageService.getRaw(selectedBoxSessionStorageKey);
      return sessionStorageSelectedBox ? (sessionStorageSelectedBox as TimeOptionType) : '1d';
    });
  };

  return (
    <ChartWrapper
      zoomOutHandler={zoomOutHandler}
      zoomInHandler={zoomInHandler}
      onResetZoomHandler={onResetZoomHandler}
      onResetTimestamp={onResetTimestamp}
      isPanAndZoomActive={isPanAndZoomActive}
      togglePanAndZoom={togglePanAndZoom}
      removeChart={removeChart}
      id={pointChart.id}
      timestamps={timestamps}
      onChangeTimestapHandler={onChangeTimestapHandler}
      onSelectTimestampHandler={onSelectTimestampHandler}
      selectTabletTimeOptionHandler={selectTabletTimeOptionHandler}
      setTimestamps={setTimestamps}
      sessionStorageKey={sessionStorageKey}
      selectedBoxSessionStorageKey={selectedBoxSessionStorageKey}
      sites={sites}
      onSelectEventHandler={onSelectEventHandler}
      pointChartData={allSeries}
      selectedBox={selectedBox}
      setSelectedBox={setSelectedBox}
      isSyncTimeframe={isSyncTimeframe}
      toggleSyncTimeframe={toggleSyncTimeframe}
      changeTimeFormatHandler={changeTimeFormatHandler}
      toggleModeZoomOption={toggleModeZoomOption}
      activeZoomMode={activeZoomMode}
      isLocalTrends={isLocalTrends}
    >
      {isLoading ? (
        <Box sx={loaderBox}>
          <CustomWaterLoading />
        </Box>
      ) : null}
      <Box sx={{ opacity: isLoading ? 0 : 1 }}>
        <LineChart
          chartRef={chartRef}
          key={pointChart.id}
          options={options}
          allSeries={allSeries}
          extendedColors={extendedColors}
        />
      </Box>
    </ChartWrapper>
  );
};
