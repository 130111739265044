import { useState, useEffect } from 'react';
import { AlarmsService } from 'src/services';
import { AlarmData } from 'src/features/alarms';
import { useAuthentication } from 'src/features/authentication/context';

type useAlarmEventsArgumentsTypes = {
  selectedSiteIds: string[];
  date: { fromDate: string; toDate: string };
};

export const useAlarmEvents = ({ selectedSiteIds, date }: useAlarmEventsArgumentsTypes) => {
  const [alarms, setAlarms] = useState<AlarmData[]>([]);
  const {
    customerId: { value: customerId },
  } = useAuthentication();
  useEffect(() => {
    if (!customerId || !date.fromDate) return;
    selectedSiteIds.forEach((siteId: string) => {
      AlarmsService.getAllBySiteId(customerId, siteId, date.fromDate, date.toDate)
        .then((data) => {
          setAlarms((prevState) => [...prevState, ...data]);
        })
        .catch((error) => console.error(error));
    });

    return () => {
      setAlarms([]);
    };
  }, [selectedSiteIds, date, customerId]);

  return alarms;
};
