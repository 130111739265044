import { Theme, styled } from '@mui/material/styles';
import { Button } from '@mui/material';

type StylesTypes = {
  theme: Theme;
};

const mobileSiteContainer = {
  paddingBottom: '58px',
};

const mobileSiteList = {
  marginTop: '16px',
  marginBottom: '40px',
};

const numOfItemsBox = {
  display: 'flex',
  justifyContent: 'center',
};

const StyledLoadMoreButton = styled(Button)(({ theme }: StylesTypes) => ({
  width: '327px',
  marginTop: '24px',
  color: theme.palette.common.white,
  background: theme.palette.primary.main,
}));

export { mobileSiteContainer, mobileSiteList, numOfItemsBox, StyledLoadMoreButton };
