import { IconsTypesProps } from './types';
import { CURRENT_COLOR } from 'src/config';

export const PlusFillIcon = ({ fill, width = 15, height = 15 }: IconsTypesProps) => {
  const color = fill ?? CURRENT_COLOR;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24">
      <g id="evaPlusFill0">
        <g id="evaPlusFill1">
          <path
            id="evaPlusFill2"
            fill={color}
            d="M19 11h-6V5a1 1 0 0 0-2 0v6H5a1 1 0 0 0 0 2h6v6a1 1 0 0 0 2 0v-6h6a1 1 0 0 0 0-2Z"
          />
        </g>
      </g>
    </svg>
  );
};
