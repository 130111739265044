import { FunctionComponent, isValidElement } from 'react';
import { styled, SvgIcon, Typography, useTheme } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { useResponsive } from 'src/hooks';
import { StyledNavigationItem } from './style';
import { NavigationItemType } from './types';

const ActiveDiv = styled('div')(({ theme }) => ({
  position: 'absolute',
  bottom: 0,
  [theme.breakpoints.down('md')]: {
    left: '50%',
    transform: 'translateX(-50%)',
    width: '60%',
  },
  width: '100%',
  height: 3,
  backgroundColor: theme.palette.primary.main,
  borderRadius: 16,
}));

const NavigationItem: FunctionComponent<
  Omit<NavigationItemType, 'label'> & {
    isActive: boolean;
    label: string | JSX.Element;
  }
> = ({ path, label, icon: Icon, isActive, onClick: onClickProp }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const isMobile = useResponsive('down', 'md');

  const onClick = () => {
    navigate({
      pathname: path,
      search: location.search,
    });
  };
  return (
    <StyledNavigationItem
      data-testid="navigation-item-component"
      isActive={isActive}
      sx={{
        [theme.breakpoints.down('md')]: {
          flexBasis: '30%',
        },
      }}
      onClick={onClickProp ? onClickProp : onClick}
      flexDirection={isMobile ? 'column' : 'row'}
      alignItems="center"
    >
      {!isValidElement(Icon) ? (
        <SvgIcon
          fontSize={isMobile ? 'medium' : 'small'}
          sx={{
            marginRight: '6px',
            [theme.breakpoints.down('md')]: {
              marginRight: 0,
            },
          }}
          component={Icon as any}
        />
      ) : (
        Icon
      )}
      <Typography
        sx={{
          fontSize: 14,
          fontWeight: isActive ? 'bold' : '400',
          [theme.breakpoints.down('md')]: {
            fontSize: 12,
          },
        }}
      >
        {label}
      </Typography>
      {isActive && <ActiveDiv data-testid="active-div" />}
    </StyledNavigationItem>
  );
};

export default NavigationItem;
