import { useAuthentication } from 'src/features/authentication/context';
import { Protected } from 'src/features/protected-route/components';
import { HeaderOnlyLayout } from 'src/layouts';
import { TenantSwitchPage } from '../../pages';

import { ElementProps } from '../../types/element';
import { ACCOUNT_ISSUE_PATH, LOGIN_PATH, ROOT_PATH, SITES_MANAGEMENT_PATH } from '../../config';
import { LoadingElement } from '../LoadingElement';

export const TenantSwitchElement = ({ isSuperAdmin }: ElementProps) => {
  const { isAnyCustomerActive, hasLoaded, loggedIn, getAllCustomerIds, user } = useAuthentication();
  const { uniqueIds } = { ...getAllCustomerIds() };
  let activeUniqueIds: string[] = [];
  if (user) {
    try {
      const parsedAccessRoles = JSON.parse(user?.accessRoles?.replace(/\\/g, ''));
      activeUniqueIds = (uniqueIds || []).filter((id) =>
        parsedAccessRoles.some((role: any) => role.includes(id) && role.endsWith(':active'))
      );
    } catch (error) {
      console.error('Error parsing access roles:', error);
    }
  }

  const getUniqueRedirectPath = () => {
    if (!loggedIn.value) return LOGIN_PATH;
    if (isSuperAdmin) return ROOT_PATH;
    if (uniqueIds !== null && activeUniqueIds?.length === 1) {
      return `${SITES_MANAGEMENT_PATH}?customerId=${activeUniqueIds[0]}`;
    }
    if (!isAnyCustomerActive.value) return ACCOUNT_ISSUE_PATH;
  };

  const TenantSwitchRouteElement = () => (
    <Protected
      isAuthenticated={loggedIn.value}
      isAuthorized={
        isAnyCustomerActive.value &&
        !isSuperAdmin &&
        uniqueIds !== null &&
        activeUniqueIds.length > 1
      }
      uniqueRedirectPath={getUniqueRedirectPath()}
    >
      <HeaderOnlyLayout>
        <TenantSwitchPage />
      </HeaderOnlyLayout>
    </Protected>
  );

  const getTenantSwitchElement = () => {
    if (isSuperAdmin && loggedIn.value) return <TenantSwitchRouteElement />;

    if (!loggedIn.loaded) return <LoadingElement />;

    if (loggedIn.loaded && !loggedIn.value) return <TenantSwitchRouteElement />;

    if (!hasLoaded.user) return <LoadingElement />;

    if (!isAnyCustomerActive.loaded) return <LoadingElement />;

    return <TenantSwitchRouteElement />;
  };

  return getTenantSwitchElement();
};
