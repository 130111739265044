import { FunctionComponent, PropsWithChildren } from 'react';
import { cardContent, StyledCard } from './style';
import { CardContent, CardProps, SxProps, Theme } from '@mui/material';

const Card: FunctionComponent<
  PropsWithChildren<
    CardProps & {
      cardContentSx?: SxProps<Theme>;
      renderCardFooter?: () => JSX.Element;
    }
  >
> = ({ children, cardContentSx, renderCardFooter, ...props }) => (
  <StyledCard {...props}>
    <CardContent sx={{ ...cardContent, ...cardContentSx }}>{children}</CardContent>
    {renderCardFooter && renderCardFooter()}
  </StyledCard>
);

export default Card;
