import { useEffect, useState } from 'react';
import {
  TableRow,
  TableCell,
  Typography,
  IconButton,
  Stack,
  useTheme,
  Dialog,
} from '@mui/material';
import { ReportType } from '../../types';
import { AssetReportIcon, DataReportIcon, MoreVerticalFillIcon, PDFReportIcon } from 'src/assets';
import {
  MenuPopover,
  MenuPopoverItem,
  NoAvailableDataBox,
  CustomWaterLoading,
} from 'src/components';
import { useHtmlElement, useToggle } from 'src/hooks';
import { Delete } from '@mui/icons-material';
import { formatTime } from '../../helper/formatTime';
import { ReportPreview } from '../report-preview';
import { ReportsService } from 'src/services';
import { useAuthentication } from 'src/features/authentication/context';
import { SYSTEM } from '../../config/config';
import { DeleteConfirmationPopup } from '../delete-confirmation-popup';
import useQueryParamsActions from 'src/hooks/useQueryParamsActions';

type TableRowItemProps = {
  row: any;
  selected: boolean;
  handleDelete: () => void;
};

export const TableRowItemHistory = ({ row, selected, handleDelete }: TableRowItemProps) => {
  const tableCellStyle = { maxWidth: 'sm', whiteSpace: 'normal', wordWrap: 'break-word' };
  const { htmlElement, addHtmlElement, removeHtmlElement } = useHtmlElement();
  const { state, onToggle } = useToggle();
  const { state: deletePopupState, onToggle: onToggleDeletePopup } = useToggle();
  const theme = useTheme();
  const { append, get, remove } = useQueryParamsActions();
  const {
    customerId: { value: customerId },
  } = useAuthentication();

  const getActions: {
    [key in ReportType]?: (customerId: string, instanceId: string) => Promise<any>;
  } = {
    [ReportType.ASSET]: ReportsService.getAssetHistoryTemplateByInstanceId,
    [ReportType.DATA]: ReportsService.getDataHistoryTemplateByInstanceId,
  };

  const getFunction = getActions[row?.reportType as ReportType];

  const [reportDetail, setReportDetail] = useState<any>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const isReportDetailEmpty = !reportDetail || Object.keys(reportDetail).length === 0;

  const { formattedDate, formattedTime } = formatTime(row?.createdAt);

  const createdBy =
    row.createdBy === null ? SYSTEM : `${row?.createdBy?.firstName} ${row?.createdBy?.lastName}`;

  const menuPopoverItems = (
    <>
      <MenuPopoverItem
        icon={<Delete color="error" />}
        customContent={
          <Typography variant="body2" color="error">
            Delete
          </Typography>
        }
        handleClick={onToggleDeletePopup}
        testId="delete-button"
      />
    </>
  );

  const getReportTypeIcon = (reportType: ReportType) => {
    switch (reportType) {
      case ReportType.ASSET:
        return <AssetReportIcon />;
      case ReportType.PDF:
        return <PDFReportIcon />;
      case ReportType.DATA:
        return <DataReportIcon />;
      default:
        return <PDFReportIcon />;
    }
  };

  const onOpenOptions = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    addHtmlElement(event.currentTarget);
  };

  const reportId = get('reportId');
  useEffect(() => {
    (async () => {
      if (reportId === row.id) {
        const fetchDetailReports = async () => {
          const instanceId = row?.id;
          if (!getFunction || !customerId || !instanceId) return;
          try {
            const response = await getFunction(customerId, instanceId);
            return response;
          } catch (error) {
            console.error(error);
          }
        };
        setIsLoading(true);
        onToggle();

        try {
          const response = await fetchDetailReports();
          setReportDetail(response);
          console.log(response);
        } catch (error) {
          console.error('Error fetching detail reports:', error);
        } finally {
          setIsLoading(false);
        }
      }
    })();
  }, [reportId, row, onToggle, setIsLoading, customerId, getFunction]);

  const onClickHandler = async (event: React.MouseEvent<HTMLElement>) => {
    if (htmlElement) {
      return;
    }
    event.stopPropagation();

    append('reportId', row.id);
  };

  let content;
  if (isLoading) {
    content = <CustomWaterLoading />;
  } else if (!isReportDetailEmpty) {
    content = (
      <ReportPreview
        onlyExport
        title={reportDetail.reportName}
        data={reportDetail}
        isHistoryPage={true}
        reportId={row?.id}
      />
    );
  } else {
    content = <NoAvailableDataBox />;
  }

  return (
    <>
      <DeleteConfirmationPopup
        title={<>Are you sure you wish to delete this saved report?</>}
        state={deletePopupState}
        onToggle={onToggleDeletePopup}
        onDelete={handleDelete}
      />
      <Dialog
        sx={{
          '& .MuiPaper-root': {
            maxWidth: '100% !important',
          },
        }}
        onClose={() => {
          remove('reportId');
          onToggle();
        }}
        open={state}
      >
        {content}
      </Dialog>
      <TableRow
        sx={{ cursor: 'pointer' }}
        hover
        selected={selected}
        data-sm="table-row"
        onClick={onClickHandler}
      >
        <TableCell data-sm="type-row" sx={{ ...tableCellStyle, width: '20px' }}>
          {getReportTypeIcon(row.reportType)}
        </TableCell>
        <TableCell data-sm="name-row" sx={tableCellStyle}>
          <Typography variant="subtitle2">{row.reportName ?? '-'}</Typography>
        </TableCell>
        <TableCell data-sm="created-by-row" sx={tableCellStyle}>
          <Typography variant="subtitle2">{createdBy}</Typography>
        </TableCell>
        <TableCell data-sm="modified-row" sx={tableCellStyle}>
          <Stack alignItems={'flex-start'} justifyContent={'center'}>
            <Typography variant="subtitle2">{formattedDate ?? '-'}</Typography>
            <Typography variant="overline" sx={{ color: theme.palette.grey[500] }}>
              {formattedTime ?? '-'}
            </Typography>
          </Stack>
        </TableCell>

        <TableCell
          data-sm="context-menu-row"
          align="right"
          sx={{ ...tableCellStyle, width: '20px' }}
        >
          <IconButton
            data-sm="context-menu-button"
            color={htmlElement ? 'inherit' : 'default'}
            onClick={onOpenOptions}
          >
            <MoreVerticalFillIcon />
          </IconButton>
          <MenuPopover open={htmlElement} onClose={removeHtmlElement} arrow="right-top">
            {menuPopoverItems}
          </MenuPopover>
        </TableCell>
      </TableRow>
    </>
  );
};
