import { FunctionComponent } from 'react';
import { FilterComponentProps } from '../types';
import { Stack } from '@mui/material';
// import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateRangePicker } from 'rsuite';
import '../styles.css';
import dayjs from 'dayjs';

function getLocalISOString(date: Date) {
  const offsetMs = date.getTimezoneOffset() * 60 * 1000;
  const localDate = new Date(date.getTime() - offsetMs);
  return localDate.toISOString().split('T')[0] + 'T' + localDate.toISOString().split('T')[1];
}

const DateRangeFilter: FunctionComponent<FilterComponentProps> = ({ name, value, onChange }) => (
  <Stack flexDirection="row" gap="10px">
    <DateRangePicker
      ranges={[]}
      placement="bottomEnd"
      cleanable
      value={
        Array.isArray(value) && value.length === 2
          ? [new Date(value[0] as string), new Date(value[1] as string)]
          : null
      }
      onChange={(dates) => {
        if (!dates) return onChange([], name);
        const [startDate, endDate] = dates;
        const sameDate = dayjs(startDate).isSame(endDate);

        if (sameDate) {
          startDate.setHours(0, 0, 0);
          endDate.setHours(23, 59, 59);
        }

        onChange([getLocalISOString(startDate), getLocalISOString(endDate)], name);
      }}
    />
  </Stack>
);

export default DateRangeFilter;
