import { DataSource, EditDataSourceInput } from '../types';

export function getDataSourceTagProperties(
  data: any,
  dataSource: DataSource
): EditDataSourceInput[] {
  const tagProperties: EditDataSourceInput[] = [];
  const validProperties: string[][] = [];

  if (dataSource.inputs) {
    dataSource.inputs.forEach((input) => {
      validProperties.push([
        `${input.id}dataType`,
        `${input.id}rawUnit`,
        `${input.id}displayName`,
        `${input.id}hidden`,
      ]);
    });
  }
  Object.keys(data).forEach((key) => {
    const arr = validProperties.find((subArr) => subArr.includes(key));
    if (arr) {
      const tempObj = {
        id: arr[0].split('dataType')[0],
        dataType: data[arr[0]]?.toLowerCase().split(' ').join('_') || null,
        displayName: data[arr[2]] || null,
        rawUnit: data[arr[1]] || null,
        hidden: data[arr[3]] === 'true' ? true : false,
      };

      tagProperties.push(tempObj);
      validProperties.splice(validProperties.indexOf(arr), 1);
    }
  });

  return tagProperties;
}

export const extractUpdatedDataSources = (
  updatedData: EditDataSourceInput[],
  dataSource: DataSource
) => {
  const changedInputs = [];

  if (dataSource.inputs && updatedData) {
    for (let i = 0; i < updatedData.length; i++) {
      const newInput: any = updatedData[i];
      let foundInOldObject = false;
      for (let j = 0; j < dataSource.inputs.length; j++) {
        const oldInput: any = dataSource.inputs[j];
        if (newInput.id === oldInput.id) {
          let hasChanged = false;
          for (const field in newInput) {
            if (oldInput[field] !== newInput[field]) {
              hasChanged = true;
            }
          }
          if (hasChanged) {
            changedInputs.push(newInput);
          }
          foundInOldObject = true;
          break;
        }
      }
      if (!foundInOldObject) {
        changedInputs.push(newInput);
      }
    }

    return changedInputs;
  }
};
