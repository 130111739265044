import { Skeleton } from '@mui/material';

export const ChipsSkeleton = () => (
  <>
    {[{ key: 1 }, { key: 2 }, { key: 3 }].map((data) => (
      <Skeleton
        animation="wave"
        variant="rounded"
        height={'32px'}
        width={'96px'}
        key={data.key}
        sx={{ m: '4px' }}
      />
    ))}
  </>
);
