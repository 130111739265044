import { IconsTypesProps } from './types';
import { CURRENT_COLOR } from 'src/config';

export const MapOverviewIcon = ({ fill }: IconsTypesProps) => {
  const color = fill ?? CURRENT_COLOR;

  return (
    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.72982 11.3646H8.72982C9.18815 11.3646 9.56315 10.9896 9.56315 10.5312V3.86458C9.56315 3.40625 9.18815 3.03125 8.72982 3.03125H3.72982C3.27148 3.03125 2.89648 3.40625 2.89648 3.86458V10.5312C2.89648 10.9896 3.27148 11.3646 3.72982 11.3646ZM3.72982 18.0312H8.72982C9.18815 18.0312 9.56315 17.6562 9.56315 17.1979V13.8646C9.56315 13.4062 9.18815 13.0312 8.72982 13.0312H3.72982C3.27148 13.0312 2.89648 13.4062 2.89648 13.8646V17.1979C2.89648 17.6562 3.27148 18.0312 3.72982 18.0312ZM12.0632 18.0312H17.0632C17.5215 18.0312 17.8965 17.6562 17.8965 17.1979V10.5312C17.8965 10.0729 17.5215 9.69792 17.0632 9.69792H12.0632C11.6048 9.69792 11.2298 10.0729 11.2298 10.5312V17.1979C11.2298 17.6562 11.6048 18.0312 12.0632 18.0312ZM11.2298 3.86458V7.19792C11.2298 7.65625 11.6048 8.03125 12.0632 8.03125H17.0632C17.5215 8.03125 17.8965 7.65625 17.8965 7.19792V3.86458C17.8965 3.40625 17.5215 3.03125 17.0632 3.03125H12.0632C11.6048 3.03125 11.2298 3.40625 11.2298 3.86458Z"
        fill={color}
      />
    </svg>
  );
};
