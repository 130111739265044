import { IconsTypesProps } from 'src/assets/icons/types';

export const WastewaterFilterIcon = ({ width = 22, height = 22 }: IconsTypesProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="12" cy="12" r="9.5" stroke="#0461C1" />
    <circle cx="11.9996" cy="11.9996" r="8.4" fill="#049ACC" />
  </svg>
);
