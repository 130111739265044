import { useState, useEffect, useCallback } from 'react';
import { ControllersService } from 'src/services';
import { ControllerAdditionalInfo } from 'src/types';

type Props = {
  customerId?: string | null;
  siteId?: string | null;
  gatewayId?: string;
  controllerId?: string;
};

export const useControllerAdditionalInfo = ({
  customerId,
  siteId,
  gatewayId,
  controllerId,
}: Props) => {
  const [controllerAdditionalInfo, setControllerAdditionalInfo] =
    useState<ControllerAdditionalInfo[]>();

  const getControllerAdditionalInfo = useCallback(() => {
    if (!customerId || !siteId || !gatewayId || !controllerId) return;
    ControllersService.getAdditionalInfoById(customerId, siteId, gatewayId, controllerId).then(
      (response) => {
        setControllerAdditionalInfo(response);
      }
    );
  }, [customerId, siteId, gatewayId, controllerId]);

  useEffect(() => {
    getControllerAdditionalInfo();
  }, [getControllerAdditionalInfo]);

  return {
    controllerAdditionalInfo,
    getControllerAdditionalInfo,
  };
};
