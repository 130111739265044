import { useCallback } from 'react';
import { LocalStorageService } from 'src/features/authentication/utils';
import { POINT_CHARTS_LOCAL_STORAGE_KEY } from '../config';
import { PointChartType } from '../types/point-chart';
import { getSelectedInputs } from '../utils';

export type setNewFormValuesForSpecificChartArgs = {
  chartType: 'events' | 'pointChart';
  chartId: string;
  customerId: string;
  formValues: any;
};

type getNewFormValuesForSpecificChartArgs = {
  chartType: 'events' | 'pointChart';
  chartId: string;
  customerId: string;
};

type updateLocalStorageForAllPointChartsArgs = {
  chartId: string;
  values: Record<string, string>;
};

export const useFormValues = () => {
  const localStorage = window?.localStorage;

  const setOrUpdateNewFormValuesForSpecificChart = useCallback(
    ({ chartType, chartId, customerId, formValues }: setNewFormValuesForSpecificChartArgs) => {
      const key = `${chartType}-${chartId}-${customerId}`;
      localStorage.setItem(key, JSON.stringify(formValues));
    },
    [localStorage]
  );

  const getNewFormValuesForSpecificChart = ({
    chartType,
    chartId,
    customerId,
  }: getNewFormValuesForSpecificChartArgs) => {
    const key = `${chartType}-${chartId}-${customerId}`;
    const formValues = localStorage.getItem(key);

    return formValues ? JSON.parse(formValues) : {};
  };

  const updateLocalStorageForAllPointCharts = ({
    chartId,
    values,
  }: updateLocalStorageForAllPointChartsArgs) => {
    const pointCharts = LocalStorageService.getParsed(POINT_CHARTS_LOCAL_STORAGE_KEY);
    const findChartById = pointCharts?.find((item: PointChartType) => item.id === chartId);

    const filteredPointCharts = pointCharts?.filter((item: PointChartType) => item.id !== chartId);
    const selectedInputs = getSelectedInputs(values);

    const updatedPointCharts = [
      ...filteredPointCharts,
      {
        id: findChartById?.id,
        customerId: findChartById?.customerId,
        selectedInputs: selectedInputs,
      },
    ];

    LocalStorageService.set(POINT_CHARTS_LOCAL_STORAGE_KEY, JSON.stringify(updatedPointCharts));
  };

  return {
    setOrUpdateNewFormValuesForSpecificChart,
    getNewFormValuesForSpecificChart,
    updateLocalStorageForAllPointCharts,
  };
};
