const calculateFieldSpaceInPercent = (fieldsLength: number, isMobile: boolean) => {
  if (fieldsLength === 1) {
    return 100;
  }

  if (isMobile) {
    return 100 / 2;
  }

  if (fieldsLength % 4 === 0) {
    return 100 / 4;
  }

  if (fieldsLength > 3) {
    return 100 / 3;
  }

  return 100 / fieldsLength;
};

export default calculateFieldSpaceInPercent;
