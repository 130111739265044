import React, { useState, ChangeEvent, KeyboardEvent, useEffect, useRef } from 'react';
import { TextField, IconButton, InputAdornment, useTheme } from '@mui/material';
import { Clear } from '@mui/icons-material';
import { SearchFillIcon } from 'src/assets';
import { Container, SuggestionsContainer } from './style';
import { SearchSuggestion } from './SearchSuggestion';

type SearchBarTypes = {
  inputValue: string;
  setInputValue: React.Dispatch<React.SetStateAction<string>>;
  searchKey?: string;
};

const SearchBar = ({ inputValue, setInputValue, searchKey }: SearchBarTypes) => {
  const [suggestions, setSuggestions] = useState<string[]>([]);
  const [filteredSuggestions, setFilteredSuggestions] = useState<string[]>([]);
  const [highlightedIndex, setHighlightedIndex] = useState<number | null>(null);
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const theme = useTheme();

  const textFieldRef = useRef<HTMLInputElement>(null);

  const handleProgrammaticBlur = () => {
    if (textFieldRef.current) {
      textFieldRef.current.blur();
    }
  };

  useEffect(() => {
    if (!searchKey) return;
    const storedSuggestions = localStorage.getItem(searchKey);
    const parsedSuggestions = storedSuggestions ? JSON.parse(storedSuggestions) : [];

    setSuggestions(parsedSuggestions);
  }, [searchKey]);

  useEffect(() => {
    if (inputValue === '') {
      setFilteredSuggestions(suggestions);
      return;
    }

    setFilteredSuggestions(
      suggestions.filter((suggestion) => suggestion.includes(inputValue.toLocaleLowerCase()))
    );
  }, [inputValue, suggestions]);

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const handleSelectSuggestion = (suggestion: string) => {
    setInputValue(suggestion);
    handleProgrammaticBlur();
  };

  const handleClearSuggestion = (suggestion: string) => {
    setSuggestions(suggestions.filter((s) => s !== suggestion));

    if (!searchKey) return;
    const storedSuggestions = localStorage.getItem(searchKey);
    const parsedSuggestions = storedSuggestions
      ? JSON.parse(storedSuggestions).filter(
          (storedSuggestion: string) => storedSuggestion !== suggestion
        )
      : [];

    localStorage.setItem(searchKey, JSON.stringify(parsedSuggestions));
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'ArrowDown') {
      setHighlightedIndex((prevIndex) =>
        prevIndex === null || prevIndex === suggestions.length - 1 ? 0 : prevIndex + 1
      );
    } else if (event.key === 'ArrowUp') {
      setHighlightedIndex((prevIndex) =>
        prevIndex === null || prevIndex === 0 ? suggestions.length - 1 : prevIndex - 1
      );
    } else if (event.key === 'Enter' && highlightedIndex !== null) {
      handleSelectSuggestion(suggestions[highlightedIndex]);
      setHighlightedIndex(null);
    }
  };

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
    setHighlightedIndex(null);
  };

  const getPaperHeight = () => {
    if (filteredSuggestions.length === 0 || !isFocused) {
      return '0px';
    } else {
      return '100%';
    }
  };

  return (
    <Container>
      <TextField
        inputRef={textFieldRef}
        autoComplete="off"
        value={inputValue}
        size="small"
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
        onFocus={handleFocus}
        onBlur={handleBlur}
        fullWidth
        placeholder="Search Report"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchFillIcon fill={isFocused ? undefined : theme?.palette.grey[400]} />
            </InputAdornment>
          ),
          endAdornment: inputValue && (
            <InputAdornment position="end">
              <IconButton sx={{ p: '4px' }} onClick={() => setInputValue('')}>
                <Clear fontSize="small" />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <SuggestionsContainer
        sx={{ height: getPaperHeight() }}
        onMouseDown={(e) => e.preventDefault()}
      >
        <SearchSuggestion
          suggestions={filteredSuggestions}
          highlightedIndex={highlightedIndex}
          onSelect={handleSelectSuggestion}
          onClear={handleClearSuggestion}
          isFocused={isFocused}
          theme={theme}
        />
      </SuggestionsContainer>
    </Container>
  );
};

export default SearchBar;
