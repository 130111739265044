import { Button, Stack, Typography } from '@mui/material';
import { FunctionComponent } from 'react';
import { FilterIcon } from 'src/assets/icons/FilterIcon';
import { IFilterProps } from '../types';

const DesktopFilters: FunctionComponent<IFilterProps> = ({ openFilters }) => (
  <Stack sx={{ position: 'relative' }} flexDirection="row" alignItems="center">
    <Typography variant="h6" sx={{ flex: '1', color: 'black' }} />
    <Button
      onClick={() => {
        openFilters();
      }}
      sx={{ marginLeft: '12px', flexShrink: 0, height: '100%', maxHeight: '100%' }}
      variant="text"
    >
      <Stack flexDirection="row" alignItems="center">
        <Typography marginRight="8px" fontWeight="700" variant="body1">
          Filters
        </Typography>
        <FilterIcon />
      </Stack>
    </Button>
  </Stack>
);

export default DesktopFilters;
