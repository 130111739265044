import {
  api,
  LOGIN_URL,
  ACCOUNTS_USERS_URL_START,
  ACCOUNTS_USERS_FORGOT_PASSWORD,
  ACCOUNTS_USERS_REPLACE_PASSWORD,
  ACCOUNTS_USERS_URL_END,
  ACCOUNTS_USERS_SET_PASSWORD,
  RESET_PASSWORD,
  REFRESH,
  SET_PROFILE_URL,
} from '../api';
import {
  LoginParamsTypes,
  ChangePasswordParamsTypes,
  ForgotePasswordParamsTypes,
  ReplacePasswordParamsTypes,
  RefreshAccessTokenTypes,
  SetProfileTypes,
} from './types';

// SUMMARY:
// Endpoint for login.
const login = async (data: LoginParamsTypes) => {
  const response = await api({
    method: 'post',
    url: LOGIN_URL,
    data,
  });

  return response;
};

// SUMMARY:
// Endpoint for setting password after super admin reset or first login.
const changePassword = async (data: ChangePasswordParamsTypes) => {
  const response = await api({
    method: 'post',
    url: `${ACCOUNTS_USERS_URL_START}${ACCOUNTS_USERS_SET_PASSWORD}`,
    data,
  });

  return response;
};

// SUMMARY:
// Endpoint for resetting password on edit user page.
const resetPassword = async (customerId: string, userId: string) => {
  const response = await api({
    method: 'post',
    url: `${ACCOUNTS_USERS_URL_START}/${customerId}${ACCOUNTS_USERS_URL_END}/${userId}${RESET_PASSWORD}`,
  });

  return response.data;
};

// SUMMARY:
// Endpoint for sending email for forgot password.
const forgotPassword = async (data: ForgotePasswordParamsTypes) => {
  const response = await api({
    method: 'post',
    url: `${ACCOUNTS_USERS_URL_START}${ACCOUNTS_USERS_FORGOT_PASSWORD}`,
    data,
  });

  return response;
};

// SUMMARY:
// Endpoint for replacing password within forgot password flow.
const replacePassword = async (data: ReplacePasswordParamsTypes) => {
  const response = await api({
    method: 'post',
    url: `${ACCOUNTS_USERS_URL_START}${ACCOUNTS_USERS_REPLACE_PASSWORD}`,
    data,
  });

  return response;
};

const refreshAccessToken = async (data: RefreshAccessTokenTypes) => {
  const response = await api({
    method: 'post',
    url: `${ACCOUNTS_USERS_URL_START}${REFRESH}`,
    data,
  });

  return response.data;
};

const setProfile = async (data: SetProfileTypes) => {
  const response = await api({
    method: 'post',
    url: `${SET_PROFILE_URL}`,
    data,
  });

  return response.data;
};

export const AuthenticationService = {
  login,
  changePassword,
  forgotPassword,
  replacePassword,
  resetPassword,
  refreshAccessToken,
  setProfile,
};
