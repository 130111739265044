import { useResponsive } from 'src/hooks';
import fieldClass from '../utils/fieldClass';

const useFieldSpacing = (fieldsLength: number, blockElement?: boolean) => {
  const isMobile = useResponsive('down', 'md');

  return fieldClass(fieldsLength, isMobile, blockElement);
};

export default useFieldSpacing;
