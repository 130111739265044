import { Container, Stack, Typography } from '@mui/material';
import React from 'react';

const NoLogsPlaceholder = () => (
  <Container maxWidth={false}>
    <Stack sx={{ minHeight: '450px' }} alignItems="center" justifyContent="center">
      <Typography variant="h4">No logs data.</Typography>
    </Stack>
  </Container>
);
export default NoLogsPlaceholder;
