export const AxisXIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4 13V13.01M4 9V9.01M4 5V5.01M17 20L20 17M20 17L17 14M20 17H4"
      stroke="#919EAB"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
