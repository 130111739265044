import { DropResult } from 'react-beautiful-dnd';

export const reorderItems = <T>(
  items: T[],
  result: DropResult,
  onUpdate: (reorderedItems: T[]) => void
) => {
  const { destination, source } = result;

  if (!destination) {
    return;
  }

  if (destination.index === source.index) {
    return;
  }

  const [movedItem] = items.splice(source.index, 1);
  items.splice(destination.index, 0, movedItem);

  onUpdate(items.map((item, index) => ({ ...item, order: index })));
};
