import React from 'react';
import { Box, useTheme } from '@mui/material';

import { IconsTypesProps } from 'src/assets/icons/types';
import { StledExtendedNavItem, StyledTitle, iconBox } from '../extendedNavItem/style';
import { useResponsive } from 'src/hooks';

type Props = {
  title: string;
  icon: React.FunctionComponent<IconsTypesProps>;
  greyColor: string;
  activeItem: string;
  onClickHandler?: () => void;
  testId?: string;
};

export const NavItem = ({ title, icon, greyColor, activeItem, onClickHandler, testId }: Props) => {
  const iconComponent = React.createElement(icon, { fill: greyColor });
  const theme = useTheme();
  const isMobile = useResponsive('down', 'md');
  const isActive = activeItem === title;
  return (
    <StledExtendedNavItem
      theme={theme}
      isActive={isActive}
      onClick={onClickHandler}
      data-sm={testId}
    >
      <Box sx={iconBox}>{iconComponent}</Box>
      <StyledTitle variant={isMobile ? 'caption' : 'body2'} theme={theme} isActive={isActive}>
        {title}
      </StyledTitle>
    </StledExtendedNavItem>
  );
};
