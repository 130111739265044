import { DialogProps, Stack } from '@mui/material';
import { FunctionComponent, useEffect, useState } from 'react';
import AddColumnDialog from '../../sidebar/columns/AddColumnDialog';
import AssetPropertySelect from './AssetPropertySelect';
import AggregationSelect from './AssetAggregationSelect';
import { AssetProperty } from '../../../types';
import AssetPropertyDataProvider from 'src/features/reports/context/assetPropertyContext';
import ColumnAggregations from '../../sidebar/add-column-inputs/ColumnAggregations';

const AddAssetPropertyDialog: FunctionComponent<
  Omit<DialogProps, 'onSubmit'> & {
    assetProperty: AssetProperty | null;
    assetPropertieslist: AssetProperty[];
    onSubmit: (data: Record<string, any>) => void;
    onCancel: () => void;
  }
> = ({ onSubmit, onCancel, assetPropertieslist, assetProperty, ...props }) => {
  const [assetState, setAssetState] = useState<Record<string, any>>({});
  const [columnStatistics, setColumnStatistics] = useState<Record<string, boolean>>(
    assetProperty?.columnStatistics || {
      total: false,
      minimum: false,
      maximum: false,
      average: false,
    }
  );

  useEffect(() => {
    if (assetProperty) {
      setAssetState({
        asset: {
          type: assetProperty.asset.type,
          property: `${assetProperty.asset.property.value}_${assetProperty.asset.property.label}`,
        },
        aggregation: `${assetProperty.aggregation.value}_${assetProperty.aggregation.label}`,
      });
      setColumnStatistics(assetProperty.columnStatistics);
    } else {
      setColumnStatistics({
        total: false,
        minimum: false,
        maximum: false,
        average: false,
      });
      setAssetState({});
    }
  }, [assetProperty, props.open, setAssetState]);

  return (
    <AddColumnDialog
      isEdit={!!assetProperty}
      disabled={
        !assetState.asset ||
        !assetState.asset.type ||
        !assetState.asset.property ||
        !assetState.aggregation
      }
      onSubmit={() => onSubmit({ ...columnStatistics, ...assetState })}
      onCancel={onCancel}
      columnStatistics={columnStatistics}
      {...props}
    >
      <AssetPropertyDataProvider
        value={{
          assetProperties: assetPropertieslist,
          currentAssetProperty: assetProperty,

          currentState: assetState,
        }}
      >
        <Stack gap="24px">
          <AssetPropertySelect
            fetchAssetProperties={props.open}
            value={assetState.asset ?? {}}
            onChange={(event, type) => {
              setAssetState((prevState) => ({
                ...prevState,
                asset: {
                  type: type ? event.target.value : prevState?.asset?.type,
                  property: !type ? event.target.value : '',
                },
              }));
            }}
          />
          <AggregationSelect
            asset={assetState.asset || {}}
            value={assetState.aggregation ?? ''}
            onChange={(event) => {
              setAssetState((prevState) => ({
                ...prevState,
                aggregation: event.target.value,
              }));
            }}
          />
        </Stack>
      </AssetPropertyDataProvider>
      <ColumnAggregations
        value={columnStatistics}
        onChange={(value) => setColumnStatistics(value)}
      />
    </AddColumnDialog>
  );
};

export default AddAssetPropertyDialog;
