import { LeftArrowIcon } from 'src/assets';
import { StyledIconBox } from './style';

type Props = {
  onClick: () => void;
};

export const ArrowBackBox = ({ onClick }: Props) => (
  <StyledIconBox onClick={onClick}>
    <LeftArrowIcon />
  </StyledIconBox>
);
