import { formatNumberFromTheDevice } from 'src/features/widgets/utils';
import { ControllerAdditionalInfo } from 'src/types';
import { removeLeadingZeros } from './removeLeadingZeros';
import { trimTagValue } from './trimTagValue';

type PossibleValuesType = {
  id?: string;
  displayName: string;
  value?: string;
};

export const getDataForUpdateInhand = (
  values: any,
  possibleValues: PossibleValuesType[],
  controllerAdditionalInfo?: ControllerAdditionalInfo[],
  gatewayId?: string,
  activeMode?: string,
  displayMode?: string
) => {
  const arrValues = Object.entries(values);

  // eslint-disable-next-line array-callback-return
  const controls = arrValues.map((item: any) => {
    const isModeTag = item[0] === 'mode';
    //Current value for each item
    const newValue = trimTagValue(item[1]);
    const mode = possibleValues?.find(
      (mode) => mode.displayName?.replace(/\s/g, '') === item[1]?.replace(/\s/g, '')
    );

    if (isModeTag && mode) {
      if (values?.mode !== displayMode) {
        return {
          tagId: mode?.id,
          oldValue:
            activeMode !== '' ? removeLeadingZeros(trimTagValue(activeMode)) : 'NO_PREVIOUS_VALUE',
          newValue: removeLeadingZeros(trimTagValue(mode?.value)),
        };
      }
    }

    //If it is not mode
    if (!isModeTag) {
      const splitedItem = item[0]?.split('-');
      const idFromItemValue = splitedItem[1];
      const findDefaultItem = controllerAdditionalInfo?.find((item) => item.id === idFromItemValue);
      let defaultValue;

      const findValueIncludedInsideOfUserDefinedValues =
        findDefaultItem?.advancedTagMapping?.userDefinedValues?.find(
          (userItem) => userItem?.value === formatNumberFromTheDevice(findDefaultItem?.value)
        );
      if (findValueIncludedInsideOfUserDefinedValues) {
        const { value, name } = findValueIncludedInsideOfUserDefinedValues;
        const sufixName = name && `-${name}`;
        defaultValue = `${value}${sufixName}`;
      } else {
        defaultValue = findDefaultItem?.value;
      }

      const oldValue = findDefaultItem?.value.toString();

      let predefinedOldValue;

      const isPredefined = findDefaultItem?.advancedTagMapping?.allowedInputs === 'PREDEFINED';
      const predefinedNewValue = newValue?.split('-')[0].trim() || newValue?.trim();

      if (oldValue) predefinedOldValue = oldValue?.split('-')[0].trim() || oldValue?.trim();

      if (defaultValue !== newValue)
        return {
          tagId: idFromItemValue,
          oldValue: isPredefined
            ? removeLeadingZeros(predefinedOldValue)
            : removeLeadingZeros(trimTagValue(oldValue)),
          newValue: isPredefined
            ? removeLeadingZeros(predefinedNewValue)
            : removeLeadingZeros(trimTagValue(newValue)),
        };
    }
  });
  const filteredControls = controls.filter(Boolean);
  const data = {
    gatewayId: gatewayId,
    controls: filteredControls,
  };

  return data;
};
