import { IconButton, Stack, SvgIcon, Typography, useTheme } from '@mui/material';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import useQueryParamsActions from 'src/hooks/useQueryParamsActions';
import DeleteIcon from '@mui/icons-material/Delete';
import ImageIcon from '@mui/icons-material/Image';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { imageMimeTypes } from 'src/constants/imageMimeTypes';
import { AttachmentField } from '../types';
import { LogsService } from 'src/services/logs';
import { useAuthentication } from 'src/features/authentication/context';

const AttachmentItem: FunctionComponent<{
  index: number;
  field: AttachmentField;
  files: any;
  file:
    | { id: string; mime_type: string; file: File; name: string; order: number }
    | { id: string; name: string; mime_type: string };
  disabled: boolean;
  onOpenPreviewBox: () => void;
}> = ({ file, onOpenPreviewBox, index, disabled, field, files }) => {
  const theme = useTheme();
  const { setValue, watch } = useFormContext();
  const { append } = useQueryParamsActions();
  const log = watch('id');
  const {
    siteId: { value: siteId },
    customerId: { value: customerId },
  } = useAuthentication();
  const [imagePreview, setImagePreview] = useState<string | null>(null);

  useEffect(() => {
    const isImage = imageMimeTypes.includes(file.mime_type);
    if (isImage) {
      if ((file as any).file) {
        const imageUrl = URL.createObjectURL((file as any).file);

        setImagePreview(imageUrl);
      } else {
        LogsService.getAttachment(log, file.id, siteId!, customerId!).then((response) => {
          const imageUrl = URL.createObjectURL(response);
          setImagePreview(imageUrl);
        });
      }
    }
  }, [file, siteId, customerId, log, setImagePreview]);

  return (
    <Stack
      key={file instanceof File ? file.name : file.id}
      onClick={() => {
        onOpenPreviewBox();
        append('attachment', (file as any).id);
      }}
      alignItems="center"
      justifyContent="center"
      sx={{
        ...{
          boxShadow: 2,
          backgroundColor: theme.palette.background.default,
          borderRadius: '8px',

          flexShrink: '0',
          position: 'relative',
          width: '100px',
          height: '100px',
        },
        zIndex: 50 - index,
      }}
    >
      {!disabled && (
        <IconButton
          onClick={(event) => {
            event.stopPropagation();
            setValue(
              field.id + '_attachment',
              files.filter((el: any) => el.id !== (file as any).id)
            );
          }}
          sx={{
            backgroundColor: theme.palette.background.default,
            position: 'absolute',
            right: '-18px',
            top: '-10px',
          }}
        >
          <SvgIcon fontSize="small" color="error" component={DeleteIcon} />
        </IconButton>
      )}
      {imagePreview ? (
        <img src={imagePreview} alt="Preview" />
      ) : (
        <>
          <SvgIcon
            fontSize="large"
            component={
              imageMimeTypes.includes(file instanceof File ? file.type : file.mime_type)
                ? ImageIcon
                : InsertDriveFileIcon
            }
          />
          <Typography
            sx={{
              whiteSpace: 'nowrap',
              width: '60%',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
            variant="caption"
          >
            {file.name}
          </Typography>
        </>
      )}
    </Stack>
  );
};

export default AttachmentItem;
