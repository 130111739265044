import { ReactNode, useState } from 'react';
import { Box, InputAdornment, useTheme } from '@mui/material';
import { InfoIcon } from 'src/assets';
import { RHFTextField } from 'src/components/minimals/form';
import { LightTooltip } from 'src/components/minimals/tooltip/LightTooltip';
import { ControllerAdditionalInfo } from 'src/types';
import { textField } from './style';
import { isTagEditable } from 'src/features/widgets/utils';
import { LatestControlsForInhandTypes } from 'src/features/widgets/hooks/types';
import { STATE } from 'src/constants';
import { LocalStorageService } from 'src/features/authentication/utils';

type InputFieldModalItemProps = {
  setValue: (key: string, value: string) => void;
  titleTooltip: (item: ControllerAdditionalInfo) => ReactNode;
  item: ControllerAdditionalInfo;
  title: string;
  selectedMode?: { id?: string; displayName?: string; value?: string };
  latestControlsForInhand: LatestControlsForInhandTypes[];
  isErrorOnUpdateControls: boolean;
  enableApplyButtonHandler: () => void;
  isDataLoading: boolean;
  isAppliedChanges: boolean;
  setIsAppliedChangedHandler: (value: boolean) => void;
  disableApplyButtonHandler: () => void;
  isCloseBtn: boolean;
};

export const InputFieldModalItem = ({
  setValue,
  item,
  titleTooltip,
  title,
  selectedMode,
  latestControlsForInhand,
  isErrorOnUpdateControls,
  enableApplyButtonHandler,
  isDataLoading,
  isAppliedChanges,
  setIsAppliedChangedHandler,
  disableApplyButtonHandler,
  isCloseBtn,
}: InputFieldModalItemProps) => {
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [previousValue, setPreviousValue] = useState<string>();
  const isStateDataType = item?.dataType === STATE;
  const tagId = item?.id;
  const latestControlItem = latestControlsForInhand?.find((item) => item?.tagId === tagId);
  const isSuccessFalse = !latestControlItem?.success && !isDataLoading;
  const isControlError = isSuccessFalse || isErrorOnUpdateControls;

  // const isError = window.localStorage.getItem(tagId) === 'error';

  const theme = useTheme();

  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setIsAppliedChangedHandler(false);
    enableApplyButtonHandler();
    const key = `${item.dataType}-${item.id}`;
    const { value } = e.target;
    const formatValue = Number(value);

    if (latestControlItem && latestControlItem?.success === false) {
      LocalStorageService.set(tagId, 'error');
    }

    const minValue = item?.advancedTagMapping?.minimumValue;
    const maxValue = item?.advancedTagMapping?.maximumValue;
    const maxDecimal = item?.advancedTagMapping?.maximumDecimal;

    const findDecimalArr = value.split('.') || value.split(',');
    const decimalNumber = findDecimalArr[1]?.length || 0;

    const isMaxDecimal = maxDecimal || maxDecimal === 0;

    if (isMaxDecimal && maxDecimal >= decimalNumber) {
      setValue(key, value);
    }

    if (!maxDecimal && maxDecimal !== 0) setValue(key, value);

    if (formatValue < minValue! || formatValue > maxValue! || isNaN(formatValue) || !value) {
      disableApplyButtonHandler();
      setErrorMessage(`Please enter a value within the range of ${minValue} to ${maxValue}`);
    } else {
      setErrorMessage('');
    }

    setPreviousValue(`Previous value: ${item.value}`);
  };

  const rawUnit = item.rawUnit ? `(${item.rawUnit})` : '';

  const disableFiled =
    isCloseBtn || isDataLoading || !isTagEditable({ item: item, selectedMode: selectedMode });

  const successMessage =
    isAppliedChanges && !isDataLoading && latestControlItem && latestControlItem?.success
      ? 'Successfully updated'
      : '';

  const renederMessage = () => {
    const newValue = latestControlItem?.newValue;
    if (errorMessage) return errorMessage;

    if (
      isControlError &&
      !isDataLoading &&
      latestControlItem &&
      latestControlItem?.success === false &&
      isAppliedChanges
    )
      return `Failed to change to ${newValue}`;

    const notApplyingChanges = !isAppliedChanges && !isDataLoading;

    if (notApplyingChanges || isDataLoading) return previousValue;

    if (successMessage) return successMessage;
  };

  return (
    <RHFTextField
      sx={textField(
        errorMessage.length > 0 ||
          (isErrorOnUpdateControls && !isDataLoading) ||
          (isSuccessFalse && isAppliedChanges),
        theme,
        !!successMessage
      )}
      onChange={(e) => onChangeHandler(e)}
      error={!!errorMessage}
      helperText={renederMessage()}
      name={`${item.dataType}-${item.id}`}
      label={`${item.displayName} ${!isStateDataType ? rawUnit : ''}`}
      disabled={disableFiled}
      testId={`${title}-input`}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <LightTooltip
              arrow
              title={titleTooltip(item)}
              placement="right-start"
              enterTouchDelay={0}
            >
              <Box height={'20px'}>
                <InfoIcon />
              </Box>
            </LightTooltip>
          </InputAdornment>
        ),
      }}
    />
  );
};
