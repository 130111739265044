import { Container, Dialog } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import {
  CONFIRMATION_MODAL_HEADING_ADD,
  CONFIRMATION_MODAL_DESCRIPTION_ADD,
  BREADCRUMBS_ROOT,
  BREADCRUMBS_ADD_CUSTOMER,
} from '../config';
import { CustomerAccountDetailsForm } from '../components/CustomerAccountDetailsForm';
import { CheckmarkCircleFilledLargeIcon, CloseIcon } from 'src/assets';
import { ConfirmationModal, CustomBreadcrumbs } from 'src/components';
import { useDocumentTitle, useToggle } from 'src/hooks';
import { ROOT_PATH } from 'src/routes/config';
import { useNavigate } from 'react-router';

export const AddCustomerAccount = () => {
  const { state, onToggle } = useToggle();
  const navigate = useNavigate();

  const theme = useTheme();
  const primaryMainColor = theme.palette.primary.main;
  const greyBoldColor = theme.palette.grey[700];

  useDocumentTitle('Streametric - Add Customer Account');

  const handleRedirect = () => {
    navigate(ROOT_PATH);
  };

  const onCloseDialog = () => {
    onToggle();
    handleRedirect();
  };

  return (
    <Container maxWidth={false} disableGutters>
      <Dialog onClose={onCloseDialog} open={state}>
        <ConfirmationModal
          onClose={onCloseDialog}
          state={state}
          mainIcon={<CheckmarkCircleFilledLargeIcon fill={primaryMainColor} />}
          closeIcon={<CloseIcon fill={greyBoldColor} />}
          heading={CONFIRMATION_MODAL_HEADING_ADD}
          headingVariant="h6"
          description={CONFIRMATION_MODAL_DESCRIPTION_ADD}
          descriptionVariant="body1"
        />
      </Dialog>
      <CustomBreadcrumbs
        links={[
          {
            name: BREADCRUMBS_ROOT,
            href: ROOT_PATH,
          },
          {
            name: BREADCRUMBS_ADD_CUSTOMER,
          },
        ]}
        sx={{ my: '35px' }}
        testId="customer-account-breadcrumb"
      />
      <CustomerAccountDetailsForm handleConfirmationWindow={onToggle} />
    </Container>
  );
};

export default AddCustomerAccount;
