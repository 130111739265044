export const BarsChartWidget = () => (
  <svg width="154" height="73" viewBox="0 0 154 73" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.619629 11.887H152.62"
      stroke="#919EAB"
      strokeOpacity="0.24"
      strokeWidth="0.619658"
      strokeLinecap="square"
      strokeDasharray="1.24 1.86"
    />
    <path
      d="M0.619629 32.3352H152.62"
      stroke="#919EAB"
      strokeOpacity="0.24"
      strokeWidth="0.619658"
      strokeLinecap="square"
      strokeDasharray="1.24 1.86"
    />
    <path
      d="M0.619629 52.7839H152.62"
      stroke="#919EAB"
      strokeOpacity="0.24"
      strokeWidth="0.619658"
      strokeLinecap="square"
      strokeDasharray="1.24 1.86"
    />
    <path
      d="M0 13.526C0 12.1571 1.10972 11.0474 2.47863 11.0474H9.91453C11.2834 11.0474 12.3932 12.1571 12.3932 13.526V72.4997H0V13.526Z"
      fill="#05B6B2"
    />
    <path
      d="M23.5469 32.8595C23.5469 31.4906 24.6566 30.3809 26.0255 30.3809H33.4614C34.8303 30.3809 35.94 31.4906 35.94 32.8595V72.4999H23.5469V32.8595Z"
      fill="#05B6B2"
    />
    <path
      d="M47.094 24.4352C47.094 23.0663 48.2037 21.9565 49.5726 21.9565H57.0085C58.3774 21.9565 59.4872 23.0663 59.4872 24.4352V72.4994H47.094V24.4352Z"
      fill="#05B6B2"
    />
    <path
      d="M70.6409 2.47863C70.6409 1.10972 71.7506 0 73.1195 0H80.5554C81.9243 0 83.034 1.10972 83.034 2.47863V72.4998H70.6409V2.47863Z"
      fill="#05B6B2"
    />
    <path
      d="M94.1882 60.4781C94.1882 59.1092 95.298 57.9995 96.6669 57.9995H104.103C105.472 57.9995 106.581 59.1092 106.581 60.4781V72.4994H94.1882V60.4781Z"
      fill="#05B6B2"
    />
    <path
      d="M117.735 7.31164C117.735 5.94273 118.845 4.83301 120.213 4.83301H127.649C129.018 4.83301 130.128 5.94273 130.128 7.31164V72.4996H117.735V7.31164Z"
      fill="#05B6B2"
    />
    <path
      d="M141.282 16.0123C141.282 14.6434 142.391 13.5337 143.76 13.5337H151.196C152.565 13.5337 153.675 14.6434 153.675 16.0123V72.5003H141.282V16.0123Z"
      fill="#05B6B2"
    />
  </svg>
);
