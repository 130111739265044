import { Theme, css } from '@mui/material/styles';

type StylesTypes = {
  theme: Theme;
};

export const scrollbarOverride: any = ({ theme }: StylesTypes) => css`
  && {
    msoverflowstyle: none;
    scrollbarwidth: none;
    &::-webkit-scrollbar {
      width: 8px;
      height: 8px;
    }
    &::-webkit-scrollbar-track {
      background-color: 'transparent';
      border-radius: 4px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: ${theme.scrollbar?.thumb};
      border-radius: 4px;
    }
    &::-webkit-scrollbar-thumb:hover {
      background-color: ${theme.scrollbar?.thumbHover};
    }
  }
`;

export const customScrollbar = (customSelector: string, theme: Theme) => `
/* Custom scrollbar styles based on the theme */
${customSelector}::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
${customSelector}::-webkit-scrollbar-track {
  background-color: ${theme.scrollbar?.background};
  border-radius: 4px;
}
${customSelector}::-webkit-scrollbar-thumb {
  background-color: ${theme.scrollbar?.thumb};
  border-radius: 4px;
}
${customSelector}::-webkit-scrollbar-thumb:hover {
  background-color: ${theme.scrollbar?.thumbHover};
}
`;
