import { ALARMS_PATH, RULES_PATH, SUBSCRIPTIONS_PATH, USERS_PATH } from 'src/routes/config';
import { COUNT_URL, UNSUBSCRIBE, api } from '../api';
import { AddAlarmRuleType, GetAllParamsTypes, RemoveTypes } from './types';

// SUMMARY:
// Endpoint for adding alarmRule.
const add = async (customerId: string, siteId: string, data: AddAlarmRuleType) => {
  const response = await api({
    method: 'post',
    url: `${ALARMS_PATH}/${customerId}/${siteId}${RULES_PATH}`,
    data,
  });

  return response.data;
};

// SUMMARY:
// Endpoint for get all data.
const getAll = async (
  customerId: string,
  siteId: string,
  userId: string,
  params?: GetAllParamsTypes
) => {
  const response = await api({
    method: 'get',
    url: `${ALARMS_PATH}/${customerId}/${siteId}${RULES_PATH}${USERS_PATH}/${userId}`,
    params,
  });

  return response.data;
};

// SUMMARY:
// Endpoint for adding alarmRule.
const getCount = async (customerId: string, siteId: string) => {
  const response = await api({
    method: 'get',
    url: `${ALARMS_PATH}/${customerId}/${siteId}${RULES_PATH}${COUNT_URL}`,
  });

  return response.data;
};

const edit = async (customerId: string, siteId: string, ruleId: string, data: AddAlarmRuleType) => {
  const response = await api({
    method: 'patch',
    url: `${ALARMS_PATH}/${customerId}/${siteId}${RULES_PATH}/${ruleId}`,
    data,
  });

  return response.data;
};

const getById = async (customerId: string, siteId: string, ruleId: string) => {
  const response = await api({
    method: 'get',
    url: `${ALARMS_PATH}/${customerId}/${siteId}${RULES_PATH}/${ruleId}`,
  });

  return response.data;
};

const remove = async ({ customerId, siteId, ruleId }: RemoveTypes) => {
  const response = await api({
    method: 'delete',
    url: `${ALARMS_PATH}/${customerId}/${siteId}${RULES_PATH}/${ruleId}`,
  });

  return response.data;
};

const getUserSubscription = async (customerId: string, ruleId: string, userId: string) => {
  const response = await api({
    method: 'get',
    url: `${ALARMS_PATH}/${customerId}${SUBSCRIPTIONS_PATH}${RULES_PATH}/${ruleId}${USERS_PATH}/${userId}`,
  });

  return response.data;
};

const postUserSubscription = async (
  customerId: string,
  ruleId: string,
  userId: string,
  type: string
) => {
  const response = await api({
    method: 'post',
    url: `${ALARMS_PATH}/${customerId}${SUBSCRIPTIONS_PATH}${RULES_PATH}/${ruleId}${USERS_PATH}/${userId}/${type}`,
  });

  return response.data;
};

const editUserSubscription = async (
  customerId: string,
  ruleId: string,
  userId: string,
  type: string
) => {
  const response = await api({
    method: 'patch',
    url: `${ALARMS_PATH}/${customerId}${SUBSCRIPTIONS_PATH}${RULES_PATH}/${ruleId}${USERS_PATH}/${userId}/${type}`,
  });

  return response.data;
};

const unsubscribeUser = async (customerId: string, ruleId: string, userId: string) => {
  const response = await api({
    method: 'delete',
    url: `${ALARMS_PATH}/${customerId}${SUBSCRIPTIONS_PATH}${RULES_PATH}/${ruleId}${USERS_PATH}/${userId}${UNSUBSCRIBE}`,
  });

  return response.data;
};

export const AlarmRuleService = {
  add,
  getAll,
  getCount,
  getById,
  edit,
  remove,
  getUserSubscription,
  postUserSubscription,
  editUserSubscription,
  unsubscribeUser,
};
