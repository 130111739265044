import { useState } from 'react';
import { subDays } from 'date-fns';
import dayjs from 'dayjs';
import { SessionStorageService } from 'src/features/authentication/utils';
import { TimeOptionType } from '../types';
import { SelectedBoxType } from '../components/timeframe-bar-options/types';
import { getTimestampsBasedOnTimeBox } from '../components/line-chart-trend/utils';

type UseSyncTimeframesArgs = {
  sessionStorageKey: string;
  selectedBoxSessionStorageKey: string;
};

export const useSyncTimeframes = ({
  sessionStorageKey,
  selectedBoxSessionStorageKey,
}: UseSyncTimeframesArgs) => {
  const [selectedBox, setSelectedBox] = useState<TimeOptionType>(() => {
    const sessionStorageSelectedBox = SessionStorageService.getRaw(selectedBoxSessionStorageKey);
    return sessionStorageSelectedBox ? (sessionStorageSelectedBox as TimeOptionType) : '1d';
  });

  const [timestamps, setTimestamps] = useState<any>(() => {
    const timestamps = {
      fromDate: dayjs(subDays(new Date(), 1)),
      toDate: dayjs(new Date()),
    };
    const sessionStorageTimestamps = SessionStorageService.getParsed(sessionStorageKey);
    const sessionStorage = {
      fromDate: dayjs(new Date(sessionStorageTimestamps.fromDate)),
      toDate: dayjs(new Date(sessionStorageTimestamps.toDate)),
    };

    if (selectedBox === 'custom' && sessionStorageTimestamps) {
      return sessionStorage;
    }
    if (selectedBox !== 'custom' && sessionStorageTimestamps) {
      const newDate = getTimestampsBasedOnTimeBox(selectedBox);
      return newDate;
    }
    return timestamps;
  });

  const onChangeTimestapHandler = (date: 'fromDate' | 'toDate', value: any) => {
    setTimestamps((prevState: any) => {
      const newState = { ...prevState, [date]: value };
      SessionStorageService.set(sessionStorageKey, JSON.stringify(newState));
      return newState;
    });
  };

  const onSelectTimestampHandler = (days: number) => {
    const now = new Date();
    setTimestamps(() => {
      const newState = {
        fromDate: dayjs(subDays(now, days)),
        toDate: dayjs(now),
      };
      SessionStorageService.set(sessionStorageKey, JSON.stringify(newState));
      return newState;
    });
  };

  const onSelectBoxHandler = (value: SelectedBoxType) => {
    setSelectedBox(value);
    SessionStorageService.set(selectedBoxSessionStorageKey, value);
  };

  return {
    syncTimestamps: timestamps,
    setTimestamps,
    onChangeTimestapHandler,
    onSelectTimestampHandler,
    selectedBox,
    onSelectBoxHandler,
  };
};
