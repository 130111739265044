import { IconsTypesProps } from '../types';
import { CURRENT_COLOR } from 'src/config';

export const CheckmarkCircleFilledLargeIcon = ({
  fill,
  width = 68,
  height = 68,
}: IconsTypesProps) => {
  const color = fill ?? CURRENT_COLOR;

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 68 68"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.666504 34C0.666504 15.5905 15.5903 0.666687 33.9998 0.666687C42.8404 0.666687 51.3189 4.17858 57.5701 10.4298C63.8213 16.681 67.3332 25.1595 67.3332 34C67.3332 52.4095 52.4093 67.3334 33.9998 67.3334C15.5903 67.3334 0.666504 52.4095 0.666504 34ZM33.0998 46.0334L48.3332 26.0334V25.9334C49.0596 24.9806 49.2226 23.7128 48.7608 22.6073C48.2989 21.5018 47.2824 20.7267 46.0941 20.574C44.9058 20.4212 43.7263 20.914 42.9998 21.8667L30.3998 38.5334L24.9665 31.6C24.2341 30.6592 23.0554 30.1806 21.8745 30.3445C20.6935 30.5084 19.6897 31.2898 19.2412 32.3945C18.7926 33.4992 18.9674 34.7592 19.6998 35.7L27.8332 46.0667C28.4692 46.8717 29.4406 47.3389 30.4665 47.3334C31.4982 47.3309 32.4705 46.8508 33.0998 46.0334Z"
        fill={color}
      />
    </svg>
  );
};
