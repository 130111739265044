import { Stack, Box, Typography, Divider } from '@mui/material';
import { titleBox, titleStyle, formGroup } from './style';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { useEffect, useState } from 'react';

type SelectedItems = {
  assetTypes: string[];
  siteTypes: string[];
};

type FilterOption = Readonly<{
  type: string;
  label: string;
}>;

type Props = {
  title: string;
  options: ReadonlyArray<FilterOption>;
  selectedOptions: SelectedItems;
  stateName: 'assetTypes' | 'siteTypes';
  onSelectChecboxItems: (
    type: 'assetTypes' | 'siteTypes',
    value: string,
    isSelected: boolean
  ) => void;
  onSelectAllItems: (
    type: 'assetTypes' | 'siteTypes',
    options: string[],
    isAllSelected: boolean
  ) => void;
};

export const FilterCategoryItem = ({
  title,
  options,
  selectedOptions,
  stateName,
  onSelectAllItems,
  onSelectChecboxItems,
}: Props) => {
  const relevantSelectedOptions = selectedOptions[stateName] || [];
  const [isAllSelected, setIsAllSelected] = useState(
    relevantSelectedOptions.length === options.length
  );

  useEffect(() => {
    setIsAllSelected(selectedOptions[stateName].length === options.length);
  }, [setIsAllSelected, selectedOptions, stateName, options]);

  const handleSelectAll = () => {
    onSelectAllItems(
      stateName,
      options.map((option) => option.type),
      isAllSelected
    );
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.name;
    const isChecked = event.target.checked;
    onSelectChecboxItems(stateName, value, isChecked);
  };

  return (
    <Stack>
      <Box sx={titleBox}>
        <Typography sx={titleStyle}>{title}</Typography>
      </Box>
      <FormGroup sx={formGroup}>
        <FormControlLabel
          control={<Checkbox onChange={handleSelectAll} checked={isAllSelected} />}
          label="Select All"
        />
        <Divider />
        {options.map((option) => (
          <FormControlLabel
            key={option.type}
            control={
              <Checkbox
                name={option.type}
                onChange={handleCheckboxChange}
                checked={relevantSelectedOptions?.includes(option.type)}
              />
            }
            label={option.label}
          />
        ))}
      </FormGroup>
    </Stack>
  );
};
