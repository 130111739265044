import { Skeleton, TableCell, TableRow } from '@mui/material';

type SiteManagementRowsSkeletonProps = {
  rowsNum: number;
  isMobile: boolean;
};

export const SiteManagementRowsSkeleton = ({
  rowsNum,
  isMobile,
}: SiteManagementRowsSkeletonProps) => {
  const renderRows = () => {
    const rows: { id: string }[] = [];
    for (let rowIndex = 0; rowIndex < rowsNum; rowIndex++) {
      rows.push({ id: `row-${rowIndex}` });
    }

    return (
      <>
        {rows.map((row) =>
          isMobile ? (
            <Skeleton
              key={row.id}
              animation={'wave'}
              variant="rounded"
              width={'100%'}
              height={'120px'}
              sx={{ mt: '8px', mb: '8px' }}
            />
          ) : (
            <TableRow key={row.id}>
              <TableCell colSpan={5} sx={{ padding: 0 }}>
                <Skeleton animation={'wave'} variant="rounded" width={'100%'} height={'72px'} />
              </TableCell>
            </TableRow>
          )
        )}
      </>
    );
  };

  return renderRows();
};
