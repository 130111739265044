import { AGGREGATION_SELECT_OPTIONS } from '../config/config';
import { v4 as uuid } from 'uuid';
import { timeFrameOptions } from './timeFrameToDateUtils';
import dayjs from 'dayjs';

const mapDataCustomColumns = (column: any, index: number) => ({
  aggregation: AGGREGATION_SELECT_OPTIONS.find(
    (aggr) => aggr.value === column.aggregation.toLowerCase()
  ),
  order: index,
  id: uuid(),
  unit: column.unit,
  tag: {
    tagId: column.tagid,
    rawUnit: column.unit,
    tagName: column.tagname,
    gatewayId: column.gatewayid,
    siteId: column.siteid,
    siteName: column.sitename,
  },

  columnStatistics: column.statistics
    ? {
        TOTAL: column.statistics.includes('TOTAL'),
        MIN: column.statistics.includes('MIN'),
        MAX: column.statistics.includes('MAX'),
        AVERAGE: column.statistics.includes('AVERAGE'),
      }
    : { TOTAL: false, MIN: false, MAX: false, AVERAGE: false },
});

const mapAssetCustomColumns = (assetProps: any) => (column: any, index: number) => ({
  asset: {
    type: column.assetType,
    property: assetProps.find((el: any) => el.value === column.name),
  },
  id: uuid(),
  order: index,
  aggregation: AGGREGATION_SELECT_OPTIONS.find(
    (aggr) => aggr.value === column.aggregation.toLowerCase()
  ),
  columnStatistics: column.statistics
    ? {
        TOTAL: column.statistics.includes('TOTAL'),
        MIN: column.statistics.includes('MIN'),
        MAX: column.statistics.includes('MAX'),
        AVERAGE: column.statistics.includes('AVERAGE'),
      }
    : { TOTAL: false, MIN: false, MAX: false, AVERAGE: false },
});

const parseTemplateResponse = (template: any, assetProps?: any, filters?: any) => ({
  timeFrame: (() => {
    const element = timeFrameOptions.find((option) => {
      const dateRange = option.parseToDate();

      return (
        dayjs(dateRange[0]).format('YYYY-MM-DD') === template.timeframe.start &&
        dayjs(dateRange[1]).add(1, 'day').format('YYYY-MM-DD') === template.timeframe.end
      );
    });

    if (element) return element.value;

    return [template.timeframe.start, template.timeframe.end];
  })(),
  filters: template.filters,
  columns: {
    default:
      template.reportType === 'DATA'
        ? {
            date: template.columns.date,
            time: template.columns.time,
          }
        : {
            date: template.columns.default.date,
            site_name: template.columns.default.siteName,
            site_type: template.columns.default.siteType,
            asset_name: template.columns.default.assetName,
            asset_type: template.columns.default.assetType,
          },

    custom: template.columns.custom
      ? template.columns.custom.map(
          template.reportType === 'ASSET'
            ? mapAssetCustomColumns(assetProps.reduce((acc: any, ap: any) => acc.concat(ap[1]), []))
            : mapDataCustomColumns
        )
      : [],
  },
  aggregationInterval: template.dataAggregation,
});
export default parseTemplateResponse;
