export const timeFrames = [
  { label: 'This Week', id: 'THIS_WEEK' },
  { label: 'This Month', id: 'THIS_MONTH' },
  { label: 'Last Week', id: 'LAST_WEEK' },
  { label: 'Last Month', id: 'LAST_MONTH' },
  { label: 'Last 7 Days', id: 'LAST_SEVEN_DAYS' },
  { label: 'Last 30 Days', id: 'LAST_THIRTY_DAYS' },
];

export const scheduleIntervals = [
  { label: 'Weekly', id: 'WEEKLY' },
  { label: 'Monthly', id: 'MONTHLY' },
];

export const daysOfAMonth = [
  { label: '1st', id: '1' },
  { label: '2nd', id: '2' },
  { label: '3rd', id: '3' },
  { label: '4th', id: '4' },
  { label: '5th', id: '5' },
  { label: '6th', id: '6' },
  { label: '7th', id: '7' },
  { label: '8th', id: '8' },
  { label: '9th', id: '9' },
  { label: '10th', id: '10' },
  { label: '11th', id: '11' },
  { label: '12th', id: '12' },
  { label: '13th', id: '13' },
  { label: '14th', id: '14' },
  { label: '15th', id: '15' },
  { label: '16th', id: '16' },
  { label: '17th', id: '17' },
  { label: '18th', id: '18' },
  { label: '19th', id: '19' },
  { label: '20th', id: '20' },
  { label: '21st', id: '21' },
  { label: '22nd', id: '22' },
  { label: '23rd', id: '23' },
  { label: '24th', id: '24' },
  { label: '25th', id: '25' },
  { label: '26th', id: '26' },
  { label: '27th', id: '27' },
  { label: '28th', id: '28' },
  { label: '29th', id: '29' },
  { label: '30th', id: '30' },
  { label: '31st', id: '31' },
];

export const daysOfTheWeek: string[] = [
  'MONDAY',
  'TUESDAY',
  'WEDNESDAY',
  'THURSDAY',
  'FRIDAY',
  'SATURDAY',
  'SUNDAY',
];
