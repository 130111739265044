import { Theme, styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { NAV } from 'src/config';
import { scrollbarOverride } from 'src/styles';

type StylesTypes = {
  theme: Theme;
  isMobile: boolean;
  isOpen: boolean;
  isSuperAdmin: boolean;
  isCustomerAccountPage: boolean;
};

const StyledSideBar = styled(Box, {
  shouldForwardProp: (prop) =>
    prop !== 'isMobile' &&
    prop !== 'isOpen' &&
    prop !== 'isSuperAdmin' &&
    prop !== 'isCustomerAccountPage',
})(({ theme, isMobile, isOpen, isSuperAdmin, isCustomerAccountPage }: StylesTypes) => ({
  background:
    isSuperAdmin && isCustomerAccountPage ? theme.palette.grey[600] : theme.sidebar.background,
  position: isMobile ? 'fixed' : 'sticky',
  top: '0',
  height: '100vh',
  minWidth: `${NAV.W_SIDE_BAR}px`,
  width: `${NAV.W_SIDE_BAR}px`,
  borderRight:
    isSuperAdmin && isCustomerAccountPage ? 'none' : `1px dashed ${theme.palette.divider}`,
  transition: isMobile ? 'transform 0.3s ease-out' : 'none',
  transform: !isOpen && isMobile ? 'translateX(-100%)' : 'translateX(0)',
  overflow: isOpen ? 'visible' : 'hidden',
  zIndex: '100',
}));

const CustomScroll = styled(Box)(scrollbarOverride, {
  width: '100%',
  height: '100%',
  padding: '32px 16px 0 16px',
});

export { StyledSideBar, CustomScroll };
