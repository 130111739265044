import { TableRow, TableCell, Typography, useTheme } from '@mui/material';
import { isNull } from 'lodash';
import { formatSecondsToHoursAndMinutes } from 'src/utilities/date-utility';

type Props = {
  row: any;
  columns: { key: string; value: string }[];
};

export const DataReportTableRowItem = ({ row, columns }: Props) => {
  const theme = useTheme();
  return (
    <TableRow hover data-testId="table-row" sx={{ background: theme.palette.common.white }}>
      {columns.map((column) => {
        const columnData =
          column.key.includes('dailyRuntime') && row[column.key]
            ? formatSecondsToHoursAndMinutes(row[column.key])
            : row[column.key];

        return (
          <TableCell sx={{ minWidth: '50px' }} key={column.key} data-testId="date-row">
            <Typography variant="subtitle2">
              {!isNull(columnData) && typeof columnData !== 'undefined' ? columnData : '-'}
            </Typography>
          </TableCell>
        );
      })}
    </TableRow>
  );
};
