import { ArrowForward } from '@mui/icons-material';
import { Stack, SvgIcon, Typography, useTheme } from '@mui/material';
import dayjs from 'dayjs';
import { FunctionComponent } from 'react';
import { useHtmlElement, useResponsive } from 'src/hooks';
import HistoryPopover from './HistoryPopover';
import { VersionHistoryItem } from '../types';
import { formatUTCDateToLocalDate } from 'src/utilities';

interface IFormHistoryProps {
  lastVersion: VersionHistoryItem | null;
  versions: VersionHistoryItem[];
  onChangeVersionHistory: (version: VersionHistoryItem) => void;
}

const FormHistory: FunctionComponent<IFormHistoryProps> = ({
  lastVersion,
  onChangeVersionHistory,
  versions,
}) => {
  const { addHtmlElement, removeHtmlElement, htmlElement } = useHtmlElement();
  const isMobile = useResponsive('down', 'lg');
  const theme = useTheme();
  return (
    <Stack
      sx={{
        backgroundColor: theme.palette.background.default,
        paddingBlock: isMobile ? '12px' : '28px',
      }}
    >
      <Stack flexDirection={isMobile ? 'column' : 'row'} gap={isMobile ? '8px' : '16px'}>
        {lastVersion && (
          <Typography sx={{ fontSize: '12px', fontWeight: '500' }}>
            Last edited:{' '}
            {dayjs(formatUTCDateToLocalDate(lastVersion.changedAt)).format('M/D/YYYY, h:mm A')}
          </Typography>
        )}
        <Typography sx={{ fontSize: '12px', fontWeight: '500' }}>
          Edited by: {lastVersion?.changedBy || 'System'}
        </Typography>
        <HistoryPopover
          onExpand={onChangeVersionHistory}
          open={!!htmlElement}
          previousVersions={versions}
          element={htmlElement}
          onClose={() => {
            removeHtmlElement();
          }}
        />
        <Stack
          onClick={(event) => {
            addHtmlElement(event.currentTarget);
          }}
          flexDirection="row"
          alignItems="center"
          gap="6px"
        >
          <SvgIcon color="error" fontSize="small" component={ArrowForward} />
          <Typography
            color="error"
            sx={{
              textDecoration: 'underline',
              cursor: 'pointer',
              fontSize: '12px',
              fontWeight: '500',
            }}
          >
            Version History
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default FormHistory;
