import { IconsTypesProps } from 'src/assets/icons/types';

export const WaterTreatmentPlantFilterIcon = ({ width = 22, height = 22 }: IconsTypesProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.81641 11.2492C4.81641 10.8883 5.12618 10.5957 5.5083 10.5957H20.1245C20.5066 10.5957 20.8164 10.8883 20.8164 11.2492V14.8862C20.8164 15.0672 20.737 15.24 20.597 15.3636L13.3296 21.7796C13.0647 22.0135 12.6536 22.0145 12.3874 21.782L5.03855 15.3639C4.89693 15.2402 4.81641 15.0663 4.81641 14.8841V11.2492Z"
      fill="#049ACC"
    />
    <path
      d="M3.81641 3.51789C3.81641 3.14234 4.13876 2.83789 4.53641 2.83789H21.0964C21.4941 2.83789 21.8164 3.14234 21.8164 3.51789V15.2895C21.8164 15.4797 21.7321 15.6612 21.5839 15.7899L13.3039 22.9843C13.0283 23.2237 12.6045 23.2237 12.3289 22.9843L4.04893 15.7899C3.90073 15.6612 3.81641 15.4797 3.81641 15.2895V3.51789Z"
      stroke="#0461C1"
    />
  </svg>
);
