import { Box, Button, useTheme } from '@mui/material';
import { PlusFillIcon } from 'src/assets';
import { useResponsive } from 'src/hooks';

type AddWidgetBoxProps = {
  title: string;
  onToggle: () => void;
  fullWidth?: boolean;
  btnTestId?: string;
};

export const AddWidgetBox = ({
  onToggle,
  title,
  fullWidth,
  btnTestId = 'add-widget-button',
}: AddWidgetBoxProps) => {
  const isMobile = useResponsive('down', 'sm');
  const theme = useTheme();
  const buttonSize = isMobile ? 'small' : 'large';
  const iconSize = isMobile ? 14 : 20;

  const onToggleHandler = () => {
    onToggle();
  };

  return (
    <Box>
      <Box
        sx={{
          width: fullWidth ? '100%' : isMobile ? '300px' : '344px',
          height: '252px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          border: '1px solid',
          borderColor: theme.palette.background.neutral,
          backgroundColor: theme.palette.background.default,
          borderRadius: '16px',
          margin: isMobile ? '0 auto' : 'none',
          marginTop: '16px',
        }}
      >
        <Button
          onClick={onToggleHandler}
          size={buttonSize}
          variant="contained"
          startIcon={<PlusFillIcon width={iconSize} height={iconSize} />}
          data-sm={btnTestId}
        >
          {title}
        </Button>
      </Box>
    </Box>
  );
};
