export class NewCustomerAccount {
  customerName: string;

  companyName?: string;

  businessAddress?: BusinessAddress;

  status: string;

  selectedThemeId?: string;

  inactiveThemeIds?: string[];

  constructor(
    customerName: string,
    status: string,
    companyName?: string,
    businessAddress?: BusinessAddress,
    selectedThemeId?: string,
    inactiveThemeIds?: string[]
  ) {
    this.customerName = customerName;
    this.status = status;
    this.companyName = companyName;
    this.businessAddress = businessAddress;
    this.selectedThemeId = selectedThemeId;
    this.inactiveThemeIds = inactiveThemeIds;
  }
}

export class UpdateCustomerAccount extends NewCustomerAccount {}
export class CustomerAccount extends NewCustomerAccount {
  id: string;

  createdAt: string;

  updatedAt: string;

  constructor(
    id: string,
    customerName: string,
    status: string,
    createdAt: string,
    updatedAt: string,
    companyName?: string,
    businessAddress?: BusinessAddress,
    selectedThemeId?: string,
    inactiveThemeIds?: string[]
  ) {
    super(customerName, status, companyName, businessAddress, selectedThemeId, inactiveThemeIds);
    this.id = id;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
  }
}

export class BusinessAddress {
  street?: string;

  city?: string;

  country?: string;

  state?: string;

  zip?: string;

  constructor(businessAddress?: BusinessAddress) {
    this.street = businessAddress?.street;
    this.city = businessAddress?.city;
    this.country = businessAddress?.country;
    this.state = businessAddress?.state;
    this.zip = businessAddress?.zip;
  }

  public toString = (): string => {
    const propArray = [this.street, this.city, this.country, this.state, this.zip];
    return propArray.filter((entry) => !!entry).join(', ');
  };
}
