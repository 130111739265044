import { useAuthentication } from 'src/features/authentication/context';
import { Protected } from 'src/features/protected-route/components';
import { LoginLayout } from 'src/layouts';
import { LinkSentPage } from '../../pages';
import { TENANT_SWITCH_PATH } from '../../config';
import { ElementProps } from '../../types/element';
import { LoadingElement } from '../LoadingElement';

export const ForgotPasswordLinkSentElement = ({ isSuperAdmin }: ElementProps) => {
  const { getUser, loggedIn } = useAuthentication();

  const getUniqueRedirectPath = () => {
    if (getUser()) return TENANT_SWITCH_PATH;
  };

  const ForgotPasswordLinkSentRouteElement = () => (
    <Protected
      isAuthenticated={!getUser()}
      isAuthorized={true}
      uniqueRedirectPath={getUniqueRedirectPath()}
    >
      <LoginLayout>
        <LinkSentPage />
      </LoginLayout>
    </Protected>
  );

  const getForgotPasswordLinkSentElement = () => {
    if (!loggedIn.loaded) {
      return <LoadingElement />;
    }

    return <ForgotPasswordLinkSentRouteElement />;
  };

  return getForgotPasswordLinkSentElement();
};
