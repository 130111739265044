import { Box, Stack, styled } from '@mui/material';
import { StyledNavigationItemProps } from './types';

export const StyledNavigationItem = styled(Stack)<StyledNavigationItemProps>(
  ({ theme, isActive }) => ({
    borderBottom: '2px',
    color: isActive ? theme.palette.grey[800] : theme.palette.grey[500],
    fontWeight: isActive ? 'bold' : undefined,
    borderColor: 'transparent',
    paddingBlock: theme.spacing(1),
    marginRight: theme.spacing(2),
    position: 'relative',
    cursor: 'pointer',
  })
);

export const Header = styled(Box)(({ theme }) => ({
  background: theme.palette.background.default,

  paddingBlock: theme.spacing(1),
  paddingInline: theme.spacing(2),
  width: '100%',
  [theme.breakpoints.up('md')]: {
    margin: '12px',
    paddingInline: theme.spacing(3),
    borderRadius: '16px',
  },
}));

export const StyledSettingsBox = styled(Box)(({ theme }) => ({
  marginRight: '6px',
  marginLeft: '22px',
  display: 'flex',
  alignItems: 'center',
  [theme.breakpoints.down('md')]: {
    marginRight: 0,
  },
}));

export const StyledExpandMoreBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  [theme.breakpoints.up('md')]: {
    display: 'none',
  },
}));
