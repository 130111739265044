import { Box, Typography, Button, useTheme } from '@mui/material';

type ModalRemoveContentProps = {
  headerTitle: string;
  width: number;
  widgetName: string;
  onRemove: () => Promise<void>;
  onClose: () => void;
};

export const ModalRemoveContent = ({
  headerTitle,
  width,
  widgetName,
  onRemove,
  onClose,
}: ModalRemoveContentProps) => {
  const breakLine = () => <Box sx={{ background: 'rgba(145, 158, 171, 0.24)', height: '1px' }} />;
  const theme = useTheme();

  const onRemoveHandler = () => {
    onRemove().catch((error) => console.error(error));
  };

  const onCloseHandler = () => {
    onClose();
  };

  return (
    <Box
      sx={{
        width: `${width}px`,
        maxWidth: '100%',
        backgroundColor: theme.palette.background.default,
      }}
      data-sm="remove-widget-modal"
    >
      <Box sx={{ padding: '24px' }} component="header">
        <Typography variant="h6">{headerTitle}</Typography>
      </Box>
      {breakLine()}
      <Box sx={{ padding: '24px' }}>
        <Typography sx={{ color: '#566369' }} variant="body1">
          Are you sure you want to remove
          <span style={{ fontWeight: '600', display: 'inline' }}> {widgetName} </span> widget from
          Site Overview screen?
        </Typography>
      </Box>
      {breakLine()}
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', padding: '24px' }} component="footer">
        <Button sx={{ mr: '12px' }} variant="outlined" data-sm="no-button" onClick={onCloseHandler}>
          No
        </Button>
        <Button variant="contained" data-sm="yes-button" onClick={onRemoveHandler}>
          Yes
        </Button>
      </Box>
    </Box>
  );
};
