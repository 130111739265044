const titleBox = {
  padding: '24px 24px 16px 24px',
};

const titleStyle = {
  fontWeight: '700',
  fontSize: '16px',
};

const formGroup = {
  padding: '8px 15px',
};

export { titleBox, titleStyle, formGroup };
