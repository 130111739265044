import { FormProvider as Form, UseFormReturn } from 'react-hook-form';

type Props = {
  children: React.ReactNode;
  methods: UseFormReturn<any>;
  onSubmit?: VoidFunction;
};

export const FormProvider = ({ children, onSubmit, methods }: Props) => {
  const handleFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (onSubmit) {
      onSubmit();
    }

    event.stopPropagation();
  };

  return (
    <Form {...methods}>
      <form onSubmit={handleFormSubmit}>{children}</form>
    </Form>
  );
};
