import { IconsTypesProps } from './types';
import { CURRENT_COLOR } from 'src/config';

export const SearchFillIcon = ({ fill, width = 24, height = 24 }: IconsTypesProps) => {
  const color = fill ?? CURRENT_COLOR;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24">
      <g id="evaSearchFill0">
        <g id="evaSearchFill1">
          <path
            id="evaSearchFill2"
            fill={color}
            d="m20.71 19.29l-3.4-3.39A7.92 7.92 0 0 0 19 11a8 8 0 1 0-8 8a7.92 7.92 0 0 0 4.9-1.69l3.39 3.4a1 1 0 0 0 1.42 0a1 1 0 0 0 0-1.42ZM5 11a6 6 0 1 1 6 6a6 6 0 0 1-6-6Z"
          />
        </g>
      </g>
    </svg>
  );
};
