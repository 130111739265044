import { GATEWAYS_URL_END, SITES_ROOT, api } from '../api';
import {
  AdvancedTagMappingUpdatePayload,
  GatewayInput,
  RelevantModeResponseBody,
} from 'src/types/gateways';

// SUMMARY:
// Endpoint for getting a specific advanced tag mapping (gateway input info)
const getById = async (
  customerId: string,
  siteId: string,
  gatewayId: string,
  inputId: string
): Promise<GatewayInput> => {
  const response = await api({
    method: 'get',
    url: `${SITES_ROOT}/${customerId}/${siteId}${GATEWAYS_URL_END}/${gatewayId}/input/${inputId}`,
  });

  return response.data;
};

// SUMMARY:
// Endpoint for updating a specific advanced tag mapping (gateway input info)
const update = async (
  data: AdvancedTagMappingUpdatePayload,
  customerId: string,
  siteId: string,
  gatewayId: string,
  inputId: string
) => {
  const response = await api({
    method: 'patch',
    url: `${SITES_ROOT}/${customerId}/${siteId}${GATEWAYS_URL_END}/${gatewayId}/input/${inputId}`,
    data,
  });

  return response.data;
};

// SUMMARY:
// Endpoint for getting all relevant modes for a specific controller
const getRelevantModes = async (
  customerId: string,
  siteId: string,
  gatewayId: string,
  controllerId: string
): Promise<RelevantModeResponseBody[]> => {
  const response = await api({
    method: 'get',
    url: `${SITES_ROOT}/${customerId}/${siteId}${GATEWAYS_URL_END}/${gatewayId}/relevant-mode/${controllerId}`,
  });

  return response.data;
};

export const AdvancedTagMappingService = {
  getById,
  getRelevantModes,
  update,
};
