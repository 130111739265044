import { AlarmPriority, AlarmsNotification } from 'src/types/alarms';

type IsCheckedArguments = {
  data: AlarmsNotification;
  alarmPriority: AlarmPriority;
};

export const isChecked = ({ data, alarmPriority }: IsCheckedArguments) =>
  data?.alarmPriorities?.length &&
  data.alarmPriorities?.find((item) => item === alarmPriority) !== undefined
    ? true
    : false;
