import { IconsTypesProps } from './types';
import { CURRENT_COLOR } from 'src/config';

export const UnlockIcon = ({ fill }: IconsTypesProps) => {
  const color = fill ?? CURRENT_COLOR;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="15" viewBox="0 0 12 15" fill="none">
      <path
        d="M2.00008 14.6666C1.63342 14.6666 1.31942 14.536 1.05808 14.2746C0.796749 14.0133 0.666305 13.6995 0.666749 13.3333V6.66663C0.666749 6.29996 0.797416 5.98596 1.05875 5.72463C1.32008 5.46329 1.63386 5.33285 2.00008 5.33329H8.00008V3.99996C8.00008 3.4444 7.80564 2.97218 7.41675 2.58329C7.02786 2.1944 6.55564 1.99996 6.00008 1.99996C5.54453 1.99996 5.14164 2.13618 4.79142 2.40863C4.44119 2.68107 4.20542 3.02263 4.08408 3.43329C4.02853 3.59996 3.9423 3.73618 3.82542 3.84196C3.70853 3.94774 3.57253 4.0004 3.41742 3.99996C3.19519 3.99996 3.01742 3.92485 2.88408 3.77463C2.75075 3.6244 2.7063 3.45507 2.75075 3.26663C2.91697 2.52218 3.30008 1.90263 3.90008 1.40796C4.50008 0.913293 5.20008 0.666182 6.00008 0.666627C6.92231 0.666627 7.70853 0.991738 8.35875 1.64196C9.00897 2.29218 9.33386 3.07818 9.33342 3.99996V5.33329H10.0001C10.3667 5.33329 10.6807 5.46396 10.9421 5.72529C11.2034 5.98663 11.3339 6.3004 11.3334 6.66663V13.3333C11.3334 13.7 11.2028 14.014 10.9414 14.2753C10.6801 14.5366 10.3663 14.6671 10.0001 14.6666H2.00008ZM6.00008 11.3333C6.36675 11.3333 6.68075 11.2026 6.94208 10.9413C7.20342 10.68 7.33386 10.3662 7.33342 9.99996C7.33342 9.63329 7.20275 9.31929 6.94142 9.05796C6.68008 8.79663 6.3663 8.66618 6.00008 8.66663C5.63342 8.66663 5.31942 8.79729 5.05808 9.05863C4.79675 9.31996 4.66631 9.63374 4.66675 9.99996C4.66675 10.3666 4.79742 10.6806 5.05875 10.942C5.32008 11.2033 5.63386 11.3337 6.00008 11.3333Z"
        fill={color}
      />
    </svg>
  );
};
