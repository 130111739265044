import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';

const StyledTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontWeight: '700',
  fontSize: '24px',
  whiteSpace: 'nowrap',

  overflow: 'hidden',
  textOverflow: 'ellipsis',
  [theme.breakpoints.down('sm')]: {
    fontSize: '18px',
  },
}));

const editIconBox = {
  cursor: 'pointer',
  marginTop: '5px',
  marginLeft: '12px',
};

export { StyledTitle, editIconBox };
