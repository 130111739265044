import { useState } from 'react';
import { Alert, Box, Collapse } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { CloseIcon } from 'src/assets';
import { alertBox, collapseBox, alert, StyledButton } from './style';

type Props = {
  alertMessage: string;
};

export const AlertBox = ({ alertMessage }: Props) => {
  const [open, setOpen] = useState<boolean>(false);

  const onClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={alertBox}>
      <Collapse in={open} sx={collapseBox}>
        <Alert
          sx={alert}
          action={
            <IconButton aria-label="close" color="inherit" size="small" onClick={onClose}>
              <CloseIcon width={10} height={10} />
            </IconButton>
          }
          severity="error"
        >
          {alertMessage}
          <StyledButton variant="outlined">Go to Site</StyledButton>
        </Alert>
      </Collapse>
    </Box>
  );
};
