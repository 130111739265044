import { debounce } from 'lodash';
import { useState, useEffect } from 'react';
import { SitesService } from 'src/services/sites';
import { TagResponse } from 'src/types';

type Props = {
  customerId: string;
  siteId: string;
  searchQuery: string;
};

export const useGetSiteTags = ({ customerId, siteId, searchQuery }: Props) => {
  const [tags, setTags] = useState<TagResponse[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState('');

  useEffect(() => {
    const handler = debounce(() => {
      setDebouncedSearchQuery(searchQuery);
    }, 300);
    handler();

    return () => {
      handler.cancel();
    };
  }, [searchQuery]);

  useEffect(() => {
    if (!customerId || !siteId) return;
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await SitesService.getTags(
          customerId,
          siteId,
          debouncedSearchQuery.toLowerCase()
        );
        setTags(response);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [customerId, siteId, debouncedSearchQuery]);

  return { tags, isLoading };
};
