import { TableRow, TableCell, IconButton, Typography, useTheme, Button } from '@mui/material';
import { AlertDialog, Label, MenuPopover } from 'src/components';
import { MenuPopoverItem } from './MenuPopoverItem';
import { MoreVerticalFillIcon, EditIcon, LockIcon, UnlockIcon } from 'src/assets';
import { useHtmlElement, useToggle } from 'src/hooks';
import { BusinessAddress, CustomerAccount } from '../types/customer-account';
import {
  LOCK_ALERT_BUTTON_LABEL,
  LOCK_ALERT_PRIMARY_TEXT,
  LOCK_ALERT_SECONDARY_TEXT,
  LOCK_ALERT_TITLE,
  POPOVER_EDIT_DESCRIPTION,
  POPOVER_LOCK_DESCRIPTION,
  POPOVER_UNLOCK_DESCRIPTION,
  UNLOCK_ALERT_BUTTON_LABEL,
  UNLOCK_ALERT_PRIMARY_TEXT,
  UNLOCK_ALERT_TITLE,
} from '../config';
import { SITES_MANAGEMENT_PATH } from 'src/routes/config';
import { StyledTableLink } from './style';
import { useNavigate } from 'react-router-dom';

type Props = {
  row: CustomerAccount;
  handleEdit: VoidFunction;
  onLock: VoidFunction;
  onUnlock: VoidFunction;
};

export const CustomerAccountTableRow = ({ row, handleEdit, onLock, onUnlock }: Props) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { state: toggleState, onToggle } = useToggle();
  const { customerName, businessAddress, status } = row;
  const { htmlElement, addHtmlElement, removeHtmlElement } = useHtmlElement();
  const businessAddressValues = new BusinessAddress(businessAddress);

  const isActive = status === 'active';

  const onHandleClick = () => {
    navigate(`${SITES_MANAGEMENT_PATH}?customerId=${row.id}`);
  };

  const AlertDialogLockContent = () => (
    <>
      <Typography color="text.secondary">
        {LOCK_ALERT_PRIMARY_TEXT} <strong>{customerName}</strong>?
      </Typography>
      <br />
      <Typography color="text.secondary">{LOCK_ALERT_SECONDARY_TEXT}</Typography>
    </>
  );

  const AlertDialogUnlockContent = () => (
    <>
      <Typography color="text.secondary">
        {UNLOCK_ALERT_PRIMARY_TEXT} <strong>{customerName}</strong>?
      </Typography>
    </>
  );

  const handlePopoverStatusClick = () => {
    onToggle();
    removeHtmlElement();
  };

  const handleDialogClick = () => {
    isActive ? onLock() : onUnlock();
    onToggle();
  };

  return (
    <>
      <TableRow
        sx={{ cursor: 'pointer' }}
        hover
        data-sm="table-row"
        data-testid="customer-table-row"
      >
        <TableCell
          data-sm="customer-name-row"
          sx={{ maxWidth: 'sm', whiteSpace: 'normal', wordWrap: 'break-word' }}
        >
          <StyledTableLink to={`${SITES_MANAGEMENT_PATH}?customerId=${row.id}`}>
            <Typography sx={{ width: 'fit-content' }} variant="subtitle2">
              {customerName}
            </Typography>
          </StyledTableLink>
        </TableCell>

        <TableCell
          data-sm="business-address-row"
          align="left"
          sx={{ maxWidth: 'sm', whiteSpace: 'normal', wordWrap: 'break-word' }}
          onClick={onHandleClick}
        >
          {`${businessAddressValues}`}
        </TableCell>

        <TableCell data-sm="status-row" align="left" onClick={onHandleClick}>
          <Label
            variant="soft"
            color={(status === 'locked' && 'error') || 'success'}
            sx={{ textTransform: 'capitalize' }}
          >
            {status}
          </Label>
        </TableCell>

        <TableCell data-sm="context-menu-row" align="right">
          <IconButton
            color={htmlElement ? 'inherit' : 'default'}
            onClick={(event) => addHtmlElement(event.currentTarget)}
            data-sm="context-menu-button"
            data-testid="context-menu-button"
          >
            <MoreVerticalFillIcon />
          </IconButton>
          <MenuPopover
            open={htmlElement}
            onClose={removeHtmlElement}
            arrow="right-top"
            sx={{ width: 103 }}
          >
            <MenuPopoverItem
              icon={<EditIcon />}
              description={POPOVER_EDIT_DESCRIPTION}
              handleClick={handleEdit}
              testId="edit-button"
            />
            {isActive ? (
              <MenuPopoverItem
                icon={<LockIcon />}
                description={POPOVER_LOCK_DESCRIPTION}
                color={theme.palette.error.main}
                handleClick={handlePopoverStatusClick}
                testId="lock-button"
              />
            ) : (
              <MenuPopoverItem
                icon={<UnlockIcon />}
                description={POPOVER_UNLOCK_DESCRIPTION}
                color={theme.palette.success.main}
                handleClick={handlePopoverStatusClick}
                testId="unlock-button"
              />
            )}
          </MenuPopover>
          <AlertDialog
            testId={isActive ? 'lock-customer-account-modal' : 'unlock-customer-account-modal'}
            open={toggleState}
            onClose={onToggle}
            title={isActive ? LOCK_ALERT_TITLE : UNLOCK_ALERT_TITLE}
            customContent={isActive ? <AlertDialogLockContent /> : <AlertDialogUnlockContent />}
            action={
              <>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={onToggle}
                  data-sm="cancel-button"
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleDialogClick}
                  data-sm={isActive ? 'yes-lock-button' : 'yes-unlock-button'}
                  data-testid={isActive ? 'yes-lock-button' : 'yes-unlock-button'}
                >
                  {isActive ? LOCK_ALERT_BUTTON_LABEL : UNLOCK_ALERT_BUTTON_LABEL}
                </Button>
              </>
            }
          />
        </TableCell>
      </TableRow>
    </>
  );
};
