import { Stack, Theme, styled } from '@mui/material';
import { scrollbarOverride } from 'src/styles';

type StyledContainerStackType = {
  isMobile: boolean;
  theme: Theme;
};

const StyledCommonContainerStack = styled(Stack, {
  shouldForwardProp: (prop) => prop !== 'isMobile' && prop !== 'customErrors',
})(({ isMobile, theme }: StyledContainerStackType) => ({
  flexDirection: isMobile ? 'column' : 'row',
  width: '100%',
  borderRadius: '8px',
  position: 'relative',
  padding: isMobile ? '16px 8px' : 0,
  cursor: 'pointer',
  '&:hover': {
    borderColor: theme.palette.text.primary,
  },
}));

export const StyledUserContainerStack = styled(StyledCommonContainerStack, {
  shouldForwardProp: (prop) => prop !== 'isMobile' && prop !== 'customErrors',
})(({ isMobile, theme }: StyledContainerStackType) => ({
  minHeight: isMobile ? 'auto' : '58px',
  border: `1px solid ${theme.palette.grey[400]}`,
}));

export const StyledStack = styled(Stack)(scrollbarOverride);
