import { Theme, styled } from '@mui/material/styles';
import { Box } from '@mui/material';

type StylesTypes = {
  isMobile: boolean;
};

type StyledSearchSiteBoxType = {
  theme: Theme;
};

const StyledHeaderSiteContent = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isMobile',
})(({ isMobile }: StylesTypes) => ({
  marginTop: '24px',
  display: 'flex',
  position: 'relative',
  justifyContent: 'flex-end',
  alignItems: 'center',
}));

const addSiteBtnBox = {
  display: 'flex',
  justifyContent: 'end',
};

const filterIconBox = {
  marginLeft: 'auto',
  paddingRight: '19px',
};

const StyledSearchBox = styled(Box)(({ theme }: StyledSearchSiteBoxType) => ({
  marginTop: '32px',
  display: 'flex',
  padding: '15px',
  position: 'relative',
  justifyContent: 'flex-end',
  alignItems: 'center',
  borderRadius: '15px',
  backgroundColor: theme.palette.background.default,
  boxShadow: theme.customShadows?.card,
}));

const siteSearchBar = {
  margin: '2px',
};

const toggleBtnBox = {
  display: 'flex',
  marginRight: '20px',
  justifyContent: 'center',
};

export {
  StyledHeaderSiteContent,
  addSiteBtnBox,
  filterIconBox,
  StyledSearchBox,
  toggleBtnBox,
  siteSearchBar,
};
