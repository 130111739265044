import { IconsTypesProps } from './types';
import { CURRENT_COLOR } from 'src/config';

export const AnalyticsIcon = ({ fill }: IconsTypesProps) => {
  const color = fill ?? CURRENT_COLOR;

  return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.4274 2.75H4.59408C3.58574 2.75 2.76074 3.575 2.76074 4.58333V17.4167C2.76074 18.425 3.58574 19.25 4.59408 19.25H17.4274C18.4357 19.25 19.2607 18.425 19.2607 17.4167V4.58333C19.2607 3.575 18.4357 2.75 17.4274 2.75ZM7.34408 15.5833C6.83991 15.5833 6.42741 15.1708 6.42741 14.6667V11.9167C6.42741 11.4125 6.83991 11 7.34408 11C7.84824 11 8.26074 11.4125 8.26074 11.9167V14.6667C8.26074 15.1708 7.84824 15.5833 7.34408 15.5833ZM11.0107 15.5833C10.5066 15.5833 10.0941 15.1708 10.0941 14.6667V13.75C10.0941 13.2458 10.5066 12.8333 11.0107 12.8333C11.5149 12.8333 11.9274 13.2458 11.9274 13.75V14.6667C11.9274 15.1708 11.5149 15.5833 11.0107 15.5833ZM11.0107 11C10.5066 11 10.0941 10.5875 10.0941 10.0833C10.0941 9.57917 10.5066 9.16667 11.0107 9.16667C11.5149 9.16667 11.9274 9.57917 11.9274 10.0833C11.9274 10.5875 11.5149 11 11.0107 11ZM14.6774 15.5833C14.1732 15.5833 13.7607 15.1708 13.7607 14.6667V7.33333C13.7607 6.82917 14.1732 6.41667 14.6774 6.41667C15.1816 6.41667 15.5941 6.82917 15.5941 7.33333V14.6667C15.5941 15.1708 15.1816 15.5833 14.6774 15.5833Z"
        fill={color}
      />
    </svg>
  );
};
