import { IconsTypesProps } from './types';
import { CURRENT_COLOR } from 'src/config';

export const MapDirectionIcon = ({ fill }: IconsTypesProps) => {
  const color = fill ?? CURRENT_COLOR;

  return (
    <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.76237 8.67513L1.36654 6.48763C1.18598 6.41819 1.05404 6.31055 0.970703 6.16471C0.88737 6.01888 0.845703 5.86958 0.845703 5.7168C0.845703 5.56402 0.890842 5.41471 0.98112 5.26888C1.0714 5.12305 1.20681 5.01541 1.38737 4.94596L14.179 0.195964C14.3457 0.126519 14.5054 0.11263 14.6582 0.154297C14.811 0.195964 14.9429 0.272352 15.054 0.383464C15.1651 0.494575 15.2415 0.626519 15.2832 0.779297C15.3249 0.932075 15.311 1.0918 15.2415 1.25846L10.4915 14.0501C10.4221 14.2307 10.3145 14.3661 10.1686 14.4564C10.0228 14.5467 9.87348 14.5918 9.7207 14.5918C9.56793 14.5918 9.41862 14.5501 9.27279 14.4668C9.12695 14.3835 9.01931 14.2515 8.94987 14.071L6.76237 8.67513ZM9.67904 11.4668L13.054 2.38346L3.9707 5.75846L8.05404 7.38346L9.67904 11.4668Z"
        fill={color}
      />
    </svg>
  );
};
