import { IconsTypesProps } from './types';
import { CURRENT_COLOR } from 'src/config';

export const PrinterIcon = ({ fill }: IconsTypesProps) => {
  const color = fill ?? CURRENT_COLOR;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M16.1332 5.83333H14.9999V4.16667C15.0179 3.74361 14.8676 3.33062 14.5817 3.01822C14.2959 2.70581 13.8979 2.51946 13.4749 2.5H6.52485C6.10185 2.51946 5.70382 2.70581 5.41797 3.01822C5.13212 3.33062 4.98176 3.74361 4.99985 4.16667V5.83333H3.86652C3.28007 5.83774 2.71927 6.07437 2.30692 6.4914C1.89458 6.90843 1.6643 7.47187 1.66652 8.05833V13.6083C1.6643 14.1948 1.89458 14.7582 2.30692 15.1753C2.71927 15.5923 3.28007 15.8289 3.86652 15.8333H4.58319C4.58319 16.2754 4.75878 16.6993 5.07134 17.0118C5.3839 17.3244 5.80783 17.5 6.24985 17.5H13.7499C14.1919 17.5 14.6158 17.3244 14.9284 17.0118C15.2409 16.6993 15.4165 16.2754 15.4165 15.8333H16.1332C16.7196 15.8289 17.2804 15.5923 17.6928 15.1753C18.1051 14.7582 18.3354 14.1948 18.3332 13.6083V8.05833C18.3354 7.47187 18.1051 6.90843 17.6928 6.4914C17.2804 6.07437 16.7196 5.83774 16.1332 5.83333ZM6.66652 4.16667H13.3332V5.83333H6.66652V4.16667ZM6.24985 15.8333V12.5H13.7499V15.8333H6.24985Z"
        fill={color}
      />
    </svg>
  );
};
