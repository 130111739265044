import { Theme, styled } from '@mui/material/styles';
import { Box } from '@mui/material';

type ThemeType = {
  theme: Theme;
};

const StyledSelectedDataBox = styled(Box)(({ theme }: ThemeType) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '28px 22px 16px 24px',
  marginBottom: '10px',
  position: 'sticky',
  top: 0,
  left: 0,
  zIndex: '2',
  backgroundColor: theme.palette.background.default,
}));

export { StyledSelectedDataBox };
