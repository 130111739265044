import { useState, useEffect } from 'react';
import { ReadingsService } from 'src/services';
import { subDays, subHours } from 'date-fns';
import { calculateOffsetTimestamp } from 'src/utilities';

type UseGatewayReadingsArgs = {
  gatewayId?: string;
  tagId?: string;
  isPumpRuntime?: boolean;
  isPumpCycleCount?: boolean;
  siteTimeZone?: string;
};

export const useGatewayReadings = ({
  gatewayId,
  tagId,
  isPumpRuntime,
  isPumpCycleCount,
  siteTimeZone,
}: UseGatewayReadingsArgs) => {
  const [readings, setReadings] = useState<any>([]);
  const [isDataReady, setIsDataReady] = useState<boolean>(false);

  const now = new Date();
  const threeHoursAgo = subHours(now, 3);
  const formattedStartDateThreeHoursAgo = threeHoursAgo.toISOString();
  const formattedEndDate = now.toISOString();

  const sixDaysAgo = subDays(now, 6);
  const formattedStartDateSixDaysAgo = sixDaysAgo.toISOString();

  const stardDate =
    isPumpRuntime || isPumpCycleCount
      ? formattedStartDateSixDaysAgo
      : formattedStartDateThreeHoursAgo;
  const aggregationType = isPumpRuntime || isPumpCycleCount ? 'one_day' : null;

  const isOffsetApplyed = (isPumpRuntime && siteTimeZone) || (isPumpCycleCount && siteTimeZone);
  const offsetStartDate = calculateOffsetTimestamp(formattedStartDateSixDaysAgo, siteTimeZone!);
  const offsetEndDate = calculateOffsetTimestamp(formattedEndDate, siteTimeZone!);

  useEffect(() => {
    if (!gatewayId || !tagId) return;
    ReadingsService.getReadingsNewDataStorage({
      gatewayId: gatewayId,
      startDate: isOffsetApplyed ? offsetStartDate! : stardDate,
      endDate: isOffsetApplyed ? offsetEndDate! : formattedEndDate,
      measurements: tagId,
      aggregationType: aggregationType,
      statistics: isPumpRuntime ? 'max' : null,
    })
      .then((res) => {
        setReadings(isPumpRuntime ? res.statistics : res.data);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setIsDataReady(true);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tagId, gatewayId, isPumpRuntime]);

  return { readings, isDataReady };
};
