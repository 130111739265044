import { FormControl, TextField, TextFieldProps } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import InputMask from 'react-input-mask';
import { FunctionComponent } from 'react';
import { Controller, useFormContext, ValidationRule } from 'react-hook-form';

interface IRHFTimePicekrProps extends Omit<TextFieldProps, 'name'> {
  name: string;
  rules?: { [key: string]: ValidationRule | ValidationRule[] };
}

const RHFTimePicker: FunctionComponent<IRHFTimePicekrProps> = ({
  name,
  rules,
  disabled,
  ...props
}) => {
  const { control } = useFormContext();

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <FormControl sx={{ width: '100%' }} component="fieldset">
        <Controller
          render={({ field }) => (
            <InputMask
              {...field}
              disabled={disabled}
              onKeyUp={() => {}}
              onKeyDown={() => {}}
              onChange={(event) => {
                const { value } = event.target;
                const [hh, mm, ss] = value.split(':');

                let minuteFirstDigit: undefined | number = parseInt(mm.split('')[0]);
                let secondFirstDigit: undefined | number = parseInt(ss.split('')[0]);
                let secondSecondDigit: undefined | number = parseInt(ss.split('')[1]);
                let minuteSecondDigit: undefined | number = parseInt(mm.split('')[1]);

                if (!isNaN(minuteFirstDigit) && minuteFirstDigit > 6) {
                  minuteFirstDigit = 0;
                }
                if (!isNaN(secondFirstDigit) && secondFirstDigit > 6) {
                  minuteFirstDigit = 0;
                }
                if (
                  !isNaN(minuteSecondDigit) &&
                  minuteFirstDigit === 6 &&
                  minuteSecondDigit !== 0
                ) {
                  minuteSecondDigit = 0;
                }
                if (
                  !isNaN(secondSecondDigit) &&
                  secondFirstDigit === 6 &&
                  secondSecondDigit !== 0
                ) {
                  secondSecondDigit = 0;
                }

                field.onChange(
                  `${hh}:${minuteFirstDigit}${minuteSecondDigit}:${secondFirstDigit}${secondSecondDigit}`
                );
              }}
              maskPlaceholder="99:99:99"
              mask="99:99:99"
            >
              {
                ((maskProps: any) => (
                  <TextField disabled={disabled} {...props} {...maskProps} />
                )) as any
              }
            </InputMask>
          )}
          rules={rules}
          name={name}
          control={control}
        />
      </FormControl>
    </LocalizationProvider>
  );
};

export default RHFTimePicker;
