import { IconsTypesProps } from './types';
import { CURRENT_COLOR } from 'src/config';

export const StopAlaramIcon = ({ fill, width = 20, height = 20 }: IconsTypesProps) => {
  const color = fill ?? CURRENT_COLOR;

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.6965 0H6.3146C6.02555 0 5.7365 0.122222 5.53639 0.322222L0.3224 5.53333C0.12229 5.73333 0 6.02222 0 6.31111V13.6778C0 13.9778 0.12229 14.2556 0.3224 14.4667L5.52528 19.6667C5.7365 19.8778 6.02555 20 6.3146 20H13.6853C13.9855 20 14.2634 19.8778 14.4747 19.6778L19.6775 14.4778C19.7811 14.3743 19.8629 14.2513 19.9183 14.1158C19.9736 13.9803 20.0014 13.8352 19.9999 13.6889V6.31111C19.9999 6.01111 19.8777 5.73333 19.6775 5.52222L14.4747 0.322222C14.2746 0.122222 13.9855 0 13.6965 0ZM10.0055 15.8889C9.20509 15.8889 8.56029 15.2444 8.56029 14.4444C8.56029 13.6444 9.20509 13 10.0055 13C10.806 13 11.4508 13.6444 11.4508 14.4444C11.4508 15.2444 10.806 15.8889 10.0055 15.8889ZM10.0055 11.1111C9.39408 11.1111 8.89381 10.6111 8.89381 10V5.55556C8.89381 4.94444 9.39408 4.44444 10.0055 4.44444C10.617 4.44444 11.1173 4.94444 11.1173 5.55556V10C11.1173 10.6111 10.617 11.1111 10.0055 11.1111Z"
        fill={color}
      />
    </svg>
  );
};
