import { createContext, FunctionComponent, PropsWithChildren, useContext } from 'react';
import { FormSchema } from './types';

const formRendererContext = createContext<FormSchema | null>(null);

const FormRendererContextProvider: FunctionComponent<PropsWithChildren<{ schema: FormSchema }>> = ({
  children,
  schema,
}) => <formRendererContext.Provider value={schema}>{children}</formRendererContext.Provider>;

export const useFormRendererData = () => useContext(formRendererContext);

export default FormRendererContextProvider;
