const loginLayout = {
  height: '100%',
  padding: '0px',
};
const content = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};
export { loginLayout, content };
