import { PointChartType } from '../types/point-chart';
import { POINT_CHARTS_LOCAL_STORAGE_KEY } from '../config/config';

const setLocalStorage = (key: string, value: string[]) => {
  const { localStorage } = window;
  const existingItems = localStorage.getItem(key);

  const newData = existingItems ? JSON.parse(existingItems) : [];

  newData.push(value);

  localStorage.setItem(key, JSON.stringify(newData));
};

const getLocalStorage = (key: string) =>
  window.localStorage.getItem(key) ? JSON.parse(window.localStorage.getItem(key)!) : '';

const getPointCharts = () => {
  const item = window.localStorage.getItem(POINT_CHARTS_LOCAL_STORAGE_KEY);

  if (!item) return [];

  return JSON.parse(item) as PointChartType[];
};

const addPointChart = (pointChart: PointChartType) => {
  const pointCharts = getPointCharts();
  pointCharts.push(pointChart);
  localStorage.setItem(POINT_CHARTS_LOCAL_STORAGE_KEY, JSON.stringify(pointCharts));
};

const removePointChart = (chartId: string) => {
  const pointCharts = getPointCharts();
  const newData = pointCharts.filter((element) => element.id !== chartId);
  localStorage.setItem(POINT_CHARTS_LOCAL_STORAGE_KEY, JSON.stringify(newData));
};

export const TrendsLocalStorageService = {
  set: setLocalStorage,
  get: getLocalStorage,
  getPointCharts: getPointCharts,
  addPointChart: addPointChart,
  removePointChart,
};
