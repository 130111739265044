import { useTheme, IconButton } from '@mui/material';
import { CloseIconV2 } from 'src/assets';
import {
  StyledNoLiveDataSiteCard,
  StyledNoLiveDataMessage,
  StyledEditSiteMessage,
  closeIconBox,
  StyledTextBox,
} from './style';
import { SitesService } from 'src/services/sites';

type Props = {
  handleEditSitePreview: (siteId: string | null) => void;
  siteId: string | null;
  isMobile?: boolean;
  testId?: string;
  customerId: string | null;
  removeNoLiveDataLabelHandler: () => void;
};

export const NoLiveDataSiteCard = ({
  handleEditSitePreview,
  siteId,
  isMobile,
  testId,
  customerId,
  removeNoLiveDataLabelHandler,
}: Props) => {
  const theme = useTheme();
  const greyColor = theme.palette.grey[500];

  const handleEditClick = (e: any) => {
    e.stopPropagation();
    handleEditSitePreview(siteId);
  };

  const editSitePreviewHandler = async () => {
    if (!siteId || !customerId) return;
    try {
      await SitesService.editSitePreview({
        siteId: siteId,
        customerId: customerId,
        tags: [],
        showLiveData: false,
      });
    } catch (error) {
      console.error(error);
    }
  };

  const onRemoveHandler = (e: any) => {
    e.stopPropagation();
    editSitePreviewHandler();
    removeNoLiveDataLabelHandler();
  };

  return (
    <StyledNoLiveDataSiteCard theme={theme} isMobile={isMobile ?? false} data-testid={testId}>
      <StyledTextBox theme={theme} isMobile={isMobile ?? false}>
        <StyledNoLiveDataMessage>
          No live data selected for this site preview.
        </StyledNoLiveDataMessage>
        <StyledEditSiteMessage
          theme={theme}
          isMobile={isMobile ?? false}
          onClick={(e) => {
            handleEditClick(e);
          }}
        >
          Edit Site Preview.
        </StyledEditSiteMessage>
      </StyledTextBox>
      <IconButton sx={closeIconBox} onClick={onRemoveHandler}>
        <CloseIconV2 width={16} height={16} fill={greyColor} />
      </IconButton>
    </StyledNoLiveDataSiteCard>
  );
};
