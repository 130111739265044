import { Box, Typography } from '@mui/material';
import { WidgetWrapper } from '../WidgetWrapper';
import {
  cardGraphicLevel,
  cardGraphicText,
  cardGraphic,
  cardGraphicInner,
  cardGraphicLabel,
  cardGraphicLabelLeft,
  cardGraphicLabelRight,
  unitWetWellLabel,
} from '../style';
import { isAlarmLevelValid, transferValueToNewRange } from '../../utils';
import { AlarmType } from '../../types';
import { AssetType } from 'src/features/sites/types/types';
import { useGetLatestGatewayReadings } from 'src/hooks';
import { WET_WELL_TAG_NAME } from 'src/features/sites';

type Props = {
  height: number;
  waterLevel: number | null;
  title: string;
  unit: string;
  alarms: AlarmType[];
  isEditMode?: boolean;
  widgetId?: string;
  toggleAddOrRemoveHandler?: () => void;
  testId?: string;
  asset: AssetType | undefined;
};

export const LiftStationWetWell = ({
  height,
  waterLevel,
  title,
  alarms,
  unit,
  isEditMode,
  widgetId,
  toggleAddOrRemoveHandler,
  testId,
  asset,
}: Props) => {
  const waterTowerAsset = asset?.assignedTags?.find(
    (tag) => tag?.tagName?.toLowerCase() === WET_WELL_TAG_NAME
  );

  const assignedTagId = waterTowerAsset?.assignedTagId;
  const gatewayId = waterTowerAsset?.assignedGatewayId;

  const { readings: latestReadings } = useGetLatestGatewayReadings({
    gatewayId: gatewayId,
    tagId: assignedTagId,
  });
  const readingsRawInputName = assignedTagId && latestReadings[assignedTagId];
  const readingsValues = readingsRawInputName?.values;
  const readingValueList = readingsValues && Object.values(readingsValues);
  const readingValue = readingValueList && readingValueList[0];

  const waterLevelData = waterLevel || Number(readingValue);

  const HEIGHT_DISPLAY = 100;
  const validLevel = waterLevelData !== null && waterLevelData !== -1 && !isNaN(waterLevelData);
  const waterLevelDisplay = validLevel
    ? transferValueToNewRange(0, height, 0, HEIGHT_DISPLAY, waterLevelData)
    : null;

  const handleAlarmsView = () =>
    alarms.map((alarm, index) => (
      <Box
        key={index}
        display="span"
        sx={{
          bottom: `${transferValueToNewRange(0, height, 0, HEIGHT_DISPLAY, alarm.level)}%`,
          ...cardGraphicLabel,
          ...(isAlarmLevelValid(alarm) ? cardGraphicLabelRight : {}),
        }}
      >
        {isAlarmLevelValid(alarm) ? alarm.alarmTagName : ''}
      </Box>
    ));

  const displayWaterLevelData = () => {
    if (!isNaN(waterLevelData) && validLevel) {
      return waterLevelData;
    } else {
      return '';
    }
  };

  return (
    <WidgetWrapper
      title={title}
      isEditMode={isEditMode}
      widgetId={widgetId!}
      toggleAddOrRemoveHandler={toggleAddOrRemoveHandler}
      testId={testId}
      isDataReady={waterLevelData !== null}
    >
      <Box>
        <Typography sx={unitWetWellLabel}>Feet</Typography>
      </Box>
      <Box sx={cardGraphic}>
        <Box sx={cardGraphicInner}>
          <Box display="span" sx={{ bottom: '100%', ...cardGraphicLabel, ...cardGraphicLabelLeft }}>
            {height} {unit}
          </Box>
          <Box display="span" sx={{ bottom: '0%', ...cardGraphicLabel, ...cardGraphicLabelLeft }}>
            0 {unit}
          </Box>

          <Box display="span" sx={() => cardGraphicLevel(waterLevelDisplay)} />
          <Box display="span" sx={() => cardGraphicText(waterLevelDisplay)}>
            {validLevel && displayWaterLevelData()} {unit}
          </Box>

          {handleAlarmsView()}
        </Box>
      </Box>
    </WidgetWrapper>
  );
};

export default LiftStationWetWell;
