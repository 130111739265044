import { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import { Container, Dialog, Button, Box, Table, TableBody, useTheme } from '@mui/material';
import { useDocumentTitle, useToggle } from 'src/hooks';
import { ExportIcon } from 'src/assets';
import { ExportModalContent, TableRowItem, Calendar } from '../components';
import { PumpData, SiteType } from '../types';
import {
  CustomBreadcrumbs,
  MemoizedScrollbar,
  TableHeadCustom,
  useTable,
  TablePaginationCustom,
  TableRowsSkeleton,
} from 'src/components';
import { TABLE_HEAD_REPORTS } from '../config/config';
import { customBreadcrumbs, exportBox, calendarBox, StyledTableContainer } from './style';
import { ReportsService } from 'src/services';
import { formatDate } from '../helper';
import { useAuthentication } from 'src/features/authentication/context';

export const Reports = () => {
  const { state, onToggle } = useToggle();
  const { page, order, orderBy, rowsPerPage, selected, onChangePage, onChangeRowsPerPage } =
    useTable();
  const {
    customerId: { value: customerId },
  } = useAuthentication();

  const theme = useTheme();

  const [selectedDate, setSelectedDate] = useState<any>(dayjs(new Date()));
  const [reports, setReports] = useState<{ value: PumpData[]; loaded: boolean }>({
    value: [],
    loaded: false,
  });
  const formattedDate = formatDate(selectedDate);

  useDocumentTitle('Streametric - Reports');

  useEffect(() => {
    if (!customerId || !formattedDate) return;
    if (customerId && formattedDate) {
      ReportsService.getAll(customerId, formattedDate, formattedDate).then((response) => {
        const data = response[formattedDate];

        const arrayData = data && Object.values(data);
        let result: PumpData[] = [];
        arrayData.forEach((site: SiteType) => {
          const { siteName } = site;
          Object.values(site.assets).forEach((asset) => {
            result.push({
              siteName: siteName,
              assetName: asset.assetName,
              data: asset.data,
            });
          });
        });

        result.sort((a: PumpData, b: PumpData) => {
          const siteNameA = a.siteName.toLowerCase();
          const siteNameB = b.siteName.toLowerCase();
          const assetNameA = a.assetName.toLowerCase();
          const assetNameB = b.assetName.toLowerCase();

          if (siteNameA < siteNameB) {
            return -1;
          } else if (siteNameA > siteNameB) {
            return 1;
          } else {
            if (assetNameA < assetNameB) {
              return -1;
            } else if (assetNameA > assetNameB) {
              return 1;
            } else {
              return 0;
            }
          }
        });

        setReports({ value: result, loaded: true });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerId, formattedDate]);

  const onCloseDialog = () => {
    if (state) onToggle();
  };

  const onExportHandler = () => {
    onToggle();
  };

  const onChangeDateHandler = (date: string) => {
    setSelectedDate(date);
  };

  return (
    <Container maxWidth={false} disableGutters data-sm="reports-page">
      <Dialog onClose={onCloseDialog} open={state}>
        <ExportModalContent headerTitle="Export Pump Report" onClose={onCloseDialog} width={368} />
      </Dialog>
      <CustomBreadcrumbs
        links={[
          {
            name: 'Reports',
            href: '',
            testId: 'breadcrumb-reports',
          },
          {
            name: 'Pump Report',
            href: '',
            testId: 'breadcrumb-pump-report',
          },
        ]}
        sx={customBreadcrumbs}
      />
      <Box sx={exportBox}>
        <Button variant="text" data-sm="export-button" onClick={onExportHandler}>
          <ExportIcon />
          Export
        </Button>
      </Box>
      <Container
        sx={{ width: '100%', overflow: 'auto' }}
        maxWidth={false}
        disableGutters
        data-sm="reports-page-content"
      >
        <Box component="section">
          <StyledTableContainer theme={theme}>
            <Box sx={calendarBox}>
              <Calendar onChange={onChangeDateHandler} />
            </Box>
            <MemoizedScrollbar>
              <Table size="medium" data-sm="reports-table">
                <TableHeadCustom order={order} orderBy={orderBy} headLabel={TABLE_HEAD_REPORTS} />

                <TableBody data-sm="table-reports-content">
                  {reports.loaded ? (
                    reports.value
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((row: any, index: number) => (
                        <TableRowItem key={index} row={row} selected={selected.includes(row.id)} />
                      ))
                  ) : (
                    <TableRowsSkeleton rowsNum={8} cellsNum={7} />
                  )}
                </TableBody>
              </Table>
            </MemoizedScrollbar>
            <TablePaginationCustom
              count={reports.value.length}
              page={page}
              rowsPerPage={rowsPerPage}
              onPageChange={onChangePage}
              onRowsPerPageChange={onChangeRowsPerPage}
              testId="table-item-count"
            />
          </StyledTableContainer>
        </Box>
      </Container>
    </Container>
  );
};

export default Reports;
