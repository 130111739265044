const setSessionStorage = (key: string, value: any) => {
  window.sessionStorage.setItem(key, value);
};

const getParsedSessionStorage = (key: string) =>
  window.sessionStorage.getItem(key) ? JSON.parse(window.sessionStorage.getItem(key)!) : '';

const getRawSessionStorage = (key: string) => window.sessionStorage.getItem(key);

const removeFromSessionStorage = (key: string) => {
  window.sessionStorage.removeItem(key);
};

export const SessionStorageService = {
  set: setSessionStorage,
  getParsed: getParsedSessionStorage,
  getRaw: getRawSessionStorage,
  remove: removeFromSessionStorage,
};
