import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import {
  Grid,
  Card,
  Stack,
  Button,
  Alert,
  Box,
  Typography,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  InputAdornment,
  IconButton,
  useTheme,
  Switch,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { LoadingButton } from '@mui/lab';

import { FormProvider, RHFTextField } from 'src/components/minimals/form';
import { INTEGRATIONS_PATH } from 'src/routes/config';
import { RawUnits } from '../types/DataSource';
import { formatUTCDateToLocal } from 'src/utilities';
import { useSnackbar } from 'notistack';
import { MemoizedScrollbar, MenuPopover } from 'src/components';
import { InfoIcon } from 'src/features/theme/overrides/CustomIcons';
import { useHtmlElement } from 'src/hooks';
import { DataSource, FormDataSourceData } from '../types';
import { AddGatewayTableHead } from 'src/features/gateway-details/components/AddGatewayTableHead';
import {
  EDIT_ACTION_NAME,
  CANCEL_ACTION_NAME,
  ERROR_MESSAGE,
  LAST_EDITED_LABEL,
  DATA_SOURCE_TYPE_TOOLTIP,
  PAYLOAD_INTERVAL_TOOLTIP,
  RAW_INPUT_DATA_SOURCE_TABLE_HEAD,
  WEATHER_SOURCE_NAME,
  WEATHER_DATA_SOURCE_TYPE,
  WEATHER_DATA_SOURCE_INTERVAL,
  WEATHER_DATA_SOURCE_TYPE_DISPLAY_NAME,
} from '../config';
import { DataSourceService } from 'src/services/dataSources';
import { extractUpdatedDataSources, getDataSourceTagProperties } from '../utils/utils';
import { useAuthentication } from 'src/features/authentication/context';
import { AccessRoles } from 'src/features/user-account-details/types';
import { btnsBox, cancelBtn, lastEditedBox, pageWrapper } from './style';
import { EditFlowDataSourceForm } from './EditFlowDataSourceForm';
import { EditWeatherDataSourceForm } from './EditWeatherDataSourceForm';
type Props = {
  dataSource: DataSource;
};

export const EditDataSourceForm: React.FC<Props> = ({ dataSource }) => {
  const {
    customerId: { value: customerId },
    siteId: { value: siteId },
    getCurrentRole,
  } = useAuthentication();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const { htmlElement, addHtmlElement } = useHtmlElement();
  const { htmlElement: htmlElement2, addHtmlElement: addHtmlElement2 } = useHtmlElement();
  const [menuOpen, setMenuOpen] = useState(false);
  const [menuOpen2, setMenuOpen2] = useState(false);
  const anchorRef = useRef(null);

  const [inputs, setInputs] = useState<any[]>([]);
  const [formValues, setFormValues] = useState<FormDataSourceData>({
    dataSourceName: dataSource.dataSourceName,
    dataSourceId: dataSource.id!,
    dataSourceType: 'DS Cloud Connector',
    payloadInterval: dataSource.payloadInterval ? dataSource.payloadInterval!.toString() : '',
  });

  const allRawUnits: RawUnits[] = require('../data/raw-units.json');
  const boolIntegerUnits: RawUnits[] = require('../data/boolean-integer-units.json');

  const methods = useForm<FormDataSourceData>({
    defaultValues: formValues,
  });

  const {
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { isSubmitting, errors },
  } = methods;

  const values = watch();

  useEffect(() => {
    const newFormValues: FormDataSourceData = {
      dataSourceName: dataSource?.dataSourceName || '',
      dataSourceType: dataSource?.dataSourceType || '',
      dataSourceId: dataSource?.id || '',
      payloadInterval: dataSource?.payloadInterval?.toString() || '',
      dataSourceStatus: dataSource?.dataSourceStatus || '',
    };

    if (dataSource.dataSourceType === 'streametric_ai') {
      newFormValues.dataSourceType = 'ai_flow';
    }

    if (dataSource?.dataSourceType === WEATHER_DATA_SOURCE_TYPE) {
      newFormValues.dataSourceName = WEATHER_SOURCE_NAME;
      newFormValues.dataSourceType = WEATHER_DATA_SOURCE_TYPE_DISPLAY_NAME;
      newFormValues.payloadInterval = WEATHER_DATA_SOURCE_INTERVAL;
    }

    dataSource?.inputs?.forEach((input) => {
      if (input) {
        newFormValues[`${input.id}dataType`] = input.dataType
          ? (input.dataType.charAt(0)?.toUpperCase() + input.dataType.slice(1)).split('_').join(' ')
          : '';
        newFormValues[`${input.id}rawUnit`] = input.rawUnit || '';
        newFormValues[`${input.id}displayName`] = input.displayName ?? input.rawInputName;
        newFormValues[`${input.id}hidden`] = input?.hidden?.toString() || 'false';
      }
    });

    setFormValues(newFormValues);
    if (dataSource) {
      setInputs(dataSource.inputs!.map((input) => ({ ...input })));
    }
  }, [dataSource]);

  useEffect(() => {
    reset(formValues);
  }, [formValues, reset]);

  const handleFormFieldInputChange = (
    value: string,
    index: number,
    field: string,
    name: string
  ) => {
    setInputs((prevStates) => {
      const newState = [...prevStates];
      newState[index][field] = value;
      return newState;
    });
    setValue(name, value);
    if (field === 'dataType') {
      setValue(name.split('dataType')[0] + 'rawUnit', '');
    }
  };

  const handleChange = (isChecked: boolean, name: string, target: any) => {
    setValue(name, isChecked.toString());
  };

  const determineRawUnit = (index: number) => {
    const inputDataType = inputs[index]?.dataType;
    return inputDataType === 'Alarm' ||
      inputDataType === 'alarm' ||
      inputDataType === 'Mode' ||
      inputDataType === 'mode' ||
      inputDataType === 'State' ||
      inputDataType === 'state'
      ? boolIntegerUnits
      : allRawUnits;
  };

  const handleErrorView = (errors: any) => {
    if (Object.keys(errors).length !== 0) {
      return (
        <Grid item lg={12}>
          <Alert severity="error">{ERROR_MESSAGE}</Alert>
        </Grid>
      );
    }
    return null;
  };
  const handleLastEditedView = () => {
    if (dataSource) {
      return (
        <Box component="div" sx={lastEditedBox}>
          <Typography variant="body2" color="text.secondary">
            {LAST_EDITED_LABEL} &nbsp;
          </Typography>
          <Typography variant="subtitle2" color="text.secondary">
            {formatUTCDateToLocal(false, dataSource?.updatedAt)}
          </Typography>
        </Box>
      );
    }
    return null;
  };

  const editDataSource = async (data: any) => {
    if (!customerId || !siteId || !dataSource.id) {
      enqueueSnackbar('An ID is missing', { variant: 'error' });
      return;
    }
    try {
      await DataSourceService.edit(data, customerId, siteId, dataSource.id);
      navigate(INTEGRATIONS_PATH + `?customerId=${customerId}&siteId=${siteId}`);
    } catch (error) {
      if (error.response) {
        enqueueSnackbar(error.response.data.detail || error.message, { variant: 'error' });
      }
    }
  };

  const onSubmit = async (data: any) => {
    const dataToSubmit = {
      payloadInterval: +data.payloadInterval,
      dataSourceStatus: data.dataSourceStatus,
      inputs: extractUpdatedDataSources(getDataSourceTagProperties(data, dataSource), dataSource),
    };
    await editDataSource(dataToSubmit);
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3} sx={pageWrapper}>
        <Card
          sx={{
            padding: '24px',
            p: 3,
            width: '100%',
            backgroundColor: theme.palette.background.default,
          }}
        >
          {dataSource.dataSourceType === WEATHER_DATA_SOURCE_TYPE ? (
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2, justifyContent: 'flex-end' }}>
              <Typography>
                {watch('dataSourceStatus') === 'active' ? 'Active' : 'Deactivated'}
              </Typography>
              <Switch
                checked={watch('dataSourceStatus') === 'active' ? true : false}
                onChange={(event) =>
                  setValue(
                    'dataSourceStatus',
                    event.target.checked === true ? 'active' : 'deactivated'
                  )
                }
                inputProps={{ 'aria-label': 'Active switch' }}
              />
            </Box>
          ) : null}

          <Grid item md={12} xs={12}>
            <Box
              rowGap={3}
              columnGap={2}
              display="grid"
              gridTemplateColumns={{
                xs: 'repeat(1, 1fr)',
                sm: 'repeat(4, 1fr)',
              }}
            >
              <RHFTextField
                name="dataSourceType"
                label="Data Source Type"
                testId="data-source-type-input"
                disabled={true}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <div
                        ref={anchorRef}
                        onMouseEnter={() => setMenuOpen2(true)}
                        onMouseLeave={() => setMenuOpen2(false)}
                      >
                        <IconButton
                          disableRipple
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            background: 'transparent',
                            '&:hover': { background: 'transparent' },
                            minWidth: 0,
                          }}
                          onMouseEnter={(event) => addHtmlElement2(event.currentTarget)}
                        >
                          <InfoIcon fill="gray" />
                        </IconButton>
                        <MenuPopover
                          anchorEl={htmlElement2}
                          onClose={() => setMenuOpen2(false)}
                          open={menuOpen2 ? htmlElement2 : null}
                          arrow="top-right"
                          sx={{
                            marginTop: '10px',
                            width: '250px',
                            paddingLeft: '10px',
                            backgroundColor: theme.palette.background.default,
                          }}
                        >
                          {DATA_SOURCE_TYPE_TOOLTIP}
                        </MenuPopover>
                      </div>
                    </InputAdornment>
                  ),
                }}
              />
              <RHFTextField
                name="dataSourceId"
                label="Data Source Id"
                testId="data-source-id-input"
                disabled={true}
                onBlur={(event) => setValue('dataSourceId', event.target.value.trim())}
              />
              <RHFTextField
                name="dataSourceName"
                label="Data Source Name"
                testId="data-source-name-input"
                rules={{ required: 'Data Source Name is required' }}
                error={!!errors.dataSourceName}
                helperText={errors.dataSourceName?.message}
                disabled={true}
                onBlur={(event) => setValue('dataSourceName', event.target.value.trim())}
              />
              <RHFTextField
                name="payloadInterval"
                label="Expected Payload Interval (Minutes)*"
                testId="expected-payload-interval-input"
                rules={{ required: 'Expected Payload Interval is required' }}
                error={!!errors.payloadInterval}
                helperText={errors.payloadInterval?.message}
                disabled={
                  dataSource.dataSourceType === WEATHER_DATA_SOURCE_TYPE ||
                  getCurrentRole(customerId, siteId) !== AccessRoles.SUPER_ADMIN
                }
                onBlur={(event) => setValue('payloadInterval', event.target.value.trim())}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <div
                        ref={anchorRef}
                        onMouseEnter={() => setMenuOpen(true)}
                        onMouseLeave={() => setMenuOpen(false)}
                      >
                        <IconButton
                          disableRipple
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            background: 'transparent',
                            '&:hover': { background: 'transparent' },
                            minWidth: 0,
                          }}
                          onMouseEnter={(event) => addHtmlElement(event.currentTarget)}
                        >
                          <InfoIcon fill="gray" />
                        </IconButton>
                        <MenuPopover
                          anchorEl={htmlElement}
                          onClose={() => setMenuOpen(false)}
                          open={menuOpen ? htmlElement : null}
                          arrow="top-right"
                          sx={{
                            marginTop: '10px',
                            width: '250px',
                            paddingLeft: '10px',
                            backgroundColor: theme.palette.background.default,
                          }}
                        >
                          {PAYLOAD_INTERVAL_TOOLTIP}
                        </MenuPopover>
                      </div>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
          </Grid>

          <Card sx={{ mb: '24px', mt: '35px' }}>
            <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
              <MemoizedScrollbar>
                <Table size={'medium'} sx={{ minWidth: 800 }} data-sm="data-source-raw-input-form">
                  <AddGatewayTableHead headLabel={RAW_INPUT_DATA_SOURCE_TABLE_HEAD} />
                  <TableBody
                    data-sm="table-content"
                    sx={{ backgroundColor: theme.palette.background.default }}
                  >
                    {dataSource?.inputs?.map((input, index) => (
                      <TableRow sx={{ cursor: 'pointer' }} data-sm="table-row" key={index}>
                        {dataSource.dataSourceType === WEATHER_DATA_SOURCE_TYPE ? (
                          <EditWeatherDataSourceForm
                            input={input}
                            values={values}
                            errors={errors}
                            handleChange={handleChange}
                          />
                        ) : (
                          <EditFlowDataSourceForm
                            input={input}
                            index={index}
                            values={values}
                            errors={errors}
                            handleFormFieldInputChange={handleFormFieldInputChange}
                            handleChange={handleChange}
                            determineRawUnit={determineRawUnit}
                          />
                        )}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </MemoizedScrollbar>
            </TableContainer>
          </Card>

          {handleErrorView(errors)}

          <Grid item lg={12} sx={{ mb: '30px' }}>
            <Stack sx={btnsBox}>
              {handleLastEditedView()}
              <Button
                to={`${INTEGRATIONS_PATH}/?customerId=${customerId}&siteId=${siteId}`}
                component={Link}
                variant="outlined"
                sx={cancelBtn}
                data-sm="cancel-button"
              >
                {CANCEL_ACTION_NAME}
              </Button>
              <LoadingButton
                type="submit"
                variant="contained"
                loading={isSubmitting}
                data-sm={'save-changes-button'}
              >
                {EDIT_ACTION_NAME}
              </LoadingButton>
            </Stack>
          </Grid>
        </Card>
      </Grid>
    </FormProvider>
  );
};

export default EditDataSourceForm;
