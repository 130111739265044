import { useEffect, useState } from 'react';
import { Box, Button, Tooltip, Typography, useTheme } from '@mui/material';
import { ControllerWidget } from 'src/assets/widgets/ControllerWidget';
import { WidgetWrapper } from '../WidgetWrapper';
import {
  modeStyle,
  iconBox,
  controllBtn,
  modeText,
  currentMode,
  ControllerWidgetModal,
} from './style';
import { ControllerModalContent } from '../ControllerModalContent';
import { Controller as ControllerType } from 'src/types';
import { ControllerWidgetStatusBox } from '../ControllerWidgetStatusBox';
import { useAuthentication } from 'src/features/authentication/context';
import { useControllerAdditionalInfo, useController } from '../../hooks';
import { useActiveMode } from '../../hooks/useActiveMode';
import { LocalStorageService } from 'src/features/authentication/utils';
import { SettingsIconV2 } from 'src/assets/icons/SettingsIconV2';

type ControllerProps = {
  title: string;
  controllerId: string;
  gatewayId?: string;
  widgetId: string;
  isEditMode: boolean;
  testId: string;
  toggleAddOrRemoveHandler?: () => void;
  controllers?: ControllerType[];
  getControllers: () => void;
};

export const Controller = ({
  title,
  controllerId,
  gatewayId,
  widgetId,
  toggleAddOrRemoveHandler,
  isEditMode,
  testId,
  controllers,
  getControllers,
}: ControllerProps) => {
  const [controllerModalContentState, setControllerModalContentState] = useState<boolean>(false);
  const [confirmDialogState, setConfirmDialogState] = useState<boolean>(false);
  const [tagIdsForUpdate, setTagIdsForUpdate] = useState<string[]>([]);

  const onSetTagIdsForUpdate = (tagIds: string[]) => {
    setTagIdsForUpdate(tagIds);
  };

  const theme = useTheme();

  const onConfirmDialogStateHandler = (value: boolean) => {
    setConfirmDialogState(value);
  };
  const onControllerModalContentStateHandler = (value: boolean) => {
    setControllerModalContentState(value);
  };

  const [openTooltip, setOpenTooltip] = useState<boolean>(false);

  const handleCloseTooltip = () => {
    setOpenTooltip(false);
  };

  const handleOpenTooltip = () => {
    setOpenTooltip(true);
  };

  const {
    customerId: { value: customerId },
    siteId: { value: siteId },
  } = useAuthentication();

  const { controllerAdditionalInfo, getControllerAdditionalInfo } = useControllerAdditionalInfo({
    customerId,
    siteId,
    gatewayId,
    controllerId,
  });
  const {
    isDataLoading,
    setIsDataLoadingHandler,
    setErrorOnUpdateControls,
    latestControl,
    latestControlsForInhand,
    isErrorLastControls,
    isErrorOnUpdateControls,
    isSuccessfulUpdate,
    lastUpdateReadingTimestamp,
    isAppliedChanges,
    setIsAppliedChangedHandler,
    enableApplyButtonHandler,
    disableApplyButtonHandler,
    isBtnApplyDisabled,
    isCloseBtn,
    setIsCloseBtn,
  } = useController({
    gatewayId: gatewayId,
    controllerModalContentState: controllerModalContentState,
  });

  const onCloseHandler = () => {
    setIsCloseBtn(false);
    getControllerAdditionalInfo();
    getControllers();
    if (!isBtnApplyDisabled && !isAppliedChanges) {
      onConfirmDialogStateHandler(true);
    } else {
      disableApplyButtonHandler();
      onControllerModalContentStateHandler(false);
    }
    if (!isDataLoading) {
      setIsAppliedChangedHandler(false);
      controllerAdditionalInfo?.forEach((tag) => {
        LocalStorageService.set(tag.id, 'error-seen');
      });
    }
  };

  const onOpenHandler = () => {
    getControllers();
    setControllerModalContentState(true);
    getControllerAdditionalInfo();
  };

  const findCurrentController = controllers?.find((controller) => controller.id === controllerId);
  const activeMode = findCurrentController?.activeMode;

  const { displayMode } = useActiveMode({
    activeMode: activeMode,
    controllerAdditionalInfo: controllerAdditionalInfo,
  });

  useEffect(() => {
    let interval: any;

    if (!latestControlsForInhand) {
      latestControl(customerId!, gatewayId!, tagIdsForUpdate!);
    }
    if (isDataLoading) {
      interval = setInterval(() => {
        latestControl(customerId!, gatewayId!, tagIdsForUpdate!);
      }, 5000);
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [
    controllerAdditionalInfo,
    customerId,
    gatewayId,
    isDataLoading,
    latestControl,
    latestControlsForInhand,
    tagIdsForUpdate,
  ]);
  return (
    <Box>
      <ControllerWidgetModal
        maxWidth={'lg'}
        open={controllerModalContentState}
        onClose={onCloseHandler}
      >
        <ControllerModalContent
          onClose={onCloseHandler}
          title={title}
          controllerId={controllerId}
          gatewayId={gatewayId}
          activeMode={activeMode}
          latestControl={latestControl}
          latestControlsForInhand={latestControlsForInhand}
          setIsDataLoadingHandler={setIsDataLoadingHandler}
          setErrorOnUpdateControls={setErrorOnUpdateControls}
          controllerAdditionalInfo={controllerAdditionalInfo}
          isErrorLastControls={isErrorLastControls}
          isErrorOnUpdateControls={isErrorOnUpdateControls}
          isDataLoading={isDataLoading}
          lastUpdateReadingTimestamp={lastUpdateReadingTimestamp}
          isAppliedChanges={isAppliedChanges}
          setIsAppliedChangedHandler={setIsAppliedChangedHandler}
          confirmDialogState={confirmDialogState}
          onConfirmDialogStateHandler={onConfirmDialogStateHandler}
          enableApplyButtonHandler={enableApplyButtonHandler}
          disableApplyButtonHandler={disableApplyButtonHandler}
          isBtnApplyDisabled={isBtnApplyDisabled}
          onControllerModalContentStateHandler={onControllerModalContentStateHandler}
          displayMode={displayMode}
          onSetTagIdsForUpdate={onSetTagIdsForUpdate}
          isCloseBtn={isCloseBtn}
        />
      </ControllerWidgetModal>
      <WidgetWrapper
        title={title}
        isEditMode={isEditMode}
        widgetId={widgetId}
        toggleAddOrRemoveHandler={toggleAddOrRemoveHandler}
        testId={testId}
        isDataReady={true}
        isControllerWidget={true}
      >
        <Box sx={{ display: 'flex' }}>
          <Box sx={iconBox}>
            <ControllerWidget width={70} height={70} />
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            <Box sx={modeStyle}>
              <Typography sx={modeText}>
                Mode:
                {displayMode.length > 25 ? (
                  <Tooltip
                    title={displayMode}
                    open={openTooltip}
                    onClose={handleCloseTooltip}
                    onOpen={handleOpenTooltip}
                  >
                    <Typography component="span" sx={currentMode}>
                      {`${displayMode.substring(0, 40)}...`}
                    </Typography>
                  </Tooltip>
                ) : (
                  <Typography component="span" sx={currentMode}>
                    {displayMode || '-'}
                  </Typography>
                )}
              </Typography>
            </Box>

            <Box sx={{ display: 'flex' }}>
              <ControllerWidgetStatusBox
                isDataLoading={isDataLoading}
                isErrorLastControls={isErrorLastControls}
                isErrorOnUpdateControls={isErrorOnUpdateControls}
                isSuccessfulUpdate={isSuccessfulUpdate}
                lastUpdateReadingTimestamp={lastUpdateReadingTimestamp}
              />
              <Button
                sx={controllBtn}
                variant="text"
                onClick={onOpenHandler}
                startIcon={<SettingsIconV2 fill={theme.palette.primary.main} />}
              >
                Settings
              </Button>
            </Box>
          </Box>
        </Box>
      </WidgetWrapper>
    </Box>
  );
};
