import React, { useState, useContext, useEffect } from 'react';
import { SiteInterface } from 'src/features/sites/types/site';
import { SiteContextTypes, SiteProviderPropsTypes } from './type';
import { useLocation } from 'react-router-dom';
import { SitesService } from 'src/services/sites';

const SiteContext = React.createContext<SiteContextTypes>({} as SiteContextTypes);

export const useSite = () => useContext(SiteContext);

export const SiteProvider = ({ children }: SiteProviderPropsTypes) => {
  const [site, setSite] = useState<SiteInterface | null>(null);
  const location = useLocation();

  const saveSite = (site: SiteInterface) => {
    setSite(site);
    localStorage.setItem('selectedSite', JSON.stringify(site));
  };

  useEffect(() => {
    const { search } = location;
    const searchParams = new URLSearchParams(search);
    if (search.includes('customerId') && search.includes('siteId')) {
      const cId = searchParams.get('customerId');
      const sId = searchParams.get('siteId');
      if (!sId || !cId) return;
      const currentSite = getSite();
      if (!currentSite || (currentSite && currentSite.id !== sId)) {
        SitesService.getById({ customerId: cId, siteId: sId }).then((response) => {
          saveSite(response);
        });
      } else if (currentSite) {
        saveSite(currentSite);
      }
    }
  }, [location]);

  const toString = (site: SiteInterface | null): string => {
    if (!site) return '';
    const propArray = [
      site?.location?.street,
      site?.location?.city,
      site?.location?.country,
      site?.location?.state,
      site?.location?.zip,
    ];
    return propArray.filter((entry) => !!entry).join(', ');
  };

  const getSiteToString = (): string => toString(getSite());

  const getSite = () => {
    const siteFromStorage = localStorage.getItem('selectedSite');
    let site = null;
    if (siteFromStorage) {
      site = JSON.parse(siteFromStorage) as SiteInterface;
    }
    return site;
  };

  return (
    <SiteContext.Provider value={{ site, saveSite, getSite, getSiteToString }}>
      {children}
    </SiteContext.Provider>
  );
};
