import { max, maxLength, min, minLength, required } from '../config/errorMessages';
import { FieldValidators } from '../types';

export const getErrorMessage = (
  errors: any,
  fieldName: string,
  fieldValidations?: Partial<FieldValidators>
) => {
  const fieldKeys = fieldName.split('.');
  const field =
    fieldKeys.length === 2 ? errors?.[fieldKeys[0]]?.[fieldKeys[1]] : errors?.[fieldKeys[0]];
  if (field?.type === 'required') return required();
  if (field?.type === 'maxLength') return maxLength(fieldValidations?.maxLength || 0);
  if (field?.type === 'minLength') return minLength(fieldValidations?.minLength || 0);
  if (field?.type === 'min') return min(fieldValidations?.min || 0);
  if (field?.type === 'max') return max(fieldValidations?.max || 0);
};
