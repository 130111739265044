import { Stack, TableCell, Theme, styled } from '@mui/material';
import { Label } from 'src/components';

const DetailsCellLabel = styled(TableCell)(() => ({
  width: '30%',
  padding: '4px 0',
  verticalAlign: 'top',
}));

const DetailsCellValue = styled(TableCell)(() => ({
  width: '70%',
  padding: '4px 0',
  verticalAlign: 'top',
}));

type NotificationsHeaderStyleType = {
  theme: Theme;
};

const NotificationsHeaderCellLabel = styled(TableCell)(
  ({ theme }: NotificationsHeaderStyleType) => ({
    width: '30%',
    padding: '4px 0',
    backgroundImage: 'none',
    backgroundColor: theme.palette.background.default,
  })
);

const NotificationsHeaderCellValue = styled(TableCell)(
  ({ theme }: NotificationsHeaderStyleType) => ({
    width: '70%',
    padding: '4px 0',
    backgroundImage: 'none',
    backgroundColor: theme.palette.background.default,
  })
);

const NotificationsCellLabel = styled(TableCell)(() => ({
  width: '30%',
  padding: '4px 0',
}));

const NotificationsCellValue = styled(TableCell)(() => ({
  width: '70%',
  padding: '4px 0',
}));

const AlarmConditionsCellLabel = styled(TableCell)(() => ({
  padding: '4px 0',
  wordWrap: 'break-word',
  wordBreak: 'break-all',
}));

const AlarmConditionsCellGateway = styled(TableCell)(() => ({
  padding: '4px 10px',
}));

const AlarmConditionsCellValue = styled(TableCell)(() => ({
  width: '80px',
  padding: '4px 0',
}));

const GatewayLabel = styled(Label)(() => ({
  maxWidth: '240px!important',
  width: 'fit-content',
  display: 'inline-block!important',
  justifyContent: 'unset!important',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  lineHeight: '24px!important',
}));

const FooterStack = styled(Stack)(() => ({
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
}));

export {
  DetailsCellLabel,
  DetailsCellValue,
  NotificationsHeaderCellLabel,
  NotificationsHeaderCellValue,
  NotificationsCellLabel,
  NotificationsCellValue,
  AlarmConditionsCellLabel,
  AlarmConditionsCellGateway,
  AlarmConditionsCellValue,
  GatewayLabel,
  FooterStack,
};
