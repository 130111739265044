import { MapPinIcon } from 'src/assets';
import { SiteType } from '../../types/types';
import { LiftFilterIcon } from './LiftFilterIcon';
import { PumpFilterIcon } from './PumpFilterIcon';
import { WastewaterFilterIcon } from './WastewaterFilterIcon';
import { WaterTowerFilterIcon } from './WaterTowerFilterIcon';
import { WaterTreatmentPlantFilterIcon } from './WaterTreatmentPlantFilterIcon';
import { WellFilterIcon } from './WellFilterIcon';

type Filter = {
  label: string;
  value: number;
  id: string;
  icon: React.FunctionComponent;
};

export const filters: Filter[] = [
  {
    label: 'Pump/Booster Station',
    value: 1,
    id: SiteType.booster_pump_station,
    icon: PumpFilterIcon,
  },
  {
    label: 'Lift Station',
    value: 2,
    id: SiteType.lift_station,
    icon: LiftFilterIcon,
  },
  {
    label: 'Process Plant',
    value: 3,
    id: SiteType.process_plant,
    icon: MapPinIcon,
  },
  {
    label: 'Combined Sewage Overflow',
    value: 8,
    id: SiteType.combined_sewage_overflow,
    icon: MapPinIcon,
  },
  {
    label: 'Wastewater Treatment Plant',
    value: 4,
    id: SiteType.wastewater_treatment_plant,
    icon: WastewaterFilterIcon,
  },
  {
    label: 'Water Tower',
    value: 5,
    id: SiteType.water_tower,
    icon: WaterTowerFilterIcon,
  },
  {
    label: 'Water Treatment Plant',
    value: 6,
    id: SiteType.water_treatment_plant,
    icon: WaterTreatmentPlantFilterIcon,
  },
  {
    label: 'Well',
    value: 7,
    id: SiteType.water_well,
    icon: WellFilterIcon,
  },
];

export const popupPaddingTopLeft = [0, 400] as [number, number];
export const popupPaddingBottomRight = [60, 0] as [number, number];

export const FILTER_POPOVER_TITLE = 'Filter by Site Type:';
export const FILTER_POPOVER_CLEAR_ALL_BTN = 'Clear All';
export const FILTER_POPOVER_APPLY_BTN = 'Apply';
export const FILTERS_BTN_TEXT = 'Filters';
export const MAP_SEARCH_BAR_PLACEHOLDER_SHORT = 'Search...';
export const MAP_SEARCH_BAR_PLACEHOLDER = 'Search for Site Name or Address';
