import { IconsTypesProps } from './types';
import { CURRENT_COLOR } from 'src/config';

export const ContactUsIcon = ({ fill }: IconsTypesProps) => {
  const color = fill ?? CURRENT_COLOR;

  return (
    <svg width="24" height="18" viewBox="0 0 24 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M22 0H2C0.9 0 0 0.9 0 2V16C0 17.1 0.9 18 2 18H22C23.1 18 23.99 17.1 23.99 16L24 2C24 0.9 23.1 0 22 0ZM8 3C9.66 3 11 4.34 11 6C11 7.66 9.66 9 8 9C6.34 9 5 7.66 5 6C5 4.34 6.34 3 8 3ZM14 15H2V14C2 12 6 10.9 8 10.9C10 10.9 14 12 14 14V15ZM17.85 11H19.24C19.4 11 19.54 11.07 19.64 11.2L20.74 12.65C20.89 12.85 20.87 13.13 20.69 13.3L19.33 14.66C19.15 14.84 18.85 14.86 18.66 14.7C17.53 13.74 16.69 12.45 16.28 10.99C16.1 10.36 16 9.69 16 9C16 8.31 16.1 7.64 16.28 7C16.69 5.53 17.53 4.25 18.66 3.29C18.86 3.12 19.15 3.15 19.33 3.33L20.69 4.69C20.87 4.87 20.89 5.15 20.74 5.34L19.64 6.79C19.55 6.92 19.4 6.99 19.24 6.99H17.85C17.63 7.62 17.5 8.29 17.5 8.99C17.5 9.69 17.63 10.37 17.85 11Z"
        fill={color}
      />
    </svg>
  );
};
