const controllerWidgetStatusBox = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  maxWidth: '95%',
};

const lastUpdatedText = {
  fontSize: '0.7rem',
  marginTop: '0.4rem',
  marginRight: '0.2rem',
};

const tooltip = {
  marginTop: '0.8rem',
  marginLeft: '.2rem',
};

const statusTitleStyle = {
  fontSize: '0.7rem',
  fontWeight: 'bold',
};

export { controllerWidgetStatusBox, lastUpdatedText, tooltip, statusTitleStyle };
