import { styled, Theme } from '@mui/material/styles';
import { Box, DialogContent } from '@mui/material';
import { scrollbarOverride } from 'src/styles';

type StylesStyledModalContentTypes = {
  theme: Theme;
  isMobile: boolean;
};

type StylesWidgetsListTypes = {
  isMobile: boolean;
};

const StyledModalContent = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isMobile',
})(({ theme, isMobile }: StylesStyledModalContentTypes) => ({
  width: isMobile ? '100%' : '756px',
  // background: theme.palette.background.default,
  borderRadius: '16px',
}));

const StyledWidgetsList = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isMobile',
})(({ isMobile }: StylesWidgetsListTypes) => ({
  position: 'relative',
  display: 'grid',
  gridTemplateColumns: isMobile ? 'repeat(1,1fr)' : 'repeat(3,1fr)',
  justifyContent: 'center',
  gridGap: '16px',
  padding: '20px 24px 30px 24px',
  overflow: 'hidden',
  zIndex: 1,
}));

const buttonsBox = {
  display: 'flex',
  justifyContent: 'flex-end',
};

const discardBtn = {
  marginRight: '10px',
};

const DialogContentWithCustomScroll = styled(DialogContent)(scrollbarOverride);

export {
  StyledModalContent,
  StyledWidgetsList,
  buttonsBox,
  discardBtn,
  DialogContentWithCustomScroll,
};
