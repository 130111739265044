import { Button, Stack, useTheme } from '@mui/material';
import { FunctionComponent, useState } from 'react';
import SectionTitle from './SectionTitle';
import { BlockStack, CustomTextArea } from '../styles';
import { useFormContext } from 'react-hook-form';
import CommentBox from './CommentBox';
import { LogsService } from 'src/services/logs';
import { useAuthentication } from 'src/features/authentication/context';
import { useSnackbar } from 'notistack';
import { LogComment } from 'src/features/logs/types';
import { v4 as uuid } from 'uuid';
import dayjs from 'dayjs';
import DeleteCommentConfirmDialog from 'src/features/logs/components/DeleteCommentConfirmDialog';

const CommentsField: FunctionComponent<{ disabled: boolean }> = ({ disabled }) => {
  const theme = useTheme();
  const {
    siteId: { value: siteId },
    customerId: { value: customerId },
    user,
  } = useAuthentication();
  const [temporaryComment, setTemporaryComment] = useState('');
  const { watch, setValue } = useFormContext();
  const [deleteCommentId, setDeleteCommentId] = useState<string | null>(null);
  const [isDeleteingComment, setIsDeleteingComment] = useState(false);
  const [sendingComment, setSendingComment] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const comments: LogComment[] = Array.isArray(watch('comments')) ? watch('comments') : [];

  const logId = watch('id');

  return (
    <BlockStack>
      <DeleteCommentConfirmDialog
        open={!!deleteCommentId}
        isDeleteingComment={isDeleteingComment}
        onClose={() => {
          setDeleteCommentId(null);
        }}
        onConfirm={async () => {
          if (!siteId || !deleteCommentId)
            throw Error('Site id and delete comment id must be provided!');
          try {
            setIsDeleteingComment(true);
            if (logId) {
              await LogsService.deleteComment(siteId, logId, customerId!, deleteCommentId!);
            }
            setValue(
              'comments',
              comments.filter((comment) => comment.id !== deleteCommentId)
            );
            enqueueSnackbar('Successfully deleted comment!', { variant: 'success' });
            setDeleteCommentId(null);
            setIsDeleteingComment(false);
          } catch (err) {
            setIsDeleteingComment(false);
            enqueueSnackbar('Failed to delete comment', { variant: 'error' });
            console.error(err);
          }
        }}
      />
      <SectionTitle>Comments</SectionTitle>
      <CustomTextArea
        disabled={sendingComment || disabled}
        onChange={(event) => setTemporaryComment(event.target.value.slice(0, 2000))}
        maxRows={3}
        minRows={3}
        value={temporaryComment}
        name="comments"
      />
      <Stack sx={{ paddingBlock: theme.spacing(1) }} flexDirection="row" justifyContent="flex-end">
        <Button
          disabled={!temporaryComment.length || sendingComment || disabled}
          onClick={async () => {
            try {
              if (!siteId || !customerId) throw Error('Site id and log id must be provided!');
              const comment = temporaryComment;
              setSendingComment(true);
              setTemporaryComment('');

              let commentData = {
                id: uuid(),
                authorName:
                  user.accessRole === 'SUPER_ADMIN'
                    ? 'Super Admin'
                    : user.firstName && user.lastName
                    ? `${user.firstName} ${user.lastName}`
                    : 'You',
                authorId: user.userId,
                createdAt: dayjs().toISOString(),
                content: temporaryComment,
              };
              if (logId) {
                commentData = await LogsService.addComment(siteId, customerId, logId, {
                  content: comment,
                });
                (commentData as any).sort((a: any, b: any) =>
                  dayjs(a.createdAt).isAfter(dayjs(b.createdAt)) ? -1 : 1
                );
              }

              const newComments = Array.isArray(commentData)
                ? commentData
                : [commentData, ...comments];
              setValue('comments', newComments);

              if (newComments.length === 1) {
                const formBox = document.querySelector('#form-box');
                if (formBox) {
                  setTimeout(() => {
                    formBox.scrollTop = formBox?.scrollHeight;
                  }, 300);
                }
              }

              setSendingComment(false);
            } catch (err) {
              enqueueSnackbar('Failed to add comment', { variant: 'error' });
              setSendingComment(false);
              console.error(err);
            }
          }}
          variant="outlined"
        >
          {sendingComment ? 'Adding comment...' : 'Add comment'}
        </Button>
      </Stack>
      <Stack gap="10px">
        {comments.map((comment) => (
          <CommentBox
            key={comment.id}
            {...comment}
            showDateAndStatus={!!logId}
            onEdit={async (editedCommentId, editedComment) => {
              try {
                if (!siteId || !customerId) throw Error('Site id and log id must be provided!');
                if (logId) {
                  await LogsService.patchComment(siteId, customerId, logId, editedCommentId, {
                    content: editedComment,
                  });
                }

                setValue(
                  'comments',
                  comments.map((comment) =>
                    comment.id === editedCommentId
                      ? { ...comment, content: editedComment, updatedAt: dayjs().toISOString() }
                      : comment
                  )
                );
              } catch (err) {
                enqueueSnackbar('Failed to update comment', { variant: 'error' });
                console.error(err);
              }
            }}
            onDelete={async (deletedCommentId) => {
              setDeleteCommentId(deletedCommentId);
            }}
          />
        ))}
      </Stack>
    </BlockStack>
  );
};

export default CommentsField;
