import { Box, Theme, styled, Button } from '@mui/material';
import { HEADER } from 'src/config';

type OverlayProps = {
  active: string;
};

type StyledProfileBtnType = {
  theme: Theme;
};

const IconWrapper = styled(Box)`
  height: inherit;
  position: relative;
  transition: transform 0.3s;
  &:hover {
    transform: scale(1.15);
  }
`;

const Overlay = styled(Box)(({ active }: OverlayProps) => ({
  position: 'absolute',
  top: '0',
  left: '0',
  width: '100%',
  height: '100%',
  borderRadius: '50%',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  opacity: `${active === 'true' ? '1' : '0'}`,
  transition: 'opacity 0.3s',
}));

type StyledHeaderType = {
  theme: Theme;
  isMobile: boolean;
  isTransparent?: boolean;
};

const StyledHeader = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isMobile' && prop !== 'isTransparent',
})(({ theme, isMobile, isTransparent }: StyledHeaderType) => ({
  width: '100%',
  height: isTransparent ? 'auto' : `${HEADER.H_MAIN_DESKTOP}px`,
  display: 'flex',
  alignItems: 'center',
  padding: (() => {
    if (isTransparent) {
      return 0;
    }
    return isMobile ? '0 20px' : '16px';
  })(),
  '@media (max-width:900px)': {
    backgroundColor: isTransparent ? 'transparent' : theme.palette.background.paper,
  },
  backgroundColor: theme.palette.background.paper,
}));

const iconBox = {
  cursor: 'pointer',
  height: '40px',
};

const StyledProfileBtn = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'isMobile',
})(({ theme }: StyledProfileBtnType) => ({
  color: theme.palette.common.black,
  fontSize: '16px',
  fontWeight: theme.typography.fontWeightRegular,
  padding: '0',
  '&:hover': {
    background: 'none',
  },
}));

export { iconBox, IconWrapper, Overlay, StyledHeader, StyledProfileBtn };
