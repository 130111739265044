import { useEffect, useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
  Box,
  Button,
  DialogActions,
  Divider,
  InputAdornment,
  TextField,
  Typography,
  useTheme,
  Dialog,
  Stack,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { SearchFillIcon } from 'src/assets';
import { FormProvider } from 'src/components/minimals/form';
import {
  formProviderWrapper,
  modalContentItemsList,
  selectDataTitle,
  StyledModalWrapper,
  DialogContentWithCustomScroll,
} from 'src/features/trends/components/add-chart-modal-content/style';
import { StyledSelectedDataBox } from './style';
import { useGetSiteTags } from 'src/hooks';
import { SelectedItemsListSiteTrends } from '../../pages/site-trends/components/selected-items-list-site-trends';
import { CustomWaterLoading } from 'src/components';
import { useAuthentication } from 'src/features/authentication/context';
import { ModalContentItem } from '../../pages/site-trends';
import { useSiteLocalTrendsContext } from 'src/contexts/siteLocalTrendsContext';
import { getSelectedInputsLocalTrends } from '../../utils/getSelctedInputsLocalTrends';

type Props = {
  state: boolean;
  onToggle: () => void;
  isExistingChart?: boolean;
  chartId?: string;
};

export const SiteTrendsChartModal = ({ state, onToggle, isExistingChart, chartId }: Props) => {
  const theme = useTheme();

  const disabledTextColor = theme.palette.text.disabled;
  const {
    customerId: { value: customerId },
    siteId: { value: siteId },
  } = useAuthentication();
  const { addNewChart, updateExitingChart, getFormValuesForSpecificChart } =
    useSiteLocalTrendsContext();

  const defaultValues: { [x: string]: string } = {
    search: '',
  };
  const formValuesForExistingChart: { [x: string]: string } = useMemo(
    () => getFormValuesForSpecificChart(chartId!),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getFormValuesForSpecificChart]
  );

  const methods = useForm({
    defaultValues: isExistingChart ? formValuesForExistingChart : defaultValues,
  });

  const { setValue, watch, reset } = methods;
  const values = watch();

  const { tags, isLoading } = useGetSiteTags({
    customerId: customerId!,
    siteId: siteId!,
    searchQuery: values.search,
  });

  const visibleTags = useMemo(
    () => tags?.filter((tag: { hidden: boolean }) => tag.hidden === false),
    [tags]
  );

  useEffect(() => {
    if (formValuesForExistingChart) {
      reset(formValuesForExistingChart);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValuesForExistingChart]);

  const onAddChartHandler = () => {
    if (!customerId || !siteId) return;
    const newChartid = uuidv4();
    //Get formated selected inputs from the form values
    const selectedInputs = getSelectedInputsLocalTrends(values);
    const chart = {
      id: isExistingChart ? chartId! : newChartid,
      customerId: customerId,
      siteId: siteId,
      selectedInputs: selectedInputs,
    };
    //Add chart to the state context
    isExistingChart ? updateExitingChart(chart) : addNewChart(chart);
    //Close the Dialog
    onToggle();

    //Reset form values
    reset();
  };

  const onCloseHandler = () => {
    onToggle();
    reset();
  };

  return (
    <Dialog open={state} onClose={onCloseHandler} maxWidth="lg">
      <DialogContentWithCustomScroll sx={formProviderWrapper}>
        <FormProvider methods={methods}>
          <StyledModalWrapper theme={theme}>
            <StyledSelectedDataBox>
              <Typography variant="body1" sx={selectDataTitle}>
                Select Data
              </Typography>
              <TextField
                onChange={(event) => {
                  setValue('search', event.target.value);
                  // setIsLoading(true);
                }}
                name="search"
                fullWidth
                placeholder="Search tags..."
                inputProps={{ style: { padding: '10px' }, 'data-sm': 'search-input' }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchFillIcon fill={disabledTextColor} />
                    </InputAdornment>
                  ),
                }}
              />

              <SelectedItemsListSiteTrends setValue={setValue} values={values} />
            </StyledSelectedDataBox>

            {isLoading ? (
              <Stack
                flexDirection={'row'}
                justifyContent={'center'}
                alignItems={'center'}
                sx={{ mb: '16px' }}
              >
                <CustomWaterLoading />
              </Stack>
            ) : (
              <Box sx={modalContentItemsList}>
                {visibleTags?.map((tag) => (
                  <ModalContentItem key={tag.id} tag={tag} setValue={setValue} values={values} />
                ))}
              </Box>
            )}
          </StyledModalWrapper>
        </FormProvider>
      </DialogContentWithCustomScroll>

      <Divider />
      <DialogActions sx={{ backgroundColor: theme.palette.background.default }}>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: '10px' }}>
          <Button onClick={onCloseHandler} variant="outlined" data-sm="cancel-modal-button">
            Cancel
          </Button>
          <Button variant="contained" data-sm="add-modal-button" onClick={onAddChartHandler}>
            Add
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};
