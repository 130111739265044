import { styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';

type StyledTypes = {
  background?: string;
  color?: string;
};

const StyledLabelTagBox = styled(Box)(({ background, color }: StyledTypes) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '45px',
  height: '22px',
  background: background,
  border: `1.5px solid ${color}`,
  borderRadius: '6px',
}));

const StyledLabelTagValue = styled(Typography)(({ color }: StyledTypes) => ({
  fontSize: '12px',
  color: '#3B3B3B',
  fontWeight: '700',
}));

export { StyledLabelTagBox, StyledLabelTagValue };
