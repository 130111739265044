import { IconsTypesProps } from './types';
import { CURRENT_COLOR } from 'src/config';

export const OneDayRangeIcon = ({ fill }: IconsTypesProps) => {
  const color = fill ?? CURRENT_COLOR;
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="mask0_12482_51808" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
        <rect width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_12482_51808)">
        <path
          d="M5.3077 21.5001C4.80257 21.5001 4.375 21.3251 4.025 20.9751C3.675 20.6251 3.5 20.1975 3.5 19.6924V6.30784C3.5 5.80271 3.675 5.37514 4.025 5.02514C4.375 4.67514 4.80257 4.50014 5.3077 4.50014H6.69233V3.15397C6.69233 2.93475 6.76573 2.75174 6.91253 2.60494C7.05931 2.45816 7.24232 2.38477 7.46155 2.38477C7.68077 2.38477 7.86378 2.45816 8.01058 2.60494C8.15736 2.75174 8.23075 2.93475 8.23075 3.15397V4.50014H15.8077V3.13474C15.8077 2.92192 15.8794 2.74372 16.023 2.60014C16.1666 2.45656 16.3448 2.38477 16.5576 2.38477C16.7704 2.38477 16.9486 2.45656 17.0922 2.60014C17.2358 2.74372 17.3076 2.92192 17.3076 3.13474V4.50014H18.6923C19.1974 4.50014 19.625 4.67514 19.975 5.02514C20.325 5.37514 20.5 5.80271 20.5 6.30784V19.6924C20.5 20.1975 20.325 20.6251 19.975 20.9751C19.625 21.3251 19.1974 21.5001 18.6923 21.5001H5.3077ZM5.3077 20.0001H18.6923C18.7692 20.0001 18.8397 19.9681 18.9038 19.904C18.9679 19.8398 19 19.7693 19 19.6924V10.3078H4.99997V19.6924C4.99997 19.7693 5.03202 19.8398 5.09612 19.904C5.16024 19.9681 5.23077 20.0001 5.3077 20.0001ZM4.99997 8.80787H19V6.30784C19 6.23091 18.9679 6.16038 18.9038 6.09627C18.8397 6.03217 18.7692 6.00012 18.6923 6.00012H5.3077C5.23077 6.00012 5.16024 6.03217 5.09612 6.09627C5.03202 6.16038 4.99997 6.23091 4.99997 6.30784V8.80787Z"
          fill={color}
        />
        <circle cx="8" cy="13" r="1" fill={color} />
      </g>
    </svg>
  );
};
