import { IconsTypesProps } from '../types';
import { CURRENT_COLOR } from 'src/config';

export const FormsIcon = ({ fill }: IconsTypesProps) => {
  const color = fill ?? CURRENT_COLOR;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <mask id="mask0_16660_133254" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
        <rect width="24" height="24" fill={color} />
      </mask>
      <g mask="url(#mask0_16660_133254)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.5875 20.4125C3.97917 20.8042 4.45 21 5 21H19C19.55 21 20.0208 20.8042 20.4125 20.4125C20.8042 20.0208 21 19.55 21 19V5C21 4.45 20.8042 3.97917 20.4125 3.5875C20.0208 3.19583 19.55 3 19 3H14.8C14.5833 2.4 14.2208 1.91667 13.7125 1.55C13.2042 1.18333 12.6333 1 12 1C11.3667 1 10.7958 1.18333 10.2875 1.55C9.77917 1.91667 9.41667 2.4 9.2 3H5C4.45 3 3.97917 3.19583 3.5875 3.5875C3.19583 3.97917 3 4.45 3 5V19C3 19.55 3.19583 20.0208 3.5875 20.4125ZM19 5V19H5V5H19ZM12.5375 4.0375C12.3958 4.17917 12.2167 4.25 12 4.25C11.7833 4.25 11.6042 4.17917 11.4625 4.0375C11.3208 3.89583 11.25 3.71667 11.25 3.5C11.25 3.28333 11.3208 3.10417 11.4625 2.9625C11.6042 2.82083 11.7833 2.75 12 2.75C12.2167 2.75 12.3958 2.82083 12.5375 2.9625C12.6792 3.10417 12.75 3.28333 12.75 3.5C12.75 3.71667 12.6792 3.89583 12.5375 4.0375ZM8.7 17H7.5C7.36667 17 7.25 16.95 7.15 16.85C7.05 16.75 7 16.6333 7 16.5V15.275C7 15.1417 7.025 15.0125 7.075 14.8875C7.125 14.7625 7.2 14.65 7.3 14.55L12.95 8.9L15.1 11.05L9.4 16.7C9.31667 16.7833 9.2125 16.8542 9.0875 16.9125C8.9625 16.9708 8.83333 17 8.7 17ZM16.85 9.25L15.8 10.35L13.65 8.2L14.75 7.15C14.85 7.05 14.9667 7 15.1 7C15.2333 7 15.35 7.05 15.45 7.15L16.85 8.55C16.95 8.65 17 8.76667 17 8.9C17 9.03333 16.95 9.15 16.85 9.25ZM6.5 8.5C6.5 8.22386 6.72386 8 7 8H12C12.2761 8 12.5 8.22386 12.5 8.5C12.5 8.77614 12.2761 9 12 9H7C6.72386 9 6.5 8.77614 6.5 8.5ZM6.5 10.5C6.5 10.2239 6.72386 10 7 10H9C9.27614 10 9.5 10.2239 9.5 10.5C9.5 10.7761 9.27614 11 9 11H7C6.72386 11 6.5 10.7761 6.5 10.5ZM14 14C13.7239 14 13.5 14.2239 13.5 14.5C13.5 14.7761 13.7239 15 14 15H17C17.2761 15 17.5 14.7761 17.5 14.5C17.5 14.2239 17.2761 14 17 14H14ZM10.5 16.5C10.5 16.2239 10.7239 16 11 16H17C17.2761 16 17.5 16.2239 17.5 16.5C17.5 16.7761 17.2761 17 17 17H11C10.7239 17 10.5 16.7761 10.5 16.5Z"
          fill={color}
        />
      </g>
    </svg>
  );
};
