import {
  CardProps,
  Collapse,
  Divider,
  IconButton,
  Stack,
  StackProps,
  SvgIcon,
} from '@mui/material';
import { FunctionComponent, PropsWithChildren, useState } from 'react';
import { StyledSidebarWrapper } from '../../pages/style';
import { useResponsive } from 'src/hooks';
import { KeyboardArrowDown } from '@mui/icons-material';

const SidebarWrapper: FunctionComponent<
  PropsWithChildren<{
    cardProps?: CardProps;
    stackProps?: StackProps;
    renderFooterContent?: (onCollapse: () => void) => JSX.Element;
  }>
> = ({ children, renderFooterContent, cardProps, stackProps }) => {
  const isMobile = useResponsive('down', 'lg');
  const [collapse, setCollapse] = useState(true);
  const onCollapse = () => {
    setCollapse(false);
  };
  const content = (
    <>
      {' '}
      {children}
      {renderFooterContent ? (
        <Stack>
          <Divider sx={{ marginTop: '34px', marginBottom: '16px' }} />{' '}
          {renderFooterContent(onCollapse)}
        </Stack>
      ) : (
        <></>
      )}
    </>
  );

  return (
    <StyledSidebarWrapper {...(cardProps || {})}>
      <Stack {...(stackProps || {})}>
        {isMobile && (
          <Stack flexDirection="row" justifyContent="flex-end">
            <IconButton
              onClick={() => {
                setCollapse(!collapse);
              }}
            >
              <SvgIcon
                sx={{
                  transition: 'transform 0.3s',
                  transform: collapse ? 'rotate(180deg)' : 'rotate(0deg)',
                }}
                component={KeyboardArrowDown}
              />
            </IconButton>
          </Stack>
        )}
        {isMobile ? (
          <Collapse sx={{ paddingBlock: '16px' }} in={collapse}>
            {content}
          </Collapse>
        ) : (
          content
        )}
      </Stack>
    </StyledSidebarWrapper>
  );
};

export default SidebarWrapper;
