import { Button, Dialog, DialogProps, Stack, Typography } from '@mui/material';
import { FunctionComponent, PropsWithChildren } from 'react';

import { IAddColumnDialogProps } from '../../../types';

const AddColumnDialog: FunctionComponent<
  PropsWithChildren<
    IAddColumnDialogProps &
      Omit<DialogProps, 'onSubmit'> & {
        isEdit: boolean;
        disabled?: boolean;
        columnStatistics: Record<string, boolean>;
      }
  >
> = ({ isEdit, children, disabled = false, onSubmit, onCancel, ...props }) => (
  <Dialog {...props} PaperProps={{ sx: { padding: '24px' } }}>
    <Typography paddingBottom="24px" variant="h6">
      {isEdit ? 'Edit column' : 'Add column'}
    </Typography>
    {children}

    <Stack flexDirection="row" marginTop={2} justifyContent="flex-end">
      <Stack flexDirection="row" gap="10px">
        <Button onClick={onCancel} variant="outlined" color="primary">
          Cancel
        </Button>
        <Button
          disabled={disabled}
          onClick={() => onSubmit()}
          variant="contained"
          color="primary"
          data-sm="done-btn"
        >
          Done
        </Button>
      </Stack>
    </Stack>
  </Dialog>
);
export default AddColumnDialog;
