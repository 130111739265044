export const generateYAxesWithColors = (tags: any[], colors: string[]) => {
  if (tags.length <= 1) return {};
  const yAxes: Record<string, any> = {};

  tags.forEach((tag, index) => {
    const key = index === 0 ? 'y' : `y${index - 1}`;

    yAxes[key] = {
      type: 'linear',
      display: true,
      position: 'left',
      ticks: {
        fontColor: colors[index % colors.length],
        color: colors[index % colors.length],
      },
      grid: {
        drawOnChartArea: false,
      },
    };
  });

  return yAxes;
};
