export const LiveDataWidget = () => (
  <svg width="165" height="74" viewBox="0 0 165 74" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect y="3" width="101.215" height="9" fill="#E8EBEC" />
    <rect x="147" width="18" height="14" rx="3" fill="#36B37E" />
    <rect x="147" y="20" width="18" height="14" rx="3" fill="#36B37E" />
    <rect x="147" y="40" width="18" height="14" rx="3" fill="#36B37E" />
    <rect x="147" y="60" width="18" height="14" rx="3" fill="#FFAB00" />
    <rect y="23" width="122" height="9" fill="#E8EBEC" />
    <rect y="43" width="101.215" height="9" fill="#E8EBEC" />
    <rect y="63" width="53.3185" height="9" fill="#E8EBEC" />
  </svg>
);
