import html2canvas from 'html2canvas';

export const exportAsPNG = async (ref: any) => {
  const element = ref.current;

  const canvas = await html2canvas(element);

  const data = canvas.toDataURL('image/png');
  const link = document.createElement('a');

  if (typeof link.download === 'string') {
    link.href = data;
    link.download = 'image.png';

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } else {
    window.open(data);
  }
};
