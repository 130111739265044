import { Stack, SvgIcon, Theme, Typography } from '@mui/material';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { imageMimeTypes } from 'src/constants/imageMimeTypes';

const defaultContentRenderer = (file: File, theme: Theme, fileName: string = '') =>
  imageMimeTypes.includes(file.type) ? (
    <img
      width="100%"
      height="100%"
      style={{ objectFit: 'contain', padding: '12px' }}
      src={URL.createObjectURL(file)}
      alt="preview"
    />
  ) : (
    <Stack
      alignItems="center"
      justifyContent="center"
      sx={{
        width: '100%',
        height: '100%',
      }}
    >
      <SvgIcon fontSize="large" component={InsertDriveFileIcon} />
      <Typography
        sx={{ marginTop: theme.spacing(1), width: '80%', textAlign: 'center' }}
        variant="body1"
      >
        {fileName || file.name}
      </Typography>
    </Stack>
  );

export default defaultContentRenderer;
