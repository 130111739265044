import React, { Suspense, SyntheticEvent, memo, useState, useMemo } from 'react';
import { Box, Checkbox, CircularProgress, FormControlLabel } from '@mui/material';
import { SimpleTreeView } from '@mui/x-tree-view/SimpleTreeView';
import { GetAllResponse } from 'src/services/sites';
import { SEPARATOR, SITE } from '../../config/config';

type Props = {
  selectedItems: string[];
  handleSelectedItemsChange: (event: SyntheticEvent, ids: string[]) => void;
  sites: { value: GetAllResponse[] | null; loaded: boolean };
  searchedSites: GetAllResponse[];
  isLoading: boolean;
  sitesSelectable?: boolean;
  selectedAssetTypes: string[];
};

const FilterTreeCategoryItem = memo(
  React.lazy(() => import('../filter-tree-category-item/FilterTreeCategoryItem'))
);

const FilterTreeCategoryList = ({
  selectedItems,
  handleSelectedItemsChange,
  sites,
  sitesSelectable = true,
  searchedSites,
  isLoading,
  selectedAssetTypes,
}: Props) => {
  const [expanded, setExpanded] = useState<string[]>([]);
  const sitesForDisplay = searchedSites.length > 0 ? searchedSites : sites?.value;

  const allSiteIds = useMemo(
    () =>
      (sitesForDisplay ?? []).map(
        (site) => `${SITE}${SEPARATOR}${site.siteName}${SEPARATOR}${site.id}`
      ),
    [sitesForDisplay]
  );

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    const syntheticEvent = event as unknown as SyntheticEvent;

    if (event.target.checked) {
      handleSelectedItemsChange(syntheticEvent, allSiteIds);
    } else {
      handleSelectedItemsChange(syntheticEvent, []);
    }
  };

  const areAllSelected = useMemo(
    () => allSiteIds.every((id) => selectedItems.includes(id)),
    [allSiteIds, selectedItems]
  );

  const handleToggle = (nodeId: string) => {
    setExpanded((prev) =>
      prev.includes(nodeId) ? prev.filter((id) => id !== nodeId) : [...prev, nodeId]
    );
  };
  return (
    <Box sx={{ marginTop: '10px' }}>
      {isLoading ? (
        <CircularProgress sx={{ ml: '20px' }} size="16px" />
      ) : (
        <>
          <FormControlLabel
            control={<Checkbox checked={areAllSelected} onChange={handleSelectAll} />}
            label="Select All"
          />

          <Suspense fallback={<CircularProgress sx={{ ml: '20px' }} size="16px" />}>
            <SimpleTreeView
              checkboxSelection
              selectedItems={selectedItems}
              onSelectedItemsChange={handleSelectedItemsChange}
              multiSelect
            >
              {sitesForDisplay?.map((site) => (
                <FilterTreeCategoryItem
                  key={site.id}
                  id={site.id}
                  label={site.siteName}
                  isExpanded={expanded.includes(site.id)}
                  handleToggle={handleToggle}
                  selectedAssetTypes={selectedAssetTypes}
                />
              ))}
            </SimpleTreeView>
          </Suspense>
        </>
      )}
    </Box>
  );
};

export default memo(FilterTreeCategoryList);
