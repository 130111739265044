import VisibilityIcon from '@mui/icons-material/Visibility';
import { IconButton, Stack, SvgIcon, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { FunctionComponent } from 'react';
import { VersionHistoryItem } from '../types';
import { formatUTCDateToLocalDate } from 'src/utilities';

const HistoryPopoverItem: FunctionComponent<{
  version: VersionHistoryItem;
  onExpand: (version: VersionHistoryItem) => void;
}> = ({ version, onExpand }) => (
  <Stack flexDirection="row" gap="6px" alignItems="center" justifyContent="space-between">
    <Typography
      sx={{ width: '10px', flexShrink: '0' }}
      color="primary"
      fontSize="12px"
      fontWeight="600"
    >
      {version.version}
    </Typography>
    <Typography sx={{ width: '125px', flexShrink: 0 }} fontSize="12px" fontWeight="600">
      {dayjs(formatUTCDateToLocalDate(version.changedAt)).format('M/D/YYYY, h:mm A')}
    </Typography>
    <Typography flex="1" fontSize="12px" fontWeight="600">
      {version.changedBy || version.createdBy}
    </Typography>
    <IconButton size="small" onClick={() => onExpand(version)}>
      <SvgIcon fontSize="small" component={VisibilityIcon} />
    </IconButton>
  </Stack>
);

export default HistoryPopoverItem;
