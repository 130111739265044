import { ReactNode } from 'react';
import { InputAdornment, Box, TextField, useTheme } from '@mui/material';
import { RHFAutocomplete } from 'src/components/minimals/form';
import { LightTooltip } from 'src/components/minimals/tooltip/LightTooltip';
import { InfoIcon } from 'src/assets';
import { ControllerAdditionalInfo } from 'src/types';
import { isTagEditable } from 'src/features/widgets/utils';
import { LatestControlsForInhandTypes } from 'src/features/widgets/hooks/types';
import { textField } from './style';
import { useResponsive } from 'src/hooks';
import { filterOptions } from 'src/utilities';
import { STATE } from 'src/constants';
import { LocalStorageService } from 'src/features/authentication/utils';

type DropdownModalItemProps = {
  getInputOptions: (item: any) => string[] | { label: string; value: string }[];
  setValue: (key: string, value: string) => void;
  titleTooltip: (item: any) => ReactNode;
  item: ControllerAdditionalInfo;
  title: string;
  selectedMode?: { id?: string; displayName?: string; value?: string };
  latestControlsForInhand: LatestControlsForInhandTypes[];
  isErrorOnUpdateControls: boolean;
  enableApplyButtonHandler: () => void;
  isDataLoading: boolean;
  isAppliedChanges: boolean;
  setIsAppliedChangedHandler: (value: boolean) => void;
  isCloseBtn: boolean;
};

export const DropdownModalItem = ({
  getInputOptions,
  titleTooltip,
  item,
  title,
  selectedMode,
  latestControlsForInhand,
  isErrorOnUpdateControls,
  enableApplyButtonHandler,
  isDataLoading,
  setValue,
  isAppliedChanges,
  setIsAppliedChangedHandler,
  isCloseBtn,
}: DropdownModalItemProps) => {
  const theme = useTheme();
  const isStateDataType = item?.dataType === STATE;
  const rawUnit = item.rawUnit ? `(${item.rawUnit})` : '';
  const tagId = item?.id;
  const latestControlItem = latestControlsForInhand?.find((item) => item.tagId === tagId);

  const isErrorShown = window.localStorage.getItem(tagId) === 'error-seen';

  if (latestControlItem && latestControlItem?.success === false && !isErrorShown) {
    LocalStorageService.set(tagId, 'error');
  }

  const isSuccessFalse = !latestControlItem?.success && !isDataLoading;

  const isControlError = isSuccessFalse || isErrorOnUpdateControls;

  const errorMessage =
    (latestControlItem && latestControlItem?.success === false && isAppliedChanges) || !isErrorShown
      ? `Failed to change to ${latestControlItem?.newValue}`
      : '';
  const successMessage =
    isAppliedChanges && !isDataLoading && latestControlItem && latestControlItem?.success
      ? 'Successfully updated'
      : '';

  const isMobile = useResponsive('down', 'md');
  const disableFiled =
    isCloseBtn || isDataLoading || !isTagEditable({ item: item, selectedMode: selectedMode });

  const inputOptions = getInputOptions(item);

  const onChangeHandler = (value: string) => {
    setIsAppliedChangedHandler(false);
    const key = `${item.dataType}-${item.id}`;

    setValue(key, value);
    enableApplyButtonHandler();
  };

  return (
    <RHFAutocomplete
      disabled={disableFiled}
      onChange={(_, newValue) => onChangeHandler(newValue as string)}
      name={`${item.dataType}-${item.id}`}
      options={['', ...(inputOptions as string[])]}
      filterOptions={(options: string[], state: any) =>
        filterOptions(options, state, ['', ...(inputOptions as string[])])
      }
      renderInput={(params) => (
        <TextField
          sx={textField(isControlError, theme, !!successMessage)}
          helperText={
            isErrorOnUpdateControls || (isSuccessFalse && latestControlItem)
              ? errorMessage
              : successMessage
          }
          {...params}
          fullWidth
          inputProps={{
            ...params.inputProps,
            'data-sm': `${title}-input`,
            readOnly: isMobile,
          }}
          label={`${item.displayName} ${!isStateDataType ? rawUnit : ''}`}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position="start">
                <LightTooltip
                  arrow
                  title={titleTooltip(item)}
                  placement="right-start"
                  enterTouchDelay={0}
                >
                  <Box>
                    <InfoIcon />
                  </Box>
                </LightTooltip>
              </InputAdornment>
            ),
          }}
        />
      )}
    />
  );
};
