import { Theme } from '@mui/material';
import { CriticalIcon, InfoIcon, OfflineIcon, WarningIcon } from 'src/assets';
import { AlarmData } from 'src/features/alarms';
import { EventStatus } from 'src/features/alarm-pop-up/types';

export const renderTitleIcon = (
  alarmData: AlarmData,
  theme: Theme,
  color: string | null = null
) => {
  const fillColor =
    color ??
    (alarmData.eventStatus === EventStatus.CLEARED
      ? theme.palette.grey[500]
      : alarmData.priority === 'Critical'
      ? theme.palette.error.main
      : alarmData.priority === 'Warning'
      ? theme.palette.warning.main
      : alarmData.priority === 'Offline'
      ? theme.palette.error.main
      : theme.palette.info.main);

  if (alarmData?.priority === 'Critical') {
    return <CriticalIcon fill={fillColor} />;
  } else if (alarmData?.priority === 'Warning') {
    return <WarningIcon fill={fillColor} />;
  } else if (alarmData?.priority === 'Offline') {
    return <OfflineIcon fill={fillColor} />;
  } else {
    return <InfoIcon fill={fillColor} />;
  }
};
