const setLocalStorage = (key: string, value: any) => {
  window.localStorage.setItem(key, value);
};

const getParsedLocalStorage = (key: string) =>
  window.localStorage.getItem(key) ? JSON.parse(window.localStorage.getItem(key)!) : '';

const getRawLocalStorage = (key: string) => window.localStorage.getItem(key);

const removeFromLocalStorage = (key: string) => {
  window.localStorage.removeItem(key);
};

export const LocalStorageService = {
  set: setLocalStorage,
  getParsed: getParsedLocalStorage,
  getRaw: getRawLocalStorage,
  remove: removeFromLocalStorage,
};
