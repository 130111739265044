import { useAuthentication } from 'src/features/authentication/context';
import { Protected } from 'src/features/protected-route/components';
import { AppLayout } from 'src/layouts';
import { EditUserAccountPage } from '../../pages';
import { ACCOUNT_ISSUE_PATH, EDIT_USER_ACCOUNT_HEADER, LOGIN_PATH } from '../../config';
import {
  NAV_ITEMS_HEADER_SITES_MANAGEMENT,
  getNavItemsHeaderSiutesManagementSAAndAA,
} from 'src/features';
import { ElementProps } from '../../types/element';
import { LoadingElement } from '../LoadingElement';
import { AccessRoles } from 'src/features/user-account-details/types';

export const UserAccountEditElement = ({ isSuperAdmin }: ElementProps) => {
  const { loggedIn, getCurrentRole, customerId, user } = useAuthentication();

  const isAccountAdmin = getCurrentRole(customerId.value, null) === AccessRoles.ACCOUNT_ADMIN;

  const getUniqueRedirectPath = () => {
    if (!loggedIn.value) return LOGIN_PATH;
    if (!isSuperAdmin && getCurrentRole(customerId.value, null) !== AccessRoles.ACCOUNT_ADMIN)
      return ACCOUNT_ISSUE_PATH + '?reason=Access Denied';
  };

  const UserAccountEditRouteElement = () => (
    <Protected
      isAuthenticated={loggedIn.value}
      isAuthorized={
        isSuperAdmin || getCurrentRole(customerId.value, null) === AccessRoles.ACCOUNT_ADMIN
      }
      uniqueRedirectPath={getUniqueRedirectPath()}
    >
      <AppLayout
        headerTitle={EDIT_USER_ACCOUNT_HEADER}
        headerNavItems={
          isSuperAdmin || isAccountAdmin
            ? getNavItemsHeaderSiutesManagementSAAndAA(user, isSuperAdmin)
            : NAV_ITEMS_HEADER_SITES_MANAGEMENT
        }
      >
        <EditUserAccountPage />
      </AppLayout>
    </Protected>
  );

  const getUserAccountEditElement = () => {
    if (!loggedIn.loaded || !customerId.loaded) {
      return <LoadingElement />;
    }

    return <UserAccountEditRouteElement />;
  };

  return getUserAccountEditElement();
};
