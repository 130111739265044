import { ApexOptions } from 'apexcharts';
import { formatUTCDateToLocal, unixTimestampToISO8601 } from 'src/utilities';

export const ADD_CHART_BTN_TEXT = 'Add Chart';
export const POINT_CHARTS_LOCAL_STORAGE_KEY = 'pointCharts';
export const SEPARATOR = '(sep)';

export const getChartOptions = (): ApexOptions => ({
  chart: {
    stacked: false,
    id: 'basic-bar-2',
    type: 'scatter',
    zoom: {
      type: 'xy',
    },
    toolbar: {
      autoSelected: 'zoom',
      tools: {
        download: false,
      },
    },
    animations: {
      enabled: false,
    },
    redrawOnParentResize: false,
    redrawOnWindowResize: false,
  },
  dataLabels: {
    enabled: false,
  },
  yaxis: {
    labels: {
      formatter: function (val) {
        return val.toFixed(1);
      },
    },
  },
  xaxis: {
    type: 'datetime',
    labels: {
      datetimeUTC: false,
      showDuplicates: false,
      format: 'MMM d yyyy hh:mmtt',
    },
  },
  markers: {
    size: 3,
  },
  tooltip: {
    x: {
      show: true,
      formatter: function (val) {
        return formatUTCDateToLocal(false, unixTimestampToISO8601(val / 1000));
      },
    },
    y: {
      formatter: function (val) {
        return val.toFixed(1);
      },
      title: {
        formatter: (seriesName) => seriesName,
      },
    },
  },
  legend: {
    formatter: (legendName) => legendName,
    showForSingleSeries: true,
    offsetY: 0,
    position: 'top',
    horizontalAlign: 'left',
  },
});
