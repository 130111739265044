import { IconsTypesProps } from './types';
import { CURRENT_COLOR } from 'src/config';

export const MoreVerticalFillIcon = ({ fill }: IconsTypesProps) => {
  const color = fill ?? CURRENT_COLOR;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <g id="evaMoreVerticalFill0">
        <g id="evaMoreVerticalFill1">
          <g id="evaMoreVerticalFill2" fill={color}>
            <circle cx="12" cy="12" r="2" />
            <circle cx="12" cy="5" r="2" />
            <circle cx="12" cy="19" r="2" />
          </g>
        </g>
      </g>
    </svg>
  );
};
