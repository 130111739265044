import {
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  SelectProps,
  Stack,
  useTheme,
} from '@mui/material';
import { FunctionComponent, useEffect, useState } from 'react';
import { useAssetPropertyData } from 'src/features/reports/context/assetPropertyContext';
import { getAvailableAssetProperties } from './utils/getAvailableAssetProperties';
import Label from 'src/components/minimals/form/Label';
import { useTemplateState } from 'src/features/reports/context/templateContext';
import assetTypes from '../../../../asset-details/data/asset_types.json';

const AssetPropertySelect: FunctionComponent<
  Omit<SelectProps, 'onChange'> & {
    fetchAssetProperties: boolean;
    value: any;
    onChange: (event: SelectChangeEvent<unknown>, type?: 'assetType' | 'assetProperty') => void;
  }
> = ({ value, fetchAssetProperties, onChange, ...props }) => {
  const [tags, setTags] = useState<{ value: string; label: string; available: boolean }[]>([]);
  const assetPropertyData = useAssetPropertyData();
  const { assetProperties } = useTemplateState();
  const theme = useTheme();

  useEffect(() => {
    setTags((prevTags) => getAvailableAssetProperties(prevTags, assetPropertyData, value.type));
  }, [assetPropertyData, value]);

  useEffect(() => {
    setTags(
      assetProperties
        .map((assetProp) => (assetProp[0] === value.type ? assetProp[1] : false))
        .filter(Boolean)[0] || []
    );
  }, [assetProperties, value]);

  return (
    <Stack gap="10px">
      <Label sx={{ backgroundColor: theme.palette.grey[200] }}>Asset Property</Label>
      <Stack gap="10px">
        <FormControl>
          <Label sx={{ backgroundColor: theme.palette.grey[200] }}>Asset Type</Label>
          <Select
            color="primary"
            value={value.type || ''}
            onChange={(event) => onChange(event, 'assetType')}
            {...props}
            inputProps={{ 'data-sm': 'assetType-input-field' }}
          >
            <MenuItem value={''}>Select Asset Type</MenuItem>
            {assetTypes.map((tag) => (
              <MenuItem key={tag.value} value={tag.value}>
                {tag.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl>
          <Label sx={{ backgroundColor: theme.palette.grey[200] }}>Asset Property</Label>
          <Select
            onChange={(event) => onChange(event)}
            disabled={!value.type}
            color="primary"
            value={value.property || ''}
            {...props}
            inputProps={{ 'data-sm': 'assetProperty-input-field' }}
          >
            <MenuItem value={''}>Select Asset Property</MenuItem>
            {tags.map((tag) => (
              <MenuItem
                disabled={!tag.available}
                key={tag.value}
                value={`${tag.value}_${tag.label}`}
              >
                {tag.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Stack>
    </Stack>
  );
};

export default AssetPropertySelect;
