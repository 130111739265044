import { Box, Typography, useTheme } from '@mui/material';
import { TimeOptionType } from '../../types';
import {
  OneDayRangeIcon,
  SenvenDaysRangeIcon,
  OneMonthRangeIcon,
  CustomOptionDateIcon,
  ArrowDownIconSmall,
} from 'src/assets';
import { StyledBoxIcon } from '../timeframe-bar-options/components/time-box-option/style';

type Props = {
  selectedTimeOption: TimeOptionType;
  onClick: (event: React.MouseEvent<HTMLDivElement>) => void;
};

export const SelectedTimeframeOption = ({ selectedTimeOption, onClick }: Props) => {
  const theme = useTheme();
  const mainColor = theme.palette.primary.main;

  const renderIcon = () => {
    if (selectedTimeOption === '1d') return <OneDayRangeIcon fill={mainColor} />;
    if (selectedTimeOption === '7d') return <SenvenDaysRangeIcon fill={mainColor} />;
    if (selectedTimeOption === '30d') return <OneMonthRangeIcon fill={mainColor} />;
    if (selectedTimeOption === 'custom') return <CustomOptionDateIcon fill={mainColor} />;
  };

  return (
    <Box sx={{ cursor: 'pointer' }} onClick={(e) => onClick(e)}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <StyledBoxIcon theme={theme} isActive={true}>
            {renderIcon()}
          </StyledBoxIcon>
          <Typography sx={{ margin: '0 6px', textTransform: 'capitalize' }}>
            {selectedTimeOption}
          </Typography>
        </Box>
        <ArrowDownIconSmall />
      </Box>
    </Box>
  );
};
