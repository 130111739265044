import { IconsTypesProps } from './types';
import { CURRENT_COLOR } from 'src/config';

export const CheckIcon = ({ fill }: IconsTypesProps) => {
  const color = fill ?? CURRENT_COLOR;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
      <path d="M382-240 154-468l57-57 171 171 367-367 57 57-424 424Z" fill={color} />
    </svg>
  );
};
