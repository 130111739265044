import { Cancel, Create } from '@mui/icons-material';
import { MenuItem, Stack, SvgIcon, Typography } from '@mui/material';
import { FunctionComponent } from 'react';
import { AssetProperty } from '../../../types';
import DraggableColumn from '../../sidebar/columns/DraggableColumn';
import assetTypes from '../../../../asset-details/data/asset_types.json';

const AssetPropertyDraggable: FunctionComponent<{
  item: AssetProperty;
  index: number;
  onEdit: (item: AssetProperty) => void;
  onRemove: (item: AssetProperty) => void;
}> = ({ item, index, onEdit, onRemove }) => {
  const { asset, aggregation, id } = item;
  const assetTypeLabel = assetTypes.find((assetType) => assetType.value === asset.type)?.label;
  return (
    <DraggableColumn
      renderContextItems={() => (
        <>
          <MenuItem onClick={() => onEdit(item)}>
            <Stack alignItems="center" gap="2px" flexDirection="row">
              <SvgIcon component={Create} />
              <Typography variant="body2">Edit</Typography>
            </Stack>
          </MenuItem>
          <MenuItem onClick={() => onRemove(item)}>
            <Stack alignItems="center" gap="2px" flexDirection="row">
              <SvgIcon color="error" component={Cancel} />
              <Typography color="error" variant="body2">
                Remove
              </Typography>
            </Stack>
          </MenuItem>
        </>
      )}
      id={id}
      index={index}
    >
      <Typography variant="body2" fontWeight="600" color="primary">
        {assetTypeLabel || asset.type} - {asset.property.label} - {aggregation.label}
      </Typography>
    </DraggableColumn>
  );
};

export default AssetPropertyDraggable;
