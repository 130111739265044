import { TableFooter } from '@mui/material';
import { TableFooterRow } from './components';
import { formatSecondsToHoursAndMinutes } from 'src/utilities/date-utility';

type Props = {
  data: Record<string, number>;
  columns: { key: string; value: string }[];
};

export const TableFooterComponent = ({ data, columns }: Props) => {
  const entries = data ? Object.entries(data) : [];

  return (
    <TableFooter>
      {entries?.map(([key, value]: any) => {
        const tableRowItem = { title: key, vals: [] } as { title: string; vals: any };

        columns.forEach((item) => {
          const val =
            item.key.includes('dailyRuntime') && value[item.key]
              ? formatSecondsToHoursAndMinutes(value[item.key])
              : value[item.key];
          tableRowItem.vals.push(val);
        });

        return (
          <TableFooterRow key={key} title={tableRowItem.title} cols={tableRowItem.vals.slice(1)} />
        );
      })}
    </TableFooter>
  );
};
