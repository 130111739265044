import { Theme, styled } from '@mui/material/styles';
import { Typography, Box } from '@mui/material';

type StyledTypes = {
  theme: Theme;
};

type StyledNoLiveDataSiteCardTypes = {
  theme: Theme;
  isMobile: boolean;
};

const StyledNoLiveDataSiteCard = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isMobile',
})(({ theme, isMobile }: StyledNoLiveDataSiteCardTypes) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '7px 12px',
  borderRadius: '8px',
  border: `1px solid ${theme.palette.grey[300]}`,
  marginTop: isMobile ? '10px' : '0',
}));

const StyledTextBox = styled(Box)(({ theme, isMobile }: StyledNoLiveDataSiteCardTypes) => ({
  display: 'flex',
  flexDirection: isMobile ? 'column' : 'row',
}));

const StyledNoLiveDataMessage = styled(Typography)(({ theme }: StyledTypes) => ({
  fontSize: '12px',
  color: theme.palette.grey[500],
}));

const StyledEditSiteMessage = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'isMobile',
})(({ theme, isMobile }: StyledNoLiveDataSiteCardTypes) => ({
  fontSize: '12px',
  fontWeight: '700',
  color: theme.palette.primary.main,
  margin: isMobile ? '0 27px 0 0' : '0 27px 0 3px',
  cursor: 'pointer',
  textDecoration: 'none',
  '&:hover': {
    textDecoration: 'underline',
  },
}));

const closeIconBox = {
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
};

export {
  StyledNoLiveDataSiteCard,
  StyledNoLiveDataMessage,
  StyledEditSiteMessage,
  closeIconBox,
  StyledTextBox,
};
