import { Box, BoxProps } from '@mui/material';
import { FunctionComponent, PropsWithChildren } from 'react';
import { cardFooter } from './style';

const CardFooter: FunctionComponent<PropsWithChildren<BoxProps>> = ({ children, ...props }) => (
  <Box sx={cardFooter} {...props}>
    {children}
  </Box>
);

export default CardFooter;
