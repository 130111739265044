import { Collapse, Stack } from '@mui/material';
import { FunctionComponent, PropsWithChildren, useEffect, useState } from 'react';
import { useResponsive } from 'src/hooks';

const SiteTab: FunctionComponent<PropsWithChildren> = ({ children }) => {
  const isMobile = useResponsive('down', 'md');
  const [isScrolled, setIsScrolled] = useState(false);
  useEffect(() => {
    let lastScrollPosition = 0;

    const handleScroll = function () {
      const currentScrollPosition = window.scrollY || document.documentElement.scrollTop;

      if (currentScrollPosition > lastScrollPosition) {
        setIsScrolled(true);
      } else if (currentScrollPosition < lastScrollPosition) {
        setIsScrolled(false);
      }

      lastScrollPosition = currentScrollPosition;
    };
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  const navigation = (
    <Stack
      justifyContent={isMobile ? 'center' : 'flex-start'}
      style={{ marginTop: 12 }}
      direction="row"
      alignItems="center"
    >
      {children}
    </Stack>
  );

  return isMobile ? <Collapse in={!isScrolled}>{navigation}</Collapse> : navigation;
};

export default SiteTab;
