import { Button, Stack, useTheme } from '@mui/material';
import { ChangeEvent, FunctionComponent, useRef, useState } from 'react';
import { AttachmentField as AttachmentFieldType } from '../types';
import { useFormContext } from 'react-hook-form';
import SectionTitle from './SectionTitle';
import { BlockStack } from '../styles';
import { v4 as uuid } from 'uuid';
import useQueryParamsActions from 'src/hooks/useQueryParamsActions';
import AttachmentsPreviewBox from 'src/features/logs/components/AttachmentsPreviewBox';
import AttachmentItem from './AttachmentItem';

const AttachmentField: FunctionComponent<{
  field: AttachmentFieldType;
  disabled: boolean;
}> = ({ field, disabled }) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const { setValue, watch } = useFormContext();
  const [attachmentPreviewBoxOpen, setAttachmentPreviewBoxOpen] = useState(false);
  const theme = useTheme();
  const files = watch(field.id + '_attachment') || [];
  const log = watch('id');
  const { get, remove } = useQueryParamsActions();
  const attachment = get('attachment');

  const handleUploadFile = (event: ChangeEvent) => {
    const { files: inputFiles } = event.target as HTMLInputElement;
    const filesArray = Array.from(inputFiles ? inputFiles : []);

    const newFilesValue = [...files, ...filesArray];
    setValue(
      field.id + '_attachment',
      newFilesValue.map(
        (
          file:
            | File
            | {
                id: string;
                name: string;
                order: number;
                mime_type: string;
              },
          index: number
        ) =>
          file instanceof File
            ? { id: uuid(), mime_type: file.type, file, name: file.name, order: index + 1 }
            : file
      )
    );
  };

  return (
    <BlockStack>
      <Stack flexDirection="row" alignItems="center" justifyContent="space-between">
        <SectionTitle>Attachments</SectionTitle>
        <Button
          disabled={disabled}
          variant="outlined"
          onClick={() => {
            if (inputRef.current) inputRef.current.click();
          }}
        >
          Add attachment
        </Button>
      </Stack>
      <input
        multiple
        onChange={handleUploadFile}
        type="file"
        accept={['image/jpeg', 'image/png', 'application/pdf'].join(',')}
        ref={inputRef}
        style={{ display: 'none' }}
      />
      {attachment && attachmentPreviewBoxOpen ? (
        <AttachmentsPreviewBox
          onClose={(event) => {
            event.stopPropagation();
            setAttachmentPreviewBoxOpen(false);
            remove('attachment');
          }}
          attachment={attachment}
          log={log}
          content={files.map((file: any, index: number) => ({ ...file, order: index + 1 }))}
        />
      ) : (
        <></>
      )}
      <Stack
        flex="1"
        sx={{
          overflow: 'auto',
          width: '100%',
          minHeight: '100px',
          maxWidth: '100%',
          paddingBlock: '16px',
          marginTop: theme.spacing(2),
        }}
        flexDirection="row"
        gap={theme.spacing(1)}
      >
        {files.map(
          (
            file:
              | { id: string; mime_type: string; file: File; name: string; order: number }
              | { id: string; name: string; mime_type: string },
            index: number
          ) => (
            <AttachmentItem
              key={index}
              disabled={disabled}
              onOpenPreviewBox={() => {
                setAttachmentPreviewBoxOpen(true);
              }}
              field={field}
              file={file}
              files={files}
              index={index}
            />
          )
        )}
      </Stack>
    </BlockStack>
  );
};

export default AttachmentField;
