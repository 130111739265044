import { IconsTypesProps } from './types';

export const MapPopupInfoIcon = ({ width = 15, height = 15 }: IconsTypesProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-testid="MapPopupInfoIcon"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 7.35 18.95 4.8 17.07 2.93C15.19 1.05 12.65 0 10 0ZM11 14C11 14.55 10.55 15 10 15C9.45 15 9 14.55 9 14V9C9 8.45 9.45 8 10 8C10.55 8 11 8.45 11 9V14ZM9 6C9 6.55 9.45 7 10 7C10.55 7 11 6.55 11 6C11 5.45 10.55 5 10 5C9.45 5 9 5.45 9 6Z"
      fill="#2486FD"
    />
  </svg>
);
