import { format } from 'date-fns';

export const formatTime = (utcDate: string) => {
  if (!utcDate) return {};
  const localDate = new Date(utcDate);

  const formattedDate = format(localDate, 'MMM do yyyy.');

  const formattedTime = format(localDate, 'h:mm a');

  return { formattedDate, formattedTime };
};
