import { Card, Stack, Typography, useTheme } from '@mui/material';

type Props = {
  title?: string;
};

export const NoAvailableDataBox = ({ title = 'No available data.' }: Props) => {
  const theme = useTheme();
  return (
    <Card
      sx={{ backgroundColor: theme.palette.background.default, width: '100%', padding: '2rem' }}
    >
      <Stack alignItems="center" height="100%" width="100%" justifyContent="center">
        <Typography textAlign="center" variant="h6">
          {title}
        </Typography>
      </Stack>
    </Card>
  );
};
