import { FunctionComponent } from 'react';
import { Section as SectionInterface } from '../types';
import Field from './Field';
import SectionTitle from './SectionTitle';
import { calculateFieldsLength } from '../utils/calculateFieldsLength';
import { checkBlockElement } from '../utils/checkBlockElement';
import { BlockStack, StyledSection } from '../styles';

const Section: FunctionComponent<SectionInterface & { disabled: boolean }> = ({
  disabled,
  ...section
}) => (
  <StyledSection>
    <SectionTitle sx={{ paddingBottom: '32px' }}>{section.name}</SectionTitle>
    <BlockStack flexDirection="row" gap="10px" flexWrap="wrap">
      {section.fields
        .sort((a, b) => a.order - b.order)
        .map((field, index) => (
          <Field
            disabled={disabled}
            blockElement={section.fields.length - 1 === index && checkBlockElement(section.fields)}
            groupName={section.id}
            fieldsLength={calculateFieldsLength(section.fields)}
            key={field.id}
            field={field}
          />
        ))}
    </BlockStack>
  </StyledSection>
);

export default Section;
