import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  Typography,
} from '@mui/material';
import { FunctionComponent } from 'react';

interface IDeleteCommentConfirmDialogProps extends DialogProps {
  onConfirm: () => void;
  isDeleteingComment: boolean;
  onClose: () => void;
}

const DeleteCommentConfirmDialog: FunctionComponent<IDeleteCommentConfirmDialogProps> = ({
  onConfirm,
  isDeleteingComment,
  onClose,
  ...props
}) => (
  <Dialog {...props}>
    <Box borderBottom="1px solid #919EAB3D" padding="24px">
      <Typography fontSize="18px" fontWeight="bold">
        Delete Comment
      </Typography>
    </Box>
    <DialogContent sx={{ padding: '24px' }}>
      <Typography fontSize="16px" fontWeight="400">
        Are you sure you want to delete this comment?
      </Typography>
    </DialogContent>
    <DialogActions sx={{ borderTop: '1px solid #919EAB3D' }}>
      <Button disabled={isDeleteingComment} onClick={onClose} variant="outlined">
        Cancel
      </Button>
      <Button disabled={isDeleteingComment} onClick={onConfirm} variant="contained" color="error">
        {isDeleteingComment ? 'Deleteing comment...' : 'Delete Comment'}
      </Button>
    </DialogActions>
  </Dialog>
);

export default DeleteCommentConfirmDialog;
