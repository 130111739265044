import { IconsTypesProps } from './types';
import { CURRENT_COLOR } from 'src/config';

export const KeyIcon = ({ fill }: IconsTypesProps) => {
  const color = fill ?? CURRENT_COLOR;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M5.83337 5C8.00837 5 9.85837 6.39167 10.5417 8.33333H19.1667V11.6667H17.5V15H14.1667V11.6667H10.5417C9.85837 13.6083 8.00837 15 5.83337 15C3.07504 15 0.833374 12.7583 0.833374 10C0.833374 7.24167 3.07504 5 5.83337 5ZM4.16671 10C4.16671 10.9167 4.91671 11.6667 5.83337 11.6667C6.75004 11.6667 7.50004 10.9167 7.50004 10C7.50004 9.08333 6.75004 8.33333 5.83337 8.33333C4.91671 8.33333 4.16671 9.08333 4.16671 10Z"
        fill={color}
      />
    </svg>
  );
};
