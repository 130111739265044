import { Container } from '@mui/material';
import { AddWidgetBox, SiteTrendsChartModal } from '../../components';
import { useToggle } from 'src/hooks';
import { useSiteLocalTrendsContext } from 'src/contexts/siteLocalTrendsContext';
import { LineChartTrend } from 'src/features/trends/components/line-chart-trend';
import { useAuthentication } from 'src/features/authentication/context';

export const SiteTrends = () => {
  const { state, onToggle } = useToggle();
  const { lineChartsLocalTrends, removeChart } = useSiteLocalTrendsContext();

  const {
    siteId: { value: siteId },
    customerId: { value: customerId },
  } = useAuthentication();

  const displayedCharts = lineChartsLocalTrends.filter(
    (chart) => chart.siteId === siteId && chart.customerId === customerId
  );

  return (
    <>
      <SiteTrendsChartModal state={state} onToggle={onToggle} />
      <Container maxWidth={false} disableGutters data-sm="site-trends-container">
        {displayedCharts?.map((chart: any) => (
          <LineChartTrend
            key={chart.id}
            removeChart={removeChart}
            pointChart={chart}
            isLocalTrends={true}
          />
        ))}
        <AddWidgetBox fullWidth title="Add Chart" onToggle={onToggle} />
      </Container>
    </>
  );
};
