import { useState, useEffect } from 'react';
import {
  WaterTower,
  Pump,
  LiftStationWetWell,
  RunTimeChart,
  TrendWidget,
  Controller,
} from 'src/features/widgets/components';
import { WidgetType, WidgetTypeEnum, AssetType, WidgetDataTypeEnum } from '../../types/types';
import { AssetService, ControllersService } from 'src/services';
import {
  useWaterLevelData,
  usePumpRuntimeData,
  useCycleCountData,
  useTrendWidgetData,
  useAssetStateData,
} from '../../hooks';
import { WIDGET_TYPES_CONNECTED_TO_ASSET } from '../../config';
import { useAuthentication } from 'src/features/authentication/context';
import { getTitleForTrendWidgets } from '../../utils';
import { Controller as ControllerType, TagResponse } from 'src/types';
import { CycleCountChart } from 'src/features/widgets/components/CycleCountChart';
import { Valve } from 'src/features/widgets/components/Valve/Valve';
import { useSite } from 'src/contexts/siteContext/sites.context';
import { LiveDataWidget } from 'src/features/widgets/components/LiveDataWidget';
import { useWebSockets } from 'src/contexts/webSocketsContext/websockets.context';
import { useResponsive } from 'src/hooks';

type WidgetComponentType = {
  widget: WidgetType;
  isEditMode: boolean;
  toggleAddOrRemoveHandler: () => void;
  gatewayInputs: TagResponse[];
  isAddedController?: boolean;
  controllers?: ControllerType[];
  getControllers: () => void;
};

export const WidgetComponent = ({
  widget,
  isEditMode,
  toggleAddOrRemoveHandler,
  gatewayInputs,
  isAddedController,
  controllers,
  getControllers,
}: WidgetComponentType) => {
  const {
    customerId: { value: customerId },
    siteId: { value: siteId },
    user,
  } = useAuthentication();
  const [asset, setAsset] = useState<AssetType>();
  const [controller, setController] = useState<ControllerType>({
    id: '',
    controllerName: '',
    controllerType: '',
    gatewayId: '',
  });
  const { connectedTo } = widget;
  const isMobile = useResponsive('down', 'md');
  const { site } = useSite();
  const siteTimeZone = site?.location?.timeZone;
  const { messages } = useWebSockets();

  const { dailyCycleCounts } = useCycleCountData({
    customerId: customerId,
    siteId: siteId,
    asset: asset!,
  });

  const { waterLevel, height, alarms } = useWaterLevelData({
    asset: asset!,
    customerId: customerId,
  });
  const { assetState } = useAssetStateData({ asset: asset! });
  const { dailyRuntimes } = usePumpRuntimeData({
    customerId: customerId,
    siteId: siteId,
    asset: asset!,
  });
  const gatewayInput = gatewayInputs?.find((input) => input.id === connectedTo);

  const { inputData } = useTrendWidgetData({
    gatewayInput: gatewayInput!,
    customerId: customerId,
    user: user,
  });

  useEffect(() => {
    if (!customerId || !siteId || !isAddedController || !connectedTo) return;

    ControllersService.getById(customerId, siteId, connectedTo).then((response) => {
      setController(response);
    });
  }, [connectedTo, customerId, siteId, isAddedController]);

  useEffect(() => {
    if (!customerId || !siteId) return;
    if (WIDGET_TYPES_CONNECTED_TO_ASSET.includes(widget.widgetType))
      AssetService.getAssetById({
        customerId: customerId,
        siteId: siteId,
        assetId: widget.connectedTo,
      })
        .then((data) => setAsset(data))
        .catch((error) => console.error(error));
  }, [customerId, siteId, widget.connectedTo, widget.widgetType]);

  switch (widget.widgetType) {
    case WidgetTypeEnum.TOWER:
      return (
        <WaterTower
          widgetId={widget.id}
          title={asset ? asset.assetName : ''}
          height={height}
          waterLevel={waterLevel}
          unit={''}
          alarms={alarms}
          isEditMode={isEditMode}
          testId="tower-widget"
          toggleAddOrRemoveHandler={toggleAddOrRemoveHandler}
          asset={asset}
        />
      );
    case WidgetTypeEnum.PUMP:
      return (
        <Pump
          widgetSubType={widget.widgetSubType}
          title={asset ? asset.assetName : ''}
          runMode={assetState}
          mode={''}
          isEditMode={isEditMode}
          widgetId={widget.id}
          testId="pump-widet"
          toggleAddOrRemoveHandler={toggleAddOrRemoveHandler}
          asset={asset}
        />
      );
    case WidgetTypeEnum.LIFT_STATION_WET_WELL:
      return (
        <LiftStationWetWell
          height={height}
          waterLevel={waterLevel}
          title={asset ? asset.assetName : ''}
          unit={''}
          alarms={alarms!}
          isEditMode={isEditMode}
          widgetId={widget.id}
          testId="wet-well-widget"
          toggleAddOrRemoveHandler={toggleAddOrRemoveHandler}
          asset={asset}
        />
      );
    case WidgetTypeEnum.BARS_GRAPH:
      if (widget.widgetDataType === WidgetDataTypeEnum.RUNTIME) {
        return (
          <RunTimeChart
            title={asset ? `${asset.assetName} -` : ''}
            values={
              dailyRuntimes !== null
                ? dailyRuntimes!.map((x) => ({ label: x.day, value: x.value }))
                : null
            }
            isEditMode={isEditMode}
            widgetId={widget.id}
            testId="bars-graph-widget"
            toggleAddOrRemoveHandler={toggleAddOrRemoveHandler}
            asset={asset}
            siteTimeZone={siteTimeZone}
          />
        );
      } else if (widget.widgetDataType === WidgetDataTypeEnum.DAILY_CYCLE_COUNT) {
        return (
          <CycleCountChart
            title={asset ? `${asset.assetName} -` : ''}
            values={
              dailyCycleCounts !== null
                ? dailyCycleCounts!.map((x) => ({ label: x.day, value: x.value }))
                : null
            }
            isEditMode={isEditMode}
            widgetId={widget.id}
            testId="bars-graph-widget"
            toggleAddOrRemoveHandler={toggleAddOrRemoveHandler}
            asset={asset}
            siteTimeZone={siteTimeZone}
          />
        );
      } else {
        return (
          <RunTimeChart
            title={asset ? `${asset.assetName} -` : ''}
            values={
              dailyRuntimes !== null
                ? dailyRuntimes!.map((x) => ({ label: x.day, value: x.value }))
                : null
            }
            isEditMode={isEditMode}
            widgetId={widget.id}
            testId="bars-graph-widget"
            toggleAddOrRemoveHandler={toggleAddOrRemoveHandler}
            asset={asset}
            siteTimeZone={siteTimeZone}
          />
        );
      }

    case WidgetTypeEnum.SPIRAL_DOTS_GRAPH:
      return (
        <TrendWidget
          type="continuous"
          title={getTitleForTrendWidgets(gatewayInput)}
          unit={gatewayInput?.rawUnit ?? ''}
          isEditMode={isEditMode}
          widgetId={widget.id}
          testId="spiral-dots-graph-widget"
          toggleAddOrRemoveHandler={toggleAddOrRemoveHandler}
          values={inputData}
          gatewayInput={gatewayInput}
        />
      );

    case WidgetTypeEnum.LIVE_DATA:
      return (
        <LiveDataWidget
          title={widget.title ?? 'Live Data'}
          isDataReady={true}
          tags={widget.tags}
          widgetId={widget.id}
          toggleAddOrRemoveHandler={toggleAddOrRemoveHandler}
          testId="live-data-widget"
          isEditMode={isEditMode}
          messages={messages}
          isMobile={isMobile}
          pos={widget.positioning}
        />
      );
    case WidgetTypeEnum.LINEAR_GRAPH:
      return (
        <TrendWidget
          type="discrete"
          title={getTitleForTrendWidgets(gatewayInput)}
          unit={gatewayInput?.rawUnit ?? ''}
          isEditMode={isEditMode}
          widgetId={widget.id}
          testId="linear-graph-widget"
          toggleAddOrRemoveHandler={toggleAddOrRemoveHandler}
          values={inputData}
          gatewayInput={gatewayInput}
        />
      );
    case WidgetTypeEnum.CONTROLLER_WIDGET:
      return (
        <Controller
          title={controller?.controllerName}
          controllerId={controller?.id}
          gatewayId={controller?.gatewayId}
          testId="controller-widget"
          isEditMode={isEditMode}
          widgetId={widget.id}
          toggleAddOrRemoveHandler={toggleAddOrRemoveHandler}
          controllers={controllers}
          getControllers={getControllers}
        />
      );
    case WidgetTypeEnum.VALVE:
      return (
        <Valve
          widgetId={widget.id}
          isEditMode={isEditMode}
          toggleAddOrRemoveHandler={toggleAddOrRemoveHandler}
          title={asset ? asset.assetName : ''}
          asset={asset}
          testId="valve-widget"
        />
      );

    default:
      return null;
  }
};

export default WidgetComponent;
