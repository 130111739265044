import { useFormContext, Controller, ValidationRule } from 'react-hook-form';
import {
  CircularProgress,
  MenuItem,
  Select,
  SelectProps,
  FormControl,
  FormHelperText,
  InputLabel,
  SxProps,
  ListItemIcon,
  Stack,
  Box,
  useTheme,
} from '@mui/material';
import { SelectDropdownData } from 'src/types';
import { useState } from 'react';

interface RHFSelectProps<T> extends Omit<SelectProps<T>, 'name'> {
  name: string;
  options: SelectDropdownData[];
  rules?: { [key: string]: ValidationRule | ValidationRule[] };
  optional?: boolean;
  testId?: string;
  isLoading?: boolean;
  helperText?: string | boolean;
  label?: string;
  size?: 'small' | 'medium';
  sx?: SxProps;
}

export const RHFSelect = <T,>({
  name,
  rules,
  options,
  optional = false,
  testId = '',
  isLoading = false,
  helperText,
  label,
  size,
  sx,
  ...other
}: RHFSelectProps<T>) => {
  const { control, getFieldState } = useFormContext();
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    sx: { width: '100%', maxWidth: '100%' },
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        backgroundColor: theme.palette.background.default,
      },
    },
  };

  const handleOpen = () => {
    setOpen(true);
    document.body.style.touchAction = 'none';
  };

  const handleClose = () => {
    setOpen(false);
    document.body.style.touchAction = 'auto';
  };

  const renderMenuContent = () =>
    isLoading ? (
      <MenuItem key={'loader'} value={'loading'}>
        Loading... <CircularProgress sx={{ ml: '8px' }} size={'16px'} />
      </MenuItem>
    ) : (
      options.map((option, index) =>
        option?.value !== '' ? (
          <MenuItem key={option.value} value={option.value}>
            <Stack
              maxWidth="100%"
              flexDirection="row"
              sx={{ textOverflow: 'ellipsis', overflow: 'hidden', alignItems: 'center' }}
            >
              {option.icon && (!option.iconPosition || option.iconPosition === 'start') ? (
                <ListItemIcon sx={{ minWidth: 'initial', mr: '8px' }}>{option.icon}</ListItemIcon>
              ) : null}
              {option.label}
              {option.icon && option.iconPosition === 'end' ? (
                <ListItemIcon sx={{ minWidth: 'initial', mr: '8px' }}>{option.icon}</ListItemIcon>
              ) : null}
            </Stack>
          </MenuItem>
        ) : null
      )
    );

  const getSpecificOption = (value: T) => options.find((option) => option.value === value);

  const renderValue = (value: T): React.ReactNode => {
    if (Array.isArray(value)) {
      return (
        <Stack gap="10px" flexDirection="row">
          {value.map((el) => (
            <Stack
              key={el}
              sx={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              {el}
            </Stack>
          ))}
        </Stack>
      );
    }
    const specificOption = getSpecificOption(value);
    const optionLabel = specificOption?.label;
    const optionIcon = specificOption?.icon;
    const iconPosition = specificOption?.iconPosition;

    return (
      <Stack
        sx={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        {optionIcon && (!iconPosition || iconPosition === 'start') ? (
          <ListItemIcon sx={{ minWidth: 'initial', mr: '8px' }}>{optionIcon}</ListItemIcon>
        ) : null}
        {optionLabel}
        {optionIcon && iconPosition === 'end' ? (
          <ListItemIcon sx={{ minWidth: 'initial', mr: '8px' }}>{optionIcon}</ListItemIcon>
        ) : null}
      </Stack>
    );
  };

  const hasError = !!Object.keys(getFieldState(name).error || {}).length;

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      defaultValue={other.multiple ? [] : ''}
      render={({ field: { onChange, ...fieldProps } }) => (
        <Box sx={{ minWidth: other.style?.minWidth ? other.style.minWidth : 120 }}>
          <FormControl fullWidth size={size} sx={{ ...sx }}>
            {label ? (
              <InputLabel sx={{ width: '70%', height: '70%', fontSize: '14px' }}>
                {label}
              </InputLabel>
            ) : null}
            <Select
              error={hasError}
              open={open}
              onOpen={handleOpen}
              onClose={handleClose}
              onChange={(event) => {
                onChange(event.target.value);
              }}
              inputProps={{
                'data-sm': testId,
              }}
              MenuProps={MenuProps}
              renderValue={renderValue}
              label={label}
              {...fieldProps}
              {...other}
            >
              {optional ? (
                <MenuItem key={'none'} value="">
                  <em>None</em>
                </MenuItem>
              ) : null}
              {renderMenuContent()}
            </Select>
            {helperText ? <FormHelperText error={hasError}>{helperText}</FormHelperText> : null}
          </FormControl>
        </Box>
      )}
    />
  );
};
