import { IconsTypesProps } from './types';
import { CURRENT_COLOR } from 'src/config';

export const RefreshIcon = ({ fill }: IconsTypesProps) => {
  const color = fill ?? CURRENT_COLOR;

  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.7666 4.23341C10.6799 3.14674 9.13993 2.52008 7.4466 2.69341C4.99993 2.94008 2.9866 4.92674 2.71327 7.37341C2.3466 10.6067 4.8466 13.3334 7.99993 13.3334C10.1266 13.3334 11.9533 12.0867 12.8066 10.2934C13.0199 9.84674 12.6999 9.33341 12.2066 9.33341C11.9599 9.33341 11.7266 9.46674 11.6199 9.68674C10.8666 11.3067 9.05993 12.3334 7.0866 11.8934C5.6066 11.5667 4.41327 10.3601 4.09993 8.88008C3.53993 6.29341 5.5066 4.00008 7.99993 4.00008C9.1066 4.00008 10.0933 4.46008 10.8133 5.18674L9.8066 6.19341C9.3866 6.61341 9.67993 7.33341 10.2733 7.33341H12.6666C13.0333 7.33341 13.3333 7.03341 13.3333 6.66674V4.27341C13.3333 3.68008 12.6133 3.38008 12.1933 3.80008L11.7666 4.23341Z"
        fill={color}
      />
    </svg>
  );
};
