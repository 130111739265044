export function addIndexesToDuplicates(array: string[]) {
  const count: any = {};
  const result = [];

  for (const element of array) {
    let modifiedElement = element;
    if (count[element] === undefined) {
      count[element] = 0;
    } else {
      count[element]++;
      modifiedElement = `${element} (${count[element]})`;
    }
    result.push(modifiedElement);
  }

  return result;
}
