import { LabelColor } from 'src/components';
import { AlarmPriority } from '../types';

const getPriorityColor = (priority: string, eventStatus: string): LabelColor => {
  if (eventStatus === 'cleared') {
    return 'default';
  } else if (priority === AlarmPriority.CRITICAL) {
    return 'error';
  } else if (priority === AlarmPriority.WARNING) {
    return 'warning';
  } else if (priority === AlarmPriority.OFFLINE) {
    return 'error';
  } else {
    return 'info';
  }
};

export { getPriorityColor };
