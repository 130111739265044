import { Box, styled } from '@mui/material';

export const boxWrapperStyle = {
  width: '800px',
  maxWidth: '100%',
  pb: '12px',
};
export const stackStyle = {
  minWidth: 'min-content',
  padding: '0 15px',
};

export const StyledStackBox = styled(Box)(({ theme }) => ({
  overflowX: 'auto',
  maxWidth: '100%',
  '&::-webkit-scrollbar': {
    height: '8px',
    width: '8px',
  },
  '&::-webkit-scrollbar-track': {
    backgroundColor: 'transparent',
    borderRadius: '4px',
  },
  '&::-webkit-scrollbar-thumb': {
    background: `${theme.scrollbar?.thumb}`,
    borderRadius: '4px',
  },
  '&::-webkit-scrollbar-thumb:hover': {
    background: `${theme.scrollbar?.thumbHover}`,
  },
}));
