import { Theme, styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';

type StylesTypes = {
  theme: Theme;
};
const StyledTrendModalContent = styled(Box)(({ theme }: StylesTypes) => ({
  maxWidth: '1000px',
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: theme.palette.background.default,
}));

const trendModalContentHeader = {
  display: 'flex',
  justifyContent: 'space-between',
  padding: '2rem',
};

const chartWrapper = {
  width: '1300px',
  maxWidth: '90%',
  margin: '0 auto ',
  height: '350px',
  padding: '2rem 0 3rem 0',
};

const closeIconBox = {
  cursor: 'pointer',
};

const StyledLabel = styled(Typography)(({ theme }: { theme: Theme }) => ({
  fontSize: '14px',
  position: 'absolute',
  transform: 'rotate(-90deg)',
  left: 13,
  bottom: 35,
  color: theme.palette.grey[600],
}));

export {
  StyledTrendModalContent,
  trendModalContentHeader,
  chartWrapper,
  closeIconBox,
  StyledLabel,
};
