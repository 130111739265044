import { FunctionComponent, useEffect } from 'react';
import { FormSchema } from '../types';
import { FormProvider, useForm } from 'react-hook-form';
import { formatFormFields, orderElements } from '../utils/formUtils';
import { isSection } from '../guards';
import Section from './Section';
import '../styles.css';
import Field from './Field';
import CommentsField from './CommentsField';
import FormRendererContextProvider from '../formRenderer.context';
import DateField from './DateField';
import DateRangeField from './DateRangeField';
import { Box, Stack, useTheme } from '@mui/material';
import { useResponsive } from 'src/hooks';

interface IFormRendererProps {
  onSubmit: (data: any) => void;
  schema: FormSchema;
  preview?: boolean;
  onChange?: (data: any) => void;
  formData?: any;
  children?: JSX.Element;
  formActionsComponent: JSX.Element;
}

const FormRenderer: FunctionComponent<IFormRendererProps> = ({
  onSubmit,
  children,
  onChange,
  schema,
  formData,
  preview,
  formActionsComponent,
}) => {
  const isDesktop = useResponsive('up', 'md');
  const methods = useForm({
    defaultValues: formData
      ? { ...formatFormFields(schema), ...formData }
      : formatFormFields(schema),
  });
  const theme = useTheme();
  const data = methods.watch();

  useEffect(() => {
    onChange && onChange(data);
  }, [onChange, data]);
  return (
    <FormRendererContextProvider schema={schema}>
      <form className="form" onSubmit={methods.handleSubmit(onSubmit)}>
        <FormProvider {...methods}>
          <Stack
            paddingBottom="80px"
            maxHeight={isDesktop ? '80vh' : '100%'}
            height="100%"
            overflow="auto"
          >
            <Box
              sx={{
                flexWrap: 'wrap',
                display: 'flex',
                flexDirection: 'row',
                gap: '10px',
              }}
            >
              <Box sx={{ flexBasis: '100%', paddingBlock: theme.spacing(2) }}>
                {schema.dateType === 'date' ? (
                  <DateField disabled={!!preview} />
                ) : (
                  <DateRangeField disabled={!!preview} />
                )}
              </Box>
              {orderElements(schema).map((element) =>
                isSection(element) ? (
                  <Section disabled={!!preview} key={element.id} {...element} />
                ) : (
                  <Field
                    disabled={!!preview}
                    key={element.id}
                    fieldsLength={3}
                    blockElement={false}
                    field={element}
                  />
                )
              )}
              {schema.allowComments && <CommentsField disabled={!!preview} />}
              {children ? children : <></>}
            </Box>
          </Stack>
          {formActionsComponent}
        </FormProvider>
      </form>
    </FormRendererContextProvider>
  );
};

export default FormRenderer;
