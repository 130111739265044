export function formatSecondsToDhms(seconds: number): string {
  const days = Math.floor(seconds / (3600 * 24));
  const hours = Math.floor((seconds % (3600 * 24)) / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const secs = Math.floor(seconds % 60);

  if (days > 0) {
    return `${days}d ${hours > 0 ? hours + 'h' : ''}`;
  } else if (hours > 0) {
    return `${hours}h ${minutes > 0 ? minutes + 'm' : ''}`;
  } else if (minutes > 0) {
    return `${minutes}m ${secs > 0 ? secs + 's' : ''}`;
  } else {
    return `${secs}s`;
  }
}
