import { FC, useEffect, useState } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import {
  ListItem,
  ListItemAvatar,
  Avatar,
  TextField,
  Autocomplete,
  IconButton,
  Stack,
  Typography,
  useTheme,
  useAutocomplete,
  SxProps,
  Theme,
} from '@mui/material';
import { Close, DragIndicator } from '@mui/icons-material';
import { useResponsive } from 'src/hooks';
import { uniqueFilterOptions } from 'src/utilities';
import { TagType } from '../../types/types';

export type Props = {
  item: any;
  index: number;
  rows: any[];
  selectedTags: string[];
  onRemoveRow: (id: string) => void;
  dropdownData: { value: any[]; loaded: boolean };
  handleSetRow: (
    rowId: string,
    tagId: string,
    gatewayId: string,
    parentType: string,
    tag: TagType | null
  ) => void;
  handleMobileListWidth: (value: string) => void;
  customListboxProps?: ReturnType<ReturnType<typeof useAutocomplete>['getListboxProps']> & {
    sx?: SxProps<Theme>;
    ref?: React.Ref<Element>;
  };
};

export const DraggableListItem: FC<Props> = ({
  item,
  index,
  rows,
  selectedTags,
  onRemoveRow,
  dropdownData,
  handleSetRow,
  handleMobileListWidth,
  customListboxProps,
}) => {
  const theme = useTheme();
  const isMobile = useResponsive('down', 'sm');
  const [selectedTag, setSelectedTag] = useState<{
    tagId: string;
    gatewayName: string;
    connectedAsset: string;
  } | null>(null);
  const renderTagOptions = (props: any, option: string, state: any) => {
    const tag = dropdownData.value.find((tag) => tag.id === option);
    return (
      <Stack
        {...props}
        key={tag.id}
        data-testid={'dropdown-item'}
        sx={{
          flex: 1,
          flexDirection: 'row',
          height: '50px',
          justifyContent:
            tag && tag.displayName !== '' ? 'space-between!important' : 'flex-end!important',
        }}
      >
        <Typography
          variant="body2"
          sx={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            fontWeight: 700,
          }}
        >
          {tag?.displayName}
        </Typography>
        {renderAdditionalLabels(tag)}
      </Stack>
    );
  };

  const renderAdditionalLabels = (tag: any) => (
    <Stack sx={{ flexDirection: 'row' }}>
      {tag?.gatewayName ? (
        <Stack
          sx={{
            background: theme?.palette?.info?.lighter,
            borderRadius: '10px',
            padding: '4px 16px',
            justifyContent: 'center!important',
            alignItems: 'center!important',
          }}
        >
          <Typography
            variant="body2"
            sx={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              fontWeight: 700,
            }}
            color={theme?.palette?.info?.dark}
          >
            {tag?.gatewayName}
          </Typography>
        </Stack>
      ) : null}
      {tag?.connectedAsset ? (
        <Stack
          sx={{
            background: theme.palette.grey[300],
            borderRadius: '10px',
            padding: '4px 16px',
            justifyContent: 'center!important',
            alignItems: 'center!important',
            ml: '8px',
          }}
        >
          <Typography
            variant="body2"
            sx={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              fontWeight: 700,
            }}
            color={theme.palette.grey[700]}
          >
            {tag?.connectedAsset}
          </Typography>
        </Stack>
      ) : null}
    </Stack>
  );

  useEffect(() => {
    const tag = dropdownData.value.find((val) => val.id === item.tagId);
    if (!tag) return;
    setSelectedTag(
      item.tagId !== ''
        ? {
            tagId: tag.id,
            gatewayName: tag.gatewayName,
            connectedAsset: tag.connectedAsset,
          }
        : null
    );
  }, [item.tagId, dropdownData.value]);

  const hasAtLeastTwoNonEmptyStrings = (): boolean => {
    let nonEmptyCount = 0;

    for (const item of rows) {
      if (item && item.tagId !== '') {
        nonEmptyCount++;
        if (nonEmptyCount >= 2) {
          return true;
        }
      }
    }

    return false;
  };

  const handleDropdownChange = (newValue: string | null) => {
    if (!newValue) {
      if (!hasAtLeastTwoNonEmptyStrings()) handleMobileListWidth('100%');
      setSelectedTag(null);
      handleSetRow(item.id, '', '', 'gateway', null);
      return;
    }

    const tag = dropdownData.value.find((val) => val.id === newValue);
    setSelectedTag({
      tagId: tag.id,
      gatewayName: tag.gatewayName,
      connectedAsset: tag.connectedAsset,
    });

    handleMobileListWidth('fit-content');

    if (tag)
      handleSetRow(item.id, tag.id, tag.gatewayId, tag.parentType, {
        tagId: tag.id,
        alarm: tag.alarm,
        connectedToAssets: tag.connectedToAssets,
        dataType: tag.dataType,
        displayName: tag.displayName,
        gatewayId: tag.gatewayId,
        value: tag.value,
        position: tag.position,
        rawUnit: tag.rawUnit,
      });
  };

  const handleRemoveRow = (id: string) => {
    onRemoveRow(id);
    if (!hasAtLeastTwoNonEmptyStrings()) handleMobileListWidth('100%');
  };

  const getUpgradedLabels = () => {
    const labels = dropdownData.value.map(
      (val) =>
        (val.displayName ? val.displayName : val.rawInputName) +
        ' ' +
        val.gatewayName +
        ' ' +
        val.connectedAsset
    );

    return labels;
  };

  return (
    <Draggable draggableId={item.id} index={index}>
      {(provided, snapshot) => (
        <ListItem
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          sx={{
            background: snapshot.isDragging ? 'rgb(235,235,235)' : 'transparent',
            padding: isMobile ? '8px 0' : '8px 16px',
          }}
          data-testid={`row-${item.position}`}
        >
          <ListItemAvatar sx={{ minWidth: isMobile ? 'auto' : '56px' }}>
            <Avatar sx={{ backgroundColor: 'transparent' }}>
              <DragIndicator color="action" />
            </Avatar>
          </ListItemAvatar>
          <Autocomplete
            fullWidth
            size="small"
            ListboxProps={{
              ...customListboxProps,
              className: 'custom-autocomplete-scrollbar',
              style: {
                display: isMobile ? 'block' : customListboxProps?.style?.display,
                width: isMobile ? 'max-content' : customListboxProps?.style?.width,
                overflowX: isMobile ? 'auto' : customListboxProps?.style?.overflowX,
              },
            }}
            options={['', ...dropdownData.value.map((val) => val.id)]}
            renderOption={renderTagOptions}
            getOptionLabel={(option: string) => {
              const values = ['', ...dropdownData.value.map((val) => val.id)];
              const labels = [
                '',
                ...dropdownData.value.map((val) =>
                  val.displayName ? val.displayName : val.rawInputName
                ),
              ];
              const index = values.indexOf(option);
              return `${labels[index]}`;
            }}
            filterOptions={(options: string[], state: any) =>
              uniqueFilterOptions(options, state, ['', ...getUpgradedLabels()], selectedTags)
            }
            value={item.tagId && item.tagId !== '' ? item.tagId : ''}
            onChange={(_, newValue) => handleDropdownChange(newValue)}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                data-testid={`text-field-${item.position}`}
                inputProps={{
                  ...params.inputProps,
                  'data-sm': 'alarm-priority-input',
                }}
                label="Select a tag"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {renderAdditionalLabels(selectedTag)}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
          />
          <IconButton
            data-testid={`remove-${item.id}`}
            onClick={() => handleRemoveRow(item.id)}
            sx={{ ml: isMobile ? 0 : '8px' }}
          >
            <Close />
          </IconButton>
        </ListItem>
      )}
    </Draggable>
  );
};
