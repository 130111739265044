import { Theme, styled } from '@mui/material/styles';
import { Button } from '@mui/material';

type StylesTypes = {
  theme: Theme;
  isActive: boolean;
};

const StyledFilterButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'isActive',
})(({ theme, isActive }: StylesTypes) => ({
  background: isActive ? theme.palette.primary.lighter : 'rgba(0,0,0,0)',
  color: theme.palette.primary.main,
  padding: '6px 8px',
}));

const filtersBox = {
  margin: '16px 0 12px 0',
};

const filterBtnBox = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  flexDirection: 'row',
  marginBottom: '12px',
};

export { StyledFilterButton, filtersBox, filterBtnBox };
