import { useEffect, useState } from 'react';
import { TagResponse } from 'src/features/alarm-rules/types';
import { convertToNumber } from '../utils';
import { useWebSockets } from 'src/contexts/webSocketsContext/websockets.context';
import { UserTypes } from 'src/features/authentication/types';

type useTrendWidgetDataArgumentsTypes = {
  gatewayInput: TagResponse;
  customerId: string | null;
  user: UserTypes | any;
};
export const useTrendWidgetData = ({ gatewayInput }: useTrendWidgetDataArgumentsTypes) => {
  const [inputData, setInputData] = useState<any[] | null>(null);
  let { messages } = useWebSockets();
  const timestamp = Number(messages?.data?.timestamp);
  const gatewayInputs = messages?.data?.gateway_inputs;
  const findInput = gatewayInputs?.find(
    (input: { id: string | undefined }) => input.id === gatewayInput?.id
  );

  useEffect(() => {
    if (findInput) {
      setInputData((prevState: any[] | null) => {
        if (prevState === null) {
          return [
            {
              inputName: findInput.displayName,
              rawUnit: findInput.rawUnit,
              timestamp: new Date(timestamp * 1000).toString(),
              input: convertToNumber(findInput.value),
            },
          ];
        }
        return [
          {
            inputName: findInput.displayName,
            rawUnit: findInput.rawUnit,
            timestamp: new Date(timestamp * 1000).toString(),
            input: convertToNumber(findInput.value),
          },
          ...prevState,
        ];
      });
    }
  }, [findInput, timestamp]);

  return { inputData };
};
