import { Chip } from '@mui/material';

type Props = {
  title: string;
  onDelete: () => void;
};

export const SelectedFilterPill = ({ title, onDelete }: Props) => (
  <Chip label={title} onDelete={onDelete} />
);
