import { useMemo, useCallback } from 'react';
import { useAuthentication } from 'src/features/authentication/context';
import { Protected } from 'src/features/protected-route/components';
import { ACCOUNT_ISSUE_PATH, LOGIN_PATH } from '../../config';
import { AppLayout } from 'src/layouts';
import { NAV_ITEMS_HEADER_SELECTED_SITE, getNavItemsHeaderSelectedSiteSAAndAA } from 'src/features';
import { ElementProps } from '../../types/element';
import { LoadingElement } from '../LoadingElement';
import { useSite } from 'src/contexts/siteContext/sites.context';
import EditSite from 'src/features/sites/pages/EditSite';
import { AccessRoles } from 'src/features/user-account-details/types';

export const SiteInfoElement = ({ isSuperAdmin }: ElementProps) => {
  const {
    isCustomerAccessAllowed,
    isSiteAccessAllowed,
    loggedIn,
    customerId,
    siteId,
    siteIdBelongsToCustomer,
    getCurrentRole,
    user,
  } = useAuthentication();
  const { getSite } = useSite();

  const isAccountAdmin =
    getCurrentRole(customerId.value, siteId.value) === AccessRoles.ACCOUNT_ADMIN;

  const getUniqueRedirectPath = useCallback(() => {
    if (!loggedIn.value) return LOGIN_PATH;

    if (!isSuperAdmin && (!isCustomerAccessAllowed.value || !isSiteAccessAllowed.value)) {
      return `${ACCOUNT_ISSUE_PATH}?reason=Access Denied`;
    }

    if (!siteIdBelongsToCustomer.value) {
      return `${ACCOUNT_ISSUE_PATH}?reason=Site does not exist`;
    }

    return null;
  }, [
    loggedIn.value,
    isSuperAdmin,
    isCustomerAccessAllowed.value,
    isSiteAccessAllowed.value,
    siteIdBelongsToCustomer.value,
  ]);

  const siteInfoRouteElement = useMemo(
    () => (
      <Protected
        isAuthenticated={loggedIn.value}
        isAuthorized={
          (isSuperAdmin || (isCustomerAccessAllowed.value && isSiteAccessAllowed.value)) &&
          siteIdBelongsToCustomer.value
        }
        uniqueRedirectPath={getUniqueRedirectPath()}
      >
        <AppLayout
          isSiteHeader
          headerTitle={getSite()?.siteName}
          headerNavItems={
            isSuperAdmin || isAccountAdmin
              ? getNavItemsHeaderSelectedSiteSAAndAA(user, isSuperAdmin)
              : NAV_ITEMS_HEADER_SELECTED_SITE
          }
        >
          <EditSite />
        </AppLayout>
      </Protected>
    ),
    [
      loggedIn.value,
      isSuperAdmin,
      isCustomerAccessAllowed.value,
      isSiteAccessAllowed.value,
      siteIdBelongsToCustomer.value,
      getUniqueRedirectPath,
      user,
      getSite,
      isAccountAdmin,
    ]
  );

  const getSiteInfoElement = () => {
    if (!loggedIn.loaded) return <LoadingElement />;

    if (loggedIn.loaded && !loggedIn.value) return siteInfoRouteElement;

    if (!customerId.value || !customerId.loaded || !siteId.value || !siteId.loaded)
      return <LoadingElement />;

    if (!siteIdBelongsToCustomer.loaded) return <LoadingElement />;

    if (isSuperAdmin && loggedIn.loaded) return siteInfoRouteElement;

    if (!isCustomerAccessAllowed.loaded || !isSiteAccessAllowed.loaded) return <LoadingElement />;

    return siteInfoRouteElement;
  };

  return getSiteInfoElement();
};
