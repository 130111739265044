import { Theme, styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';

type StylesTypes = {
  theme: Theme;
  isSuperAdmin: boolean;
  isCustomerAccountPage: boolean;
};

const StyledNav = styled(Box)(() => ({
  height: `calc(100% - 100px)`,
  display: 'flex',
  flexDirection: 'column',
}));

const navHeader = { marginTop: '20px' };

const StyledHeaderTitle = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'isSuperAdmin' && prop !== 'isCustomerAccountPage',
})(({ theme, isSuperAdmin, isCustomerAccountPage }: StylesTypes) => ({
  marginLeft: '16px',
  color:
    isSuperAdmin && isCustomerAccountPage ? theme.palette.common.white : theme.palette.common.white,
}));

const headerList = {
  marginTop: '12px',
};

const helpText = {
  display: 'block',
  textAlign: 'center',
};

const footerStack = {
  flexDirection: 'column',
  justifyContent: 'flex-end',
  height: '100%',
};

const footerBtn = {
  width: '207px',
  margin: '16px auto',
  boxShadow: 'none',
  textTransform: 'capitalize',
};

const footerContent = {
  display: ' flex',
  flexDirection: 'column',
  justifyContent: 'center',
};

const headerTitle = {
  marginLeft: '16px',
};

const StyledFooterText = styled('span', {
  shouldForwardProp: (prop) => prop !== 'isSuperAdmin' && prop !== 'isCustomerAccountPage',
})(({ theme, isSuperAdmin, isCustomerAccountPage }: StylesTypes) => ({
  display: 'block',
  margin: '25px 0 40px 0',
  color:
    isSuperAdmin && isCustomerAccountPage ? theme.palette.common.white : theme.palette.grey[500],
  fontSize: theme.typography.caption.fontSize,
  fontWeight: theme.typography.fontWeightBold,
  textAlign: 'center',
  textTransform: 'uppercase',
}));

export {
  navHeader,
  StyledHeaderTitle,
  headerList,
  headerTitle,
  helpText,
  footerStack,
  footerBtn,
  footerContent,
  StyledFooterText,
  StyledNav,
};
