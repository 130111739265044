import { Stack, Typography } from '@mui/material';
import { FunctionComponent } from 'react';
import dayjs from 'dayjs';
import { formatUTCDateToLocalDate } from 'src/utilities';
import formatDateRange from '../utils/formatDateRange';

interface ILogDateProps {
  date: string | null;
  startDate: string;
  endDate?: string;
}

const LogDate: FunctionComponent<ILogDateProps> = ({ date, startDate, endDate }) => (
  <Stack flexDirection="column">
    <Typography sx={{ fontSize: '12px', color: '#566369' }} variant="body1">
      <span> {formatDateRange(startDate, endDate)}</span>
    </Typography>
    {date && (
      <Typography sx={{ fontSize: '12px', color: '#566369' }} variant="body1">
        Last modified: {dayjs(formatUTCDateToLocalDate(date)).format('M/D/YYYY, h:mm A')}{' '}
      </Typography>
    )}
  </Stack>
);

export default LogDate;
